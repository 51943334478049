import React from 'react';
import FindTab from './Find/FindTab';
import AddTab from './Add/AddTab';
import { usePermissions } from 'Util/Helpers/Permissions';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import ViewEditTab from './ViewEdit/ViewEditTab';
import { matchPath, useHistory } from 'react-router';
import './DisciplinaryMatters.scss';

interface MatchComplaint {
   complaintId?: string;
}

const BASE_URL = '/disciplinary-matters';
const VIEW_EDIT_ROUTE = BASE_URL + '/:complaintId';

const FIND_TAB: RoutedTab = {
   label: 'Find',
   route: '',
   component: FindTab,
   pageTitle: 'Search',
   exact: true,
   permissions: 'DisciplinaryMatter.Read',
   permissionRedirectPath: '/',
};
const ADD_TAB: RoutedTab = {
   label: 'Add',
   route: '/add',
   component: AddTab,
   pageTitle: 'Add',
   permissions: 'DisciplinaryMatter.Create',
   permissionRedirectPath: '/disciplinary-matters',
};
const VIEW_EDIT_TAB: RoutedTab = {
   label: 'View',
   route: '/:complaintId',
   component: ViewEditTab,
   permissions: 'DisciplinaryMatter.Read',
   permissionRedirectPath: '/',
};

const disciplinaryTabs: readonly RoutedTab[] = [FIND_TAB];

const DisciplinaryMatters = (): JSX.Element => {
   const canAdd = usePermissions('DisciplinaryMatter.Create');
   const tabs = [...disciplinaryTabs];
   if (canAdd) tabs.push(ADD_TAB);

   const history = useHistory();
   const matches = matchPath<MatchComplaint>(
      history.location.pathname,
      VIEW_EDIT_ROUTE
   );

   const hasValidComplaintId =
      matches &&
      matches.params &&
      matches.params.complaintId &&
      Number(matches.params.complaintId) > 0;
   if (hasValidComplaintId) tabs.push(VIEW_EDIT_TAB);

   return (
      <RoutedTabContainer
         tabs={tabs}
         baseUrl={BASE_URL}
         panelClassName="disciplinary-matters"
         pageTitle="Disciplinary Matters"
      />
   );
};

export default DisciplinaryMatters;
