import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import ViewSystemParameter from './ViewSystemParameter';
import AddSystemParameter from './AddSystemParameter';

const systemParameterTabs: readonly RoutedTab[] = [
   {
      label: 'View',
      route: '',
      component: ViewSystemParameter,
      pageTitle: 'View',
      exact: true,
      permissions: 'SystemParameter.Read',
   },
   {
      label: 'Add',
      route: '/add',
      component: AddSystemParameter,
      pageTitle: 'Add',
      permissions: 'SystemParameter.Create',
   },
];

const SystemParameter = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={systemParameterTabs}
         baseUrl="/superuser/system-parameter"
         panelClassName="systemParameter"
         pageTitle="System Parameter"
      />
   );
};

export default SystemParameter;
