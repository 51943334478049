import { createReducer } from 'reduxsauce';
import ApiError from 'Models/Other/ApiError';
import { AnyAction } from 'redux';
import StateReadonly from './StateModel';
import FeesPaymentSearchResultReadOnly from 'Models/FeesPayment/FeesPaymentSearchResult';
import FeesPaymentSearchInput from 'Models/FeesPayment/FeesPaymentSearchInput';
import FeeRequestLineDisplay from 'Models/FeesPayment/FeeRequestLineDisplay';
import FeesPaymentSearchResult from 'Models/FeesPayment/FeesPaymentSearchResult';
import FeeRequestLineInput from 'Models/FeesPayment/FeeRequestLineInput';
import FeeRequestInput from 'Models/FeesPayment/FeeRequestInput';
import { createScopedActions } from '.';
import FeeRequestDtoReadonly from 'Models/FeeRequest/Dto/FeeRequestDto';

interface FeesPaymentState {
   searchResult: FeesPaymentSearchResultReadOnly;
   feeRequest: FeeRequestDtoReadonly;
   feeRequestLine: FeeRequestLineDisplay;
   updatedFeeRequestLineId: number;
   createdFeeRequestId: number;
}

export type FeesPaymentStateReadOnly = Readonly<FeesPaymentState>;

interface TypeNames {
   GET_SEARCH_RESULT_REQUEST: string;
   GET_SEARCH_RESULT_SUCCESS: string;
   GET_SEARCH_RESULT_FAILURE: string;
   GET_SEARCH_RESULT_RESET: string;

   SAVE_FEE_REQUEST_REQUEST: string;
   SAVE_FEE_REQUEST_SUCCESS: string;
   SAVE_FEE_REQUEST_FAILURE: string;

   SAVE_FEE_REQUEST_LINE_REQUEST: string;
   SAVE_FEE_REQUEST_LINE_SUCCESS: string;
   SAVE_FEE_REQUEST_LINE_FAILURE: string;

   GET_FEE_REQUEST_REQUEST: string;
   GET_FEE_REQUEST_SUCCESS: string;
   GET_FEE_REQUEST_FAILURE: string;

   GET_FEE_REQUEST_LINE_REQUEST: string;
   GET_FEE_REQUEST_LINE_SUCCESS: string;
   GET_FEE_REQUEST_LINE_FAILURE: string;
}

type FeesPaymentTypeNames = Readonly<TypeNames>;

export interface FeesPaymentCreators {
   getSearchResultRequest: (model: FeesPaymentSearchInput) => AnyAction;
   getSearchResultSuccess: (
      data: FeesPaymentSearchResultReadOnly | undefined
   ) => AnyAction;
   getSearchResultFailure: (error: ApiError) => AnyAction;
   getSearchResultReset: () => AnyAction;

   saveFeeRequestRequest: (data: FeeRequestInput) => AnyAction;
   saveFeeRequestSuccess: (data: number | undefined) => AnyAction;
   saveFeeRequestFailure: (error: ApiError) => AnyAction;

   saveFeeRequestLineRequest: (lineData: FeeRequestLineInput) => AnyAction;
   saveFeeRequestLineSuccess: (data: number | undefined) => AnyAction;
   saveFeeRequestLineFailure: (error: ApiError) => AnyAction;

   getFeeRequestRequest: (data: number) => AnyAction;
   getFeeRequestSuccess: (data: FeeRequestDtoReadonly) => AnyAction;
   getFeeRequestFailure: (error: ApiError) => AnyAction;

   getFeeRequestLineRequest: (data: number) => AnyAction;
   getFeeRequestLineSuccess: (data: FeeRequestLineDisplay) => AnyAction;
   getFeeRequestLineFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: FeesPaymentStateReadOnly = {
   searchResult: ({} as unknown) as FeesPaymentSearchResult,
   feeRequest: ({} as unknown) as FeeRequestDtoReadonly,
   feeRequestLine: ({} as unknown) as FeeRequestLineDisplay,
   updatedFeeRequestLineId: 0,
   createdFeeRequestId: 0,
};

/* ------------- Reducers ------------- */
const getSearchResultSuccess = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         searchResult: action.data,
      };
   }
   return state;
};

const getSearchResultReset = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   return {
      ...state,
      searchResult: ({} as unknown) as FeesPaymentSearchResult,
   };
};

const saveFeeRequestSuccess = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         createdFeeRequestId: action.data,
      };
   }
   return state;
};

const getFeeRequestReset = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   return {
      ...state,
      feeRequest: ({} as unknown) as FeeRequestDtoReadonly,
   };
};

const getFeeRequestSuccess = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         feeRequest: action.data,
      };
   }
   return state;
};

const saveFeeRequestLineSuccess = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         updatedFeeRequestLineId: action.data,
      };
   }
   return state;
};

const getFeeRequestLineSuccess = (
   state: FeesPaymentStateReadOnly,
   action: AnyAction
): FeesPaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         feeRequestLine: {
            feeRequestLineId: action.data.feeRequestLineId,
            feeTypeCode: action.data.feeType.feeTypeCode,
            feeTypeDescription: action.data.feeType.description,
            feeRequestLineAmount: action.data.feeRequestLineAmount,
            optional: action.data.optional,
            paid: action.data.paid,
            creditReason: action.data.creditReason,
         },
      };
   }
   return state;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   FeesPaymentTypeNames,
   FeesPaymentCreators
>('feePayment', {
   getSearchResultRequest: ['searchInput'],
   getSearchResultSuccess: ['data'],
   getSearchResultFailure: ['error'],
   getSearchResultReset: [],

   saveFeeRequestRequest: ['data'],
   saveFeeRequestSuccess: ['data'],
   saveFeeRequestFailure: ['error'],

   saveFeeRequestLineRequest: ['lineData'],
   saveFeeRequestLineSuccess: ['data'],
   saveFeeRequestLineFailure: ['error'],

   getFeeRequestRequest: ['feeRequestId'],
   getFeeRequestSuccess: ['data'],
   getFeeRequestFailure: ['error'],

   getFeeRequestLineRequest: ['feeRequestLineId'],
   getFeeRequestLineSuccess: ['data'],
   getFeeRequestLineFailure: ['error'],
});

export const FeesPaymentTypes = Types;
export const FeesPaymentActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_SEARCH_RESULT_RESET]: getSearchResultReset,
   [Types.GET_SEARCH_RESULT_SUCCESS]: getSearchResultSuccess,
   [Types.SAVE_FEE_REQUEST_SUCCESS]: saveFeeRequestSuccess,
   [Types.SAVE_FEE_REQUEST_LINE_SUCCESS]: saveFeeRequestLineSuccess,
   [Types.GET_FEE_REQUEST_REQUEST]: getFeeRequestReset,
   [Types.GET_FEE_REQUEST_SUCCESS]: getFeeRequestSuccess,
   [Types.GET_FEE_REQUEST_LINE_SUCCESS]: getFeeRequestLineSuccess,
});

/* ------------- Selectors ------------- */

export const selectSearchResult = (
   state: StateReadonly
): FeesPaymentSearchResultReadOnly => state.feePayment.searchResult;

export const selectFeeRequest = (state: StateReadonly): FeeRequestDtoReadonly =>
   state.feePayment.feeRequest;

export const selectFeeRequestLine = (
   state: StateReadonly
): FeeRequestLineDisplay => state.feePayment.feeRequestLine;

export const selectUpdatedFeeRequestLineId = (state: StateReadonly): number =>
   state.feePayment.updatedFeeRequestLineId;

export const selectCreatedFeeRequestId = (state: StateReadonly): number =>
   state.feePayment.createdFeeRequestId;
