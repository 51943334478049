import { put, call } from 'redux-saga/effects';
import { NotificationActions } from '../Redux/NotificationRedux';
import { ApiResponse } from 'apisauce';
import WebMessageDtoReadonly from 'Models/Notifications/WebMessageDto';
import { NotificationServiceType } from 'State/Services/NotificationService';

interface NotificationParams {
   type: string;
   token: string;
   id: string;
   message: WebMessageDtoReadonly;
   data: string;
}

export function* getWebMessages(
   notificationService: Readonly<NotificationServiceType>,
   action: Readonly<NotificationParams>
): Generator {
   const notificationResponse = yield call(notificationService.getWebMessages);
   const response = notificationResponse as ApiResponse<WebMessageDtoReadonly>;

   if (response.status && response.ok) {
      yield put(NotificationActions.getWebMessagesSuccess(response.data));
   } else {
      yield put(
         NotificationActions.getWebMessagesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateWebMessage(
   notificationService: Readonly<NotificationServiceType>,
   action: Readonly<NotificationParams>
): Generator {
   const { message } = action;
   const notificationResponse = yield call(
      notificationService.updateWebMessage,
      message
   );
   const response = notificationResponse as ApiResponse<WebMessageDtoReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(NotificationActions.updateWebMessageSuccess(response.data));
   } else {
      yield put(
         NotificationActions.updateWebMessageFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* triggerNotification(
   notificationService: Readonly<NotificationServiceType>,
   action: Readonly<NotificationParams>
): Generator {
   const { data } = action;
   const licenceResponse = yield call(
      notificationService.triggerNotification,
      data
   );
   const response = licenceResponse as ApiResponse<string>;

   if (response.status && response.ok) {
      yield put(NotificationActions.triggerNotificationSuccess(response.data));
   } else {
      yield put(
         NotificationActions.triggerNotificationFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
