import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardText } from 'react-md/lib/Cards';
import { PhotoSection, PersonalSection, PublicRegisterSection } from './Views';
import {
   selectContact,
   selectContactScreenData,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
   ContactEditModel,
   DefaultContactEditModel,
} from 'Models/Contact/Data/ContactEditModel';
import Loading from 'Components/Common/Loading';
import {
   getBusinessAddressLine,
   getPhysicalAddressLine,
   getMailingAddressLine,
   isValidPhysicalAddress,
} from 'Util/Helpers/Address';
import { Redirect } from 'react-router';
import '../../Contact.scss';
import {
   selectIsLoading,
   selectErrorMessage,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import AvatarEditor from 'react-avatar-editor';
import ValidationModel from 'Components/Common/ModelValidator/ValidationModel';
import { validateModel } from 'Components/Common/ModelValidator/Helper';
import {
   ERROR_INDENTITY_SAVE,
   SUCCESS_SAVE,
   ERROR_REGISTRATION_EXIST,
   ERROR_POBOX,
   ERROR_VALIDATION_FAIL,
} from './CreateContactConstant';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { usePermissions } from 'Util/Helpers/Permissions';
import LoadingButton from 'Components/Common/LoadingButton';

export type ContactValidationFields = Pick<
   ContactEditModel,
   | 'registrationNumber'
   | 'firstName'
   | 'lastName'
   | 'dateOfBirth'
   | 'emailAddress'
   | 'contactStatusCode'
>;

export const CONTACT_VALIDATION_MODEL: ValidationModel<ContactValidationFields> = {
   errorMessage: 'The model is not valid',
   fields: {
      registrationNumber: {
         validation: 'registration-number',
         error: false,
      },
      firstName: {
         validation: 'text',
         required: true,
         error: false,
      },
      lastName: {
         validation: 'text',
         required: true,
         error: false,
      },
      dateOfBirth: {
         validation: 'text',
         required: true,
         error: false,
      },
      emailAddress: {
         validation: 'email',
         error: false,
         required: false,
      },
      contactStatusCode: {
         validation: 'text',
         error: false,
         required: true,
      },
   },
};

interface CreateContactProps {
   contactId?: number;
   editMode?: boolean;
   onSaveSuccess?: () => void;
}

const CreateContact = ({
   onSaveSuccess,
   editMode,
   contactId,
}: Readonly<CreateContactProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const screenData = useSelector(selectContactScreenData);
   const isError = useSelector(
      selectIsError([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );
   const saveErrorMessage = useSelector(
      selectErrorMessage(ContactTypes.SAVE_CONTACT_DATA_REQUEST)
   );
   const isLoading = useSelector(
      selectIsLoading([
         ContactTypes.SAVE_CONTACT_DATA_REQUEST,
         ContactTypes.GET_CONTACT_SCREEN_DATA_REQUEST,
      ])
   );
   const dispatch = useDispatch();

   // states:
   const [isSaveTriggered, setIsSaveTriggered] = useState(false);
   const [inputModel, setInputModel] = useState(DefaultContactEditModel);
   const [isEditDataLoaded, setIsEditDataLoaded] = useState(false);
   const [isValidated, setIsValidate] = useState(false);
   const [validationModel, setValidationModel] = useState(
      CONTACT_VALIDATION_MODEL
   );

   const title = editMode ? 'Edit Contact Details' : 'Create Contact Details';
   const photoEditor = useRef<AvatarEditor | null>(null);
   const hasModifyPermission = usePermissions([
      'Contact.Create' && 'Contact.Update',
   ]);

   let validationResult = isValidated
      ? validateModel<ContactValidationFields>(inputModel, validationModel)
      : undefined;

   const tryFetchData = useCallback((): void => {
      if (editMode && contactId) {
         dispatch(ContactActions.getContactScreenDataRequest(contactId));
      }
   }, [dispatch, editMode, contactId]);

   useEffect((): (() => void) => {
      tryFetchData();
      return (): void => {
         dispatch(ContactActions.getContactScreenDataReset());
      };
   }, [dispatch, tryFetchData]);

   useEffect(() => {
      if (editMode && screenData && !isEditDataLoaded && !isLoading) {
         const contactInput = {
            ...screenData,
            // set full address
            mailingAddressFull: getMailingAddressLine(screenData),
            physicalAddressFull: getPhysicalAddressLine(screenData),
            businessAddressFull: getBusinessAddressLine(screenData),
            contactId: screenData.contactId,
         };
         setInputModel(contactInput);
         setIsEditDataLoaded(true);
      }
   }, [screenData, dispatch, editMode, isEditDataLoaded, isLoading]);

   useEffect((): void => {
      if (isError && saveErrorMessage && isSaveTriggered) {
         setIsSaveTriggered(false);
         const errorMessage = saveErrorMessage;
         toast.error(errorMessage);
         if (errorMessage && errorMessage.includes(ERROR_REGISTRATION_EXIST)) {
            const newValidationModel: ValidationModel<ContactValidationFields> = {
               ...validationModel,
               fields: {
                  ...validationModel.fields,
                  registrationNumber: {
                     validation: 'number',
                     error: true,
                     errorMessage: errorMessage as string,
                  },
               },
            };

            // force registration number to be invalid
            setValidationModel(newValidationModel);
         }
      }
   }, [saveErrorMessage, isSaveTriggered, validationModel, isError]);

   const hasSavedNewContact =
      screenData && isSaveTriggered && !isLoading && !isError;

   useEffect((): void => {
      // save successful
      if (hasSavedNewContact) {
         setIsSaveTriggered(false);
         toast.success(SUCCESS_SAVE);

         if (screenData && !screenData.isIdentityUserCreated) {
            toast.warn(ERROR_INDENTITY_SAVE);
         }

         if (onSaveSuccess) {
            onSaveSuccess();
         }
      }
   }, [hasSavedNewContact, onSaveSuccess, screenData]);

   if (!editMode && screenData && hasSavedNewContact) {
      return <Redirect to={`/contact/${screenData.contactId}`} />;
   }

   const resetRegistrationValidation = (): void => {
      const newValidationModel: ValidationModel<ContactValidationFields> = {
         ...validationModel,
         fields: {
            ...validationModel.fields,
            registrationNumber: {
               validation: 'number',
               error: false,
            },
         },
      };

      // clear existing validation to use default
      setValidationModel(newValidationModel);
   };

   const validateAddress = (): boolean => {
      // physical address validation:
      if (!isValidPhysicalAddress(inputModel.physicalAddressLine1)) {
         toast.error(ERROR_POBOX);
         return false;
      }
      return true;
   };

   const validateAndSave = (): void => {
      resetRegistrationValidation();
      setIsValidate(true);

      if (hasModifyPermission) {
         if (!validateAddress()) {
            return;
         }

         validationResult = validateModel<ContactValidationFields>(
            inputModel,
            validationModel
         );

         if (!validationResult || validationResult.error) {
            toast.error(ERROR_VALIDATION_FAIL);
            return;
         }
      }

      // save the contact details:
      setIsSaveTriggered(true);
      const canvas = photoEditor.current && photoEditor.current.getImage();
      const photoData = canvas ? canvas.toDataURL() : '';
      const newInputModel = { ...inputModel, photoData };
      setInputModel(newInputModel);
      dispatch(ContactActions.saveContactDataRequest(newInputModel));
   };

   return (
      <Loading
         isLoading={(editMode && !isEditDataLoaded) || isLoading}
         isError={false}
         tryReload={tryFetchData}
      >
         <Card className="create-contact md-block-centered md-cell--12">
            <CardText>
               <h2>{title}</h2>
               {hasModifyPermission && (
                  <>
                     <PhotoSection
                        model={inputModel}
                        updateModel={setInputModel}
                        validationResult={validationResult}
                        photoEditor={photoEditor}
                        isEditMode={editMode}
                        contactPhoto={
                           editMode ? contact.contactPhoto : undefined
                        }
                     />
                     <h2>Personal Details</h2>
                     <PersonalSection
                        model={inputModel}
                        updateModel={setInputModel}
                        validationResult={validationResult}
                     />
                  </>
               )}
               <PublicRegisterSection
                  model={inputModel}
                  updateModel={setInputModel}
                  validationResult={validationResult}
               />
               <div className="controls">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     onClick={(): void => validateAndSave()}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) validateAndSave();
                     }}
                     isLoading={isLoading}
                  >
                     Save and Close
                  </LoadingButton>
               </div>
            </CardText>
         </Card>
      </Loading>
   );
};

export default React.memo(CreateContact);
