export type LicenceTypeCode =
   | 'CP'
   | 'CG'
   | 'CD'
   | 'RP'
   | 'RG'
   | 'RD'
   | 'JP'
   | 'JG'
   | 'JD'
   | 'LCD'
   | 'LCG'
   | 'LCP'
   | 'LCAD'
   | 'LCAG'
   | 'LCAP'
   | 'EMPL'
   | 'GE53'
   | 'GE56'
   | 'GE57'
   | 'GI'
   | 'PCD'
   | 'PCG'
   | 'PCP'
   | 'PRD'
   | 'PRG'
   | 'PRP'
   | 'S21';

export const LICENCE_LEVEL: ReadonlyMap<string, string> = new Map([
   ['CERTIFYING', 'Certifying'],
   ['JOURNEYMAN', 'Journeyman'],
   ['TRADESMAN', 'Tradesman'],
   ['TRAINEE', 'Trainee'],
   ['EXEMPTION', 'Exemption'],
]);

export const EXEMPT_LICENCE_TYPES: readonly string[] = [
   'LCD',
   'LCG',
   'LCP',
] as LicenceTypeCode[];
export const TRAINEE_LICENCE_TYPES: readonly string[] = [
   'LCAD',
   'LCAG',
   'LCAP',
] as LicenceTypeCode[];
export const JOURNEYMAN_LICENCE_TYPES: readonly string[] = [
   'JD',
   'JG',
   'JP',
] as LicenceTypeCode[];
export const TRADESMAN_LICENCE_TYPES: readonly string[] = [
   'RD',
   'RG',
   'RP',
] as LicenceTypeCode[];
export const CERTIFYING_LICENCE_TYPES: readonly string[] = [
   'CD',
   'CG',
   'CP',
] as LicenceTypeCode[];

export const DRAINLAYER_LICENCE_TYPES: readonly string[] = [
   'LCD',
   'LCAD',
   'JD',
   'RD',
   'CD',
] as LicenceTypeCode[];
export const PLUMBER_LICENCE_TYPES: readonly string[] = [
   'LCP',
   'LCAP',
   'JP',
   'RP',
   'CP',
] as LicenceTypeCode[];
export const GASFITTER_LICENCE_TYPES: readonly string[] = [
   'LCG',
   'LCAG',
   'JG',
   'RG',
   'CG',
] as LicenceTypeCode[];

export const EMPLOYER_LICENCE_TYPE = 'EMPL';
export const SECTION_21_LICENCE_TYPE = 'S21';
export const EXEMPTION_GAFITTING_UNDER_SUPERVISION_LICENCE_TYPE = 'LCG';

export const LICENCE_TYPES_FOR_DISCIPLINARY_FEE: readonly string[] = [
   ...CERTIFYING_LICENCE_TYPES,
   ...TRADESMAN_LICENCE_TYPES,
   ...JOURNEYMAN_LICENCE_TYPES,
];
