import React from 'react';
import { useLicenceYearList } from 'Util/Helpers/Metadata';
import { ConditionValueProps } from './ConditionValueProps';
import SelectField from 'react-md/lib/SelectFields';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ConditionLicenceYearProps {
   onChange?: (val: string | number) => void;
}

export const ConditionLicenceYear = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
}: Readonly<ConditionLicenceYearProps & ConditionValueProps>): JSX.Element => {
   const licenceYears = useLicenceYearList();

   return (
      <SelectField
         floating
         id={`app-${1}-${conditionCode}`}
         lineDirection="center"
         className="md-cell md-cell--12"
         label={description}
         inputClassName={getInputValidationClassName(!isChecked)}
         menuItems={licenceYears}
         position={SelectField.Positions.BELOW}
         value={textValue}
         defaultValue=" "
         required
         error={!isChecked}
         errorText="Please select a licence year"
         disabled={disabled}
         onChange={onChange}
      />
   );
};
