import React, { useState, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash-es';
import {
   selectContactScreenData,
   ContactTypes,
   ContactActions,
} from 'State/Redux/ContactRedux';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
   ContactEditModel,
   DefaultTrainingProviderEditModel,
} from 'Models/Contact/Data/ContactEditModel';
import Loading from 'Components/Common/Loading';
import {
   getBusinessAddressLine,
   getPhysicalAddressLine,
   getMailingAddressLine,
   isValidPhysicalAddress,
} from 'Util/Helpers/Address';
import { Redirect } from 'react-router';
import {
   selectIsLoading,
   selectErrorMessage,
   selectIsError,
   selectIsSuccess,
} from 'State/Redux/AsyncRedux';
import ValidationModel from 'Components/Common/ModelValidator/ValidationModel';
import { validateModel } from 'Components/Common/ModelValidator/Helper';

import { CONTACT_TYPE_TRAINING_PROVIDER } from 'Util/Constants/Contact';

import { isEnterKeyPress } from 'Util/Helpers/Input';
import {
   ERROR_REGISTRATION_EXIST,
   SUCCESS_SAVE,
   ERROR_POBOX,
} from 'Components/Pages/Contact/SubMenu/CreateContact/CreateContactConstant';
import { PersonalSection } from 'Components/Pages/Contact/SubMenu/CreateContact/Views';
import TrainingProviderHeaderSection from './TrainingProviderHeaderSection';
import Card, { CardText } from 'react-md/lib/Cards';
import LoadingButton from 'Components/Common/LoadingButton';

export type TrainingProviderValidationFields = Pick<
   ContactEditModel,
   | 'companyName'
   | 'registrationNumber'
   | 'firstName'
   | 'lastName'
   | 'dateOfBirth'
   | 'emailAddress'
   | 'mobileNumber'
   | 'phoneNumber'
   | 'publicPhone'
   | 'publicEmail'
   | 'contactStatusCode'
   | 'mailingAddressLine1'
   | 'physicalAddressLine1'
>;

const CONTACT_VALIDATION_MODEL: ValidationModel<TrainingProviderValidationFields> = {
   errorMessage: 'The model is not valid',
   fields: {
      companyName: {
         validation: 'text',
         error: false,
         required: true,
      },
      registrationNumber: {
         validation: 'registration-number',
         error: false,
      },
      firstName: {
         validation: 'text',
         required: true,
         error: false,
      },
      lastName: {
         validation: 'text',
         required: true,
         error: false,
      },
      dateOfBirth: {
         validation: 'text',
         error: false,
      },
      emailAddress: {
         validation: 'email',
         error: false,
      },
      mobileNumber: {
         validation: 'phone-number',
         error: false,
      },
      phoneNumber: {
         validation: 'phone-number',
         error: false,
      },
      publicPhone: {
         validation: 'phone-number',
         error: false,
      },
      publicEmail: {
         validation: 'email',
         error: false,
      },
      contactStatusCode: {
         validation: 'text',
         error: false,
         required: true,
      },
      mailingAddressLine1: {
         validation: 'text',
         error: false,
         required: false,
      },
      physicalAddressLine1: {
         validation: (data: TrainingProviderValidationFields): boolean =>
            !isValidPhysicalAddress(data.physicalAddressLine1),
         error: false,
         required: false,
      },
   },
};

interface CreateTrainingProviderProps {
   contactId?: number;
   editMode?: boolean;
   onSaveSuccess?: () => void;
}

const CreateTrainingProvider = ({
   editMode,
   contactId,
   onSaveSuccess,
}: Readonly<CreateTrainingProviderProps>): JSX.Element => {
   const screenData = useSelector(selectContactScreenData);
   const isError = useSelector(
      selectIsError([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );
   const saveErrorMessage = useSelector(
      selectErrorMessage(ContactTypes.SAVE_CONTACT_DATA_REQUEST)
   );
   const isLoading = useSelector(
      selectIsLoading([
         ContactTypes.SAVE_CONTACT_DATA_REQUEST,
         ContactTypes.GET_CONTACT_SCREEN_DATA_REQUEST,
      ])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );
   const dispatch = useDispatch();

   // states:
   const [isSaveTriggered, setIsSaveTriggered] = useState(false);
   const [inputModel, setInputModel] = useState(
      DefaultTrainingProviderEditModel
   );
   const [isEditDataLoaded, setIsEditDataLoaded] = useState(false);
   const [isValidated, setIsValidate] = useState(false);
   const [validationModel, setValidationModel] = useState(
      CONTACT_VALIDATION_MODEL
   );
   let validationResult = isValidated
      ? validateModel<TrainingProviderValidationFields>(
           inputModel,
           validationModel
        )
      : undefined;

   const tryFetchData = useCallback((): void => {
      if (editMode && contactId) {
         dispatch(ContactActions.getContactScreenDataRequest(contactId));
      }
   }, [dispatch, editMode, contactId]);

   useEffect((): (() => void) => {
      tryFetchData();
      return (): void => {
         dispatch(ContactActions.getContactScreenDataReset());
      };
   }, [dispatch, tryFetchData]);

   useEffect(() => {
      if (editMode && screenData && !isEditDataLoaded) {
         const contactInput = {
            ...cloneDeep<ContactEditModel>(screenData),
            // set full address
            mailingAddressFull: getMailingAddressLine(screenData),
            physicalAddressFull: getPhysicalAddressLine(screenData),
            businessAddressFull: getBusinessAddressLine(screenData),
            contactId: screenData.contactId,
         };
         setInputModel(contactInput);
         setIsEditDataLoaded(true);
      }
   }, [screenData, editMode, isEditDataLoaded]);

   useEffect((): void => {
      if (isError && saveErrorMessage && isSaveTriggered) {
         setIsSaveTriggered(false);
         const errorMessage = saveErrorMessage;
         toast.error(errorMessage);
         if (errorMessage && errorMessage.includes(ERROR_REGISTRATION_EXIST)) {
            const newValidationModel: ValidationModel<TrainingProviderValidationFields> = {
               ...validationModel,
               fields: {
                  ...validationModel.fields,
                  registrationNumber: {
                     validation: 'number',
                     error: true,
                     errorMessage: errorMessage as string,
                  },
               },
            };

            // force registration number to be invalid
            setValidationModel(newValidationModel);
         }
      }
   }, [saveErrorMessage, isSaveTriggered, validationModel, isError]);

   useEffect((): void => {
      // save successful
      if (screenData && isSaveTriggered && isSuccess) {
         setIsSaveTriggered(false);
         toast.success(SUCCESS_SAVE);
         if (onSaveSuccess) {
            onSaveSuccess();
         }
      }
   }, [isSaveTriggered, onSaveSuccess, isSuccess, screenData]);

   if (!editMode && screenData && isSaveTriggered && isSuccess) {
      return <Redirect to={`/contact/${screenData.contactId}`} />;
   }

   const resetRegistrationValidation = (): void => {
      const newValidationModel: ValidationModel<TrainingProviderValidationFields> = {
         ...validationModel,
         fields: {
            ...validationModel.fields,
            registrationNumber: {
               validation: 'number',
               error: false,
            },
         },
      };

      // clear existing validation to use default
      setValidationModel(newValidationModel);
   };

   const validateAddress = (): boolean => {
      // physical address validation:
      if (!isValidPhysicalAddress(inputModel.physicalAddressLine1)) {
         toast.error(ERROR_POBOX);
         return false;
      }
      return true;
   };

   const validateAndSave = (): void => {
      resetRegistrationValidation();
      setIsValidate(true);
      validationResult = validateModel<TrainingProviderValidationFields>(
         inputModel,
         validationModel
      );
      if (!validationResult || validationResult.error) {
         toast.error('Validation Failed: Please enter all required fields');
         return;
      }

      if (!validateAddress()) {
         return;
      }

      // save the contact details:
      setIsSaveTriggered(true);
      const newInputModel = {
         ...inputModel,
         contactTypeCode: CONTACT_TYPE_TRAINING_PROVIDER,
      };
      setInputModel(newInputModel);
      dispatch(ContactActions.saveContactDataRequest(newInputModel));
   };

   const title = editMode
      ? 'Edit Training Provider'
      : 'Create Training Provider';

   return (
      <Loading
         isLoading={(editMode && !isEditDataLoaded) || isLoading}
         isError={false}
         tryReload={tryFetchData}
      >
         <>
            <Card className="create-contact md-block-centered md-cell--12">
               <CardText>
                  <h2>{title}</h2>
                  <>
                     <TrainingProviderHeaderSection
                        model={inputModel}
                        updateModel={setInputModel}
                        validationResult={validationResult}
                        isEditMode={editMode}
                     />
                     <hr />
                     <h2>Contact Details</h2>
                     <PersonalSection
                        model={inputModel}
                        updateModel={setInputModel}
                        validationResult={validationResult}
                        companyProfile
                     />
                     <div className="controls">
                        <LoadingButton
                           isLoading={isLoading}
                           flat
                           primary
                           swapTheming
                           onClick={(): void => validateAndSave()}
                           onKeyUp={(keyPress): void => {
                              if (isEnterKeyPress(keyPress)) validateAndSave();
                           }}
                        >
                           Save and Close
                        </LoadingButton>
                     </div>
                  </>
               </CardText>
            </Card>
         </>
      </Loading>
   );
};

export default CreateTrainingProvider;
