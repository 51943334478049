import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import { withAccessToken, createApi } from '.';
import QuestionSaveDto from 'Models/FitAndProper/Dto/QuestionSaveDto';
import AnswerHistoryDto from 'Models/FitAndProper/Dto/AnswerHistoryDto';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';

export interface FitAndProperServiceType {
   getQuestions(): Promise<ApiResponse<QuestionDto[]>>;
   getQuestionsAndAnswersById(id: string): Promise<ApiResponse<QuestionDto[]>>;
   getQuestionsAndAnswersByIdAndQuestionId(
      id: string,
      questionId: number
   ): Promise<ApiResponse<QuestionDto[]>>;
   getQuestionHistoryById(
      userId: string,
      questionId: number
   ): Promise<ApiResponse<AnswerHistoryDto[]>>;
   saveAnswerById(questions: QuestionSaveDto): Promise<ApiResponse<boolean>>;
   saveAnswerListById(
      userId: string,
      questions: QuestionSaveBulkDto[]
   ): Promise<ApiResponse<QuestionDto[]>>;
   saveAnswerListByIdAndQuestionId(
      userId: string,
      questions: QuestionSaveBulkDto[],
      questionId: number
   ): Promise<ApiResponse<QuestionDto[]>>;
}

const create = (baseURL: string | undefined): FitAndProperServiceType => {
   const api = createApi({ baseURL });

   const getQuestions = (): Promise<ApiResponse<QuestionDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get('/FitToPractice');
   };

   const getQuestionsAndAnswersById = (
      id: string
   ): Promise<ApiResponse<QuestionDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/FitToPractice/${id}`);
   };

   const getQuestionsAndAnswersByIdAndQuestionId = (
      userId: string,
      questionId: number
   ): Promise<ApiResponse<QuestionDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/FitToPractice/${userId}?questionType=${questionId}`);
   };

   const getQuestionHistoryById = (
      userId: string,
      questionId: number
   ): Promise<ApiResponse<AnswerHistoryDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/FitToPractice/${userId}/history/${questionId}`);
   };

   const saveAnswerById = (
      question: QuestionSaveDto
   ): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/FitToPractice`, JSON.stringify(question));
   };

   const saveAnswerListById = (
      userId: string,
      questions: QuestionDto[]
   ): Promise<ApiResponse<QuestionDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/FitToPractice/${userId}`, questions);
   };

   const saveAnswerListByIdAndQuestionId = (
      userId: string,
      questions: QuestionDto[],
      questionId: number
   ): Promise<ApiResponse<QuestionDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/FitToPractice/${userId}/${questionId}`, questions);
   };

   return {
      getQuestions,
      getQuestionsAndAnswersById,
      getQuestionsAndAnswersByIdAndQuestionId,
      getQuestionHistoryById,
      saveAnswerById,
      saveAnswerListById,
      saveAnswerListByIdAndQuestionId,
   };
};

export default {
   create,
};
