import { put, call } from 'redux-saga/effects';
import { MetadataActions } from '../Redux/MetadataRedux';
import { MetadataServiceType } from '../Services/MetadataService';
import { ApiResponse } from 'apisauce';
import { Metadata } from 'Models/Metadata';
import { MetadataType } from 'Util/Constants/MetadataType';
import { MetadataTypeModel } from 'Models/Metadata/MetadataByType';

interface MetadataParams {
   type: string;
   token: string;
   metadataType: MetadataType;
}

export function* getMetadata(
   metadataService: Readonly<MetadataServiceType>,
   action: Readonly<MetadataParams>
): Generator {
   const metadataResponse = yield call(metadataService.getMetadata);
   const response = metadataResponse as ApiResponse<Metadata>;

   if (response.status && response.ok && response.data) {
      yield put(MetadataActions.getMetadataSuccess(response.data));
   } else {
      yield put(
         MetadataActions.getMetadataFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getMetadataByType(
   metadataService: Readonly<MetadataServiceType>,
   action: Readonly<MetadataParams>
): Generator {
   const { metadataType } = action;
   const metadataResponse = yield call(
      metadataService.getMetadataByType,
      metadataType
   );
   const response = metadataResponse as ApiResponse<MetadataTypeModel>;

   if (response.status && response.ok && response.data) {
      yield put(MetadataActions.getMetadataByTypeSuccess(response.data));
   } else {
      yield put(
         MetadataActions.getMetadataByTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
