import React, { useState } from 'react';
import './FileUploadSection.scss';
import { Button } from 'react-md/lib/Buttons';
import { FontIcon } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import {
   ApplicationActions,
   ApplicationTypes,
   selectRegistrationFileDownload,
} from 'State/Redux/ApplicationRedux';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';

interface Props {
   fileName: string;
   fileId: number;
   appId: number;
   isApproved: boolean;
   removeFile: () => void;
}

const FileApplicationApproval = ({
   fileName,
   fileId,
   appId,
   isApproved,
   removeFile,
}: Readonly<Props>): JSX.Element => {
   const dispatch = useDispatch();
   const [hasBeenDownload, setHasBeenDownload] = React.useState<
      string | undefined
   >(undefined);
   const fileDownload = useSelector(selectRegistrationFileDownload);
   const [reloadFiles, setReloadFiles] = useState<boolean>(false);
   const [newTab, setNewTab] = useState(false);

   const isSuccessAccept = useSelector(
      selectIsSuccess([ApplicationTypes.PUT_ACCEPT_FILE_REQUEST])
   );

   React.useEffect(() => {
      if (reloadFiles && isSuccessAccept) {
         dispatch(
            ApplicationActions.getRegistrationFilesByApplicationRequest(
               appId.toString()
            )
         );
         setReloadFiles(false);
      }
   }, [reloadFiles, isSuccessAccept, appId, dispatch]);

   React.useEffect(() => {
      if (hasBeenDownload && fileDownload) {
         FileSaver.saveAs(fileDownload, hasBeenDownload);
         if (!newTab) {
            toast.success(`File ${hasBeenDownload} has been download`);
         }
         const file = fileDownload !== undefined ? fileDownload : new Blob();
         if (file) {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setNewTab(false);
         }

         setHasBeenDownload(undefined);
      }
   }, [hasBeenDownload, fileDownload, newTab]);

   const downloadFile = (fileId: number, filename: string): void => {
      dispatch(
         ApplicationActions.getRegistrationFileDownloadRequest(
            fileId.toString()
         )
      );
      setHasBeenDownload(filename);
      setNewTab(true);
   };

   const acceptFile = (
      fileId: number,
      filename: string,
      appId: number
   ): void => {
      dispatch(
         ApplicationActions.putAcceptFileRequest(
            fileId.toString(),
            appId.toString()
         )
      );
      setHasBeenDownload(filename);
      setReloadFiles(true);
   };

   return (
      <div className="md-grid file-box">
         <div className="md-cell--middle md-cell--1">
            <FontIcon>{'attach_file'}</FontIcon>
         </div>
         <div className="md-cell--middle md-cell--6 alignment">
            <button
               className="btn-link-style"
               onClick={(): void => downloadFile(fileId, fileName)}
            >
               {fileName}
            </button>
         </div>
         <div className="file-upload-buttons">
            <Button
               flat
               className={
                  isApproved ? 'file-accepted-button' : 'file-accept-button'
               }
               onClick={(): void => acceptFile(fileId, fileName, appId)}
            >
               ACCEPT
            </Button>

            <Button flat className="file-decline-button" onClick={removeFile}>
               DELETE
            </Button>
         </div>
      </div>
   );
};

export default FileApplicationApproval;
