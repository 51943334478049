import React from 'react';
import UserCard from 'Components/Common/UserCard/UserCard';

const LicencesPanel = (): JSX.Element => {
   return (
      <div className="md-grid md-cell--12">
         <UserCard showContactDetails />
      </div>
   );
};

export default LicencesPanel;
