import React from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { SelectField } from 'react-md/lib/SelectFields';
import { Button } from 'react-md/lib/Buttons';
import {
   ContactSearchQuery,
   DefaultSearchModel,
   validateSearchQuery,
   isValidSearchQuery,
   filterToValidQuery,
} from 'Models/Contact/Dto/ContactSearchQuery';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import {
   useEthnicityTypeList,
   useGenderTypeList,
   useLicenceTypeList,
   useRegistrationTypeList,
} from 'Util/Helpers/Metadata';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { ContactTypes } from 'State/Redux/ContactRedux';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   getInputValidationClassName,
   isNullOrWhiteSpace,
} from 'Util/Helpers/Validation';
import DateInput from 'Components/Common/DateInput/DateInput';

interface ContactSearchPanelProps {
   query: ContactSearchQuery;
   setQuery: (query: ContactSearchQuery) => void;
   isSearching: boolean;
   setIsSearching: (isSearching: boolean) => void;
   reset: () => void;
}

const ContactSearchPanel = ({
   query,
   setQuery,
   isSearching,
   setIsSearching,
   reset,
}: Readonly<ContactSearchPanelProps>): JSX.Element => {
   const licenceTypes = useLicenceTypeList('Select Licence Type');
   const registrationTypes = useRegistrationTypeList(
      'Select Registration Type'
   );

   const genderTypes = useGenderTypeList('All');

   const enthnicityTypes = useEthnicityTypeList('All');

   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SEARCH_CONTACT_REQUEST])
   );

   const validation = validateSearchQuery(query);
   const filteredQuery = filterToValidQuery(query);
   const canSearch = isValidSearchQuery(filteredQuery);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="floating-center-title"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.licenceNumber
               )}
               type="search"
               value={query.licenceNumber}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, licenceNumber: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.licenceNumber}
               errorText="Invalid licence number"
            />
         </div>
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="floating-center-title"
               label="Contact Name(s)"
               lineDirection="center"
               placeholder="Contact Name(s)"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.name)}
               type="search"
               value={query.name}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, name: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.name}
               errorText="Invalid name"
            />
         </div>
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="floating-center-title"
               label="Company Name"
               lineDirection="center"
               placeholder="Company Name"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.companyName
               )}
               type="search"
               value={query.companyName}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, companyName: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.companyName}
               errorText="Invalid company name"
            />
         </div>
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="floating-center-title"
               label="Email"
               lineDirection="center"
               placeholder="Email"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.email)}
               type="search"
               value={query.email}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, email: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.email}
               errorText="Invalid email address"
            />
         </div>
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="floating-center-title"
               label="Phone"
               lineDirection="center"
               placeholder="Phone"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.phone)}
               type="search"
               value={query.phone}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, phone: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.phone}
               errorText="Invalid phone number"
            />
         </div>
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="floating-center-title"
               label="Postal Code"
               lineDirection="center"
               placeholder="Postal Code"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.postcode
               )}
               type="search"
               value={query.postcode}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, postcode: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.postcode}
               errorText="Invalid post code"
            />
         </div>
         <div className="md-cell md-cell--4">
            <SelectField
               id="select-field-6"
               label="Licence Type"
               placeholder="Select Licence Type"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.licenceType
               )}
               menuItems={licenceTypes}
               position={SelectField.Positions.BELOW}
               value={query.licenceType}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, licenceType: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.licenceType}
               errorText="Invalid licence type"
               defaultValue=" "
            />
         </div>
         <div className="md-cell md-cell--4">
            <SelectField
               id="select-field-6"
               label="Registration Type"
               placeholder="Select Registration Type"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.registrationType
               )}
               menuItems={registrationTypes}
               position={SelectField.Positions.BELOW}
               value={query.registrationType}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, registrationType: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               defaultValue=" "
               error={!validation.registrationType}
               errorText="Invalid registration type"
            />
         </div>
         <div className="md-cell md-cell--4">
            <DateInput
               id="date-of-birth-search"
               label="Date of Birth"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.dateOfBirth
               )}
               onChange={(date): void => {
                  setIsSearching(false);
                  setQuery({ ...query, dateOfBirth: date.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.dateOfBirth}
               errorText="Please enter the Date of Birth"
               maxDate={new Date()}
               value={query.dateOfBirth ? query.dateOfBirth : undefined}
               defaultValue=" "
            />
         </div>
         <div className="md-cell md-cell--4">
            <SelectField
               id="select-field-gender"
               label="Gender"
               placeholder="Select Gender"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.gender)}
               menuItems={genderTypes}
               position={SelectField.Positions.BELOW}
               value={query.gender ? query.gender : ' '}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     gender: isNullOrWhiteSpace(value) ? null : Number(value),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               defaultValue=" "
               error={!validation.registrationType}
               errorText="Invalid gender type"
            />
         </div>
         <div className="md-cell md-cell--4">
            <SelectField
               id="select-field-ethnicity"
               label="Ethnicity"
               placeholder="Select Ethnicity"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.ethnicity
               )}
               menuItems={enthnicityTypes}
               position={SelectField.Positions.BELOW}
               value={query.ethnicity ? query.ethnicity : ' '}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     ethnicity: isNullOrWhiteSpace(value)
                        ? null
                        : Number(value),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               defaultValue=" "
               error={!validation.registrationType}
               errorText="Invalid ethnicity type"
            />
         </div>
         <div className="md-cell md-cell--12">
            <LoadingButton
               flat
               primary
               swapTheming
               isLoading={isLoading && isSearching}
               disabled={!canSearch}
               className="md-cell md-cell--2 md-cell--8-offset search-button"
               onClick={(): void => {
                  setIsSearching(true);
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
            >
               Search
            </LoadingButton>
            <Button
               flat
               secondary
               className="md-cell md-cell--2 red-btn search-button"
               swapTheming
               onClick={(): void => {
                  setIsSearching(false);
                  setQuery(DefaultSearchModel);
                  reset();
               }}
               onKeyUp={(event): void => {
                  if (!isEnterKeyPress(event)) return;

                  setIsSearching(false);
                  setQuery(DefaultSearchModel);
                  reset();
               }}
            >
               Reset
            </Button>
         </div>
      </div>
   );
};

export default ContactSearchPanel;
