import moment from 'moment-timezone';
import { isNumber } from './Validation';
import {
   IS_SEARCH_LICENCE_NUMBER,
   IS_VALID_SEARCH_TEXT,
   IS_ALPHANUMERIC_TEXT,
} from 'Util/Constants/Regex';

export const isValidSearchLicenceNumber = (licenceNumber: string): boolean => {
   if (!licenceNumber) return false;

   return IS_SEARCH_LICENCE_NUMBER.test(licenceNumber.trim());
};

// applies for string search values
export const isValidSearchText = (text: string): boolean => {
   if (!text) return false;

   return IS_VALID_SEARCH_TEXT.test(text.trim());
};

export const isValidAlphanumericText = (text: string): boolean => {
   if (!text) return false;

   return IS_ALPHANUMERIC_TEXT.test(text.trim());
};

export const isValidSearchDate = (text: string): boolean => {
   if (!text) return false;

   return moment(text).isValid();
};

export const isValidQuickSearch = (value: string): boolean => {
   if (!value) return false;

   const trimmed = value.trim();
   if (!trimmed) return false;

   if (isValidSearchLicenceNumber(value)) return true;

   // don't want numbers to be interpreted as names
   // as no one is named Mr. 1234567
   if (isNumber(value)) return false;

   const VALID_NAME_LENGTH = 3;
   return trimmed.length >= VALID_NAME_LENGTH;
};
