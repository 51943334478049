import React, { useEffect, useState } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import {
   ContactActions,
   ContactTypes,
   selectContact,
   selectPaymentsSearchResults,
} from 'State/Redux/ContactRedux';
import SearchPanel from './Panels/SearchPanel';
import ResultsPanel from './Panels/ResultsPanel';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { useSelector, useDispatch } from 'react-redux';
import {
   DefaultSearchModel,
   isValidSearchQuery,
   filterToValidQuery,
} from 'Models/Contact/Dto/PaymentSearchQuery';
import './InvoicesPaymentsSearch.scss';
import { Button } from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface InvoicesPaymentsSearchProps {
   setAdvancedSearch: (setting: boolean) => void;
}

const InvoicesPaymentsSearch = ({
   setAdvancedSearch,
}: Readonly<InvoicesPaymentsSearchProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const payments = useSelector(selectPaymentsSearchResults);
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SEARCH_PAYMENTS_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ContactTypes.SEARCH_PAYMENTS_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);
   const [searchResults, setSearchResults] = useState<
      readonly PaymentSearchResult[]
   >([]);
   const [searchButtonClicked, setSearchButtonClicked] = useState(false);
   const [searchModel, setSearchModel] = useState(DefaultSearchModel);

   const searchPayments = (): void => {
      const filteredQuery = filterToValidQuery(searchModel);
      if (searchButtonClicked && isValidSearchQuery(filteredQuery)) {
         dispatch(ContactActions.searchPaymentsRequest(filteredQuery));
         setHasSearched(true);
      } else if (!hasSearched) {
         setSearchButtonClicked(false);
      }
   };

   useEffect(searchPayments, [searchButtonClicked, dispatch, searchModel]);

   const hasResults = isSuccess && hasSearched && payments !== searchResults;
   if (hasResults) setSearchResults(payments);

   const panelTitle =
      hasSearched && searchResults
         ? `Results (${searchResults.length})`
         : 'Results';

   return (
      <>
         <div className="back-button">
            <Button
               flat
               primary
               iconChildren="arrow_back"
               onClick={(): void => {
                  setAdvancedSearch(false);
                  setHasSearched(false);
                  setSearchButtonClicked(false);
                  setSearchResults([]);
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  setAdvancedSearch(false);
                  setHasSearched(false);
                  setSearchButtonClicked(false);
                  setSearchResults([]);
               }}
            >
               Back
            </Button>
         </div>
         <ExpansionList className="md-cell md-cell--12">
            <ExpansionPanel
               label="Invoice/Payments History"
               footer={null}
               className="no-margin-top"
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <SearchPanel
                  contact={contact}
                  query={searchModel}
                  setQuery={setSearchModel}
                  isSearching={isLoading}
                  setIsSearching={setSearchButtonClicked}
                  reset={(): void => {
                     setHasSearched(false);
                     setSearchButtonClicked(false);
                     setSearchResults([]);
                  }}
               />
            </ExpansionPanel>
            {hasSearched ? (
               <div className="invoice-payments-loader">
                  <ExpansionPanel
                     label={panelTitle}
                     footer={null}
                     expanded
                     expanderIcon={<></>}
                     onExpandToggle={FN_EMPTY_VOID}
                  >
                     <ResultsPanel
                        payments={searchResults}
                        isLoading={isLoading}
                     />
                  </ExpansionPanel>
               </div>
            ) : (
               <>
                  <div className="invoice-payments-padding"></div>
               </>
            )}
         </ExpansionList>
      </>
   );
};

export default React.memo(InvoicesPaymentsSearch);
