import CourseCriteriaDto, {
   DefaultCourseCriteria,
} from 'Models/Examination/Dto/CourseCriteriaDto';

interface ContactExamEligibilityDto {
   contactExamEligibilityId: number;
   contactId: number | null;
   courseCriteriaId: number | null;
   isCriteriaMet: boolean;
   isError: boolean;

   courseCriteria: CourseCriteriaDto;
}

type ContactExamEligibilityDtoReadonly = Readonly<ContactExamEligibilityDto>;

export default ContactExamEligibilityDtoReadonly;

export const DefaultContactExamEligibilityDtoModel: ContactExamEligibilityDto = {
   contactExamEligibilityId: 0,
   contactId: 0,
   courseCriteriaId: 0,
   isCriteriaMet: false,
   isError: false,

   courseCriteria: DefaultCourseCriteria,
};
