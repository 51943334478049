import React from 'react';

interface TableLoadingComponentProps {
   className: string;
   loading: boolean;
}

const TableLoadingComponent = ({
   className,
   loading,
}: Readonly<TableLoadingComponentProps>): JSX.Element => {
   return (
      <div className={`-loading ${loading ? '-active' : ''} ${className}`}>
         <div className="-loading-inner">
            <img src="/images/loading.svg" alt="loading" />
         </div>
      </div>
   );
};

export default TableLoadingComponent;
