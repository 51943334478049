import React from 'react';
import { ConditionCell } from './Conditions/ConditionCell';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import { isCriteriaMet } from 'Util/Helpers/Application';

interface LicenceCriteriaViewProps {
   isEditing: boolean;
   application: ApplicationEdit;
   updateApplication: (application: ApplicationEdit) => void;
   conditions: string[];
}

export const LicenceCriteriaView = ({
   isEditing,
   application,
   updateApplication,
   conditions,
}: Readonly<LicenceCriteriaViewProps>): JSX.Element => {
   const criteriaConditions = application.conditions.filter((c): boolean =>
      conditions.includes(c.conditionCode)
   );

   const isMet = isCriteriaMet(conditions, criteriaConditions);
   const criteriaStatusClass = isMet ? 'text-green' : 'text-red';
   const criteriaIcon = isMet ? '✔' : '✘';
   const title = `Licence Criteria`;

   if (!conditions.length) return <> </>;
   const conditionCells = criteriaConditions.map(
      (ac): JSX.Element => {
         return (
            <div key={ac.conditionCode} className="md-cell md-cell--12">
               <ConditionCell
                  application={application}
                  updateApplication={updateApplication}
                  appCondition={ac}
                  disabled={!isEditing || ac.isSystemControlled}
               />
            </div>
         );
      }
   );

   return (
      <>
         <h2 className="criteria-header">
            <span className={criteriaStatusClass}>
               {title} {criteriaIcon}
            </span>
         </h2>
         {conditionCells}
      </>
   );
};
