import React, { useState, useEffect } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import DateInput from 'Components/Common/DateInput/DateInput';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { Checkbox } from 'react-md/lib/SelectionControls';
import {
   DefaultGlobalNotification,
   validateUpdate,
   filterToValidUpdate,
   isValidUpdate,
} from 'Models/Notifications/GlobalNotification';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectGlobalNotification,
   GlobalNotificationActions,
   GlobalNotificationTypes,
} from 'State/Redux/GlobalNotificationRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import LoadingButton from 'Components/Common/LoadingButton';
import { toast } from 'react-toastify';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { Authorized } from 'Components/Common/Authorized';
import { Permission } from 'Util/Constants/Permission';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

export const DESCRIPTION_MAX_LENGTH = 320;
export const TITLE_MAX_LENGTH = 120;
export const DESCRIPTION_ROW_COUNT = 2;

const NOTIFICATION_PERMISSION: Permission = 'NotificationMaintenance.Update';

const GlobalNotification = (): JSX.Element => {
   const globalNotification = useSelector(selectGlobalNotification);
   const isLoading = useSelector(
      selectIsLoading([GlobalNotificationTypes.GET_GLOBAL_NOTIFICATION_REQUEST])
   );
   const isUpdating = useSelector(
      selectIsLoading([
         GlobalNotificationTypes.UPDATE_GLOBAL_NOTIFICATION_REQUEST,
      ])
   );

   const dispatch = useDispatch();

   const [notification, setNotification] = useState(DefaultGlobalNotification);
   const [formChange, setFormChange] = useState(false);
   const [hasUpdated, setHasUpdated] = useState(false);
   const validation = validateUpdate(notification, formChange);
   const filteredQuery = filterToValidUpdate(notification);
   const isValid = isValidUpdate(filteredQuery);

   useEffect((): void => {
      if (!globalNotification.globalNotificationId) {
         dispatch(GlobalNotificationActions.getGlobalNotificationRequest());
      } else {
         setNotification(globalNotification);
      }
   }, [dispatch, globalNotification]);

   useEffect((): void => {
      if (
         globalNotification !== notification &&
         globalNotification.globalNotificationId ===
            notification.globalNotificationId
      ) {
         setFormChange(true);
      } else {
         setFormChange(false);
      }
   }, [notification, globalNotification]);

   useEffect((): void => {
      if (hasUpdated && !isUpdating) {
         toast.success('Global notification updated!');
      }

      if (isUpdating) {
         setHasUpdated(true);
      }
   }, [isUpdating, hasUpdated]);

   const updateOnKeyPress = (
      keyPress: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (isEnterKeyPress(keyPress) && isValid) {
         dispatch(
            GlobalNotificationActions.updateGlobalNotificationRequest(
               filteredQuery
            )
         );
      }
   };

   return (
      <Authorized permissions={[NOTIFICATION_PERMISSION]} showWarning>
         <ExpansionList className="md-cell md-cell--12 find-tab">
            <ExpansionPanel
               label="Global Notification"
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--6">
                     <TextField
                        floating
                        id="floating-center-title"
                        label="Title"
                        lineDirection="center"
                        placeholder="Title"
                        className="md-cell md-cell--12"
                        inputClassName={getInputValidationClassName(
                           !validation.title
                        )}
                        error={!validation.title}
                        errorText="Please enter a valid title"
                        maxLength={TITLE_MAX_LENGTH}
                        value={notification.title}
                        onChange={(val): void => {
                           setNotification({
                              ...notification,
                              title: val.toString(),
                           });
                        }}
                        onKeyUp={updateOnKeyPress}
                     />
                     <TextField
                        floating
                        id="floating-center-title"
                        label="Description"
                        lineDirection="center"
                        placeholder="Description"
                        className="md-cell md-cell--12"
                        inputClassName={getInputValidationClassName(
                           !validation.description
                        )}
                        error={!validation.description}
                        errorText="Please enter a valid description"
                        value={notification.description}
                        maxLength={DESCRIPTION_MAX_LENGTH}
                        rows={DESCRIPTION_ROW_COUNT}
                        onChange={(val): void => {
                           setNotification({
                              ...notification,
                              description: val.toString(),
                           });
                        }}
                        onKeyUp={updateOnKeyPress}
                     />
                     <Checkbox
                        id="checkbox-force-logout"
                        name="simple-checkboxes[]"
                        label="Force Logout"
                        value="material-design"
                        checked={notification.forceLogout}
                        onChange={(e): void => {
                           setNotification({
                              ...notification,
                              forceLogout: e,
                           });
                        }}
                        defaultChecked={notification.forceLogout}
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <DateInput
                        id="appointment-date-auto"
                        label="Start Date"
                        className="md-cell md-cell--12"
                        inputClassName={getInputValidationClassName(
                           !validation.startTime
                        )}
                        error={!validation.startTime}
                        errorText="Please enter a start date that is not before the current date."
                        value={notification.startTime}
                        onChange={(date): void => {
                           setNotification({
                              ...notification,
                              startTime: date,
                           });
                        }}
                        onKeyUp={updateOnKeyPress}
                     />
                     <DateInput
                        id="appointment-date-auto"
                        label="End Date"
                        className="md-cell md-cell--12"
                        inputClassName={getInputValidationClassName(
                           !validation.endTime
                        )}
                        error={!validation.endTime}
                        errorText="Please enter a valid end date"
                        value={notification.endTime}
                        onChange={(date): void => {
                           setNotification({
                              ...notification,
                              endTime: date,
                           });
                        }}
                        onKeyUp={updateOnKeyPress}
                     />
                     <LoadingButton
                        flat
                        primary
                        className="md-cell md-cell--3 md-cell--6-offset search-button"
                        swapTheming
                        disabled={!isValid}
                        onClick={(): void => {
                           dispatch(
                              GlobalNotificationActions.updateGlobalNotificationRequest(
                                 { ...notification }
                              )
                           );
                        }}
                        onKeyUp={updateOnKeyPress}
                        isLoading={isUpdating}
                     >
                        Update
                     </LoadingButton>

                     <LoadingButton
                        flat
                        secondary
                        className="md-cell md-cell--3 red-btn search-button"
                        swapTheming
                        onClick={(): void => {
                           dispatch(
                              GlobalNotificationActions.getGlobalNotificationRequest()
                           );
                        }}
                        onKeyUp={(keyPress): void => {
                           if (isEnterKeyPress(keyPress)) {
                              dispatch(
                                 GlobalNotificationActions.getGlobalNotificationRequest()
                              );
                           }
                        }}
                        isLoading={isLoading}
                     >
                        Reset
                     </LoadingButton>
                  </div>
               </div>
            </ExpansionPanel>
         </ExpansionList>
      </Authorized>
   );
};

export default GlobalNotification;
