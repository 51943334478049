import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import SelectField from 'react-md/lib/SelectFields';
import { Complaint, validateComplaint } from 'Models/Complaint/Complaint';
import { ComplaintActions, ComplaintTypes } from 'State/Redux/ComplaintRedux';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import {
   ContactActions,
   ContactTypes,
   selectContact,
} from 'State/Redux/ContactRedux';
import { isValidRegistrationNumber } from 'Util/Helpers/RegistrationNumber';
import {
   getDisplayRegistrationNumber,
   getDisplayContactName,
} from 'Util/Helpers/Contact';
import { toast } from 'react-toastify';
import { useComplaintStatusList } from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface EditPanelProps {
   setIsEditing: (isEditing: boolean) => void;
   complaint: Complaint;
}

const DESCRIPTION_MAX_LENGTH = 1000;

const EditPanel = ({
   complaint,
   setIsEditing,
}: Readonly<EditPanelProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const isLoading = useSelector(
      selectIsLoading([
         ContactTypes.GET_CONTACT_BY_ID_REQUEST,
         ContactTypes.GET_CONTACT_BY_REGISTRATION_NUMBER_REQUEST,
         ComplaintTypes.UPDATE_COMPLAINT_REQUEST,
      ])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ComplaintTypes.UPDATE_COMPLAINT_REQUEST])
   );
   const dispatch = useDispatch();

   const [complaintModel, setComplaintModel] = useState(complaint);
   const [saveClicked, setSaveClicked] = useState(false);
   if (saveClicked && isSuccess) setIsEditing(false);

   const complaintContactId = (complaint && complaint.contactId) || 0;
   const stateContactId = (contact && contact.contactId) || 0;
   const requiresLoadingContact = complaintContactId !== stateContactId;
   const [loadedInitialContact, setLoadedInitialContact] = useState(
      !requiresLoadingContact
   );

   const registrationNumber = getDisplayRegistrationNumber(contact);

   const [inputRegistrationNumber, setRegistrationNumber] = useState(
      registrationNumber
   );
   const [searchedRegNumber, setSearchedRegNumber] = useState(
      registrationNumber
   );

   // pull in when initialising
   useEffect((): void => {
      if (loadedInitialContact) return;

      if (requiresLoadingContact && !isLoading && complaintModel.contactId) {
         dispatch(
            ContactActions.getContactByIdRequest(
               complaintModel.contactId.toString()
            )
         );
         setLoadedInitialContact(true);
      }
   }, [
      loadedInitialContact,
      requiresLoadingContact,
      isLoading,
      complaintModel.contactId,
      dispatch,
   ]);

   // pull in when updating
   useEffect((): void => {
      if (searchedRegNumber && isValidRegistrationNumber(searchedRegNumber))
         dispatch(
            ContactActions.getContactByRegistrationNumberRequest(
               searchedRegNumber
            )
         );
   }, [searchedRegNumber, dispatch]);

   const complaintStatuses = useComplaintStatusList(
      'Select Disciplinary Matter Status'
   );

   const validation = validateComplaint(complaintModel);
   const isValidContact =
      isValidRegistrationNumber(inputRegistrationNumber) &&
      inputRegistrationNumber === searchedRegNumber &&
      !!contact;
   const contactName = isValidContact ? getDisplayContactName(contact) : '';

   const isValidForm =
      isValidContact && Object.values(validation).every((v): boolean => !!v);

   const onSave = useCallback((): void => {
      let contactId = complaintModel.contactId;
      if (
         searchedRegNumber &&
         isValidRegistrationNumber(searchedRegNumber) &&
         contact
      )
         contactId = contact.contactId;

      dispatch(
         ComplaintActions.updateComplaintRequest({
            ...complaintModel,
            contactId: contactId,
         })
      );
      toast.success(`Disciplinary Matter #${complaint.complaintId} updated!`);
      setSaveClicked(true);
   }, [
      complaint.complaintId,
      contact,
      complaintModel,
      searchedRegNumber,
      dispatch,
   ]);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="disciplinary-matter-number"
               className="md-cell md-cell--12"
               label="Disciplinary Matter #"
               lineDirection="center"
               value={complaintModel.complaintId}
               disabled
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               floating
               id="select-field-6"
               lineDirection="center"
               className="md-cell md-cell--12"
               label="Disciplinary Matter Status"
               placeholder="Disciplinary Matter Status"
               inputClassName={getInputValidationClassName(
                  !validation.statusCode
               )}
               menuItems={complaintStatuses}
               position={SelectField.Positions.BELOW}
               value={complaintModel.statusCode}
               onChange={(val): void => {
                  setComplaintModel({
                     ...complaintModel,
                     statusCode: val.toString(),
                  });
               }}
               defaultValue=" "
               required
               error={!validation.statusCode}
               errorText="Please enter a valid status"
            />
         </div>

         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="licence-number"
               className="md-cell md-cell--12"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               inputClassName={getInputValidationClassName(!isValidContact)}
               value={inputRegistrationNumber}
               onChange={(val): void => {
                  setRegistrationNumber(val.toString());
               }}
               onKeyUp={(keyPress): void => {
                  if (
                     isEnterKeyPress(keyPress) &&
                     isValidRegistrationNumber(inputRegistrationNumber)
                  )
                     setSearchedRegNumber(inputRegistrationNumber);
               }}
               onBlur={(): void => {
                  if (isValidRegistrationNumber(inputRegistrationNumber))
                     setSearchedRegNumber(inputRegistrationNumber);
               }}
               required
               error={!isValidContact}
               errorText="Please enter a valid licence number"
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="respondent-name"
               className="md-cell md-cell--12"
               label="Respondent Name"
               lineDirection="center"
               placeholder="Respondent Name"
               value={contactName}
               disabled
            />
         </div>

         <div className="md-cell md-cell--6">
            <DateInput
               id="add-start-date"
               className="md-cell md-cell--12"
               label="Start Date"
               inputClassName={getInputValidationClassName(
                  !validation.startDate
               )}
               value={complaintModel.startDate}
               onChange={(date): void => {
                  setComplaintModel({
                     ...complaintModel,
                     startDate: date,
                  });
               }}
               required
               error={!validation.startDate}
               errorText="Please enter a valid start date"
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="add-end-date"
               className="md-cell md-cell--12"
               label="End Date"
               inputClassName={getInputValidationClassName(!validation.endDate)}
               value={complaintModel.endDate}
               onChange={(date): void => {
                  setComplaintModel({
                     ...complaintModel,
                     endDate: date,
                  });
               }}
               required
               error={!validation.endDate}
               errorText="Please enter a valid end date"
            />
         </div>

         <div className="md-cell md-cell--12">
            <TextField
               id="complaint-description"
               className="md-cell md-cell--12"
               label="Description"
               rows={4}
               lineDirection="center"
               placeholder="Please enter a description"
               maxLength={DESCRIPTION_MAX_LENGTH}
               inputClassName={getInputValidationClassName(
                  !validation.description
               )}
               value={complaintModel.description}
               onChange={(val): void => {
                  setComplaintModel({
                     ...complaintModel,
                     description: val.toString(),
                  });
               }}
               error={!validation.description}
               errorText="Please enter a valid description"
            />
         </div>

         <div className="md-cell md-cell--12">
            <Button
               flat
               primary
               swapTheming
               className="md-cell md-cell--2 md-cell--8-offset edit-matter-button"
               disabled={!isValidForm}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress) || !isValidForm) return;
                  onSave();
               }}
               onClick={(): void => {
                  if (!isValidForm) return;
                  onSave();
               }}
            >
               Save
            </Button>
            <Button
               flat
               swapTheming
               secondary
               className="md-cell md-cell--2 red-btn"
               onClick={(): void => {
                  setIsEditing(false);
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) setIsEditing(false);
               }}
            >
               Cancel
            </Button>
         </div>
      </div>
   );
};

export default EditPanel;
