import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useDispatch, useSelector } from 'react-redux';
import './AddLicenceType.scss';
import LicenceTypeResultsPanel from './Panels/LicenceTypeResultsPanels';
import {
   selectLicenceTypes,
   LicenceTypeActions,
   LicenceTypeTypes,
} from 'State/Redux/LicenceTypeRedux';

const ViewSystemParameter = (): JSX.Element => {
   const searchResults = useSelector(selectLicenceTypes);
   const isLoading = useSelector(
      selectIsLoading([LicenceTypeTypes.GET_LICENCE_TYPES_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   useEffect((): void => {
      dispatch(LicenceTypeActions.getLicenceTypesRequest());
      setHasSearched(true);
   }, [dispatch]);

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
            >
               <LicenceTypeResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default ViewSystemParameter;
