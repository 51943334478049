import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'react-md/lib/TextFields';
import {
   SupervisionActions,
   selectSupervisionSearchResult,
} from 'State/Redux/SupervisionRedux';

interface SupervisionInputLiteCellProps {
   onChange: (currentInput?: string) => void;
   onKeyUp: (event: React.KeyboardEvent<HTMLElement>) => void;
   disabled?: boolean;
   value?: string;
}

const SupervisionInputLite = ({
   onChange,
   onKeyUp,
   disabled,
   value,
}: Readonly<SupervisionInputLiteCellProps>): JSX.Element => {
   const dispatch = useDispatch();
   const supervisionSearchResult = useSelector(selectSupervisionSearchResult);
   const [isLoading, setIsLoading] = useState<boolean>();
   const [
      supervisorRegistrationNumber,
      setSupervisorRegistrationNumber,
   ] = useState<string>(value ?? '');

   useEffect(() => {
      if (value === '') {
         setSupervisorRegistrationNumber(value);
      }
   }, [value]);

   useEffect(() => {
      if (
         supervisorRegistrationNumber &&
         supervisorRegistrationNumber !==
            supervisionSearchResult?.registrationNumber.toString()
      ) {
         dispatch(SupervisionActions.searchSupervisorReset());
         setIsLoading(true);
         dispatch(
            SupervisionActions.searchSupervisorRequest(
               supervisorRegistrationNumber
            )
         );
      }
   }, [dispatch, supervisionSearchResult, supervisorRegistrationNumber]);

   useEffect(() => {
      if (isLoading && supervisionSearchResult) {
         setIsLoading(false);
         onChange(supervisionSearchResult.registrationNumber.toString());
      }
   }, [isLoading, onChange, supervisionSearchResult]);

   return (
      <div className="md-grid supervision-input">
         <div className="md-cell md-cell--4">
            <TextField
               floating
               id="supervisor-id"
               label="Supervisor #"
               lineDirection="center"
               placeholder="Supervisor #"
               className="md-cell md-cell--12"
               value={supervisorRegistrationNumber}
               onChange={(val): void =>
                  setSupervisorRegistrationNumber(val.toString())
               }
               disabled={disabled}
               onKeyUp={onKeyUp}
            />
         </div>
         <div className="md-cell md-cell--8">
            <TextField
               floating
               id="supervisor-name"
               label="Supervisor Name"
               lineDirection="center"
               placeholder="Supervisor Name"
               className="md-cell md-cell--12"
               value={
                  supervisionSearchResult && supervisorRegistrationNumber !== ''
                     ? supervisionSearchResult.fullName
                     : ''
               }
               disabled
            />
         </div>
      </div>
   );
};

export default SupervisionInputLite;
