import React, { useEffect } from 'react';
import ContactOverviewTab from './ContactOverview/ContactOverviewTab';
import ApplicationsTab from './Applications/ApplicationsTab';
import AssessmentsTab from './Assessments/AssessmentsTab';
import LicencesTab from './Licences/LicencesTab';
import InvoicesPaymentsTab from './InvoicesPayments/InvoicesPaymentsTab';
import SupervisionTab from './Supervision/SupervisionTab';
import ChangePasswordTab from './ChangePassword/ChangePasswordTab';
import RegistrationTab from './Registration/RegistrationTab';
import './Contact.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectContact,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import { RouteComponentProps } from 'react-router';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import Loading from 'Components/Common/Loading';
import { usePermissions } from 'Util/Helpers/Permissions';

// temporary component
const ContactNotFound = (): JSX.Element => {
   return <div>This is not the droid you&apos;re looking for.</div>;
};

const changePasswordLabel = 'Change Password';

let contactTabs: readonly RoutedTab[] = [];

interface MatchParams {
   contactId: string;
}

const Contact = ({ match }: RouteComponentProps<MatchParams>): JSX.Element => {
   const contact = useSelector(selectContact);
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.GET_CONTACT_BY_ID_REQUEST])
   );
   const isError = useSelector(
      selectIsError([ContactTypes.GET_CONTACT_BY_ID_REQUEST])
   );

   const contactOverviewTab = usePermissions(['Contact.Read'])
      ? {
           label: 'Contact Overview',
           route: '',
           component: ContactOverviewTab,
           pageTitle: 'Overview',
           exact: true,
        }
      : undefined;

   const licencesTab = usePermissions(['Licence.Read'])
      ? {
           label: 'Licences',
           route: '/licences',
           component: LicencesTab,
           pageTitle: 'Licences',
        }
      : undefined;

   const applicationsTab = usePermissions(['Application.Read'])
      ? {
           label: 'Applications',
           route: '/applications',
           component: ApplicationsTab,
           pageTitle: 'Applications',
        }
      : undefined;

   const registrationTab = usePermissions(['Registration.Read'])
      ? {
           label: 'Registration',
           route: '/registration',
           component: RegistrationTab,
           pageTitle: 'Registration',
        }
      : undefined;

   const supervisionTab = usePermissions(['Supervision.Read'])
      ? {
           label: 'Supervision',
           route: '/supervision',
           component: SupervisionTab,
           pageTitle: 'Supervision',
        }
      : undefined;

   const assessmentsTab = usePermissions(['Assessment.Read'])
      ? {
           label: 'Assessments',
           route: '/assessments',
           component: AssessmentsTab,
           pageTitle: 'Assessments',
        }
      : undefined;

   const invoicesTab = usePermissions(['Invoice.Read'])
      ? {
           label: 'Invoice/Payments',
           route: '/invoices',
           component: InvoicesPaymentsTab,
           pageTitle: 'Invoices/Payments',
        }
      : undefined;

   const changePasswordTab = usePermissions(['ChangePassword'])
      ? {
           label: changePasswordLabel,
           route: '/change-password',
           component: ChangePasswordTab,
           pageTitle: changePasswordLabel,
        }
      : undefined;

   const dispatch = useDispatch();

   if (contactTabs.length === 0) {
      if (contactOverviewTab)
         contactTabs = contactTabs.concat(contactOverviewTab);

      if (licencesTab) contactTabs = contactTabs.concat(licencesTab);

      if (applicationsTab) contactTabs = contactTabs.concat(applicationsTab);

      if (registrationTab) contactTabs = contactTabs.concat(registrationTab);

      if (supervisionTab) contactTabs = contactTabs.concat(supervisionTab);

      if (assessmentsTab) contactTabs = contactTabs.concat(assessmentsTab);

      if (invoicesTab) contactTabs = contactTabs.concat(invoicesTab);
   }

   const contactTabList = changePasswordTab
      ? contactTabs.concat(changePasswordTab)
      : contactTabs;

   const loadContact = (): void => {
      dispatch(ContactActions.getContactByIdRequest(match.params.contactId));
   };
   useEffect(loadContact, [match.params.contactId]);

   const hasContact = contact && contact.contactId > 0;

   const pageTitle = !hasContact
      ? ['Contact']
      : [
           contact.contactTypeCode === 'E'
              ? contact.companyName
              : contact.fullName,
        ];

   return (
      <Loading isLoading={isLoading} tryReload={loadContact} isError={isError}>
         {hasContact ? (
            <RoutedTabContainer
               tabs={contactTabList}
               baseUrl="/contact/:contactId"
               panelClassName="contact"
               pageTitle={pageTitle}
            />
         ) : (
            <ContactNotFound />
         )}
      </Loading>
   );
};

export default Contact;
