import React from 'react';
import {
   EditSuperviseeModalMode,
   ACCEPT_SUPERVISEE,
   DECLINE_SUPERVISEE,
} from './SupervisionModalMode';
import { useDispatch, useSelector } from 'react-redux';
import { Supervisee } from 'Models/Supervision/Data/Supervisee';
import LoadingButton from 'Components/Common/LoadingButton';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import {
   SupervisionTypes,
   SupervisionActions,
} from 'State/Redux/SupervisionRedux';
import { LicenceTypes } from 'State/Redux/LicenceRedux';
import Button from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { getCurrentDate } from 'Util/Helpers/Date';

interface EditSuperviseeProps {
   selectedSupervisee: Supervisee | null;
   updateShowModal: (showModal: boolean) => void;
   modalMode: EditSuperviseeModalMode;
}

const EditSupervisee = ({
   selectedSupervisee,
   updateShowModal,
   modalMode,
}: EditSuperviseeProps): JSX.Element => {
   const dispatch = useDispatch();
   const isLoading = useSelector(
      selectIsLoading([
         SupervisionTypes.GET_SUPERVISEE_HISTORY_REQUEST,
         LicenceTypes.GET_LICENCE_BY_ID_REQUEST,
      ])
   );

   let modalCopy;

   switch (modalMode) {
      case ACCEPT_SUPERVISEE: {
         modalCopy = (
            <div>Are you sure you want to accept this supervision request?</div>
         );
         break;
      }
      case DECLINE_SUPERVISEE: {
         modalCopy = (
            <div>
               Are you sure you want to decline this supervision request?
            </div>
         );
         break;
      }
      default:
         modalCopy = (
            <div>Are you sure you want to end this supervision request?</div>
         );
         break;
   }

   const submitButton = (): void => {
      if (selectedSupervisee) {
         switch (modalMode) {
            case ACCEPT_SUPERVISEE: {
               dispatch(
                  SupervisionActions.acceptSupervisionRequest(
                     selectedSupervisee.supervisionId
                  )
               );
               break;
            }
            case DECLINE_SUPERVISEE: {
               dispatch(
                  SupervisionActions.declineSupervisionRequest(
                     selectedSupervisee.supervisionId
                  )
               );
               break;
            }
            default:
               dispatch(
                  SupervisionActions.changeSupervisionEndDateRequest({
                     supervisionId: selectedSupervisee.supervisionId,
                     endDate: getCurrentDate(),
                     endImmediately: true,
                  })
               );
               break;
         }
         updateShowModal(false);
      }
   };

   return (
      <div>
         {modalCopy}
         <div className="md-grid md-cell--12">
            <LoadingButton
               isLoading={isLoading}
               onClick={(): void => {
                  if (selectedSupervisee && selectedSupervisee.supervisionId) {
                     submitButton();
                  }
               }}
               onKeyUp={(keyPress): void => {
                  if (
                     isEnterKeyPress(keyPress) &&
                     selectedSupervisee &&
                     selectedSupervisee.supervisionId
                  ) {
                     submitButton();
                  }
               }}
               flat
               swapTheming
               primary
            >
               Yes
            </LoadingButton>
            <Button
               onClick={(): void => {
                  updateShowModal(false);
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) updateShowModal(false);
               }}
               flat
               swapTheming
               secondary
            >
               Cancel
            </Button>
         </div>
      </div>
   );
};

export default EditSupervisee;
