import { put, call } from 'redux-saga/effects';
import { ComplaintActions } from '../Redux/ComplaintRedux';
import { ApiResponse } from 'apisauce';
import { ComplaintServiceType } from 'State/Services/ComplaintService';
import { Complaint } from 'Models/Complaint/Complaint';
import { ComplaintSearchResult } from 'Models/Complaint/ComplaintSearchResult';
import { ComplaintSearchQuery } from 'Models/Complaint/ComplaintSearchQuery';

interface ComplaintParams {
   type: string;
   token: string;
   id: string;
   query: ComplaintSearchQuery;
   complaint: Complaint;
}

export function* searchComplaints(
   complaintService: Readonly<ComplaintServiceType>,
   action: Readonly<ComplaintParams>
): Generator {
   const { query } = action;
   const complaintResponse = yield call(
      complaintService.getComplaintSearchResults,
      query
   );
   const response = complaintResponse as ApiResponse<
      readonly ComplaintSearchResult[]
   >;

   if (response.status && response.ok) {
      yield put(ComplaintActions.searchComplaintSuccess(response.data || []));
   } else {
      yield put(
         ComplaintActions.searchComplaintFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getComplaintById(
   complaintService: Readonly<ComplaintServiceType>,
   action: Readonly<ComplaintParams>
): Generator {
   const { id } = action;
   const complaintResponse = yield call(complaintService.getComplaintById, id);
   const response = complaintResponse as ApiResponse<Complaint>;

   if (response.status && response.ok) {
      yield put(ComplaintActions.getComplaintByIdSuccess(response.data));
   } else {
      yield put(
         ComplaintActions.getComplaintByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* createComplaint(
   complaintService: Readonly<ComplaintServiceType>,
   action: Readonly<ComplaintParams>
): Generator {
   const { complaint } = action;
   const complaintResponse = yield call(
      complaintService.createComplaint,
      complaint
   );
   const response = complaintResponse as ApiResponse<Complaint>;

   if (response.status && response.ok && response.data) {
      yield put(ComplaintActions.createComplaintSuccess(response.data));
   } else {
      yield put(
         ComplaintActions.createComplaintFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateComplaint(
   complaintService: Readonly<ComplaintServiceType>,
   action: Readonly<ComplaintParams>
): Generator {
   const { complaint } = action;
   const complaintResponse = yield call(
      complaintService.updateComplaint,
      complaint
   );
   const response = complaintResponse as ApiResponse<Complaint>;

   if (response.status && response.ok && response.data) {
      yield put(ComplaintActions.updateComplaintSuccess(response.data));
   } else {
      yield put(
         ComplaintActions.updateComplaintFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
