import { put, call } from 'redux-saga/effects';
import { LicenceActions } from '../Redux/LicenceRedux';
import { LicenceServiceType } from '../Services/LicenceService';
import { ApiResponse } from 'apisauce';
import Licence from 'Models/Licence/Data/LicenceGroup';
import LicenceDto from 'Models/Licence/Dto/LicenceDto';
import CreateLicenceDto from 'Models/Licence/Dto/CreateLicenceDto';
import UpdateLicenceDto from 'Models/Licence/Dto/UpdateLicenceDto';
import StatusDto from 'Models/Licence/Dto/StatusDto';
import DownloadFileItem from 'Models/BackOffice/DownloadFileItem';
import { LicenceSearchQuery } from 'Models/Licence/Dto/LicenceSearchQuery';

interface LicenceParams {
   type: string;
   token: string;
   id: string;
   data: CreateLicenceDto;
   updateData: UpdateLicenceDto;
   applicationIds: readonly number[];
   query: LicenceSearchQuery;
   exportType: string;
}

export function* getLicenceById(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const { id } = action;
   const licenceResponse = yield call(licenceService.getLicencesById, id);
   const response = licenceResponse as ApiResponse<Licence>;

   if (response.status && response.ok) {
      yield put(LicenceActions.getLicenceByIdSuccess(response.data));
   } else {
      yield put(
         LicenceActions.getLicenceByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getLicenceHistoryById(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const { id } = action;

   yield put(LicenceActions.getLicenceHistoryByIdReset());

   const licenceResponse = yield call(
      licenceService.getLicencesHistoryById,
      id
   );
   const response = licenceResponse as ApiResponse<LicenceDto>;

   if (response.status && response.ok) {
      yield put(LicenceActions.getLicenceHistoryByIdSuccess(response.data));
   } else {
      yield put(
         LicenceActions.getLicenceHistoryByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* createLicence(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const { data } = action;
   const licenceResponse = yield call(licenceService.createLicence, data);
   const response = licenceResponse as ApiResponse<StatusDto>;

   if (response.status && response.ok) {
      yield put(LicenceActions.createLicenceSuccess(response.data));
   } else {
      yield put(LicenceActions.createLicenceFailure(response.data));
   }
}

export function* updateLicence(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const { updateData } = action;
   const licenceResponse = yield call(licenceService.updateLicence, updateData);
   const response = licenceResponse as ApiResponse<StatusDto>;

   if (response.status && response.ok) {
      yield put(LicenceActions.updateLicenceSuccess(response.data));
   } else {
      yield put(LicenceActions.updateLicenceFailure(response.data));
   }
}

export function* exportLicenceCards(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const { exportType } = action;
   const licenceResponse = yield call(
      licenceService.exportLicenceCards,
      exportType
   );
   const response = licenceResponse as ApiResponse<DownloadFileItem[]>;

   if (response.status && response.ok) {
      yield put(LicenceActions.exportLicenceCardsSuccess(response.data));
   } else {
      yield put(
         LicenceActions.exportLicenceCardsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* annualRenewal(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const licenceResponse = yield call(licenceService.annualRenewal);
   const response = licenceResponse as ApiResponse<string>;

   if (response.status && response.ok) {
      yield put(LicenceActions.annualRenewalSuccess(response.data));
   } else {
      yield put(
         LicenceActions.annualRenewalFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* searchLicences(
   licenceService: Readonly<LicenceServiceType>,
   action: Readonly<LicenceParams>
): Generator {
   const { query } = action;
   const licenceResponse = yield call(licenceService.searchLicences, query);
   const response = licenceResponse as ApiResponse<LicenceDto[]>;

   if (response.status && response.ok) {
      yield put(LicenceActions.searchLicencesSuccess(response.data));
   } else {
      yield put(
         LicenceActions.searchLicencesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
