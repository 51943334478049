import React, { useCallback, useState, useEffect } from 'react';
import ApplicationSearchPanel from './Panels/ApplicationSearchPanel';
import ApplicationSearchResultsPanel from './Panels/ApplicationSearchResultsPanel';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { DefaultPagingModel, PagedQuery } from 'Models/Other/PagedQuery';
import { isEqual } from 'lodash-es';
import {
   DefaultSearchModel,
   filterToValidQuery,
   isValidSearchQuery,
   ApplicationSearchQuery,
} from 'Models/Application/Dto/ApplicationSearchQuery';
import { useSelector, useDispatch } from 'react-redux';
import {
   ApplicationTypes,
   ApplicationActions,
   selectApplicationSearchResults,
} from 'State/Redux/ApplicationRedux';
import { selectIsSuccess, selectIsLoading } from 'State/Redux/AsyncRedux';
import ApplicationSearchResult from 'Models/Application/Data/ApplicationSearchResult';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const DEFAULT_PAGING: PagedQuery = {
   ...DefaultPagingModel,
   orderBy: 'updatedDate',
   isDescending: true,
};

const ApplicationSearch = (): JSX.Element => {
   const [paging, setPaging] = useState(DefaultPagingModel);
   const [searchedPaging, setSearchedPaging] = useState(DefaultPagingModel);
   const updatePaging = useCallback(
      (updatedPaging: Readonly<PagedQuery>): void => {
         if (!isEqual(searchedPaging, updatedPaging)) setPaging(updatedPaging);
      },
      [searchedPaging]
   );

   const [searchModel, setSearchModel] = useState(DefaultSearchModel);
   const updateSearchModel = useCallback(
      (searchModel: ApplicationSearchQuery): void =>
         setSearchModel(searchModel),
      []
   );
   const [searchedModel, setSearchedModel] = useState(DefaultSearchModel);

   const [hasSearched, setHasSearched] = useState(false);
   const [
      localSearchResults,
      setSearchResults,
   ] = useState<ApplicationSearchResult | null>(null);
   const [searchButtonClicked, setSearchButtonClicked] = useState(false);
   const updateSearchButtonClicked = useCallback(
      (isClicked: boolean): void => setSearchButtonClicked(isClicked),
      []
   );

   const isLoading = useSelector(
      selectIsLoading([ApplicationTypes.SEARCH_APPLICATIONS_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ApplicationTypes.SEARCH_APPLICATIONS_REQUEST])
   );
   const searchResults = useSelector(selectApplicationSearchResults);

   const dispatch = useDispatch();
   const searchApplicationQuery = useCallback(
      (query: ApplicationSearchQuery): void => {
         dispatch(ApplicationActions.searchApplicationsRequest(query));
      },
      [dispatch]
   );

   const searchApplications = (): void => {
      const updatedPagingQuery = filterToValidQuery({
         ...searchedModel,
         ...paging,
      });
      const newQuery = filterToValidQuery({
         ...searchModel,
         ...DEFAULT_PAGING,
      });

      if (
         searchButtonClicked &&
         isValidSearchQuery(newQuery) &&
         !isEqual(searchedModel, newQuery)
      ) {
         searchApplicationQuery(newQuery);
         setHasSearched(true);
         setPaging(DEFAULT_PAGING);
         setSearchedPaging(DEFAULT_PAGING);
         setSearchedModel(newQuery);
         setSearchResults(null);
      } else if (
         !isEqual(searchedPaging, paging) &&
         isValidSearchQuery(updatedPagingQuery)
      ) {
         searchApplicationQuery({ ...searchedModel, ...paging });
         setHasSearched(true);
         setSearchedPaging(paging);
      }
      setSearchButtonClicked(false);
   };

   useEffect(searchApplications, [
      searchButtonClicked,
      searchApplicationQuery,
      searchModel,
      searchedModel,
      paging,
      searchedPaging,
   ]);

   const reset = useCallback((): void => {
      setHasSearched(false);
      setSearchButtonClicked(false);
      setSearchResults(null);
      setPaging(DEFAULT_PAGING);
      setSearchedPaging(DEFAULT_PAGING);
      setSearchModel(DefaultSearchModel);
      setSearchedModel(DefaultSearchModel);
   }, []);

   const hasResults =
      isSuccess && hasSearched && searchResults !== localSearchResults;
   if (hasResults) setSearchResults(searchResults);

   const isSearching = isLoading && hasSearched;

   const [resultsPanel, setResultsPanel] = useState<JSX.Element>(<></>);
   useEffect((): void => {
      if (!hasSearched) {
         setResultsPanel(<></>);
         return;
      }

      const totalCount =
         searchResults && searchResults.totalCount
            ? searchResults.totalCount
            : 0;
      const panelTitle = searchResults ? `Results (${totalCount})` : 'Results';

      const panel = (
         <ExpansionPanel
            label={panelTitle}
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <ApplicationSearchResultsPanel
               searchResults={localSearchResults}
               paging={paging}
               onPagingChanged={updatePaging}
               isLoading={isLoading}
            />
         </ExpansionPanel>
      );
      setResultsPanel(panel);
   }, [
      hasSearched,
      isLoading,
      localSearchResults,
      updatePaging,
      paging,
      searchResults,
   ]);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Application Search"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <ApplicationSearchPanel
               query={searchModel}
               setQuery={updateSearchModel}
               isSearching={isSearching}
               setIsSearching={updateSearchButtonClicked}
               reset={reset}
            />
         </ExpansionPanel>
         {resultsPanel}
      </ExpansionList>
   );
};

export default ApplicationSearch;
