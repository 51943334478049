import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import {
   selectPaymentResponse,
   PaymentActions,
   PaymentTypes,
} from 'State/Redux/PaymentRedux';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import Loading from 'Components/Common/Loading';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const PaymentFail = (): JSX.Element => {
   const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
   const paymentResponse = useSelector(selectPaymentResponse);
   const isLoading = useSelector(
      selectIsLoading([PaymentTypes.GET_PAYMENT_RESPONSE_REQUEST])
   );
   const dispatch = useDispatch();
   const history = useHistory();

   if (!requestSubmitted) {
      const queryStringValues = queryString.parse(history.location.search);

      if (
         queryStringValues &&
         queryStringValues.result &&
         !Array.isArray(queryStringValues.result) &&
         !isNullOrWhiteSpace(queryStringValues.result)
      ) {
         setRequestSubmitted(true);
         dispatch(
            PaymentActions.getPaymentResponseRequest(queryStringValues.result)
         );
      }
   }

   return (
      <Loading tryReload={FN_EMPTY_VOID} isLoading={isLoading} isError={false}>
         <ExpansionList className="md-cell md-cell--12">
            <ExpansionPanel
               label="Payment unsuccessful"
               className="md-expansion-panel-error"
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--12">
                     The transaction did not complete.{` `}
                     {paymentResponse &&
                     !isNullOrWhiteSpace(paymentResponse.referenceNumber)
                        ? `Reference Number: ${paymentResponse.referenceNumber}.`
                        : ''}
                     <br />
                     <br />
                     Please check credit card or bank detail before trying it
                     again.
                  </div>
               </div>
            </ExpansionPanel>
         </ExpansionList>
      </Loading>
   );
};
export default PaymentFail;
