import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import produce from 'immer';
import LicenceTypeReadonly from 'Models/LicenceType/Data/LienceType';
import LicenceType from 'Models/LicenceType/Data/LienceType';

/* ------------- Interfaces for ReduxSauce ------------- */
interface LicenceTypeState {
   licenceTypes: LicenceTypeReadonly[];
   licenceType: LicenceTypeReadonly | null;
}

export type LicenceTypeStateReadonly = Readonly<LicenceTypeState>;

interface TypeNames {
   UPDATE_LICENCE_TYPE_REQUEST: string;
   UPDATE_LICENCE_TYPE_SUCCESS: string;
   UPDATE_LICENCE_TYPE_FAILURE: string;

   GET_LICENCE_TYPES_REQUEST: string;
   GET_LICENCE_TYPES_SUCCESS: string;
   GET_LICENCE_TYPES_FAILURE: string;

   ADD_LICENCE_TYPE_REQUEST: string;
   ADD_LICENCE_TYPE_SUCCESS: string;
   ADD_LICENCE_TYPE_FAILURE: string;
}

type LicenceTypeTypeNames = Readonly<TypeNames>;

export interface LicenceTypeCreators {
   updateLicenceTypeRequest: (licenceType: LicenceTypeReadonly) => AnyAction;
   updateLicenceTypeSuccess: (data: LicenceTypeReadonly) => AnyAction;
   updateLicenceTypeFailure: (error: ApiError) => AnyAction;

   getLicenceTypesRequest: () => AnyAction;
   getLicenceTypesSuccess: (
      data: readonly LicenceType[] | undefined
   ) => AnyAction;
   getLicenceTypesFailure: (error: ApiError) => AnyAction;

   addLicenceTypeRequest: (licenceType: LicenceTypeReadonly) => AnyAction;
   addLicenceTypeSuccess: (data: LicenceTypeReadonly) => AnyAction;
   addLicenceTypeFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: LicenceTypeStateReadonly = {
   licenceType: null,
   licenceTypes: [],
};

/* ------------- Reducers ------------- */
const updateLicenceTypeSuccess = (
   state: LicenceTypeStateReadonly,
   action: AnyAction
): LicenceTypeStateReadonly => {
   return produce(state, (draftState): void => {
      const licenceType = action.data;
      if (draftState.licenceTypes) {
         const licenceTypeFound = draftState.licenceTypes.find(licType => {
            return licType.licenceTypeCode === licenceType.licenceTypeCode;
         });
         if (licenceTypeFound) {
            const index = draftState.licenceTypes.indexOf(licenceTypeFound);
            draftState.licenceTypes[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addLicenceTypeSuccess = (
   state: LicenceTypeStateReadonly,
   action: AnyAction
): LicenceTypeStateReadonly => {
   return { ...state, licenceType: action.data };
};

const getLicenceTypesSuccess = (
   state: LicenceTypeStateReadonly,
   action: AnyAction
): LicenceTypeStateReadonly => {
   return { ...state, licenceTypes: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   LicenceTypeTypeNames,
   LicenceTypeCreators
>('licenceType', {
   updateLicenceTypeRequest: ['licenceType'],
   updateLicenceTypeSuccess: ['data'],
   updateLicenceTypeFailure: ['error'],

   getLicenceTypesRequest: [],
   getLicenceTypesSuccess: ['data'],
   getLicenceTypesFailure: ['error'],

   addLicenceTypeRequest: ['licenceType'],
   addLicenceTypeSuccess: ['data'],
   addLicenceTypeFailure: ['error'],
});

export const LicenceTypeTypes = Types;
export const LicenceTypeActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_LICENCE_TYPE_SUCCESS]: updateLicenceTypeSuccess,
   [Types.GET_LICENCE_TYPES_SUCCESS]: getLicenceTypesSuccess,
   [Types.ADD_LICENCE_TYPE_SUCCESS]: addLicenceTypeSuccess,
});

/* ------------- Selectors ------------- */
export const selectLicenceType = (
   state: StateReadonly
): LicenceTypeReadonly | null => state.licenceType.licenceType;

export const selectLicenceTypes = (
   state: StateReadonly
): readonly LicenceTypeReadonly[] => state.licenceType.licenceTypes;
