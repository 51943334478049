import { put, call } from 'redux-saga/effects';
import { SystemParametersActions } from '../Redux/SystemParametersRedux';
import { SystemParametersServiceType } from '../Services/SystemParametersService';
import { ApiResponse } from 'apisauce';
import SystemParametersReadonly, {
   SystemParameters,
} from 'Models/SystemParameters/Data/SystemParameters';

interface SystemParametersParams {
   type: string;
   token: string;
   id: number;
   systemParameter: SystemParametersReadonly;
}

export function* updateSystemParameters(
   supervisionService: Readonly<SystemParametersServiceType>,
   action: Readonly<SystemParametersParams>
): Generator {
   const { systemParameter } = action;
   const supervisionResponse = yield call(
      supervisionService.updateSystemParameters,
      systemParameter
   );
   const response = supervisionResponse as ApiResponse<
      SystemParametersReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         SystemParametersActions.updateSystemParametersSuccess(response.data)
      );
   } else {
      yield put(
         SystemParametersActions.updateSystemParametersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addSystemParameters(
   supervisionService: Readonly<SystemParametersServiceType>,
   action: Readonly<SystemParametersParams>
): Generator {
   const { systemParameter } = action;
   const supervisionResponse = yield call(
      supervisionService.addSystemParameters,
      systemParameter
   );
   const response = supervisionResponse as ApiResponse<
      SystemParametersReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         SystemParametersActions.addSystemParametersSuccess(response.data)
      );
   } else {
      yield put(
         SystemParametersActions.addSystemParametersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getSystemParameters(
   supervisionService: Readonly<SystemParametersServiceType>,
   action: Readonly<SystemParametersParams>
): Generator {
   // yield put(SystemParametersActions.getSystemParametersReset());

   const supervisionResponse = yield call(
      supervisionService.getSystemParameters
   );
   const response = supervisionResponse as ApiResponse<
      readonly SystemParameters[]
   >;

   if (response.status && response.ok) {
      yield put(
         SystemParametersActions.getSystemParametersSuccess(response.data)
      );
   } else {
      yield put(
         SystemParametersActions.getSystemParametersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
