import React, { useEffect, useState } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectContact } from 'State/Redux/ContactRedux';
import SearchPanel from './Panels/SearchPanel';
import ResultsPanel from './Panels/ResultsPanel';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { useSelector, useDispatch } from 'react-redux';
import {
   DefaultSearchModel,
   isValidSearchQuery,
   filterToValidQuery,
} from 'Models/Licence/Dto/LicenceSearchQuery';
import './LicencesSearch.scss';
import { Button } from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import {
   LicenceActions,
   selectLicenceSearchResults,
   LicenceTypes,
} from 'State/Redux/LicenceRedux';
import LicenceDtoReadonly from 'Models/Licence/Dto/LicenceDto';
import CreateLicences from '../CreateLicences';
import EditLicences from '../EditLicences';
import DialogContainer from 'react-md/lib/Dialogs';

interface LicencesSearchProps {
   setAdvancedSearch: (setting: boolean) => void;
}

const LicencesSearch = ({
   setAdvancedSearch,
}: Readonly<LicencesSearchProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const licences = useSelector(selectLicenceSearchResults);
   const isLoading = useSelector(
      selectIsLoading([LicenceTypes.SEARCH_LICENCES_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([LicenceTypes.SEARCH_LICENCES_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);
   const [searchResults, setSearchResults] = useState<LicenceDtoReadonly[]>([]);
   const [searchButtonClicked, setSearchButtonClicked] = useState(false);
   const [searchModel, setSearchModel] = useState(DefaultSearchModel);
   const [showModal, setShowModal] = useState(false);
   const [editModal, setEditModal] = useState<number | null>(null);

   const searchLicences = (): void => {
      const filteredQuery = filterToValidQuery(searchModel);
      if (searchButtonClicked && isValidSearchQuery(filteredQuery)) {
         dispatch(LicenceActions.searchLicencesRequest(filteredQuery));
         setHasSearched(true);
      } else if (!hasSearched) {
         setSearchButtonClicked(false);
      }
   };

   const editLicence = (licenceId: number): void => {
      setEditModal(licenceId);
      setShowModal(true);
   };

   useEffect(searchLicences, [searchButtonClicked, dispatch, searchModel]);

   const hasResults = isSuccess && hasSearched && licences !== searchResults;
   if (hasResults) setSearchResults(licences);

   const panelTitle =
      hasSearched && searchResults
         ? `Results (${searchResults.length})`
         : 'Results';

   return (
      <>
         <div className="back-button">
            <Button
               flat
               primary
               iconChildren="arrow_back"
               onClick={(): void => {
                  setAdvancedSearch(false);
                  setHasSearched(false);
                  setSearchButtonClicked(false);
                  setSearchResults([]);
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  setAdvancedSearch(false);
                  setHasSearched(false);
                  setSearchButtonClicked(false);
                  setSearchResults([]);
               }}
            >
               Back
            </Button>
         </div>
         <ExpansionList className="md-cell md-cell--12">
            <DialogContainer
               id="simple-list-dialog"
               visible={showModal}
               title={editModal === null ? 'Add New Licence' : 'Edit Licence'}
               width={1500}
               portal
               onHide={FN_EMPTY_VOID}
            >
               {editModal === null ? (
                  <CreateLicences
                     closeDialog={(): void => setShowModal(false)}
                  />
               ) : (
                  <EditLicences
                     licenceId={editModal}
                     closeDialog={(): void => setShowModal(false)}
                  />
               )}
            </DialogContainer>
            <ExpansionPanel
               label="Licence Advanced Search"
               footer={null}
               className="no-margin-top"
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <SearchPanel
                  contact={contact}
                  query={searchModel}
                  setQuery={setSearchModel}
                  isSearching={isLoading}
                  setIsSearching={setSearchButtonClicked}
                  reset={(): void => {
                     setHasSearched(false);
                     setSearchButtonClicked(false);
                     setSearchResults([]);
                  }}
               />
            </ExpansionPanel>
            {hasSearched ? (
               <ExpansionPanel
                  label={panelTitle}
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ResultsPanel
                     licences={searchResults}
                     isLoading={isLoading}
                     editLicence={(licenceId: number): void =>
                        editLicence(licenceId)
                     }
                  />
               </ExpansionPanel>
            ) : (
               <>
                  <div className="licence-search-padding"></div>
               </>
            )}
         </ExpansionList>
      </>
   );
};

export default React.memo(LicencesSearch);
