import React, { useState, useEffect } from 'react';
import TextField from 'react-md/lib/TextFields';
import { isAmount } from 'Util/Helpers/Validation';
import { InputProps } from '../Shared/InputProps';
import FaIcon from '../FaIcon/FaIcon';
import './style.scss';
import { currencyFormatWithoutSymbol } from 'Util/Helpers/Currency';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface AmountInputProps extends InputProps {
   maxLength?: number;
   onChange: (val: string | number) => void;
   allowNegative?: boolean;
}

const AmountInput = ({
   error,
   onChange,
   floating,
   id,
   label,
   placeholder,
   className,
   errorText,
   maxLength,
   required,
   value,
   allowNegative,
}: Readonly<AmountInputProps>): JSX.Element => {
   const getValue = (value: string | number): number => {
      return Number(value.toString().replace(/,/g, ''));
   };

   const [localVal, setLocalVal] = useState<number | string>(
      value || value === 0 ? currencyFormatWithoutSymbol(getValue(value)) : ''
   );

   const [errorState, setError] = useState<boolean | undefined>(error);
   const [localErrorText, setLocalErrorText] = useState<string>();

   useEffect((): void => {
      if (value || value === 0) {
         const v = getValue(value);
         const l = getValue(localVal);

         if (v !== l) {
            const val = currencyFormatWithoutSymbol(v === Number(-0) ? 0 : v);
            setLocalVal(val);
         }
      }
   }, [value, localVal]);

   const handleChange = (val: string | number): void => {
      if (onChange) {
         onChange(val);
      }
      setLocalVal(val);
      setError(false);
   };

   const handleBlur = (): void => {
      if (localVal) {
         const value = getValue(localVal);
         const isInvalid = !isAmount(value.toString());
         setError(isInvalid);
         setLocalErrorText('Invalid Amount');
         if (!isInvalid) {
            const val = currencyFormatWithoutSymbol(
               value === Number(-0) ? 0 : value
            );
            setLocalVal(val);
            if (onChange) {
               onChange(val);
            }
         }
      } else {
         setError(true);
         setLocalErrorText(errorText);
      }
   };

   const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
      //  48 -  57   : 0-9
      //  96 - 105   : 0-9 (numpad)
      //   8         : backspace
      //   9         : tab
      //  35         : end
      //  36         : home
      //  37         : left arrow
      //  39         : right arrow
      //  46         : delete
      //  91         : left window key (for better UX, the windows key is still able to function when textbox is active)
      // 190         : period (dot) (for decimal point)
      // 110         : period (dot) (numpad)
      //  67         : c
      //  86         : v
      //  88         : x
      //  45         : insert
      // 189         : - (to allow negative value)
      // 109         : - (numpad)

      if (event.ctrlKey && [45, 67, 86, 88].indexOf(event.keyCode) > -1) {
         return;
      }

      if (event.shiftKey && [45, 46].indexOf(event.keyCode) > -1) {
         return;
      }

      if (
         !(
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            [8, 9, 35, 36, 37, 39, 46, 91, 190, 110, 109, 189].indexOf(
               event.keyCode
            ) > -1
         )
      ) {
         event.preventDefault();
         return;
      }

      //if a decimal has been added, disable the "." key
      if (
         [190, 110].indexOf(event.keyCode) > -1 &&
         localVal.toString().indexOf('.') !== -1
      ) {
         event.preventDefault();
         return;
      }

      if ([109, 189].indexOf(event.keyCode) > -1 && allowNegative) {
         if (localVal.toString().indexOf('-') !== -1) {
            event.preventDefault();
            return;
         } else {
            const val = getValue(localVal);
            let value = '';
            if (val > 0) {
               value = currencyFormatWithoutSymbol(val * -1);
            } else {
               value = '-';
            }
            setLocalVal(value);
            if (onChange) {
               onChange(value);
            }
            event.preventDefault();
            return;
         }
      }
   };

   return (
      <div className="amount-input-container">
         <TextField
            floating={floating}
            id={id}
            label={label}
            placeholder={placeholder}
            className={className}
            onChange={(val): void => handleChange(val)}
            errorText={localErrorText}
            error={errorState}
            maxLength={maxLength}
            onBlur={handleBlur}
            required={required ? required : localVal ? true : false}
            value={localVal}
            leftIcon={<FaIcon icon="dollar-sign" />}
            onKeyDown={handleKeyDown}
            helpText={false}
            inputClassName={getInputValidationClassName(errorState)}
         />
      </div>
   );
};

export default AmountInput;
