import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import React, { useState } from 'react';
import { Button, DialogContainer } from 'react-md';
import { useDispatch } from 'react-redux';
import { Column, CellInfo } from 'react-table';
import { FitAndProperActions } from 'State/Redux/FitAndProperRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { usePermissions } from 'Util/Helpers/Permissions';
import FitAndProperEdit from './FitAndProperEdit/FitAndProperEdit';
import FitAndProperHistory from './FitAndProperHistory/FitAndProperHistory';
import './FitAndProperPanel.scss';

interface FitAndProperPanelProps {
   userId: string;
   answers: Readonly<QuestionDto[]> | undefined;
   requestToRefresh: () => void;
}

interface FitAndProperDisplayModel {
   id: number;
   questionText: string;
   latestAnswer: string;
}

const generateFirAndProperResults = (
   answers: Readonly<QuestionDto[]>
): FitAndProperDisplayModel[] => {
   return answers.map(
      (q: QuestionDto, i: number): FitAndProperDisplayModel => {
         return {
            id: q.id,
            questionText: q.questionText,
            latestAnswer:
               q.latestAnswer === true
                  ? 'Yes'
                  : q.latestAnswer === false
                  ? 'No'
                  : '',
         };
      }
   );
};

const FitAndProperPanel = ({
   userId,
   answers,
   requestToRefresh,
}: Readonly<FitAndProperPanelProps>): JSX.Element => {
   const dispatch = useDispatch();
   const [
      editFitAndProperWindowVisible,
      setEditFitAndProperWindowVisible,
   ] = useState<boolean>(false);
   const hideEditModal = (): void => setEditFitAndProperWindowVisible(false);

   const [
      historyFitAndProperWindowVisible,
      setHistoryFitAndProperWindowVisible,
   ] = useState<boolean>(false);
   const hideHistoryModal = (): void =>
      setHistoryFitAndProperWindowVisible(false);

   const [editId, setEditId] = useState(Number);
   const [historyId, setHistoryId] = useState(Number);
   const hasReadPermission = usePermissions(['F2P.Answer.Read']);
   const hasWritePermission = usePermissions(['F2P.Answer.Create']);

   const titledCell = (cell: CellInfo): JSX.Element => {
      if (!cell.value) return <>-</>;
      return (
         <span className={`text-style-${cell.value}`} title={cell.value}>
            {cell.value}
         </span>
      );
   };

   const editCell = (cell: CellInfo): JSX.Element => (
      <Button
         onClick={(): void => {
            setEditId(cell.value);
            setEditFitAndProperWindowVisible(true);
         }}
      >
         <FaIcon className="edit-btn" icon="edit" />
      </Button>
   );

   const historyCell = (cell: CellInfo): JSX.Element => (
      <Button
         onClick={(): void => {
            setHistoryId(cell.value);
            dispatch(FitAndProperActions.getAnswerHistoryByIdReset());
            setHistoryFitAndProperWindowVisible(true);
         }}
      >
         <FaIcon className="edit-btn" icon="history" />
      </Button>
   );

   const onSaveSuccess = (): void => {
      hideEditModal();
      requestToRefresh();
   };

   const COLUMN_HEADERS: Column<FitAndProperDisplayModel>[] = [
      {
         Header: 'Id',
         accessor: 'id',
         sortable: false,
         width: 50,
      },
      {
         Header: 'Question',
         accessor: 'questionText',
         sortable: false,
         Cell: titledCell,
         className: 'question-column',
      },
      {
         Header: 'Latest Answer',
         accessor: 'latestAnswer',
         sortable: false,
         Cell: titledCell,
         className: 'answer-column',
         maxWidth: 200,
      },
      {
         Header: 'Edit',
         sortable: false,
         Cell: hasWritePermission && editCell,
         accessor: 'id',
         maxWidth: 75,
         className: 'button-column',
      },
      {
         Header: 'History',
         sortable: false,
         Cell: hasReadPermission && historyCell,
         accessor: 'id',
         maxWidth: 75,
         className: 'button-column',
      },
   ];

   const fitAndProperEditWindow = (
      <DialogContainer
         id="dialog-container-fit-proper-edit"
         className="fitandproper-edit-dialog"
         visible={editFitAndProperWindowVisible}
         focusOnMount={false}
         containFocus={false}
         closeOnEsc
         portal
         onHide={hideEditModal}
      >
         <Button
            className="button-close"
            onClick={hideEditModal}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) hideEditModal();
            }}
         >
            <FaIcon icon="times" />
         </Button>
         <FitAndProperEdit
            answer={answers?.find(x => x.id === editId)}
            onSaveSuccess={onSaveSuccess}
         />
      </DialogContainer>
   );

   const fitAndProperHistoryWindow = (
      <DialogContainer
         id="dialog-container-fit-proper-edit"
         className="fitandproper-history-dialog"
         visible={historyFitAndProperWindowVisible}
         focusOnMount={false}
         containFocus={false}
         closeOnEsc
         portal
         onHide={hideHistoryModal}
      >
         <Button
            className="button-close"
            onClick={hideHistoryModal}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) hideHistoryModal();
            }}
         >
            <FaIcon icon="times" />
         </Button>
         <FitAndProperHistory
            questionId={historyId}
            userId={userId}
            question={answers?.find(x => x.id === historyId)?.questionText}
         />
      </DialogContainer>
   );

   return hasReadPermission ? (
      <>
         {hasWritePermission && fitAndProperEditWindow}
         {hasReadPermission && fitAndProperHistoryWindow}
         {answers ? (
            <ClientSideTable
               data={generateFirAndProperResults(answers)}
               className="FitAndProperPanel"
               columns={COLUMN_HEADERS}
               defaultPageSize={answers.length ? answers.length : 10}
               showPagination={false}
            />
         ) : (
            <></>
         )}
      </>
   ) : (
      <></>
   );
};

export default FitAndProperPanel;
