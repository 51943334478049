import Moment from 'moment-timezone';
import { NZ_TIMEZONE } from 'Util/Constants/Common';
import { stripTimeZone } from 'Util/Helpers/Date';

interface FeesPaymentSearchInput {
   licenceNumber?: string;
   payeeName?: string;
   startDate?: string;
   endDate?: string;
   paymentTypeCode?: string;
   paymentStatusCode?: string;
}

export default FeesPaymentSearchInput;

const currentDate = Moment()
   .tz(NZ_TIMEZONE)
   .endOf('day');
const startDate = Moment()
   .tz(NZ_TIMEZONE)
   .month('Apr')
   .set('date', 1)
   .startOf('day');

const defaultStartDate = currentDate.isBefore(startDate)
   ? startDate.subtract(1, 'years')
   : startDate;

export const DefaultFeesPaymentSearchInput: FeesPaymentSearchInput = {
   licenceNumber: undefined,
   payeeName: '',
   startDate: stripTimeZone(defaultStartDate.format()),
   endDate: stripTimeZone(currentDate.format()),
   paymentTypeCode: '',
   paymentStatusCode: '',
};
