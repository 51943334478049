import { AnyAction } from 'redux';
import { IS_ASYNC_ACTION } from 'Util/Constants/Regex';
import ApiError from 'Models/Other/ApiError';
import { some, get, every } from 'lodash-es';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface AsyncState {
   [key: string]: AsyncStatusReadonly;
}

export type AsyncStateReadonly = Readonly<AsyncState>;

interface AsyncStatus {
   busy: boolean;
   error: ApiError | undefined;
   success: boolean;
}

export type AsyncStatusReadonly = Readonly<AsyncStatus>;

interface TypeNames {
   RESET_ASYNC: string;
}

type AsyncTypeNames = Readonly<TypeNames>;

export interface AsyncCreators {
   resetAsync: (targetActions: readonly string[]) => AnyAction;
}

const ASYNC_SUCCESS = 'SUCCESS';
const ASYNC_FAILURE = 'FAILURE';
const ASYNC_REQUEST = 'REQUEST';

/* ------------- Initial State ------------- */
export const INITIAL_STATE = ({} as unknown) as AsyncStateReadonly;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<AsyncTypeNames, AsyncCreators>(
   'async',
   {
      resetAsync: ['targetActions'],
   }
);

export const AsyncTypes = Types;
export const AsyncActions = Creators;

/* eslint-disable no-case-declarations */

/* ------------- Reducer ------------- */
// This reducer is done in the traditional way due to an issue
// with reduxsauce, typescript, and default reducers. This reducer
// is not expected to grow, so this solution is acceptable.
export const reducer = (
   state = INITIAL_STATE,
   action: AnyAction
): AsyncStateReadonly => {
   switch (action.type) {
      case AsyncTypes.RESET_ASYNC:
         let newState = state;
         action.targetActions.forEach((a: string): void => {
            newState = {
               ...newState,
               [a]: {
                  busy: false,
                  error: undefined,
                  success: false,
               },
            };
         });
         return newState;
      default:
         const { type } = action;
         const matches = IS_ASYNC_ACTION.exec(type);

         // Ignore if the action does not contain REQUEST/SUCCESS/FAILURE
         if (!matches) return state;

         const [, requestName, requestState] = matches;

         // Named this way so we are able to use REQUEST action types to index the async state
         const requestNameIndex = `${requestName}_${ASYNC_REQUEST}`;

         switch (requestState) {
            case ASYNC_SUCCESS: {
               return {
                  ...state,
                  [requestNameIndex]: {
                     busy: false,
                     error: undefined,
                     success: true,
                  },
               };
            }
            case ASYNC_FAILURE: {
               return {
                  ...state,
                  [requestNameIndex]: {
                     busy: false,
                     error: action.error,
                     success: false,
                  },
               };
            }
            case ASYNC_REQUEST: {
               return {
                  ...state,
                  [requestNameIndex]: {
                     busy: true,
                     error: undefined,
                     success: false,
                  },
               };
            }
            default:
               return state;
         }
   }
};

/* ------------- Selectors ------------- */

// returns true only when all actions is not loading
export const selectIsLoading = (
   actions: readonly string[]
): ((state: StateReadonly) => boolean) => {
   return (state: StateReadonly): boolean => {
      return some(actions, (action: string): AsyncStatusReadonly | boolean => {
         return get(state.async, `${action}.busy`, false);
      });
   };
};

export const selectIsError = (
   actions: readonly string[]
): ((state: StateReadonly) => boolean) => {
   return (state: StateReadonly): boolean => {
      return some(actions, (action: string): AsyncStatusReadonly | boolean => {
         return get(state.async, `${action}.error`) !== undefined;
      });
   };
};

export const selectError = (
   action: string
): ((state: StateReadonly) => AsyncStatusReadonly | ApiError | null) => {
   return (state: StateReadonly): AsyncStatusReadonly | ApiError | null => {
      return get(state.async, `${action}.error`, null);
   };
};

export const selectIsSuccess = (
   actions: readonly string[]
): ((state: StateReadonly) => boolean) => {
   return (state: StateReadonly): boolean => {
      return every(actions, (action: string): AsyncStatusReadonly | boolean => {
         return get(state.async, `${action}.success`, false);
      });
   };
};

export const selectErrorMessage = (
   action: string
): ((state: StateReadonly) => string | null) => {
   return (state: StateReadonly): string | null => {
      const status = get(state.async, action);
      if (status && status.error && status.error.message) {
         return status.error.message;
      }
      return null;
   };
};
