import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import PaymentMethodReadonly, {
   PaymentMethod,
} from 'Models/PaymentMethod/Dto/PaymentMethod';

export interface PaymentMethodServiceType {
   updatePaymentMethod(
      paymentMethod: PaymentMethodReadonly
   ): Promise<ApiResponse<readonly PaymentMethodReadonly[]>>;
   getPaymentMethod(): Promise<ApiResponse<readonly PaymentMethod[]>>;
   addPaymentMethod(
      paymentMethod: PaymentMethodReadonly
   ): Promise<ApiResponse<PaymentMethodReadonly>>;
}

const create = (baseURL: string | undefined): PaymentMethodServiceType => {
   const api = createApi({ baseURL });

   const updatePaymentMethod = (
      paymentMethod: PaymentMethodReadonly
   ): Promise<ApiResponse<readonly PaymentMethodReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/PaymentMethod`, paymentMethod);
   };

   const getPaymentMethod = (): Promise<ApiResponse<
      readonly PaymentMethod[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/PaymentMethod`);
   };

   const addPaymentMethod = (
      paymentMethod: PaymentMethodReadonly
   ): Promise<ApiResponse<PaymentMethodReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/PaymentMethod`, paymentMethod);
   };

   return {
      updatePaymentMethod,
      getPaymentMethod,
      addPaymentMethod,
   };
};

export default {
   create,
};
