import React, { useState } from 'react';
import { Column, SortingRule, CellInfo } from 'react-table';
import { DISPLAY_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { BatchItemReadonly } from 'Models/BackOffice/BatchItem';
import moment from 'moment-timezone';
import LoadingButton from 'Components/Common/LoadingButton';

interface ExportHistoryResultsPanelProps {
   isBatchItemDownloading: boolean;
   searchResults: readonly BatchItemReadonly[];
   downloadFile: (index: number) => void;
   isRefreshing: boolean;
}

const ExportHistoryResultsPanel = ({
   isBatchItemDownloading,
   searchResults,
   downloadFile,
   isRefreshing,
}: Readonly<ExportHistoryResultsPanelProps>): JSX.Element => {
   const [selectedFileName, setSelectedFileName] = useState('');

   const DownloadButton = (cell: CellInfo): JSX.Element => {
      if (
         cell.original.batchStateCode === 'FAIL' ||
         cell.original.batchStateCode === 'INPR'
      )
         return <></>;

      return (
         <LoadingButton
            isLoading={
               isBatchItemDownloading &&
               searchResults[cell.index].filename === selectedFileName
            }
            className="btn-small"
            flat
            primary
            swapTheming
            disabled={
               isBatchItemDownloading &&
               searchResults[cell.index].filename !== selectedFileName
            }
            onClick={(): void => {
               setSelectedFileName(searchResults[cell.index].filename);
               downloadFile(cell.index);
            }}
         >
            Download
         </LoadingButton>
      );
   };

   const COLUMN_HEADERS: Column<BatchItemReadonly>[] = [
      {
         Header: 'File Name',
         accessor: 'filename',
         sortable: true,
      },
      {
         Header: 'Process',
         accessor: 'batchProcessDescription',
         sortable: true,
      },
      {
         Header: 'State',
         accessor: 'batchStateDescription',
         sortable: true,
      },
      {
         Header: 'Export Date',
         accessor: 'createdOn',
         sortable: true,
         Cell: (cellInfo): string => {
            return moment(cellInfo.value).format(DISPLAY_DATE_FORMAT);
         },
      },
      {
         Header: '',
         accessor: 'batchId',
         sortable: false,
         Cell: DownloadButton,
         width: 150,
      },
   ];

   const DEFAULT_SORTING: SortingRule[] = [{ id: 'completedDate', desc: true }];

   return (
      <ClientSideTable
         loading={isRefreshing}
         data={searchResults}
         className="ExportHistoryPaney"
         columns={COLUMN_HEADERS}
         defaultPageSize={10}
         showPaginationTop
         defaultSorted={DEFAULT_SORTING}
      />
   );
};

export default ExportHistoryResultsPanel;
