import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
   selectResults,
   ResultActions,
   ResultTypes,
} from 'State/Redux/ResultRedux';
import ResultDto, {
   DefaultResultDtoModel,
} from 'Models/Examination/Dto/ResultDto';
import Moment from 'moment-timezone';
import { selectContact } from 'State/Redux/ContactRedux';
import { Column, CellInfo, SortingRule } from 'react-table';
import { SelectField } from 'react-md/lib/SelectFields';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { useLicenceYearList, useResultStatusList } from 'Util/Helpers/Metadata';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';

interface ResultDisplayModel {
   date: string;
   courseName: string | null;
   courseCode: string | null;
   provider: string;
   result: string;
   mark: number | null;
   resultStatusCode: string | null;
   resultStatusDescription: string;
   licenceYear: number | string | null;
   licenceYearDescription: string | null;
   resultId?: number;
}

const generateResultModels = (
   results: readonly ResultDto[]
): ResultDisplayModel[] => {
   return results.map(
      (result): ResultDisplayModel => {
         return {
            date: result.resultDate,
            courseName: result.course ? result.course.courseName : '-',
            courseCode: result.course ? result.course.courseNumber : '-',
            provider: result.course
               ? `${result.course.trainingProvider.companyName} - ${result.course.trainingProvider.fullName}`
               : '-',
            result: result.passFailCode,
            mark: result.mark,
            resultStatusDescription: result.resultStatusDescription,
            resultStatusCode: result.resultStatusCode,
            licenceYearDescription: result.licenceYearDescription,
            licenceYear: result.licenceYear,
            resultId: result.resultId,
         };
      }
   );
};

const DateRenderer = (cell: CellInfo): JSX.Element => {
   return (
      <div>
         {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
      </div>
   );
};

const IconHeader = (): JSX.Element => {
   return <FaIcon icon="ellipsis-h" />;
};

const AssessmentsHistory = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const results = useSelector(selectResults);
   const isLoading = useSelector(
      selectIsLoading([
         ResultTypes.UPDATE_RESULT_REQUEST,
         ResultTypes.GET_RESULT_BY_CONTACT_ID_REQUEST,
      ])
   );
   const isError = useSelector(
      selectIsError([ResultTypes.UPDATE_RESULT_REQUEST])
   );
   const dispatch = useDispatch();

   const licenceYears = useLicenceYearList();
   const resultStatuses = useResultStatusList();
   const [result, setResult] = useState<ResultDto>(DefaultResultDtoModel);
   const [updateSubmitted, setUpdateSubmitted] = useState<boolean>(false);

   useEffect((): void => {
      if (updateSubmitted && !isError && !isLoading) {
         toast.success('Result updated successfully');
         dispatch(
            ResultActions.getResultByContactIdRequest(
               contact.contactId.toString()
            )
         );

         setUpdateSubmitted(false);
      }

      if (updateSubmitted && isError && !isLoading) {
         toast.error('Failed to update result. Please try again');
         setUpdateSubmitted(false);
      }
   }, [
      isLoading,
      isError,
      updateSubmitted,
      dispatch,
      result,
      contact.contactId,
   ]);

   const IconEdit = (cell: CellInfo): JSX.Element => {
      const cellResultId = cell.original.resultId as number | null;
      const isSelectedCell = cellResultId === result.resultId;
      return (
         <>
            <FaIcon
               className="small-icon md-pointer--hover"
               icon={isSelectedCell ? 'check' : 'edit'}
               disabled={!isSelectedCell && result.resultId !== 0}
               onClick={(e: React.MouseEvent): void => {
                  if (isSelectedCell) {
                     //execute an update for the result
                     dispatch(ResultActions.updateResultRequest(result));
                     setUpdateSubmitted(true);
                     setResult(DefaultResultDtoModel);
                  } else {
                     setResult(cell.original as ResultDto);
                  }
                  e.stopPropagation();
               }}
            />
            {isSelectedCell && (
               <FaIcon
                  className="small-icon md-pointer--hover"
                  icon="times"
                  onClick={(e: React.MouseEvent): void => {
                     setResult(DefaultResultDtoModel);
                     e.stopPropagation();
                  }}
               />
            )}
         </>
      );
   };

   const renderEditableStatus = (cellInfo: CellInfo): JSX.Element => {
      return (
         <>
            {cellInfo.original.resultId !== result.resultId ? (
               <span className="editable-result-text">
                  {cellInfo.original.resultStatusDescription}
               </span>
            ) : (
               <SelectField
                  id="result-status"
                  className="result-status-selection"
                  menuItems={resultStatuses}
                  value={
                     result.resultStatusCode
                        ? (result.resultStatusCode as string)
                        : cellInfo.original.resultStatusCode
                  }
                  defaultValue={cellInfo.original.resultStatusCode}
                  onChange={(value): void => {
                     setResult({
                        ...result,
                        resultStatusCode: value as string,
                     });
                  }}
               >
                  {cellInfo.original.resultStatusDescription}
               </SelectField>
            )}
         </>
      );
   };

   const renderEditableYear = (cellInfo: CellInfo): JSX.Element => {
      const currentLicenceYear = cellInfo.original.licenceYear;

      return (
         <>
            {cellInfo.original.resultId !== result.resultId ? (
               <span className="editable-year-text">
                  {cellInfo.original.licenceYearDescription}
               </span>
            ) : (
               <SelectField
                  id="licence-year"
                  className="licence-year-selection"
                  menuItems={licenceYears}
                  value={
                     result.licenceYear
                        ? result.licenceYear.toString()
                        : currentLicenceYear
                  }
                  defaultValue={currentLicenceYear}
                  onChange={(value): void =>
                     setResult({
                        ...result,
                        licenceYear: value as number,
                     })
                  }
               >
                  {cellInfo.original.resultDate}
               </SelectField>
            )}
         </>
      );
   };

   const DEFAULT_SORTING: SortingRule[] = [{ id: 'date', desc: true }];

   const COLUMN_HEADERS: Column<ResultDisplayModel>[] = [
      {
         Header: 'Date',
         accessor: 'date',
         Cell: DateRenderer,
      },
      {
         Header: 'Title',
         accessor: 'courseName',
      },
      {
         Header: 'Code',
         accessor: 'courseCode',
      },
      {
         Header: 'Provider',
         accessor: 'provider',
      },
      {
         Header: 'Result',
         accessor: 'result',
      },
      {
         Header: 'Result %',
         accessor: 'mark',
      },
      {
         Header: 'Status',
         accessor: 'resultStatusDescription',
         Cell: renderEditableStatus,
         className: 'expandable-cell',
      },
      {
         Header: 'Licence Year',
         accessor: 'licenceYearDescription',
         Cell: renderEditableYear,
         className: 'expandable-cell',
      },
      {
         Header: IconHeader,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         accessor: FN_EMPTY_VOID,
         Cell: IconEdit,
         sortable: false,
      },
   ];

   return (
      <div className="result assessments-table">
         <ClientSideTable
            data={generateResultModels(results)}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
         />
      </div>
   );
};

export default AssessmentsHistory;
