import {
   isValidEndDateAfterStartDate,
   isValidDate,
   isNullOrWhiteSpace,
} from 'Util/Helpers/Validation';

interface FeeMaintenanceSearchQueryModel {
   startDate: string;
   endDate: string;
   application: string;
   feeType: string;
   pastFees: boolean;
}

export type FeeMaintenanceSearchQuery = Readonly<
   FeeMaintenanceSearchQueryModel
>;

export const DefaultFeeMaintenanceSearchQuery: FeeMaintenanceSearchQuery = {
   startDate: '',
   endDate: '',
   application: '',
   feeType: '',
   pastFees: false,
};

interface FeeMaintenanceSearchQueryValidationModel {
   startDate: boolean;
   endDate: boolean;
   application: boolean;
   feeType: boolean;
}

export type FeeMaintenanceSearchQueryValidation = Readonly<
   FeeMaintenanceSearchQueryValidationModel
>;

export const DefaultFeeMaintenanceSearchQueryValidation: FeeMaintenanceSearchQueryValidation = {
   startDate: false,
   endDate: false,
   application: false,
   feeType: false,
};

export const validateSearch = (
   query: FeeMaintenanceSearchQuery
): FeeMaintenanceSearchQueryValidation => {
   return {
      startDate: isValidDate(query.startDate), // don't trust the datepicker | I don't trust like that: https://youtu.be/zONW46d50A0
      endDate:
         isValidDate(query.endDate) &&
         isValidEndDateAfterStartDate(query.startDate, query.endDate),
      application: !isNullOrWhiteSpace(query.application),
      feeType: !isNullOrWhiteSpace(query.feeType),
   };
};

export const filterToValidQuery = (
   query: FeeMaintenanceSearchQuery
): FeeMaintenanceSearchQuery => {
   return {
      startDate: query.startDate, // trust the datepicker
      endDate: query.endDate,
      application: query.application,
      feeType: query.feeType,
      pastFees: query.pastFees,
   };
};

export const isValidQuery = (query: FeeMaintenanceSearchQuery): boolean => {
   return isValidEndDateAfterStartDate(query.startDate, query.endDate);
};
