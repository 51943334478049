import React, { useState, useCallback } from 'react';
import { Column, SortingRule } from 'react-table';
import { useSelector, useDispatch } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { FeeTypeTypes, FeeTypeActions } from 'State/Redux/FeeTypeRedux';
import FeeTypeReadonly from 'Models/FeeMaintenance/Dto/FeeType';
import { validateFeeTypes } from 'Models/FeeType/Data/FeeType';

interface ResultsPanelProps {
   searchResults: readonly FeeTypeReadonly[];
   isLoading: boolean;
}

const FeeTypeResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const [editTarget, setEditTarget] = useState<FeeTypeReadonly | null>(null);
   const isSuccess = useSelector(
      selectIsSuccess([FeeTypeTypes.UPDATE_FEE_TYPE_REQUEST])
   );

   const [editCode, setEditCode] = useState('');
   const [editDescription, setEditDescription] = useState('');
   const [editGLCode, setEditGLCode] = useState('');
   const [editIsGSTInclusive, setEditIsGSTInclusive] = useState(false);
   const [editIsDeleted, setEditIsDeleted] = useState(false);
   const [editDescriptionRequired, setEditDescriptionRequired] = useState(
      false
   );
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditCodeCallback = useCallback((value: string) => {
      setEditCode(value);
   }, []);
   const setEditDescriptionCallback = useCallback((value: string) => {
      setEditDescription(value);
   }, []);
   const setEditGLCodeCallback = useCallback((value: string) => {
      setEditGLCode(value);
   }, []);
   const setEditIsGSTInclusiveCallback = useCallback((value: boolean) => {
      setEditIsGSTInclusive(value);
   }, []);
   const setEditIsDeletedCallback = useCallback((value: boolean) => {
      setEditIsDeleted(value);
   }, []);
   const setEditDescriptionRequiredCallback = useCallback((value: boolean) => {
      setEditDescriptionRequired(value);
   }, []);

   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   const dispatch = useDispatch();

   if (updateClicked && isSuccess !== null) {
      toast.success(`Fee Type updated successfully`);
      setUpdateClicked(false);
   }
   const isValidForm = editTarget
      ? Object.values(
           validateFeeTypes({
              ...editTarget,
              feeTypeCode: editCode,
              description: editDescription,
              glcode: editGLCode,
              isGstinclusive: editIsGSTInclusive,
              isDeleted: editIsDeleted,
              descriptionRequired: editDescriptionRequired,
           })
        ).every((v): boolean => !!v)
      : false;

   const DEFAULT_SORTING: SortingRule[] = [{ id: 'feeTypeCode', desc: false }];

   const COLUMN_HEADERS: Column<FeeTypeReadonly>[] = [
      {
         Header: 'Code',
         accessor: 'feeTypeCode',
      },
      {
         Header: 'Description',
         accessor: 'description',
      },
      {
         Header: 'GL Code',
         accessor: 'glcode',
      },
      {
         Header: 'GST Inclusive',
         accessor: 'isGstinclusive',
         width: 150,
         className: 'center-icon',
         Cell: (cellInfo): JSX.Element => {
            if (cellInfo.value) {
               return <FaIcon className="small-icon" icon="check" />;
            }

            return <FaIcon className="small-icon" icon="times" />;
         },
      },
      {
         Header: 'Description Required',
         accessor: 'descriptionRequired',
         width: 200,
         className: 'center-icon',
         Cell: (cellInfo): JSX.Element => {
            if (!cellInfo.value) {
               return <FaIcon className="small-icon" icon="times" />;
            }

            return <FaIcon className="small-icon" icon="check" />;
         },
      },

      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: 'feeTypeCode',
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.feeTypeCode);
                     setEditDescription(cellInfo.original.description);
                     setEditGLCode(cellInfo.original.glcode);
                     setEditIsGSTInclusive(cellInfo.original.isGstinclusive);
                     setEditDescriptionRequired(
                        cellInfo.original.descriptionRequired
                     );
                     setEditIsDeleted(cellInfo.original.isDeleted);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.feeTypeCode);
                     setEditDescription(cellInfo.original.description);
                     setEditGLCode(cellInfo.original.glcode);
                     setEditIsGSTInclusive(cellInfo.original.isGstinclusive);
                     setEditDescriptionRequired(
                        cellInfo.original.descriptionRequired
                     );
                     setEditIsDeleted(cellInfo.original.isDeleted);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit Fee Type"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 fee-type-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Code</h3>
                  <TextField
                     floating
                     id="fee-type-code"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCode}
                     required
                     placeholder="Code"
                     onChange={(value): void => {
                        setEditCodeCallback(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Description</h3>
                  <TextField
                     floating
                     id="fee-type-description"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editDescription}
                     required
                     placeholder="Description"
                     onChange={(value): void => {
                        setEditDescriptionCallback(value.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">GL Code</h3>
                  <TextField
                     floating
                     id="fee-type-gl-code"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editGLCode}
                     required
                     placeholder="GL Code"
                     onChange={(value): void => {
                        setEditGLCodeCallback(value.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <Checkbox
                     id="is-gst-inclusive"
                     className="md-cell md-cell--12"
                     label="GST Inclusive"
                     checked={editIsGSTInclusive}
                     name="is-gst-inclusive"
                     onChange={(val): void => {
                        setEditIsGSTInclusiveCallback(val);
                     }}
                  />
                  <Checkbox
                     id="description-required"
                     className="md-cell md-cell--12"
                     label="Description Required"
                     checked={editDescriptionRequired}
                     name="description-required"
                     onChange={(val): void => {
                        setEditDescriptionRequiredCallback(val);
                     }}
                  />
                  <Checkbox
                     id="is-deleted"
                     className="md-cell md-cell--12"
                     label="Deleted"
                     checked={editIsDeleted}
                     name="is-deleted"
                     onChange={(val): void => {
                        setEditIsDeletedCallback(val);
                     }}
                  />
               </div>
               <Button
                  disabled={!isValidForm}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           FeeTypeActions.updateFeeTypeRequest({
                              ...editTarget,
                              feeTypeCode: editCode,
                              description: editDescription,
                              glcode: editGLCode,
                              isGstinclusive: editIsGSTInclusive,
                              isDeleted: editIsDeleted,
                              descriptionRequired: editDescriptionRequired,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           FeeTypeActions.updateFeeTypeRequest({
                              ...editTarget,
                              feeTypeCode: editCode,
                              description: editDescription,
                              glcode: editGLCode,
                              isGstinclusive: editIsGSTInclusive,
                              isDeleted: editIsDeleted,
                              descriptionRequired: editDescriptionRequired,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
         />
      </>
   );
};

export default FeeTypeResultsPanel;
