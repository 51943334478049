import React, { useState, useEffect } from 'react';
import { Column, SortingRule, CellInfo } from 'react-table';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { useSelector, useDispatch } from 'react-redux';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { Link } from 'react-router-dom';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { ExpansionList, ExpansionPanel, SelectField } from 'react-md';
import { useRegistrationApplicationStatusesFilter } from 'Util/Helpers/Metadata';
import Moment from 'moment';
import { ListValue } from 'react-md/lib/SelectFields/SelectField';
import {
   ApplicationActions,
   selectPendingRegistrationApplications,
} from 'State/Redux/ApplicationRedux';
import { PendingRegistrationApplications } from 'Models/Application/Data/PendingRegistrationApplications';
import { ApplicationPendingReviewReadonly } from 'Models/Application/Data/ApplicationPendingReview';

const ViewRegistrationAction = (cellInfo: CellInfo): JSX.Element => {
   return (
      <Link
         className="registration--action"
         to={getApplicationLink(
            cellInfo.original.contactId,
            cellInfo.original.applicationId
         )}
      >
         View
      </Link>
   );
};

const DEFAULT_SORTING: SortingRule[] = [{ id: 'createdDate', desc: true }];

const getApplicationLink = (
   contactId: number,
   applicationId: number
): string => {
   return `/contact/${contactId}/applications/${applicationId}/`;
};
const DateRenderer = (cell: CellInfo): JSX.Element => {
   return (
      <div>
         {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
      </div>
   );
};

const COLUMN_HEADERS: Column<PendingRegistrationApplications>[] = [
   {
      Header: 'Application Id',
      accessor: 'applicationId',
   },
   {
      Header: 'Licensing Number',
      accessor: 'registrationNumber',
   },
   {
      Header: `Registrant's Name`,
      accessor: 'registrantName',
   },
   {
      Header: 'Licensing year',
      accessor: 'licenceYearDescription',
   },
   {
      Header: 'Application Type',
      accessor: 'applicationTypeCode',
   },
   {
      Header: 'Date',
      accessor: 'createdDate',
      Cell: DateRenderer,
   },
   {
      Header: 'Status',
      accessor: 'applicationStatusDescription',
   },
   {
      Header: <FaIcon icon="ellipsis-h" />,
      id: 'edit',
      className: 'center-icon',
      accessor: FN_EMPTY_VOID,
      Cell: ViewRegistrationAction,
      sortable: false,
   },
];

const RegistrationsApplication = (): JSX.Element => {
   const [statusFilter, setStatusFilter] = useState(' ');
   const [filterList, setFilterList] = useState<
      ApplicationPendingReviewReadonly[]
   >([]);
   const dispatch = useDispatch();
   const pendingRegistrationApplications = useSelector(
      selectPendingRegistrationApplications
   );

   const filterByStatus = (value: ListValue): void => {
      setStatusFilter(value.toString());
      if (value === ' ') {
         setFilterList(pendingRegistrationApplications.list);
      } else {
         setFilterList(
            pendingRegistrationApplications.list.filter(
               x => x.applicationStatusCode === value
            )
         );
      }
   };

   const statusSelectItems = useRegistrationApplicationStatusesFilter('All');

   useEffect(() => {
      dispatch(ApplicationActions.getPendingRegistrationApplicationsRequest());
   }, [dispatch]);

   useEffect(() => {
      setFilterList(
         pendingRegistrationApplications.list
            ? pendingRegistrationApplications.list
            : []
      );
   }, [pendingRegistrationApplications]);

   return (
      <>
         <ExpansionList className="md-cell md-cell--12 applications-pending-review">
            <ExpansionPanel
               label="Filter By"
               footer={null}
               expanderIcon={<FaIcon icon="angle-down" />}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--4">
                     <SelectField
                        id="registration-filter-request-status-field"
                        label="Status"
                        className="md-cell md-cell--12"
                        menuItems={statusSelectItems}
                        value={statusFilter}
                        position={SelectField.Positions.BELOW}
                        onChange={filterByStatus}
                     />
                  </div>
               </div>
            </ExpansionPanel>
            <ExpansionPanel
               label="Applications pending review"
               footer={null}
               // secondaryLabel="Last updated: 25/05/2023 10:00 AM"
               secondaryLabel={`Last updated: ${
                  pendingRegistrationApplications.lastUpdatedDate
                     ? pendingRegistrationApplications.lastUpdatedDate
                     : '-'
               }`}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--12">
                     <ClientSideTable
                        data={filterList}
                        className="-striped -highlight"
                        defaultPageSize={10}
                        columns={COLUMN_HEADERS}
                        defaultSorted={DEFAULT_SORTING}
                     />
                  </div>
               </div>
            </ExpansionPanel>
         </ExpansionList>
      </>
   );
};

export default RegistrationsApplication;
