import { put, call } from 'redux-saga/effects';
import { ResultActions } from '../Redux/ResultRedux';
import { ResultServiceType } from '../Services/ResultService';
import { ApiResponse } from 'apisauce';
import ResultDto from 'Models/Examination/Dto/ResultDto';
import ResultStatusDtoReadonly from 'Models/Examination/Dto/ResultStatusDto';
import ResultSearchResultReadOnly from 'Models/Examination/Data/ResultSearchResult';
import { ResultSearchQuery } from 'Models/Examination/Data/ResultSearchQuery';
import ResultSearchResultDtoReadOnly from 'Models/Examination/Dto/ResultSearchResultDto';

interface ResultParams {
   type: string;
   token: string;
   contactId: string;
   result: ResultDto;
   searchResult: ResultSearchResultDtoReadOnly;
   query: ResultSearchQuery;
   results: ResultDto[];
}

export function* getResultByContactId(
   resultService: Readonly<ResultServiceType>,
   action: Readonly<ResultParams>
): Generator {
   const { contactId } = action;
   const resultResponse = yield call(
      resultService.getResultsByContactId,
      contactId
   );
   const response = resultResponse as ApiResponse<ResultDto[]>;

   if (response.status && response.ok) {
      yield put(ResultActions.getResultByContactIdSuccess(response.data));
   } else {
      yield put(
         ResultActions.getResultByContactIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getResultStatusByContactId(
   resultService: Readonly<ResultServiceType>,
   action: Readonly<ResultParams>
): Generator {
   const { contactId } = action;
   const resultResponse = yield call(
      resultService.getResultStatusByContactId,
      contactId
   );
   const response = resultResponse as ApiResponse<ResultStatusDtoReadonly>;

   if (response.status && response.ok) {
      yield put(ResultActions.getResultStatusByContactIdSuccess(response.data));
   } else {
      yield put(
         ResultActions.getResultStatusByContactIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateResult(
   resultService: Readonly<ResultServiceType>,
   action: Readonly<ResultParams>
): Generator {
   const resultResponse = yield call(resultService.updateResult, action.result);
   const response = resultResponse as ApiResponse<ResultDto>;

   if (response.status && response.ok && response.data) {
      yield put(ResultActions.updateResultSuccess(response.data));
   } else {
      yield put(
         ResultActions.updateResultFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateResultBySearchResult(
   resultService: Readonly<ResultServiceType>,
   action: Readonly<ResultParams>
): Generator {
   const resultResponse = yield call(
      resultService.updateResultBySearchResult,
      action.searchResult
   );
   const response = resultResponse as ApiResponse<
      ResultSearchResultDtoReadOnly
   >;

   if (response.status && response.ok && response.data) {
      yield put(ResultActions.updateResultBySearchResultSuccess(response.data));
   } else {
      yield put(
         ResultActions.updateResultBySearchResultFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* searchResults(
   resultService: Readonly<ResultServiceType>,
   action: Readonly<ResultParams>
): Generator {
   const { query } = action;
   const resultResponse = yield call(resultService.searchResult, query);
   const response = resultResponse as ApiResponse<ResultSearchResultReadOnly[]>;

   if (response.status && response.ok) {
      yield put(ResultActions.searchResultSuccess(response.data || []));
   } else {
      yield put(
         ResultActions.searchResultFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveValidatedResults(
   resultService: Readonly<ResultServiceType>,
   action: Readonly<ResultParams>
): Generator {
   const { results } = action;
   const resultResponse = yield call(
      resultService.saveValidatedResults,
      results
   );

   const response = resultResponse as ApiResponse<ResultDto[]>;

   if (response.status && response.ok) {
      yield put(ResultActions.saveValidatedResultsSuccess(response.data));
   } else {
      yield put(
         ResultActions.saveValidatedResultsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
