import React, { useState, useEffect } from 'react';
import FeeRequest from './FeeRequest';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
   FeesPaymentActions,
   FeesPaymentTypes,
   selectFeeRequest,
} from 'State/Redux/FeesPaymentRedux';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import Loading from 'Components/Common/Loading';
import FeeRequestDetailDisplay from 'Models/FeesPayment/FeeRequestDetailDisplay';
import FeeRequestLineDisplay, {
   getDefaultFeeRequestLineDisplayList,
} from 'Models/FeesPayment/FeeRequestLineDisplay';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface FeeRequestIdUrlParam {
   feeRequestId?: string;
}

const EditFeeRequest = (): JSX.Element => {
   const history = useHistory();
   const params = useParams<FeeRequestIdUrlParam>();
   const [feeRequestId, setFeeRequestId] = useState();
   const [feeRequestRequested, setFeeRequestRequested] = useState(false);
   const [feeRequestLoaded, setFeeRequestLoaded] = useState(false);
   const [feeRequestDisplay, setFeeRequestDisplay] = useState<
      FeeRequestDetailDisplay
   >();
   const [feeRequestLines, setFeeRequestLines] = useState(
      getDefaultFeeRequestLineDisplayList()
   );

   const feeRequest = useSelector(selectFeeRequest);
   const isGetLoading = useSelector(
      selectIsLoading([FeesPaymentTypes.GET_FEE_REQUEST_REQUEST])
   );
   const getSuccess = useSelector(
      selectIsSuccess([FeesPaymentTypes.GET_FEE_REQUEST_REQUEST])
   );
   const dispatch = useDispatch();

   useEffect((): void => {
      if (history.location && params.feeRequestId) {
         setFeeRequestId(Number(params.feeRequestId));
      }
   }, [history, params]);

   useEffect((): void => {
      if (feeRequestId && !feeRequestRequested) {
         dispatch(FeesPaymentActions.getFeeRequestRequest(feeRequestId));
         setFeeRequestRequested(true);
      }
   }, [feeRequestId, feeRequestRequested, dispatch]);

   useEffect((): void => {
      if (isGetLoading) {
         setFeeRequestLoaded(false);
      }
   }, [isGetLoading]);

   useEffect((): void => {
      if (getSuccess && feeRequest && !feeRequestLoaded) {
         // map fee request data to display data
         setFeeRequestDisplay({ ...feeRequest, feeRequestId: feeRequestId });
         const feeRequestLineList: FeeRequestLineDisplay[] = [];
         feeRequest.feeRequestLine.forEach(l => {
            feeRequestLineList.push({
               feeRequestId: feeRequestId,
               feeRequestLineId: l.feeRequestLineId,
               feeRequestLineAmount: l.feeRequestLineAmount,
               feeTypeCode: l.feeTypeCode,
               feeTypeDescription: l.feeType && l.feeType.description,
               creditReason: l.creditReason,
               optional: l.optional,
               paid: l.paid,
            });
         });
         setFeeRequestLines(feeRequestLineList);
         setFeeRequestLoaded(true);
      }
   }, [
      getSuccess,
      feeRequestLoaded,
      feeRequest,
      feeRequestLines,
      setFeeRequestDisplay,
      setFeeRequestLines,
      feeRequestId,
   ]);

   return (
      <Loading
         isLoading={isGetLoading || !feeRequestRequested || !feeRequestLoaded}
         isError={false}
         tryReload={FN_EMPTY_VOID}
         overlayOnChildren
      >
         <FeeRequest
            editMode={true}
            feeRequestDisplayInput={feeRequestDisplay}
            feeRequestLinesInput={feeRequestLines}
         />
      </Loading>
   );
};

export default React.memo(EditFeeRequest);
