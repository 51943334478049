import React, { useState, useCallback } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectIsSuccess,
   selectIsLoading,
   selectErrorMessage,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   selectPaymentMethod,
   PaymentMethodActions,
   PaymentMethodTypes,
} from 'State/Redux/PaymentMethodRedux';
import {
   DefaultPaymentMethodModel,
   validatePaymentMethod,
} from 'Models/PaymentMethod/Dto/PaymentMethod';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const AddPaymentMethod = (): JSX.Element => {
   const paymentMethod = useSelector(selectPaymentMethod);
   const isLoading = useSelector(
      selectIsLoading([PaymentMethodTypes.ADD_PAYMENT_METHOD_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([PaymentMethodTypes.ADD_PAYMENT_METHOD_REQUEST])
   );
   const isError = useSelector(
      selectIsError([PaymentMethodTypes.ADD_PAYMENT_METHOD_REQUEST])
   );
   const getErrorMessage = useSelector(
      selectErrorMessage(PaymentMethodTypes.ADD_PAYMENT_METHOD_REQUEST)
   );

   const dispatch = useDispatch();

   const [paymentMethodModel, setPaymentMethodModel] = useState(
      DefaultPaymentMethodModel
   );

   const [addClicked, setAddClicked] = useState(false);

   const onAdd = useCallback((): void => {
      dispatch(
         PaymentMethodActions.addPaymentMethodRequest({
            ...paymentMethodModel,
         })
      );
      setAddClicked(true);
   }, [dispatch, paymentMethodModel]);

   if (addClicked && isSuccess && paymentMethod !== null) {
      toast.success(
         `New payment method "${paymentMethod.paymentMethodCode}" created!`
      );
      setAddClicked(false);
      setPaymentMethodModel(DefaultPaymentMethodModel);
   }

   if (addClicked && isError && paymentMethod === null) {
      toast.error(getErrorMessage);
   }
   const isValidForm = Object.values(
      validatePaymentMethod(paymentMethodModel)
   ).every((v): boolean => !!v);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add Payment Method"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Payment Code"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Payment Code"
                     value={paymentMethodModel.paymentMethodCode}
                     maxLength={4}
                     onChange={(val): void => {
                        const newModel = {
                           ...paymentMethodModel,
                           paymentMethodCode: val.toString(),
                        };
                        setPaymentMethodModel(newModel);
                     }}
                     required
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Description"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Description"
                     value={paymentMethodModel.description}
                     onChange={(val): void => {
                        const newModel = {
                           ...paymentMethodModel,
                           description: val.toString(),
                        };
                        setPaymentMethodModel(newModel);
                     }}
                     required
                  />
               </div>

               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="GL Code"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="GL Code"
                     value={paymentMethodModel.glcode}
                     onChange={(val): void => {
                        const newModel = {
                           ...paymentMethodModel,
                           glcode: val.toString(),
                        };
                        setPaymentMethodModel(newModel);
                     }}
                     required
                  />
               </div>

               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
                     disabled={!isValidForm}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        onAdd();
                     }}
                     onClick={(): void => {
                        onAdd();
                     }}
                  >
                     Add
                  </LoadingButton>
               </div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddPaymentMethod;
