import React, { useState, useEffect } from 'react';
import './Style.scss';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import TextField from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields';
import { useUserRoleList } from 'Util/Helpers/Metadata';
import LoadingButton from 'Components/Common/LoadingButton';
import Button from 'react-md/lib/Buttons';
import SearchResult from './Views/SearchResult';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectAdminUserSearchResult,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import UserEditModel, {
   DefaultUserEditModel,
} from 'Models/SuperUser/UserEditModel';
import { selectIsLoading, selectErrorMessage } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { isEnterKeyPress } from 'Util/Helpers/Input';

const SearchUser = (): JSX.Element => {
   const roleList = useUserRoleList('', true);
   const dispatch = useDispatch();
   const searchResult = useSelector(selectAdminUserSearchResult);
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SEARCH_ADMIN_USER_REQUEST])
   );
   const errorMessage = useSelector(
      selectErrorMessage(ContactTypes.SEARCH_ADMIN_USER_REQUEST)
   );
   const [initiated, setInitiated] = useState();
   const [searchFilter, setSearchFilter] = useState<UserEditModel>(
      DefaultUserEditModel
   );
   const [searchTriggered, setSearchTriggered] = useState(false);

   const searchUsers = (): void => {
      dispatch(ContactActions.searchAdminUserRequest(searchFilter));
      setSearchTriggered(true);
   };

   const reset = (): void => {
      setSearchFilter(DefaultUserEditModel);
      dispatch(ContactActions.searchAdminUserReset());
   };

   useEffect((): void => {
      if (!initiated) {
         setInitiated(true);
         dispatch(ContactActions.searchAdminUserReset());
      }
   }, [initiated, dispatch]);

   useEffect((): void => {
      if (searchTriggered && errorMessage) {
         toast.error(errorMessage);
         setSearchTriggered(false);
      }
   }, [searchTriggered, errorMessage]);

   const renderControls = (): JSX.Element => {
      return (
         <div className="controls">
            <LoadingButton
               flat
               primary
               swapTheming
               isLoading={isLoading}
               onClick={searchUsers}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) searchUsers();
               }}
            >
               Search
            </LoadingButton>
            <Button
               flat
               secondary
               className="red-btn"
               swapTheming
               onClick={reset}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  reset();
               }}
            >
               Reset
            </Button>
         </div>
      );
   };

   return (
      <ExpansionList className="md-cell md-cell--12 search-user-container">
         <ExpansionPanel
            label="Search User(s)"
            footer={null}
            expanded
            expanderIcon={<></>}
            className="add-user-panel"
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="add-user-userid"
                     label="User Id"
                     lineDirection="center"
                     placeholder="User Id"
                     className="md-cell md-cell--bottom md-cell--12"
                     maxLength={50}
                     value={searchFilter.userName}
                     onChange={(e): void => {
                        setSearchFilter({
                           ...searchFilter,
                           userName: e.toString(),
                        });
                     }}
                  />
                  <TextField
                     floating
                     id="add-user-fullname"
                     label="Full Name"
                     lineDirection="center"
                     placeholder="Full Name"
                     className="md-cell md-cell--bottom md-cell--12"
                     maxLength={100}
                     value={searchFilter.fullName}
                     onChange={(e): void => {
                        setSearchFilter({
                           ...searchFilter,
                           fullName: e.toString(),
                        });
                     }}
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="add-user-email"
                     label="Email"
                     lineDirection="center"
                     placeholder="Email"
                     className="md-cell md-cell--bottom md-cell--12"
                     maxLength={100}
                     value={searchFilter.emailAddress}
                     onChange={(e): void => {
                        setSearchFilter({
                           ...searchFilter,
                           emailAddress: e.toString(),
                        });
                     }}
                  />
                  <SelectField
                     floating
                     id="add-user-role"
                     label="Role"
                     placeholder="Select Role"
                     className="md-cell md-cell--bottom md-cell--12 role-select"
                     position={SelectField.Positions.BELOW}
                     menuItems={roleList}
                     value={searchFilter.roleCode}
                     onChange={(e): void => {
                        setSearchFilter({
                           ...searchFilter,
                           roleCode: e.toString(),
                        });
                     }}
                  />
               </div>
               <div className="md-cell md-cell--12">{renderControls()}</div>
            </div>
         </ExpansionPanel>
         {searchResult && (
            <SearchResult users={searchResult} triggerSearch={searchUsers} />
         )}
      </ExpansionList>
   );
};

export default SearchUser;
