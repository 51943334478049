import React from 'react';
import { Avatar } from 'react-md/lib/Avatars';
import { IconSeparator } from 'react-md/lib/Helpers';
import './UserCard.scss';
import FaIcon from '../FaIcon/FaIcon';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import Contact from 'Models/Contact/Data/Contact';
import { CONTACT_STATUS_ACTIVE } from 'Util/Constants/Contact';
import { selectContact } from 'State/Redux/ContactRedux';
import DefaultIfNull from 'Components/Common/DefaultIfNull';
import { getMailingAddressLine } from 'Util/Helpers/Address';
import {
   isContactFlagged,
   getDisplayRegistrationNumber,
   getDisplayContactName,
   getDisplayDateOrUrl,
} from 'Util/Helpers/Contact';

interface UserCardProps {
   showContactDetails?: boolean;
   overrideContact?: Contact;
}

interface UserContactDetailsProps {
   contact: Contact;
}

const UserContactDetails = ({
   contact,
}: Readonly<UserContactDetailsProps>): JSX.Element => {
   const address = getMailingAddressLine(contact);
   return (
      <div className="md-cell md-cell--6 contact-wrapper">
         <p className="contact-details">
            <FaIcon icon="mobile-alt" />{' '}
            <DefaultIfNull displayElement={contact.mobileNumber} />
         </p>

         <p className="contact-details">
            <FaIcon icon="paper-plane" />{' '}
            <DefaultIfNull displayElement={address} />
         </p>
      </div>
   );
};

const UserCard = ({
   showContactDetails = true,
   overrideContact,
}: Readonly<UserCardProps>): JSX.Element => {
   let contact = useSelector(selectContact);
   if (overrideContact) {
      contact = overrideContact;
   }

   const photo = get(contact, 'contactPhoto.fileLocation', '');
   const contactStatus = get(contact, 'contactStatus.description', '');
   const textColor =
      contact.contactStatusCode === CONTACT_STATUS_ACTIVE
         ? 'text-green'
         : 'text-red';

   const Item = ({
      label,
      children,
   }: {
      label: JSX.Element;
      children: JSX.Element;
   }): JSX.Element => (
      <IconSeparator label={label} iconBefore className="md-cell md-cell--10">
         {children}
      </IconSeparator>
   );

   return (
      <div className="md-grid md-cell--12 user-card">
         <div
            className={'md-cell md-cell--' + (showContactDetails ? '6' : '12')}
         >
            <div className="user-card">
               <Item
                  label={
                     <div title={getDisplayContactName(contact)}>
                        {getDisplayContactName(contact) + ' '}
                        {isContactFlagged(contact) && (
                           <FaIcon className="flag-selected" icon="flag" />
                        )}
                        <p className="birthday">
                           {getDisplayDateOrUrl(contact)}
                        </p>
                     </div>
                  }
               >
                  <Avatar className="profile-photo" src={photo} />
               </Item>

               <div className="right-container md-cell md-cell--2">
                  <div className="licence-id-wrapper">
                     <h2 className="licence-id">
                        {getDisplayRegistrationNumber(contact, '-')}
                     </h2>
                  </div>
                  <h2 className={`status-text nowrap ${textColor}`}>
                     {contactStatus}
                  </h2>
               </div>
            </div>
         </div>
         {showContactDetails && <UserContactDetails contact={contact} />}
      </div>
   );
};

export default UserCard;
