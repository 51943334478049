import {
   getFullAddress,
   DefaultAddressModel,
   AddressModel,
} from '../AddressModel';
import CountryInput from 'Components/Common/CountryInput/CountryInput';
import React, { useState } from 'react';
import TextField from 'react-md/lib/TextFields';
import Button from 'react-md/lib/Buttons';
import {
   isNullOrWhiteSpace,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import CityInput from 'Components/Common/CityInput/CityInput';
import { useDispatch, useSelector } from 'react-redux';
import {
   EnumDtoActions,
   selectCities,
   selectPostCodeCities,
} from 'State/Redux/EnumRedux';
import { DEFAULT_COUNTRY_CODE } from 'Util/Constants/Address';

interface AddAddressProps {
   onCancel: () => void;
   onSave: (val: AddressModel) => void;
}

const AddAddress = ({
   onCancel,
   onSave,
}: Readonly<AddAddressProps>): JSX.Element => {
   const [addressLine1Val, setAddressLine1Val] = useState(false);
   const [cityVal, setCityVal] = useState(false);
   const [postalCodeVal, setPostalCodeVal] = useState(false);
   const [address, setAddress] = useState(DefaultAddressModel);

   const dispatch = useDispatch();

   const citiesDto = useSelector(selectCities);
   const postCitiesDto = useSelector(selectPostCodeCities);

   React.useEffect(() => {
      if (!citiesDto || citiesDto.length === 0) {
         dispatch(EnumDtoActions.getCitiesRequest());
      }
      if (!postCitiesDto || postCitiesDto.length === 0) {
         dispatch(EnumDtoActions.getPostCodeCitiesRequest());
      }
   }, [citiesDto, postCitiesDto, dispatch]);

   const validateInputs = (): boolean => {
      const aval = isNullOrWhiteSpace(address.addressLine1);
      const cval =
         isNullOrWhiteSpace(address.city) || address.city === 'Select a City';
      const pval =
         isNullOrWhiteSpace(address.postcode) ||
         (postCitiesDto.filter(
            x => x.city === address.city && x.postCode === address.postcode
         ).length === 0 &&
            address.countryCode === DEFAULT_COUNTRY_CODE);
      setAddressLine1Val(aval);
      setCityVal(cval);
      setPostalCodeVal(pval);

      return aval || cval || pval;
   };

   const handleSave = (): void => {
      if (!validateInputs()) {
         const newAddress = {
            ...address,
            fullAddress: getFullAddress(address),
         };
         setAddress(newAddress);
         onSave(newAddress);
      }
   };

   return (
      <>
         <TextField
            floating
            id="add-address-line1"
            label="Address Line 1"
            lineDirection="center"
            placeholder="Address Line 1"
            className="md-cell md-cell--bottom md-cell--12"
            inputClassName={getInputValidationClassName(addressLine1Val)}
            required
            errorText="Please enter the address line 1"
            error={addressLine1Val}
            defaultValue={address.addressLine1}
            onChange={(val): void => {
               setAddress({ ...address, addressLine1: val.toString() });
            }}
         />
         <TextField
            floating
            id="add-address-suburb"
            label="Suburb"
            lineDirection="center"
            placeholder="Suburb"
            className="md-cell md-cell--bottom md-cell--6"
            errorText="Please enter the Suburb"
            defaultValue={address.suburb}
            onChange={(val): void => {
               setAddress({ ...address, suburb: val.toString() });
            }}
         />
         {address.countryCode !== DEFAULT_COUNTRY_CODE && (
            <TextField
               floating
               id="add-address-city"
               label="City"
               lineDirection="center"
               placeholder="City"
               className="md-cell md-cell--bottom md-cell--6"
               inputClassName={getInputValidationClassName(cityVal)}
               required
               errorText="Please enter the City"
               error={cityVal}
               defaultValue={address.city}
               onChange={(val): void => {
                  setAddress({ ...address, city: val.toString() });
               }}
            />
         )}
         {address.countryCode === DEFAULT_COUNTRY_CODE && (
            <CityInput
               id="add-address-city-dropdown"
               label="City"
               placeholder="Select City"
               className="md-cell md-cell--bottom md-cell--6 country-input"
               required
               errorText="Please enter the City"
               error={cityVal}
               value={address.city}
               onChange={(val): void => {
                  setAddress({ ...address, city: val.toString() });
               }}
               listDropdown={['Select a City', ...citiesDto.map(x => x.name)]}
            />
         )}
         <TextField
            floating
            id="add-address-postcode"
            label="Postcode "
            lineDirection="center"
            placeholder="Postcode "
            type="number"
            className="md-cell md-cell--bottom md-cell--6"
            inputClassName={getInputValidationClassName(postalCodeVal)}
            required
            errorText={
               address.postcode
                  ? 'Please enter a valid Postcode'
                  : 'Please enter the Postcode'
            }
            error={postalCodeVal}
            defaultValue={address.postcode}
            onChange={(val): void => {
               setAddress({ ...address, postcode: val.toString() });
            }}
         />
         <CountryInput
            id="add-address-country"
            label="Country"
            placeholder="Select Country"
            className="md-cell md-cell--bottom md-cell--6 country-input"
            errorText="Please enter the Country"
            required
            onChange={(val): void => {
               setAddress({ ...address, countryCode: val.toString() });
            }}
         />
         <div className="add-address-controls">
            <Button
               flat
               primary
               swapTheming
               onClick={(): void => handleSave()}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) handleSave();
               }}
            >
               Save Address
            </Button>
            <Button
               flat
               primary
               swapTheming
               onClick={(): void => onCancel()}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) onCancel();
               }}
            >
               Cancel
            </Button>
         </div>
      </>
   );
};

export default AddAddress;
