export const IS_ASYNC_ACTION = /(.*)_(REQUEST|SUCCESS|FAILURE)/;
export const IS_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const IS_PHONE_NUMBER = /^[+]?[0-9]{9,13}$/;
export const IS_NUMBER = /^[0-9- ]+$/;
export const IS_AMOUNT = /^[-+]?[0-9]+(?:,[0-9]{3})*(?:\.[0-9]{1,2})?$/gim;
export const IS_STRING_WITH_AT_LEAST_ONE_LETTER = /^\d*[a-zA-Z][a-zA-Z0-9\s]*$/gim;
export const IS_LICENCE_NUMBER = /^[eE]*\d{1,5}$/;
export const IS_SEARCH_LICENCE_NUMBER = /^(e|crc|tp|un)*\d{1,5}$/i;
export const IS_VALID_SEARCH_TEXT = /^[a-zA-Z\-']+[a-zA-Z\-' ]+/;
export const IS_POSTCODE = /^([a-zA-Z]|\d){3}/;
export const TO_CURRENCY = /\d(?=(\d{3})+\.)/g;
export const IS_REQUEST_ACTION = /(.*)_(REQUEST)/;
export const IS_ALPHANUMERIC_TEXT = /^[a-zA-Z0-9]+$/;
export const IS_URL = /\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/g;
export const IS_USER_ID = /^[a-zA-Z0-9\-_]{0,50}$/;
