import React, { useState, useEffect, ReactText } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'react-md/lib/TextFields';
import {
   SupervisionActions,
   SupervisionTypes,
   selectSupervisionSearchResult,
} from 'State/Redux/SupervisionRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { getSupervisionStatus } from 'Util/Helpers/Supervision';
import { selectContact } from 'State/Redux/ContactRedux';
import { useLicenceSupervisions } from 'Util/Helpers/Metadata';
import SupervisionReadonly from 'Models/Supervision/Data/Supervision';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import './SupervisionInput.scss';
import {
   LICENCE_SUPERVISION_CONDITION_DESCRIPTION,
   LICENCE_SUPERVISION_REG_DESCRIPTION,
} from 'Util/Constants/Conditions';

interface SupervisionInputCellProps {
   selectedSupervision: SupervisionReadonly;
   onSave: (supervisionRegistrationNumber: string) => void;
   onChange: (currentInput: string) => void;
   disabled: boolean;
   value: string;
}

const SupervisionInput = ({
   selectedSupervision,
   onSave,
   onChange,
   disabled,
   value,
}: Readonly<SupervisionInputCellProps>): JSX.Element => {
   const supervisionSearchResult = useSelector(selectSupervisionSearchResult);
   const currentContact = useSelector(selectContact);
   const isLoadingSearchSupervisor = useSelector(
      selectIsLoading([SupervisionTypes.SEARCH_SUPERVISOR_REQUEST])
   );
   const dispatch = useDispatch();
   const [shouldValidate, setShouldValidate] = useState(false);
   const licenceSupervisions = useLicenceSupervisions();
   const supervisionStatus = getSupervisionStatus(
      licenceSupervisions,
      supervisionSearchResult,
      selectedSupervision.licence.licenceTypeCode,
      selectedSupervision.licence.disciplineCode,
      selectedSupervision.licence.licenceYearNumber,
      currentContact
   );

   const invalidInput =
      supervisionStatus.supervisorStatus !== 'Valid' &&
      !isLoadingSearchSupervisor &&
      shouldValidate &&
      !!value &&
      value.length > 0 &&
      value !== LICENCE_SUPERVISION_CONDITION_DESCRIPTION;

   useEffect(() => {
      if (supervisionSearchResult && !invalidInput && shouldValidate) {
         setShouldValidate(false);
         onSave(supervisionSearchResult.registrationNumber.toString());
      }
   }, [
      supervisionSearchResult,
      onSave,
      invalidInput,
      supervisionStatus,
      shouldValidate,
   ]);
   const quickSearchErrorClass = invalidInput ? 'quick-search-error' : '';

   const searchSupervisor = (): void => {
      if (!isLoadingSearchSupervisor) {
         setShouldValidate(true);
         dispatch(SupervisionActions.searchSupervisorRequest(value));
      }
   };

   const changeSupervisor = (val: ReactText): void => {
      const inputValue = val.toString();
      onChange(inputValue);
   };

   useEffect(() => {
      if (value) {
         dispatch(SupervisionActions.searchSupervisorRequest(value));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="md-grid supervision-input">
         <TextField
            id="floating-center-title"
            label="Supervisor's Licence Number"
            lineDirection="center"
            placeholder="Supervisor"
            className="md-cell md-cell--6"
            inputClassName={getInputValidationClassName(invalidInput)}
            value={
               value === LICENCE_SUPERVISION_CONDITION_DESCRIPTION ||
               value === LICENCE_SUPERVISION_REG_DESCRIPTION
                  ? ''
                  : value
            }
            errorText={supervisionStatus.statusMessage}
            error={invalidInput}
            disabled={disabled}
            // style the height on the input element and remove any padding
            inlineIndicator={
               <FaIcon
                  icon="search"
                  className={`quick-search-icon ${quickSearchErrorClass}`}
                  onClick={(): void => {
                     searchSupervisor();
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) searchSupervisor();
                  }}
               />
            }
            onChange={changeSupervisor}
            onBlur={(): void => {
               searchSupervisor();
            }}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) {
                  searchSupervisor();
               }
            }}
         />
         <TextField
            disabled
            id="floating-center-title"
            label="Supervisor Name"
            lineDirection="center"
            placeholder="Supervisor"
            className="md-cell md-cell--6"
            value={
               supervisionSearchResult &&
               value &&
               value !== LICENCE_SUPERVISION_CONDITION_DESCRIPTION
                  ? supervisionSearchResult.fullName
                  : ''
            }
         />
      </div>
   );
};

export default React.memo(SupervisionInput);
