import React, { useState, useEffect } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { Button } from 'react-md/lib/Buttons';
import { DialogContainer } from 'react-md/lib/Dialogs';
import * as ADDRESS from 'Util/Constants/Address';
import { AddressModel } from './AddressModel';
import AddAddress from './Views/AddAddress';
import { InputProps } from '../Shared/InputProps';
import './style.scss';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface AddressInputProps extends InputProps {
   addressParams?: string;
   maxLength?: number;
   onChange?: (val: string | number) => void;
   onSubmit?: (val: AddressModel) => void;
}

export interface AdminWindow extends Window {
   /*eslint-disable */
   AddressFinder: any;
   /*eslint-enable */
}
declare let window: AdminWindow;

const AddressInput = ({
   addressParams,
   maxLength,
   onChange,
   onSubmit,
   error,
   required,
   id,
   label,
   placeholder,
   className,
   defaultValue,
   disabled,
   value,
   errorText,
}: Readonly<AddressInputProps>): JSX.Element => {
   const [allowManualSelection, setAllowManualSelection] = useState<boolean>(
      false
   );
   const [, setResultSelected] = useState<boolean>(false);
   const [addressInputVisible, setAddressInputVisible] = useState<boolean>(
      false
   );
   const [address, setAddress] = useState<string>();
   /*eslint-disable */
   const [widget, setWidget] = useState<any>();
   const [textVal, setTextVal] = useState<any>();
   /*eslint-enable */
   const [errorState, setError] = useState<boolean | undefined>(false);

   useEffect((): void => {
      setError(error);
   }, [error]);

   const handleChange = (val: string): void => {
      setAddress(val);
      setError(false);
      if (onChange) {
         onChange(val);
      }
   };

   const onBlur = (): void => {
      if (!address && required) {
         setError(true);
      } else {
         setError(false);
      }
   };

   const addNewAddress = (val: AddressModel): void => {
      setAddressInputVisible(false);
      if (onSubmit) {
         setAddress(val.fullAddress);
         setResultSelected(true);
         onSubmit(val);
         setTextVal({ value: val.fullAddress });
         setAllowManualSelection(false);
         setError(false);
      }
   };

   // Initial load
   useEffect((): void => {
      if (widget) return;

      const afWidget = new window.AddressFinder.Widget(
         document.getElementById(id),
         // key is okay to be held locally as addressfinder uses domain source to clear access
         ADDRESS.ADDRESS_FINDER_KEY,
         ADDRESS.ADDRESS_FINDER_DEFAULT_COUNTRY,
         {
            /*eslint-disable */
            address_params: addressParams,
            manual_style: true, // allow manual style injection (from styles.css)
            byline: false, // don't show powered by AddressFinder in the footer in production
            empty_content: ADDRESS.NO_RESULTS_USER_STRING,
            /*eslint-enable */
         }
      );
      afWidget.on(ADDRESS.WIDGET_NO_RESULTS_SELECTED, (): void => {
         // no results were found
         setResultSelected(false);
         setAllowManualSelection(true);
         // clear parent state data
         // handleChange
         setAddress('');
         setError(false);
         if (onChange) {
            onChange('');
         }
      });
      afWidget.on(ADDRESS.WIDGET_UPDATED_AND_NO_RESULTS_SELECTED, (): void => {
         // results were updated but not selected
         setResultSelected(false);
         // handleChange
         setAddress('');
         setError(false);
         if (onChange) {
            onChange('');
         }
      });
      afWidget.on(
         ADDRESS.WIDGET_RESULT_SELECTED,
         (fullAddress: string, metaData: string): void => {
            // selection registered. Store in local state
            const selected = new window.AddressFinder.NZSelectedAddress(
               fullAddress,
               metaData
            );
            // addressfinder wraps values in functions, pull these out and set them to a local obj
            const address: AddressModel = {
               /*eslint-disable */
               addressLine1: selected.address_line_1(),
               addressLine2: selected.address_line_2(),
               /*eslint-enable */
               suburb: selected.suburb(),
               city: selected.city(),
               postcode: selected.postcode(),
               fullAddress: selected.fullAddress,
               countryCode: ADDRESS.DEFAULT_COUNTRY_CODE,
            };
            setAddress(selected.fullAddress);
            setResultSelected(true);
            setAllowManualSelection(false);
            // send resulting selection including all address finder fields up to parent for processing
            if (onSubmit) {
               onSubmit(address);
               setError(false);
            }
         }
      );
      setWidget(afWidget);
   }, [widget, id, onSubmit, onChange, addressParams]);

   return (
      <div className="address-container">
         <TextField
            id={id}
            label={label}
            placeholder={placeholder}
            className={className}
            onChange={handleChange}
            defaultValue={defaultValue}
            value={value}
            required={required}
            disabled={disabled}
            errorText={errorText}
            error={errorState}
            onFocus={(): void => setTextVal({})}
            maxLength={maxLength}
            onBlur={onBlur}
            {...textVal}
         />
         {allowManualSelection && (
            <div className="address-controls">
               <Button
                  className="button-enter-manual"
                  label="Enter Address Manually"
                  onClick={(): void => setAddressInputVisible(true)}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress))
                        setAddressInputVisible(true);
                  }}
               />
            </div>
         )}
         <DialogContainer
            id="dialog-container-address-input"
            visible={addressInputVisible}
            focusOnMount={false}
            containFocus={false}
            onHide={FN_EMPTY_VOID}
            contentClassName="md-grid"
            title="Add Custom Address"
            lastChild
            disableScrollLocking
            renderNode={document.body}
         >
            <AddAddress
               onCancel={(): void => setAddressInputVisible(false)}
               onSave={(val): void => addNewAddress(val)}
            />
         </DialogContainer>
      </div>
   );
};

export default AddressInput;
