import React, { useState, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash-es';
import { Card, CardText } from 'react-md/lib/Cards';
import {
   selectContactScreenData,
   ContactTypes,
   ContactActions,
} from 'State/Redux/ContactRedux';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
   ContactEditModel,
   DefaultContactEditModel,
} from 'Models/Contact/Data/ContactEditModel';
import Loading from 'Components/Common/Loading';
import {
   getBusinessAddressLine,
   getPhysicalAddressLine,
   getMailingAddressLine,
   isValidPhysicalAddress,
} from 'Util/Helpers/Address';
import { Redirect } from 'react-router';
import '../../Contact.scss';
import {
   selectIsLoading,
   selectErrorMessage,
   selectIsError,
   selectIsSuccess,
} from 'State/Redux/AsyncRedux';
import ValidationModel from 'Components/Common/ModelValidator/ValidationModel';
import { validateModel } from 'Components/Common/ModelValidator/Helper';
import { PersonalSection, PublicRegisterSection } from '../CreateContact/Views';
import HeaderSection from './Views/HeaderSection';
import { CONTACT_TYPE_EMPLOYER } from 'Util/Constants/Contact';
import {
   SUCCESS_SAVE,
   ERROR_REGISTRATION_EXIST,
   ERROR_POBOX,
} from '../CreateContact/CreateContactConstant';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import LoadingButton from 'Components/Common/LoadingButton';

export type CompanyContactValidationFields = Pick<
   ContactEditModel,
   | 'companyName'
   | 'registrationNumber'
   | 'firstName'
   | 'lastName'
   | 'dateOfBirth'
   | 'emailAddress'
   | 'mobileNumber'
   | 'phoneNumber'
   | 'publicPhone'
   | 'publicEmail'
   | 'contactStatusCode'
   | 'mailingAddressLine1'
   | 'physicalAddressLine1'
>;

const CONTACT_VALIDATION_MODEL: ValidationModel<CompanyContactValidationFields> = {
   errorMessage: 'The model is not valid 1',
   fields: {
      companyName: {
         validation: 'text',
         error: false,
         required: true,
      },
      registrationNumber: {
         validation: 'registration-number',
         error: false,
      },
      firstName: {
         validation: 'text',
         required: true,
         error: false,
      },
      lastName: {
         validation: 'text',
         required: true,
         error: false,
      },
      dateOfBirth: {
         validation: 'text',
         error: false,
      },
      emailAddress: {
         validation: 'email',
         error: false,
      },
      mobileNumber: {
         validation: 'phone-number',
         error: false,
      },
      phoneNumber: {
         validation: 'phone-number',
         error: false,
      },
      publicPhone: {
         validation: 'phone-number',
         error: false,
      },
      publicEmail: {
         validation: 'email',
         error: false,
      },
      contactStatusCode: {
         validation: 'text',
         error: false,
         required: true,
      },
      mailingAddressLine1: {
         validation: 'text',
         error: false,
         required: false,
      },
      physicalAddressLine1: {
         validation: 'text',
         error: false,
         required: false,
      },
   },
};

interface CompanyCreateContactProps {
   contactId?: number;
   editMode?: boolean;
   onSaveSuccess?: () => void;
}

const CreateCompanyContact = ({
   onSaveSuccess,
   editMode,
   contactId,
}: Readonly<CompanyCreateContactProps>): JSX.Element => {
   const screenData = useSelector(selectContactScreenData);
   const isError = useSelector(
      selectIsError([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );
   const saveErrorMessage = useSelector(
      selectErrorMessage(ContactTypes.SAVE_CONTACT_DATA_REQUEST)
   );
   const isLoading = useSelector(
      selectIsLoading([
         ContactTypes.SAVE_CONTACT_DATA_REQUEST,
         ContactTypes.GET_CONTACT_SCREEN_DATA_REQUEST,
      ])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );
   const dispatch = useDispatch();

   // states:
   const [isSaveTriggered, setIsSaveTriggered] = useState(false);
   const [inputModel, setInputModel] = useState(DefaultContactEditModel);
   const [isEditDataLoaded, setIsEditDataLoaded] = useState(false);
   const [isValidated, setIsValidate] = useState(false);
   const [validationModel, setValidationModel] = useState(
      CONTACT_VALIDATION_MODEL
   );
   const title = editMode
      ? 'Edit Employer Licence company'
      : 'Create new Employer Licence company';
   let validationResult = isValidated
      ? validateModel<CompanyContactValidationFields>(
           inputModel,
           validationModel
        )
      : undefined;

   const tryFetchData = useCallback((): void => {
      if (editMode && contactId) {
         dispatch(ContactActions.getContactScreenDataRequest(contactId));
      }
   }, [dispatch, editMode, contactId]);

   useEffect((): (() => void) => {
      tryFetchData();
      return (): void => {
         dispatch(ContactActions.getContactScreenDataReset());
      };
   }, [dispatch, tryFetchData]);

   useEffect(() => {
      if (editMode && screenData && !isEditDataLoaded) {
         const contactInput = {
            ...cloneDeep<ContactEditModel>(screenData),
            // set full address
            mailingAddressFull: getMailingAddressLine(screenData),
            physicalAddressFull: getPhysicalAddressLine(screenData),
            businessAddressFull: getBusinessAddressLine(screenData),
            contactId: screenData.contactId,
         };
         setInputModel(contactInput);
         setIsEditDataLoaded(true);
      }
   }, [screenData, editMode, isEditDataLoaded]);

   useEffect((): void => {
      if (isError && saveErrorMessage && isSaveTriggered) {
         setIsSaveTriggered(false);
         const errorMessage = saveErrorMessage;
         toast.error(errorMessage);
         if (errorMessage && errorMessage.includes(ERROR_REGISTRATION_EXIST)) {
            const newValidationModel: ValidationModel<CompanyContactValidationFields> = {
               ...validationModel,
               fields: {
                  ...validationModel.fields,
                  registrationNumber: {
                     validation: 'number',
                     error: true,
                     errorMessage: errorMessage as string,
                  },
               },
            };

            // force registration number to be invalid
            setValidationModel(newValidationModel);
         }
      }
   }, [saveErrorMessage, isSaveTriggered, validationModel, isError]);

   useEffect((): void => {
      // save successful
      if (screenData && isSaveTriggered && isSuccess) {
         setIsSaveTriggered(false);
         toast.success(SUCCESS_SAVE);

         if (onSaveSuccess) {
            onSaveSuccess();
         }
      }
   }, [isSaveTriggered, isSuccess, onSaveSuccess, screenData]);

   if (!editMode && screenData && isSaveTriggered && isSuccess) {
      return <Redirect to={`/contact/${screenData.contactId}`} />;
   }

   const resetRegistrationValidation = (): void => {
      const newValidationModel: ValidationModel<CompanyContactValidationFields> = {
         ...validationModel,
         fields: {
            ...validationModel.fields,
            registrationNumber: {
               validation: 'number',
               error: false,
            },
         },
      };

      // clear existing validation to use default
      setValidationModel(newValidationModel);
   };

   const validateAddress = (): boolean => {
      // physical address validation:
      if (!isValidPhysicalAddress(inputModel.physicalAddressLine1)) {
         toast.error(ERROR_POBOX);
         return false;
      }
      return true;
   };

   const validateAndSave = (): void => {
      resetRegistrationValidation();
      setIsValidate(true);
      validationResult = validateModel<CompanyContactValidationFields>(
         inputModel,
         validationModel
      );
      if (!validationResult || validationResult.error) {
         toast.error('Validation Failed: Please enter all required fields');
         return;
      }

      if (!validateAddress()) {
         return;
      }

      // save the contact details:
      setIsSaveTriggered(true);
      const newInputModel = {
         ...inputModel,
         contactTypeCode: CONTACT_TYPE_EMPLOYER,
      };
      setInputModel(newInputModel);
      dispatch(ContactActions.saveContactDataRequest(newInputModel));
   };

   return (
      <Loading
         isLoading={(editMode && !isEditDataLoaded) || isLoading}
         isError={false}
         tryReload={tryFetchData}
      >
         <Card className="create-contact md-block-centered md-cell--12">
            <CardText>
               <h2>{title}</h2>
               <HeaderSection
                  model={inputModel}
                  updateModel={setInputModel}
                  validationResult={validationResult}
                  isEditMode={editMode}
               />
               <hr />
               <PersonalSection
                  model={inputModel}
                  updateModel={setInputModel}
                  validationResult={validationResult}
                  companyProfile
               />
               <PublicRegisterSection
                  model={inputModel}
                  updateModel={setInputModel}
                  validationResult={validationResult}
                  hidePublicRegister={true}
               />
               <div className="controls">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     onClick={(): void => validateAndSave()}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) validateAndSave();
                     }}
                  >
                     Save and Close
                  </LoadingButton>
               </div>
            </CardText>
         </Card>
      </Loading>
   );
};

export default React.memo(CreateCompanyContact);
