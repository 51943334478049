import React, { useEffect, useState, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { useSelector, useDispatch } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import {
   ApplicationActions,
   ApplicationTypes,
   selectApplicationForEdit,
} from 'State/Redux/ApplicationRedux';
import UserCard from 'Components/Common/UserCard/UserCard';
import { CriteriaViewPanel } from './Panels/CriteriaViewPanel';
import Loading from 'Components/Common/Loading';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import { useParams } from 'react-router';
import Button from 'react-md/lib/Buttons';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { isApplicationEditable } from 'Util/Helpers/Application';
import { selectApplicationStatuses } from 'State/Redux/MetadataRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { Authorized } from 'Components/Common/Authorized';
import { PaymentActions, selectPaymentDetail } from 'State/Redux/PaymentRedux';

interface ApplicationUrlParameters {
   contactId: string;
   applicationId: string;
}

export const ApplicationViewEdit = (): JSX.Element => {
   const params = useParams<ApplicationUrlParameters>();
   const applicationId = params.applicationId;

   const dispatch = useDispatch();

   const loadApplication = (): void => {
      dispatch(
         ApplicationActions.getApplicationForOverviewRequest(applicationId)
      );
      dispatch(
         PaymentActions.getPaymentDetailRequest(
            Number(applicationId),
            null,
            null,
            null
         )
      );
   };
   useEffect(loadApplication, [dispatch, applicationId]);

   const applicationEdit = useSelector(selectApplicationForEdit);
   const applicationPayment = useSelector(selectPaymentDetail);
   const isLoading = useSelector(
      (state: StateReadonly): boolean =>
         selectIsLoading([
            ApplicationTypes.GET_APPLICATION_FOR_OVERVIEW_REQUEST,
         ])(state) ||
         !applicationEdit ||
         !applicationEdit.applicationId
   );
   const isError = useSelector(
      selectIsError([ApplicationTypes.GET_APPLICATION_FOR_OVERVIEW_REQUEST])
   );
   const [isEditing, setIsEditing] = useState(false);
   const disableEditing = useCallback((): void => setIsEditing(false), []);

   const applicationTitle = 'Application #' + applicationId.toString();

   const criteriaPanelTitle =
      applicationEdit && applicationEdit.applicationTypeDescription
         ? applicationTitle + ' - ' + applicationEdit.applicationTypeDescription
         : applicationTitle;

   const canEdit = isApplicationEditable(
      useSelector(selectApplicationStatuses),
      applicationEdit.applicationStatusCode
   );

   const toggleButton =
      canEdit && !isEditing ? (
         <Button
            icon
            className="plus-btn"
            onClick={(): void => setIsEditing(true)}
            onKeyUp={(evt): void => {
               if (isEnterKeyPress(evt)) setIsEditing(true);
            }}
         >
            <FaIcon icon="edit" />
            <p>Edit</p>
         </Button>
      ) : canEdit ? (
         <Button
            icon
            className="plus-btn"
            onClick={disableEditing}
            onKeyUp={(evt): void => {
               if (isEnterKeyPress(evt)) disableEditing();
            }}
         >
            <FaIcon icon="times" />
            <p>Reset</p>
         </Button>
      ) : (
         <></>
      );
   const guardedToggleButton = (
      <Authorized permissions={'Application.Update'}>{toggleButton}</Authorized>
   );

   return (
      <Loading
         isLoading={isLoading}
         isError={isError}
         tryReload={loadApplication}
      >
         <ExpansionList className="md-cell md-cell--12">
            <ExpansionPanel
               label="Applications"
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <UserCard />
               </div>
            </ExpansionPanel>
            <ExpansionPanel
               label={
                  <>
                     {criteriaPanelTitle} {guardedToggleButton}
                  </>
               }
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <CriteriaViewPanel
                  isEditing={isEditing}
                  disableEditing={disableEditing}
                  application={applicationEdit}
                  payment={applicationPayment}
               />
            </ExpansionPanel>
         </ExpansionList>
      </Loading>
   );
};
