import React, { useState, useCallback, useEffect } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectIsSuccess,
   selectIsLoading,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   selectLicenceType,
   LicenceTypeTypes,
   LicenceTypeActions,
} from 'State/Redux/LicenceTypeRedux';
import {
   DefaultLicenceTypeModel,
   validateLicenceTypes,
} from 'Models/LicenceType/Data/LienceType';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { useDisciplineList } from 'Util/Helpers/Metadata';
import SelectField from 'react-md/lib/SelectFields';

const AddLicenceType = (): JSX.Element => {
   const disciplineList = useDisciplineList('Select Discipline');

   const licenceType = useSelector(selectLicenceType);
   const isLoading = useSelector(
      selectIsLoading([LicenceTypeTypes.ADD_LICENCE_TYPE_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([LicenceTypeTypes.ADD_LICENCE_TYPE_REQUEST])
   );

   const isError = useSelector(
      selectIsError([LicenceTypeTypes.ADD_LICENCE_TYPE_REQUEST])
   );

   const dispatch = useDispatch();

   const [licenceTypeModel, setLicenceTypeModel] = useState(
      DefaultLicenceTypeModel
   );

   const [addClicked, setAddClicked] = useState(false);

   const onAdd = useCallback((): void => {
      dispatch(
         LicenceTypeActions.addLicenceTypeRequest({
            ...licenceTypeModel,
         })
      );
      setAddClicked(true);
   }, [dispatch, licenceTypeModel]);

   if (addClicked && isSuccess && licenceType !== null) {
      toast.success(
         `New licence type "${licenceType.licenceTypeCode}" created!`
      );
      setAddClicked(false);
      setLicenceTypeModel(DefaultLicenceTypeModel);
   }

   useEffect((): void => {
      if (addClicked && isError) {
         toast.error(`Cannot add licence, it may already exist.`);
         setAddClicked(false);
      }
   }, [addClicked, isError, licenceType]);

   const isValidForm = Object.values(
      validateLicenceTypes(licenceTypeModel)
   ).every((v): boolean => !!v);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add Licence Type"
            footer={null}
            expanded
            expanderIcon={<></>}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Code"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Code"
                     value={licenceTypeModel.licenceTypeCode}
                     maxLength={4}
                     onChange={(val): void => {
                        const newModel = {
                           ...licenceTypeModel,
                           licenceTypeCode: val.toString(),
                        };
                        setLicenceTypeModel(newModel);
                     }}
                     required
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Description"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Description"
                     value={licenceTypeModel.description}
                     onChange={(val): void => {
                        const newModel = {
                           ...licenceTypeModel,
                           description: val.toString(),
                        };
                        setLicenceTypeModel(newModel);
                     }}
                     required
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <SelectField
                     id="select-field-6"
                     lineDirection="center"
                     label="Discipline"
                     placeholder="Discipline"
                     className="md-cell md-cell--12"
                     menuItems={disciplineList}
                     position={SelectField.Positions.BELOW}
                     value={licenceTypeModel.disciplineCode}
                     onChange={(val): void => {
                        const newModel = {
                           ...licenceTypeModel,
                           disciplineCode: val.toString(),
                        };
                        setLicenceTypeModel(newModel);
                     }}
                  />
               </div>

               <div className="md-cell md-cell--6">
                  <Checkbox
                     id="is-audited"
                     className="md-cell md-cell--12"
                     label="Audited"
                     checked={licenceTypeModel.isAudited}
                     name="is-audited"
                     onChange={(val): void => {
                        const newModel = {
                           ...licenceTypeModel,
                           isAudited: val,
                        };
                        setLicenceTypeModel(newModel);
                     }}
                  />

                  <Checkbox
                     id="web-register"
                     className="md-cell md-cell--12"
                     label="Include in Web Register"
                     checked={licenceTypeModel.includeInWebRegister}
                     name="web-register"
                     onChange={(val): void => {
                        const newModel = {
                           ...licenceTypeModel,
                           includeInWebRegister: val,
                        };
                        setLicenceTypeModel(newModel);
                     }}
                  />
               </div>

               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
                     disabled={!isValidForm}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        onAdd();
                     }}
                     onClick={(): void => {
                        onAdd();
                     }}
                  >
                     Add
                  </LoadingButton>
               </div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddLicenceType;
