import { isValidSearchText } from './Search';
import { isNumber } from './Validation';
import { IS_SEARCH_LICENCE_NUMBER, IS_POSTCODE } from 'Util/Constants/Regex';

const MIN_POSTCODE_SEARCH_LENGTH = 3;
const MAX_POSTCODE_SEARCH_LENGTH = 10;

export const isValidSearchLicenceNumber = (licenceNumber: string): boolean => {
   if (!licenceNumber) return false;

   return IS_SEARCH_LICENCE_NUMBER.test(licenceNumber.trim());
};

export const isValidSearchName = isValidSearchText;
export const isValidSearchSuburb = isValidSearchText;
export const isValidSearchTown = isValidSearchText;

export const isValidSearchPostcode = (postcode: string): boolean => {
   if (!postcode) return false;

   const trimmedPostcode = postcode.trim();
   if (
      !trimmedPostcode ||
      trimmedPostcode.length < MIN_POSTCODE_SEARCH_LENGTH ||
      trimmedPostcode.length > MAX_POSTCODE_SEARCH_LENGTH
   ) {
      return false;
   }

   return IS_POSTCODE.test(trimmedPostcode);
};

export const isValidSearchLicenceType = (licenceType: string): boolean => {
   if (!licenceType) return false;

   const trimmedLicenceType = licenceType.trim();
   return !!trimmedLicenceType.length;
};

export const isValidSearchRegistrationType = (
   registrationType: string
): boolean => {
   if (!registrationType) return false;

   const trimmedRegistrationType = registrationType.trim();
   return !!trimmedRegistrationType.length;
};

export const isValidQuickSearch = (value: string): boolean => {
   if (!value) return false;

   const trimmed = value.trim();
   if (!trimmed) return false;

   if (isValidSearchLicenceNumber(value)) return true;

   // don't want numbers to be interpreted as names
   // as no one is named Mr. 1234567
   if (isNumber(value)) return false;

   const VALID_NAME_LENGTH = 3;
   return trimmed.length >= VALID_NAME_LENGTH;
};
