import LoadingButton from 'Components/Common/LoadingButton';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import QuestionSaveDto from 'Models/FitAndProper/Dto/QuestionSaveDto';
import React, { useEffect, useState } from 'react';
import { Card, CardText, TextField } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import './FitAndProperEdit.scss';
import {
   FitAndProperActions,
   FitAndProperTypes,
} from 'State/Redux/FitAndProperRedux';
import { selectErrorMessage, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { selectContact } from 'State/Redux/ContactRedux';

interface FitAndProperEditProps {
   answer: Readonly<QuestionDto> | undefined;
   onSaveSuccess?: () => void;
}

const OPTION_YES = 'Yes';
const OPTION_NO = 'No';

const FitAndProperEdit = ({
   answer,
   onSaveSuccess,
}: Readonly<FitAndProperEditProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const saveError = useSelector(
      selectErrorMessage(FitAndProperTypes.SAVE_ANSWER_BY_ID_REQUEST)
   );
   const saveSuccess = useSelector(
      selectIsSuccess([FitAndProperTypes.SAVE_ANSWER_BY_ID_REQUEST])
   );
   const [initialLoad, setInitialLoad] = useState(true);
   const [isSaveTriggered, setIsSaveTriggered] = useState(false);
   const [reasonText, setReasonText] = useState(String);
   const [currentAnswer, setCurrentAnswer] = useState(Boolean);
   const [isError, setIsError] = useState(false);
   const dispatch = useDispatch();

   const renderRadioOption = (
      id: string,
      groupName: string,
      option: string,
      defaultValue?: string
   ): JSX.Element => {
      const isChecked =
         initialLoad && defaultValue && defaultValue === option
            ? {
                 checked: true,
              }
            : undefined;

      return (
         <label className="bold black-text">
            <span className="radio-option-label">{option}</span>
            <input
               id={id}
               radioGroup={groupName}
               className="with-gap"
               name={groupName}
               type="radio"
               onChange={(): void => onValueChange(option)}
               {...isChecked}
            />
            <span />
         </label>
      );
   };

   useEffect((): void => {
      if (saveError) {
         toast.error(saveError);
         setIsSaveTriggered(false);
      }
   }, [saveError]);

   useEffect((): void => {
      if (!initialLoad && onSaveSuccess && saveSuccess && isSaveTriggered) {
         toast.success('Question saved successfully.');
         setIsSaveTriggered(false);
         if (onSaveSuccess) {
            dispatch(FitAndProperActions.saveAnswerByIdReset());
            onSaveSuccess();
         }
      }
   }, [dispatch, initialLoad, onSaveSuccess, isSaveTriggered, saveSuccess]);

   const onValueChange = (val: string): void => {
      const currentVal = val === OPTION_YES ? true : false;
      setCurrentAnswer(currentVal);
      setInitialLoad(false);
   };

   const onReasonChange = (val: string): void => {
      if (!val) {
         setIsError(true);
      }
      setReasonText(val);
      setIsError(false);
   };

   const validateAndSave = (): void => {
      if (!answer || !contact.userId) {
         toast.error(
            'Something went wrong. please refresh the page and try again'
         );
         return;
      }

      if (!reasonText) {
         setIsError(true);
         toast.error('Validation Failed: Please enter the reason to proceed');
         return;
      }

      if (currentAnswer === answer.latestAnswer) {
         toast.error('No changes made. Please change the answer to proceed');
         return;
      }

      setIsError(false);
      setIsSaveTriggered(true);
      setInitialLoad(false);
      const reqAnswer: QuestionSaveDto = {
         id: answer.id,
         adminReason: reasonText,
         answer: currentAnswer,
         userId: contact.userId,
      };

      dispatch(FitAndProperActions.saveAnswerByIdRequest(reqAnswer));
   };

   const defaultVal =
      !answer || answer.latestAnswer === null
         ? ''
         : answer.latestAnswer === true
         ? OPTION_YES
         : OPTION_NO;

   return (
      <Card className="fit-and-proper-edit md-block-centered md-cell--12">
         {answer ? (
            <CardText>
               <h2>
                  Override answer for <b>{contact.fullName}</b>
               </h2>
               <div className="fp-edit-question">{answer.questionText}</div>
               <div className="fp-options">
                  {renderRadioOption(
                     OPTION_YES,
                     answer.id.toString(),
                     OPTION_YES,
                     defaultVal
                  )}
                  {renderRadioOption(
                     OPTION_NO,
                     answer.id.toString(),
                     OPTION_NO,
                     defaultVal
                  )}
               </div>
               <div>
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Override Reason"
                     lineDirection="center"
                     placeholder="Reason"
                     className="md-cell md-cell--bottom md-cell--12"
                     required
                     block={true}
                     error={isError}
                     onChange={(val): void => onReasonChange(val.toString())}
                  />
               </div>
               <div className="controls">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isSaveTriggered}
                     onClick={(): void => validateAndSave()}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) validateAndSave();
                     }}
                     disabled={initialLoad}
                  >
                     Save and Close
                  </LoadingButton>
               </div>
            </CardText>
         ) : (
            <></>
         )}
      </Card>
   );
};

export default FitAndProperEdit;
