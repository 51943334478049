import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { Complaint } from 'Models/Complaint/Complaint';
import { ComplaintSearchQuery } from 'Models/Complaint/ComplaintSearchQuery';
import { ComplaintSearchResult } from 'Models/Complaint/ComplaintSearchResult';
import { withAccessToken, createApi } from '.';

export interface ComplaintServiceType {
   getComplaintSearchResults(
      query: ComplaintSearchQuery
   ): Promise<ApiResponse<readonly ComplaintSearchResult[]>>;
   getComplaintById(id: string): Promise<ApiResponse<Complaint>>;
   createComplaint(complaint: Complaint): Promise<ApiResponse<Complaint>>;
   updateComplaint(complaint: Complaint): Promise<ApiResponse<Complaint>>;
}

const create = (baseURL: string | undefined): ComplaintServiceType => {
   const api = createApi({ baseURL });

   const getComplaintSearchResults = (
      query: ComplaintSearchQuery
   ): Promise<ApiResponse<readonly ComplaintSearchResult[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Complaints/Search`, query);
   };

   const getComplaintById = (id: string): Promise<ApiResponse<Complaint>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Complaints/${id}`);
   };

   const createComplaint = (
      complaint: Complaint
   ): Promise<ApiResponse<Complaint>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Complaints`, complaint);
   };

   const updateComplaint = (
      complaint: Complaint
   ): Promise<ApiResponse<Complaint>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(
         `/Complaints/${complaint.complaintId}/Edit`,
         complaint
      );
   };

   return {
      getComplaintSearchResults,
      getComplaintById,
      createComplaint,
      updateComplaint,
   };
};

export default {
   create,
};
