import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useDispatch, useSelector } from 'react-redux';
import RegistrationTypeResultsPanel from './Panels/RegistrationTypeResultsPanel';
import {
   RegistrationTypeActions,
   selectRegistrationTypes,
   RegistrationTypeTypes,
} from 'State/Redux/RegistrationTypeRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const ViewRegistrationType = (): JSX.Element => {
   const searchResults = useSelector(selectRegistrationTypes);
   const isLoading = useSelector(
      selectIsLoading([RegistrationTypeTypes.GET_REGISTRATION_TYPE_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   useEffect((): void => {
      dispatch(RegistrationTypeActions.getRegistrationTypeRequest());
      setHasSearched(true);
   }, [dispatch]);

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <RegistrationTypeResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default ViewRegistrationType;
