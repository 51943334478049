import { createReducer } from 'reduxsauce';
import ApiError from 'Models/Other/ApiError';
import { AnyAction } from 'redux';
import StateReadonly from './StateModel';
import { FinancialExtractSearchQuery } from 'Models/BackOffice/FinancialExtractInput';
import { FinancialExtractResultItem } from 'Models/BackOffice/FinancialExtractResultItem';
import { createScopedActions } from '.';

interface FinancialExtractState {
   searchInput: FinancialExtractSearchQuery;
   searchResult: FinancialExtractResultItem[] | null;
   updatePaymentResult: Blob;
}

export type FinancialExtractStateReadOnly = Readonly<FinancialExtractState>;

interface TypeNames {
   GET_SEARCH_RESULT_REQUEST: string;
   GET_SEARCH_RESULT_SUCCESS: string;
   GET_SEARCH_RESULT_FAILURE: string;
   GET_SEARCH_RESULT_RESET: string;
   UPDATE_FINANCIAL_EXTRACT_REQUEST: string;
   UPDATE_FINANCIAL_EXTRACT_SUCCESS: string;
   UPDATE_FINANCIAL_EXTRACT_FAILURE: string;
   UPDATE_FINANCIAL_EXTRACT_RESET: string;
}

type FinancialExtractTypeNames = Readonly<TypeNames>;

export interface FinancialExtractCreators {
   getSearchResultRequest: (query: FinancialExtractSearchQuery) => AnyAction;
   getSearchResultSuccess: (
      data: readonly FinancialExtractResultItem[]
   ) => AnyAction;
   getSearchResultFailure: (error: ApiError) => AnyAction;
   getSearchResultReset: () => AnyAction;

   updateFinancialExtractRequest: (
      model: FinancialExtractResultItem[],
      financialType: string,
      fileName: string
   ) => AnyAction;
   updateFinancialExtractSuccess: (data: Blob | undefined) => AnyAction;
   updateFinancialExtractFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: FinancialExtractStateReadOnly = {
   searchInput: ({} as unknown) as FinancialExtractSearchQuery,
   searchResult: null,
   updatePaymentResult: new Blob(),
};

/* ------------- Reducers ------------- */
const getSearchResultSuccess = (
   state: FinancialExtractStateReadOnly,
   action: AnyAction
): FinancialExtractStateReadOnly => {
   return { ...state, searchResult: action.data || [] };
};

const getSearchResultReset = (
   state: FinancialExtractStateReadOnly,
   action: AnyAction
): FinancialExtractStateReadOnly => {
   return { ...state, searchResult: null };
};

const updateFinancialExtractSuccess = (
   state: FinancialExtractStateReadOnly,
   action: AnyAction
): FinancialExtractStateReadOnly => {
   return { ...state, updatePaymentResult: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   FinancialExtractTypeNames,
   FinancialExtractCreators
>('financialExtract', {
   getSearchResultRequest: ['query'],
   getSearchResultSuccess: ['data'],
   getSearchResultFailure: ['error'],
   getSearchResultReset: [],
   updateFinancialExtractRequest: ['model', 'financialType', 'fileName'],
   updateFinancialExtractSuccess: ['data'],
   updateFinancialExtractFailure: ['error'],
});

export const FinancialExtractTypes = Types;
export const FinancialExtractActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_SEARCH_RESULT_RESET]: getSearchResultReset,
   [Types.GET_SEARCH_RESULT_SUCCESS]: getSearchResultSuccess,
   [Types.UPDATE_FINANCIAL_EXTRACT_SUCCESS]: updateFinancialExtractSuccess,
});

/* ------------- Selectors ------------- */
export const selectSearchResult = (
   state: StateReadonly
): FinancialExtractResultItem[] | null => state.financialExtract.searchResult;

export const selectupdatePaymentResult = (state: StateReadonly): Blob =>
   state.financialExtract.updatePaymentResult;
