import React, { useState } from 'react';
import ReactTable, {
   TableProps,
   Column,
   SortingRule,
   RowInfo,
   ReactTableDefaults,
} from 'react-table';
import TableLoadingComponent from 'Components/Common/TableLoadingComponent/TableLoadingComponent';
import { PagedQuery } from 'Models/Other/PagedQuery';
import { isEqual } from 'lodash-es';

interface PagingState {
   page: number;
   pageSize: number;
   sorted: SortingRule | readonly SortingRule[];
}

//eslint-disable-next-line
interface ServerSideTableProps<T = any>
   extends Partial<Pick<TableProps<T>, 'getTrProps' | 'TrComponent'>> {
   headers: Column<T>[];
   totalResults: number;
   data: readonly T[];
   isLoading: boolean;
   paging: PagedQuery;
   onPagingChanged: (paging: PagedQuery) => void;
}

export const ServerSideTable = ({
   headers,
   totalResults,
   data,
   isLoading,
   paging,
   onPagingChanged,
   TrComponent,
   getTrProps,
}: Readonly<ServerSideTableProps>): JSX.Element => {
   const [localPaging, setLocalPaging] = useState(paging);
   const [fetchedPaging, setFetchedPaging] = useState(paging);

   const pageSort = paging.orderBy
      ? ([{ id: paging.orderBy, desc: paging.isDescending }] as SortingRule[])
      : [];
   const pages = Math.ceil(totalResults / paging.pageSize);

   return (
      <ReactTable
         data={[...data]}
         showPaginationTop
         className="-striped -highlight"
         columns={headers}
         multiSort={false}
         manual
         pages={pages}
         page={paging.pageNumber}
         pageSize={paging.pageSize}
         loading={isLoading}
         sorted={pageSort}
         getTrProps={getTrProps}
         TrComponent={TrComponent || ReactTableDefaults.TrComponent}
         LoadingComponent={TableLoadingComponent}
         getTheadThProps={(
            state: PagingState,
            rowInfo?: RowInfo,
            column?: Column
         ): object => {
            return {
               onClick: (): void => {
                  if (
                     !column ||
                     !column.id ||
                     (column.sortable !== undefined && !column.sortable)
                  )
                     return;
                  const columnName = column.id;

                  const isCurrentlySorted = localPaging.orderBy === columnName;
                  const newOrdering = isCurrentlySorted
                     ? !localPaging.isDescending
                     : false;

                  const newPaging = {
                     pageNumber: 0,
                     pageSize: state.pageSize,
                     orderBy: columnName,
                     isDescending: newOrdering,
                  };
                  if (!isEqual(localPaging, newPaging))
                     setLocalPaging(newPaging);
               },
            };
         }}
         onFetchData={(state: PagingState): void => {
            const newPaging = {
               pageNumber: state.page,
               pageSize: state.pageSize,
               orderBy: localPaging.orderBy,
               isDescending: localPaging.isDescending,
            };
            if (!isEqual(fetchedPaging, newPaging)) {
               setLocalPaging(newPaging);
               setFetchedPaging(newPaging);
               onPagingChanged(newPaging);
            }
         }}
      />
   );
};
