import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment-timezone';
import { Column, RowInfo, SortingRule } from 'react-table';
import { DialogContainer } from 'react-md/lib/Dialogs';
import {
   selectSupervisionHistory,
   SupervisionTypes,
} from 'State/Redux/SupervisionRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import StateReadonly from 'State/Redux/StateModel';
import SupervisionTableModel from './SupervisorTableModel';
import SupervisionDetailedHistoryTable from './SupervisorDetailedHistoryTable';
import EditSupervision from '../EditSupervision';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import SupervisionReadonly from 'Models/Supervision/Data/Supervision';
import { LicenceType } from 'Models/Metadata/LicenceType';
import {
   isSupervisionDateCurrentActive,
   isSupervisionDateFutureActive,
} from 'Util/Helpers/Supervision';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import SupervisionStatusMessage from './SupervisorStatusMessage';

const DEFAULT_SORTING: SortingRule[] = [{ id: 'licenceYear', desc: true }];

const generateSupervisionTableModels = (
   supervision: readonly SupervisionReadonly[],
   licenceTypes: readonly LicenceType[]
): SupervisionTableModel[] => {
   const data: SupervisionTableModel[] = [];
   supervision.forEach((su): void => {
      const licenceType = licenceTypes.find(
         (lt): boolean => lt.licenceTypeCode === su.licence.licenceTypeCode
      );
      const licenceTypeDescription = licenceType
         ? licenceType.description.trim()
         : '';

      data.push({
         licenceId: su.licence.licenceId,
         licenceType: licenceTypeDescription,
         supervisorName: su.supervisorName,
         licenceYear: su.licence.licenceYearNumberNavigation.description,
         licenceStarts: Moment(su.licence.startDate).format(),
         licenceEnds: su.licence.expiryDate
            ? Moment(su.licence.expiryDate).format()
            : '',
         supervision: su,
      });
   });
   return data;
};

const SupervisorHistory = (): JSX.Element => {
   const supervision = useSelector(selectSupervisionHistory);
   const isLoading = useSelector(
      selectIsLoading([SupervisionTypes.GET_SUPERVISOR_HISTORY_REQUEST])
   );

   const [showModal, setShowModal] = useState(false);
   const [
      selectedSupervision,
      setSelectedSupervision,
   ] = useState<SupervisionReadonly | null>(null);

   const licenceTypes = useSelector(
      (state: StateReadonly): readonly LicenceType[] =>
         state.metadata.licenceTypes
   );

   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   const COLUMN_HEADERS: Column<SupervisionTableModel>[] = [
      {
         Header: 'Licence Type',
         accessor: 'licenceType',
      },
      {
         Header: 'Supervisor',
         id: 'supervisorName',
         accessor: (tableModel: SupervisionTableModel): string => {
            // If the supervision has ended, display blank value
            if (
               !isSupervisionDateCurrentActive(tableModel.supervision) &&
               !isSupervisionDateFutureActive(tableModel.supervision)
            ) {
               return '-';
            }
            return tableModel.supervisorName;
         },
      },
      {
         Header: 'Licence Year',
         accessor: 'licenceYear',
      },
      {
         Header: 'Licence Starts',
         accessor: 'licenceStarts',
         Cell: (cellInfo): string => {
            return Moment(cellInfo.value).format(NAMED_DATE_FORMAT);
         },
      },
      {
         Header: 'Licence Ends',
         accessor: 'licenceEnds',
         Cell: (cellInfo): string => {
            const date = Moment(cellInfo.value);
            return date.isValid() ? date.format(NAMED_DATE_FORMAT) : '';
         },
      },
      {
         Header: 'Days Supervised',
         id: 'daysSupervised',
         accessor: (tableModel: SupervisionTableModel): number => {
            return tableModel.supervision.supervisionHistory.reduce(
               (a, b): number => {
                  return a + Math.round(b.daysSupervised);
               },
               0
            );
         },
      },
      {
         Header: 'Supervision Status',
         id: 'supervisionStatus',
         accessor: (tableModel: SupervisionTableModel): string => {
            return tableModel.supervision.supervisionStatus;
         },
         width: 500,
         Cell: (cellInfo): JSX.Element => {
            return <SupervisionStatusMessage tableModel={cellInfo.original} />;
         },
      },
      {
         Header: <FaIcon icon="ellipsis-h" />,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  className="small-icon table-row-clickable"
                  icon="edit"
                  onClick={(): void => {
                     setSelectedSupervision(cellInfo.original.supervision);
                     setShowModal(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress))
                        setSelectedSupervision(cellInfo.original.supervision);
                     setShowModal(true);
                  }}
               />
            );
         },
         sortable: false,
      },
   ];

   const data = generateSupervisionTableModels(supervision, licenceTypes);

   return (
      <>
         <DialogContainer
            id="edit-supervisor-dialog"
            visible={showModal}
            className="edit-dialog"
            title="Edit Supervision"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <EditSupervision
               updateShowModal={updateShowModal}
               selectedSupervision={selectedSupervision}
            />
         </DialogContainer>

         <div className="supervision-history">
            <ClientSideTable
               data={data}
               defaultPageSize={10}
               columns={COLUMN_HEADERS}
               loading={isLoading}
               defaultSorted={DEFAULT_SORTING}
               SubComponent={(info: RowInfo): JSX.Element => {
                  return (
                     <SupervisionDetailedHistoryTable
                        tableModel={info.original}
                     />
                  );
               }}
            />
         </div>
      </>
   );
};

export default React.memo(SupervisorHistory);
