import { put, call } from 'redux-saga/effects';
import { PaymentMethodActions } from '../Redux/PaymentMethodRedux';
import { PaymentMethodServiceType } from '../Services/PaymentMethodService';
import { ApiResponse } from 'apisauce';
import PaymentMethodReadonly, {
   PaymentMethod,
} from 'Models/PaymentMethod/Dto/PaymentMethod';
import { MetadataActions } from 'State/Redux/MetadataRedux';

interface PaymentMethodParams {
   type: string;
   token: string;
   id: number;
   paymentMethod: PaymentMethodReadonly;
}

export function* updatePaymentMethod(
   supervisionService: Readonly<PaymentMethodServiceType>,
   action: Readonly<PaymentMethodParams>
): Generator {
   const { paymentMethod } = action;
   const supervisionResponse = yield call(
      supervisionService.updatePaymentMethod,
      paymentMethod
   );
   const response = supervisionResponse as ApiResponse<PaymentMethodReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentMethodActions.updatePaymentMethodSuccess(response.data));
      yield put(MetadataActions.getMetadataByTypeRequest('PaymentMethods'));
   } else {
      yield put(
         PaymentMethodActions.updatePaymentMethodFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addPaymentMethod(
   supervisionService: Readonly<PaymentMethodServiceType>,
   action: Readonly<PaymentMethodParams>
): Generator {
   const { paymentMethod } = action;
   const supervisionResponse = yield call(
      supervisionService.addPaymentMethod,
      paymentMethod
   );
   const response = supervisionResponse as ApiResponse<PaymentMethodReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentMethodActions.addPaymentMethodSuccess(response.data));
      yield put(MetadataActions.getMetadataByTypeRequest('PaymentMethods'));
   } else {
      yield put(
         PaymentMethodActions.addPaymentMethodFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Adding Payment',
         })
      );
   }
}

export function* getPaymentMethod(
   supervisionService: Readonly<PaymentMethodServiceType>,
   action: Readonly<PaymentMethodParams>
): Generator {
   const supervisionResponse = yield call(supervisionService.getPaymentMethod);
   const response = supervisionResponse as ApiResponse<
      readonly PaymentMethod[]
   >;

   if (response.status && response.ok) {
      yield put(PaymentMethodActions.getPaymentMethodSuccess(response.data));
   } else {
      yield put(
         PaymentMethodActions.getPaymentMethodFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
