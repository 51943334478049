export const isEnterKeyPress = (
   keyPressed: React.KeyboardEvent<HTMLElement>
): boolean => keyPressed.key === 'Enter';

export interface SelectItem {
   label: string;
   value: string;
}

//eslint-disable-next-line
export function createSelectList<T = any>(
   entries: readonly T[],
   valueSelector: (entry: T) => string, // gets the value for the input
   labelSelector: (entry: T) => string, // gets the visible label for the input
   defaultValue?: string
): SelectItem[] {
   const def = defaultValue ? [{ label: defaultValue, value: ' ' }] : [];
   return def.concat(
      (entries || [])
         .map(
            (entry): SelectItem => {
               return {
                  value: valueSelector(entry) || '',
                  label: labelSelector(entry),
               };
            }
         )
         .sort((a, b): number =>
            a.label < b.label ? -1 : b.label > a.label ? 1 : 0
         )
   );
}
