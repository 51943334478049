import React from 'react';
import Moment from 'moment-timezone';
import { Column, SortingRule } from 'react-table';
import SupervisionTableModel from './SupervisorTableModel';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import SupervisionHistoryReadonly from 'Models/Supervision/Data/SupervisionHistory';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';

interface SupervisionDetailedHistoryTableProps {
   tableModel: SupervisionTableModel;
}

const SUB_COLUMN_HEADERS: Column<SupervisionHistoryReadonly>[] = [
   {
      Header: 'Supervisor',
      accessor: 'supervisorFullName',
   },
   {
      Header: 'Supervision Starts',
      id: 'startDate',
      accessor: (history: SupervisionHistoryReadonly): string => {
         return Moment(history.startDate).format();
      },
      Cell: (cellInfo): string => {
         return Moment(cellInfo.original.startDate).format(NAMED_DATE_FORMAT);
      },
   },
   {
      Header: 'Supervision Ends',
      id: 'endDate',
      accessor: (history: SupervisionHistoryReadonly): string => {
         return Moment(history.endDate).format();
      },
      Cell: (cellInfo): string => {
         return Moment(cellInfo.original.endDate).format(NAMED_DATE_FORMAT);
      },
   },
   {
      Header: 'Days Supervised',
      id: 'daysSupervised',
      accessor: (history: SupervisionHistoryReadonly): number => {
         return Math.round(history.daysSupervised);
      },
   },
];

const DEFAULT_SORTING: SortingRule[] = [{ id: 'endDate', desc: true }];

const SupervisionDetailedHistoryTable = ({
   tableModel,
}: SupervisionDetailedHistoryTableProps): JSX.Element => {
   const data = tableModel.supervision.supervisionHistory;
   return (
      <div className="sub-table-wrapper">
         <div className="sub-table-header">Supervision History</div>
         <ClientSideTable
            data={data}
            className="-striped -highlight"
            defaultPageSize={100}
            minRows={5}
            showPagination={false}
            columns={SUB_COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
         />
      </div>
   );
};

export default SupervisionDetailedHistoryTable;
