import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ResultDto from 'Models/Examination/Dto/ResultDto';
import ApiError from 'Models/Other/ApiError';
import ResultStatusDto from 'Models/Examination/Dto/ResultStatusDto';
import ResultStatusDtoReadonly from 'Models/Examination/Dto/ResultStatusDto';
import StateReadonly from './StateModel';
import { ResultSearchQuery } from 'Models/Examination/Data/ResultSearchQuery';
import ResultSearchResultReadOnly from 'Models/Examination/Data/ResultSearchResult';
import { createScopedActions } from '.';
import ResultSearchResultDtoReadOnly from 'Models/Examination/Dto/ResultSearchResultDto';
import produce from 'immer';
import ResultDtoReadonly from 'Models/Examination/Dto/ResultDto';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ResultState {
   result: ResultDto | null;
   results: ResultDto[];
   resultStatus: ResultStatusDtoReadonly | null;
   searchResults: ResultSearchResultReadOnly | null;
}

export type ResultStateReadonly = Readonly<ResultState>;

interface TypeNames {
   GET_RESULT_BY_CONTACT_ID_REQUEST: string;
   GET_RESULT_BY_CONTACT_ID_SUCCESS: string;
   GET_RESULT_BY_CONTACT_ID_FAILURE: string;
   RESET_RESULTS: string;

   GET_RESULT_STATUS_BY_CONTACT_ID_REQUEST: string;
   GET_RESULT_STATUS_BY_CONTACT_ID_SUCCESS: string;
   GET_RESULT_STATUS_BY_CONTACT_ID_FAILURE: string;
   RESET_RESULT_STATUS: string;

   UPDATE_RESULT_REQUEST: string;
   UPDATE_RESULT_SUCCESS: string;
   UPDATE_RESULT_FAILURE: string;

   UPDATE_RESULT_BY_SEARCH_RESULT_REQUEST: string;
   UPDATE_RESULT_BY_SEARCH_RESULT_SUCCESS: string;
   UPDATE_RESULT_BY_SEARCH_RESULT_FAILURE: string;

   SEARCH_RESULT_REQUEST: string;
   SEARCH_RESULT_SUCCESS: string;
   SEARCH_RESULT_FAILURE: string;

   SAVE_VALIDATED_RESULTS_REQUEST: string;
   SAVE_VALIDATED_RESULTS_SUCCESS: string;
   SAVE_VALIDATED_RESULTS_FAILURE: string;
}

type ResultTypeNames = Readonly<TypeNames>;

export interface ResultCreators {
   getResultByContactIdRequest: (contactId: string) => AnyAction;
   getResultByContactIdSuccess: (data: ResultDto[] | undefined) => AnyAction;
   getResultByContactIdFailure: (error: ApiError) => AnyAction;
   resetResults: () => AnyAction;

   getResultStatusByContactIdRequest: (contactId: string) => AnyAction;
   getResultStatusByContactIdSuccess: (
      data: ResultStatusDto | undefined
   ) => AnyAction;
   getResultStatusByContactIdFailure: (error: ApiError) => AnyAction;
   resetResultStatus: () => AnyAction;

   updateResultRequest: (result: ResultDto) => AnyAction;
   updateResultSuccess: (data: ResultDto) => AnyAction;
   updateResultFailure: (error: ApiError) => AnyAction;

   updateResultBySearchResultRequest: (
      searchResult: ResultSearchResultDtoReadOnly
   ) => AnyAction;
   updateResultBySearchResultSuccess: (
      data: ResultSearchResultDtoReadOnly
   ) => AnyAction;
   updateResultBySearchResultFailure: (error: ApiError) => AnyAction;

   searchResultRequest: (query: ResultSearchQuery) => AnyAction;
   searchResultSuccess: (data: ResultSearchResultReadOnly[]) => AnyAction;
   searchResultFailure: (error: ApiError) => AnyAction;

   saveValidatedResultsRequest: (results: ResultDtoReadonly[]) => AnyAction;
   saveValidatedResultsSuccess: (
      data: ResultDtoReadonly[] | undefined
   ) => AnyAction;
   saveValidatedResultsFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ResultStateReadonly = {
   result: null,
   results: [],
   resultStatus: null,
   searchResults: null,
};

/* ------------- Reducers ------------- */
const getResultByContactIdSuccess = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return { ...state, results: action.data };
};

const resetResults = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => ({ ...state, results: [] });

const getResultStatusByContactIdSuccess = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return { ...state, resultStatus: action.data };
};

const resetResultStatus = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => ({ ...state, resultStatus: null });

const updateResultSuccess = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return { ...state, result: action.data };
};

const updateResultBySearchResultSuccess = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return produce(state, (draftState): void => {
      const updatedResult = action.data;
      // find search result to update
      if (draftState.searchResults) {
         const resultFound = draftState.searchResults.results.find(result => {
            return result.resultId === updatedResult.resultId;
         });
         if (resultFound) {
            const index = draftState.searchResults.results.indexOf(resultFound);
            draftState.searchResults.results[index] = {
               ...action.data,
            };
         }
      }
   });
};

const searchResultReset = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return { ...state, searchResults: null };
};

const searchResultSuccess = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return { ...state, searchResults: action.data || [] };
};

const saveValidatedResultsSuccess = (
   state: ResultStateReadonly,
   action: AnyAction
): ResultStateReadonly => {
   return { ...state, results: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ResultTypeNames,
   ResultCreators
>('result', {
   getResultByContactIdRequest: ['contactId'],
   getResultByContactIdSuccess: ['data'],
   getResultByContactIdFailure: ['error'],
   resetResults: [],

   getResultStatusByContactIdRequest: ['contactId'],
   getResultStatusByContactIdSuccess: ['data'],
   getResultStatusByContactIdFailure: ['error'],
   resetResultStatus: [],

   updateResultRequest: ['result'],
   updateResultSuccess: ['data'],
   updateResultFailure: ['error'],

   updateResultBySearchResultRequest: ['searchResult'],
   updateResultBySearchResultSuccess: ['data'],
   updateResultBySearchResultFailure: ['error'],

   searchResultRequest: ['query'],
   searchResultSuccess: ['data'],
   searchResultFailure: ['error'],

   saveValidatedResultsRequest: ['results'],
   saveValidatedResultsSuccess: ['data'],
   saveValidatedResultsFailure: ['error'],
});

export const ResultTypes = Types;
export const ResultActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_RESULT_BY_CONTACT_ID_SUCCESS]: getResultByContactIdSuccess,
   [Types.RESET_RESULTS]: resetResults,
   [Types.GET_RESULT_STATUS_BY_CONTACT_ID_SUCCESS]: getResultStatusByContactIdSuccess,
   [Types.RESET_RESULT_STATUS]: resetResultStatus,
   [Types.UPDATE_RESULT_SUCCESS]: updateResultSuccess,
   [Types.UPDATE_RESULT_BY_SEARCH_RESULT_SUCCESS]: updateResultBySearchResultSuccess,
   [Types.SEARCH_RESULT_REQUEST]: searchResultReset,
   [Types.SEARCH_RESULT_SUCCESS]: searchResultSuccess,
   [Types.SAVE_VALIDATED_RESULTS_SUCCESS]: saveValidatedResultsSuccess,
});

/* ------------- Selectors ------------- */

export const selectResult = (state: StateReadonly): ResultDto | null =>
   state.result.result;

export const selectResults = (state: StateReadonly): readonly ResultDto[] =>
   state.result.results;

export const selectResultStatus = (
   state: StateReadonly
): ResultStatusDtoReadonly | null => state.result.resultStatus;

export const selectSearchResults = (
   state: StateReadonly
): ResultSearchResultReadOnly | null => state.result.searchResults;
