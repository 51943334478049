import {
   isNumber,
   isNullOrWhiteSpace,
   isValidLicenceNumber,
   isValidEndDateAfterStartDate,
   isValidDate,
} from 'Util/Helpers/Validation';
import { isValidSearchName } from 'Util/Helpers/ContactSearch';

interface ComplaintSearchQueryModel {
   complaintId: string;
   registrationNumber: string;
   name: string;
   startDate: string;
   endDate: string;
   status: string;
}

export type ComplaintSearchQuery = Readonly<ComplaintSearchQueryModel>;

export const DefaultComplaintSearchQuery: ComplaintSearchQuery = {
   complaintId: '',
   registrationNumber: '',
   name: '',
   startDate: '',
   endDate: '',
   status: '',
};

interface ComplaintSearchQueryValidationModel {
   complaintId: boolean;
   registrationNumber: boolean;
   name: boolean;
   startDate: boolean;
   endDate: boolean;
   status: boolean;
}

export type ComplaintSearchQueryValidation = Readonly<
   ComplaintSearchQueryValidationModel
>;

export const DefaultComplaintSearchQueryValidation: ComplaintSearchQueryValidation = {
   complaintId: false,
   registrationNumber: false,
   name: false,
   startDate: false,
   endDate: false,
   status: false,
};

export const validateSearch = (
   query: ComplaintSearchQuery
): ComplaintSearchQueryValidation => {
   return {
      complaintId:
         isNullOrWhiteSpace(query.complaintId) || isNumber(query.complaintId),
      registrationNumber:
         isNullOrWhiteSpace(query.registrationNumber) ||
         isValidLicenceNumber(query.registrationNumber),
      name: isNullOrWhiteSpace(query.name) || isValidSearchName(query.name),
      startDate: isValidDate(query.startDate), // don't trust the datepicker
      endDate:
         isValidDate(query.endDate) &&
         isValidEndDateAfterStartDate(query.startDate, query.endDate),
      status: true, // handled by select field
   };
};

export const filterToValidQuery = (
   query: ComplaintSearchQuery
): ComplaintSearchQuery => {
   return {
      complaintId:
         isNullOrWhiteSpace(query.complaintId) || isNumber(query.complaintId)
            ? query.complaintId.trim()
            : '',
      registrationNumber:
         isNullOrWhiteSpace(query.registrationNumber) ||
         isValidLicenceNumber(query.registrationNumber)
            ? query.registrationNumber.trim()
            : '',
      name:
         isNullOrWhiteSpace(query.name) || isValidSearchName(query.name)
            ? query.name.trim()
            : '',
      startDate: query.startDate, // trust the datepicker
      endDate: query.endDate,
      status: isNullOrWhiteSpace(query.status)
         ? query.status.trim()
         : query.status,
   };
};

export const isValidQuery = (query: ComplaintSearchQuery): boolean => {
   return (
      !!query.complaintId ||
      !!query.registrationNumber ||
      !!query.name ||
      !!query.startDate ||
      !!query.endDate ||
      !!query.status
   );
};
