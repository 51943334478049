import React from 'react';
import DialogContainer, { DialogContainerProps } from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import './style.scss';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface ConfirmationDialogProps extends DialogContainerProps {
   // omitting is not possible at this time (with v1.12.3)
   // due to  `Index Signatures` in `DialogContainerProps`
   // which contains
   //    // for the `component` prop until refactored out
   //    [key: string]: any;
   // it can be ommitted but all unnecessary props assignment
   // need to be done afterwards.
   // please uncomment following line if the library removed it
   // extends Omit<DialogContainerProps, 'modal' | 'actions'> {
   confirmLabel?: string;
   cancelLabel?: string;
   onConfirm: () => void;
   onCancel: () => void;
   disabled?: boolean;
   children: JSX.Element;
}

const ConfirmationDialog = ({
   confirmLabel,
   cancelLabel,
   onConfirm,
   onCancel,
   children,
   disabled,
   ...rest
}: Readonly<ConfirmationDialogProps>): JSX.Element => {
   const actions = [];
   actions.push(
      <Button
         flat
         secondary
         swapTheming
         onClick={onCancel}
         onKeyUp={(keyPress): void => {
            if (isEnterKeyPress(keyPress)) onCancel();
         }}
      >
         {cancelLabel ? cancelLabel : 'Cancel'}
      </Button>
   );
   actions.push(
      <Button
         flat
         primary
         swapTheming
         disabled={disabled}
         onClick={onConfirm}
         onKeyUp={(keyPress): void => {
            if (isEnterKeyPress(keyPress)) onConfirm();
         }}
      >
         {confirmLabel ? confirmLabel : 'Confirm'}
      </Button>
   );

   return (
      <DialogContainer
         actions={actions}
         modal
         width={rest.width ? rest.width : 700}
         height={rest.height ? rest.height : 250}
         title={rest.width ? rest.title : 'Confirmation'}
         dialogClassName={`pgdb-confirmation-dialog ${rest.className || ''}`}
         contentClassName={`pgdb-confirmation-dialog-content ${rest.contentClassName ||
            ''}`}
         footerClassName={`pgdb-confirmation-dialog-footer ${rest.contentClassName ||
            ''}`}
         focusOnMount={rest.focusOnMount ? rest.focusOnMount : false}
         {...rest}
      >
         {children}
      </DialogContainer>
   );
};

export default ConfirmationDialog;
