import React, { useState, useEffect } from 'react';
import './style.scss';
import TextField from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import { useUserRoleList } from 'Util/Helpers/Metadata';
import UserEditModel, {
   DefaultUserEditModel,
} from 'Models/SuperUser/UserEditModel';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { isValidEmail, isValidUserId } from 'Util/Helpers/Validation';
import { useDispatch, useSelector } from 'react-redux';
import {
   ContactActions,
   ContactTypes,
   selectNewAdminUserId,
} from 'State/Redux/ContactRedux';
import { selectIsLoading, selectErrorMessage } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { SUCCESS_SAVE, USER_EDIT_URL } from '../Constants';
import { Redirect } from 'react-router';
import Button from 'react-md/lib/Buttons';
import { selectUser } from 'State/Redux/OidcRedux';

interface AddUserProps {
   editMode?: boolean;
   userEditModel?: UserEditModel;
   onCancel?: () => void;
   onSave?: () => void;
}

const AddUser = ({
   editMode,
   userEditModel,
   onCancel,
   onSave,
}: Readonly<AddUserProps>): JSX.Element => {
   const title = editMode ? 'Edit User' : 'New User';
   const roleList = useUserRoleList();
   const [userModel, setUserModel] = useState<UserEditModel>(
      editMode && userEditModel ? userEditModel : DefaultUserEditModel
   );
   const [initiated, setInitiated] = useState();
   const [saveTriggered, setSaveTriggered] = useState(false);
   const [saveCompleted, setSaveCompleted] = useState(false);
   const dispatch = useDispatch();
   const newAdminUserId = useSelector(selectNewAdminUserId);
   const saveErrorMessage = useSelector(
      selectErrorMessage(ContactTypes.SAVE_ADMIN_USER_REQUEST)
   );
   const invalidEmail =
      userModel.emailAddress && !isValidEmail(userModel.emailAddress)
         ? true
         : false;
   const user = useSelector(selectUser);

   // User cannot change his own role
   const disableRoleEdit =
      editMode &&
      userModel.userId &&
      user &&
      user.profile &&
      user.profile.user_id &&
      user.profile.user_id === userModel.userId
         ? true
         : false;

   const invalidUserId =
      userModel.userName && !isValidUserId(userModel.userName) ? true : false;
   const isValid =
      userModel.userName &&
      userModel.fullName &&
      userModel.roleCode &&
      userModel.emailAddress &&
      !invalidEmail &&
      !invalidUserId;

   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SAVE_ADMIN_USER_REQUEST])
   );

   const handleSave = (): void => {
      dispatch(ContactActions.saveAdminUserRequest(userModel));
      setSaveTriggered(true);
   };

   useEffect((): void => {
      if (!initiated) {
         setInitiated(true);
         if (!editMode) {
            dispatch(ContactActions.saveAdminUserReset());
         }
      }
   }, [initiated, editMode, dispatch]);

   useEffect((): void => {
      if (saveTriggered && newAdminUserId && !saveErrorMessage) {
         toast.success(SUCCESS_SAVE);
         setSaveTriggered(false);
         setSaveCompleted(true);
         if (onSave) {
            onSave();
         }
      }
   }, [saveTriggered, newAdminUserId, saveErrorMessage, onSave]);

   useEffect((): void => {
      if (saveTriggered && saveErrorMessage) {
         toast.error(saveErrorMessage);
         setSaveTriggered(false);
      }
   }, [saveTriggered, saveErrorMessage]);

   if (
      !editMode &&
      newAdminUserId &&
      saveCompleted &&
      !saveErrorMessage &&
      !saveTriggered
   ) {
      return <Redirect to={`${USER_EDIT_URL}${newAdminUserId}`} />;
   }

   const renderControls = (): JSX.Element => {
      return (
         <div className="controls">
            <LoadingButton
               flat
               primary
               swapTheming
               isLoading={isLoading}
               disabled={!isValid}
               onClick={handleSave}
            >
               Save
            </LoadingButton>
            {onCancel && (
               <Button primary flat onClick={onCancel}>
                  Cancel
               </Button>
            )}
         </div>
      );
   };

   return (
      <ExpansionList className="md-cell md-cell--12 add-user-container">
         <ExpansionPanel
            label={title}
            footer={null}
            expanded
            expanderIcon={<></>}
            className="add-user-panel"
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="add-user-registration"
                     label="User Id"
                     lineDirection="center"
                     placeholder="User Id"
                     className="md-cell md-cell--bottom md-cell--12"
                     defaultValue={userModel.userName}
                     onChange={(e): void => {
                        setUserModel({
                           ...userModel,
                           userName: e.toString(),
                        });
                     }}
                     required={!editMode}
                     errorText="Please enter the User Id"
                     maxLength={50}
                     disabled={editMode || isLoading}
                     error={invalidUserId}
                  />
                  <TextField
                     floating
                     id="add-user-firstname"
                     label="Full Name"
                     lineDirection="center"
                     placeholder="Full Name"
                     className="md-cell md-cell--bottom md-cell--12"
                     required
                     errorText="Please enter the Full Name"
                     defaultValue={userModel.fullName}
                     onChange={(e): void => {
                        setUserModel({
                           ...userModel,
                           fullName: e.toString(),
                        });
                     }}
                     maxLength={100}
                     disabled={isLoading}
                  />
                  <div className="md-text-field-container md-full-width ">
                     <Checkbox
                        id="add-user-disable"
                        name="cb-user-disable"
                        label="Disabled"
                        value="material-design"
                        className="md-cell md-cell--4"
                        checked={userModel.isDisabled}
                        onChange={(e): void => {
                           setUserModel({
                              ...userModel,
                              isDisabled: e,
                           });
                        }}
                        disabled={isLoading || disableRoleEdit}
                     />
                  </div>
               </div>
               <div className="md-cell md-cell--6">
                  <SelectField
                     floating
                     id="add-user-role"
                     label="Role"
                     placeholder="Select Role"
                     className="md-cell md-cell--12 user-role-select"
                     position={SelectField.Positions.BELOW}
                     required={!disableRoleEdit}
                     value={userModel.roleCode}
                     onChange={(e): void => {
                        setUserModel({
                           ...userModel,
                           roleCode: e.toString(),
                        });
                     }}
                     errorText="Please select the Role"
                     menuItems={roleList}
                     disabled={isLoading || disableRoleEdit}
                  />
                  <TextField
                     floating
                     id="add-user-email"
                     label="Email"
                     lineDirection="center"
                     placeholder="Email"
                     className="md-cell md-cell--bottom md-cell--12"
                     required
                     defaultValue={userModel.emailAddress}
                     onChange={(e): void => {
                        setUserModel({
                           ...userModel,
                           emailAddress: e.toString(),
                        });
                     }}
                     error={invalidEmail}
                     errorText="Please enter the Email"
                     maxLength={100}
                     disabled={isLoading}
                  />
               </div>
               <div className="md-cell md-cell--12">{renderControls()}</div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddUser;
