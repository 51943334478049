interface PagedQueryModel {
   pageSize: number;
   pageNumber: number;
   orderBy?: string;
   isDescending: boolean;
}

export type PagedQuery = Readonly<PagedQueryModel>;

export const DefaultPagingModel: PagedQuery = {
   pageSize: 20,
   pageNumber: 0,
   orderBy: undefined,
   isDescending: false,
};
