import { put, call } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import LicenceTypeReadonly, {
   LicenceType,
} from 'Models/LicenceType/Data/LienceType';
import { LicenceTypeServiceType } from 'State/Services/LicenceTypeService';
import { LicenceTypeActions } from 'State/Redux/LicenceTypeRedux';
import { MetadataActions } from 'State/Redux/MetadataRedux';

interface LicenceTypeParams {
   type: string;
   token: string;
   id: number;
   licenceType: LicenceTypeReadonly;
}

export function* updateLicenceType(
   licenceTypeService: Readonly<LicenceTypeServiceType>,
   action: Readonly<LicenceTypeParams>
): Generator {
   const { licenceType } = action;
   const licenceTypeResponse = yield call(
      licenceTypeService.updateLicenceType,
      licenceType
   );
   const response = licenceTypeResponse as ApiResponse<LicenceTypeReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(LicenceTypeActions.updateLicenceTypeSuccess(response.data));
      yield put(MetadataActions.getMetadataByTypeRequest('LicenceTypes'));
   } else {
      yield put(
         LicenceTypeActions.updateLicenceTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addLicenceType(
   licenceTypeService: Readonly<LicenceTypeServiceType>,
   action: Readonly<LicenceTypeParams>
): Generator {
   const { licenceType } = action;
   const licenceTypeResponse = yield call(
      licenceTypeService.addLicenceType,
      licenceType
   );
   const response = licenceTypeResponse as ApiResponse<LicenceTypeReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(LicenceTypeActions.addLicenceTypeSuccess(response.data));
      yield put(MetadataActions.getMetadataByTypeRequest('LicenceTypes'));
   } else {
      yield put(
         LicenceTypeActions.addLicenceTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getLicenceTypes(
   licenceTypeService: Readonly<LicenceTypeServiceType>,
   action: Readonly<LicenceTypeParams>
): Generator {
   const licenceTypeResponse = yield call(licenceTypeService.getLicenceTypes);
   const response = licenceTypeResponse as ApiResponse<readonly LicenceType[]>;

   if (response.status && response.ok) {
      yield put(LicenceTypeActions.getLicenceTypesSuccess(response.data));
   } else {
      yield put(
         LicenceTypeActions.getLicenceTypesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
