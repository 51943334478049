import { call, put } from 'redux-saga/effects';
import { FeesPaymentServiceType } from 'State/Services/FeesPaymentService';
import { ApiResponse } from 'apisauce';
import { FeesPaymentActions } from 'State/Redux/FeesPaymentRedux';
import FeesPaymentSearchInput from 'Models/FeesPayment/FeesPaymentSearchInput';
import FeesPaymentSearchResult from 'Models/FeesPayment/FeesPaymentSearchResult';

import FeeRequestLineDisplay from 'Models/FeesPayment/FeeRequestLineDisplay';
import FeeRequestLineInput from 'Models/FeesPayment/FeeRequestLineInput';
import FeeRequestInput from 'Models/FeesPayment/FeeRequestInput';
import FeeRequestDtoReadonly from 'Models/FeeRequest/Dto/FeeRequestDto';

interface FeesPaymentParams {
   type: string;
   token: string;
   searchInput: FeesPaymentSearchInput;
   data: FeeRequestInput;
   lineData: FeeRequestLineInput;
   feeRequestId: number;
   feeRequestLineId: number;
}

export function* getSearchResult(
   feesPaymentService: Readonly<FeesPaymentServiceType>,
   action: Readonly<FeesPaymentParams>
): Generator {
   const { searchInput } = action;
   const feesPaymentResponse = yield call(
      feesPaymentService.getSearchResult,
      searchInput
   );
   const response = feesPaymentResponse as ApiResponse<FeesPaymentSearchResult>;

   if (response.status && response.ok && response.data) {
      yield put(FeesPaymentActions.getSearchResultSuccess(response.data));
   } else {
      yield put(
         FeesPaymentActions.getSearchResultFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Getting Payment/Fee Request details',
         })
      );
   }
}

export function* getFeeRequest(
   feesPaymentService: Readonly<FeesPaymentServiceType>,
   action: Readonly<FeesPaymentParams>
): Generator {
   const { feeRequestId } = action;
   const feesPaymentResponse = yield call(
      feesPaymentService.getFeeRequest,
      feeRequestId
   );
   const response = feesPaymentResponse as ApiResponse<FeeRequestDtoReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(FeesPaymentActions.getFeeRequestSuccess(response.data));
   } else {
      yield put(
         FeesPaymentActions.getFeeRequestFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Getting FeeRequest',
         })
      );
   }
}

export function* getFeeRequestLine(
   feesPaymentService: Readonly<FeesPaymentServiceType>,
   action: Readonly<FeesPaymentParams>
): Generator {
   const { feeRequestLineId } = action;
   const feesPaymentResponse = yield call(
      feesPaymentService.getFeeRequestLine,
      feeRequestLineId
   );
   const response = feesPaymentResponse as ApiResponse<FeeRequestLineDisplay>;

   if (response.status && response.ok && response.data) {
      yield put(FeesPaymentActions.getFeeRequestLineSuccess(response.data));
   } else {
      yield put(
         FeesPaymentActions.getFeeRequestLineFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Getting FeeRequest Line',
         })
      );
   }
}

export function* saveFeeRequest(
   feesPaymentService: Readonly<FeesPaymentServiceType>,
   action: Readonly<FeesPaymentParams>
): Generator {
   const { data } = action;

   const feeSaveResponse = yield call(feesPaymentService.saveFeeRequest, data);
   const response = feeSaveResponse as ApiResponse<number>;

   if (response.status && response.ok && response.data) {
      yield put(FeesPaymentActions.saveFeeRequestSuccess(response.data));
   } else {
      yield put(
         FeesPaymentActions.saveFeeRequestFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Saving Fee Request details',
         })
      );
   }
}

export function* saveFeeRequestLine(
   feesPaymentService: Readonly<FeesPaymentServiceType>,
   action: Readonly<FeesPaymentParams>
): Generator {
   const { lineData } = action;
   const feeSaveResponse = yield call(
      feesPaymentService.saveFeeRequestLine,
      lineData
   );
   const response = feeSaveResponse as ApiResponse<number>;

   if (response.status && response.ok && response.data) {
      yield put(FeesPaymentActions.saveFeeRequestLineSuccess(response.data));
   } else {
      yield put(
         FeesPaymentActions.saveFeeRequestLineFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Saving Fee Request details',
         })
      );
   }
}
