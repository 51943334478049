import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import RegistrationTypeReadonly, {
   RegistrationType,
} from 'Models/RegistrationType/Dto/RegistrationType';
import { createScopedActions } from '.';
import produce from 'immer';

/* ------------- Interfaces for ReduxSauce ------------- */
interface RegistrationTypeState {
   registrationTypes: RegistrationTypeReadonly[];
   registrationType: RegistrationTypeReadonly | null;
}

export type RegistrationTypeStateReadonly = Readonly<RegistrationTypeState>;

interface TypeNames {
   UPDATE_REGISTRATION_TYPE_REQUEST: string;
   UPDATE_REGISTRATION_TYPE_SUCCESS: string;
   UPDATE_REGISTRATION_TYPE_FAILURE: string;

   GET_REGISTRATION_TYPE_REQUEST: string;
   GET_REGISTRATION_TYPE_SUCCESS: string;
   GET_REGISTRATION_TYPE_FAILURE: string;

   ADD_REGISTRATION_TYPE_REQUEST: string;
   ADD_REGISTRATION_TYPE_SUCCESS: string;
   ADD_REGISTRATION_TYPE_FAILURE: string;
}

type RegistrationTypeTypeNames = Readonly<TypeNames>;

export interface RegistrationTypeCreators {
   updateRegistrationTypeRequest: (
      boardMeetingDate: RegistrationTypeReadonly
   ) => AnyAction;
   updateRegistrationTypeSuccess: (data: RegistrationTypeReadonly) => AnyAction;
   updateRegistrationTypeFailure: (error: ApiError) => AnyAction;

   getRegistrationTypeRequest: () => AnyAction;
   getRegistrationTypeSuccess: (
      data: readonly RegistrationType[] | undefined
   ) => AnyAction;
   getRegistrationTypeFailure: (error: ApiError) => AnyAction;

   addRegistrationTypeRequest: (
      registrationType: RegistrationTypeReadonly
   ) => AnyAction;
   addRegistrationTypeSuccess: (data: RegistrationTypeReadonly) => AnyAction;
   addRegistrationTypeFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: RegistrationTypeStateReadonly = {
   registrationType: null,
   registrationTypes: [],
};

/* ------------- Reducers ------------- */
const updateRegistrationTypeSuccess = (
   state: RegistrationTypeStateReadonly,
   action: AnyAction
): RegistrationTypeStateReadonly => {
   return produce(state, (draftState): void => {
      const registrationType = action.data;
      if (draftState.registrationTypes) {
         const registrationTypeFound = draftState.registrationTypes.find(
            sysPram => {
               return (
                  sysPram.registrationTypeCode ===
                  registrationType.registrationTypeCode
               );
            }
         );
         if (registrationTypeFound) {
            const index = draftState.registrationTypes.indexOf(
               registrationTypeFound
            );
            draftState.registrationTypes[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addRegistrationTypeSuccess = (
   state: RegistrationTypeStateReadonly,
   action: AnyAction
): RegistrationTypeStateReadonly => {
   return { ...state, registrationType: action.data };
};

const getRegistrationTypeSuccess = (
   state: RegistrationTypeStateReadonly,
   action: AnyAction
): RegistrationTypeStateReadonly => {
   return { ...state, registrationTypes: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   RegistrationTypeTypeNames,
   RegistrationTypeCreators
>('registrationType', {
   updateRegistrationTypeRequest: ['registrationType'],
   updateRegistrationTypeSuccess: ['data'],
   updateRegistrationTypeFailure: ['error'],

   getRegistrationTypeRequest: [],
   getRegistrationTypeSuccess: ['data'],
   getRegistrationTypeFailure: ['error'],

   addRegistrationTypeRequest: ['registrationType'],
   addRegistrationTypeSuccess: ['data'],
   addRegistrationTypeFailure: ['error'],
});

export const RegistrationTypeTypes = Types;
export const RegistrationTypeActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_REGISTRATION_TYPE_SUCCESS]: updateRegistrationTypeSuccess,
   [Types.GET_REGISTRATION_TYPE_SUCCESS]: getRegistrationTypeSuccess,
   [Types.ADD_REGISTRATION_TYPE_SUCCESS]: addRegistrationTypeSuccess,
});

/* ------------- Selectors ------------- */
export const selectRegistrationType = (
   state: StateReadonly
): RegistrationTypeReadonly | null => state.registrationType.registrationType;

export const selectRegistrationTypes = (
   state: StateReadonly
): readonly RegistrationTypeReadonly[] =>
   state.registrationType.registrationTypes;
