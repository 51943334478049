import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import { ComplaintSearchQuery } from 'Models/Complaint/ComplaintSearchQuery';
import { ComplaintSearchResult } from 'Models/Complaint/ComplaintSearchResult';
import { Complaint } from 'Models/Complaint/Complaint';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ComplaintState {
   complaint: Complaint;
   searchResults: ComplaintSearchResult[] | null;
}

export type ComplaintStateReadonly = Readonly<ComplaintState>;

interface TypeNames {
   GET_COMPLAINT_BY_ID_REQUEST: string;
   GET_COMPLAINT_BY_ID_SUCCESS: string;
   GET_COMPLAINT_BY_ID_FAILURE: string;

   UPDATE_COMPLAINT_REQUEST: string;
   UPDATE_COMPLAINT_SUCCESS: string;
   UPDATE_COMPLAINT_FAILURE: string;

   CREATE_COMPLAINT_REQUEST: string;
   CREATE_COMPLAINT_SUCCESS: string;
   CREATE_COMPLAINT_FAILURE: string;

   SEARCH_COMPLAINT_REQUEST: string;
   SEARCH_COMPLAINT_SUCCESS: string;
   SEARCH_COMPLAINT_FAILURE: string;
}

type ComplaintTypeNames = Readonly<TypeNames>;

export interface ComplaintCreators {
   getComplaintByIdRequest: (id: string) => AnyAction;
   getComplaintByIdSuccess: (data: Complaint | undefined) => AnyAction;
   getComplaintByIdFailure: (error: ApiError) => AnyAction;

   createComplaintRequest: (complaint: Complaint) => AnyAction;
   createComplaintSuccess: (data: Complaint) => AnyAction;
   createComplaintFailure: (error: ApiError) => AnyAction;

   updateComplaintRequest: (complaint: Complaint) => AnyAction;
   updateComplaintSuccess: (data: Complaint) => AnyAction;
   updateComplaintFailure: (error: ApiError) => AnyAction;

   searchComplaintRequest: (query: ComplaintSearchQuery) => AnyAction;
   searchComplaintSuccess: (
      data: readonly ComplaintSearchResult[]
   ) => AnyAction;
   searchComplaintFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ComplaintStateReadonly = {
   complaint: ({} as unknown) as Complaint,
   searchResults: null,
};

/* ------------- Reducers ------------- */
const getComplaintByIdSuccess = (
   state: ComplaintStateReadonly,
   action: AnyAction
): ComplaintStateReadonly => {
   return { ...state, complaint: action.data };
};

const createComplaintSuccess = (
   state: ComplaintStateReadonly,
   action: AnyAction
): ComplaintStateReadonly => {
   return { ...state, complaint: action.data };
};

const updateComplaintSuccess = (
   state: ComplaintStateReadonly,
   action: AnyAction
): ComplaintStateReadonly => {
   return { ...state, complaint: action.data };
};

const searchComplaintsReset = (
   state: ComplaintStateReadonly
): ComplaintStateReadonly => {
   return { ...state, searchResults: null };
};

const searchComplaintsSuccess = (
   state: ComplaintStateReadonly,
   action: AnyAction
): ComplaintStateReadonly => {
   return { ...state, searchResults: action.data || [] };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ComplaintTypeNames,
   ComplaintCreators
>('complaint', {
   getComplaintByIdRequest: ['id'],
   getComplaintByIdSuccess: ['data'],
   getComplaintByIdFailure: ['error'],

   createComplaintRequest: ['complaint'],
   createComplaintSuccess: ['data'],
   createComplaintFailure: ['error'],

   updateComplaintRequest: ['complaint'],
   updateComplaintSuccess: ['data'],
   updateComplaintFailure: ['error'],

   searchComplaintRequest: ['query'],
   searchComplaintSuccess: ['data'],
   searchComplaintFailure: ['error'],
});

export const ComplaintTypes = Types;
export const ComplaintActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_COMPLAINT_BY_ID_SUCCESS]: getComplaintByIdSuccess,
   [Types.CREATE_COMPLAINT_SUCCESS]: createComplaintSuccess,
   [Types.UPDATE_COMPLAINT_SUCCESS]: updateComplaintSuccess,
   [Types.SEARCH_COMPLAINT_SUCCESS]: searchComplaintsSuccess,
   [Types.SEARCH_COMPLAINT_REQUEST]: searchComplaintsReset,
});

/* ------------- Selectors ------------- */

export const selectSearchResults = (
   state: StateReadonly
): readonly ComplaintSearchResult[] | null => state.complaint.searchResults;

export const selectComplaint = (state: StateReadonly): Complaint =>
   state.complaint.complaint;
