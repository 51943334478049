import React from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import AddPanel from './Panels/AddPanel';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const AddTab = (): JSX.Element => {
   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <AddPanel />
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddTab;
