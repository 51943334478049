import React from 'react';
import { ConditionValueProps } from './ConditionValueProps';
import DateInput from 'Components/Common/DateInput/DateInput';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ConditionDatePickerProps {
   onChange?: (val: string | number) => void;
}

export const ConditionDatePicker = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
}: Readonly<ConditionDatePickerProps & ConditionValueProps>): JSX.Element => {
   return (
      <DateInput
         id={`app-${1}-${conditionCode}`}
         className="md-cell md-cell--12"
         label={description}
         inputClassName={getInputValidationClassName(!isChecked)}
         value={textValue}
         name={conditionCode}
         error={!isChecked}
         disabled={disabled}
         onChange={onChange}
      />
   );
};
