import moment from 'moment-timezone';
import { NZ_TIMEZONE } from 'Util/Constants/Common';

export const stripTimeZone = (date: string): string => {
   const plusIndex = date.indexOf('+');
   if (plusIndex < 0) return date;
   return date.substring(0, plusIndex);
};

export const toDateString = (date: Date): string => {
   return stripTimeZone(
      moment(date)
         .tz(NZ_TIMEZONE)
         .format()
   );
};

export const getCurrentDate = (): string => {
   return stripTimeZone(
      moment()
         .tz(NZ_TIMEZONE)
         .format()
   );
};

// Returns current date with time at 00:00
export const getCurrentDateWithoutTime = (): string => {
   return stripTimeZone(
      moment()
         .tz(NZ_TIMEZONE)
         .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
         .format()
   );
};
