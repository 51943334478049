import { put, call } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { MetadataActions } from 'State/Redux/MetadataRedux';
import FeeTypeReadonly from 'Models/FeeMaintenance/Dto/FeeType';
import FeeType from 'Models/FeeMaintenance/Dto/FeeType';
import { FeeTypeServiceType } from 'State/Services/FeeTypeService';
import { FeeTypeActions } from 'State/Redux/FeeTypeRedux';

interface FeeTypeParams {
   type: string;
   token: string;
   id: number;
   feeType: FeeTypeReadonly;
}

export function* updateFeeType(
   feeTypeService: Readonly<FeeTypeServiceType>,
   action: Readonly<FeeTypeParams>
): Generator {
   const { feeType } = action;
   const feeTypeResponse = yield call(feeTypeService.updateFeeType, feeType);
   const response = feeTypeResponse as ApiResponse<FeeTypeReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(FeeTypeActions.updateFeeTypeSuccess(response.data));
      yield put(MetadataActions.getMetadataByTypeRequest('FeeTypes'));
   } else {
      yield put(
         FeeTypeActions.updateFeeTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addFeeType(
   feeTypeService: Readonly<FeeTypeServiceType>,
   action: Readonly<FeeTypeParams>
): Generator {
   const { feeType } = action;
   const feeTypeResponse = yield call(feeTypeService.addFeeType, feeType);
   const response = feeTypeResponse as ApiResponse<FeeTypeReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(FeeTypeActions.addFeeTypeSuccess(response.data));
      yield put(MetadataActions.getMetadataByTypeRequest('FeeTypes'));
   } else {
      yield put(
         FeeTypeActions.addFeeTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getFeeTypes(
   feeTypeService: Readonly<FeeTypeServiceType>,
   action: Readonly<FeeTypeParams>
): Generator {
   const feeTypeResponse = yield call(feeTypeService.getFeeTypes);
   const response = feeTypeResponse as ApiResponse<readonly FeeType[]>;

   if (response.status && response.ok) {
      yield put(FeeTypeActions.getFeeTypesSuccess(response.data));
   } else {
      yield put(
         FeeTypeActions.getFeeTypesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
