import React from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router';
import { buildDocumentTitle, useDocumentTitle } from './useDocumentTitle';
import { Permission } from 'Util/Constants/Permission';
import { usePermissions } from 'Util/Helpers/Permissions';
import { toast } from 'react-toastify';

const DEFAULT_PATH = '/';

interface PgdbRouteProps extends RouteProps {
   pageTitle?: string | readonly string[];
   permissions?: Readonly<Permission> | readonly Readonly<Permission>[];
   permissionRedirectPath?: string;
}

export const PgdbRoute = ({
   pageTitle,
   permissions,
   permissionRedirectPath,
   children,
   ...rest
}: Readonly<PgdbRouteProps>): JSX.Element => {
   const docTitle = buildDocumentTitle(pageTitle);
   useDocumentTitle(docTitle);

   const location = useLocation();
   const isAuthed = usePermissions(permissions);
   const targetRedirect = permissionRedirectPath || DEFAULT_PATH;
   const shouldRedirect = !isAuthed && location.pathname !== DEFAULT_PATH;

   if (shouldRedirect) {
      toast.info(
         `You don't have permission to visit the page at '${location.pathname}' and have been redirected.`
      );
   }

   return (
      <Route {...rest}>
         {shouldRedirect ? <Redirect to={targetRedirect} /> : children}
      </Route>
   );
};
