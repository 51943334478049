import { Permission } from 'Util/Constants/Permission';
import { useSelector } from 'react-redux';
import { selectUser } from 'State/Redux/OidcRedux';

interface UserProfile {
   sid: string;
   sub: string;
   auth_time: number;
   idp: string;
   amr: readonly string[];
   user_id: string;
   name: string;
   email: string;
   licence_id: string;
   user_status: string;
   role: readonly string[];
   permission: readonly Readonly<Permission>[];
}

// hook for testing whether a permission is present
export const usePermissions = (
   requiredPermissions?: Readonly<Permission> | readonly Readonly<Permission>[]
): boolean => {
   const user = useSelector(selectUser);
   if (!user || user.expired || !user.profile) return false;

   if (!requiredPermissions) return true; // No permissions specified so returning true

   const requiredPerms = Array.isArray(requiredPermissions)
      ? requiredPermissions
      : [requiredPermissions];
   if (!requiredPerms.length) return true; // assume that permissions are not required

   const userProfile = (user.profile as unknown) as Readonly<UserProfile>;
   if (!userProfile.permission || !userProfile.permission.length) return false;

   return requiredPerms.every((p: Permission): boolean =>
      userProfile.permission.includes(p)
   );
};
