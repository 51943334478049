import CourseDto from 'Models/Examination/Dto/CourseDto';
import ContactExamEligibilityDto from './ContactExamEligibilityDto';

interface CourseCriteriaDto {
   courseCriteriaId: number;
   contactExamEligibilityId: number;
   courseId: number | null;
   displayText: string;
   courseDescription: string;
   displayOrder: number | null;
   isSystemControlled: boolean | null;
   isDeleted: boolean | null;

   course: CourseDto | null;
   contactExamEligibility: ContactExamEligibilityDto[];
}

type CourseCriteriaDtoReadonly = Readonly<CourseCriteriaDto>;

export default CourseCriteriaDtoReadonly;

export const DefaultCourseCriteria: CourseCriteriaDto = {
   courseCriteriaId: 0,
   contactExamEligibilityId: 0,
   courseId: 0,
   displayText: '',
   courseDescription: '',
   displayOrder: 0,
   isSystemControlled: false,
   isDeleted: false,

   course: null,
   contactExamEligibility: [],
};
