import React, { useState, useCallback } from 'react';
import { Column, SortingRule } from 'react-table';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';
import { useSelector, useDispatch } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import PaymentMethodReadonly, {
   validatePaymentMethod,
} from 'Models/PaymentMethod/Dto/PaymentMethod';
import {
   PaymentMethodTypes,
   PaymentMethodActions,
} from 'State/Redux/PaymentMethodRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface ResultsPanelProps {
   searchResults: readonly PaymentMethodReadonly[];
   isLoading: boolean;
}

const PaymentMethodResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const dispatch = useDispatch();
   const [editTarget, setEditTarget] = useState<PaymentMethodReadonly | null>(
      null
   );
   const isSuccess = useSelector(
      selectIsSuccess([PaymentMethodTypes.ADD_PAYMENT_METHOD_REQUEST])
   );

   const [editPaymentMethodCode, setEditCode] = useState('');
   const [editDescription, setEditDescription] = useState('');
   const [editGlcode, setEditValue] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditCodeCallback = useCallback((value: string) => {
      setEditCode(value);
   }, []);
   const setEditDescriptionCallback = useCallback((value: string) => {
      setEditDescription(value);
   }, []);
   const setEditGlcodeCallback = useCallback((value: string) => {
      setEditValue(value);
   }, []);
   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   if (updateClicked && isSuccess !== null) {
      toast.success(`Payment method updated successfully`);
      setUpdateClicked(false);
   }
   const isValidForm = editTarget
      ? Object.values(
           validatePaymentMethod({
              ...editTarget,
              paymentMethodCode: editPaymentMethodCode,
              description: editDescription,
              glcode: editGlcode,
           })
        ).every((v): boolean => !!v)
      : false;

   const DEFAULT_SORTING: SortingRule[] = [
      { id: 'paymentMethodCode', desc: false },
   ];

   const COLUMN_HEADERS: Column<PaymentMethodReadonly>[] = [
      {
         Header: 'Payment Code',
         accessor: 'paymentMethodCode',
      },
      {
         Header: 'Description',
         accessor: 'description',
      },
      {
         Header: 'GL Code',
         accessor: 'glcode',
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.paymentMethodCode);
                     setEditDescription(cellInfo.original.description);
                     setEditValue(cellInfo.original.glcode);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.paymentMethodCode);
                     setEditDescription(cellInfo.original.description);
                     setEditValue(cellInfo.original.glcode);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit Payment Method"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 payment-method-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Payment Method Code</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editPaymentMethodCode}
                     required
                     placeholder="Code"
                     onChange={(value): void => {
                        setEditCodeCallback(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Description</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editDescription}
                     required
                     placeholder="Description"
                     onChange={(value): void => {
                        setEditDescriptionCallback(value.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">GL Code</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editGlcode}
                     required
                     placeholder="GL Code"
                     onChange={(value): void => {
                        setEditGlcodeCallback(value.toString());
                     }}
                  />
               </div>
               <Button
                  disabled={!isValidForm}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           PaymentMethodActions.updatePaymentMethodRequest({
                              ...editTarget,
                              paymentMethodCode: editPaymentMethodCode,
                              description: editDescription,
                              glcode: editGlcode,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           PaymentMethodActions.updatePaymentMethodRequest({
                              ...editTarget,
                              paymentMethodCode: editPaymentMethodCode,
                              description: editDescription,
                              glcode: editGlcode,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
            TrComponent={TableRowLink}
         />
      </>
   );
};

export default PaymentMethodResultsPanel;
