import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import FinancialExtracts from './Panels/FinancialExtract';
import FinancialExtractResults from './Panels/FinancialExtractResults';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';
import { Button } from 'react-md/lib/Buttons';
import { DefaultFinancialExtractSearchInput } from 'Models/BackOffice/FinancialExtractInput';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import {
   selectSearchResult,
   FinancialExtractTypes,
   FinancialExtractActions,
   selectupdatePaymentResult,
} from 'State/Redux/FinancialExtractRedux';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectErrorMessage,
   selectIsLoading,
   selectIsSuccess,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { isValidEndDateAfterStartDate } from 'Util/Helpers/Validation';
import FileSaver from 'file-saver';
import Moment from 'moment-timezone';
import './Finance.scss';
import {
   FINANCIAL_REPORT_SEE_EXTRACTION_FILES,
   FINANCIAL_REPORT_REVIEW_BANK_DEPOSIT_SLIP,
} from 'Util/Constants/FinancialReportType';
import FinancialExtractTotals from './Panels/FinancialExtractTotals';
import {
   BatchActions,
   BatchTypes,
   selectFinancialItems,
   selectDownloadedBatchItem,
} from 'State/Redux/BatchRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import ExportHistoryResultsPanel from '../Export/Panels/ResultsPanel';
import { SelectField } from 'react-md';

const BYTE_ORDER_MARK = '\uFEFF';

const FinancialExtract = (): JSX.Element => {
   const searchError = useSelector(
      selectErrorMessage(FinancialExtractTypes.GET_SEARCH_RESULT_REQUEST)
   );
   const dispatch = useDispatch();
   const searchResults = useSelector(selectSearchResult);
   const downloadedBatchItem = useSelector(selectDownloadedBatchItem);
   const [searchInput, setSearchInput] = useState(
      DefaultFinancialExtractSearchInput
   );
   const [hasSearched, setHasSearched] = useState(false);
   const [hasFinancialSearched, setHasFinancialSearched] = useState(false);
   const [showTotals, setShowTotals] = useState(false);
   const [downloadItemClicked, setDownloadItemClicked] = useState(false);
   const [selectedFileName, setSelectedFileName] = useState('');
   const [fileName, setFileName] = useState('');
   const [softRefresh, setSoftRefresh] = useState(false);
   const [exportClicked, setExportClicked] = useState(false);
   const [extensionFile, setExtensionFile] = useState<string>('txt');

   const isBatchItemDownloading = useSelector(
      selectIsLoading([BatchTypes.DOWNLOAD_BATCH_ITEM_REQUEST])
   );
   const isBatchItemDownloaded = useSelector(
      selectIsSuccess([BatchTypes.DOWNLOAD_BATCH_ITEM_REQUEST])
   );
   const exportResults = useSelector(selectupdatePaymentResult);
   const isFileHistoryLoading = useSelector(
      selectIsLoading([BatchTypes.GET_FINANCIAL_ITEMS_REQUEST])
   );

   const isDownloaded = useSelector(
      selectIsSuccess([FinancialExtractTypes.UPDATE_FINANCIAL_EXTRACT_REQUEST])
   );

   const hasReturnedResults =
      !searchResults || !searchResults.length ? false : true;

   const fileHistory = useSelector(selectFinancialItems);

   const panelTitle = fileHistory
      ? `Results (${fileHistory.length})`
      : 'Results';

   useEffect((): void => {
      if (
         !exportResults ||
         exportResults.size < 1 ||
         !exportClicked ||
         !isDownloaded
      )
         return;

      const blob = new Blob([BYTE_ORDER_MARK, exportResults], {
         type: 'text/csv',
      });

      FileSaver.saveAs(blob, fileName);

      setExportClicked(false);
   }, [exportResults, fileName, exportClicked, isDownloaded]);

   const search = (): void => {
      setHasFinancialSearched(false);
      setHasSearched(false);
      setShowTotals(false);
      setSoftRefresh(false);

      if (
         searchInput.startDate &&
         searchInput.endDate &&
         !isValidEndDateAfterStartDate(
            searchInput.startDate,
            searchInput.endDate
         )
      ) {
         toast.error('Start date should be less than End date');
         return;
      }

      const searchQuery = { ...searchInput };

      if (
         searchInput.financialExtractTypeCode !==
         FINANCIAL_REPORT_SEE_EXTRACTION_FILES
      ) {
         dispatch(FinancialExtractActions.getSearchResultRequest(searchQuery));
         setHasSearched(true);
      }

      if (
         searchInput.financialExtractTypeCode ===
         FINANCIAL_REPORT_REVIEW_BANK_DEPOSIT_SLIP
      ) {
         setShowTotals(true);
      }

      if (
         searchInput.financialExtractTypeCode ===
         FINANCIAL_REPORT_SEE_EXTRACTION_FILES
      ) {
         setHasFinancialSearched(true);
         dispatch(
            BatchActions.getFinancialItemsRequest({
               startDate: searchQuery.startDate ?? '',
               endDate: searchQuery.endDate ?? '',
               batchProcessCode: '',
            })
         );
      }
   };

   const reset = (): void => {
      setSearchInput(DefaultFinancialExtractSearchInput);
      setHasSearched(false);
      setShowTotals(false);
      setHasFinancialSearched(false);
      setSoftRefresh(false);
      dispatch(FinancialExtractActions.getSearchResultReset());
   };

   useEffect((): void => {
      if (searchError) {
         toast.error(searchError);
      }
   }, [searchError]);

   useEffect((): void => {
      if (isBatchItemDownloaded && downloadItemClicked) {
         setDownloadItemClicked(false);
         if (downloadedBatchItem === null || downloadedBatchItem.size < 1) {
            toast.error(`No data available for extract`);
         } else {
            const blob = new Blob([downloadedBatchItem]);

            FileSaver.saveAs(blob, selectedFileName);
         }
         setSoftRefresh(true);
         const searchQuery = { ...searchInput };
         dispatch(
            BatchActions.getFinancialItemsRequest({
               startDate: searchQuery.startDate ?? '',
               endDate: searchQuery.endDate ?? '',
               batchProcessCode: '',
            })
         );
      }
   }, [
      isBatchItemDownloaded,
      downloadedBatchItem,
      downloadItemClicked,
      selectedFileName,
      dispatch,
      searchInput,
   ]);

   const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
   const onConfirm = (): void => {
      setShowConfirmationDialog(true);
   };

   const onConfirmationDialogConfirm = (): void => {
      const file = `XERO-${Moment().format(
         'YYYY-MM-DD-HH-mm-ss'
      )}_Manual.${extensionFile}`;
      setFileName(file);

      dispatch(
         FinancialExtractActions.updateFinancialExtractRequest(
            searchResults || [],
            searchInput.financialExtractTypeCode || '',
            file
         )
      );
      setShowConfirmationDialog(false);
      toast.success('Successfully Extracted');
      setExportClicked(true);
      dispatch(FinancialExtractActions.getSearchResultReset());
   };

   const onConfirmationDialogCancel = (): void => {
      setShowConfirmationDialog(false);
   };

   return (
      <ExpansionList className="md-cell md-cell--12 search-container">
         <FinancialExtracts
            searchInput={searchInput}
            updateSearchInput={setSearchInput}
            onSearch={search}
            onReset={reset}
         />
         {hasSearched && (
            <FinancialExtractResults searchResults={searchResults || []} />
         )}
         {showTotals && (
            <FinancialExtractTotals totalResults={searchResults || []} />
         )}
         <>
            {hasReturnedResults && hasSearched ? (
               <>
                  <SelectField
                     id="select-extension-file"
                     lineDirection="center"
                     className="md-cell md-cell--1 md-cell--9-offset"
                     label="Extension file"
                     menuItems={['txt', 'csv']}
                     listClassName={'max-height-50'}
                     position={SelectField.Positions.BELOW}
                     value={extensionFile}
                     onChange={(val): void => setExtensionFile(val.toString())}
                  />
                  <Button
                     className="md-cell md-cell--2"
                     flat
                     primary
                     swapTheming
                     onClick={(): void => onConfirm()}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) onConfirm();
                     }}
                  >
                     Extract
                  </Button>
               </>
            ) : (
               <></>
            )}
            {(hasFinancialSearched && !isFileHistoryLoading) || softRefresh ? (
               <ExpansionPanel
                  label={panelTitle}
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ExportHistoryResultsPanel
                     downloadFile={(index: number): void => {
                        setDownloadItemClicked(true);
                        setSelectedFileName(fileHistory[index].filename);
                        dispatch(
                           BatchActions.downloadBatchItemRequest(
                              fileHistory[index].batchId.toString()
                           )
                        );
                     }}
                     isBatchItemDownloading={isBatchItemDownloading}
                     searchResults={fileHistory || []}
                     isRefreshing={isFileHistoryLoading}
                  />
               </ExpansionPanel>
            ) : (
               <></>
            )}
            <ConfirmationDialog
               id="fn-detail-confirmation"
               onConfirm={onConfirmationDialogConfirm}
               onCancel={onConfirmationDialogCancel}
               visible={showConfirmationDialog}
               portal
            >
               <>Are you sure you want to Extract these records?</>
            </ConfirmationDialog>
         </>
      </ExpansionList>
   );
};

export default FinancialExtract;
