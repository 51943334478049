import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import { Metadata } from 'Models/Metadata';
import { MetadataTypeModel } from 'Models/Metadata/MetadataByType';
import { MetadataType } from 'Util/Constants/MetadataType';

export interface MetadataServiceType {
   getMetadata(): Promise<ApiResponse<Metadata>>;
   getMetadataByType(
      metadataByType: MetadataType
   ): Promise<ApiResponse<MetadataTypeModel>>;
}

const create = (baseURL: string | undefined): MetadataServiceType => {
   const api = createApi({ baseURL });

   const getMetadata = (): Promise<ApiResponse<Metadata>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Metadata`);
   };

   const getMetadataByType = (
      metadataByType: MetadataType
   ): Promise<ApiResponse<MetadataTypeModel>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Metadata/ByType/${metadataByType}`);
   };

   return {
      getMetadata,
      getMetadataByType,
   };
};

export default {
   create,
};
