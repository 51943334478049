interface RegistrationNumberContactValidation {
   id: number;
   isValid: boolean;
   registrationNumber?: number;
   passFailCode: string;
   mark?: number;
   contactName: string;
   resultDate: string;
   isValidated: boolean;
}

type RegistrationNumberContactValidationReadonly = Readonly<
   RegistrationNumberContactValidation
>;

export const DefaultRegistrationNumberContactValidation: RegistrationNumberContactValidation = {
   id: 0,
   isValid: false,
   registrationNumber: undefined,
   passFailCode: '',
   mark: undefined,
   contactName: '',
   resultDate: '',
   isValidated: false,
};

export default RegistrationNumberContactValidationReadonly;
