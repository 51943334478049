// Refer to https://tools.ietf.org/html/rfc4180 for more info
const SEPARATOR = ',';
const NEWLINE = '\r\n';
const CHARS_REQUIRING_QUOTING = ['"', ',', '\r\n'];

const requiresQuoting = (text: string): boolean =>
   CHARS_REQUIRING_QUOTING.some(c => text.includes(c));

const escapeValue = (value: string): string => {
   // can't use value.replace('"', '""') because JS only replaces the first matching substring
   const quoteEscaped = value.replace(/"/g, '""');
   return requiresQuoting(quoteEscaped)
      ? '"' + quoteEscaped + '"'
      : quoteEscaped;
};

export const createCsv = (
   // eslint-disable-next-line
   rows: readonly Readonly<Record<string, any>>[]
): string => {
   const headers = Object.keys(rows[0]);
   const headerLine = headers.map(escapeValue).join(SEPARATOR);

   const csvLines = rows.map((row): string => {
      const rowValues = headers.map((header): string => {
         const columnValue =
            row[header] === undefined || row[header] === null
               ? ''
               : (row[header].toString() as string);
         return escapeValue(columnValue);
      });
      return rowValues.join(SEPARATOR);
   });

   return [headerLine, ...csvLines].join(NEWLINE);
};
