export type RegistrationTypeCode =
   | 'CP'
   | 'CG'
   | 'CD'
   | 'RP'
   | 'RG'
   | 'RD'
   | 'JP'
   | 'JG'
   | 'JD';

export const JOURNEYMAN_REGISTRATION_TYPES: readonly string[] = [
   'JD',
   'JG',
   'JP',
] as RegistrationTypeCode[];
export const TRADESMAN_REGISTRATION_TYPES: readonly string[] = [
   'RD',
   'RG',
   'RP',
] as RegistrationTypeCode[];
export const CERTIFYING_REGISTRATION_TYPES: readonly string[] = [
   'CD',
   'CG',
   'CP',
] as RegistrationTypeCode[];

export const DRAINLAYER_REGISTRATION_TYPES: readonly string[] = [
   'JD',
   'RD',
   'CD',
] as RegistrationTypeCode[];
export const PLUMBER_REGISTRATION_TYPES: readonly string[] = [
   'JP',
   'RP',
   'CP',
] as RegistrationTypeCode[];
export const GASFITTER_REGISTRATION_TYPES: readonly string[] = [
   'JG',
   'RG',
   'CG',
] as RegistrationTypeCode[];

export const DISCIPLINE_CODES: string[] = ['P', 'G', 'D'];
