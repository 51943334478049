import React, { useState, useCallback } from 'react';
import { Column, SortingRule } from 'react-table';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';
import { useSelector, useDispatch } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import SystemParametersReadonly, {
   validateSystemParameters,
} from 'Models/SystemParameters/Data/SystemParameters';
import {
   SystemParametersTypes,
   SystemParametersActions,
} from 'State/Redux/SystemParametersRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface ResultsPanelProps {
   searchResults: readonly SystemParametersReadonly[];
   isLoading: boolean;
}

const SystemParameterResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const dispatch = useDispatch();
   const [
      editTarget,
      setEditTarget,
   ] = useState<SystemParametersReadonly | null>(null);
   const isSuccess = useSelector(
      selectIsSuccess([SystemParametersTypes.ADD_SYSTEM_PARAMETERS_REQUEST])
   );

   const [editCode, setEditCode] = useState('');
   const [editDescription, setEditDescription] = useState('');
   const [editValue, setEditValue] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditCodeCallback = useCallback((value: string) => {
      setEditCode(value);
   }, []);
   const setEditDescriptionCallback = useCallback((value: string) => {
      setEditDescription(value);
   }, []);
   const setEditValueCallback = useCallback((value: string) => {
      setEditValue(value);
   }, []);
   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   if (updateClicked && isSuccess !== null) {
      toast.success(`System parameter updated successfully`);
      setUpdateClicked(false);
   }
   const isValidForm = editTarget
      ? Object.values(
           validateSystemParameters({
              ...editTarget,
              parameterCode: editCode,
              description: editDescription,
              value: editValue,
           })
        ).every((v): boolean => !!v)
      : false;

   const DEFAULT_SORTING: SortingRule[] = [
      { id: 'parameterCode', desc: false },
   ];

   const COLUMN_HEADERS: Column<SystemParametersReadonly>[] = [
      {
         Header: 'Code',
         accessor: 'parameterCode',
      },
      {
         Header: 'Description',
         accessor: 'description',
      },
      {
         Header: 'Value',
         accessor: 'value',
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.parameterCode);
                     setEditDescription(cellInfo.original.description);
                     setEditValue(cellInfo.original.value);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.parameterCode);
                     setEditDescription(cellInfo.original.description);
                     setEditValue(cellInfo.original.value);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <React.Fragment>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit System Paremeter"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 system-parameter-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Code</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCode}
                     required
                     placeholder="Code"
                     onChange={(value): void => {
                        setEditCodeCallback(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Description</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editDescription}
                     required
                     placeholder="Description"
                     onChange={(value): void => {
                        setEditDescriptionCallback(value.toString());
                     }}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Value</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editValue}
                     required
                     placeholder="Value"
                     onChange={(value): void => {
                        setEditValueCallback(value.toString());
                     }}
                  />
               </div>
               <Button
                  disabled={!isValidForm}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           SystemParametersActions.updateSystemParametersRequest(
                              {
                                 ...editTarget,
                                 parameterCode: editCode,
                                 description: editDescription,
                                 value: editValue,
                              }
                           )
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           SystemParametersActions.updateSystemParametersRequest(
                              {
                                 ...editTarget,
                                 parameterCode: editCode,
                                 description: editDescription,
                                 value: editValue,
                              }
                           )
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
            TrComponent={TableRowLink}
         />
      </React.Fragment>
   );
};

export default SystemParameterResultsPanel;
