import Moment from 'moment-timezone';

interface FeeRequestDetailDisplay {
   feeRequestId?: number;
   feeRequestNumber: string;
   registrationNumber?: number;
   feeRequestDate: string;
   feeRequestStatusCode: string;
   totalAmount: number;
   paidAmount: number;
   applicationId?: number;
   contactId?: number;
}

export default FeeRequestDetailDisplay;

const currentDate = Moment();

export const DefaultFeeRequestDetail: FeeRequestDetailDisplay = {
   feeRequestNumber: '',
   registrationNumber: undefined,
   feeRequestDate: currentDate.toString(),
   feeRequestStatusCode: 'UNPD',
   totalAmount: 0,
   paidAmount: 0,
};
