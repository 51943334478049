import React from 'react';
import { SelectField } from 'react-md/lib/SelectFields';
import DateInput from 'Components/Common/DateInput/DateInput';
import { TextField } from 'react-md/lib/TextFields';
import '../Payment.scss';
import PaymentDetailPaymentRecordReadOnly from 'Models/Payment/Data/PaymentDetailPaymentRecord';
import {
   usePaymentMethodList,
   usePaymentStatusList,
} from 'Util/Helpers/Metadata';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import moment from 'moment-timezone';
import { NZ_TIMEZONE } from 'Util/Constants/Common';
import { CREDIT_CARD_STAFF } from 'Util/Constants/PaymentMethod';
import { PaymentValidationFields } from '../Payment';
import { ValidationResult } from 'Components/Common/ModelValidator/ValidationModel';
import { FocusContainer } from 'react-md/lib/Helpers';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface PaymentDetailProps {
   isPaid: boolean;
   isCancelled: boolean;
   payment: PaymentDetailPaymentRecordReadOnly;
   updateModel: (
      model: PaymentDetailPaymentRecordReadOnly,
      field: keyof PaymentValidationFields
   ) => void;
   validationResult?: ValidationResult<PaymentValidationFields>;
   canUpdatePaymentDate: boolean;
}

const PaymentDetail = ({
   isPaid,
   isCancelled,
   payment,
   updateModel,
   validationResult,
   canUpdatePaymentDate,
}: Readonly<PaymentDetailProps>): JSX.Element => {
   const paymentTypes = usePaymentMethodList(undefined, true);
   const paymentStatus = usePaymentStatusList();

   return (
      <FocusContainer focusOnMount={!isPaid} className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <SelectField
               id="select-payment-type"
               label="Payment Type"
               placeholder="Select Payment Type"
               menuItems={paymentTypes}
               position={SelectField.Positions.BELOW}
               onChange={(val): void => {
                  updateModel(
                     {
                        ...payment,
                        paymentMethod: val.toString(),
                     },
                     'paymentMethod'
                  );
               }}
               value={payment.paymentMethod}
               required
               inputClassName={getInputValidationClassName(
                  validationResult &&
                     validationResult.fields.paymentMethod.error
               )}
               errorText="Please select the payment type"
               error={
                  validationResult &&
                  validationResult.fields.paymentMethod.error
               }
               simplifiedMenu={false}
               disabled={isPaid || isCancelled}
               listClassName="select-menu"
            />
         </div>
         <div className="md-cell md-cell--6"></div>
         <DateInput
            id="datepicker-payment-date"
            label="Payment Date"
            className="md-cell md-cell--6"
            inputClassName={getInputValidationClassName(
               validationResult && validationResult.fields.paymentDate.error
            )}
            onChange={(date): void => {
               updateModel(
                  {
                     ...payment,
                     paymentDate: date,
                  },
                  'paymentDate'
               );
            }}
            required
            error={
               validationResult && validationResult.fields.paymentDate.error
            }
            errorText="Please enter the payment date"
            value={payment.paymentDate}
            disabled={
               isPaid && canUpdatePaymentDate && !isCancelled
                  ? false
                  : isPaid ||
                    isCancelled ||
                    payment.paymentMethod === CREDIT_CARD_STAFF
            }
         />
         {isPaid || payment.paymentMethod !== CREDIT_CARD_STAFF ? (
            <>
               <div className="md-cell md-cell--6">
                  <TextField
                     id="textfield-payer-name"
                     label="Payer's Name"
                     type="text"
                     onChange={(val): void => {
                        updateModel(
                           {
                              ...payment,
                              payerName: val.toString(),
                           },
                           'payerName'
                        );
                     }}
                     value={payment.payerName}
                     required
                     disabled={isPaid}
                     inputClassName={getInputValidationClassName(
                        validationResult &&
                           validationResult.fields.payerName.error
                     )}
                     error={
                        validationResult &&
                        validationResult.fields.payerName.error
                     }
                     errorText="Please enter the payer's name"
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     id="textfield-bank-branch"
                     label="Bank/Branch"
                     type="text"
                     onChange={(val): void => {
                        updateModel(
                           {
                              ...payment,
                              bankBranch: val.toString(),
                           },
                           'bankBranch'
                        );
                     }}
                     value={payment.bankBranch}
                     required
                     disabled={isPaid}
                     inputClassName={getInputValidationClassName(
                        validationResult &&
                           validationResult.fields.bankBranch.error
                     )}
                     error={
                        validationResult &&
                        validationResult.fields.bankBranch.error
                     }
                     errorText="Please enter the bank/branch information"
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     id="textfield-reference-number"
                     label="Reference Number"
                     type="text"
                     onChange={(val): void => {
                        updateModel(
                           {
                              ...payment,
                              referenceNumber: val.toString(),
                           },
                           'referenceNumber'
                        );
                     }}
                     value={payment.referenceNumber}
                     required
                     disabled={isPaid}
                     inputClassName={getInputValidationClassName(
                        validationResult &&
                           validationResult.fields.referenceNumber.error
                     )}
                     error={
                        validationResult &&
                        validationResult.fields.referenceNumber.error
                     }
                     errorText="Please enter the reference number"
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <SelectField
                     id="select-payment-status"
                     label="Status"
                     placeholder="Select Payment Status"
                     menuItems={paymentStatus}
                     position={SelectField.Positions.BELOW}
                     onChange={(val): void => {
                        updateModel(
                           {
                              ...payment,
                              paymentStatus: val.toString(),
                           },
                           'paymentStatus'
                        );
                     }}
                     value={payment.paymentStatus}
                     required
                     inputClassName={getInputValidationClassName(
                        validationResult &&
                           validationResult.fields.paymentStatus.error
                     )}
                     errorText="Please select the payment status"
                     error={
                        validationResult &&
                        validationResult.fields.paymentStatus.error
                     }
                     simplifiedMenu={false}
                     disabled={isPaid}
                  />
               </div>
            </>
         ) : null}
         <div className="md-cell md-cell--6">
            <TextField
               id="textfield-amount"
               label="Amount"
               type="text"
               className="total-amount"
               value={formatCurrencyString(payment.amount)}
               required
               disabled
            />
            {isPaid && (
               <span className="payment-status-info">
                  {`${payment.createdBy}${
                     payment.createdOn
                        ? ` 
                           ${moment(payment.createdOn)
                              .tz(NZ_TIMEZONE)
                              .format('DD/MM/YYYY H:mma')}`
                        : ''
                  }`}
               </span>
            )}
         </div>
      </FocusContainer>
   );
};

export default PaymentDetail;
