import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import userManager from 'Util/Auth/AuthManager';
import { User } from 'oidc-client';
import './auth.scss';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import queryString from 'query-string';
import StateReadonly from 'State/Redux/StateModel';

const SignInCallback = (): JSX.Element => {
   const user = useSelector(
      (state: StateReadonly): User | undefined => state.oidc.user
   );

   const [isSuccess, setIsSuccess] = useState(false);
   const [error, setError] = useState<Error>();
   const location = useLocation();

   if (user && !user.access_token) {
      return <></>;
   }

   if (isSuccess) {
      const values = queryString.parse(location.search);
      const redirectPath =
         values &&
         values.returnUrl &&
         !Array.isArray(values.returnUrl) &&
         !isNullOrWhiteSpace(values.returnUrl)
            ? values.returnUrl
            : '/';

      return <Redirect to={redirectPath} />;
   }

   if (error && !isNullOrWhiteSpace(error.message)) {
      userManager.removeUser();
      return (
         <div className="auth-error">
            <div>
               <p>
                  {error.message}
                  <br />
               </p>
               <a href={window.location.origin}>Go back to Home</a>
               <br />
               <a href="/logout">Logout</a>
            </div>
         </div>
      );
   }

   return (
      <CallbackComponent
         userManager={userManager}
         successCallback={(): void => {
            setIsSuccess(true);
         }}
         errorCallback={(error): void => {
            setError(error);
         }}
      >
         <div className="auth-loading" />
      </CallbackComponent>
   );
};

export default SignInCallback;
