import React, { useEffect } from 'react';
import { ContactEditModel } from 'Models/Contact/Data/ContactEditModel';
import TextField from 'react-md/lib/TextFields';
import {
   isNullOrWhiteSpace,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import { ValidationResult } from 'Components/Common/ModelValidator/ValidationModel';
import { CompanyContactValidationFields } from '../CreateCompanyContact';
import { useContactStatusList } from 'Util/Helpers/Metadata';
import SelectField from 'react-md/lib/SelectFields';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { REGISTRATION_NUMBER_LENGTH } from 'Util/Helpers/RegistrationNumber';

interface HeaderSectionProps {
   model: ContactEditModel;
   updateModel: (model: ContactEditModel) => void;
   isEditMode?: boolean;
   validationResult?: ValidationResult<CompanyContactValidationFields>;
}

const HeaderSection = ({
   model,
   updateModel,
   isEditMode,
   validationResult,
}: Readonly<HeaderSectionProps>): JSX.Element => {
   const contactStatuses = useContactStatusList();

   useEffect((): void => {
      if (!model.contactStatusCode && contactStatuses.length) {
         updateModel({ ...model, contactStatusCode: contactStatuses[0].value });
      }
   }, [model, contactStatuses, updateModel]);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-company-name"
               label="Company Trading Name"
               lineDirection="center"
               placeholder="Company Trading Name"
               className="md-cell md-cell--bottom md-cell--12"
               inputClassName={getInputValidationClassName(
                  validationResult && validationResult.fields.companyName.error
               )}
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     companyName: val.toString(),
                  });
               }}
               defaultValue={model.companyName}
               required
               errorText="Please enter the company trading name"
               error={
                  validationResult && validationResult.fields.companyName.error
               }
               maxLength={100}
            />
            <TextField
               floating
               id="floating-center-company-url"
               label="URL"
               lineDirection="center"
               placeholder="URL"
               className="md-cell md-cell--bottom md-cell--12"
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     url: val.toString(),
                  });
               }}
               defaultValue={model.url}
               maxLength={200}
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               className="md-cell md-cell--bottom md-cell--12"
               inputClassName={getInputValidationClassName(
                  validationResult &&
                     validationResult.fields.registrationNumber.error
               )}
               maxLength={REGISTRATION_NUMBER_LENGTH}
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     registrationNumber: isNullOrWhiteSpace(val)
                        ? undefined
                        : Number(val),
                  });
               }}
               type="number"
               disabled={isEditMode && model.registrationNumber ? true : false}
               defaultValue={model.registrationNumber}
               error={
                  validationResult &&
                  validationResult.fields.registrationNumber.error
               }
               errorText="Invalid Licence Number"
            />
            <SelectField
               floating
               id="select-contact-status"
               label="Status"
               placeholder="Select Status"
               className="md-cell md-cell--12 contact-status-select"
               inputClassName={getInputValidationClassName(
                  validationResult &&
                     validationResult.fields.contactStatusCode.error
               )}
               menuItems={contactStatuses}
               position={SelectField.Positions.BELOW}
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     contactStatusCode: val.toString(),
                  });
               }}
               value={model.contactStatusCode}
               required
               errorText="Please select the Status"
               error={
                  validationResult &&
                  validationResult.fields.contactStatusCode.error
               }
            />
            <h3>Notifications</h3>
            <small>(You may select Multiple)</small>
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--4">
                  <Checkbox
                     id="checkbox-no-sms"
                     name="simple-checkboxes[]"
                     label="SMS Notifications for Applications"
                     value="material-design"
                     onChange={(e): void => {
                        updateModel({
                           ...model,
                           optOutSmsnotifications: !e,
                        });
                     }}
                     defaultChecked={!model.optOutSmsnotifications}
                  />
               </div>
               <div className="md-cell md-cell--4">
                  <Checkbox
                     id="checkbox-no-email"
                     name="simple-checkboxes[]"
                     label="Email Notifications for Applications"
                     value="material-design"
                     onChange={(e): void => {
                        updateModel({
                           ...model,
                           optOutEmailNotifications: !e,
                        });
                     }}
                     defaultChecked={!model.optOutEmailNotifications}
                  />
               </div>
               <div className="md-cell md-cell--4">
                  <Checkbox
                     id="checkbox-subscribed"
                     name="simple-checkboxes[]"
                     label="Subscribed"
                     value="material-design"
                     onChange={(e): void => {
                        updateModel({
                           ...model,
                           subscribeFlag: e,
                        });
                     }}
                     defaultChecked={model.subscribeFlag}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default HeaderSection;
