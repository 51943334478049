import React from 'react';
import { SelectField } from 'react-md/lib/SelectFields';
import {
   getDropdownList,
   DEFAULT_COUNTRY_CODE,
} from 'Util/Constants/CountryCode';
import { InputProps } from '../Shared/InputProps';

interface CountryInputProps extends InputProps {
   menuClassName?: string;
   onChange: (val: string) => void;
}

const CountryInput = ({
   id,
   label,
   placeholder,
   className,
   errorText,
   menuClassName,
   required,
   onChange,
}: Readonly<CountryInputProps>): JSX.Element => {
   const countryCodes = [...getDropdownList()];
   return (
      <SelectField
         floating
         id={id}
         label={label}
         placeholder={placeholder}
         className={className}
         errorText={errorText}
         menuItems={countryCodes}
         menuClassName={menuClassName}
         defaultValue={DEFAULT_COUNTRY_CODE}
         required={required}
         onChange={(val): void => onChange(val.toString())}
      />
   );
};

export default CountryInput;
