import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import Moment from 'moment-timezone';
import { NZ_TIMEZONE } from 'Util/Constants/Common';
import { getCurrentDate, stripTimeZone } from 'Util/Helpers/Date';

interface ComplaintModel {
   complaintId: number;
   contactId?: number;
   name?: string;
   startDate: string | null;
   endDate: string | null;
   statusCode: string;
   description: string;
}

export type Complaint = Readonly<ComplaintModel>;

export const DefaultComplaint: Complaint = {
   complaintId: 0,
   startDate: getCurrentDate(),
   endDate: stripTimeZone(
      Moment(new Date())
         .tz(NZ_TIMEZONE)
         .add(3, 'years')
         .format()
   ),
   statusCode: ' ',
   description: '',
};

interface ValidationModel {
   contactId: boolean;
   startDate: boolean;
   endDate: boolean;
   statusCode: boolean;
   description: boolean;
}

export type ComplaintValidation = Readonly<ValidationModel>;

export const InitialValidationModel: ComplaintValidation = {
   contactId: true,
   startDate: true,
   endDate: true,
   statusCode: true,
   description: true,
};

export const validateComplaint = (
   complaint: Complaint
): ComplaintValidation => {
   const startDateExists = Moment(complaint.startDate || '').isValid();
   const endDateExists = Moment(complaint.endDate || '').isValid();

   const isValidStartDate =
      !startDateExists ||
      (startDateExists && !endDateExists) ||
      (startDateExists &&
         endDateExists &&
         Moment(complaint.startDate || '') <= Moment(complaint.endDate || ''));
   const isValidEndDate =
      !endDateExists ||
      (endDateExists &&
         startDateExists &&
         Moment(complaint.startDate || '') <= Moment(complaint.endDate || ''));

   return {
      contactId: !!(complaint.contactId && complaint.contactId > 0),
      startDate: isValidStartDate,
      endDate: isValidEndDate,
      statusCode: !isNullOrWhiteSpace(complaint.statusCode),
      description: !isNullOrWhiteSpace(complaint.description),
   };
};
