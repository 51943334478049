import React, { useState, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import ViewEditPanel from './Panels/ViewEditPanel';
import { usePermissions } from 'Util/Helpers/Permissions';
import Button from 'react-md/lib/Buttons';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const ViewEditTab = (): JSX.Element => {
   const hasEdit = usePermissions(['DisciplinaryMatter.Update']);
   const [isEditing, setIsEditing] = useState(false);
   const updateEditingState = useCallback(
      (enableEditing: boolean): void => {
         const newState = hasEdit && enableEditing;
         setIsEditing(newState);
      },
      [hasEdit]
   );

   const panelLabel = hasEdit ? (
      <>
         View/Edit
         <Button
            className="plus-btn"
            onClick={(e: React.MouseEvent): void => {
               setIsEditing(!isEditing);
               e.stopPropagation();
            }}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) setIsEditing(!isEditing);
            }}
         >
            <FaIcon icon="edit" />
         </Button>
      </>
   ) : (
      'View'
   );

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label={panelLabel}
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <ViewEditPanel
               isEditing={isEditing}
               setIsEditing={updateEditingState}
            />
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default ViewEditTab;
