import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import { BoardMeetingDateSearchQuery } from 'Models/BoardMeetingDate/BoardMeetingDateSearchQuery';
import BoardMeetingDateReadonly from 'Models/BoardMeetingDate/BoardMeetingDate';

export interface BoardMeetingDateServiceType {
   updateBoardMeetingDate(
      boardMeetingDate: BoardMeetingDateReadonly
   ): Promise<ApiResponse<readonly BoardMeetingDateReadonly[]>>;
   searchBoardMeetingDate(
      query: BoardMeetingDateSearchQuery
   ): Promise<ApiResponse<BoardMeetingDateReadonly>>;
   addBoardMeetingDate(
      boardMeetingDate: BoardMeetingDateReadonly
   ): Promise<ApiResponse<BoardMeetingDateReadonly>>;
}

const create = (baseURL: string | undefined): BoardMeetingDateServiceType => {
   const api = createApi({ baseURL });

   const updateBoardMeetingDate = (
      boardMeetingDate: BoardMeetingDateReadonly
   ): Promise<ApiResponse<readonly BoardMeetingDateReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/BoardMeetingDate`, boardMeetingDate);
   };

   const searchBoardMeetingDate = (
      query: BoardMeetingDateSearchQuery
   ): Promise<ApiResponse<BoardMeetingDateReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/BoardMeetingDate/Search`, query);
   };

   const addBoardMeetingDate = (
      boardMeetingDate: BoardMeetingDateReadonly
   ): Promise<ApiResponse<BoardMeetingDateReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/BoardMeetingDate`, boardMeetingDate);
   };

   return {
      searchBoardMeetingDate,
      updateBoardMeetingDate,
      addBoardMeetingDate,
   };
};

export default {
   create,
};
