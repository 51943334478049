import React from 'react';
import { ServerSideTable } from 'Components/Common/ServerSideTable/ServerSideTable';
import { PagedQuery } from 'Models/Other/PagedQuery';
import { RowInfo, Column, CellInfo } from 'react-table';
import ApplicationSearchResultReadonly, {
   ApplicationSearchResultModelReadonly,
} from 'Models/Application/Data/ApplicationSearchResult';
import { NZ_TIMEZONE, DISPLAY_DATE_FORMAT } from 'Util/Constants/Common';
import Moment from 'moment-timezone';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';

const getApplicationUrl = (
   app: ApplicationSearchResultModelReadonly
): string => {
   if (!app) return '';
   return `/contact/${app.contactId}/applications/${app.applicationId}`;
};

const DateCell = (cell: CellInfo): string => {
   if (!cell.value) return '';
   return Moment(cell.value)
      .tz(NZ_TIMEZONE)
      .format(DISPLAY_DATE_FORMAT);
};

const TitledCell = (cell: CellInfo): JSX.Element => {
   if (!cell.value) return <></>;
   return <span title={cell.value}>{cell.value}</span>;
};

const COLUMN_HEADERS: Column<ApplicationSearchResultModelReadonly>[] = [
   {
      Header: 'Licence Number',
      accessor: 'registrationNumber',
   },
   {
      Header: "Registrant's Name",
      accessor: 'registrantName',
      Cell: TitledCell,
   },
   {
      Header: 'Licensing Year',
      accessor: 'licenceYearDescription',
      Cell: TitledCell,
   },
   {
      Header: 'Application Type',
      accessor: 'applicationTypeDescription',
      Cell: TitledCell,
   },
   {
      Header: 'Status',
      accessor: 'applicationStatusDescription',
      Cell: TitledCell,
   },
   {
      Header: 'Created',
      accessor: 'createdDate',
      Cell: DateCell,
   },
   {
      Header: 'Updated',
      accessor: 'updatedDate',
      Cell: DateCell,
   },
];

interface ApplicationSearchResultsPanelProps {
   searchResults: ApplicationSearchResultReadonly | null;
   paging: PagedQuery;
   onPagingChanged: (paging: PagedQuery) => void;
   isLoading: boolean;
}

const ApplicationSearchResultsPanel = ({
   searchResults,
   paging,
   onPagingChanged,
   isLoading,
}: Readonly<ApplicationSearchResultsPanelProps>): JSX.Element => {
   const totalCount =
      searchResults && searchResults.totalCount ? searchResults.totalCount : 0;
   const applications =
      searchResults && searchResults.applications
         ? searchResults.applications
         : [];

   return (
      <ServerSideTable
         headers={COLUMN_HEADERS}
         data={applications}
         totalResults={totalCount}
         paging={paging}
         onPagingChanged={onPagingChanged}
         isLoading={isLoading}
         TrComponent={TableRowLink}
         getTrProps={(
            finalState: any, //eslint-disable-line
            rowInfo?: RowInfo
         ): object => {
            if (!rowInfo) return {};
            const application =
               rowInfo &&
               (rowInfo.original as ApplicationSearchResultModelReadonly);
            if (!application) return {};
            if (application.applicationId <= 0 || application.contactId <= 0)
               return {};
            const targetUrl = getApplicationUrl(application);
            return {
               to: targetUrl,
               isExternal: true,
            };
         }}
      />
   );
};

export default ApplicationSearchResultsPanel;
