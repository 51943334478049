export const LICENCE_STATUS_ACTIVE = 'ACTV';
export const LICENCE_STATUS_EXPIRED = 'EXPD';
export const LICENCE_STATUS_REVOKED = 'REVD';
export const LICENCE_STATUS_PAYMENT_REVERSED = 'PAYR';
export const LICENCE_STATUS_READY_TO_PRINT = 'RDYP';
export const LICENCE_STATUS_REPRINT = 'RPNT';
export const LICENCE_STATUS_SUSPENDED = 'SUPD';
export const LICENCE_STATUS_CANCELLED = 'CNCL';
export const LICENCE_STATUS_PENDING = 'PEND';

export const ActiveLicenceStatuses: readonly string[] = [
   LICENCE_STATUS_REPRINT,
   LICENCE_STATUS_ACTIVE,
   LICENCE_STATUS_READY_TO_PRINT,
];
