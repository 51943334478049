import React, { useEffect, useState } from 'react';
import './ReviewApplications.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectIsLoading,
   selectIsSuccess,
   selectErrorMessage,
} from 'State/Redux/AsyncRedux';
import {
   ApplicationTypes,
   ApplicationActions,
   selectPendingReviewApplications,
} from 'State/Redux/ApplicationRedux';
import { Permission } from 'Util/Constants/Permission';
import { usePermissions } from 'Util/Helpers/Permissions';
import { CellInfo, Column, SortingRule } from 'react-table';
import Moment from 'moment-timezone';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { ApplicationSearchResultModelReadonly } from 'Models/Application/Data/ApplicationSearchResult';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { Grid, Cell } from 'react-md/lib/Grids';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { Link } from 'react-router-dom';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { ApplicationPendingReviewReadonly } from 'Models/Application/Data/ApplicationPendingReview';
import Button from 'react-md/lib/Buttons';
import {
   APPROVED_STATUS,
   REJECTED_STATUS,
} from 'Util/Constants/ApplicationStatus';
import { toast } from 'react-toastify';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';

const REGISTRAR_PERMISSION_READ: Permission = 'ReviewRegistration.Read';
const REGISTRAR_PERMISSION_UPDATE: Permission = 'ReviewRegistration.Update';

const getApplicationUrl = (
   contactId: number,
   applicationId: number
): string => {
   return `/contact/${contactId}/applications/${applicationId}`;
};

const IconHeader = (): JSX.Element => {
   return <FaIcon icon="ellipsis-h" />;
};

const DateCell = (cell: CellInfo): JSX.Element => {
   return (
      <div>
         {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
      </div>
   );
};

const TitledCell = (cell: CellInfo): JSX.Element => {
   if (!cell.value) return <></>;
   return <span title={cell.value}>{cell.value}</span>;
};

const DEFAULT_SORTING: SortingRule[] = [{ id: 'createdDate', desc: true }];

const ReviewApplications = (): JSX.Element => {
   const pendingApplicationsBuffer =
      useSelector(selectPendingReviewApplications) || [];
   const [pendingReviewApplications, setPendingReviewApplications] = useState(
      pendingApplicationsBuffer
   );
   const isLoading = useSelector(
      selectIsLoading([
         ApplicationTypes.GET_PENDING_REVIEW_APPLICATIONS_REQUEST,
         ApplicationTypes.UPDATE_APPLICATION_STATUS_REQUEST,
      ])
   );
   const saveSuccess = useSelector(
      selectIsSuccess([ApplicationTypes.UPDATE_APPLICATION_STATUS_REQUEST])
   );
   const saveError = useSelector(
      selectErrorMessage(ApplicationTypes.UPDATE_APPLICATION_STATUS_REQUEST)
   );
   const dispatch = useDispatch();
   const hasReviewReadPermission = usePermissions(REGISTRAR_PERMISSION_READ);
   const hasReviewUpdatePermission = usePermissions(
      REGISTRAR_PERMISSION_UPDATE
   );
   const [saveTriggered, setSaveTriggered] = useState(false);
   const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
   const [rejectApplication, setRejectApplication] = useState(false);

   const loadPendingReviewApplications = (): void => {
      if (!hasReviewReadPermission) return;
      dispatch(ApplicationActions.getPendingReviewApplicationsRequest());
   };
   useEffect(loadPendingReviewApplications, [
      hasReviewReadPermission,
      dispatch,
   ]);

   useEffect((): void => {
      const filteredApplications: ApplicationPendingReviewReadonly[] = [];
      pendingApplicationsBuffer.forEach(application => {
         filteredApplications.push({
            ...application,
            isChecked: false,
         });
      });
      setPendingReviewApplications([...filteredApplications]);
   }, [pendingApplicationsBuffer]);

   useEffect((): void => {
      if (saveSuccess && saveTriggered) {
         toast.success('Application(s) Status Updated');
         setSaveTriggered(false);
      }
   }, [saveSuccess, saveTriggered]);

   useEffect((): void => {
      if (saveError && saveTriggered) {
         toast.error(saveError);
         setSaveTriggered(false);
      }
   }, [saveError, saveTriggered]);

   const getSelectCount = (): number => {
      return pendingReviewApplications.filter(f => f.isChecked).length;
   };

   const toggeleAll = (check: boolean): void => {
      const filteredApplications: ApplicationPendingReviewReadonly[] = [];
      pendingReviewApplications.forEach(application => {
         filteredApplications.push({
            ...application,
            isChecked: check,
         });
      });
      setPendingReviewApplications([...filteredApplications]);
   };

   const toggeleCheck = (check: boolean, applicationId: number): void => {
      const filteredApplications: ApplicationPendingReviewReadonly[] = [];
      pendingReviewApplications.forEach(application => {
         filteredApplications.push({
            ...application,
            isChecked:
               application.applicationId === applicationId
                  ? check
                  : application.isChecked,
         });
      });
      setPendingReviewApplications([...filteredApplications]);
   };

   const CheckBoxCell = (cell: CellInfo): JSX.Element => {
      return (
         <Checkbox
            className="check-box-application"
            id={`checkbox-app-${cell.original.applicationId}`}
            name="simple-checkboxes-app[]"
            checked={cell.value}
            onChange={(e): void => toggeleCheck(e, cell.original.applicationId)}
         />
      );
   };

   const UpdateApplicationStatus = (status: string): void => {
      const applicationIds = pendingReviewApplications
         .filter(f => f.isChecked)
         .map(m => {
            return m.applicationId;
         });

      if (applicationIds && applicationIds.length) {
         setSaveTriggered(true);
         dispatch(
            ApplicationActions.updateApplicationStatusRequest({
               applicationIds: applicationIds,
               status: status,
            })
         );
      }
   };

   const renderControls = (): JSX.Element => {
      return (
         <div className="app-review-controls">
            <Button
               flat
               primary
               swapTheming
               disabled={!getSelectCount() || isLoading}
               onClick={(): void => {
                  setRejectApplication(false);
                  setShowConfirmationDialog(true);
               }}
            >
               Approve
            </Button>
            <Button
               flat
               secondary
               className="red-btn"
               swapTheming
               disabled={!getSelectCount() || isLoading}
               onClick={(): void => {
                  setRejectApplication(true);
                  setShowConfirmationDialog(true);
               }}
            >
               Decline
            </Button>
         </div>
      );
   };

   const getConfirmationStatus = (): JSX.Element => {
      const status = rejectApplication ? 'Decline' : 'Approve';
      return <span className={status.toLowerCase()}>{status}</span>;
   };

   const COLUMN_HEADERS: Column<ApplicationSearchResultModelReadonly>[] = [
      {
         Header: 'Application ID',
         accessor: 'applicationId',
      },
      {
         Header: 'Licence Number',
         accessor: 'registrationNumber',
      },
      {
         Header: "Registrant's Name",
         accessor: 'registrantName',
         Cell: TitledCell,
      },
      {
         Header: 'Licensing Year',
         accessor: 'licenceYearDescription',
         Cell: TitledCell,
      },
      {
         Header: 'Application Type',
         accessor: 'applicationTypeDescription',
         Cell: TitledCell,
      },
      {
         Header: 'Created',
         accessor: 'createdDate',
         Cell: DateCell,
      },
      {
         Header: IconHeader,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         sortable: false,
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo: CellInfo): JSX.Element => {
            const contactId =
               cellInfo && cellInfo.original && cellInfo.original.contactId
                  ? cellInfo.original.contactId
                  : 0;
            const applicationId =
               cellInfo && cellInfo.original && cellInfo.original.applicationId
                  ? cellInfo.original.applicationId
                  : 0;
            const link = getApplicationUrl(contactId, applicationId);
            return (
               <Link to={link} className="disable-link-styles">
                  <FaIcon
                     className="small-icon md-pointer--hover"
                     icon="edit"
                  />
               </Link>
            );
         },
      },
   ];

   // The below checkbox only for Registrar Roles
   if (
      hasReviewUpdatePermission &&
      pendingReviewApplications &&
      pendingReviewApplications.length
   ) {
      COLUMN_HEADERS.unshift({
         Header: (
            <Checkbox
               className="check-box-application"
               id={`checkbox-app-all`}
               name="simple-checkboxes-app-all"
               onChange={(e): void => toggeleAll(e)}
            />
         ),
         Cell: CheckBoxCell,
         accessor: 'isChecked',
         width: 50,
         sortable: false,
      });
   }

   return (
      <Grid>
         <Cell size={12}>
            <ExpansionList className="application-review">
               <ExpansionPanel
                  label="Applications Pending Review"
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ClientSideTable
                     data={pendingReviewApplications}
                     className="-striped -highlight"
                     defaultPageSize={10}
                     columns={COLUMN_HEADERS}
                     defaultSorted={DEFAULT_SORTING}
                     loading={isLoading}
                  />
                  {renderControls()}
               </ExpansionPanel>

               <ConfirmationDialog
                  id="fn-detail-confirmation"
                  className="application-status-confirmation"
                  onConfirm={(): void => {
                     setShowConfirmationDialog(false);
                     UpdateApplicationStatus(
                        rejectApplication ? REJECTED_STATUS : APPROVED_STATUS
                     );
                  }}
                  onCancel={(): void => {
                     setRejectApplication(false);
                     setShowConfirmationDialog(false);
                  }}
                  visible={showConfirmationDialog}
                  portal
               >
                  <>
                     Are you sure you want to {getConfirmationStatus()} the
                     selected Application(s)?
                  </>
               </ConfirmationDialog>
            </ExpansionList>
         </Cell>
      </Grid>
   );
};

export default ReviewApplications;
