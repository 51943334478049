import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import FeeReadonly, { Fee } from 'Models/FeeMaintenance/Dto/Fee';
import { createScopedActions } from '.';
import produce from 'immer';
import { FeeMaintenanceSearchQuery } from 'Models/FeeMaintenance/Dto/FeeMaintenanceSearchQuery';

/* ------------- Interfaces for ReduxSauce ------------- */
interface FeeMaintenanceState {
   feeMaintenances: FeeReadonly[];
   feeMaintenance: FeeReadonly | null;
}

export type FeeMaintenanceStateReadonly = Readonly<FeeMaintenanceState>;

interface TypeNames {
   UPDATE_FEE_MAINTENANCE_REQUEST: string;
   UPDATE_FEE_MAINTENANCE_SUCCESS: string;
   UPDATE_FEE_MAINTENANCE_FAILURE: string;

   GET_FEE_MAINTENANCE_REQUEST: string;
   GET_FEE_MAINTENANCE_SUCCESS: string;
   GET_FEE_MAINTENANCE_FAILURE: string;

   ADD_FEE_MAINTENANCE_REQUEST: string;
   ADD_FEE_MAINTENANCE_SUCCESS: string;
   ADD_FEE_MAINTENANCE_FAILURE: string;
}

type FeeMaintenanceTypeNames = Readonly<TypeNames>;

export interface FeeMaintenanceCreators {
   updateFeeMaintenanceRequest: (boardMeetingDate: FeeReadonly) => AnyAction;
   updateFeeMaintenanceSuccess: (data: FeeReadonly) => AnyAction;
   updateFeeMaintenanceFailure: (error: ApiError) => AnyAction;

   getFeeMaintenanceRequest: (query: FeeMaintenanceSearchQuery) => AnyAction;
   getFeeMaintenanceSuccess: (data: readonly Fee[] | undefined) => AnyAction;
   getFeeMaintenanceFailure: (error: ApiError) => AnyAction;

   addFeeMaintenanceRequest: (feeMaintenance: FeeReadonly) => AnyAction;
   addFeeMaintenanceSuccess: (data: FeeReadonly) => AnyAction;
   addFeeMaintenanceFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: FeeMaintenanceStateReadonly = {
   feeMaintenance: null,
   feeMaintenances: [],
};

/* ------------- Reducers ------------- */
const updateFeeMaintenanceSuccess = (
   state: FeeMaintenanceStateReadonly,
   action: AnyAction
): FeeMaintenanceStateReadonly => {
   return produce(state, (draftState): void => {
      const feeMaintenance = action.data;
      if (draftState.feeMaintenances) {
         const feeMaintenanceFound = draftState.feeMaintenances.find(
            sysPram => {
               return sysPram.feeId === feeMaintenance.feeId;
            }
         );
         if (feeMaintenanceFound) {
            const index = draftState.feeMaintenances.indexOf(
               feeMaintenanceFound
            );
            draftState.feeMaintenances[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addFeeMaintenanceSuccess = (
   state: FeeMaintenanceStateReadonly,
   action: AnyAction
): FeeMaintenanceStateReadonly => {
   return { ...state, feeMaintenance: action.data };
};

const getFeeMaintenanceSuccess = (
   state: FeeMaintenanceStateReadonly,
   action: AnyAction
): FeeMaintenanceStateReadonly => {
   return { ...state, feeMaintenances: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   FeeMaintenanceTypeNames,
   FeeMaintenanceCreators
>('feeMaintenance', {
   updateFeeMaintenanceRequest: ['feeMaintenance'],
   updateFeeMaintenanceSuccess: ['data'],
   updateFeeMaintenanceFailure: ['error'],

   getFeeMaintenanceRequest: ['query'],
   getFeeMaintenanceSuccess: ['data'],
   getFeeMaintenanceFailure: ['error'],

   addFeeMaintenanceRequest: ['feeMaintenance'],
   addFeeMaintenanceSuccess: ['data'],
   addFeeMaintenanceFailure: ['error'],
});

export const FeeMaintenanceTypes = Types;
export const FeeMaintenanceActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_FEE_MAINTENANCE_SUCCESS]: updateFeeMaintenanceSuccess,
   [Types.GET_FEE_MAINTENANCE_SUCCESS]: getFeeMaintenanceSuccess,
   [Types.ADD_FEE_MAINTENANCE_SUCCESS]: addFeeMaintenanceSuccess,
});

/* ------------- Selectors ------------- */
export const selectFeeMaintenance = (
   state: StateReadonly
): FeeReadonly | null => state.feeMaintenance.feeMaintenance;

export const selectFeeMaintenances = (
   state: StateReadonly
): readonly FeeReadonly[] => state.feeMaintenance.feeMaintenances;
