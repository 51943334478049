import { takeLatest, all } from 'redux-saga/effects';
import 'regenerator-runtime/runtime';

import {
   getContactById,
   getContactByRegistrationNumber,
   getContactScreenData,
   saveContactData,
   searchContact,
   getContactNotes,
   saveContactNote,
   searchPayments,
   getRegistrationsByContact,
   saveRegistration,
   getContactPaymentsHistory,
   getTrainingProviders,
   validateContactRegistrationNumbers,
   exportNotesByContactId,
   saveAdminUser,
   getAdminUser,
   searchAdminUser,
} from './ContactSaga';

import {
   ContactService,
   LicenceService,
   SupervisionService,
   SystemParametersService,
   PaymentMethodService,
   RegistrationTypeService,
   FeeMaintenanceService,
   ResultService,
   ApplicationService,
   IdentityService,
   ComplaintService,
   MetadataService,
   ExaminationService,
   CourseService,
   FeesPaymentService,
   LetterService,
   BoardMeetingDateService,
   ContactPhotoService,
   PaymentService,
   GlobalNotificationService,
   ReceiptService,
   FinancialExtractService,
   LicenceTypeService,
   FeeTypeService,
   DashboardService,
   FitAndProperService,
   EnumService,
} from '../Services';

import { ContactTypes } from '../Redux/ContactRedux';
import { ReceiptTypes } from '../Redux/ReceiptRedux';

import {
   getLicenceById,
   getLicenceHistoryById,
   createLicence,
   updateLicence,
   exportLicenceCards,
   annualRenewal,
   searchLicences,
} from './LicenceSaga';
import { LicenceTypes } from 'State/Redux/LicenceRedux';

import {
   getSupervisorHistory,
   searchSupervisor,
   changeSupervisor,
   changeSupervisionEndDate,
   getSuperviseeHistory,
   acceptSupervision,
   declineSupervision,
} from './SupervisionSaga';
import { SupervisionTypes } from 'State/Redux/SupervisionRedux';

import {
   getApplicationPendingRenewal,
   getApplicationForOverview,
   getApplicationSupervisor,
   getApplicationById,
   createApplication,
   updateApplication,
   getApplicationTypes,
   searchApplications,
   cancelApplication,
   getPendingReviewApplications,
   updateApplicationStatus,
   updateApplicationReceivedDate,
   getPendingRegistrationApplications,
   getPendingRegistrationApplicationCount,
   getRegistrationFilesByApplication,
   getRegistrationFileDownload,
   putAcceptFile,
   updateFeeRequest,
   deleteRegistrationFile,
   putRequestAnotherFiles,
   overwriteApplicationStatus,
} from './ApplicationSaga';
import { ApplicationTypes } from 'State/Redux/ApplicationRedux';

import {
   getComplaintById,
   searchComplaints,
   createComplaint,
   updateComplaint,
} from './ComplaintSaga';
import { ComplaintTypes } from 'State/Redux/ComplaintRedux';

import { postPasswordReset } from './IdentitySaga';
import { IdentityTypes } from '../Redux/IdentityRedux';
import { getApplicationHistory } from './ApplicationSaga';

import {
   getResultByContactId,
   getResultStatusByContactId,
   updateResult,
   searchResults,
   updateResultBySearchResult,
   saveValidatedResults,
} from './ResultSaga';
import { ResultTypes } from '../Redux/ResultRedux';

import { getMetadata, getMetadataByType } from './MetadataSaga';
import { MetadataTypes } from '../Redux/MetadataRedux';

import {
   getExaminationEligibilityByContactId,
   updateContactExaminationEligibility,
   getExaminationEligibilityOtherNotesByContactId,
   updateContactExaminationEligibilityOtherNotes,
   getUserExaminationEligibilityReportByContactId,
   sendExaminationEligibilityReportAspeq,
   sendExaminationEligibilityReportUser,
} from './ExaminationSaga';
import { ExaminationTypes } from '../Redux/ExaminationRedux';

import {
   getAllCourses,
   getCourseCriteria,
   getCourseByCourseNumber,
   getCourseNumbers,
   saveCourse,
   editCourse,
} from './CourseSaga';
import { CourseTypes } from '../Redux/CourseRedux';
import { ContactPhotoTypes } from 'State/Redux/ContactPhotoRedux';
import {
   getContactPhotoReviews,
   saveContactPhotoReview,
   exportContactPhotos,
} from './ContactPhotoSaga';
import { FeesPaymentTypes } from 'State/Redux/FeesPaymentRedux';
import {
   getSearchResult,
   saveFeeRequest,
   getFeeRequestLine,
   saveFeeRequestLine,
   getFeeRequest,
} from './FeesPaymentSaga';
import {
   getPaymentDetail,
   getPaymentDetailFeeRequestLine,
   updatePaymentDate,
   cancelPayment,
   getPaymentResponse,
   getPaymentProcessingStatus,
   savePaymentData,
   getPaymentRedirectUrl,
   saveNonOnlinePayment,
   getPaymentTransactionAmount,
   getCardFee,
   submitPaymentCheckout,
   getAmIAllowed,
} from './PaymentSaga';
import { PaymentTypes } from 'State/Redux/PaymentRedux';
import { GlobalNotificationTypes } from 'State/Redux/GlobalNotificationRedux';
import { BatchTypes } from 'State/Redux/BatchRedux';
import {
   getGlobalNotification,
   updateGlobalNotification,
} from './GlobalNotificationSaga';

import { NotificationTypes } from 'State/Redux/NotificationRedux';
import NotificationService from 'State/Services/NotificationService';
import {
   getWebMessages,
   updateWebMessage,
   triggerNotification,
} from './NotificationSaga';

import { ReportTypes } from 'State/Redux/ReportRedux';
import ReportService from 'State/Services/ReportService';
import {
   getSqlQueries,
   getSqlQueryResults,
   editSqlQuery,
   addSqlQuery,
   deleteSqlQuery,
} from './ReportSaga';

import { getContactLetter, getLetters } from './LetterSaga';
import { LetterTypes } from '../Redux/LetterRedux';

import { getReceipt, getReceiptByPaymentId } from './ReceiptSaga';

import {
   addBoardMeetingDate,
   updateBoardMeetingDate,
   searchBoardMeetingDate,
   getBoardMeetingDates,
} from './BoardMeetingDateSaga';
import { BoardMeetingDateTypes } from '../Redux/BoardMeetingDateRedux';
import {
   searchFinancialExtractForBankDepositSlip,
   updateFinancialExtract,
} from './FinancialExtractSaga';
import { FinancialExtractTypes } from 'State/Redux/FinancialExtractRedux';
import {
   getSystemParameters,
   addSystemParameters,
   updateSystemParameters,
} from './SystemParametersSaga';
import { SystemParametersTypes } from 'State/Redux/SystemParametersRedux';
import {
   getPaymentMethod,
   addPaymentMethod,
   updatePaymentMethod,
} from './PaymentMethodSaga';
import { PaymentMethodTypes } from 'State/Redux/PaymentMethodRedux';
import {
   getRegistrationType,
   addRegistrationType,
   updateRegistrationType,
} from './RegistrationTypeSaga';
import { RegistrationTypeTypes } from 'State/Redux/RegistrationTypeRedux';
import {
   getFeeMaintenance,
   addFeeMaintenance,
   updateFeeMaintenance,
} from './FeeMaintenanceSaga';
import { FeeMaintenanceTypes } from 'State/Redux/FeeMaintenanceRedux';
import BatchService from 'State/Services/BatchService';
import {
   getBatchItems,
   downloadBatchItem,
   getFinancialItems,
} from './BatchSaga';
import { LicenceTypeTypes } from 'State/Redux/LicenceTypeRedux';
import {
   addLicenceType,
   getLicenceTypes,
   updateLicenceType,
} from './LicenceTypeSaga';
import { FeeTypeTypes } from 'State/Redux/FeeTypeRedux';
import { addFeeType, getFeeTypes, updateFeeType } from './FeeTypeSaga';

import { getDashboardData } from './DashboardSaga';
import { DashboardTypes } from '../Redux/DashboardRedux';

import { getCities, getPostCodeCities } from './EnumSaga';
import { EnumDtoTypes } from '../Redux/EnumRedux';

import {
   getQuestions,
   getQuestionsAndAnswersById,
   getQuestionsAndAnswersByIdAndQuestionId,
   saveAnswerById,
   saveAnswerListById,
   saveAnswerListByIdAndQuestionId,
   getQuestionHistoryById,
} from './FitAndProperSaga';
import { FitAndProperTypes } from '../Redux/FitAndProperRedux';

/* ------------- API ------------- */

// Set baseURL from environment variables
const baseURL = process.env.REACT_APP_ADMIN_API_URL;
const identityURL = process.env.REACT_APP_IDENTITY_API_URL;
const practitionerApiBaseURL = process.env.REACT_APP_API_URL;

const contactService = ContactService.create(baseURL);
const licenceService = LicenceService.create(baseURL);
const resultService = ResultService.create(baseURL);
const identityService = IdentityService.create(identityURL);
const supervisionService = SupervisionService.create(baseURL);
const systemParametersService = SystemParametersService.create(baseURL);
const paymentMethodService = PaymentMethodService.create(baseURL);
const registrationTypeService = RegistrationTypeService.create(baseURL);
const feeMaintenanceService = FeeMaintenanceService.create(baseURL);
const applicationService = ApplicationService.create(baseURL);
const enumService = EnumService.create(baseURL);
const complaintService = ComplaintService.create(baseURL);
const metadataService = MetadataService.create(baseURL);
const examinationService = ExaminationService.create(baseURL);
const courseService = CourseService.create(baseURL);
const contactPhotoService = ContactPhotoService.create(baseURL);
const feesPaymentService = FeesPaymentService.create(baseURL);
const paymentService = PaymentService.create(baseURL, practitionerApiBaseURL);
const letterService = LetterService.create(baseURL);
const globalNotificationService = GlobalNotificationService.create(baseURL);
const notificationService = NotificationService.create(baseURL);
const reportService = ReportService.create(baseURL);
const receiptService = ReceiptService.create(baseURL);
const boardMeetingDateService = BoardMeetingDateService.create(baseURL);
const financialExtractService = FinancialExtractService.create(baseURL);
const batchService = BatchService.create(baseURL);
const licenceTypeService = LicenceTypeService.create(baseURL);
const feeTypeService = FeeTypeService.create(baseURL);
const dashboardService = DashboardService.create(baseURL);
const fitAndProperService = FitAndProperService.create(baseURL);

/* ------------- Connect Types To Sagas ------------- */
export default function* root(): Generator {
   yield all([
      takeLatest(
         ContactTypes.GET_CONTACT_BY_ID_REQUEST,
         getContactById,
         contactService
      ),
      takeLatest(
         ContactTypes.GET_CONTACT_BY_REGISTRATION_NUMBER_REQUEST,
         getContactByRegistrationNumber,
         contactService
      ),
      takeLatest(
         ContactTypes.GET_CONTACT_SCREEN_DATA_REQUEST,
         getContactScreenData,
         contactService
      ),
      takeLatest(
         ContactTypes.SAVE_CONTACT_DATA_REQUEST,
         saveContactData,
         contactService
      ),
      takeLatest(
         ContactTypes.SAVE_ADMIN_USER_REQUEST,
         saveAdminUser,
         contactService
      ),
      takeLatest(
         ContactTypes.GET_ADMIN_USER_REQUEST,
         getAdminUser,
         contactService
      ),
      takeLatest(
         ContactTypes.SEARCH_ADMIN_USER_REQUEST,
         searchAdminUser,
         contactService
      ),
      takeLatest(
         ContactTypes.SEARCH_CONTACT_REQUEST,
         searchContact,
         contactService
      ),
      takeLatest(
         ContactTypes.SEARCH_PAYMENTS_REQUEST,
         searchPayments,
         contactService
      ),
      takeLatest(
         ContactTypes.VALIDATE_CONTACT_REGISTRATION_NUMBERS_REQUEST,
         validateContactRegistrationNumbers,
         contactService
      ),
      takeLatest(
         LicenceTypes.GET_LICENCE_BY_ID_REQUEST,
         getLicenceById,
         licenceService
      ),
      takeLatest(
         LicenceTypes.GET_LICENCE_HISTORY_BY_ID_REQUEST,
         getLicenceHistoryById,
         licenceService
      ),
      takeLatest(
         LicenceTypes.CREATE_LICENCE_REQUEST,
         createLicence,
         licenceService
      ),
      takeLatest(
         LicenceTypes.UPDATE_LICENCE_REQUEST,
         updateLicence,
         licenceService
      ),
      takeLatest(
         SystemParametersTypes.UPDATE_SYSTEM_PARAMETERS_REQUEST,
         updateSystemParameters,
         systemParametersService
      ),
      takeLatest(
         SystemParametersTypes.ADD_SYSTEM_PARAMETERS_REQUEST,
         addSystemParameters,
         systemParametersService
      ),
      takeLatest(
         SystemParametersTypes.GET_SYSTEM_PARAMETERS_REQUEST,
         getSystemParameters,
         systemParametersService
      ),
      takeLatest(
         PaymentMethodTypes.UPDATE_PAYMENT_METHOD_REQUEST,
         updatePaymentMethod,
         paymentMethodService
      ),
      takeLatest(
         PaymentMethodTypes.ADD_PAYMENT_METHOD_REQUEST,
         addPaymentMethod,
         paymentMethodService
      ),
      takeLatest(
         PaymentMethodTypes.GET_PAYMENT_METHOD_REQUEST,
         getPaymentMethod,
         paymentMethodService
      ),
      takeLatest(
         RegistrationTypeTypes.UPDATE_REGISTRATION_TYPE_REQUEST,
         updateRegistrationType,
         registrationTypeService
      ),
      takeLatest(
         RegistrationTypeTypes.ADD_REGISTRATION_TYPE_REQUEST,
         addRegistrationType,
         registrationTypeService
      ),
      takeLatest(
         RegistrationTypeTypes.GET_REGISTRATION_TYPE_REQUEST,
         getRegistrationType,
         registrationTypeService
      ),
      takeLatest(
         FeeMaintenanceTypes.UPDATE_FEE_MAINTENANCE_REQUEST,
         updateFeeMaintenance,
         feeMaintenanceService
      ),
      takeLatest(
         FeeMaintenanceTypes.ADD_FEE_MAINTENANCE_REQUEST,
         addFeeMaintenance,
         feeMaintenanceService
      ),
      takeLatest(
         FeeMaintenanceTypes.GET_FEE_MAINTENANCE_REQUEST,
         getFeeMaintenance,
         feeMaintenanceService
      ),
      takeLatest(
         SupervisionTypes.GET_SUPERVISOR_HISTORY_REQUEST,
         getSupervisorHistory,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.GET_SUPERVISEE_HISTORY_REQUEST,
         getSuperviseeHistory,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.SEARCH_SUPERVISOR_REQUEST,
         searchSupervisor,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.CHANGE_SUPERVISOR_REQUEST,
         changeSupervisor,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.CHANGE_SUPERVISION_END_DATE_REQUEST,
         changeSupervisionEndDate,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.ACCEPT_SUPERVISION_REQUEST,
         acceptSupervision,
         supervisionService
      ),
      takeLatest(
         SupervisionTypes.DECLINE_SUPERVISION_REQUEST,
         declineSupervision,
         supervisionService
      ),
      takeLatest(
         IdentityTypes.POST_PASSWORD_RESET_REQUEST,
         postPasswordReset,
         identityService
      ),
      takeLatest(
         ContactTypes.GET_CONTACT_NOTES_REQUEST,
         getContactNotes,
         contactService
      ),
      takeLatest(
         ContactTypes.GET_CONTACT_PAYMENTS_HISTORY_REQUEST,
         getContactPaymentsHistory,
         contactService
      ),
      takeLatest(
         ContactTypes.SAVE_CONTACT_NOTE_REQUEST,
         saveContactNote,
         contactService
      ),
      takeLatest(
         ResultTypes.UPDATE_RESULT_REQUEST,
         updateResult,
         resultService
      ),
      takeLatest(
         ResultTypes.UPDATE_RESULT_BY_SEARCH_RESULT_REQUEST,
         updateResultBySearchResult,
         resultService
      ),
      takeLatest(
         ResultTypes.GET_RESULT_BY_CONTACT_ID_REQUEST,
         getResultByContactId,
         resultService
      ),
      takeLatest(
         ResultTypes.GET_RESULT_STATUS_BY_CONTACT_ID_REQUEST,
         getResultStatusByContactId,
         resultService
      ),
      takeLatest(
         ResultTypes.SEARCH_RESULT_REQUEST,
         searchResults,
         resultService
      ),
      takeLatest(
         ResultTypes.SAVE_VALIDATED_RESULTS_REQUEST,
         saveValidatedResults,
         resultService
      ),
      takeLatest(
         ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST,
         getApplicationPendingRenewal,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_REGISTRATION_FILES_BY_APPLICATION_REQUEST,
         getRegistrationFilesByApplication,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_APPLICATION_HISTORY_REQUEST,
         getApplicationHistory,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.OVERWRITE_APPLICATION_STATUS_REQUEST,
         overwriteApplicationStatus,
         applicationService
      ),
      takeLatest(
         ContactTypes.GET_REGISTRATIONS_BY_CONTACT_REQUEST,
         getRegistrationsByContact,
         contactService
      ),
      takeLatest(
         ContactTypes.SAVE_REGISTRATION_REQUEST,
         saveRegistration,
         contactService
      ),
      takeLatest(
         ComplaintTypes.GET_COMPLAINT_BY_ID_REQUEST,
         getComplaintById,
         complaintService
      ),
      takeLatest(
         ComplaintTypes.CREATE_COMPLAINT_REQUEST,
         createComplaint,
         complaintService
      ),
      takeLatest(
         ComplaintTypes.UPDATE_COMPLAINT_REQUEST,
         updateComplaint,
         complaintService
      ),
      takeLatest(
         ComplaintTypes.SEARCH_COMPLAINT_REQUEST,
         searchComplaints,
         complaintService
      ),
      takeLatest(
         MetadataTypes.GET_METADATA_REQUEST,
         getMetadata,
         metadataService
      ),
      takeLatest(
         ApplicationTypes.GET_APPLICATION_FOR_OVERVIEW_REQUEST,
         getApplicationForOverview,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_APPLICATION_SUPERVISOR_REQUEST,
         getApplicationSupervisor,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_APPLICATION_BY_ID_REQUEST,
         getApplicationById,
         applicationService
      ),
      takeLatest(
         ContactTypes.GET_TRAINING_PROVIDERS_REQUEST,
         getTrainingProviders,
         contactService
      ),
      takeLatest(
         ExaminationTypes.GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_REQUEST,
         getExaminationEligibilityByContactId,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_REQUEST,
         updateContactExaminationEligibility,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_REQUEST,
         getExaminationEligibilityOtherNotesByContactId,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTES_REQUEST,
         updateContactExaminationEligibilityOtherNotes,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.GET_USER_EXAMINATION_ELIGIBILITY_REPORT_BY_CONTACT_ID_REQUEST,
         getUserExaminationEligibilityReportByContactId,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.SEND_EXAMINATION_ELIGIBILITY_REPORT_ASPEQ_REQUEST,
         sendExaminationEligibilityReportAspeq,
         examinationService
      ),
      takeLatest(
         ExaminationTypes.SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_REQUEST,
         sendExaminationEligibilityReportUser,
         examinationService
      ),
      takeLatest(
         CourseTypes.GET_ALL_COURSES_REQUEST,
         getAllCourses,
         courseService
      ),
      takeLatest(
         CourseTypes.GET_COURSE_CRITERIA_REQUEST,
         getCourseCriteria,
         courseService
      ),
      takeLatest(
         CourseTypes.GET_COURSE_BY_COURSE_NUMBER_REQUEST,
         getCourseByCourseNumber,
         courseService
      ),
      takeLatest(
         CourseTypes.GET_COURSE_NUMBERS_REQUEST,
         getCourseNumbers,
         courseService
      ),
      takeLatest(CourseTypes.SAVE_COURSE_REQUEST, saveCourse, courseService),
      takeLatest(
         ContactPhotoTypes.GET_CONTACT_PHOTO_REVIEW_REQUEST,
         getContactPhotoReviews,
         contactPhotoService
      ),
      takeLatest(
         ContactPhotoTypes.SAVE_CONTACT_PHOTO_REVIEW_REQUEST,
         saveContactPhotoReview,
         contactPhotoService
      ),
      takeLatest(
         FeesPaymentTypes.GET_SEARCH_RESULT_REQUEST,
         getSearchResult,
         feesPaymentService
      ),
      takeLatest(
         FeesPaymentTypes.SAVE_FEE_REQUEST_REQUEST,
         saveFeeRequest,
         feesPaymentService
      ),
      takeLatest(
         LetterTypes.GET_CONTACT_LETTER_REQUEST,
         getContactLetter,
         letterService
      ),
      takeLatest(LetterTypes.GET_LETTERS_REQUEST, getLetters, letterService),
      takeLatest(
         ApplicationTypes.CREATE_APPLICATION_REQUEST,
         createApplication,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.UPDATE_APPLICATION_REQUEST,
         updateApplication,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.UPDATE_APPLICATION_STATUS_REQUEST,
         updateApplicationStatus,
         applicationService
      ),
      takeLatest(
         GlobalNotificationTypes.GET_GLOBAL_NOTIFICATION_REQUEST,
         getGlobalNotification,
         globalNotificationService
      ),
      takeLatest(
         GlobalNotificationTypes.UPDATE_GLOBAL_NOTIFICATION_REQUEST,
         updateGlobalNotification,
         globalNotificationService
      ),
      takeLatest(
         NotificationTypes.GET_WEB_MESSAGES_REQUEST,
         getWebMessages,
         notificationService
      ),
      takeLatest(
         NotificationTypes.UPDATE_WEB_MESSAGE_REQUEST,
         updateWebMessage,
         notificationService
      ),
      takeLatest(
         NotificationTypes.TRIGGER_NOTIFICATION_REQUEST,
         triggerNotification,
         notificationService
      ),
      takeLatest(
         ReportTypes.GET_SQL_QUERIES_REQUEST,
         getSqlQueries,
         reportService
      ),
      takeLatest(
         ReportTypes.GET_SQL_QUERY_RESULTS_REQUEST,
         getSqlQueryResults,
         reportService
      ),
      takeLatest(
         ReportTypes.EDIT_SQL_QUERY_REQUEST,
         editSqlQuery,
         reportService
      ),
      takeLatest(ReportTypes.ADD_SQL_QUERY_REQUEST, addSqlQuery, reportService),
      takeLatest(
         ReportTypes.DELETE_SQL_QUERY_REQUEST,
         deleteSqlQuery,
         reportService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_DETAIL_REQUEST,
         getPaymentDetail,
         paymentService
      ),
      takeLatest(
         FeesPaymentTypes.GET_FEE_REQUEST_REQUEST,
         getFeeRequest,
         feesPaymentService
      ),
      takeLatest(
         FeesPaymentTypes.GET_FEE_REQUEST_LINE_REQUEST,
         getFeeRequestLine,
         feesPaymentService
      ),
      takeLatest(
         FeesPaymentTypes.SAVE_FEE_REQUEST_LINE_REQUEST,
         saveFeeRequestLine,
         feesPaymentService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_DETAIL_FEE_REQUEST_LINE_REQUEST,
         getPaymentDetailFeeRequestLine,
         paymentService
      ),
      takeLatest(
         ApplicationTypes.GET_APPLICATION_TYPES_REQUEST,
         getApplicationTypes,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.SEARCH_APPLICATIONS_REQUEST,
         searchApplications,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.CANCEL_APPLICATION_REQUEST,
         cancelApplication,
         applicationService
      ),
      takeLatest(
         PaymentTypes.UPDATE_PAYMENT_DATE_REQUEST,
         updatePaymentDate,
         paymentService
      ),
      takeLatest(ReceiptTypes.GET_RECEIPT_REQUEST, getReceipt, receiptService),
      takeLatest(
         ReceiptTypes.GET_RECEIPT_BY_PAYMENT_ID_REQUEST,
         getReceiptByPaymentId,
         receiptService
      ),
      takeLatest(
         PaymentTypes.CANCEL_PAYMENT_REQUEST,
         cancelPayment,
         paymentService
      ),
      takeLatest(
         ContactTypes.EXPORT_NOTES_BY_CONTACT_ID_REQUEST,
         exportNotesByContactId,
         contactService
      ),
      takeLatest(
         BoardMeetingDateTypes.ADD_BOARD_MEETING_DATE_REQUEST,
         addBoardMeetingDate,
         boardMeetingDateService
      ),
      takeLatest(
         BoardMeetingDateTypes.UPDATE_BOARD_MEETING_DATE_REQUEST,
         updateBoardMeetingDate,
         boardMeetingDateService
      ),
      takeLatest(
         BoardMeetingDateTypes.SEARCH_BOARD_MEETING_DATE_REQUEST,
         searchBoardMeetingDate,
         boardMeetingDateService
      ),
      takeLatest(
         BoardMeetingDateTypes.GET_BOARD_MEETING_DATES_REQUEST,
         getBoardMeetingDates,
         boardMeetingDateService
      ),
      takeLatest(
         ApplicationTypes.GET_PENDING_REVIEW_APPLICATIONS_REQUEST,
         getPendingReviewApplications,
         applicationService
      ),
      takeLatest(
         LicenceTypes.EXPORT_LICENCE_CARDS_REQUEST,
         exportLicenceCards,
         licenceService
      ),
      takeLatest(
         LicenceTypes.ANNUAL_RENEWAL_REQUEST,
         annualRenewal,
         licenceService
      ),
      takeLatest(
         LicenceTypes.SEARCH_LICENCES_REQUEST,
         searchLicences,
         licenceService
      ),
      takeLatest(
         ContactPhotoTypes.EXPORT_CONTACT_PHOTOS_REQUEST,
         exportContactPhotos,
         contactPhotoService
      ),
      takeLatest(
         FinancialExtractTypes.GET_SEARCH_RESULT_REQUEST,
         searchFinancialExtractForBankDepositSlip,
         financialExtractService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_RESPONSE_REQUEST,
         getPaymentResponse,
         paymentService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_PROCESSING_STATUS_REQUEST,
         getPaymentProcessingStatus,
         paymentService
      ),
      takeLatest(
         PaymentTypes.SAVE_PAYMENT_DATA_REQUEST,
         savePaymentData,
         paymentService
      ),
      takeLatest(
         PaymentTypes.GET_PAYMENT_REDIRECT_URL_REQUEST,
         getPaymentRedirectUrl,
         paymentService
      ),
      takeLatest(
         PaymentTypes.SAVE_NON_ONLINE_PAYMENT_REQUEST,
         saveNonOnlinePayment,
         paymentService
      ),
      takeLatest(
         BatchTypes.GET_BATCH_ITEMS_REQUEST,
         getBatchItems,
         batchService
      ),
      takeLatest(
         FinancialExtractTypes.UPDATE_FINANCIAL_EXTRACT_REQUEST,
         updateFinancialExtract,
         financialExtractService
      ),
      takeLatest(
         BatchTypes.DOWNLOAD_BATCH_ITEM_REQUEST,
         downloadBatchItem,
         batchService
      ),
      takeLatest(
         LicenceTypeTypes.GET_LICENCE_TYPES_REQUEST,
         getLicenceTypes,
         licenceTypeService
      ),
      takeLatest(
         LicenceTypeTypes.UPDATE_LICENCE_TYPE_REQUEST,
         updateLicenceType,
         licenceTypeService
      ),
      takeLatest(
         LicenceTypeTypes.ADD_LICENCE_TYPE_REQUEST,
         addLicenceType,
         licenceTypeService
      ),
      takeLatest(
         BatchTypes.GET_FINANCIAL_ITEMS_REQUEST,
         getFinancialItems,
         batchService
      ),
      takeLatest(CourseTypes.EDIT_COURSE_REQUEST, editCourse, courseService),
      takeLatest(
         PaymentTypes.GET_PAYMENT_TRANSACTION_AMOUNT_REQUEST,
         getPaymentTransactionAmount,
         paymentService
      ),
      takeLatest(PaymentTypes.GET_CARD_FEE_REQUEST, getCardFee, paymentService),
      takeLatest(
         PaymentTypes.SUBMIT_PAYMENT_CHECKOUT_REQUEST,
         submitPaymentCheckout,
         paymentService
      ),
      takeLatest(
         MetadataTypes.GET_METADATA_BY_TYPE_REQUEST,
         getMetadataByType,
         metadataService
      ),
      takeLatest(
         FeeTypeTypes.GET_FEE_TYPES_REQUEST,
         getFeeTypes,
         feeTypeService
      ),
      takeLatest(
         FeeTypeTypes.UPDATE_FEE_TYPE_REQUEST,
         updateFeeType,
         feeTypeService
      ),
      takeLatest(FeeTypeTypes.ADD_FEE_TYPE_REQUEST, addFeeType, feeTypeService),
      takeLatest(
         DashboardTypes.GET_DASHBOARD_DATA_REQUEST,
         getDashboardData,
         dashboardService
      ),
      takeLatest(
         ApplicationTypes.UPDATE_APPLICATION_RECEIVED_DATE_REQUEST,
         updateApplicationReceivedDate,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_PENDING_REGISTRATION_APPLICATIONS_REQUEST,
         getPendingRegistrationApplications,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_PENDING_REGISTRATION_APPLICATION_COUNT_REQUEST,
         getPendingRegistrationApplicationCount,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.GET_REGISTRATION_FILE_DOWNLOAD_REQUEST,
         getRegistrationFileDownload,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.UPDATE_FEE_REQUEST,
         updateFeeRequest,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.PUT_ACCEPT_FILE_REQUEST,
         putAcceptFile,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.DELETE_REGISTRATION_FILE_REQUEST,
         deleteRegistrationFile,
         applicationService
      ),
      takeLatest(
         ApplicationTypes.PUT_REQUEST_ANOTHER_FILES_REQUEST,
         putRequestAnotherFiles,
         applicationService
      ),
      takeLatest(
         PaymentTypes.GET_AM_I_ALLOWED_REQUEST,
         getAmIAllowed,
         paymentService
      ),
      takeLatest(
         FitAndProperTypes.GET_QUESTIONS_REQUEST,
         getQuestions,
         fitAndProperService
      ),
      takeLatest(
         FitAndProperTypes.GET_QUESTIONS_AND_ANSWERS_BY_ID_REQUEST,
         getQuestionsAndAnswersById,
         fitAndProperService
      ),
      takeLatest(
         FitAndProperTypes.GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_REQUEST,
         getQuestionsAndAnswersByIdAndQuestionId,
         fitAndProperService
      ),
      takeLatest(
         FitAndProperTypes.GET_ANSWER_HISTORY_BY_ID_REQUEST,
         getQuestionHistoryById,
         fitAndProperService
      ),
      takeLatest(
         FitAndProperTypes.SAVE_ANSWER_BY_ID_REQUEST,
         saveAnswerById,
         fitAndProperService
      ),
      takeLatest(
         FitAndProperTypes.SAVE_ANSWER_LIST_BY_ID_REQUEST,
         saveAnswerListById,
         fitAndProperService
      ),
      takeLatest(
         FitAndProperTypes.SAVE_ANSWER_LIST_BY_ID_AND_QUESTION_ID_REQUEST,
         saveAnswerListByIdAndQuestionId,
         fitAndProperService
      ),
      takeLatest(
         EnumDtoTypes.GET_POST_CODE_CITIES_REQUEST,
         getPostCodeCities,
         enumService
      ),
      takeLatest(EnumDtoTypes.GET_CITIES_REQUEST, getCities, enumService),
   ]);
}
