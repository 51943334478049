import { put, call } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { ReceiptServiceType } from 'State/Services/ReceiptService';
import { ReceiptActions } from 'State/Redux/ReceiptRedux';
import ReceiptRequestReadOnly from 'Models/Payment/Data/ReceiptRequest';

interface ReceiptParams {
   type: string;
   token: string;
   request: ReceiptRequestReadOnly;
   paymentId: number;
}

export function* getReceipt(
   receiptService: Readonly<ReceiptServiceType>,
   action: Readonly<ReceiptParams>
): Generator {
   const { request } = action;

   const receiptResponse = yield call(receiptService.getReceipt, request);
   const response = receiptResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(ReceiptActions.getReceiptSuccess(response.data));
   } else {
      yield put(
         ReceiptActions.getReceiptFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getReceiptByPaymentId(
   receiptService: Readonly<ReceiptServiceType>,
   action: Readonly<ReceiptParams>
): Generator {
   const { paymentId } = action;

   const receiptResponse = yield call(
      receiptService.getReceiptByPaymentId,
      paymentId
   );
   const response = receiptResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(ReceiptActions.getReceiptByPaymentIdSuccess(response.data));
   } else {
      yield put(
         ReceiptActions.getReceiptByPaymentIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
