import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import WebMessageDtoReadonly from 'Models/Notifications/WebMessageDto';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface NotificationState {
   webMessage: WebMessageDtoReadonly[];
   triggerNotification: string;
}

export type NotificationStateReadonly = Readonly<NotificationState>;

interface TypeNames {
   GET_WEB_MESSAGES_REQUEST: string;
   GET_WEB_MESSAGES_SUCCESS: string;
   GET_WEB_MESSAGES_FAILURE: string;

   UPDATE_WEB_MESSAGE_REQUEST: string;
   UPDATE_WEB_MESSAGE_SUCCESS: string;
   UPDATE_WEB_MESSAGE_FAILURE: string;

   TRIGGER_NOTIFICATION_REQUEST: string;
   TRIGGER_NOTIFICATION_SUCCESS: string;
   TRIGGER_NOTIFICATION_FAILURE: string;
}

type NotificationTypeNames = Readonly<TypeNames>;

export interface NotificationCreators {
   getWebMessagesRequest: () => AnyAction;
   getWebMessagesSuccess: (
      data: WebMessageDtoReadonly | undefined
   ) => AnyAction;
   getWebMessagesFailure: (error: ApiError) => AnyAction;

   updateWebMessageRequest: (message: WebMessageDtoReadonly) => AnyAction;
   updateWebMessageSuccess: (
      data: WebMessageDtoReadonly | undefined
   ) => AnyAction;
   updateWebMessageFailure: (error: ApiError) => AnyAction;

   triggerNotificationRequest: (data: string) => AnyAction;
   triggerNotificationSuccess: (data: string | undefined) => AnyAction;
   triggerNotificationFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: NotificationStateReadonly = {
   webMessage: [],
   triggerNotification: '',
};

/* ------------- Reducers ------------- */
const getWebMessagesSuccess = (
   state: NotificationState,
   action: AnyAction
): NotificationStateReadonly => {
   return { ...state, webMessage: action.data };
};

const updateWebMessageSuccess = (
   state: NotificationState,
   action: AnyAction
): NotificationStateReadonly => {
   return { ...state };
};
const triggerNotificationSuccess = (
   state: NotificationState,
   action: AnyAction
): NotificationStateReadonly => {
   return { ...state, triggerNotification: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   NotificationTypeNames,
   NotificationCreators
>('notification', {
   getWebMessagesRequest: [],
   getWebMessagesSuccess: ['data'],
   getWebMessagesFailure: ['error'],

   updateWebMessageRequest: ['message'],
   updateWebMessageSuccess: ['data'],
   updateWebMessageFailure: ['error'],

   triggerNotificationRequest: ['data'],
   triggerNotificationSuccess: ['data'],
   triggerNotificationFailure: ['error'],
});

export const NotificationTypes = Types;
export const NotificationActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_WEB_MESSAGES_SUCCESS]: getWebMessagesSuccess,
   [Types.UPDATE_WEB_MESSAGE_SUCCESS]: updateWebMessageSuccess,
   [Types.TRIGGER_NOTIFICATION_SUCCESS]: triggerNotificationSuccess,
});

/* ------------- Selectors ------------- */
export const selectWebMessage = (
   state: StateReadonly
): WebMessageDtoReadonly[] => state.notification.webMessage;
