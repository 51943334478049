import React, { useState, useEffect } from 'react';
import Loading from 'Components/Common/Loading';
import { ExpansionList } from 'react-md/lib/ExpansionPanels';
import './style.scss';
import Button from 'react-md/lib/Buttons';
import FeeRequestLineDisplay, {
   DefaultFeeRequestLineDisplay,
} from 'Models/FeesPayment/FeeRequestLineDisplay';
import './style.scss';
import AmountInput from 'Components/Common/AmountInput/AmountInput';
import { Column, CellInfo } from 'react-table';
import DialogContainer from 'react-md/lib/Dialogs';
import { useSelector, useDispatch } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import { FeeType } from 'Models/Metadata/FeeType';
import TextField from 'react-md/lib/TextFields';
import ValidationModel from 'Components/Common/ModelValidator/ValidationModel';
import { validateModel } from 'Components/Common/ModelValidator/Helper';
import { toast } from 'react-toastify';
import { Checkbox } from 'react-md/lib/SelectionControls';
import FeeRequestLineInput from 'Models/FeesPayment/FeeRequestLineInput';
import {
   FeesPaymentTypes,
   FeesPaymentActions,
   selectFeeRequestLine,
   selectUpdatedFeeRequestLineId,
} from 'State/Redux/FeesPaymentRedux';
import {
   selectErrorMessage,
   selectIsSuccess,
   selectIsLoading,
} from 'State/Redux/AsyncRedux';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface FeeRequestLineProps {
   onSave?(e: FeeRequestLineDisplay): void;
   onCancel?(): void;
   model: FeeRequestLineDisplay;
   editMode?: boolean;
   saveOnClose?: boolean;
}

export type FeeRequestValidationFields = Pick<
   FeeRequestLineDisplay,
   'feeTypeCode' | 'feeRequestLineAmount'
>;

const FEE_REQUEST_LINE_VALIDATION_MODEL: ValidationModel<FeeRequestValidationFields> = {
   errorMessage: 'Fee Request Line is not valid',
   fields: {
      feeTypeCode: {
         validation: 'text',
         error: false,
         required: true,
         errorMessage: 'Please select the Fee type',
      },
      feeRequestLineAmount: {
         validation: 'amount',
         error: false,
         required: false,
         errorMessage: 'Please enter the amount',
      },
   },
};

// Fee Request line component supports both Save to DB / just return the record for bulk saving.
// To save the FL on 'Save' click - set SaveOnClose = true
const FeeRequestLine = ({
   onSave,
   onCancel,
   editMode,
   model,
   saveOnClose,
}: Readonly<FeeRequestLineProps>): JSX.Element => {
   const feeRequestLine = useSelector(selectFeeRequestLine);
   const updatedFeeRequestLineId = useSelector(selectUpdatedFeeRequestLineId);
   const saveError = useSelector(
      selectErrorMessage(FeesPaymentTypes.SAVE_FEE_REQUEST_LINE_REQUEST)
   );
   const saveSuccess = useSelector(
      selectIsSuccess([FeesPaymentTypes.SAVE_FEE_REQUEST_LINE_REQUEST])
   );
   const isSaveLoading = useSelector(
      selectIsLoading([FeesPaymentTypes.SAVE_FEE_REQUEST_LINE_REQUEST])
   );
   const isGetLoading = useSelector(
      selectIsLoading([FeesPaymentTypes.GET_FEE_REQUEST_LINE_REQUEST])
   );
   const getSuccess = useSelector(
      selectIsSuccess([FeesPaymentTypes.GET_FEE_REQUEST_LINE_REQUEST])
   );
   const dispatch = useDispatch();

   const [feeRequestLineModel, setFeeRequestLineModel] = useState(
      editMode ? model : DefaultFeeRequestLineDisplay
   );
   const [feeTypeTableVisible, setFeeTypeTableVisible] = useState(false);
   const feeTypes = useSelector((state: StateReadonly): FeeType[] => {
      return state.metadata.feeTypes.sort((a, b) =>
         a.description < b.description ? -1 : 1
      );
   });
   const [invalidDescription, setInvalidDescription] = useState(false);
   const [saveClicked, setSaveClicked] = useState(false);
   const [isValidated, setIsValidate] = useState(false);
   const [isFeeRequestLineLoaded, setIsFeeRequestLineLoaded] = useState(false);
   const [validationModel] = useState(FEE_REQUEST_LINE_VALIDATION_MODEL);
   let validationResult = isValidated
      ? validateModel<FeeRequestValidationFields>(
           feeRequestLineModel,
           validationModel
        )
      : undefined;

   const [saveSubmitted, setSaveSubmitted] = useState(false);

   const clickableCell = (cell: CellInfo): JSX.Element => {
      return (
         <div
            className="buttoncell"
            onClick={(): void => handleOnTypeSelect(cell)}
         >
            {cell.value}
         </div>
      );
   };

   const feeTypeTableHeaders: Column<FeeType>[] = [
      {
         Header: 'Fee Type',
         accessor: 'description',
         Cell: clickableCell,
      },
      {
         Header: 'GL Code',
         accessor: 'glcode',
         Cell: clickableCell,
      },
   ];

   useEffect((): void => {
      if (
         !isFeeRequestLineLoaded &&
         saveOnClose &&
         model.feeRequestId &&
         model.feeRequestLineId &&
         editMode
      ) {
         dispatch(
            FeesPaymentActions.getFeeRequestLineRequest(model.feeRequestLineId)
         );
      }

      if (
         !isFeeRequestLineLoaded &&
         saveOnClose &&
         model.feeRequestId &&
         !model.feeRequestLineId &&
         !editMode
      ) {
         setFeeRequestLineModel({
            ...DefaultFeeRequestLineDisplay,
            feeRequestId: model.feeRequestId,
         });
      }
   }, [dispatch, isFeeRequestLineLoaded, saveOnClose, model, editMode]);

   useEffect((): void => {
      if (getSuccess && editMode && feeRequestLine) {
         setIsFeeRequestLineLoaded(true);
         setFeeRequestLineModel(feeRequestLine);
      }
   }, [getSuccess, feeRequestLine, editMode]);

   useEffect((): void => {
      if (saveClicked && saveSuccess && saveOnClose) {
         toast.success('Fee Request Line Created');
         setSaveClicked(false);
      }
   }, [saveSuccess, saveClicked, saveOnClose]);

   useEffect((): void => {
      if (saveClicked && saveError && saveOnClose) {
         toast.error('Error Saving Fee Request Line');
         setSaveClicked(false);
      }
   }, [saveError, saveClicked, saveOnClose]);

   useEffect((): void => {
      if (
         saveSubmitted &&
         !isSaveLoading &&
         saveSuccess &&
         updatedFeeRequestLineId > 0 &&
         onSave
      ) {
         onSave({
            ...DefaultFeeRequestLineDisplay,
            feeRequestLineId: updatedFeeRequestLineId,
         });
      }
   }, [
      isSaveLoading,
      onSave,
      saveSubmitted,
      saveSuccess,
      updatedFeeRequestLineId,
   ]);

   const handleOnTypeSelect = (cell: CellInfo): void => {
      const item = feeTypes[cell.index].feeTypeCode;
      const itemDesc = feeTypes[cell.index].description;
      setFeeRequestLineModel({
         ...feeRequestLineModel,
         feeTypeDescription: itemDesc,
         feeTypeCode: item,
      });
      setFeeTypeTableVisible(false);
   };

   const handleSave = (): void => {
      // validate the input
      setIsValidate(true);
      validationResult = validateModel<FeeRequestValidationFields>(
         feeRequestLineModel,
         validationModel
      );

      if (validationResult.error) {
         toast.error('Please enter the Fee details.');
         return;
      }

      // Validate the Description:
      const feeTypeItem = feeTypes.find(
         f => f.feeTypeCode === feeRequestLineModel.feeTypeCode
      );
      if (
         feeTypeItem &&
         feeTypeItem.descriptionRequired &&
         !feeRequestLineModel.creditReason
      ) {
         toast.error(
            `Description is mandatory for the Fee Type ${feeRequestLineModel.feeTypeDescription}`
         );
         setInvalidDescription(true);
         return;
      }

      if (saveOnClose) {
         const inputModel: FeeRequestLineInput = {
            ...feeRequestLineModel,
         };
         dispatch(FeesPaymentActions.saveFeeRequestLineRequest(inputModel));
         setSaveSubmitted(true);
      } else {
         if (onSave) {
            onSave(feeRequestLineModel);
         }
      }
   };

   const renderFeeTypeTable = (): JSX.Element => {
      return (
         <DialogContainer
            title=""
            id="dialog-container-feerequest-types"
            visible={feeTypeTableVisible}
            focusOnMount={false}
            containFocus={false}
            onHide={FN_EMPTY_VOID}
            lastChild
            disableScrollLocking
            renderNode={document.body}
            className="fee-request-types-dialog"
            closeOnEsc
            actions={
               <Button
                  flat
                  secondary
                  swapTheming
                  onClick={(): void => setFeeTypeTableVisible(false)}
               >
                  Cancel
               </Button>
            }
         >
            <div className="fee-request-types">
               <ClientSideTable
                  className="fee-request-types-table -striped -highlight"
                  columns={feeTypeTableHeaders}
                  showPageSizeOptions={true}
                  showPageJump={false}
                  showPagination={false}
                  minRows={0}
                  data={feeTypes}
                  noDataText=""
                  pages={0}
                  pageSize={feeTypes.length}
               />
            </div>
         </DialogContainer>
      );
   };

   return (
      <Loading
         isLoading={isGetLoading || isSaveLoading}
         isError={false}
         tryReload={FN_EMPTY_VOID}
         overlayOnChildren
      >
         <ExpansionList className="fee-request-line-container">
            <>
               <div className="md-grid md-cell--12">
                  <TextField
                     floating
                     id="fee-request-type"
                     label="Fee Type"
                     lineDirection="center"
                     className={`md-cell md-cell--bottom md-cell--12 ${!editMode &&
                        'fee-type-text'}`}
                     inputClassName={getInputValidationClassName(
                        validationResult &&
                           validationResult.fields.feeTypeCode.error
                     )}
                     onClick={(): void => {
                        !editMode && setFeeTypeTableVisible(true);
                     }}
                     value={feeRequestLineModel.feeTypeDescription}
                     required
                     errorText={
                        validationResult
                           ? validationResult.fields.feeTypeCode.errorMessage
                           : 'Invalid Fee Type'
                     }
                     error={
                        validationResult &&
                        validationResult.fields.feeTypeCode.error
                     }
                     helpText={false}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12">
                  <TextField
                     floating
                     id="fee-request-description"
                     label="Description"
                     lineDirection="center"
                     className="md-cell md-cell--bottom md-cell--12"
                     inputClassName={getInputValidationClassName(
                        invalidDescription
                     )}
                     value={feeRequestLineModel.creditReason}
                     onChange={(e): void => {
                        setFeeRequestLineModel({
                           ...feeRequestLineModel,
                           creditReason: e.toString(),
                        });
                        setInvalidDescription(false);
                     }}
                     errorText="Description Required"
                     error={invalidDescription}
                     helpText={false}
                  />
               </div>
               <div className="md-grid md-cell--12">
                  <AmountInput
                     floating
                     id="fee-request-amount"
                     label="Amount"
                     required
                     errorText={
                        validationResult
                           ? validationResult.fields.feeRequestLineAmount
                                .errorMessage
                           : 'Invalid Amount'
                     }
                     inputClassName={getInputValidationClassName(
                        validationResult &&
                           validationResult.fields.feeRequestLineAmount.error
                     )}
                     error={
                        validationResult &&
                        validationResult.fields.feeRequestLineAmount.error
                     }
                     onChange={(e): void => {
                        setFeeRequestLineModel({
                           ...feeRequestLineModel,
                           feeRequestLineAmount: e
                              ? Number(e.toString().replace(/[^0-9.-]+/g, ''))
                              : 0,
                        });
                     }}
                     value={feeRequestLineModel.feeRequestLineAmount}
                     allowNegative
                  />
               </div>
               <div className="md-grid md-cell--12">
                  <Checkbox
                     id="checkbox-optional"
                     name="cb-optional"
                     label="Optional"
                     value="material-design"
                     className="md-cell md-cell--4"
                     checked={feeRequestLineModel.optional}
                     onChange={(e): void => {
                        setFeeRequestLineModel({
                           ...feeRequestLineModel,
                           optional: e,
                        });
                     }}
                  />
                  <div className="md-cell--8 fee-request-edit-controls">
                     <Button
                        flat
                        secondary
                        swapTheming
                        onClick={onCancel}
                        onKeyUp={(keyPress): void => {
                           if (isEnterKeyPress(keyPress) && onCancel)
                              onCancel();
                        }}
                     >
                        Cancel
                     </Button>
                     <Button
                        flat
                        primary
                        swapTheming
                        onClick={handleSave}
                        onKeyUp={(keyPress): void => {
                           if (isEnterKeyPress(keyPress)) handleSave();
                        }}
                     >
                        Save
                     </Button>
                  </div>
               </div>
               {renderFeeTypeTable()}
            </>
         </ExpansionList>
      </Loading>
   );
};

export default React.memo(FeeRequestLine);
