export const ApplicationTypeGroup1: string[] = [
   'LADR', //Annual LC Trainee Renewal for Drainlaying
   'LAGR', //Annual LC Trainee Renewal for Gasfitting
   'LAPR', //Annual LC Trainee Renewal for Plumbing
   'RCD', //Annual Licence Renewal for Certifying Drainlayers
   'CGR', //Annual Licence Renewal for Certifying Gasfitters
   'CPR', //Annual Licence Renewal for Certifying Plumbers
   'JDR', //Annual Licence Renewal for Journeyman Drainlayer
   'JGR', //Annual Licence Renewal for Journeyman Gasfitter
   'JPR', //Annual Licence Renewal for Journeyman Plumber
   'RDR', //Annual Licence Renewal for Tradesman Drainlayers
   'RGR', //Annual Licence Renewal for Tradesman Gasfitters
   'RPR', //Annual Licence Renewal for Tradesman Plumbers
   'LCDR', //Annual Renewal EXMP. Drainlaying under supervision
   'LCGR', //Annual Renewal EXMP. Gasfitting under supervision
   'LCPR', //Annual Renewal EXMP. Plumbing under supervision
];

export const ApplicationTypeGroup2: string[] = [
   'DC', //Registration for a Certifying Drainlayer
   'GC', //Registration for a Certifying Gasfitter
   'PC', //Registration for a Certifying Plumber
   'DJ', //Registration for a Journeyman Drainlayer
   'GJ', //Registration for a Journeyman Gasfitter
   'PJ', //Registration for a Journeyman Plumber
   'DR', //Registration for a Tradesman Drainlayer
   'GR', //Registration for a Tradesman Gasfitter
   'PR', //Registration for a Tradesman Plumber
];

export const ApplicationTypeGroup3: string[] = [
   'TMCG', //Registration under TTMRA Certifying Gasfitter
   'TMCP', //Registration under TTMRA Certifying Plumber
   'TMCD', //Registration under TTMRA Certifying Drainlayer
   'TMD', //Registration under TTMRA Tradesman Drainlayer
   'TMG', //Registration under TTMRA Tradesman Gasfitter
   'TMP', //Registration under TTMRA Tradesman Plumber
];

export const ApplicationTypeGroup4: string[] = [
   'ELR', //Annual Employer Licence Renewal
   'AE53', //Annual Exemption fitting of water heaters
   'AE57', //Annual Exemption specific gasfitting equipment
   'AE56', //Annual Exemption specific plumbing equipment
   'PLD', //Provisional Licence for a Drainlayer B
   'PLG', //Provisional Licence for a Gasfitter B
   'PLP', //Provisional Licence for a Plumber B
];

export const ApplicationTypeGroup5: string[] = [
   'RJPR',
   'RJGR',
   'RJDR',
   'RRPR',
   'RRGR',
   'RRDR',
   'RCPR',
   'RCGR',
   'RRCD',
];

export const ApplicationTypeGroups: readonly string[][] = [
   ApplicationTypeGroup1,
   ApplicationTypeGroup2,
   ApplicationTypeGroup3,
   ApplicationTypeGroup4,
   ApplicationTypeGroup5,
];
