import PeopleData from 'Models/Licence/Data/PeopleData';
import BulkRenewal from 'Models/Application/Data/BulkRenewal';
import LicenceInformation from 'Models/Licence/Data/LicenceInformation';

interface LicenceGroup {
   currentLicences: readonly LicenceInformation[];
   nextLicences: readonly LicenceInformation[];
   otherLicences: readonly BulkRenewal[];
   currentData: PeopleData;
   nextData: PeopleData;
}

type LicenceGroupReadonly = Readonly<LicenceGroup>;

export default LicenceGroupReadonly;

export const hasLicencesForPeriod = (data: PeopleData): boolean => {
   return data && data.trades && !!data.trades.length;
};

export const hasLicences = (data: LicenceGroup): boolean => {
   return (
      data &&
      (hasLicencesForPeriod(data.currentData) ||
         hasLicencesForPeriod(data.nextData))
   );
};
