import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import produce from 'immer';
import ApiError from 'Models/Other/ApiError';
import ContactReadonly from 'Models/Contact/Data/Contact';
import StateReadonly from './StateModel';
import { Supervision } from 'Models/Supervision/Data/Supervision';
import ChangeSupervisionEndDateDtoReadOnly from 'Models/Supervision/Dto/ChangeSupervisionEndDateDto';
import ChangeSupervisorDtoReadonly from 'Models/Supervision/Dto/ChangeSupervisionDto';
import { createScopedActions } from '.';
import SuperviseeReadonly, {
   Supervisee,
} from 'Models/Supervision/Data/Supervisee';
import SupervisionStatus from 'Models/Supervision/Data/SupervisionStatus';

/* ------------- Interfaces for ReduxSauce ------------- */
interface SupervisionState {
   supervisorHistory: readonly Supervision[];
   superviseeHistory: readonly Supervisee[];
   supervisorSearchResult: ContactReadonly | null;
}

export type SupervisionStateReadonly = Readonly<SupervisionState>;

interface TypeNames {
   GET_SUPERVISOR_HISTORY_REQUEST: string;
   GET_SUPERVISOR_HISTORY_SUCCESS: string;
   GET_SUPERVISOR_HISTORY_FAILURE: string;
   GET_SUPERVISOR_HISTORY_RESET: string;

   GET_SUPERVISEE_HISTORY_REQUEST: string;
   GET_SUPERVISEE_HISTORY_SUCCESS: string;
   GET_SUPERVISEE_HISTORY_FAILURE: string;
   GET_SUPERVISEE_HISTORY_RESET: string;

   SEARCH_SUPERVISOR_REQUEST: string;
   SEARCH_SUPERVISOR_SUCCESS: string;
   SEARCH_SUPERVISOR_FAILURE: string;
   SEARCH_SUPERVISOR_RESET: string;

   CHANGE_SUPERVISOR_REQUEST: string;
   CHANGE_SUPERVISOR_SUCCESS: string;
   CHANGE_SUPERVISOR_FAILURE: string;

   CHANGE_SUPERVISION_END_DATE_REQUEST: string;
   CHANGE_SUPERVISION_END_DATE_SUCCESS: string;
   CHANGE_SUPERVISION_END_DATE_FAILURE: string;

   DECLINE_SUPERVISION_REQUEST: string;
   DECLINE_SUPERVISION_SUCCESS: string;
   DECLINE_SUPERVISION_FAILURE: string;

   ACCEPT_SUPERVISION_REQUEST: string;
   ACCEPT_SUPERVISION_SUCCESS: string;
   ACCEPT_SUPERVISION_FAILURE: string;
}

type SupervisionTypeNames = Readonly<TypeNames>;

export interface SupervisionCreators {
   getSupervisorHistoryRequest: (id: number) => AnyAction;
   getSupervisorHistorySuccess: (
      data: readonly Supervision[] | undefined
   ) => AnyAction;
   getSupervisorHistoryFailure: (error: ApiError) => AnyAction;
   getSupervisorHistoryReset: () => AnyAction;

   getSuperviseeHistoryRequest: (id: number) => AnyAction;
   getSuperviseeHistorySuccess: (
      data: readonly Supervisee[] | undefined
   ) => AnyAction;
   getSuperviseeHistoryFailure: (error: ApiError) => AnyAction;
   getSuperviseeHistoryReset: () => AnyAction;

   searchSupervisorRequest: (registrationNumber: string) => AnyAction;
   searchSupervisorSuccess: (data: ContactReadonly | undefined) => AnyAction;
   searchSupervisorFailure: (error: ApiError) => AnyAction;
   searchSupervisorReset: () => AnyAction;

   changeSupervisorRequest: (
      changeSupervisorDto: ChangeSupervisorDtoReadonly
   ) => AnyAction;
   changeSupervisorSuccess: (data: Supervision | undefined) => AnyAction;
   changeSupervisorFailure: (error: ApiError) => AnyAction;

   changeSupervisionEndDateRequest: (
      changeEndDateDto: ChangeSupervisionEndDateDtoReadOnly
   ) => AnyAction;
   changeSupervisionEndDateSuccess: (
      data: Supervision | undefined
   ) => AnyAction;
   changeSupervisionEndDateFailure: (error: ApiError) => AnyAction;

   acceptSupervisionRequest: (supervisionId: number) => AnyAction;
   acceptSupervisionSuccess: (
      data: SuperviseeReadonly | undefined
   ) => AnyAction;
   acceptSupervisionFailure: (error: ApiError) => AnyAction;

   declineSupervisionRequest: (supervisionId: number) => AnyAction;
   declineSupervisionSuccess: (
      data: SuperviseeReadonly | undefined
   ) => AnyAction;
   declineSupervisionFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: SupervisionStateReadonly = {
   supervisorHistory: [],
   superviseeHistory: [],
   supervisorSearchResult: null,
};

/* ------------- Reducers ------------- */
const getSupervisorHistorySuccess = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return { ...state, supervisorHistory: action.data };
};

const getSuperviseeHistorySuccess = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return { ...state, superviseeHistory: action.data };
};

const getSupervisorHistoryReset = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return { ...state, supervisorHistory: [] };
};

const searchSupervisorSuccess = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return { ...state, supervisorSearchResult: action.data };
};

const changeSupervisorSuccess = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return produce(state, (draftState): void => {
      const updatedSupervision = action.data;
      const supervisionFound = draftState.supervisorHistory.find(
         (su): boolean => su.supervisionId === updatedSupervision.supervisionId
      );
      if (supervisionFound) {
         const index = draftState.supervisorHistory.indexOf(supervisionFound);
         draftState.supervisorHistory[index] = {
            ...action.data,
            licence: supervisionFound.licence,
         };
      }
   });
};

const changeSupervisionEndDateSuccess = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return produce(state, (draftState): void => {
      const updatedSupervision = action.data;
      const supervisionFound = draftState.supervisorHistory.find(
         (su): boolean => su.supervisionId === updatedSupervision.supervisionId
      );
      if (supervisionFound) {
         supervisionFound.endDate = updatedSupervision.endDate;
         const index = draftState.supervisorHistory.indexOf(supervisionFound);
         draftState.supervisorHistory[index] = {
            ...action.data,
            licence: supervisionFound.licence,
         };
      }
      const superviseeFound = draftState.superviseeHistory.find(
         (su): boolean => su.supervisionId === updatedSupervision.supervisionId
      );
      if (superviseeFound) {
         superviseeFound.supervisionEnds = updatedSupervision.endDate;
      }
   });
};

const updateSuperviseeSuccess = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return produce(state, (draftState): void => {
      const updatedSupervisee = action.data;
      const superviseeFound = draftState.superviseeHistory.find(
         (su): boolean => su.supervisionId === updatedSupervisee.supervisionId
      );
      if (superviseeFound) {
         const index = draftState.superviseeHistory.indexOf(superviseeFound);
         draftState.superviseeHistory[index] = {
            ...action.data,
         };
      }
   });
};

const searchSupervisorReset = (
   state: SupervisionStateReadonly,
   action: AnyAction
): SupervisionStateReadonly => {
   return { ...state, supervisorSearchResult: null };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   SupervisionTypeNames,
   SupervisionCreators
>('supervision', {
   getSupervisorHistoryRequest: ['id'],
   getSupervisorHistorySuccess: ['data'],
   getSupervisorHistoryFailure: ['error'],
   getSupervisorHistoryReset: [],

   getSuperviseeHistoryRequest: ['id'],
   getSuperviseeHistorySuccess: ['data'],
   getSuperviseeHistoryFailure: ['error'],
   getSuperviseeHistoryReset: [],

   searchSupervisorRequest: ['registrationNumber'],
   searchSupervisorSuccess: ['data'],
   searchSupervisorFailure: ['error'],
   searchSupervisorReset: [],

   changeSupervisorRequest: ['changeSupervisorDto'],
   changeSupervisorSuccess: ['data'],
   changeSupervisorFailure: ['error'],

   changeSupervisionEndDateRequest: ['changeEndDateDto'],
   changeSupervisionEndDateSuccess: ['data'],
   changeSupervisionEndDateFailure: ['error'],

   acceptSupervisionRequest: ['supervisionId'],
   acceptSupervisionSuccess: ['data'],
   acceptSupervisionFailure: ['error'],

   declineSupervisionRequest: ['supervisionId'],
   declineSupervisionSuccess: ['data'],
   declineSupervisionFailure: ['error'],
});

export const SupervisionTypes = Types;
export const SupervisionActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_SUPERVISOR_HISTORY_SUCCESS]: getSupervisorHistorySuccess,
   [Types.GET_SUPERVISOR_HISTORY_RESET]: getSupervisorHistoryReset,
   [Types.GET_SUPERVISEE_HISTORY_SUCCESS]: getSuperviseeHistorySuccess,
   [Types.SEARCH_SUPERVISOR_SUCCESS]: searchSupervisorSuccess,
   [Types.SEARCH_SUPERVISOR_REQUEST]: searchSupervisorReset,
   [Types.SEARCH_SUPERVISOR_RESET]: searchSupervisorReset,
   [Types.CHANGE_SUPERVISOR_SUCCESS]: changeSupervisorSuccess,
   [Types.CHANGE_SUPERVISION_END_DATE_SUCCESS]: changeSupervisionEndDateSuccess,
   [Types.ACCEPT_SUPERVISION_SUCCESS]: updateSuperviseeSuccess,
   [Types.DECLINE_SUPERVISION_SUCCESS]: updateSuperviseeSuccess,
});

/* ------------- Selectors ------------- */
export const selectSupervisionHistory = (
   state: StateReadonly
): readonly Supervision[] => state.supervision.supervisorHistory;

export const selectSuperviseeHistory = (
   state: StateReadonly
): readonly Supervisee[] =>
   state.supervision.superviseeHistory.filter(s => {
      return (
         s.supervisionStatus === SupervisionStatus.Accepted ||
         s.supervisionStatus === SupervisionStatus.Pending
      );
   });

export const selectSupervisionSearchResult = (
   state: StateReadonly
): ContactReadonly | null => {
   return state.supervision.supervisorSearchResult;
};
