import React from 'react';

interface LicenceTitleProps {
   level: string;
   discipline: string;
}

export const LicenceTitle = ({
   level,
   discipline,
}: Readonly<LicenceTitleProps>): JSX.Element => {
   return (
      <>
         <p className="level">{level}</p>
         <p className="trade">{discipline}</p>
      </>
   );
};
