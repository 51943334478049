import React, { useState, useCallback } from 'react';
import {
   EDIT_SUPERVISION,
   EditSupervisorModalMode,
   END_IMMEDIATELY_CONFIRMATION,
} from './SupervisionModalMode';
import EditSupervisionModalBody from './EditSupervisionModalBody';
import EndImmediatelyModalBody from './EndImmediatelyModalBody';
import SupervisionReadonly from 'Models/Supervision/Data/Supervision';

interface EditSupervisionProps {
   selectedSupervision: SupervisionReadonly | null;
   updateShowModal: (showModal: boolean) => void;
}

const EditSupervision = ({
   selectedSupervision,
   updateShowModal,
}: EditSupervisionProps): JSX.Element => {
   const [modalMode, setModalMode] = useState<EditSupervisorModalMode>(
      EDIT_SUPERVISION
   );

   const updateModalMode = useCallback(
      (modalMode: EditSupervisorModalMode): void => {
         setModalMode(modalMode);
      },
      [setModalMode]
   );

   switch (modalMode) {
      case END_IMMEDIATELY_CONFIRMATION: {
         return (
            <EndImmediatelyModalBody
               updateShowModal={updateShowModal}
               selectedSupervision={selectedSupervision}
               updateModalMode={updateModalMode}
            />
         );
      }
      default:
         return (
            <EditSupervisionModalBody
               updateShowModal={updateShowModal}
               selectedSupervision={selectedSupervision}
               updateModalMode={updateModalMode}
            />
         );
   }
};

export default EditSupervision;
