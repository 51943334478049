import React from 'react';
import moment from 'moment';

const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY LT';

interface ReviewCardProps {
   photoUrl: string;
   updatedDate: string;
}

const PhotoUpdatedImage = ({
   photoUrl,
   updatedDate,
}: Readonly<ReviewCardProps>): JSX.Element => {
   return (
      <>
         <img src={photoUrl} alt="Profile" className="profile-image-history" />
         <h6 className="small-font">
            Last Update {moment.utc(updatedDate).format(DISPLAY_DATE_FORMAT)}
         </h6>
      </>
   );
};

export default PhotoUpdatedImage;
