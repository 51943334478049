import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import PaymentMethodReadonly, {
   PaymentMethod,
} from 'Models/PaymentMethod/Dto/PaymentMethod';
import { createScopedActions } from '.';
import produce from 'immer';

/* ------------- Interfaces for ReduxSauce ------------- */
interface PaymentMethodState {
   paymentMethods: PaymentMethodReadonly[];
   paymentMethod: PaymentMethodReadonly | null;
}

export type PaymentMethodStateReadonly = Readonly<PaymentMethodState>;

interface TypeNames {
   UPDATE_PAYMENT_METHOD_REQUEST: string;
   UPDATE_PAYMENT_METHOD_SUCCESS: string;
   UPDATE_PAYMENT_METHOD_FAILURE: string;

   GET_PAYMENT_METHOD_REQUEST: string;
   GET_PAYMENT_METHOD_SUCCESS: string;
   GET_PAYMENT_METHOD_FAILURE: string;

   ADD_PAYMENT_METHOD_REQUEST: string;
   ADD_PAYMENT_METHOD_SUCCESS: string;
   ADD_PAYMENT_METHOD_FAILURE: string;
}

type PaymentMethodTypeNames = Readonly<TypeNames>;

export interface PaymentMethodCreators {
   updatePaymentMethodRequest: (
      boardMeetingDate: PaymentMethodReadonly
   ) => AnyAction;
   updatePaymentMethodSuccess: (data: PaymentMethodReadonly) => AnyAction;
   updatePaymentMethodFailure: (error: ApiError) => AnyAction;

   getPaymentMethodRequest: () => AnyAction;
   getPaymentMethodSuccess: (
      data: readonly PaymentMethod[] | undefined
   ) => AnyAction;
   getPaymentMethodFailure: (error: ApiError) => AnyAction;

   addPaymentMethodRequest: (paymentMethod: PaymentMethodReadonly) => AnyAction;
   addPaymentMethodSuccess: (data: PaymentMethodReadonly) => AnyAction;
   addPaymentMethodFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: PaymentMethodStateReadonly = {
   paymentMethod: null,
   paymentMethods: [],
};

/* ------------- Reducers ------------- */
const updatePaymentMethodSuccess = (
   state: PaymentMethodStateReadonly,
   action: AnyAction
): PaymentMethodStateReadonly => {
   return produce(state, (draftState): void => {
      const paymentMethod = action.data;
      if (draftState.paymentMethods) {
         const paymentMethodFound = draftState.paymentMethods.find(sysPram => {
            return (
               sysPram.paymentMethodCode === paymentMethod.paymentMethodCode
            );
         });
         if (paymentMethodFound) {
            const index = draftState.paymentMethods.indexOf(paymentMethodFound);
            draftState.paymentMethods[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addPaymentMethodSuccess = (
   state: PaymentMethodStateReadonly,
   action: AnyAction
): PaymentMethodStateReadonly => {
   return { ...state, paymentMethod: action.data };
};

const getPaymentMethodSuccess = (
   state: PaymentMethodStateReadonly,
   action: AnyAction
): PaymentMethodStateReadonly => {
   return { ...state, paymentMethods: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   PaymentMethodTypeNames,
   PaymentMethodCreators
>('paymentMethod', {
   updatePaymentMethodRequest: ['paymentMethod'],
   updatePaymentMethodSuccess: ['data'],
   updatePaymentMethodFailure: ['error'],

   getPaymentMethodRequest: [],
   getPaymentMethodSuccess: ['data'],
   getPaymentMethodFailure: ['error'],

   addPaymentMethodRequest: ['paymentMethod'],
   addPaymentMethodSuccess: ['data'],
   addPaymentMethodFailure: ['error'],
});

export const PaymentMethodTypes = Types;
export const PaymentMethodActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_PAYMENT_METHOD_SUCCESS]: updatePaymentMethodSuccess,
   [Types.GET_PAYMENT_METHOD_SUCCESS]: getPaymentMethodSuccess,
   [Types.ADD_PAYMENT_METHOD_SUCCESS]: addPaymentMethodSuccess,
});

/* ------------- Selectors ------------- */
export const selectPaymentMethod = (
   state: StateReadonly
): PaymentMethodReadonly | null => state.paymentMethod.paymentMethod;

export const selectPaymentMethods = (
   state: StateReadonly
): readonly PaymentMethodReadonly[] => state.paymentMethod.paymentMethods;
