import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import { BatchItemReadonly } from 'Models/BackOffice/BatchItem';
import { BatchSearchQuery } from 'Models/BackOffice/BatchSearchQuery';

/* ------------- Interfaces for ReduxSauce ------------- */
interface BatchState {
   batchItems: BatchItemReadonly[];
   financialItems: BatchItemReadonly[];
   batchItem: Blob;
}

export type BatchStateReadonly = Readonly<BatchState>;

interface TypeNames {
   GET_BATCH_ITEMS_REQUEST: string;
   GET_BATCH_ITEMS_SUCCESS: string;
   GET_BATCH_ITEMS_FAILURE: string;

   GET_FINANCIAL_ITEMS_REQUEST: string;
   GET_FINANCIAL_ITEMS_SUCCESS: string;
   GET_FINANCIAL_ITEMS_FAILURE: string;

   DOWNLOAD_BATCH_ITEM_REQUEST: string;
   DOWNLOAD_BATCH_ITEM_SUCCESS: string;
   DOWNLOAD_BATCH_ITEM_FAILURE: string;
}

type BatchTypeNames = Readonly<TypeNames>;

export interface BatchCreators {
   getBatchItemsRequest: (query: BatchSearchQuery) => AnyAction;
   getBatchItemsSuccess: (data: BatchItemReadonly[] | undefined) => AnyAction;
   getBatchItemsFailure: (error: ApiError) => AnyAction;

   getFinancialItemsRequest: (query: BatchSearchQuery) => AnyAction;
   getFinancialItemsSuccess: (
      data: BatchItemReadonly[] | undefined
   ) => AnyAction;
   getFinancialItemsFailure: (error: ApiError) => AnyAction;

   downloadBatchItemRequest: (id: string) => AnyAction;
   downloadBatchItemSuccess: (data: Blob | undefined) => AnyAction;
   downloadBatchItemFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: BatchStateReadonly = {
   batchItems: [],
   batchItem: new Blob(),
   financialItems: [],
};

/* ------------- Reducers ------------- */
const getBatchItemsSuccess = (
   state: BatchState,
   action: AnyAction
): BatchStateReadonly => {
   return { ...state, batchItems: action.data };
};

const getFinancialItemsSuccess = (
   state: BatchState,
   action: AnyAction
): BatchStateReadonly => {
   return { ...state, financialItems: action.data };
};

const downloadBatchItemSuccess = (
   state: BatchState,
   action: AnyAction
): BatchStateReadonly => {
   return { ...state, batchItem: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<BatchTypeNames, BatchCreators>(
   'batch',
   {
      getBatchItemsRequest: ['query'],
      getBatchItemsSuccess: ['data'],
      getBatchItemsFailure: ['error'],

      getFinancialItemsRequest: ['query'],
      getFinancialItemsSuccess: ['data'],
      getFinancialItemsFailure: ['error'],

      downloadBatchItemRequest: ['id'],
      downloadBatchItemSuccess: ['data'],
      downloadBatchItemFailure: ['error'],
   }
);

export const BatchTypes = Types;
export const BatchActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_BATCH_ITEMS_SUCCESS]: getBatchItemsSuccess,
   [Types.GET_FINANCIAL_ITEMS_SUCCESS]: getFinancialItemsSuccess,
   [Types.DOWNLOAD_BATCH_ITEM_SUCCESS]: downloadBatchItemSuccess,
});

/* ------------- Selectors ------------- */
export const selectBatchItems = (state: StateReadonly): BatchItemReadonly[] =>
   state.batch.batchItems;

export const selectFinancialItems = (
   state: StateReadonly
): BatchItemReadonly[] => state.batch.financialItems;

export const selectDownloadedBatchItem = (state: StateReadonly): Blob =>
   state.batch.batchItem;
