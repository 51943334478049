import React, { useState, useCallback } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectIsSuccess,
   selectIsLoading,
   selectIsError,
   selectErrorMessage,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   selectRegistrationType,
   RegistrationTypeActions,
   RegistrationTypeTypes,
} from 'State/Redux/RegistrationTypeRedux';
import {
   DefaultRegistrationTypeModel,
   validateRegistrationType,
} from 'Models/RegistrationType/Dto/RegistrationType';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import SelectField from 'react-md/lib/SelectFields';
import { DISCIPLINE_CODES } from 'Util/Constants/RegistrationTypes';

const AddRegistrationType = (): JSX.Element => {
   const systemParameter = useSelector(selectRegistrationType);
   const isLoading = useSelector(
      selectIsLoading([RegistrationTypeTypes.ADD_REGISTRATION_TYPE_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([RegistrationTypeTypes.ADD_REGISTRATION_TYPE_REQUEST])
   );
   const isError = useSelector(
      selectIsError([RegistrationTypeTypes.ADD_REGISTRATION_TYPE_REQUEST])
   );
   const getErrorMessage = useSelector(
      selectErrorMessage(RegistrationTypeTypes.ADD_REGISTRATION_TYPE_REQUEST)
   );

   const dispatch = useDispatch();

   const [systemParameterModel, setSystemParameterModel] = useState(
      DefaultRegistrationTypeModel
   );

   const [addClicked, setAddClicked] = useState(false);

   const onAdd = useCallback((): void => {
      dispatch(
         RegistrationTypeActions.addRegistrationTypeRequest({
            ...systemParameterModel,
         })
      );
      setAddClicked(true);
   }, [dispatch, systemParameterModel]);

   if (addClicked && isSuccess && systemParameter !== null) {
      toast.success(
         `New system parameter "${systemParameter.registrationTypeCode}" created!`
      );
      setAddClicked(false);
      setSystemParameterModel(DefaultRegistrationTypeModel);
   }

   if (addClicked && isError && systemParameter === null) {
      toast.error(getErrorMessage);
   }
   const isValidForm = Object.values(
      validateRegistrationType(systemParameterModel)
   ).every((v): boolean => !!v);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="type-code"
                     label="Type Code"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Type Code"
                     value={systemParameterModel.registrationTypeCode}
                     maxLength={4}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           registrationTypeCode: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     required
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="description"
                     label="Description"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Description"
                     value={systemParameterModel.description}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           description: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     required
                  />
               </div>

               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="cbl-credits"
                     label="CBL Credits"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="CBL Credits"
                     value={systemParameterModel.cblcredits}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           cblcredits: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                  />
               </div>

               <div className="md-cell md-cell--6">
                  <SelectField
                     floating
                     id="select-discipline-code"
                     label="Discipline Code"
                     placeholder="Discipline Code"
                     className="md-cell md-cell--12"
                     position={SelectField.Positions.BELOW}
                     value={systemParameterModel.disciplineCode}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           disciplineCode: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     errorText="Select Discipline Code"
                     menuItems={DISCIPLINE_CODES}
                  />
               </div>

               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
                     disabled={!isValidForm}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        onAdd();
                     }}
                     onClick={(): void => {
                        onAdd();
                     }}
                  >
                     Add
                  </LoadingButton>
               </div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddRegistrationType;
