import React from 'react';
import { ConditionValueProps } from './ConditionValueProps';
import TextField from 'react-md/lib/TextFields';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ConditionTextFieldProps {
   onChange?: (val: string | number) => void;
}

export const ConditionTextField = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
}: Readonly<ConditionTextFieldProps & ConditionValueProps>): JSX.Element => {
   return (
      <TextField
         id={`app-${1}-${conditionCode}`}
         className="md-cell md-cell--12"
         label={description}
         lineDirection="center"
         inputClassName={getInputValidationClassName(!isChecked)}
         value={textValue}
         error={!isChecked}
         disabled={disabled}
         onChange={onChange}
      />
   );
};
