import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import PersonalDetailsPanel from './Panels/PersonalDetailsPanel';
import CurrentLicencesPanel from './Panels/CurrentLicencesPanel';
import PendingRenewalApplication from 'Components/Common/PendingRenewalApplication';
import NotesPanel from './Panels/Notes/NotesPanel';
import './ContactOverviewTab.scss';
import Button from 'react-md/lib/Buttons';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import DialogContainer from 'react-md/lib/Dialogs';
import { CreateContact } from '../SubMenu/CreateContact';
import {
   selectContact,
   ContactTypes,
   ContactActions,
   selectExportedNote,
} from 'State/Redux/ContactRedux';
import { LicenceActions, selectLicence } from 'State/Redux/LicenceRedux';
import {
   selectApplicationPendingRenewal,
   ApplicationActions,
   ApplicationTypes,
} from 'State/Redux/ApplicationRedux';
import { useSelector, useDispatch } from 'react-redux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import {
   selectIsLoading,
   selectIsSuccess,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import Loading from 'Components/Common/Loading';
import { LicenceTypes } from 'State/Redux/LicenceRedux';
import { hasLicences } from 'Models/Licence/Data/LicenceGroup';
import {
   CONTACT_TYPE_EMPLOYER,
   CONTACT_TYPE_TRAINING_PROVIDER,
   COMPANY_CONTACT_TYPES,
} from 'Util/Constants/Contact';
import { CreateCompanyContact } from '../SubMenu/CreateCompanyContact';
import LetterSelection from 'Components/Common/LetterSelection/LetterSelection';
import LoadingButton from 'Components/Common/LoadingButton';
import FileSaver from 'file-saver';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import CreateTrainingProvider from 'Components/Pages/CPD/TrainingProvider/CreateTrainingProvider';
import { usePermissions } from 'Util/Helpers/Permissions';
import FitAndProperPanel from './Panels/FitAndProper/FitAndProperPanel';
import {
   FitAndProperActions,
   selectFitAndProper,
} from 'State/Redux/FitAndProperRedux';

const ContactOverviewTab = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const licence = useSelector(selectLicence);
   const fitAndProperAnswers = useSelector(selectFitAndProper);
   const pendingApplicationResponse = useSelector(
      selectApplicationPendingRenewal
   );

   const notes = useSelector(selectExportedNote);
   const isDownloading = useSelector(
      selectIsLoading([ContactTypes.EXPORT_NOTES_BY_CONTACT_ID_REQUEST])
   );
   const isDownloaded = useSelector(
      selectIsSuccess([ContactTypes.EXPORT_NOTES_BY_CONTACT_ID_REQUEST])
   );
   const [isExportClicked, setIsExportClicked] = useState(false);

   const dispatch = useDispatch();
   const loadPendingApplications = (): void => {
      dispatch(
         ApplicationActions.getApplicationPendingRenewalRequest(
            contact.contactId.toString()
         )
      );
   };
   useEffect(loadPendingApplications, [contact.contactId, dispatch]);

   const loadLicenceCards = (): void => {
      if (contact.registrationNumber)
         dispatch(
            LicenceActions.getLicenceByIdRequest(
               contact.registrationNumber.toString()
            )
         );
   };
   useEffect(loadLicenceCards, [contact.registrationNumber, dispatch]);

   const loadFitAndProper = (): void => {
      dispatch(
         FitAndProperActions.getQuestionsAndAnswersByIdRequest(contact.userId)
      );
   };
   useEffect(loadFitAndProper, [contact.userId, dispatch]);

   const loadNotes = (): void => {
      dispatch(
         ContactActions.getContactNotesRequest(contact.contactId.toString())
      );
   };
   useEffect(loadNotes, [contact.contactId, dispatch]);

   const isSuccessPendingApplications = useSelector(
      selectIsSuccess([
         ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST,
      ])
   );
   const isErrorPendingApplications = useSelector(
      selectIsError([ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST])
   );
   const isSuccessLicences = useSelector(
      selectIsSuccess([LicenceTypes.GET_LICENCE_BY_ID_REQUEST])
   );
   const isErrorLicences = useSelector(
      selectIsError([LicenceTypes.GET_LICENCE_BY_ID_REQUEST])
   );
   const isErrorNotes = useSelector(
      selectIsError([ContactTypes.GET_CONTACT_NOTES_REQUEST])
   );
   const isLoadingOverview = useSelector(
      selectIsLoading([
         ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST,
         ContactTypes.GET_CONTACT_NOTES_REQUEST,
         LicenceTypes.GET_LICENCE_BY_ID_REQUEST,
      ])
   );

   const [contactEditVisible, setContactEditVisible] = useState<boolean>(false);
   const [letterSelectionVisible, setLetterSelectionVisible] = useState<
      boolean
   >(false);

   const title = COMPANY_CONTACT_TYPES.some(
      ct => ct === contact.contactTypeCode
   )
      ? 'Company Details'
      : 'Personal Details';

   useEffect((): void => {
      if (isDownloaded && isExportClicked) {
         const blob = new Blob([notes], {
            type: 'text/csv',
         });

         FileSaver.saveAs(blob, `Notes_${contact.registrationNumber}.csv`);
         setIsExportClicked(false);
      }
   }, [isDownloaded, notes, contact.registrationNumber, isExportClicked]);

   const personalDetailsLabel = (
      <>
         {title}
         {usePermissions(['Contact.Update']) && (
            <Button
               icon
               className="letter-btn"
               onClick={(e: React.MouseEvent): void => {
                  setLetterSelectionVisible(true);
                  e.stopPropagation();
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  setLetterSelectionVisible(true);
               }}
            >
               <FaIcon icon="envelope-open-text" />
            </Button>
         )}
         {usePermissions(['Letter.Read']) && (
            <Button
               icon
               className="plus-btn"
               onClick={(e: React.MouseEvent): void => {
                  setContactEditVisible(true);
                  e.stopPropagation();
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  setContactEditVisible(true);
               }}
            >
               <FaIcon icon="edit" />
            </Button>
         )}
      </>
   );

   const hideContactEditModal = (): void => setContactEditVisible(false);

   const resolveEditComponent = (): JSX.Element => {
      switch (contact.contactTypeCode) {
         case CONTACT_TYPE_EMPLOYER:
            return (
               <CreateCompanyContact
                  editMode
                  contactId={contact.contactId}
                  onSaveSuccess={(): void => {
                     setContactEditVisible(false);
                  }}
               />
            );
         case CONTACT_TYPE_TRAINING_PROVIDER:
            return (
               <CreateTrainingProvider
                  editMode
                  contactId={contact.contactId}
                  onSaveSuccess={(): void => {
                     setContactEditVisible(false);
                  }}
               />
            );
         default:
            return (
               <CreateContact
                  editMode
                  contactId={contact.contactId}
                  onSaveSuccess={(): void => {
                     setContactEditVisible(false);
                  }}
               />
            );
      }
   };

   const contactEditWindow = (
      <DialogContainer
         id="dialog-container-contact-edit"
         className="contact-edit-dialog"
         visible={contactEditVisible}
         focusOnMount={false}
         containFocus={false}
         portal
         fullPage
         onHide={hideContactEditModal}
      >
         <Button
            className="button-close"
            onClick={hideContactEditModal}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) hideContactEditModal();
            }}
         >
            <FaIcon icon="times" />
         </Button>
         {resolveEditComponent()}
      </DialogContainer>
   );

   const hideLetterSelectionModal = (): void =>
      setLetterSelectionVisible(false);
   const letterSelectionWindow = (
      <DialogContainer
         id="dialog-container-letter-selection"
         className="letter-selection-dialog"
         width={800}
         visible={letterSelectionVisible}
         focusOnMount={false}
         containFocus={false}
         portal
         onHide={hideLetterSelectionModal}
         title="Available Letters"
      >
         <Button
            className="button-close"
            onClick={hideLetterSelectionModal}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) hideLetterSelectionModal();
            }}
         >
            <FaIcon icon="times" />
         </Button>
         <LetterSelection />
      </DialogContainer>
   );

   const showLicenceCards =
      isSuccessLicences && licence && hasLicences(licence);

   const currentLicencesPanel = showLicenceCards ? (
      <ExpansionPanel
         label="Current Licence(s)"
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
      >
         <CurrentLicencesPanel licence={licence} />
      </ExpansionPanel>
   ) : (
      <></>
   );

   const fitAndProper = (
      <ExpansionPanel
         label={'Fit & Proper Questions'}
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
      >
         <FitAndProperPanel
            userId={contact.userId}
            answers={fitAndProperAnswers}
            requestToRefresh={(): void => loadFitAndProper()}
         />
      </ExpansionPanel>
   );

   const showRenewalApplications = usePermissions(['RenewalApplication.Read']);
   const showPendingApplications =
      isSuccessPendingApplications &&
      pendingApplicationResponse &&
      pendingApplicationResponse.length > 0 &&
      showRenewalApplications;

   const pendingApplicationsPanel = showPendingApplications ? (
      <ExpansionPanel
         label="Pending Renewal Application(s)"
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
      >
         <PendingRenewalApplication />
      </ExpansionPanel>
   ) : (
      <></>
   );

   const showNotes = usePermissions(['Note.Read']);

   const notesPanel = showNotes ? (
      <ExpansionPanel
         label="Notes"
         footer={null}
         expanded
         expanderIcon={
            <>
               <LoadingButton
                  flat
                  primary
                  onClick={(): void => {
                     dispatch(
                        ContactActions.exportNotesByContactIdRequest(
                           contact.contactId.toString()
                        )
                     );
                     setIsExportClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     dispatch(
                        ContactActions.exportNotesByContactIdRequest(
                           contact.contactId.toString()
                        )
                     );
                     setIsExportClicked(true);
                  }}
                  isLoading={isDownloading}
               >
                  Export Notes
               </LoadingButton>
            </>
         }
         onExpandToggle={FN_EMPTY_VOID}
      >
         <NotesPanel />
      </ExpansionPanel>
   ) : (
      <></>
   );

   let isErrorOverview =
      isErrorLicences || isErrorPendingApplications || isErrorNotes;

   if (
      (isErrorPendingApplications || isErrorNotes) &&
      !showRenewalApplications &&
      !showNotes
   )
      isErrorOverview = false;

   const reloadOverview = (): void => {
      if (isErrorLicences) loadLicenceCards();
      if (isErrorPendingApplications) loadPendingApplications();
      if (isErrorNotes) loadNotes();
   };

   return (
      <Loading
         isLoading={isLoadingOverview}
         isError={isErrorOverview}
         tryReload={reloadOverview}
      >
         <ExpansionList className="contact-overview-tab md-cell md-cell--12">
            {contactEditWindow}
            {letterSelectionWindow}
            <ExpansionPanel
               label={personalDetailsLabel}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <PersonalDetailsPanel />
            </ExpansionPanel>

            {fitAndProper}

            {currentLicencesPanel}

            {pendingApplicationsPanel}

            {notesPanel}
         </ExpansionList>
      </Loading>
   );
};

export default ContactOverviewTab;
