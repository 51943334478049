import { put, call } from 'redux-saga/effects';
import { FeeMaintenanceActions } from '../Redux/FeeMaintenanceRedux';
import { FeeMaintenanceServiceType } from '../Services/FeeMaintenanceService';
import { ApiResponse } from 'apisauce';
import FeeReadonly, { Fee } from 'Models/FeeMaintenance/Dto/Fee';
import { FeeMaintenanceSearchQuery } from 'Models/FeeMaintenance/Dto/FeeMaintenanceSearchQuery';

interface FeeMaintenanceParams {
   type: string;
   token: string;
   id: number;
   feeMaintenance: FeeReadonly;
   query: FeeMaintenanceSearchQuery;
}

export function* updateFeeMaintenance(
   supervisionService: Readonly<FeeMaintenanceServiceType>,
   action: Readonly<FeeMaintenanceParams>
): Generator {
   const { feeMaintenance } = action;
   const supervisionResponse = yield call(
      supervisionService.updateFeeMaintenance,
      feeMaintenance
   );
   const response = supervisionResponse as ApiResponse<FeeReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(
         FeeMaintenanceActions.updateFeeMaintenanceSuccess(response.data)
      );
   } else {
      yield put(
         FeeMaintenanceActions.updateFeeMaintenanceFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addFeeMaintenance(
   supervisionService: Readonly<FeeMaintenanceServiceType>,
   action: Readonly<FeeMaintenanceParams>
): Generator {
   const { feeMaintenance } = action;
   const supervisionResponse = yield call(
      supervisionService.addFeeMaintenance,
      feeMaintenance
   );
   const response = supervisionResponse as ApiResponse<FeeReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(FeeMaintenanceActions.addFeeMaintenanceSuccess(response.data));
   } else {
      yield put(
         FeeMaintenanceActions.addFeeMaintenanceFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Adding Registration Type',
         })
      );
   }
}

export function* getFeeMaintenance(
   supervisionService: Readonly<FeeMaintenanceServiceType>,
   action: Readonly<FeeMaintenanceParams>
): Generator {
   const { query } = action;
   const supervisionResponse = yield call(
      supervisionService.getFeeMaintenance,
      query
   );
   const response = supervisionResponse as ApiResponse<readonly Fee[]>;

   if (response.status && response.ok) {
      yield put(FeeMaintenanceActions.getFeeMaintenanceSuccess(response.data));
   } else {
      yield put(
         FeeMaintenanceActions.getFeeMaintenanceFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
