import React, { useState } from 'react';
import '../Style.scss';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import UserEditModel from 'Models/SuperUser/UserEditModel';
import { Column, CellInfo } from 'react-table';
import ExpansionPanel from 'react-md/lib/ExpansionPanels';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { useSelector } from 'react-redux';
import { ContactTypes } from 'State/Redux/ContactRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import Button from 'react-md/lib/Buttons';
import DialogContainer from 'react-md/lib/Dialogs';
import { EditUser } from '../../EditUser';
interface SearchResultProps {
   users: UserEditModel[];
   triggerSearch: () => void;
}

const SearchResult = ({
   users,
   triggerSearch,
}: Readonly<SearchResultProps>): JSX.Element => {
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SEARCH_ADMIN_USER_REQUEST])
   );

   const [editDialogVisible, setEditDialogVisible] = useState(false);
   const [editUserId, setEditUserId] = useState();

   const StatusCell = (cell: CellInfo): JSX.Element => {
      return (
         <div className="status-cell">
            {cell.value ? (
               <div className="status-icon">
                  <FaIcon icon="user" className="small-icon red" />
                  <span>Disabled</span>
               </div>
            ) : (
               <div className="status-icon">
                  <FaIcon icon="user" className="small-icon green" />
                  <span>Active</span>
               </div>
            )}
         </div>
      );
   };

   const EditCell = (cell: CellInfo): JSX.Element => {
      return (
         <>
            {/* <Link to={`${USER_EDIT_URL}${cell.value}`}> */}
            <Button
               icon
               className="table-row-button"
               iconEl={<FaIcon icon="edit" className="small-icon" />}
               onClick={(): void => {
                  setEditUserId(cell.value);
                  setEditDialogVisible(true);
               }}
            />
            {/* </Link> */}
         </>
      );
   };

   const COLUMN_HEADERS: Column<UserEditModel>[] = [
      {
         Header: 'User Id',
         accessor: 'userName',
      },
      {
         Header: 'Full Name',
         accessor: 'fullName',
      },
      {
         Header: 'Email',
         accessor: 'emailAddress',
      },
      {
         Header: 'Role',
         accessor: 'roleCode',
      },
      {
         Header: 'Status',
         accessor: 'isDisabled',
         Cell: StatusCell,
      },
      {
         Header: '',
         Cell: EditCell,
         accessor: 'userId',
         sortable: false,
      },
   ];

   const renderEditDialog = (): JSX.Element => {
      return (
         <DialogContainer
            id="dialog-container-user-edit"
            className="user-edit-dialog"
            visible={editDialogVisible}
            focusOnMount={false}
            containFocus={false}
            closeOnEsc
            paddedContent={false}
         >
            <EditUser
               editUserId={editUserId}
               onCancel={(): void => setEditDialogVisible(false)}
               onSave={(): void => {
                  setEditDialogVisible(false);
                  triggerSearch();
               }}
            />
         </DialogContainer>
      );
   };

   return (
      <ExpansionPanel
         label={`Results (${users.length})`}
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
         className="user-search-result"
      >
         <ClientSideTable
            data={users}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            loading={isLoading}
         />
         {renderEditDialog()}
      </ExpansionPanel>
   );
};

export default SearchResult;
