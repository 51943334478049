import { call, put } from 'redux-saga/effects';
import { FinancialExtractServiceType } from 'State/Services/FinancialExtractService';
import { ApiResponse } from 'apisauce';
import { FinancialExtractActions } from 'State/Redux/FinancialExtractRedux';
import { FinancialExtractSearchQuery } from 'Models/BackOffice/FinancialExtractInput';
import { FinancialExtractResultItem } from 'Models/BackOffice/FinancialExtractResultItem';

interface FinancialExtractParams {
   type: string;
   token: string;
   query: FinancialExtractSearchQuery;
   model: FinancialExtractResultItem[];
   financialType: string;
   fileName: string;
}

export function* searchFinancialExtractForBankDepositSlip(
   financialExtractSearchService: Readonly<FinancialExtractServiceType>,
   action: Readonly<FinancialExtractParams>
): Generator {
   const { query } = action;
   const financialExtractSearchResponse = yield call(
      financialExtractSearchService.getSearchResult,
      query
   );
   const response = financialExtractSearchResponse as ApiResponse<
      readonly FinancialExtractResultItem[]
   >;

   if (response.status && response.ok) {
      yield put(
         FinancialExtractActions.getSearchResultSuccess(response.data || [])
      );
   } else {
      yield put(
         FinancialExtractActions.getSearchResultFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateFinancialExtract(
   financialExtractService: Readonly<FinancialExtractServiceType>,
   action: Readonly<FinancialExtractParams>
): Generator {
   const { model, financialType, fileName } = action;
   const financialExtractResponse = yield call(
      financialExtractService.updateFinancialExtract,
      model,
      financialType,
      fileName
   );

   const response = financialExtractResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(
         FinancialExtractActions.updateFinancialExtractSuccess(response.data)
      );
   } else {
      yield put(
         FinancialExtractActions.updateFinancialExtractFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
