import React, { useState, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import SearchPanel from './Panels/SearchPanel';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useDispatch, useSelector } from 'react-redux';
import {
   BoardMeetingDateTypes,
   BoardMeetingDateActions,
   selectBoardMeetingDates,
} from 'State/Redux/BoardMeetingDateRedux';
import { BoardMeetingDateSearchQuery } from 'Models/BoardMeetingDate/BoardMeetingDateSearchQuery';
import BoardMeetingDateResultsPanel from './Panels/BoardMeetingDateResultsPanel';
import './AddBoardMeetingDate.scss';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const SearchBoardMeetingDates = (): JSX.Element => {
   const searchResults = useSelector(selectBoardMeetingDates);
   const isLoading = useSelector(
      selectIsLoading([BoardMeetingDateTypes.SEARCH_BOARD_MEETING_DATE_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);
   const searchBoardMeetingDates = useCallback(
      (query: BoardMeetingDateSearchQuery): void => {
         dispatch(BoardMeetingDateActions.searchBoardMeetingDateRequest(query));
         setHasSearched(true);
      },
      [dispatch]
   );
   const resetSearch = useCallback((): void => setHasSearched(false), []);
   const isSearching = isLoading && hasSearched;

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         <ExpansionPanel
            label="Search"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <SearchPanel
               searchBoardMeetingDates={searchBoardMeetingDates}
               isSearching={isSearching}
               resetSearch={resetSearch}
            />
         </ExpansionPanel>
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <BoardMeetingDateResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default SearchBoardMeetingDates;
