import React, { useState, useEffect, useCallback } from 'react';
import Papa from 'papaparse';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { FileUpload } from 'react-md/lib/FileInputs';
import { useDispatch, useSelector } from 'react-redux';
import {
   ContactActions,
   selectContactValidations,
} from 'State/Redux/ContactRedux';
import RegistrationNumberContactValidationReadonly, {
   DefaultRegistrationNumberContactValidation,
} from 'Models/Contact/Dto/RegistrationNumberContactValidation';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import DateInput from 'Components/Common/DateInput/DateInput';
import CourseDtoReadonly, {
   DefaultCourseDtoModel,
} from 'Models/Examination/Dto/CourseDto';
import {
   CourseActions,
   selectSearchedCourse,
   selectCourseNumbers,
} from 'State/Redux/CourseRedux';
import { ResultActions, ResultTypes } from 'State/Redux/ResultRedux';
import ResultDtoReadonly, {
   DefaultResultDtoModel,
} from 'Models/Examination/Dto/ResultDto';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { DefaultContactLimitedDtoModel } from 'Models/Contact/Dto/ContactLimitedDto';
import { STATUS_APPROVED, PASS_FAIL_SELECTION } from 'Util/Constants/Result';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import { SelectField } from 'react-md/lib/SelectFields';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import {
   DATEPICKER_DATE_FORMAT,
   PARSE_DATE_FORMATS,
} from 'Util/Constants/Common';
import moment from 'moment-timezone';

const AddResults = (): JSX.Element => {
   const [course, setCourse] = useState<CourseDtoReadonly>(
      DefaultCourseDtoModel
   );
   const [courseCode, setCourseCode] = useState<string>();
   const [courseCodeEnabled, setCourseCodeEnabled] = useState<boolean>(true);
   const [hasSaved, setHasSaved] = useState<boolean>(false);

   const dispatch = useDispatch();
   const validatedResults = useSelector(selectContactValidations);
   const searchedCourse = useSelector(selectSearchedCourse);
   const courseNumbers = useSelector(selectCourseNumbers);

   const handleChange = (files: File | Array<File> | null): void => {
      setCourseCodeEnabled(false);
   };

   const updateValidatedResults = useCallback(() => {
      if (
         validatedResults.length &&
         validatedResults.some(
            result =>
               !result.isValidated &&
               !result.isValid &&
               result.registrationNumber
         )
      ) {
         dispatch(
            ContactActions.validateContactRegistrationNumbersRequest(
               validatedResults
            )
         );
      }
   }, [dispatch, validatedResults]);

   const hasAddedResults = selectIsSuccess([
      ResultTypes.SAVE_VALIDATED_RESULTS_SUCCESS,
   ]);

   useEffect(() => {
      if (hasAddedResults && hasSaved) {
         setHasSaved(false);
         toast.success('Successfully Added Results');
         dispatch(ContactActions.setResultValidationLines([]));
         setCourseCode('');
         setCourse(DefaultCourseDtoModel);
      }
   }, [hasAddedResults, hasSaved, setHasSaved, dispatch]);

   useEffect(() => {
      dispatch(CourseActions.getCourseNumbersRequest());
   }, [dispatch]);

   useEffect(() => {
      if (validatedResults.length !== 0) {
         setCourseCodeEnabled(false);
         updateValidatedResults();
      } else {
         setCourseCodeEnabled(true);
      }
   }, [validatedResults, updateValidatedResults, setCourseCodeEnabled]);

   useEffect(() => {
      if (searchedCourse) setCourse(searchedCourse);
   }, [searchedCourse]);

   useEffect(() => {
      return (): void => {
         dispatch(ContactActions.setResultValidationLines([]));
         dispatch(CourseActions.resetSearchedCourse());
      };
   }, [dispatch]);

   const handleLoad = (file: File): void => {
      Papa.parse(file, {
         complete: (result: Papa.ParseResult): void => {
            const resultLines: RegistrationNumberContactValidationReadonly[] = [];
            let resultIndex = validatedResults.length;
            result.data.forEach(resultLine => {
               const registrationNumber = resultLine[0];
               if (registrationNumber === null || registrationNumber === '')
                  return;
               resultLines.push({
                  ...DefaultRegistrationNumberContactValidation,
                  id: resultIndex,
                  resultDate: moment(resultLine[0], PARSE_DATE_FORMATS).format(
                     DATEPICKER_DATE_FORMAT
                  ),
                  registrationNumber: resultLine[1],
                  passFailCode: String(resultLine[2]).toUpperCase(),
                  mark: resultLine[3],
               });
               resultIndex++;
            });
            dispatch(
               ContactActions.setResultValidationLines([
                  ...validatedResults,
                  ...resultLines,
               ])
            );
         },
      });
   };

   const updateResultLine = (
      result: RegistrationNumberContactValidationReadonly
   ): void => {
      dispatch(ContactActions.updateResultValidationLine(result));
   };

   const removeResultLine = (
      result: RegistrationNumberContactValidationReadonly
   ): void => {
      dispatch(ContactActions.removeResultValidationLine(result));
   };

   const validateAndAddResults = (): void => {
      if (validatedResults.some(r => !r.isValid)) return;
      //construct a resultdto array for insertion via the api
      const resultsDto: ResultDtoReadonly[] = [];
      validatedResults.forEach(result => {
         resultsDto.push({
            ...DefaultResultDtoModel,
            mark: Number(result.mark),
            passFailCode: result.passFailCode,
            resultDate: result.resultDate,
            courseId: course.courseId,
            //if admins are uploading the result then default it to approved
            resultStatusCode: STATUS_APPROVED,
            contact: {
               ...DefaultContactLimitedDtoModel,
               registrationNumber: result.registrationNumber
                  ? result.registrationNumber
                  : null,
            },
         });
      });
      setHasSaved(true);
      dispatch(ResultActions.saveValidatedResultsRequest(resultsDto));
   };

   const renderResults = (): JSX.Element => {
      if (validatedResults.length === 0) return <></>;
      return (
         <>
            {validatedResults.map(
               (result): JSX.Element => {
                  return (
                     <div
                        key={result.id}
                        className="md-grid md-cell--12 result-line"
                     >
                        <div className="md-grid md-cell--2">
                           <DateInput
                              id={`add-result-date-${result.id}`}
                              className="add-result-date"
                              label="Result Date"
                              value={result.resultDate}
                              onChange={(val): void => {
                                 updateResultLine({
                                    ...result,
                                    resultDate: val,
                                 });
                              }}
                           />
                        </div>

                        <div className="md-grid md-cell--2">
                           <TextField
                              floating
                              className="result-registration-number-input"
                              label="Licence Number"
                              defaultValue={result.registrationNumber}
                              onChange={(val): void =>
                                 updateResultLine({
                                    ...result,
                                    registrationNumber: Number(val),
                                    contactName: '-',
                                    isValid: false,
                                 })
                              }
                              onBlur={updateValidatedResults}
                              onKeyUp={(evt): void => {
                                 if (isEnterKeyPress(evt))
                                    updateValidatedResults();
                              }}
                           />
                        </div>
                        <div className="md-grid-md-cell--1 md-cell--middle">
                           <FaIcon
                              icon={result.isValid ? 'check' : 'times'}
                              className={`result-margin-top ${
                                 result.isValid ? 'green' : 'red'
                              }`}
                           />
                        </div>
                        <div className="md-grid md-cell--3">
                           <TextField
                              floating
                              className="result-contact-input"
                              label="Practitioner Name"
                              disabled
                              value={result.contactName}
                           />
                        </div>
                        <div className="md-grid md-cell--2">
                           <SelectField
                              id={`pass-fail-selection-${result.id}`}
                              menuItems={PASS_FAIL_SELECTION}
                              floating
                              className="md-cell--12 restrict-option-height"
                              label="Pass/Fail"
                              position={SelectField.Positions.BELOW}
                              value={result.passFailCode}
                              defaultValue={result.passFailCode}
                              onChange={(e): void => {
                                 result = {
                                    ...result,
                                    passFailCode: e.toString(),
                                 };
                                 updateResultLine(result);
                              }}
                           />
                        </div>
                        <div className="md-grid md-cell--1">
                           <TextField
                              floating
                              className="result-mark-input"
                              label="Result %"
                              defaultValue={result.mark}
                              onChange={(val): void =>
                                 updateResultLine({
                                    ...result,
                                    mark: isNullOrWhiteSpace(val)
                                       ? undefined
                                       : Number(val),
                                 })
                              }
                           />
                        </div>

                        <div className="md-grid md-cell--1 md-cell--middle">
                           <Button
                              onClick={(): void => removeResultLine(result)}
                              onKeyUp={(keyPress): void => {
                                 if (isEnterKeyPress(keyPress))
                                    removeResultLine(result);
                              }}
                              className="result-margin-top remove-result-row"
                           >
                              <FaIcon icon="times" />
                           </Button>
                        </div>
                     </div>
                  );
               }
            )}
         </>
      );
   };

   const renderSaveResults = (): JSX.Element => {
      return (
         <div className="md-cell md-cell--12">
            <FileUpload
               iconBefore
               flat
               primary
               allowDuplicates
               id="add-result-upload"
               className="csv-input add-result"
               onChange={handleChange}
               onLoad={handleLoad}
               accept="text/csv"
               label="Upload Results"
               disabled={course.courseId === 0}
            />
            <Button
               flat
               primary
               onClick={(): void => {
                  dispatch(
                     ContactActions.setResultValidationLines([
                        ...validatedResults,
                        {
                           ...DefaultRegistrationNumberContactValidation,
                           id: validatedResults.length,
                           registrationNumber: undefined,
                        },
                     ])
                  );
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  dispatch(
                     ContactActions.setResultValidationLines([
                        ...validatedResults,
                        {
                           ...DefaultRegistrationNumberContactValidation,
                           id: validatedResults.length,
                           registrationNumber: undefined,
                        },
                     ])
                  );
               }}
               className="add-result"
               disabled={course.courseId === 0}
            >
               Add Result
            </Button>

            <Button
               flat
               secondary
               swapTheming
               onClick={(): void => {
                  dispatch(ContactActions.setResultValidationLines([]));
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress))
                     dispatch(ContactActions.setResultValidationLines([]));
               }}
               className="clear-results"
               disabled={validatedResults.length === 0}
            >
               Clear Results
            </Button>

            <Button
               flat
               primary
               swapTheming
               disabled={
                  validatedResults.length === 0 ||
                  validatedResults.some(
                     r => !r.isValid || !r.resultDate || !r.passFailCode
                  )
               }
               onClick={(): void => validateAndAddResults()}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) validateAndAddResults();
               }}
               className="add-results"
            >
               Save Results
            </Button>
         </div>
      );
   };

   return (
      <ExpansionList className="md-cell md-cell--12 add-result">
         <ExpansionPanel
            label="Input Result"
            expanded
            footer={null}
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <SelectField
                     position={SelectField.Positions.BELOW}
                     id="add-result-course-code"
                     label="Course Code"
                     className="md-cell--12 restrict-option-height"
                     menuItems={courseNumbers}
                     required={true}
                     onChange={(val): void => {
                        dispatch(
                           CourseActions.getCourseByCourseNumberRequest(
                              val.toString()
                           )
                        );
                        setCourseCode(val.toString());
                     }}
                     value={courseCode}
                     disabled={!courseCodeEnabled}
                  />
               </div>
               <div className="md-cell md-cell--6"></div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="add-result-course-title"
                     label="Course Title"
                     disabled
                     lineDirection="center"
                     className="md-cell md-cell--bottom md-cell--12"
                     value={course.courseName}
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="add-result-training-provider"
                     label="Training Provider"
                     disabled
                     lineDirection="center"
                     className="md-cell md-cell--bottom md-cell--12"
                     value={course.trainingProvider.companyName}
                  />
               </div>
               {renderResults()}
               {renderSaveResults()}
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddResults;
