import React, { useEffect } from 'react';
import { usePermissions } from 'Util/Helpers/Permissions';
import './RegistrationFitAndProper.scss';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import CountryListMultiSelect from 'Components/Common/CountryListMultiSelect/CountryListMultiSelect';

interface FitAndProperProps {
   fitAndProper?: QuestionDto[];
   answerData: Array<{
      id: number;
      checkbox: boolean;
      text: string | undefined;
      latestOtherAnswer?: string;
   }>;
   setAnswerData: (
      data: Array<{
         id: number;
         checkbox: boolean;
         text: string | undefined;
         latestOtherAnswer?: string;
      }>
   ) => void;

   selectedCountriesList: Array<{ value: string }>;
   setSelectedCountriesList: (data: Array<{ value: string }>) => void;
   disabled: boolean;
}

const OPTION_YES = 'Yes';
const OPTION_NO = 'No';
export const QUESTION_TYPE_COUNTRY = '13';
export const QUESTION_TYPE_REGISTRATION = 1;

export const RegistrationFitAndProper = ({
   fitAndProper,
   answerData,
   setAnswerData,
   selectedCountriesList,
   setSelectedCountriesList,
   disabled,
}: Readonly<FitAndProperProps>): JSX.Element => {
   const hasReadPermission = usePermissions(['F2P.Answer.Read']);

   const onValueChange = (val: string, groupName: number): void => {
      // set answerData only if empty.
      const newAnswers = { ...answerData };
      newAnswers[+groupName].checkbox = val === OPTION_YES;
      if (groupName === +QUESTION_TYPE_COUNTRY && val === OPTION_NO) {
         newAnswers[groupName].text = '';
         setSelectedCountriesList([]);
      }
      setAnswerData({ ...newAnswers });
   };

   useEffect(() => {
      fitAndProper?.map(a => {
         if (a.latestOtherAnswer) {
            const parseArray = JSON.parse(a.latestOtherAnswer as string);
            setSelectedCountriesList(parseArray);
         }
      });
   }, [fitAndProper, setSelectedCountriesList]);

   useEffect(() => {
      const newAnswers = { ...answerData };
      fitAndProper?.map((q, i) => {
         newAnswers[q.id] = {
            id: q.id,
            checkbox: q.latestAnswer,
            text: q.latestOtherAnswer,
         };
      });

      setAnswerData({ ...newAnswers });

      if (newAnswers && newAnswers[+QUESTION_TYPE_COUNTRY]) {
         const textCountry = newAnswers[+QUESTION_TYPE_COUNTRY].text;
         setSelectedCountriesList(
            textCountry && textCountry !== null ? JSON.parse(textCountry) : []
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fitAndProper]);

   const renderRadioOption = (
      id: string,
      groupName: string,
      option: string,
      defaultValue?: string
   ): JSX.Element => {
      const isChecked =
         defaultValue && defaultValue === option
            ? {
                 defaultChecked: true,
              }
            : undefined;

      return (
         <label className="bold black-text">
            <span className="radio-option-label">{option}</span>
            <input
               id={id}
               radioGroup={groupName}
               className="with-gap"
               name={groupName}
               type="radio"
               disabled={disabled}
               value={defaultValue}
               onChange={(e): void => onValueChange(option, +groupName)}
               {...isChecked}
            />
            <span />
         </label>
      );
   };

   const renderListOfCountry = (
      countryList: Array<{ value: string }>
   ): JSX.Element => {
      return (
         <CountryListMultiSelect
            selectedCountries={countryList}
            onUpdateSelectedCountries={setSelectedCountriesList}
            disabled={disabled}
         />
      );
   };

   return hasReadPermission ? (
      <>
         {fitAndProper ? (
            <div className="registration-fit-and-proper-inline">
               <div className="title">Fit & Proper Questions</div>
               {fitAndProper?.map(a => {
                  const defaultVal =
                     !a || a.latestAnswer === null
                        ? ''
                        : a.latestAnswer === true
                        ? OPTION_YES
                        : OPTION_NO;
                  return (
                     <div
                        className={
                           a.id === +QUESTION_TYPE_COUNTRY
                              ? 'section-last'
                              : 'section'
                        }
                        key={a.id}
                     >
                        <div className="col-3 question-section">
                           {a.questionText}
                        </div>
                        <div className="col-2 option-section">
                           {renderRadioOption(
                              OPTION_YES,
                              a.id.toString(),
                              OPTION_YES,
                              defaultVal
                           )}
                           {renderRadioOption(
                              OPTION_NO,
                              a.id.toString(),
                              OPTION_NO,
                              defaultVal
                           )}
                        </div>
                        {a.id === +QUESTION_TYPE_COUNTRY &&
                        answerData[a.id] &&
                        answerData[a.id].checkbox ? (
                           renderListOfCountry(selectedCountriesList)
                        ) : (
                           <></>
                        )}
                     </div>
                  );
               })}
            </div>
         ) : (
            <></>
         )}
      </>
   ) : (
      <></>
   );
};
