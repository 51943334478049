import React from 'react';
import {
   selectSearchResult,
   FeesPaymentTypes,
} from 'State/Redux/FeesPaymentRedux';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { ServerSideTable } from 'Components/Common/ServerSideTable/ServerSideTable';
import { Column, CellInfo } from 'react-table';
import { PagedQuery } from 'Models/Other/PagedQuery';
import Button from 'react-md/lib/Buttons';
import Moment from 'moment-timezone';
import { DISPLAY_DATE_FORMAT } from 'Util/Constants/Common';
import {
   FEE_REQUEST_UNPAID_DESC,
   FEE_REQUEST_PART_PAID_DESC,
   FEE_REQUEST_CANCELLED_DESC,
} from 'Util/Constants/FeeRequestStatus';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import FeePaymentResultItemReadOnly from 'Models/FeesPayment/FeePaymentResultItem';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { CANCELLED } from 'Util/Constants/PaymentStatus';
import { usePermissions } from 'Util/Helpers/Permissions';

interface FeesSearchProps {
   paging: PagedQuery;
   onPagingChanged: (paging: PagedQuery) => void;
}

const EMPTY_COL_INDICATOR = '-';
const FEE_REQUEST_EDIT_URL = '/fees-payments/edit-fee-request/';

const formatDate = (date: string | null): string => {
   return date && Moment(date).isValid()
      ? Moment(date).format(DISPLAY_DATE_FORMAT)
      : '';
};

const PaymentDateCell = (cell: CellInfo): JSX.Element => {
   // If payment date is available then show the payment date: else feerequest date
   const dateval = cell.value ? cell.value : cell.original.feeRequestDate;
   return <>{dateval ? formatDate(dateval) : EMPTY_COL_INDICATOR}</>;
};

const CommonDashedCell = (cell: CellInfo): JSX.Element => {
   return <>{cell.value ? cell.value : EMPTY_COL_INDICATOR}</>;
};

const AppTypeCell = (cell: CellInfo): JSX.Element => {
   return (
      <>
         {cell.value ? (
            cell.original.isLevy ? (
               <>
                  {cell.value}
                  <div className="levy-cell">Levy</div>
               </>
            ) : (
               cell.value
            )
         ) : (
            EMPTY_COL_INDICATOR
         )}
      </>
   );
};

const StatusCell = (cell: CellInfo): JSX.Element => {
   const isCancelledPayment =
      cell.original &&
      cell.original.payment &&
      cell.original.payment.paymentStatusCode === CANCELLED;

   return <>{isCancelledPayment ? 'Cancelled Payment' : cell.value}</>;
};

const FeePaymentResultsPanel = ({
   paging,
   onPagingChanged,
}: Readonly<FeesSearchProps>): JSX.Element => {
   const searchResult = useSelector(selectSearchResult);
   const isLoadingSearch = useSelector(
      selectIsLoading([FeesPaymentTypes.GET_SEARCH_RESULT_REQUEST])
   );
   const history = useHistory();
   const permissions = usePermissions([
      'BackOfficeFinance.Create' && 'BackOfficeFinance.Update',
   ]);

   const PayButtonCell = (cell: CellInfo): JSX.Element => {
      const returnLInk = `&returnUrl=${history.location.pathname}`;

      // Send the payment Id only if the payment record exist (or) Send the FeeRequest Id
      const hasPayment =
         cell.value !== FEE_REQUEST_UNPAID_DESC &&
         cell.value !== FEE_REQUEST_PART_PAID_DESC &&
         cell.original.payment &&
         cell.original.payment.paymentId;

      const isCancelledPayment =
         cell.original &&
         cell.original.payment &&
         cell.original.payment.paymentId &&
         cell.original.payment.paymentStatusCode === CANCELLED;

      const paymentUrl =
         hasPayment || isCancelledPayment
            ? `/payment?paymentId=${cell.original.payment.paymentId}${returnLInk}`
            : `/payment?feeRequestId=${cell.original.feeRequestId}${returnLInk}`;

      const viewOnly =
         hasPayment ||
         cell.value === FEE_REQUEST_CANCELLED_DESC ||
         isCancelledPayment;

      return (
         <>
            <Link
               to={{
                  pathname: `${FEE_REQUEST_EDIT_URL}${cell.original.feeRequestId}`,
                  state: { returnUrl: history.location.pathname },
               }}
               target="_blank"
            >
               <Button
                  icon
                  className="table-row-button"
                  iconEl={
                     <FaIcon
                        icon="edit"
                        className="small-icon fee-request-line-table-icon"
                     />
                  }
               />
            </Link>
            {(permissions || viewOnly) && (
               <Link to={paymentUrl} target="_blank">
                  <Button primary flat className="table-row-button">
                     {viewOnly ? 'View' : 'Pay Now'}
                  </Button>
               </Link>
            )}
         </>
      );
   };

   const COLUMN_HEADERS: Column<FeePaymentResultItemReadOnly>[] = [
      {
         Header: 'Licence Number',
         accessor: 'licenceNumber',
      },
      {
         Header: 'Payer Name',
         accessor: 'payment.payee',
         Cell: CommonDashedCell,
      },
      {
         Header: 'Application Type',
         accessor: 'applicationTypeDesc',
         Cell: AppTypeCell,
         width: 400,
      },
      {
         Header: 'Licensing Year',
         accessor: 'licenceYear',
         Cell: CommonDashedCell,
      },
      {
         Header: 'Fee / Payment Status',
         accessor: 'feeRequestStatusCode',
         Cell: StatusCell,
      },
      {
         Header: 'Fee / Payment Date',
         accessor: 'payment.paymentDate',
         Cell: PaymentDateCell,
         sortable: true,
      },
      {
         Header: '',
         Cell: PayButtonCell,
         accessor: 'feeRequestStatusCode',
         sortable: false,
         width: 160,
      },
   ];

   return (
      <ServerSideTable
         headers={COLUMN_HEADERS}
         data={searchResult.results}
         totalResults={searchResult.totalCount}
         paging={paging}
         onPagingChanged={onPagingChanged}
         isLoading={isLoadingSearch}
      />
   );
};

export default FeePaymentResultsPanel;
