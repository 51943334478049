import { put, call } from 'redux-saga/effects';
import { GlobalNotificationActions } from '../Redux/GlobalNotificationRedux';
import { ApiResponse } from 'apisauce';
import { ReadonlyGlobalNotification } from 'Models/Notifications/GlobalNotification';
import { GlobalNotificationServiceType } from 'State/Services/GlobalNotificationService';

interface GlobalNotificationParams {
   type: string;
   token: string;
   id: string;
   notification: ReadonlyGlobalNotification;
}

export function* getGlobalNotification(
   globalNotificationService: Readonly<GlobalNotificationServiceType>,
   action: Readonly<GlobalNotificationParams>
): Generator {
   const globalNotificationResponse = yield call(
      globalNotificationService.getGlobalNotification
   );
   const response = globalNotificationResponse as ApiResponse<
      ReadonlyGlobalNotification
   >;

   if (response.status && response.ok) {
      yield put(
         GlobalNotificationActions.getGlobalNotificationSuccess(response.data)
      );
   } else {
      yield put(
         GlobalNotificationActions.getGlobalNotificationFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateGlobalNotification(
   globalNotificationService: Readonly<GlobalNotificationServiceType>,
   action: Readonly<GlobalNotificationParams>
): Generator {
   const { notification } = action;
   const globalNotificationResponse = yield call(
      globalNotificationService.updateGlobalNotification,
      notification
   );
   const response = globalNotificationResponse as ApiResponse<
      ReadonlyGlobalNotification
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         GlobalNotificationActions.updateGlobalNotificationSuccess(
            response.data
         )
      );
   } else {
      yield put(
         GlobalNotificationActions.updateGlobalNotificationFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
