import React, { useState } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { SelectField } from 'react-md/lib/SelectFields';
import { TextField } from 'react-md/lib/TextFields';
import {
   ComplaintSearchQuery,
   DefaultComplaintSearchQuery,
   validateSearch,
   filterToValidQuery,
   isValidQuery,
} from 'Models/Complaint/ComplaintSearchQuery';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useComplaintStatusList } from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import LoadingButton from 'Components/Common/LoadingButton';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ComplaintsSearchPanelProps {
   searchComplaints: (query: ComplaintSearchQuery) => void;
   isSearching: boolean;
   resetSearch: () => void;
}

const ComplaintsSearchPanel = ({
   searchComplaints,
   isSearching,
   resetSearch,
}: Readonly<ComplaintsSearchPanelProps>): JSX.Element => {
   const complaintStatuses = useComplaintStatusList(
      'Select Disciplinary Matter Status'
   );

   const [searchQuery, setSearchQuery] = useState(DefaultComplaintSearchQuery);
   const validation = validateSearch(searchQuery);
   const filteredQuery = filterToValidQuery(searchQuery);
   const isValid = isValidQuery(filteredQuery);

   const searchOnKeyPress = (
      keyPress: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (isEnterKeyPress(keyPress) && isValid) searchComplaints(filteredQuery);
   };

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Disciplinary Matter #"
               lineDirection="center"
               placeholder="Disciplinary Matter #"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.complaintId
               )}
               error={!validation.complaintId}
               errorText="Please enter a valid disciplinary matter number"
               value={searchQuery.complaintId}
               onChange={(val): void => {
                  setSearchQuery({
                     ...searchQuery,
                     complaintId: val.toString(),
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               id="select-field-6"
               lineDirection="center"
               label="Disciplinary Matter Status"
               placeholder="Disciplinary Matter Status"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.status)}
               menuItems={complaintStatuses}
               position={SelectField.Positions.BELOW}
               value={searchQuery.status}
               onChange={(val): void => {
                  setSearchQuery({
                     ...searchQuery,
                     status: val.toString(),
                  });
               }}
               onKeyUp={searchOnKeyPress}
               defaultValue=" "
               error={!validation.status}
               errorText="Please enter a valid status"
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.registrationNumber
               )}
               error={!validation.registrationNumber}
               errorText="Please enter a valid licence number"
               value={searchQuery.registrationNumber}
               onChange={(val): void => {
                  setSearchQuery({
                     ...searchQuery,
                     registrationNumber: val.toString(),
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Respondent Name"
               lineDirection="center"
               placeholder="Respondent Name"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.name)}
               error={!validation.name}
               errorText="Please enter a valid name"
               value={searchQuery.name}
               onChange={(val): void => {
                  setSearchQuery({
                     ...searchQuery,
                     name: val.toString(),
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="complaint-start-date"
               label="Start Date"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.startDate
               )}
               error={!validation.startDate}
               errorText="Please enter a valid start date"
               value={searchQuery.startDate ? searchQuery.startDate : undefined}
               onChange={(date): void => {
                  setSearchQuery({
                     ...searchQuery,
                     startDate: date,
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>

         <div className="md-cell md-cell--6">
            <DateInput
               id="complaint-end-date"
               label="End Date"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.endDate)}
               error={!validation.endDate}
               errorText="Please enter a valid end date"
               value={searchQuery.endDate ? searchQuery.endDate : undefined}
               onChange={(date): void => {
                  setSearchQuery({
                     ...searchQuery,
                     endDate: date,
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>
         <div className="md-cell md-cell--8"></div>
         <div className="md-cell md-cell--4">
            <LoadingButton
               flat
               primary
               className="md-cell md-cell--6 search-button"
               swapTheming
               isLoading={isSearching}
               disabled={!isValid}
               onClick={(): void => searchComplaints(filteredQuery)}
               onKeyUp={searchOnKeyPress}
            >
               Search
            </LoadingButton>
            <Button
               flat
               secondary
               className="md-cell md-cell--6 red-btn search-button"
               swapTheming
               onClick={(): void => {
                  setSearchQuery(DefaultComplaintSearchQuery);
                  resetSearch();
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) {
                     setSearchQuery(DefaultComplaintSearchQuery);
                     resetSearch();
                  }
               }}
            >
               Reset
            </Button>
         </div>
      </div>
   );
};

export default ComplaintsSearchPanel;
