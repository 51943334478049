import ContactService from './ContactService';
import ApplicationService from './ApplicationService';
import LicenceService from './LicenceService';
import IdentityService from './IdentityService';
import SupervisionService from './SupervisionService';
import SystemParametersService from './SystemParametersService';
import PaymentMethodService from './PaymentMethodService';
import RegistrationTypeService from './RegistrationTypeService';
import FeeMaintenanceService from './FeeMaintenanceService';
import ComplaintService from './ComplaintService';
import ResultService from './ResultService';
import MetadataService from './MetadataService';
import ExaminationService from './ExaminationService';
import CourseService from './CourseService';
import FeesPaymentService from './FeesPaymentService';
import NotificationService from './NotificationService';
import ReportService from './ReportService';
import LetterService from './LetterService';
import BoardMeetingDateService from './BoardMeetingDateService';
import ReceiptService from './ReceiptService';
import GlobalNotificationService from './GlobalNotificationService';
import PaymentService from './PaymentService';
import ContactPhotoService from './ContactPhotoService';
import LicenceTypeService from './LicenceTypeService';
import FeeTypeService from './FeeTypeService';
import DashboardService from './DashboardService';
import FitAndProperService from './FitAndProperService';
import EnumService from './EnumService';

import FinancialExtractService from './FinancialExtractService';
import apisauce, { ApisauceInstance, ApisauceConfig } from 'apisauce';
import { UserState } from 'redux-oidc';

export { ContactService };
export { ApplicationService };
export { LicenceService };
export { NotificationService };
export { ReportService };
export { IdentityService };
export { ComplaintService };
export { ResultService };
export { MetadataService };
export { SupervisionService };
export { SystemParametersService };
export { PaymentMethodService };
export { RegistrationTypeService };
export { FeeMaintenanceService };
export { ExaminationService };
export { CourseService };
export { FeesPaymentService };
export { LetterService };
export { BoardMeetingDateService };
export { ReceiptService };
export { GlobalNotificationService };
export { PaymentService };
export { ContactPhotoService };
export { FinancialExtractService };
export { LicenceTypeService };
export { FeeTypeService };
export { DashboardService };
export { FitAndProperService };
export { EnumService };

const MIMETYPE_JSON = 'application/json';

const DEFAULT_API_CONFIG: ApisauceConfig = {
   baseURL: '',
   data: {},
   headers: {
      Accept: MIMETYPE_JSON,
      'Content-Type': MIMETYPE_JSON,
   },
};

export const createApi = (options: ApisauceConfig): ApisauceInstance => {
   const resolvedOptions = { ...DEFAULT_API_CONFIG, ...options };
   return apisauce.create(resolvedOptions);
};

export const withAccessToken = (
   api: ApisauceInstance,
   state: UserState
): ApisauceInstance => {
   if (!api) return api;

   const accessToken =
      state && state.user && state.user.access_token
         ? state.user.access_token
         : '';
   if (!accessToken) return api;

   api.setHeader('Authorization', `Bearer ${accessToken}`);
   return api;
};
