export const EDIT_SUPERVISION = 'EDIT_SUPERVISION';
export const END_IMMEDIATELY_CONFIRMATION = 'END_IMMEDIATELY_CONFIRMATION';

export const ACCEPT_SUPERVISEE = 'ACCEPT_SUPERVISEE';
export const END_IMMEDIATELY = 'END_IMMEDIATELY';
export const DECLINE_SUPERVISEE = 'DECLINE_SUPERVISEE';

export type EditSupervisorModalMode =
   | 'EDIT_SUPERVISION'
   | 'END_IMMEDIATELY_CONFIRMATION';

export type EditSuperviseeModalMode =
   | 'ACCEPT_SUPERVISEE'
   | 'END_IMMEDIATELY'
   | 'DECLINE_SUPERVISEE';

export const MODAL_MODE_MAPPING: ReadonlyMap<string, string> = new Map([
   [ACCEPT_SUPERVISEE, 'Accept Supervisee'],
   [END_IMMEDIATELY, 'End Supervision'],
   [DECLINE_SUPERVISEE, 'Decline Supervisee'],
]);
