import React, { useEffect, useState } from 'react';
import { ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields';
import {
   ResultSearchQuery,
   DefaultResultSearchQueryModel,
   validateSearch,
} from 'Models/Examination/Data/ResultSearchQuery';
import {
   isEnterKeyPress,
   createSelectList,
   SelectItem,
} from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import Contact from 'Models/Contact/Data/Contact';
import { ContactActions } from 'State/Redux/ContactRedux';
import { useResultStatusList } from 'Util/Helpers/Metadata';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface SearchPanelProps {
   query: ResultSearchQuery;
   setQuery: (query: ResultSearchQuery) => void;
   setIsSearching: (isSearching: boolean) => void;
   reset: () => void;
}

const SearchPanel = ({
   query,
   setQuery,
   setIsSearching,
   reset,
}: Readonly<SearchPanelProps>): JSX.Element => {
   const [trainingProvidersList, setTrainingProvidersList] = useState<
      SelectItem[]
   >([]);
   const resultStatuses = useResultStatusList();
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(ContactActions.getTrainingProvidersRequest());
   }, [dispatch]);

   const trainingProviders = useSelector(
      (state: StateReadonly): readonly Contact[] =>
         state.contact.trainingProviders
   );

   useEffect(() => {
      setTrainingProvidersList(
         createSelectList(
            trainingProviders,
            (tp): string => tp.contactId.toString(),
            (tp): string =>
               tp.companyName ||
               `Unnamed Training Provider (${tp.contactId.toString()})`,
            'Select a Training Provider'
         )
      );
   }, [trainingProviders]);

   const validation = validateSearch(query);

   const searchOnKeyPress = (
      keyPress: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (isEnterKeyPress(keyPress)) setIsSearching(true);
   };

   return (
      <ExpansionPanel
         label="Search"
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
         className="search-panel"
      >
         <div className="md-grid md-cell--12">
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="cpd-search-input-course-code"
                  label="Course Code"
                  lineDirection="center"
                  placeholder="Course Code"
                  className="md-cell md-cell--bottom md-cell--12"
                  value={query.courseCode}
                  onChange={(val): void => {
                     setQuery({
                        ...query,
                        courseCode: val.toString(),
                     });
                  }}
                  onKeyUp={searchOnKeyPress}
                  inputClassName={getInputValidationClassName(
                     !validation.courseCode
                  )}
                  error={!validation.courseCode}
                  errorText="Invalid Course Code"
               />
            </div>
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="cpd-search-input-course-title"
                  label="Course Title"
                  lineDirection="center"
                  placeholder="Course Title"
                  className="md-cell md-cell--bottom md-cell--12"
                  value={query.courseTitle}
                  onChange={(val): void => {
                     setQuery({
                        ...query,
                        courseTitle: val.toString(),
                     });
                  }}
                  onKeyUp={searchOnKeyPress}
                  inputClassName={getInputValidationClassName(
                     !validation.courseTitle
                  )}
                  error={!validation.courseTitle}
                  errorText="Invalid Course Title"
               />
            </div>
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="cpd-search-input-licence-number"
                  label="Licence Number"
                  lineDirection="center"
                  placeholder="Licence Number"
                  className="md-cell md-cell--bottom md-cell--12"
                  value={query.licenceNumber}
                  onChange={(val): void => {
                     setQuery({
                        ...query,
                        licenceNumber: val.toString(),
                     });
                  }}
                  onKeyUp={searchOnKeyPress}
                  inputClassName={getInputValidationClassName(
                     !validation.licenceNumber
                  )}
                  error={!validation.licenceNumber}
                  errorText="Invalid Licence Number"
               />
            </div>
            <div className="md-cell md-cell--6">
               <SelectField
                  floating
                  id="cpd-search-input-training-provider"
                  label="Training Provider"
                  lineDirection="center"
                  placeholder="Training Provider"
                  className="md-cell md-cell--bottom md-cell--12"
                  position={SelectField.Positions.BELOW}
                  menuItems={trainingProvidersList}
                  value={query.trainingProvider}
                  onChange={(val): void => {
                     setQuery({
                        ...query,
                        trainingProvider: val.toString(),
                     });
                  }}
                  onKeyUp={searchOnKeyPress}
               />
            </div>
            <div className="md-cell md-cell--6">
               <SelectField
                  floating
                  id="cpd-search-input-result-status"
                  label="Result Status"
                  lineDirection="center"
                  placeholder="Result Status"
                  className="md-cell md-cell--bottom md-cell--12"
                  position={SelectField.Positions.BELOW}
                  menuItems={resultStatuses}
                  value={query.status}
                  onChange={(val): void => {
                     setQuery({
                        ...query,
                        status: val.toString(),
                     });
                  }}
                  onKeyUp={searchOnKeyPress}
               />
            </div>
            <div className="md-cell md-cell--12 search-buttons">
               <Button
                  flat
                  primary
                  swapTheming
                  onClick={(): void => setIsSearching(true)}
                  onKeyUp={searchOnKeyPress}
                  className="search"
               >
                  Search
               </Button>
               <Button
                  flat
                  secondary
                  className="red-btn reset"
                  swapTheming
                  onClick={(): void => {
                     setIsSearching(false);
                     setQuery(DefaultResultSearchQueryModel);
                     reset();
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) {
                        setIsSearching(false);
                        setQuery(DefaultResultSearchQueryModel);
                        reset();
                     }
                  }}
               >
                  Reset
               </Button>
            </div>
         </div>
      </ExpansionPanel>
   );
};

export default SearchPanel;
