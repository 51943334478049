import React, { useEffect } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import '../Supervision.scss';
import SupervisionInputCell from './SupervisionInputCell';
import {
   SupervisionActions,
   SupervisionTypes,
} from 'State/Redux/SupervisionRedux';
import { AsyncActions, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import {
   EditSupervisorModalMode,
   END_IMMEDIATELY_CONFIRMATION,
} from './SupervisionModalMode';
import SupervisionReadonly from 'Models/Supervision/Data/Supervision';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface EditSupervisionProps {
   selectedSupervision: SupervisionReadonly | null;
   updateShowModal: (showModal: boolean) => void;
   updateModalMode: (modalMode: EditSupervisorModalMode) => void;
}

const EditSupervisionModalBody = ({
   selectedSupervision,
   updateShowModal,
   updateModalMode,
}: EditSupervisionProps): JSX.Element => {
   const isSuccess = useSelector(
      selectIsSuccess([SupervisionTypes.CHANGE_SUPERVISOR_REQUEST])
   );
   const dispatch = useDispatch();

   useEffect(() => {
      if (isSuccess) {
         toast.success('Supervisor changed successfully');
         updateShowModal(false);
      }
   }, [updateShowModal, isSuccess]);

   // For cleanup on unmount
   useEffect(() => {
      return (): void => {
         dispatch(
            AsyncActions.resetAsync([
               SupervisionTypes.CHANGE_SUPERVISOR_REQUEST,
            ])
         );
         dispatch(SupervisionActions.searchSupervisorReset());
      };
   }, [dispatch]);

   return selectedSupervision ? (
      <div className="md-grid md-cell--12 supervision-modal">
         <div className="md-grid md-cell--12 grey-background form-section">
            <h3 className="md-cell md-cell--12">Change Supervisor</h3>
            <SupervisionInputCell selectedSupervision={selectedSupervision} />
         </div>
         <div className="md-grid md-cell--12 grey-background form-section">
            <h3 className="md-cell md-cell--12">Change End Date</h3>
            <Button
               onClick={(): void => {
                  updateModalMode(END_IMMEDIATELY_CONFIRMATION);
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress))
                     updateModalMode(END_IMMEDIATELY_CONFIRMATION);
               }}
               className="md-cell md-cell--12"
               flat
               primary
               swapTheming
            >
               End Immediately
            </Button>
         </div>
         <Button
            onClick={(): void => {
               updateShowModal(false);
            }}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) updateShowModal(false);
            }}
            className="cancel-button"
            flat
            secondary
            swapTheming
         >
            Cancel
         </Button>
      </div>
   ) : (
      <div>Supervision not selected</div>
   );
};

export default React.memo(EditSupervisionModalBody);
