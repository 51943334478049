import React from 'react';
import { ReactTableDefaults } from 'react-table';
import { Link } from 'react-router-dom';

interface TableRowLinkProps {
   to: string;
   isExternal: boolean;
}

export const TableRowLink = ({
   to,
   isExternal = false,
   ...rest
}: TableRowLinkProps): JSX.Element => {
   if (to) {
      return (
         <Link
            to={to}
            className="disable-link-styles"
            target={isExternal ? '_blank' : ''}
         >
            <ReactTableDefaults.TrComponent
               {...rest}
               style={{
                  // we need to override otherwise this would be display: inline-flex
                  display: 'flex',
               }}
            />
         </Link>
      );
   } else {
      return <ReactTableDefaults.TrComponent {...rest} />;
   }
};
