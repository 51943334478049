import SupervisionReadonly from 'Models/Supervision/Data/Supervision';
import RegistrationReadonly from 'Models/Registration/Registration';
import { REGISTRATION_STATUS_ACTIVE } from 'Util/Constants/RegistrationStatus';
import {
   PLUMBER_LICENCE_TYPES,
   DRAINLAYER_LICENCE_TYPES,
   GASFITTER_LICENCE_TYPES,
   SECTION_21_LICENCE_TYPE,
   EXEMPT_LICENCE_TYPES,
   EXEMPTION_GAFITTING_UNDER_SUPERVISION_LICENCE_TYPE,
} from 'Util/Constants/LicenceTypes';
import { CERTIFYING_REGISTRATION_TYPES } from 'Util/Constants/RegistrationTypes';
import LicenceDtoReadonly from 'Models/Licence/Dto/LicenceDto';
import SupervisionLimitedDtoReadonly from 'Models/Supervision/Dto/SupervisionLimitedDto';
import Moment from 'moment-timezone';
import ContactReadonly from 'Models/Contact/Data/Contact';
import { CONTACT_STATUS_DECEASED } from 'Util/Constants/Contact';
import SupervisionStatusDtoReadonly from 'Models/Supervision/Dto/SupervisionStatusDto';
import { ActiveLicenceStatuses } from 'Util/Constants/LicenceStatus';
import { Supervisee } from 'Models/Supervision/Data/Supervisee';

// Returns true if the supervision is currently active by startDate and endDate
export const isSupervisionDateCurrentActive = (
   supervision: SupervisionReadonly | SupervisionLimitedDtoReadonly
): boolean => {
   return !!(
      supervision.endDate &&
      supervision.startDate &&
      Moment().isBefore(Moment(supervision.endDate)) &&
      Moment(supervision.startDate).isBefore(Moment())
   );
};

// Returns true if the supervision is will be active sometime in the future
export const isSupervisionDateFutureActive = (
   supervision: SupervisionReadonly | SupervisionLimitedDtoReadonly
): boolean => {
   return !!(
      supervision.endDate &&
      supervision.startDate &&
      Moment(supervision.endDate).isAfter(Moment()) &&
      Moment(supervision.startDate).isAfter(Moment())
   );
};

export const isSupervisionRegistrationsValid = (
   registration: readonly RegistrationReadonly[],
   disciplineCode: string
): boolean => {
   return registration.some((r): boolean => {
      return (
         r.registrationDiscipline === disciplineCode &&
         CERTIFYING_REGISTRATION_TYPES.includes(r.registrationTypeCode) &&
         r.registrationStatusCode === REGISTRATION_STATUS_ACTIVE
      );
   });
};

export const isSupervisionLicencesValid = (
   supervisorLicences: readonly LicenceDtoReadonly[],
   licenceTypeCode: string,
   licenceYearId: number,
   licenceSupervisions: Record<string, string[]>
): boolean => {
   return supervisorLicences.some((val): boolean => {
      const lsKeyArray = Object.keys(licenceSupervisions) as Array<string>;
      const key = lsKeyArray.find(k => k === licenceTypeCode);
      if (!key) return false;
      return (
         licenceSupervisions[key].some(ls => ls === val.licenceTypeCode) &&
         val.licenceYearNumber === licenceYearId
      );
   });
};

export const isSupervisionLicenceValid = (
   supervisorLicence: LicenceDtoReadonly,
   licenceTypeCode: string,
   licenceYearId: number,
   licenceSupervisions: Record<string, string[]>
): boolean => {
   const lsKeyArray = Object.keys(licenceSupervisions) as Array<string>;
   const key = lsKeyArray.find(k => k === licenceTypeCode);
   if (!key) return false;
   return (
      licenceSupervisions[key].some(
         ls => ls === supervisorLicence.licenceTypeCode
      ) && supervisorLicence.licenceYearNumber === licenceYearId
   );
};

export const isSupervisorAllowedToSupervise = (
   licenceTypeCode: string,
   supervisor: ContactReadonly
): boolean => {
   if (PLUMBER_LICENCE_TYPES.includes(licenceTypeCode)) {
      return !supervisor.cannotSupervisePlumber;
   } else if (DRAINLAYER_LICENCE_TYPES.includes(licenceTypeCode)) {
      return !supervisor.cannotSuperviseDrainlayer;
   } else if (GASFITTER_LICENCE_TYPES.includes(licenceTypeCode)) {
      return !supervisor.cannotSuperviseGasfitter;
   }
   return true;
};

export const checkAcceptedSupervisionActive = (
   supervisee: Supervisee,
   supervisorLicences: readonly LicenceDtoReadonly[],
   licenceSupervisions: Record<string, string[]>
): boolean => {
   return supervisorLicences.some((li: LicenceDtoReadonly) => {
      return (
         isSupervisionLicenceValid(
            li,
            supervisee.licenceTypeCode,
            supervisee.licenceYearId,
            licenceSupervisions
         ) &&
         ActiveLicenceStatuses.some(ls => ls === li.licenceStatusCode) &&
         li.licenceYearNumber === supervisee.licenceYearId
      );
   });
};

export const getSupervisionStatus = (
   licenceSupervisions: Record<string, string[]>,
   supervisionSearchResult: ContactReadonly | null,
   licenceTypeCode: string,
   disciplineCode: string,
   licenceYearId: number,
   currentContact: ContactReadonly
): SupervisionStatusDtoReadonly => {
   let status: SupervisionStatusDtoReadonly = {
      statusMessage: 'Supervision valid',
      supervisorStatus: 'Valid',
   };
   if (supervisionSearchResult === null) {
      status = {
         statusMessage: 'Invalid licence number',
         supervisorStatus: 'Not Found',
      };
   } else if (
      supervisionSearchResult.contactStatusCode === CONTACT_STATUS_DECEASED
   ) {
      status = {
         statusMessage: 'Contact is deceased',
         supervisorStatus: 'Invalid',
      };
   } else if (
      supervisionSearchResult.registrationNumber ===
      currentContact.registrationNumber
   ) {
      status = {
         statusMessage: 'Supervisee cannot supervise themselves',
         supervisorStatus: 'Invalid',
      };
   } else if (
      !isSupervisorAllowedToSupervise(licenceTypeCode, supervisionSearchResult)
   ) {
      status = {
         statusMessage:
            'Selected supervisor cannot supervise due to their RTR flags',
         supervisorStatus: 'Invalid',
      };
   } else if (
      licenceTypeCode !== SECTION_21_LICENCE_TYPE &&
      licenceTypeCode !== EXEMPTION_GAFITTING_UNDER_SUPERVISION_LICENCE_TYPE &&
      !isSupervisionRegistrationsValid(
         supervisionSearchResult.registration,
         disciplineCode
      )
   ) {
      // Updated for  PPMA-1702 - Section 20 exemption and Supervising
      // Do not check for supervisor's registrations if the supervisee is holding Section 21(a)(ii) EUS or
      // Exemption Gasfitting under supervision license
      status = {
         statusMessage:
            'Selected supervisor is not a registered Certifier for this licence type',
         supervisorStatus: 'Invalid',
      };
   } else if (
      !isSupervisionLicencesValid(
         supervisionSearchResult.licenceContact,
         licenceTypeCode,
         licenceYearId,
         licenceSupervisions
      )
   ) {
      status = {
         statusMessage: 'Selected supervisor does not possess a valid licence',
         // If not an exempt or S21, the supervision assignment is still valid
         supervisorStatus:
            EXEMPT_LICENCE_TYPES.includes(licenceTypeCode) ||
            licenceTypeCode === SECTION_21_LICENCE_TYPE
               ? 'Invalid'
               : 'Pending',
      };
   }

   return status;
};
