// Contact Photo
export const PHOTO_APPROVED_STATUS_CODE = '2';
export const PHOTO_EXTRACTED_STATUS_CODE = '4';
export const PHOTO_REJECTED_STATUS_CODE = '3';
export const PHOTO_UPLOADED_STATUS_CODE = '1';

// Contact Status
export const CONTACT_STATUS_ACTIVE = 'ACTV';
export const CONTACT_STATUS_DECEASED = 'DESC';
export const CONTACT_STATUS_NON_PRACTISING = 'NONP';
export const CONTACT_STATUS_OVERSEAS = 'OVRS';
export const CONTACT_STATUS_RETIRED = 'RETD';

// Contact Notes
export const CONTACT_NOTE_MIN_NOTE_LENGTH = 0;
export const CONTACT_NOTE_MAX_NOTE_LENGTH = 1000;
export const CONTACT_NOTE_ROW_COUNT = 2;

export const CONTACT_TYPE_PRACTITIONER = 'P';
export const CONTACT_TYPE_EMPLOYER = 'E';
export const CONTACT_TYPE_TRAINING_PROVIDER = 'T';
export const CONTACT_TYPE_COMPANY_RENEWAL_CONTACT = 'CRC';
export const CONTACT_TYPE_UNREGISTERED_CONTACT = 'UR';

export const COMPANY_CONTACT_TYPES = [
   CONTACT_TYPE_EMPLOYER,
   CONTACT_TYPE_TRAINING_PROVIDER,
];
