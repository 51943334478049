import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

export interface PaymentMethod {
   paymentMethodCode: string;
   description: string;
   glcode: string;
}

type PaymentMethodReadonly = Readonly<PaymentMethod>;

export default PaymentMethodReadonly;

export const DefaultPaymentMethodModel: PaymentMethod = {
   paymentMethodCode: '',
   description: '',
   glcode: '',
};

interface ValidationModel {
   paymentMethodCode: boolean;
   description: boolean;
   glcode: boolean;
}

export type PaymentMethodValidation = Readonly<ValidationModel>;

export const validatePaymentMethod = (
   paymentMethod: PaymentMethod
): PaymentMethodValidation => {
   return {
      paymentMethodCode:
         !isNullOrWhiteSpace(paymentMethod.paymentMethodCode) &&
         paymentMethod.paymentMethodCode.length <= 4,
      description: !isNullOrWhiteSpace(paymentMethod.description),
      glcode: !isNullOrWhiteSpace(paymentMethod.glcode),
   };
};
