import React from 'react';
import SearchResults from './SearchResults';
import AddResults from './AddResults';
import './Results.scss';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';

const resultTabs: readonly RoutedTab[] = [
   {
      label: 'Search',
      route: '',
      component: SearchResults,
      pageTitle: 'Search',
      exact: true,
      permissions: 'CPD.Read',
   },
   {
      label: 'Add Result(s)',
      route: '/add-results',
      component: AddResults,
      pageTitle: 'Add Result(s)',
      permissions: 'CPD.Create',
   },
];

const Results = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={resultTabs}
         baseUrl="/cpd/results"
         panelClassName="results"
         pageTitle="Results"
      />
   );
};

export default Results;
