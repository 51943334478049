export const MAX_FILE_SIZE_IN_MB = 8;
const MEGABYTE_BYTES = 1024 * 1024;
export const MAX_FILE_SIZE_IN_BYTE = MEGABYTE_BYTES * MAX_FILE_SIZE_IN_MB;
export const FILE_EXTENSION = ['jpg', 'jpeg', 'png'];

export const MIN_PHOTO_WIDTH = 900;
export const MAX_PHOTO_WIDTH = 4500;
export const MIN_PHOTO_HEIGHT = 1200;
export const MAX_PHOTO_HEIGHT = 6000;
export const ZOOM_MIN = 1;
export const ZOOM_MAX = 5;
export const ZOOM_INCREMENT = 0.2;
export const ROTATE_INCREMENT = 90;
export const INITIAL_HEIGHT = 277;
export const INITIAL_WIDTH = 232;
export const BACK_COLOR = [255, 255, 255, 0.6];
