import React from 'react';
import { SelectField } from 'react-md/lib/SelectFields';
import { InputProps } from '../Shared/InputProps';
import { MenuItemList } from 'react-md/lib/SelectFields/SelectField';

interface CityInputProps extends InputProps {
   menuClassName?: string;
   onChange: (val: string) => void;
   listDropdown: MenuItemList;
   value: string;
}

const CityInput = ({
   id,
   label,
   placeholder,
   className,
   errorText,
   menuClassName,
   required,
   listDropdown,
   value,
   onChange,
}: Readonly<CityInputProps>): JSX.Element => {
   return (
      <SelectField
         floating
         id={id}
         label={label}
         placeholder={placeholder}
         className={className}
         errorText={errorText}
         value={value}
         menuItems={listDropdown}
         menuClassName={menuClassName}
         required={required}
         onChange={(val): void => onChange(val.toString())}
      />
   );
};

export default CityInput;
