import React, { useEffect } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { SelectField } from 'react-md/lib/SelectFields';
import {
   LicenceSearchQuery,
   DefaultSearchModel,
   validateSearchQuery,
   isValidSearchQuery,
   filterToValidQuery,
} from 'Models/Licence/Dto/LicenceSearchQuery';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import Contact from 'Models/Contact/Data/Contact';
import {
   useLicenceTypeList,
   useLicenceStatusList,
} from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import SupervisionInputLite from 'Components/Common/SupervisionInputLite/SupervisionInputLite';

interface SearchPanelProps {
   query: LicenceSearchQuery;
   contact: Contact;
   setQuery: (query: LicenceSearchQuery) => void;
   setIsSearching: (isSearching: boolean) => void;
   reset: () => void;
   isSearching: boolean;
}

const SearchPanel = ({
   contact,
   query,
   setQuery,
   setIsSearching,
   reset,
   isSearching,
}: Readonly<SearchPanelProps>): JSX.Element => {
   const licenceStatuses = useLicenceStatusList('Select Licence Status');

   const validation = validateSearchQuery(query);
   const filteredQuery = filterToValidQuery(query);
   const canSearch = isValidSearchQuery(filteredQuery);
   const licenceTypes = useLicenceTypeList('Select Licence Type');

   useEffect((): void => {
      setQuery({
         ...DefaultSearchModel,
         registrationNumber: contact.registrationNumber.toString(),
      });
   }, [contact, setQuery]);

   return (
      <div className="md-grid md-cell--12 invoice-search-panel">
         <div className="md-cell md-cell--6">
            <SelectField
               id="select-field-6"
               label="Licence Type"
               placeholder="Select Licence Type"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.licenceTypeCode
               )}
               menuItems={licenceTypes}
               position={SelectField.Positions.BELOW}
               value={query.licenceTypeCode}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, licenceTypeCode: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.licenceTypeCode}
               errorText="Invalid licence type"
               defaultValue=" "
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               id="licence-request-status-select-field"
               label="Licence Status"
               placeholder="Select Status"
               className="md-cell md-cell--12"
               menuItems={licenceStatuses}
               value={query.licenceStatusCode}
               position={SelectField.Positions.BELOW}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     licenceStatusCode: value.toString(),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="licence-search-start-date"
               label="Start Date"
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     startDate: value.toString(),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               value={query.startDate}
               className="md-cell md-cell--12"
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="licence-search-end-date"
               label="Expiry Date"
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     endDate: value.toString(),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               value={query.endDate}
               className="md-cell md-cell--12"
            />
         </div>
         <div className="md-cell md-cell--6">
            <SupervisionInputLite
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     supervisorRegistrationNumber: value ?? '',
                  });
               }}
               value={
                  query.supervisorRegistrationNumber !== ''
                     ? undefined
                     : query.supervisorRegistrationNumber
               }
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <Button
               flat
               disabled={isSearching}
               secondary
               className="md-cell md-cell--4 red-btn search-button"
               swapTheming
               onClick={(): void => {
                  setIsSearching(false);
                  setQuery({
                     ...DefaultSearchModel,
                     registrationNumber: contact.registrationNumber.toString(),
                  });
                  reset();
               }}
               onKeyUp={(event): void => {
                  if (!isEnterKeyPress(event)) return;

                  setIsSearching(false);
                  setQuery({
                     ...DefaultSearchModel,
                     registrationNumber: contact.registrationNumber.toString(),
                  });
                  reset();
               }}
            >
               Reset
            </Button>
            <Button
               flat
               primary
               swapTheming
               disabled={!canSearch || isSearching}
               className="md-cell md-cell--4 search-button btn-right"
               onClick={(): void => {
                  setIsSearching(true);
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
            >
               Search
            </Button>
         </div>
      </div>
   );
};

export default SearchPanel;
