import React, { useState, useCallback } from 'react';
import { Column, SortingRule, CellInfo } from 'react-table';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';
import { useSelector, useDispatch } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import FeeReadonly from 'Models/FeeMaintenance/Dto/Fee';
import {
   FeeMaintenanceTypes,
   FeeMaintenanceActions,
} from 'State/Redux/FeeMaintenanceRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import {
   NAMED_DATE_FORMAT,
   DISPLAY_DATE_FORMAT,
   PERCENTAGE_FORMAT,
   AMOUNT_FORMAT,
   CARD_PAYMENT_FEE,
} from 'Util/Constants/Common';
import Moment from 'moment-timezone';
import DateInput from 'Components/Common/DateInput/DateInput';
import moment from 'moment-timezone';
import {
   isValidEndDateAfterStartDate,
   isValidDate,
} from 'Util/Helpers/Validation';

interface ResultsPanelProps {
   searchResults: readonly FeeReadonly[];
   isLoading: boolean;
}

const FeeMaintenanceResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const dispatch = useDispatch();
   const [editTarget, setEditTarget] = useState<FeeReadonly | null>(null);
   const isSuccess = useSelector(
      selectIsSuccess([FeeMaintenanceTypes.ADD_FEE_MAINTENANCE_REQUEST])
   );

   const [editEndDate, setEditEndDate] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditEndDateCallback = useCallback((value: string) => {
      setEditEndDate(value);
   }, []);
   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   if (updateClicked && isSuccess !== null) {
      toast.success(`Fee updated successfully`);
      setUpdateClicked(false);
   }

   const DEFAULT_SORTING: SortingRule[] = [
      { id: 'applicationTypeCodeNavigation.description', desc: false },
   ];

   const COLUMN_HEADERS: Column<FeeReadonly>[] = [
      {
         Header: 'Application',
         accessor: 'applicationTypeCodeNavigation.description',
         width: 380,
      },
      {
         Header: 'Fee Type',
         accessor: 'feeTypeCodeNavigation.description',
         width: 300,
      },
      {
         Header: 'Amount or %',
         accessor: 'feeAmount',
         width: 100,
         Cell: (cell: CellInfo): JSX.Element => {
            if (cell.original.feeTypeCode === CARD_PAYMENT_FEE) {
               return <>{PERCENTAGE_FORMAT + cell.original.feeAmount}</>;
            } else {
               return <>{AMOUNT_FORMAT + cell.original.feeAmount}</>;
            }
         },
      },
      {
         Header: 'Status',
         accessor: 'status',
         width: 100,
         Cell: (cell: CellInfo): JSX.Element => {
            return (
               <>
                  {!cell.original.endDate ||
                  Moment(cell.original.endDate).endOf('day') >=
                     Moment().endOf('day')
                     ? 'Current'
                     : 'Past'}
               </>
            );
         },
      },
      {
         Header: 'Start Date',
         accessor: 'startDate',
         Cell: (cell: CellInfo): JSX.Element => {
            return (
               <>{Moment(cell.original.startDate).format(NAMED_DATE_FORMAT)}</>
            );
         },
      },
      {
         Header: 'End Date',
         accessor: 'endDate',
         Cell: (cell: CellInfo): JSX.Element => {
            return (
               <>
                  {cell.original.endDate
                     ? Moment(cell.original.endDate).format(NAMED_DATE_FORMAT)
                     : ''}
               </>
            );
         },
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditEndDateCallback(cellInfo.original.endDate);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditEndDateCallback(cellInfo.original.endDate);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   const dateValid = useCallback(
      (endDate: string, startDate: string): boolean => {
         return (
            endDate === '' ||
            endDate === null ||
            (isValidDate(endDate) &&
               isValidEndDateAfterStartDate(
                  moment(startDate).toDate(),
                  moment(endDate).toDate()
               ))
         );
      },
      []
   );

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit Fee"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 system-parameter-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Application</h3>
                  <TextField
                     floating
                     id="application"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={
                        editTarget?.applicationTypeCodeNavigation?.description
                     }
                     placeholder="Application"
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Fee Type</h3>
                  <TextField
                     floating
                     id="fee-type"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editTarget?.feeTypeCodeNavigation?.description}
                     placeholder="Fee Type"
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Amount or %</h3>
                  <TextField
                     floating
                     id="amount"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editTarget?.feeAmount}
                     placeholder="Amount"
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Start Date</h3>
                  <TextField
                     floating
                     id="start-date"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={moment(editTarget?.startDate).format(
                        DISPLAY_DATE_FORMAT
                     )}
                     placeholder="Start Date"
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">End Date</h3>
                  <DateInput
                     id="end-date"
                     className="md-cell--12 form-input"
                     errorText="Please select a valid end date"
                     error={
                        !dateValid(
                           editEndDate,
                           editTarget?.startDate ? editTarget?.startDate : ''
                        )
                     }
                     value={editEndDate}
                     onChange={(value): void => {
                        setEditEndDateCallback(value.toString());
                     }}
                     strict
                  />
               </div>
               <Button
                  disabled={
                     !dateValid(
                        editEndDate,
                        editTarget?.startDate ? editTarget?.startDate : ''
                     )
                  }
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           FeeMaintenanceActions.updateFeeMaintenanceRequest({
                              ...editTarget,
                              endDate: editEndDate,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           FeeMaintenanceActions.updateFeeMaintenanceRequest({
                              ...editTarget,
                              endDate: editEndDate,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
            TrComponent={TableRowLink}
         />
      </>
   );
};

export default FeeMaintenanceResultsPanel;
