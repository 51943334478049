import React from 'react';
import { ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import { Grid, Cell } from 'react-md/lib/Grids';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import { FinancialExtractResultItem } from 'Models/BackOffice/FinancialExtractResultItem';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface FinancialExtractResults {
   searchResults: readonly FinancialExtractResultItem[];
}

const FinancialExtractResults = ({
   searchResults,
}: Readonly<FinancialExtractResults>): JSX.Element => {
   return (
      <ExpansionPanel
         label="Search Results"
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
      >
         <Grid>
            <Cell className="table-headings" size={3}>
               Payee Name
            </Cell>
            <Cell className="table-headings" size={2}>
               Payment Type
            </Cell>
            <Cell className="table-headings" size={2}>
               Bank
            </Cell>
            <Cell className="table-headings" size={2}>
               Reference
            </Cell>
            <Cell className="table-headings" size={3}>
               Amount to Pay
            </Cell>
         </Grid>
         {searchResults.length > 0 ? (
            searchResults.map((value, index) => {
               return (
                  <Grid key={index}>
                     <Cell size={3} key={index}>
                        {value.payee}
                     </Cell>
                     <Cell size={2} key={index}>
                        {value.paymentMethodDescription}
                     </Cell>
                     <Cell size={2} key={index}>
                        {value.issuingBank}
                     </Cell>
                     <Cell size={2} key={index}>
                        {value.reference}
                     </Cell>
                     <Cell size={3} key={index}>
                        {formatCurrencyString(value.amount)}
                     </Cell>
                  </Grid>
               );
            })
         ) : (
            <div className="col l12 no-row">No rows found</div>
         )}
      </ExpansionPanel>
   );
};

export default FinancialExtractResults;
