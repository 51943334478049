interface ContactPhotoReview {
   contactPhotoId: number;
   approved: boolean;
   rejectReason: string;
}

export default ContactPhotoReview;

export const DefaultContactPhotoReview: ContactPhotoReview = {
   contactPhotoId: 0,
   approved: false,
   rejectReason: '',
};
