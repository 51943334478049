import React from 'react';
import SelectField from 'react-md/lib/SelectFields';
import { useGasExemptionType } from 'Util/Constants/GasExemption';
import { ConditionValueProps } from './ConditionValueProps';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ConditionGasExemptionProps {
   onChange?: (val: string | number) => void;
}

export const ConditionGasExemption = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
}: Readonly<ConditionGasExemptionProps & ConditionValueProps>): JSX.Element => {
   const gasExemptionType = useGasExemptionType();

   return (
      <SelectField
         floating
         id={`app-${1}-${conditionCode}`}
         lineDirection="center"
         className="md-cell md-cell--12"
         label={description}
         inputClassName={getInputValidationClassName(!isChecked)}
         menuItems={gasExemptionType}
         position={SelectField.Positions.BELOW}
         value={textValue}
         defaultValue=" "
         required
         error={!isChecked}
         errorText="Please select a gas exemption class"
         disabled={disabled}
         onChange={onChange}
      />
   );
};
