import React from 'react';
import { Button } from 'react-md/lib/Buttons';
import './PendingRenewalApplication.scss';
import PendingApplicationResponse from 'Models/Application/Data/PendingApplicationResponse';
import { Column, CellInfo } from 'react-table';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectApplicationPendingRenewal,
   ApplicationTypes,
   ApplicationActions,
} from 'State/Redux/ApplicationRedux';
import { ClientSideTable } from '../ClientSideTable/ClientSideTable';
import Loading from '../Loading';
import { selectIsError, selectIsLoading } from 'State/Redux/AsyncRedux';
import { selectContact } from 'State/Redux/ContactRedux';
import { CheckSyncIcon } from '../TableIcons/CheckSyncIcon';
import {
   FEE_REQUEST_UNPAID_DESC,
   FEE_REQUEST_PART_PAID_DESC,
} from 'Util/Constants/FeeRequestStatus';
import { CANCELLED } from 'Util/Constants/PaymentStatus';

const TitledCell = (cell: CellInfo): JSX.Element => {
   if (!cell.value) return <>-</>;
   return <span title={cell.value}>{cell.value}</span>;
};

const ApplicationsPanel = (): JSX.Element => {
   const pendingApplications = useSelector(selectApplicationPendingRenewal);

   const isLoading = useSelector(
      selectIsLoading([
         ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST,
      ])
   );
   const isError = useSelector(
      selectIsError([ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST])
   );
   const contact = useSelector(selectContact);

   const dispatch = useDispatch();

   const history = useHistory();

   const COLUMN_HEADERS: Column<PendingApplicationResponse>[] = [
      {
         Header: 'Licence Year',
         accessor: 'licenceYear',
         sortable: false,
         width: 145,
      },
      {
         Header: 'Application Type',
         accessor: 'applicationType',
         sortable: false,
         width: 370,
         Cell: TitledCell,
      },
      {
         Header: 'CPD',
         accessor: 'cpd',
         sortable: false,
         width: 90,
         Cell: CheckSyncIcon,
      },
      {
         Header: 'Details',
         accessor: 'details',
         sortable: false,
         width: 90,
         Cell: CheckSyncIcon,
      },
      {
         Header: 'Payment',
         accessor: 'payment',
         sortable: false,
         width: 90,
         Cell: CheckSyncIcon,
      },
      {
         Header: 'Nominated Supervisor',
         accessor: 'nominatedSupervisor',
         sortable: false,
         Cell: TitledCell,
      },
      {
         Header: 'Supervision Approval',
         accessor: 'supervisionApproval',
         sortable: false,
         Cell: CheckSyncIcon,
      },
      {
         Header: 'Fit & Proper',
         accessor: 'fitToPractice',
         sortable: false,
         Cell: CheckSyncIcon,
      },
      {
         Header: 'Supervisor Current',
         accessor: 'supervisorCurrent',
         sortable: false,
         Cell: CheckSyncIcon,
      },
      {
         Header: '',
         accessor: 'payment',
         sortable: false,
         width: 135,
         Cell: (cellInfo): JSX.Element => {
            const returnLink = `&returnUrl=${history.location.pathname}`;

            // Send the payment Id only if the payment record exist (or) Send the FeeRequest Id
            const hasPayment =
               cellInfo.value !== FEE_REQUEST_UNPAID_DESC &&
               cellInfo.value !== FEE_REQUEST_PART_PAID_DESC &&
               cellInfo.original.payment &&
               cellInfo.original.payment.paymentId;

            const isCancelledPayment =
               cellInfo.original &&
               cellInfo.original.payment &&
               cellInfo.original.payment.paymentId &&
               cellInfo.original.payment.paymentStatusCode === CANCELLED;

            const applicationIdUrl = cellInfo.original.applicationID
               ? `&applicationId=${cellInfo.original.applicationID}`
               : '';

            const paymentUrl =
               hasPayment || isCancelledPayment
                  ? `/payment?paymentId=${cellInfo.original.payment.paymentId}${applicationIdUrl}${returnLink}`
                  : `/payment?feeRequestId=${cellInfo.original.feeRequestId}${applicationIdUrl}${returnLink}`;

            return cellInfo.value ? (
               <></>
            ) : (
               <Link to={paymentUrl}>
                  <Button className="btn-small" flat primary swapTheming>
                     Pay Now
                  </Button>
               </Link>
            );
         },
      },
   ];

   if (!pendingApplications || !pendingApplications.length) return <></>;

   return (
      <Loading
         isLoading={isLoading}
         tryReload={(): void => {
            dispatch(
               ApplicationActions.getApplicationPendingRenewalRequest(
                  contact.contactId.toString()
               )
            );
         }}
         isError={isError}
      >
         <ClientSideTable
            data={[...pendingApplications]}
            className="PendingRenewalApplication"
            columns={COLUMN_HEADERS}
            showPagination={false}
            pageSize={pendingApplications.length}
         />
      </Loading>
   );
};

export default ApplicationsPanel;
