export type EnvironmentName = 'development' | 'test' | 'uat' | 'production';

export const DEV_ENV = 'development';
export const TEST_ENV = 'test';
export const UAT_ENV = 'uat';
export const PROD_ENV = 'production';

export const NON_PROD_ENV: readonly string[] = [
   DEV_ENV,
   TEST_ENV,
   UAT_ENV,
] as EnvironmentName[];
