import React from 'react';
import ReactTable, { TableProps, ReactTableDefaults } from 'react-table';
import TableLoadingComponent from 'Components/Common/TableLoadingComponent/TableLoadingComponent';

//eslint-disable-next-line
interface ClientSideTableProps<T = any>
   extends Partial<
      Pick<
         TableProps<T>,
         | 'className'
         | 'columns'
         | 'defaultPageSize'
         | 'defaultSorted'
         | 'getTdProps'
         | 'getTrProps'
         | 'loading'
         | 'minRows'
         | 'noDataText'
         | 'pages'
         | 'pageSize'
         | 'showPageJump'
         | 'showPageSizeOptions'
         | 'showPagination'
         | 'showPaginationTop'
         | 'SubComponent'
         | 'TrComponent'
      >
   > {
   data: readonly T[];
}

export const ClientSideTable = ({
   className,
   columns,
   data,
   defaultPageSize,
   defaultSorted,
   getTdProps,
   getTrProps,
   loading,
   minRows,
   noDataText,
   pages,
   pageSize,
   showPageJump,
   showPageSizeOptions,
   showPagination,
   showPaginationTop,
   SubComponent,
   TrComponent,
}: Readonly<ClientSideTableProps>): JSX.Element => {
   return (
      <ReactTable
         data={[...data]}
         showPaginationTop={showPaginationTop}
         multiSort={false}
         LoadingComponent={
            TableLoadingComponent || ReactTableDefaults.LoadingComponent
         }
         className={className || '-striped -highlight'}
         columns={columns}
         defaultPageSize={defaultPageSize || 20}
         defaultSorted={defaultSorted}
         getTdProps={getTdProps}
         getTrProps={getTrProps}
         loading={loading}
         minRows={minRows}
         noDataText={noDataText}
         pages={pages}
         pageSize={pageSize}
         showPageJump={showPageJump}
         showPageSizeOptions={showPageSizeOptions}
         showPagination={showPagination}
         SubComponent={SubComponent}
         TrComponent={TrComponent || ReactTableDefaults.TrComponent}
      />
   );
};
