export const RegistrationApplicationTypes: readonly string[] = [
   'DR', // tradesman
   'GR', // tradesman
   'PR', // tradesman
   'PC', // certifying
   'GC', // certifying
   'DC', // certifying
   'PJ', // journeyman
   'GJ', // journeyman
   'DJ', // journeyman
   'TMCD', // ttmra certifying
   'TMCG', // ttmra certifying
   'TMCP', // ttmra certifying
   'TMD', // ttmra tradesman
   'TMG', // ttmra tradesman
   'TMP', // ttmra tradesman
   'AE57', //exemption specific gasfitting
];

export const TRADESMAN_DRAINLAYER = 'DR';
export const TRADESMAN_GASFITTER = 'GR';
export const TRADESMAN_PLUMBER = 'PR';
export const JOURNEYMAN_DRAINLAYER = 'DJ';
export const JOURNEYMAN_GASFITTER = 'GJ';
export const JOURNEYMAN_PLUMBER = 'PJ';
