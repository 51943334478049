import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

export interface FeeType {
   feeTypeCode: string;
   description: string;
   glcode: string;
   isGstinclusive: boolean;
   isDeleted: boolean;
   descriptionRequired: boolean;
}

type FeeTypeReadonly = Readonly<FeeType>;

export default FeeTypeReadonly;

export const DefaultFeeTypeModel: FeeType = {
   feeTypeCode: '',
   description: '',
   glcode: '',
   isDeleted: false,
   isGstinclusive: false,
   descriptionRequired: false,
};

interface ValidationModel {
   feeTypeCode: boolean;
   description: boolean;
   glcode: boolean;
   isDeleted: boolean;
   isGstinclusive: boolean;
   descriptionRequired: boolean;
}

export type FeeTypeValidation = Readonly<ValidationModel>;

export const validateFeeTypes = (feeType: FeeType): FeeTypeValidation => {
   return {
      feeTypeCode:
         !isNullOrWhiteSpace(feeType.feeTypeCode) &&
         feeType.feeTypeCode.length <= 4,
      description: !isNullOrWhiteSpace(feeType.description),
      glcode: !isNullOrWhiteSpace(feeType.glcode),
      descriptionRequired: true,
      isGstinclusive: true,
      isDeleted: true,
   };
};
