import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import RegistrationTypeReadonly, {
   RegistrationType,
} from 'Models/RegistrationType/Dto/RegistrationType';

export interface RegistrationTypeServiceType {
   updateRegistrationType(
      registrationType: RegistrationTypeReadonly
   ): Promise<ApiResponse<readonly RegistrationTypeReadonly[]>>;
   getRegistrationType(): Promise<ApiResponse<readonly RegistrationType[]>>;
   addRegistrationType(
      registrationType: RegistrationTypeReadonly
   ): Promise<ApiResponse<RegistrationTypeReadonly>>;
}

const create = (baseURL: string | undefined): RegistrationTypeServiceType => {
   const api = createApi({ baseURL });

   const updateRegistrationType = (
      registrationType: RegistrationTypeReadonly
   ): Promise<ApiResponse<readonly RegistrationTypeReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/RegistrationType`, registrationType);
   };

   const getRegistrationType = (): Promise<ApiResponse<
      readonly RegistrationType[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/RegistrationType`);
   };

   const addRegistrationType = (
      registrationType: RegistrationTypeReadonly
   ): Promise<ApiResponse<RegistrationTypeReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/RegistrationType`, registrationType);
   };

   return {
      updateRegistrationType,
      getRegistrationType,
      addRegistrationType,
   };
};

export default {
   create,
};
