import { put, call } from 'redux-saga/effects';
import { LetterActions } from '../Redux/LetterRedux';
import { LetterServiceType } from '../Services/LetterService';
import { ApiResponse } from 'apisauce';
import LetterReadonly from 'Models/Letters/Data/Letter';
import FileDtoReadonly from 'Models/Letters/Dto/FileDto';

interface LetterParams {
   type: string;
   contactId: string;
   letterId: string;
}

export function* getContactLetter(
   letterService: Readonly<LetterServiceType>,
   action: Readonly<LetterParams>
): Generator {
   const { contactId, letterId } = action;
   const letterResponse = yield call(
      letterService.getContactLetter,
      contactId,
      letterId
   );
   const response = letterResponse as ApiResponse<FileDtoReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(LetterActions.getContactLetterSuccess(response.data));
   } else {
      yield put(
         LetterActions.getContactLetterFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getLetters(
   letterService: Readonly<LetterServiceType>
): Generator {
   const letterResponse = yield call(letterService.getLetters);
   const response = letterResponse as ApiResponse<LetterReadonly[]>;
   if (response.status && response.ok) {
      yield put(LetterActions.getLettersSuccess(response.data));
   } else {
      yield put(
         LetterActions.getLettersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
