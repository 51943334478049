import React, { useState, useCallback } from 'react';
import { Column, SortingRule } from 'react-table';
import { useSelector, useDispatch } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import LicenceTypeReadonly, {
   validateLicenceTypes,
} from 'Models/LicenceType/Data/LienceType';
import {
   LicenceTypeTypes,
   LicenceTypeActions,
} from 'State/Redux/LicenceTypeRedux';
import { Checkbox } from 'react-md/lib/SelectionControls';

interface ResultsPanelProps {
   searchResults: readonly LicenceTypeReadonly[];
   isLoading: boolean;
}

const LicenceTypeResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const [editTarget, setEditTarget] = useState<LicenceTypeReadonly | null>(
      null
   );
   const isSuccess = useSelector(
      selectIsSuccess([LicenceTypeTypes.UPDATE_LICENCE_TYPE_REQUEST])
   );

   const [editCode, setEditCode] = useState('');
   const [editDescription, setEditDescription] = useState('');
   const [editAudited, setEditAudited] = useState(false);
   const [editWebRegister, setEditWebRegister] = useState(false);
   const [editDisciplineCode, setEditDisciplineCode] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditCodeCallback = useCallback((value: string) => {
      setEditCode(value);
   }, []);
   const setEditDescriptionCallback = useCallback((value: string) => {
      setEditDescription(value);
   }, []);
   const setEditAuditedCallback = useCallback((value: boolean) => {
      setEditAudited(value);
   }, []);
   const setEditWebRegisterCallback = useCallback((value: boolean) => {
      setEditWebRegister(value);
   }, []);
   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   const dispatch = useDispatch();

   if (updateClicked && isSuccess !== null) {
      toast.success(`Licence Type updated successfully`);
      setUpdateClicked(false);
   }
   const isValidForm = editTarget
      ? Object.values(
           validateLicenceTypes({
              ...editTarget,
              licenceTypeCode: editCode,
              description: editDescription,
              isAudited: editAudited,
              includeInWebRegister: editWebRegister,
              disciplineCode: editDisciplineCode,
              disciplineDescription: '',
           })
        ).every((v): boolean => !!v)
      : false;

   const DEFAULT_SORTING: SortingRule[] = [
      { id: 'licenceTypeCode', desc: false },
   ];

   const COLUMN_HEADERS: Column<LicenceTypeReadonly>[] = [
      {
         Header: 'Code',
         accessor: 'licenceTypeCode',
      },
      {
         Header: 'Description',
         accessor: 'description',
      },
      {
         Header: 'Discipline',
         accessor: 'disciplineDescription',
      },
      {
         Header: 'Web Register',
         accessor: 'includeInWebRegister',
         width: 150,
         className: 'center-icon',
         Cell: (cellInfo): JSX.Element => {
            if (cellInfo.value) {
               return <FaIcon className="small-icon" icon="check" />;
            }

            return <FaIcon className="small-icon" icon="times" />;
         },
      },
      {
         Header: 'Audited',
         accessor: 'isAudited',
         width: 150,
         className: 'center-icon',
         Cell: (cellInfo): JSX.Element => {
            if (cellInfo.value) {
               return <FaIcon className="small-icon" icon="check" />;
            } else {
               return <FaIcon className="small-icon" icon="times" />;
            }
         },
      },

      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: 'licenceTypeCode',
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.licenceTypeCode);
                     setEditDescription(cellInfo.original.description);
                     setEditAudited(cellInfo.original.isAudited);
                     setEditWebRegister(cellInfo.original.includeInWebRegister);
                     setEditDisciplineCode(cellInfo.original.disciplineCode);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCode(cellInfo.original.licenceTypeCode);
                     setEditDescription(cellInfo.original.description);
                     setEditAudited(cellInfo.original.isAudited);
                     setEditWebRegister(cellInfo.original.includeInWebRegister);
                     setEditDisciplineCode(cellInfo.original.disciplineCode);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit Licence Type"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 licence-type-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Code</h3>
                  <TextField
                     floating
                     id="licence-type-code"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCode}
                     required
                     placeholder="Code"
                     onChange={(value): void => {
                        setEditCodeCallback(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Description</h3>
                  <TextField
                     floating
                     id="licence-type-description"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editDescription}
                     required
                     placeholder="Description"
                     onChange={(value): void => {
                        setEditDescriptionCallback(value.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <Checkbox
                     id="is-audited"
                     className="md-cell md-cell--12"
                     label="Audited"
                     checked={editAudited}
                     name="is-audited"
                     onChange={(val): void => {
                        setEditAuditedCallback(val);
                     }}
                  />
                  <Checkbox
                     id="web-register"
                     className="md-cell md-cell--12"
                     label="Include in Web Register"
                     checked={editWebRegister}
                     name="web-register"
                     onChange={(val): void => {
                        setEditWebRegisterCallback(val);
                     }}
                  />
               </div>
               <Button
                  disabled={!isValidForm}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           LicenceTypeActions.updateLicenceTypeRequest({
                              ...editTarget,
                              licenceTypeCode: editCode,
                              description: editDescription,
                              isAudited: editAudited,
                              includeInWebRegister: editWebRegister,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           LicenceTypeActions.updateLicenceTypeRequest({
                              ...editTarget,
                              licenceTypeCode: editCode,
                              description: editDescription,
                              isAudited: editAudited,
                              includeInWebRegister: editWebRegister,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
         />
      </>
   );
};

export default LicenceTypeResultsPanel;
