import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ExaminationDto from 'Models/Examination/Dto/ExaminationLimitedDto';
import ApiError from 'Models/Other/ApiError';
import ExaminationEligibilityDto from 'Models/Examination/Dto/ContactExamEligibilityDto';
import { groupBy, orderBy } from 'lodash-es';
import ContactExamEligibilityOtherNotesDtoReadonly, {
   DefaultContactExamEligibilityOtherNotesDtoModel,
} from 'Models/Examination/Data/ContactExamEligibilityOtherNotesDto';
import produce from 'immer';
import { CRITERIA_NUMBERS } from 'Util/Constants/Examination';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ExaminationState {
   examination: ExaminationDto | null;
   examinations: readonly ExaminationDto[];
   examinationEligibility: ExaminationEligibilityDto[];
   examinationEligibilityReport?: Blob;
   examinationEligibilityReportSent: boolean | null;
   contactExaminationEligibilityOtherNotes: ContactExamEligibilityOtherNotesDtoReadonly[];
}

export type ExaminationStateReadonly = Readonly<ExaminationState>;

interface TypeNames {
   GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_REQUEST: string;
   GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_SUCCESS: string;
   GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_FAILURE: string;

   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_REQUEST: string;
   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_SUCCESS: string;
   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_FAILURE: string;

   GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_REQUEST: string;
   GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_SUCCESS: string;
   GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_FAILURE: string;

   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTES_REQUEST: string;
   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTES_SUCCESS: string;
   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTES_FAILURE: string;

   GET_USER_EXAMINATION_ELIGIBILITY_REPORT_BY_CONTACT_ID_REQUEST: string;
   GET_USER_EXAMINATION_ELIGIBILITY_REPORT_BY_CONTACT_ID_SUCCESS: string;
   GET_USER_EXAMINATION_ELIGIBILITY_REPORT_BY_CONTACT_ID_FAILURE: string;

   SEND_EXAMINATION_ELIGIBILITY_REPORT_ASPEQ_REQUEST: string;
   SEND_EXAMINATION_ELIGIBILITY_REPORT_ASPEQ_SUCCESS: string;
   SEND_EXAMINATION_ELIGIBILITY_REPORT_ASPEQ_FAILURE: string;

   SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_REQUEST: string;
   SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_SUCCESS: string;
   SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_FAILURE: string;

   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_STATE: string;
   UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTE_STATE: string;
   RESET_EXAMINATION_ELIGIBILITY_REPORT_SENT_STATE: string;
   RESET_EXAMINATION_ELIGIBILITY_REPORT_STATE: string;
}

type ExaminationTypeNames = Readonly<TypeNames>;

export interface ExaminationCreators {
   getExaminationEligibilityByContactIdRequest: (
      contactId: string
   ) => AnyAction;
   getExaminationEligibilityByContactIdSuccess: (
      data: ExaminationEligibilityDto[] | undefined
   ) => AnyAction;
   getExaminationEligibilityByContactIdFailure: (error: ApiError) => AnyAction;

   updateContactExaminationEligibilityRequest: (
      contactId: string,
      examinationEligibility: ExaminationEligibilityDto[]
   ) => AnyAction;
   updateContactExaminationEligibilitySuccess: (
      data: ExaminationEligibilityDto[]
   ) => AnyAction;
   updateContactExaminationEligibilityFailure: (error: ApiError) => AnyAction;

   getExaminationEligibilityOtherNotesByContactIdRequest: (
      contactId: string
   ) => AnyAction;
   getExaminationEligibilityOtherNotesByContactIdSuccess: (
      data: ContactExamEligibilityOtherNotesDtoReadonly[] | undefined
   ) => AnyAction;
   getExaminationEligibilityOtherNotesByContactIdFailure: (
      error: ApiError
   ) => AnyAction;

   updateContactExaminationEligibilityOtherNotesRequest: (
      contactExaminationEligibilityOtherNotes: ContactExamEligibilityOtherNotesDtoReadonly[]
   ) => AnyAction;
   updateContactExaminationEligibilityOtherNotesSuccess: (
      data: ContactExamEligibilityOtherNotesDtoReadonly[]
   ) => AnyAction;
   updateContactExaminationEligibilityOtherNotesFailure: (
      error: ApiError
   ) => AnyAction;

   getUserExaminationEligibilityReportByContactIdRequest: (
      contactId: string
   ) => AnyAction;
   getUserExaminationEligibilityReportByContactIdSuccess: (
      data: Blob | undefined
   ) => AnyAction;
   getUserExaminationEligibilityReportByContactIdFailure: (
      error: ApiError
   ) => AnyAction;

   sendExaminationEligibilityReportAspeqRequest: (
      contactId: string
   ) => AnyAction;
   sendExaminationEligibilityReportAspeqSuccess: (
      data: boolean | undefined
   ) => AnyAction;
   sendExaminationEligibilityReportAspeqFailure: (error: ApiError) => AnyAction;

   sendExaminationEligibilityReportUserRequest: (
      contactId: string
   ) => AnyAction;
   sendExaminationEligibilityReportUserSuccess: (
      data: boolean | undefined
   ) => AnyAction;
   sendExaminationEligibilityReportUserFailure: (error: ApiError) => AnyAction;

   updateContactExaminationEligibilityState: (
      examinationEligibility: ExaminationEligibilityDto
   ) => AnyAction;

   updateContactExaminationEligibilityOtherNoteState: (
      note: ContactExamEligibilityOtherNotesDtoReadonly
   ) => AnyAction;

   resetExaminationEligibilityReportState: () => AnyAction;
   resetExaminationEligibilityReportSentState: () => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ExaminationStateReadonly = {
   examination: null,
   examinations: [],
   examinationEligibility: [],
   examinationEligibilityReport: undefined,
   examinationEligibilityReportSent: null,
   contactExaminationEligibilityOtherNotes: [],
};

/* ------------- Reducers ------------- */
const getExaminationEligibilityByContactIdSuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, examinationEligibility: action.data };
};

const updateContactExaminationEligibilitySuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, examinationEligibility: action.data };
};

const getExaminationEligibilityOtherNotesByContactIdSuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, contactExaminationEligibilityOtherNotes: action.data };
};

const updateContactExaminationEligibilityOtherNotesSuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, contactExaminationEligibilityOtherNotes: action.data };
};

const sendExaminationEligibilityReportAspeqSuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, examinationEligibilityReportSent: action.data };
};

const sendExaminationEligibilityReportUserSuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, examinationEligibilityReportSent: action.data };
};

const getUserExaminationEligibilityReportByContactIdSuccess = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return { ...state, examinationEligibilityReport: action.data };
};

const updateContactExaminationEligibilityState = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return produce(state, (draftState): void => {
      const eligibility = draftState.examinationEligibility.find(
         (e): boolean =>
            e.contactExamEligibilityId ===
            action.examinationEligibility.contactExamEligibilityId
      );
      if (eligibility) {
         eligibility.isCriteriaMet =
            action.examinationEligibility.isCriteriaMet;
         eligibility.isError = action.examinationEligibility.isError;
      }
   });
};

const updateContactExaminationEligibilityOtherNoteState = (
   state: ExaminationStateReadonly,
   action: AnyAction
): ExaminationStateReadonly => {
   return produce(state, (draftState): void => {
      const note = draftState.contactExaminationEligibilityOtherNotes.find(
         (n): boolean =>
            n.contactExamEligibilityId === action.note.contactExamEligibilityId
      );
      if (note) {
         note.otherNotes = action.note.otherNotes;
      } else {
         //doesn't exist, must be a new note not in the db.
         draftState.contactExaminationEligibilityOtherNotes.push({
            ...DefaultContactExamEligibilityOtherNotesDtoModel,
            contactExamEligibilityId: action.note.contactExamEligibilityId,
            otherNotes: action.note.otherNotes,
            courseCriteriaId: action.note.courseCriteriaId,
            contactId: action.note.contactId,
         });
      }
   });
};

const resetExaminationEligibilityReportState = (
   state: ExaminationStateReadonly
): ExaminationStateReadonly => {
   return { ...state, examinationEligibilityReport: undefined };
};

const resetExaminationEligibilityReportSentState = (
   state: ExaminationStateReadonly
): ExaminationStateReadonly => {
   return { ...state, examinationEligibilityReportSent: null };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ExaminationTypeNames,
   ExaminationCreators
>('examination', {
   getExaminationEligibilityByContactIdRequest: ['contactId'],
   getExaminationEligibilityByContactIdSuccess: ['data'],
   getExaminationEligibilityByContactIdFailure: ['error'],

   updateContactExaminationEligibilityRequest: [
      'contactId',
      'examinationEligibility',
   ],
   updateContactExaminationEligibilitySuccess: ['data'],
   updateContactExaminationEligibilityFailure: ['error'],

   getExaminationEligibilityOtherNotesByContactIdRequest: ['contactId'],
   getExaminationEligibilityOtherNotesByContactIdSuccess: ['data'],
   getExaminationEligibilityOtherNotesByContactIdFailure: ['error'],

   updateContactExaminationEligibilityOtherNotesRequest: [
      'contactExaminationEligibilityOtherNotes',
   ],
   updateContactExaminationEligibilityOtherNotesSuccess: ['data'],
   updateContactExaminationEligibilityOtherNotesFailure: ['error'],

   getUserExaminationEligibilityReportByContactIdRequest: ['contactId'],
   getUserExaminationEligibilityReportByContactIdSuccess: ['data'],
   getUserExaminationEligibilityReportByContactIdFailure: ['error'],

   sendExaminationEligibilityReportAspeqRequest: ['contactId'],
   sendExaminationEligibilityReportAspeqSuccess: ['data'],
   sendExaminationEligibilityReportAspeqFailure: ['error'],

   sendExaminationEligibilityReportUserRequest: ['contactId'],
   sendExaminationEligibilityReportUserSuccess: ['data'],
   sendExaminationEligibilityReportUserFailure: ['error'],

   updateContactExaminationEligibilityState: ['examinationEligibility'],

   updateContactExaminationEligibilityOtherNoteState: ['note'],

   resetExaminationEligibilityReportState: [],
   resetExaminationEligibilityReportSentState: [],
});

export const ExaminationTypes = Types;
export const ExaminationActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_EXAMINATION_ELIGIBILITY_BY_CONTACT_ID_SUCCESS]: getExaminationEligibilityByContactIdSuccess,
   [Types.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_SUCCESS]: updateContactExaminationEligibilitySuccess,
   [Types.GET_EXAMINATION_ELIGIBILITY_OTHER_NOTES_BY_CONTACT_ID_SUCCESS]: getExaminationEligibilityOtherNotesByContactIdSuccess,
   [Types.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTES_SUCCESS]: updateContactExaminationEligibilityOtherNotesSuccess,
   [Types.GET_USER_EXAMINATION_ELIGIBILITY_REPORT_BY_CONTACT_ID_SUCCESS]: getUserExaminationEligibilityReportByContactIdSuccess,
   [Types.SEND_EXAMINATION_ELIGIBILITY_REPORT_ASPEQ_SUCCESS]: sendExaminationEligibilityReportAspeqSuccess,
   [Types.SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_SUCCESS]: sendExaminationEligibilityReportUserSuccess,
   [Types.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_STATE]: updateContactExaminationEligibilityState,
   [Types.UPDATE_CONTACT_EXAMINATION_ELIGIBILITY_OTHER_NOTE_STATE]: updateContactExaminationEligibilityOtherNoteState,
   [Types.RESET_EXAMINATION_ELIGIBILITY_REPORT_STATE]: resetExaminationEligibilityReportState,
   [Types.RESET_EXAMINATION_ELIGIBILITY_REPORT_SENT_STATE]: resetExaminationEligibilityReportSentState,
});

/* ------------- Selectors ------------- */

export const selectExamination = (
   state: StateReadonly
): ExaminationDto | null => {
   return state.examination.examination;
};

export const selectExaminations = (
   state: StateReadonly
): readonly ExaminationDto[] => {
   return state.examination.examinations;
};

export const selectExaminationEligibility = (
   state: StateReadonly
): ExaminationEligibilityDto[] | null => {
   return state.examination.examinationEligibility;
};

export const selectOrderedAndGroupedExaminationEligibility = (
   state: StateReadonly
): Record<CRITERIA_NUMBERS, ExaminationEligibilityDto[]> | null => {
   if (!state || !state.examination.examinationEligibility) return null;
   const ordered = orderBy(
      state.examination.examinationEligibility,
      e => e.courseCriteria.displayOrder
   );
   return groupBy(ordered, (c): string | null =>
      c.courseCriteria !== null && c.courseCriteria.course !== null
         ? c.courseCriteria.course.courseNumber
         : '0'
   ) as Record<CRITERIA_NUMBERS, ExaminationEligibilityDto[]>;
};

export const selectContactExamEligibilityOtherNotes = (
   state: StateReadonly
): ContactExamEligibilityOtherNotesDtoReadonly[] | null => {
   return state.examination.contactExaminationEligibilityOtherNotes;
};

export const selectUserExaminationEligibilityReport = (
   state: StateReadonly
): Blob | undefined => {
   return state.examination.examinationEligibilityReport;
};

export const selectExaminationEligibilityReportSent = (
   state: StateReadonly
): boolean | null => {
   return state.examination.examinationEligibilityReportSent;
};
