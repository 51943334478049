import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'Util/Constants/Common';

interface FinancialExtractInputModel {
   startDate?: string;
   endDate?: string;
   financialExtractTypeCode?: string;
}

export default FinancialExtractInputModel;

export type FinancialExtractSearchQuery = Readonly<FinancialExtractInputModel>;

export const DefaultFinancialExtractSearchInput: FinancialExtractInputModel = {
   startDate: '',
   endDate: moment().format(DATEPICKER_DATE_FORMAT),
   financialExtractTypeCode: '',
};

export const isValidQuery = (query: FinancialExtractSearchQuery): boolean => {
   return (
      !!query.startDate && !!query.endDate && !!query.financialExtractTypeCode
   );
};
