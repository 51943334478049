import React, { Fragment } from 'react';
import { ApplicationCondition } from 'Models/Application/Data/ApplicationCondition';
import { getFieldType } from 'Util/Helpers/Conditions';
import { ConditionCheckbox } from './ConditionCheckbox';
import { ConditionCountry } from './ConditionCountry';
import { ConditionGasExemption } from './ConditionGasExemption';
import { ConditionDatePicker } from './ConditionDatePicker';
import { ConditionLicenceYear } from './ConditionLicenceYear';
import { ConditionTrainingProvider } from './ConditionTrainingProvider';
import { ConditionSupervision } from './ConditionSupervision';
import { ConditionTextField } from './ConditionTextField';
import { ConditionF2p } from './ConditionF2p';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { ApplicationActions } from 'State/Redux/ApplicationRedux';
import { useSelector, useDispatch } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import {
   isPractitionerNumber,
   REGISTRATION_NUMBER_LENGTH,
} from 'Util/Helpers/RegistrationNumber';
import { RegistrationApplicationTypes } from 'Util/Constants/ApplicationTypes';
import { selectConditions } from 'State/Redux/MetadataRedux';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';

interface ConditionCellProps {
   application: ApplicationEdit;
   updateApplication: (application: ApplicationEdit) => void;
   appCondition: ApplicationCondition;
   disabled?: boolean;
   fitAndProper?: QuestionSaveBulkDto[];
   setFitAndProper?: (fitAndProper: QuestionSaveBulkDto[]) => void;
}

export const ConditionCell = ({
   application,
   updateApplication,
   appCondition,
   disabled,
   fitAndProper,
   setFitAndProper,
}: Readonly<ConditionCellProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const dispatch = useDispatch();

   if (!updateApplication) disabled = true;
   const conditions = useSelector(selectConditions);
   const condition = conditions.find(
      mc => mc.conditionCode === appCondition.conditionCode
   );
   if (!condition) return <Fragment key={appCondition.conditionCode} />;
   if (!appCondition) return <></>;

   if (condition.styleCode === 'SY  ') disabled = true;

   const hasCreateLicenceOption = RegistrationApplicationTypes.includes(
      application.applicationTypeCode
   );

   const updateTextCondition = (val: string | number): void => {
      const textVal = (val || '').toString();
      const conditions = application.conditions.map(
         (c): ApplicationCondition => {
            return c.conditionCode === appCondition.conditionCode
               ? {
                    ...c,
                    isChecked: !isNullOrWhiteSpace(textVal),
                    textValue: textVal,
                 }
               : c;
         }
      );
      updateApplication({ ...application, conditions });
   };

   const updateSupervisionCondition = (registrationNumber: string): void => {
      const isValidPractitioner = isPractitionerNumber(registrationNumber);
      const regNumber = isValidPractitioner ? Number(registrationNumber) : 0;
      dispatch(
         ApplicationActions.getApplicationSupervisorRequest({
            applicationType: application.applicationTypeCode,
            contactId: contact.contactId,
            licenceYear: application.licenceYear,
            registrationNumber: regNumber,
         })
      );
      dispatch(ApplicationActions.setShouldSupervisionValidate(true));
   };

   const textValue = appCondition.textValue || '';
   const fieldType = getFieldType(condition);
   const supervisionTmpText =
      fieldType === 'supervision'
         ? textValue.substring(0, REGISTRATION_NUMBER_LENGTH)
         : '';
   // handle case where default text is set for supervision conditions
   const supervisionFieldText =
      supervisionTmpText.toLowerCase() === 'super' ||
      supervisionTmpText === 'lette' ||
      supervisionTmpText.toLowerCase() === 'licen' ||
      supervisionTmpText.toLowerCase() === 'nomin'
         ? ''
         : supervisionTmpText;

   switch (fieldType) {
      case 'checkbox':
         return (
            <ConditionCheckbox
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={textValue}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={(val): void => {
                  const conditions = application.conditions.map(
                     (c): ApplicationCondition => {
                        return c.conditionCode === appCondition.conditionCode
                           ? { ...c, isChecked: val }
                           : c;
                     }
                  );
                  updateApplication({ ...application, conditions });
               }}
            />
         );
      case 'country-list':
         return (
            <ConditionCountry
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={textValue}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={updateTextCondition}
            />
         );
      case 'gas-exemption-class-list':
         return (
            <ConditionGasExemption
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={textValue}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={updateTextCondition}
            />
         );
      case 'datepicker':
         return (
            <ConditionDatePicker
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={textValue}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={updateTextCondition}
            />
         );
      case 'licence-year-list':
         return (
            <ConditionLicenceYear
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={textValue}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={updateTextCondition}
            />
         );

      case 'training-provider-list':
         return (
            <ConditionTrainingProvider
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={textValue}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={updateTextCondition}
            />
         );
      case 'supervision':
         return (
            <ConditionSupervision
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={supervisionFieldText}
               isChecked={appCondition.isChecked}
               disabled={
                  disabled ||
                  (hasCreateLicenceOption && !application.createLicence)
               }
               onChange={updateTextCondition}
               onSubmit={updateSupervisionCondition}
               application={application}
            />
         );
      case 'f2p':
         return (
            <ConditionF2p
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               isChecked={appCondition.isChecked}
               application={application}
               userId={contact.userId}
               fitAndProper={fitAndProper}
               setFitAndProper={setFitAndProper}
               disabled={true}
               textValue={''}
            />
         );
      default:
         return (
            <ConditionTextField
               conditionCode={appCondition.conditionCode}
               description={condition.description}
               textValue={appCondition.textValue || ''}
               isChecked={appCondition.isChecked}
               disabled={disabled}
               onChange={updateTextCondition}
            />
         );
   }
};
