import React from 'react';
import * as LICENCE_HELPERS from 'Util/Helpers/Licence';
import LicenceCard from 'Components/Common/LicenceCard';
import Licence, {
   hasLicences,
   hasLicencesForPeriod,
} from 'Models/Licence/Data/LicenceGroup';
import LicenceInformation from 'Models/Licence/Data/LicenceInformation';

interface LicenceCardsProps {
   licences: readonly LicenceInformation[];
}

const LicenceCards = ({
   licences,
}: Readonly<LicenceCardsProps>): JSX.Element => {
   return (
      <>
         {licences.map(
            (licence, i): JSX.Element => {
               const level = LICENCE_HELPERS.getLicenceLevel(licence.typeCode);

               return (
                  <LicenceCard
                     key={i}
                     level={level}
                     discipline={licence.trade}
                     status={licence.status}
                     expiryDate={licence.expiryDate}
                  />
               );
            }
         )}
         {licences.length === 0 && (
            <span className="no-licence">No Licence information available</span>
         )}
      </>
   );
};

const NoLicences = (): JSX.Element => {
   return <p>No current or future licences available.</p>;
};

interface CurrentLicencesPanelProps {
   licence: Licence;
}

const CurrentLicencesPanel = ({
   licence,
}: Readonly<CurrentLicencesPanelProps>): JSX.Element => {
   const hasCurrentLicences = hasLicencesForPeriod(licence.currentData);
   const hasFutureLicences =
      licence &&
      licence.nextData &&
      licence.nextLicences &&
      (licence.nextLicences.length ? true : false);
   const noLicences = !hasLicences(licence);

   return (
      <div className="md-grid md-cell--12">
         {hasCurrentLicences && (
            <div className="md-cell md-cell--6">
               <div className="md-grid md-cell--12 center-cards">
                  <LicenceCards licences={licence.currentLicences} />
               </div>
               <h1 className="year-footer">
                  {licence.currentData.expires &&
                     LICENCE_HELPERS.getYearRange(licence.currentData.expires)}
               </h1>
            </div>
         )}
         {hasFutureLicences && (
            <div className="md-cell md-cell--6">
               <div className="md-grid md-cell--12 center-cards">
                  <LicenceCards licences={licence.nextLicences} />
               </div>
               <h1 className="year-footer">
                  {licence.currentData.expires &&
                     (licence.nextData.expires
                        ? LICENCE_HELPERS.getYearRange(licence.nextData.expires)
                        : LICENCE_HELPERS.getYearRange(
                             licence.currentData.expires
                          ))}
               </h1>
            </div>
         )}
         {noLicences && <NoLicences />}
      </div>
   );
};

export default CurrentLicencesPanel;
