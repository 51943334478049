import React, { useState, useEffect } from 'react';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import { Column, SortingRule, CellInfo } from 'react-table';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import Moment from 'moment-timezone';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { useSelector, useDispatch } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import { selectIsSuccess, selectIsLoading } from 'State/Redux/AsyncRedux';
import {
   ReceiptTypes,
   selectReceipt,
   ReceiptActions,
} from 'State/Redux/ReceiptRedux';
import LoadingButton from 'Components/Common/LoadingButton';
import ReceiptRequestReadOnly from 'Models/Payment/Data/ReceiptRequest';
import FileSaver from 'file-saver';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';

const CANCELLED_PAYMENT_STATUS = 'CN';

interface ResultsPanelProps {
   payments: readonly PaymentSearchResult[];
   isLoading: boolean;
}

interface PaymentSearchDisplayModel {
   feeRequestNumber: string;
   feeRequestStatus: string;
   totalAmount: string;
   feeRequestDate: string;
   paidAmount: string;
   amountUnpaid: string;
   licencingYear: string;
   paymentId: number | null;
   paymentStatusCode: string;
   paymentStatusDescription: string;
}

const generatePaymentSearchResults = (
   payments: readonly PaymentSearchResult[]
): PaymentSearchDisplayModel[] => {
   return payments.map(
      (payment): PaymentSearchDisplayModel => {
         const totalAmount = formatCurrencyString(payment.totalAmount);
         const paidAmount = formatCurrencyString(payment.paidAmount);
         const amountUnpaid = formatCurrencyString(payment.amountUnpaid);
         const requestStatus = payment.requestStatusDescription
            ? payment.requestStatusDescription.trim()
            : '';

         return {
            feeRequestNumber: payment.feeRequestNumber,
            feeRequestStatus: requestStatus,
            totalAmount: totalAmount,
            feeRequestDate: payment.feeRequestDate,
            paidAmount: paidAmount,
            amountUnpaid: amountUnpaid,
            licencingYear: payment.licencingYear,
            paymentId: payment.paymentId,
            paymentStatusCode: payment.paymentStatusCode,
            paymentStatusDescription: payment.paymentStatusDescription,
         };
      }
   );
};

const DEFAULT_SORTING: SortingRule[] = [{ id: 'feeRequestDate', desc: true }];

const ResultsPanel = ({
   payments,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const [selectedPaymentId, setSelectedPaymentId] = useState(0);
   const contact = useSelector(selectContact);
   const receipt = useSelector(selectReceipt);
   const isDownloading = useSelector(
      selectIsLoading([ReceiptTypes.GET_RECEIPT_REQUEST])
   );
   const isDownloaded = useSelector(
      selectIsSuccess([ReceiptTypes.GET_RECEIPT_REQUEST])
   );
   const dispatch = useDispatch();

   const DateRenderer = (cell: CellInfo): JSX.Element => {
      return (
         <div>
            {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
         </div>
      );
   };

   const DownloadRenderer = (cell: CellInfo): JSX.Element => {
      if (cell.original.paymentStatusCode === CANCELLED_PAYMENT_STATUS)
         return <></>;

      if (cell.value) {
         return (
            <LoadingButton
               isLoading={isDownloading && selectedPaymentId === cell.value}
               disabled={isDownloading && selectedPaymentId !== cell.value}
               flat
               primary
               className="download-receipt-button"
               onClick={(): void => {
                  setSelectedPaymentId(cell.value);

                  getReceipt({
                     paymentId: cell.value,
                     contactId: contact.contactId,
                  });
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  setSelectedPaymentId(cell.value);

                  getReceipt({
                     paymentId: cell.value,
                     contactId: contact.contactId,
                  });
               }}
            >
               Download
            </LoadingButton>
         );
      } else {
         return <div>Unable to download</div>;
      }
   };

   const getReceipt = (request: ReceiptRequestReadOnly): void => {
      dispatch(ReceiptActions.getReceiptRequest(request));
   };

   const COLUMN_HEADERS: Column<PaymentSearchDisplayModel>[] = [
      {
         Header: 'Request Number',
         accessor: 'feeRequestNumber',
      },
      {
         Header: 'Request Status',
         accessor: 'feeRequestStatus',
      },
      {
         Header: 'Payment Status',
         accessor: 'paymentStatusDescription',
      },
      {
         Header: 'Total Fee Amount',
         accessor: 'totalAmount',
      },
      {
         Header: 'Payment Date',
         accessor: 'feeRequestDate',
         Cell: DateRenderer,
      },
      {
         Header: 'Paid Amount',
         accessor: 'paidAmount',
      },
      {
         Header: 'Amount Unpaid',
         accessor: 'amountUnpaid',
      },
      {
         Header: 'Licence Year',
         accessor: 'licencingYear',
      },
      {
         Header: '',
         accessor: 'paymentId',
         Cell: DownloadRenderer,
      },
   ];

   useEffect((): void => {
      if (isDownloaded) {
         const blob = new Blob([receipt], {
            type: 'application/pdf',
         });

         FileSaver.saveAs(blob, 'Receipt.pdf');
      }
   }, [isDownloaded, receipt]);

   return (
      <ClientSideTable
         data={generatePaymentSearchResults(payments)}
         defaultPageSize={10}
         columns={COLUMN_HEADERS}
         defaultSorted={DEFAULT_SORTING}
         loading={isLoading}
      />
   );
};

export default ResultsPanel;
