import React, { useEffect, useState, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectContact } from 'State/Redux/ContactRedux';
import ResultsPanel from './Panels/ResultsPanel';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useSelector, useDispatch } from 'react-redux';
import {
   DefaultSearchModel,
   isValidSearchQuery,
   filterToValidQuery,
   ApplicationSearchQuery,
} from 'Models/Application/Dto/ApplicationSearchQuery';
import './ApplicationsSearch.scss';
import { Button } from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import {
   ApplicationActions,
   selectApplicationSearchResults,
   ApplicationTypes,
} from 'State/Redux/ApplicationRedux';
import ApplicationSearchResult from 'Models/Application/Data/ApplicationSearchResult';
import ApplicationSearchPanel from 'Components/Pages/Applications/Panels/ApplicationSearchPanel';
import { PagedQuery, DefaultPagingModel } from 'Models/Other/PagedQuery';
import { isEqual } from 'lodash-es';

const DEFAULT_PAGING: PagedQuery = {
   ...DefaultPagingModel,
   orderBy: 'updatedDate',
   isDescending: true,
};

interface ApplicationsSearchProps {
   setAdvancedSearch: (setting: boolean) => void;
}

const ApplicationsSearch = ({
   setAdvancedSearch,
}: Readonly<ApplicationsSearchProps>): JSX.Element => {
   const [paging, setPaging] = useState(DefaultPagingModel);
   const [searchedPaging, setSearchedPaging] = useState(DefaultPagingModel);
   const updatePaging = useCallback(
      (updatedPaging: Readonly<PagedQuery>): void => {
         if (!isEqual(searchedPaging, updatedPaging)) setPaging(updatedPaging);
      },
      [searchedPaging]
   );
   const contact = useSelector(selectContact);
   const applications = useSelector(selectApplicationSearchResults);
   const isLoading = useSelector(
      selectIsLoading([ApplicationTypes.SEARCH_APPLICATIONS_REQUEST])
   );
   const dispatch = useDispatch();
   const searchApplicationQuery = useCallback(
      (query: ApplicationSearchQuery): void => {
         dispatch(ApplicationActions.searchApplicationsRequest(query));
      },
      [dispatch]
   );

   const [hasSearched, setHasSearched] = useState(false);
   const [
      searchResults,
      setSearchResults,
   ] = useState<ApplicationSearchResult | null>(null);
   const [searchButtonClicked, setSearchButtonClicked] = useState(false);
   const [searchModel, setSearchModel] = useState(DefaultSearchModel);

   const searchApplications = (): void => {
      const updatedPagingQuery = filterToValidQuery({
         ...searchModel,
         ...paging,
      });
      const newQuery = filterToValidQuery({
         ...searchModel,
         ...DEFAULT_PAGING,
      });

      if (
         searchButtonClicked &&
         isValidSearchQuery(newQuery) &&
         !isEqual(searchModel, newQuery)
      ) {
         searchApplicationQuery(newQuery);
         dispatch(ApplicationActions.searchApplicationsRequest(newQuery));
         setPaging(DEFAULT_PAGING);
         setSearchedPaging(DEFAULT_PAGING);
         setHasSearched(true);
      } else if (
         !isEqual(searchedPaging, paging) &&
         isValidSearchQuery(updatedPagingQuery)
      ) {
         searchApplicationQuery({ ...searchModel, ...paging });
         setHasSearched(true);
         setSearchedPaging(paging);
      }
      setSearchButtonClicked(false);
   };
   useEffect(searchApplications, [
      searchButtonClicked,
      dispatch,
      searchModel,
      paging,
      searchedPaging,
   ]);

   const hasResults = hasSearched && applications !== searchResults;
   if (hasResults) setSearchResults(applications);

   const panelTitle =
      hasSearched && searchResults
         ? `Results (${searchResults.totalCount})`
         : 'Results';

   const reset = (): void => {
      setAdvancedSearch(false);
      setHasSearched(false);
      setSearchButtonClicked(false);
      setSearchResults(null);
      setPaging(DEFAULT_PAGING);
   };

   return (
      <>
         <div className="back-button">
            <Button
               flat
               primary
               iconChildren="arrow_back"
               onClick={(): void => {
                  reset();
               }}
               onKeyUp={(keyPress): void => {
                  if (!isEnterKeyPress(keyPress)) return;
                  reset();
               }}
            >
               Back
            </Button>
         </div>
         <ExpansionList className="md-cell md-cell--12">
            <ExpansionPanel
               label="Application Advanced Search"
               footer={null}
               className="no-margin-top"
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <ApplicationSearchPanel
                  contact={contact}
                  query={searchModel}
                  setQuery={setSearchModel}
                  isSearching={isLoading}
                  setIsSearching={setSearchButtonClicked}
                  reset={(): void => {
                     setHasSearched(false);
                     setSearchButtonClicked(false);
                     setSearchResults(null);
                  }}
               />
            </ExpansionPanel>
            {hasSearched ? (
               <ExpansionPanel
                  label={panelTitle}
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ResultsPanel
                     applications={searchResults}
                     isLoading={isLoading}
                     paging={paging}
                     onPagingChanged={updatePaging}
                  />
               </ExpansionPanel>
            ) : (
               <>
                  <div className="application-search-padding"></div>
               </>
            )}
         </ExpansionList>
      </>
   );
};

export default React.memo(ApplicationsSearch);
