import * as REGEX from 'Util/Constants/Regex';
import Moment from 'moment-timezone';
import { INPUT_ERROR_CLASS } from 'Util/Constants/Validation';

export const isNullOrWhiteSpace = (
   value: string | number | undefined | null
): boolean => {
   return !(value || '').toString().trim().length;
};

export const isValidEmail = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_EMAIL) ? true : false;
};

export const isValidPhoneNumber = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_PHONE_NUMBER) ? true : false;
};

export const isValidLicenceNumber = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_LICENCE_NUMBER) ? true : false;
};

export const isValidSearchLicenceNumber = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_SEARCH_LICENCE_NUMBER) ? true : false;
};

export const isNumber = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_NUMBER) ? true : false;
};

export const isValidLength = (value: string, length: number): boolean => {
   return (value || '').trim().length <= length;
};

export const isValidDate = (value: string | Date | null): boolean => {
   if (!value) return true;
   return Moment(value).isValid();
};

export const isAmount = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_AMOUNT) ? true : false;
};

export const isValidDateBeforeNow = (date: string | Date | null): boolean => {
   if (!date) return true;
   return Moment(date).isSameOrBefore(Moment());
};

export const isValidEndDateAfterStartDate = (
   startDate: string | Date | null,
   endDate: string | Date | null
): boolean => {
   if (!startDate || !endDate) return true;

   return Moment(endDate).isSameOrAfter(Moment(startDate));
};

export const isValidUrl = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_URL) ? true : false;
};

export const getInputValidationClassName = (
   errorValidation: boolean | null | undefined
): string => {
   return errorValidation !== undefined && errorValidation
      ? INPUT_ERROR_CLASS
      : '';
};

export const isValidUserId = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_USER_ID) ? true : false;
};
