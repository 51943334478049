import React, { useState, useEffect } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields';
import {
   useFeeRequestStatusList,
   usePaymentMethodList,
} from 'Util/Helpers/Metadata';
import { FeesPaymentTypes } from 'State/Redux/FeesPaymentRedux';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import DateInput from 'Components/Common/DateInput/DateInput';
import LoadingButton from 'Components/Common/LoadingButton';
import FeesPaymentSearchInput from 'Models/FeesPayment/FeesPaymentSearchInput';
import { SelectItem, isEnterKeyPress } from 'Util/Helpers/Input';
import { FEE_REQUEST_CANCELLED } from 'Util/Constants/FeeRequestStatus';
import { CANCELLED } from 'Util/Constants/PaymentStatus';
import { isValidRegistrationNumber } from 'Util/Helpers/RegistrationNumber';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import {
   ContactActions,
   selectContact,
   ContactTypes,
} from 'State/Redux/ContactRedux';

interface SearchProps {
   searchInput: FeesPaymentSearchInput;
   updateSearchInput: (model: FeesPaymentSearchInput) => void;
   onSearch: () => void;
   onReset: () => void;
}

const FeePaymentSearchPanel = ({
   searchInput,
   updateSearchInput,
   onSearch,
   onReset,
}: Readonly<SearchProps>): JSX.Element => {
   const isLoadingSearch = useSelector(
      selectIsLoading([FeesPaymentTypes.GET_SEARCH_RESULT_REQUEST])
   );

   const paymentTypes = usePaymentMethodList('', true);
   const [searchText, setSearchText] = useState('Search');
   const [isLicenceNumberChanged, setIsLicenceNumberChanged] = useState(false);
   const [userSearchCompleted, setUserSearchCompleted] = useState(false);
   const [triggerSearch, setTriggerSearch] = useState(false);
   const feeRequestList = useFeeRequestStatusList('', true);
   const dispatch = useDispatch();
   const contact = useSelector(selectContact);
   const [isInvalidLicence, setIsInvalidLicence] = useState(
      searchInput.licenceNumber && (!contact || !contact.fullName)
         ? true
         : false
   );
   const searchUserSuccess = useSelector(
      selectIsSuccess([ContactTypes.GET_CONTACT_BY_REGISTRATION_NUMBER_REQUEST])
   );

   const getFeeRequestStatus = (): SelectItem[] => {
      const result = feeRequestList;
      const cancelItem = result.find(f => f.value === FEE_REQUEST_CANCELLED);
      if (cancelItem) {
         cancelItem.label = 'Cancelled Fee Request';
      }
      // Cancelled Payment
      result.unshift({
         label: 'Cancelled Payment',
         value: CANCELLED,
      });
      return result;
   };

   useEffect((): void => {
      setSearchText(isLoadingSearch ? 'Searching...' : 'Search');
   }, [isLoadingSearch]);

   useEffect((): void => {
      if (searchUserSuccess && !userSearchCompleted) {
         setUserSearchCompleted(true);
         if ((!contact || !contact.fullName) && searchInput.licenceNumber) {
            setIsInvalidLicence(true);
         } else if (triggerSearch) {
            onSearch();
            setTriggerSearch(false);
         }
      }
   }, [
      contact,
      searchUserSuccess,
      userSearchCompleted,
      searchInput,
      triggerSearch,
      onSearch,
   ]);

   const handleLicenceEnter = (search?: boolean): void => {
      if (searchInput.licenceNumber) {
         if (isValidRegistrationNumber(searchInput.licenceNumber.toString())) {
            dispatch(
               ContactActions.getContactByRegistrationNumberRequest(
                  searchInput.licenceNumber.toString()
               )
            );
            setUserSearchCompleted(false);
            if (search) {
               setTriggerSearch(true);
            }
         } else {
            setIsInvalidLicence(true);
            dispatch(ContactActions.getContactByRegistrationNumberReset());
         }
         setIsLicenceNumberChanged(false);
      } else {
         dispatch(ContactActions.getContactByRegistrationNumberReset());
      }
   };

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--3">
            <TextField
               floating
               id="fee-search-input-licence"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(isInvalidLicence)}
               value={
                  searchInput.licenceNumber ? searchInput.licenceNumber : ''
               }
               onChange={(e): void => {
                  setIsLicenceNumberChanged(true);

                  updateSearchInput({
                     ...searchInput,
                     licenceNumber: e.toString(),
                  });
                  setIsInvalidLicence(false);
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) {
                     handleLicenceEnter(true);
                  }
               }}
               onBlur={(): void => {
                  isLicenceNumberChanged && handleLicenceEnter();
               }}
               error={isInvalidLicence}
               errorText={isInvalidLicence && 'Invalid Licence Number'}
            />
         </div>
         <div className="md-cell md-cell--3">
            <TextField
               floating
               id="fee-search-input-licence"
               label="Practitioner Name"
               lineDirection="center"
               className="md-cell md-cell--12"
               disabled
               value={
                  searchInput.licenceNumber && contact && contact.fullName
                     ? contact.fullName
                     : ''
               }
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="fee-search-input-payee"
               label="Payer Name"
               lineDirection="center"
               placeholder="Payer Name"
               className="md-cell md-cell--bottom md-cell--12"
               value={searchInput.payeeName}
               onChange={(e): void =>
                  updateSearchInput({
                     ...searchInput,
                     payeeName: e ? e.toString() : undefined,
                  })
               }
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="fee-search-input-start-date"
               label="Start Date"
               className="md-cell md-cell--12"
               maxDate={new Date()}
               value={searchInput.startDate ? searchInput.startDate : undefined}
               onChange={(val, event): void => {
                  updateSearchInput({
                     ...searchInput,
                     startDate: val ? val : undefined,
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="fee-search-input-end-date"
               label="End Date"
               className="md-cell md-cell--12"
               maxDate={new Date()}
               value={searchInput.endDate ? searchInput.endDate : undefined}
               onChange={(val, event): void => {
                  updateSearchInput({
                     ...searchInput,
                     endDate: val ? val : undefined,
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               id="fee-search-input-app-type"
               label="Payment Type"
               lineDirection="center"
               placeholder="Payment Type"
               className="md-cell md-cell--bottom md-cell--12"
               position={SelectField.Positions.BELOW}
               menuItems={paymentTypes}
               onChange={(e): void =>
                  updateSearchInput({
                     ...searchInput,
                     paymentTypeCode: e ? e.toString() : '',
                  })
               }
               value={searchInput.paymentTypeCode}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               id="fee-search-input-status"
               label="Payment Status"
               lineDirection="center"
               placeholder="Payment Status"
               className="md-cell md-cell--bottom md-cell--12"
               position={SelectField.Positions.BELOW}
               menuItems={getFeeRequestStatus()}
               onChange={(e): void =>
                  updateSearchInput({
                     ...searchInput,
                     paymentStatusCode: e ? e.toString() : '',
                  })
               }
               value={searchInput.paymentStatusCode}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />

            <div className="controls">
               <LoadingButton
                  flat
                  primary
                  swapTheming
                  onClick={(): void => onSearch()}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) onSearch();
                  }}
                  isLoading={isLoadingSearch}
                  disabled={
                     isInvalidLicence ||
                     (!searchInput.licenceNumber &&
                        !searchInput.paymentStatusCode &&
                        !searchInput.paymentTypeCode)
                  }
               >
                  {searchText}
               </LoadingButton>
               <Button
                  flat
                  secondary
                  className="red-btn"
                  swapTheming
                  onClick={(): void => {
                     dispatch(
                        ContactActions.getContactByRegistrationNumberReset()
                     );
                     onReset();
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     dispatch(
                        ContactActions.getContactByRegistrationNumberReset()
                     );
                     onReset();
                  }}
               >
                  Reset
               </Button>
            </div>
         </div>
      </div>
   );
};

export default FeePaymentSearchPanel;
