import { put, call } from 'redux-saga/effects';
import { ContactPhotoActions } from '../Redux/ContactPhotoRedux';
import { ContactPhotoServiceType } from '../Services/ContactPhotoService';
import { ApiResponse } from 'apisauce';
import ContactPhotoResponse from 'Models/Contact/Dto/ContactPhotoResponse';
import ContactPhotoReview from 'Models/Contact/Data/ContactPhotoReview';

interface ContactPhotoParams {
   type: string;
   token: string;
   data: ContactPhotoReview;
}

export function* getContactPhotoReviews(
   contactPhotoService: Readonly<ContactPhotoServiceType>,
   action: Readonly<ContactPhotoParams>
): Generator {
   const contactPhotoResponse = yield call(
      contactPhotoService.getContactPhotoReview
   );
   const response = contactPhotoResponse as ApiResponse<ContactPhotoResponse>;

   if (response.status && response.ok) {
      yield put(
         ContactPhotoActions.getContactPhotoReviewSuccess(response.data)
      );
   } else {
      yield put(
         ContactPhotoActions.getContactPhotoReviewFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveContactPhotoReview(
   contactPhotoService: Readonly<ContactPhotoServiceType>,
   action: Readonly<ContactPhotoParams>
): Generator {
   const contactPhotoResponse = yield call(
      contactPhotoService.saveContactPhotoReview,
      action.data
   );
   const response = contactPhotoResponse as ApiResponse<ContactPhotoResponse>;

   if (response.status && response.ok && response.data) {
      yield put(ContactPhotoActions.saveContactPhotoReviewSuccess());
   } else {
      yield put(
         ContactPhotoActions.saveContactPhotoReviewFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Saving Contact',
         })
      );
   }
}

export function* exportContactPhotos(
   contactPhotoService: Readonly<ContactPhotoServiceType>,
   action: Readonly<ContactPhotoParams>
): Generator {
   const contactPhotoResponse = yield call(
      contactPhotoService.exportContactPhotos
   );
   const response = contactPhotoResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(ContactPhotoActions.exportContactPhotosSuccess(response.data));
   } else {
      yield put(
         ContactPhotoActions.exportContactPhotosFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
