import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import LetterReadonly from 'Models/Letters/Data/Letter';
import FileDtoReadonly from 'Models/Letters/Dto/FileDto';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface LetterState {
   contactLetter?: FileDtoReadonly;
   letters?: LetterReadonly[];
}

export type LetterStateReadonly = Readonly<LetterState>;

interface TypeNames {
   GET_CONTACT_LETTER_REQUEST: string;
   GET_CONTACT_LETTER_SUCCESS: string;
   GET_CONTACT_LETTER_FAILURE: string;

   GET_LETTERS_REQUEST: string;
   GET_LETTERS_SUCCESS: string;
   GET_LETTERS_FAILURE: string;

   RESET_CONTACT_LETTER_STATE: string;
}

type LetterTypeNames = Readonly<TypeNames>;

export interface LetterCreators {
   getContactLetterRequest: (contactId: string, letterId: string) => AnyAction;
   getContactLetterSuccess: (data: FileDtoReadonly) => AnyAction;
   getContactLetterFailure: (error: ApiError) => AnyAction;

   getLettersRequest: () => AnyAction;
   getLettersSuccess: (data?: LetterReadonly[]) => AnyAction;
   getLettersFailure: (error: ApiError) => AnyAction;

   resetContactLetterState: () => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: LetterStateReadonly = {
   contactLetter: undefined,
   letters: undefined,
};

/* ------------- Reducers ------------- */
const getContactLetterSuccess = (
   state: LetterStateReadonly,
   action: AnyAction
): LetterStateReadonly => {
   return { ...state, contactLetter: action.data };
};

const getLettersSuccess = (
   state: LetterStateReadonly,
   action: AnyAction
): LetterStateReadonly => {
   return { ...state, letters: action.data };
};

const resetContactLetterState = (
   state: LetterStateReadonly
): LetterStateReadonly => {
   return { ...state, contactLetter: undefined };
};
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   LetterTypeNames,
   LetterCreators
>('letter', {
   getContactLetterRequest: ['contactId', 'letterId'],
   getContactLetterSuccess: ['data'],
   getContactLetterFailure: ['error'],

   getLettersRequest: [],
   getLettersSuccess: ['data'],
   getLettersFailure: ['error'],

   resetContactLetterState: [],
});

export const LetterTypes = Types;
export const LetterActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_CONTACT_LETTER_SUCCESS]: getContactLetterSuccess,
   [Types.GET_LETTERS_SUCCESS]: getLettersSuccess,
   [Types.RESET_CONTACT_LETTER_STATE]: resetContactLetterState,
});

/* ------------- Selectors ------------- */

export const selectContactLetter = (
   state: StateReadonly
): FileDtoReadonly | undefined => {
   return state.letter.contactLetter;
};

export const selectLetters = (
   state: StateReadonly
): LetterReadonly[] | undefined => {
   return state.letter.letters;
};
