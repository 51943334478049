import React, { useEffect, useState } from 'react';
import { TabsContainer, Tabs, Tab } from 'react-md/lib/Tabs';
import FeePaymentFindTab from './FindTab/FeePaymentFindTab';
import { AsyncActions, selectIsError } from 'State/Redux/AsyncRedux';
import {
   FeesPaymentTypes,
   FeesPaymentActions,
} from 'State/Redux/FeesPaymentRedux';
import { useSelector, useDispatch } from 'react-redux';
import './style.scss';

const FeesPayment = (): JSX.Element => {
   const isError = useSelector(
      selectIsError([FeesPaymentTypes.GET_SEARCH_RESULT_REQUEST])
   );
   const dispatch = useDispatch();
   const [initiated, setInitiated] = useState(false);

   useEffect((): void => {
      if (!initiated) {
         setInitiated(true);
         dispatch(FeesPaymentActions.getSearchResultReset());
      }
   }, [initiated, dispatch]);

   useEffect((): void => {
      // clear the search if any
      if (!isError) {
         dispatch(
            AsyncActions.resetAsync([
               FeesPaymentTypes.GET_SEARCH_RESULT_REQUEST,
            ])
         );
      }
   }, [dispatch, isError]);
   return (
      <>
         <TabsContainer panelClassName="md-grid" colored>
            <Tabs tabId="simple-tab">
               <Tab label="Find">
                  <FeePaymentFindTab />
               </Tab>
            </Tabs>
         </TabsContainer>
      </>
   );
};

export default React.memo(FeesPayment);
