import { RECEIVED_NOT_BANKED } from 'Util/Constants/PaymentStatus';
import { getCurrentDate } from 'Util/Helpers/Date';

interface PaymentDetailPaymentRecord {
   paymentId: number;
   paymentDate: string;
   paymentMethod: string;
   payerName: string;
   bankBranch: string;
   referenceNumber: string;
   paymentStatus: string;
   amount: number;
   createdBy: string;
   createdOn: string;
}

type PaymentDetailPaymentRecordReadOnly = Readonly<PaymentDetailPaymentRecord>;

export default PaymentDetailPaymentRecordReadOnly;

export const DefaultPaymentDetailPaymentRecord: PaymentDetailPaymentRecordReadOnly = {
   paymentId: 0,
   paymentDate: getCurrentDate(),
   paymentMethod: '',
   payerName: '',
   bankBranch: '',
   referenceNumber: '',
   paymentStatus: RECEIVED_NOT_BANKED,
   amount: 0,
   createdBy: '',
   createdOn: '',
};
