import React from 'react';

type ExternalLinkProps = Omit<
   React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
   >,
   'target' | 'rel'
>;

const ExternalLink = (props: Readonly<ExternalLinkProps>): JSX.Element => {
   return (
      <a target="_blank" rel="noopener noreferrer" {...props}>
         {props.children}
      </a>
   );
};
export default ExternalLink;
