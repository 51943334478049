import React, { useState, useEffect, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import DateInput from 'Components/Common/DateInput/DateInput';
import { TextField } from 'react-md/lib/TextFields';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { useDispatch, useSelector } from 'react-redux';
import CourseDtoReadonly, {
   DefaultCourseDtoModel,
} from 'Models/Examination/Dto/CourseDto';
import {
   CourseActions,
   CourseTypes,
   selectCreateCourseError,
} from 'State/Redux/CourseRedux';
import { createSelectList, SelectItem } from 'Util/Helpers/Input';
import { ContactActions } from 'State/Redux/ContactRedux';
import StateReadonly from 'State/Redux/StateModel';
import ContactReadonly from 'Models/Contact/Data/Contact';
import SelectField from 'react-md/lib/SelectFields';
import { selectLicenceYears } from 'State/Redux/MetadataRedux';
import { toast } from 'react-toastify';
import {
   selectIsError,
   selectIsSuccess,
   selectIsLoading,
   AsyncActions,
} from 'State/Redux/AsyncRedux';
import LoadingButton from 'Components/Common/LoadingButton';
import { useCourseTypeList } from 'Util/Helpers/Metadata';
import { MANDATORY_CPD_LABEL, UNKNOWN_LABEL } from 'Util/Constants/Course';
import moment from 'moment';
import Button from 'react-md/lib/Buttons';

interface CourseCreateValidation {
   courseCode: boolean;
   trainingProvider: boolean;
   title: boolean;
   startDate: boolean;
   endDate: boolean;
   licenceYear: boolean;
}

const AddCourse = (): JSX.Element => {
   const dispatch = useDispatch();
   const [course, setCourse] = useState<CourseDtoReadonly>(
      DefaultCourseDtoModel
   );
   const [trainingProvidersList, setTrainingProvidersList] = useState<
      SelectItem[]
   >([]);
   const [licenceYearList, setLicenceYearList] = useState<SelectItem[]>([]);
   const [isSaveTriggered, setIsSaveTriggered] = useState<boolean>(false);
   const [mandatoryCpd, setMandatoryCpd] = useState<boolean>(true);
   const [isValid, setIsValid] = useState<boolean>(true);
   const licenceYears = useSelector(selectLicenceYears);
   const trainingProviders = useSelector(
      (state: StateReadonly): readonly ContactReadonly[] =>
         state.contact.trainingProviders
   );
   const courseTypes = useCourseTypeList();
   const createIsError = useSelector(
      selectIsError([CourseTypes.SAVE_COURSE_REQUEST])
   );
   const createIsSuccess = useSelector(
      selectIsSuccess([CourseTypes.SAVE_COURSE_REQUEST])
   );
   const createIsLoading = useSelector(
      selectIsLoading([CourseTypes.SAVE_COURSE_REQUEST])
   );

   const resetCreateCourseState = useCallback(() => {
      dispatch(
         AsyncActions.resetAsync([
            CourseTypes.SAVE_COURSE_REQUEST,
            CourseTypes.SAVE_COURSE_FAILURE,
         ])
      );
   }, [dispatch]);

   const createError = useSelector(selectCreateCourseError);

   useEffect(resetCreateCourseState, []);

   useEffect(() => {
      if (isSaveTriggered) {
         if (createIsSuccess) {
            toast.success('Successfully Created Course');
            setCourse(DefaultCourseDtoModel);
         } else if (createIsError) {
            toast.error('Could not create course');
         }
      }
   }, [
      isSaveTriggered,
      createIsSuccess,
      createIsError,
      resetCreateCourseState,
   ]);

   useEffect(() => {
      dispatch(ContactActions.getTrainingProvidersRequest());
   }, [dispatch]);

   useEffect(() => {
      setTrainingProvidersList(
         createSelectList(
            trainingProviders,
            (tp): string => tp.contactId.toString(),
            (tp): string =>
               tp.companyName ||
               `Unnamed Training Provider (${tp.contactId.toString()})`,
            'Select a Training Provider'
         )
      );
   }, [trainingProviders]);

   useEffect(() => {
      const licenceSelectList = createSelectList(
         licenceYears,
         (ly): string => ly.licenceYearId.toString(),
         (ly): string => ly.description
      );
      setLicenceYearList(
         licenceSelectList.sort((a, b) => b.label.localeCompare(a.label))
      );
   }, [licenceYears]);

   useEffect(() => {
      const courseTypeId = mandatoryCpd
         ? courseTypes.find(ct => ct.label === MANDATORY_CPD_LABEL)?.value
         : courseTypes.find(ct => ct.label === UNKNOWN_LABEL)?.value;
      if (course.courseTypeId !== Number(courseTypeId)) {
         setCourse({ ...course, courseTypeId: Number(courseTypeId) });
      }
   }, [mandatoryCpd, setCourse, course, courseTypes]);

   const validateCourse = useCallback((): boolean => {
      const validation = {
         courseCode: !!course.courseNumber,
         trainingProvider: !!course.trainingProviderId,
         title: !!course.courseName,
         startDate:
            !!course.startDate &&
            course.endDate !== null &&
            moment(course.startDate).isBefore(course.endDate),
         endDate:
            !!course.endDate &&
            course.startDate !== null &&
            moment(course.endDate).isAfter(course.startDate),
         licenceYear: !!course.licenceYearId,
      };
      return Object.values(validation).every((v): boolean => !!v);
   }, [course]);

   useEffect(() => {
      setIsValid(validateCourse());
   }, [course, validateCourse]);

   const validateAndSaveCourse = (e: React.FormEvent): void => {
      e.preventDefault();
      if (isValid) {
         dispatch(CourseActions.saveCourseRequest(course));
         toast.update(`Creating Course`);
         resetCreateCourseState();
         setIsSaveTriggered(true);
      } else {
         toast.warn('Form validation failed');
      }
   };

   return (
      <ExpansionList className="md-cell md-cell--12 add-course">
         <ExpansionPanel
            label="Add Course"
            expanded
            footer={null}
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <form onSubmit={(e): void => validateAndSaveCourse(e)}>
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--6">
                     <TextField
                        floating
                        id="add-course-course-code"
                        label="Code"
                        lineDirection="center"
                        className="md-cell md-cell--bottom md-cell--12"
                        required
                        onChange={(val): void => {
                           setCourse({
                              ...course,
                              courseNumber: val.toString(),
                           });
                        }}
                        errorText={
                           createError?.courseNumber ??
                           'Course Code Is Required'
                        }
                        error={
                           createIsError && createError?.courseNumber !== null
                        }
                        value={course.courseNumber}
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <SelectField
                        floating
                        id="add-course-training-provider"
                        label="Training Provider"
                        lineDirection="center"
                        className="md-cell md-cell--bottom md-cell--12"
                        position={SelectField.Positions.BELOW}
                        menuItems={trainingProvidersList}
                        required
                        onChange={(val): void => {
                           setCourse({
                              ...course,
                              trainingProviderId: Number(val),
                           });
                        }}
                        errorText="Training Provider is Required"
                        value={course.trainingProviderId?.toString()}
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <TextField
                        floating
                        id="add-course-course-title"
                        label="Title"
                        lineDirection="center"
                        className="md-cell md-cell--bottom md-cell--12"
                        required
                        onChange={(val): void => {
                           setCourse({
                              ...course,
                              courseName: val.toString(),
                           });
                        }}
                        errorText={
                           createError?.courseName ?? 'Course Title is Required'
                        }
                        error={
                           createIsError && createError?.courseName !== null
                        }
                        value={course.courseName}
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <SelectField
                        id="add-course-licence-year"
                        label="Licence Year"
                        className="md-cell md-cell--bottom md-cell--12"
                        position={SelectField.Positions.BELOW}
                        menuItems={licenceYearList}
                        required
                        onChange={(val): void => {
                           setCourse({ ...course, licenceYearId: Number(val) });
                        }}
                        errorText={
                           createError?.cpdCourseAlreadyCreated ??
                           'Licence Year is Required'
                        }
                        error={
                           createIsError &&
                           createError?.cpdCourseAlreadyCreated !== null
                        }
                        value={
                           course.licenceYearId
                              ? course.licenceYearId.toString()
                              : ''
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <DateInput
                        id="add-course-start-date"
                        label="Availability Start Date"
                        required
                        onChange={(val): void => {
                           setCourse({ ...course, startDate: val });
                        }}
                        errorText="Course Start Date is Required"
                        value={course.startDate ? course.startDate : undefined}
                        maxDate={
                           course.endDate ? new Date(course.endDate) : undefined
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <DateInput
                        id="add-course-end-date"
                        label="Availability End Date"
                        required
                        onChange={(val): void => {
                           setCourse({ ...course, endDate: val });
                        }}
                        errorText="Course End Date is Required"
                        value={course.endDate ? course.endDate : undefined}
                        minDate={
                           course.startDate
                              ? new Date(course.startDate)
                              : undefined
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <TextField
                        floating
                        id="add-course-course-description"
                        label="Description"
                        lineDirection="center"
                        className="md-cell md-cell--bottom md-cell--12"
                        onChange={(val): void => {
                           setCourse({
                              ...course,
                              courseDescription: val.toString(),
                           });
                        }}
                        value={course.courseDescription}
                     ></TextField>
                  </div>
                  <div className="md-cell md-cell--6">
                     <TextField
                        floating
                        id="add-course-course-url"
                        label="URL"
                        lineDirection="center"
                        className="md-cell md-cell--bottom md-cell--12"
                        onChange={(val): void => {
                           setCourse({
                              ...course,
                              courseUrl: val.toString(),
                           });
                        }}
                        value={course.courseUrl ? course.courseUrl : ''}
                     ></TextField>
                  </div>

                  <div className="md-cell md-cell--6">
                     <Checkbox
                        id="add-course-mandatory-cpd"
                        name="add-course-mandatory-cpd"
                        label="Mandatory CPD Course"
                        className="md-cell md-cell--bottom md-cell--12"
                        defaultChecked
                        onChange={(val): void => {
                           setMandatoryCpd(val);
                        }}
                        checked={mandatoryCpd}
                     />
                  </div>
                  <div className="buttons-group md-cell--right">
                     <LoadingButton
                        flat
                        primary
                        swapTheming
                        type="submit"
                        isLoading={createIsLoading}
                        disabled={!isValid}
                     >
                        Add Course
                     </LoadingButton>

                     <Button
                        flat
                        secondary
                        swapTheming
                        onClick={(): void => {
                           setCourse(DefaultCourseDtoModel);
                           resetCreateCourseState();
                        }}
                        className="clear-course"
                     >
                        Reset
                     </Button>
                  </div>
               </div>
            </form>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddCourse;
