export const PLUMBING = 'P';
export const GASFITTING = 'G';
export const DRAINLAYING = 'D';
export const OTHER = 'O';

export type DisciplineCode = 'P' | 'G' | 'D' | 'O';

export const DisciplineCodes: readonly string[] = [
   PLUMBING,
   GASFITTING,
   DRAINLAYING,
   OTHER,
] as DisciplineCode[];

export const UNKNOWN = 'Unknown'; // for when we can't establish the discipline

export const TradesmanDisciplines: ReadonlyMap<string, string> = new Map<
   DisciplineCode,
   string
>([
   [PLUMBING, 'Plumber'],
   [GASFITTING, 'Gasfitter'],
   [DRAINLAYING, 'Drainlayer'],
   [OTHER, 'Other'],
]);

export const DisciplineColors: ReadonlyMap<string, string> = new Map<
   DisciplineCode,
   string
>([
   [PLUMBING, 'purple'],
   [GASFITTING, 'orange'],
   [DRAINLAYING, 'green'],
   [OTHER, ''],
]);

export const DisciplineCodeRank: Record<string, number> = {
   P: 1,
   G: 2,
   D: 3,
   O: 4,
};
