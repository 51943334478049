interface User {
   licenceNumber: string;
   email: string;
}

export type UserReadonly = Readonly<User>;

export const DefaultUser: UserReadonly = {
   licenceNumber: '',
   email: '',
};
