import { put, call } from 'redux-saga/effects';
import { ReportActions } from '../Redux/ReportRedux';
import { ApiResponse } from 'apisauce';
import { ReportServiceType } from 'State/Services/ReportService';
import SqlReportingReadonly from 'Models/Report/SqlReporting';

interface ReportParams {
   type: string;
   token: string;
   id: number;
   message: SqlReportingReadonly;
   data: string;
   newQuery: string;
   query: SqlReportingReadonly;
}

export function* getSqlQueries(
   reportService: Readonly<ReportServiceType>,
   action: Readonly<ReportParams>
): Generator {
   const reportResponse = yield call(reportService.getSqlQueries);
   const response = reportResponse as ApiResponse<SqlReportingReadonly>;

   if (response.status && response.ok) {
      yield put(ReportActions.getSqlQueriesSuccess(response.data));
   } else {
      yield put(
         ReportActions.getSqlQueriesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getSqlQueryResults(
   reportService: Readonly<ReportServiceType>,
   action: Readonly<ReportParams>
): Generator {
   const { id } = action;
   const reportResponse = yield call(reportService.getSqlQueryResults, id);
   const response = reportResponse as ApiResponse<string>;

   if (response.status && response.ok) {
      yield put(ReportActions.getSqlQueryResultsSuccess(response.data));
   } else {
      yield put(
         ReportActions.getSqlQueryResultsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* editSqlQuery(
   reportService: Readonly<ReportServiceType>,
   action: Readonly<ReportParams>
): Generator {
   const { id, newQuery } = action;
   const reportResponse = yield call(reportService.editSqlQuery, id, newQuery);
   const response = reportResponse as ApiResponse<string>;

   if (response.status && response.ok) {
      yield put(ReportActions.editSqlQuerySuccess(response.data));
   } else {
      yield put(
         ReportActions.editSqlQueryFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addSqlQuery(
   reportService: Readonly<ReportServiceType>,
   action: Readonly<ReportParams>
): Generator {
   const { query } = action;
   const reportResponse = yield call(reportService.addSqlQuery, query);
   const response = reportResponse as ApiResponse<string>;

   if (response.status && response.ok) {
      yield put(ReportActions.addSqlQuerySuccess(response.data));
   } else {
      yield put(
         ReportActions.addSqlQueryFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* deleteSqlQuery(
   reportService: Readonly<ReportServiceType>,
   action: Readonly<ReportParams>
): Generator {
   const { id } = action;
   const reportResponse = yield call(reportService.deleteSqlQuery, id);
   const response = reportResponse as ApiResponse<string>;

   if (response.status && response.ok) {
      yield put(ReportActions.deleteSqlQuerySuccess(response.data));
   } else {
      yield put(
         ReportActions.deleteSqlQueryFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
