interface ContactNote {
   attachment: object;
   attachmentFileName: string;
   attachmentUrl: string;
   contactNoteId: number;
   createdBy: string;
   createdOn: string;
   hasAttachment: boolean;
   note: string | number;
   applicationId: number;
}

export const DefaultContactNote: ContactNote = {
   attachment: {},
   attachmentFileName: '',
   attachmentUrl: '',
   contactNoteId: 0,
   createdBy: '',
   createdOn: '',
   hasAttachment: false,
   note: '',
   applicationId: 0,
};

type ContactNoteReadonly = Readonly<ContactNote>;
export default ContactNoteReadonly;
