import { put, call } from 'redux-saga/effects';
import { RegistrationTypeActions } from '../Redux/RegistrationTypeRedux';
import { RegistrationTypeServiceType } from '../Services/RegistrationTypeService';
import { ApiResponse } from 'apisauce';
import RegistrationTypeReadonly, {
   RegistrationType,
} from 'Models/RegistrationType/Dto/RegistrationType';
import { MetadataActions } from 'State/Redux/MetadataRedux';

interface RegistrationTypeParams {
   type: string;
   token: string;
   id: number;
   registrationType: RegistrationTypeReadonly;
}

export function* updateRegistrationType(
   supervisionService: Readonly<RegistrationTypeServiceType>,
   action: Readonly<RegistrationTypeParams>
): Generator {
   const { registrationType } = action;
   const supervisionResponse = yield call(
      supervisionService.updateRegistrationType,
      registrationType
   );
   const response = supervisionResponse as ApiResponse<
      RegistrationTypeReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         RegistrationTypeActions.updateRegistrationTypeSuccess(response.data)
      );
      yield put(MetadataActions.getMetadataByTypeRequest('RegistrationTypes'));
   } else {
      yield put(
         RegistrationTypeActions.updateRegistrationTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addRegistrationType(
   supervisionService: Readonly<RegistrationTypeServiceType>,
   action: Readonly<RegistrationTypeParams>
): Generator {
   const { registrationType } = action;
   const supervisionResponse = yield call(
      supervisionService.addRegistrationType,
      registrationType
   );
   const response = supervisionResponse as ApiResponse<
      RegistrationTypeReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         RegistrationTypeActions.addRegistrationTypeSuccess(response.data)
      );
      yield put(MetadataActions.getMetadataByTypeRequest('RegistrationTypes'));
   } else {
      yield put(
         RegistrationTypeActions.addRegistrationTypeFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Adding Registration Type',
         })
      );
   }
}

export function* getRegistrationType(
   supervisionService: Readonly<RegistrationTypeServiceType>,
   action: Readonly<RegistrationTypeParams>
): Generator {
   const supervisionResponse = yield call(
      supervisionService.getRegistrationType
   );
   const response = supervisionResponse as ApiResponse<
      readonly RegistrationType[]
   >;

   if (response.status && response.ok) {
      yield put(
         RegistrationTypeActions.getRegistrationTypeSuccess(response.data)
      );
   } else {
      yield put(
         RegistrationTypeActions.getRegistrationTypeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
