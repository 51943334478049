import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import {
   LicenceActions,
   selectUpdateLicence,
   selectUpdateLicenceError,
   selectLicenceHistory,
   LicenceTypes,
} from 'State/Redux/LicenceRedux';
import {
   SupervisionActions,
   selectSupervisionSearchResult,
} from 'State/Redux/SupervisionRedux';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import { SelectField } from 'react-md/lib/SelectFields';
import { Autocomplete } from 'react-md/lib/Autocompletes';
import UpdateLicenceDto from 'Models/Licence/Dto/UpdateLicenceDto';
import LicenceDto from 'Models/Licence/Dto/LicenceDto';
import { toast } from 'react-toastify';
import { UpdateLicenceInputValidationReadonly } from 'Models/Validator/UpdateLicenceInputValidationModel';
import {
   isNullOrWhiteSpace,
   isValidEndDateAfterStartDate,
   isValidDate,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import Moment from 'moment-timezone';
import {
   useLicenceStatusList,
   usePractitionerLicenceTypeList,
   useCompanyLicenceTypeList,
} from 'Util/Helpers/Metadata';
import { CONTACT_TYPE_EMPLOYER } from 'Util/Constants/Contact';
import {
   VALIDATION_ERROR_MESSAGE,
   TEXT_MAX_LENGTH,
   TEXT_MAX_LENGTH_SUSPENSION,
} from 'Util/Constants/Licence';
import DateInput from 'Components/Common/DateInput/DateInput';
import { NZ_TIMEZONE } from 'Util/Constants/Common';
import { isPractitionerNumber } from 'Util/Helpers/RegistrationNumber';
import { stripTimeZone, getCurrentDate } from 'Util/Helpers/Date';
import {
   selectLicenceYears,
   selectSupervisionTypeCodes,
} from 'State/Redux/MetadataRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import LoadingButton from 'Components/Common/LoadingButton';
import { SUPERVISION_STATUS_DECLINED } from 'Util/Constants/SupervisionStatus';

const isValidSuspensionDates = (editModel: UpdateLicenceDto): boolean => {
   const hasSuspensionDates =
      editModel.suspensionExpiryDate && editModel.suspensionStartDate;
   const hasValidSuspensionRange =
      isValidDate(editModel.suspensionExpiryDate) &&
      isValidDate(editModel.suspensionStartDate) &&
      isValidEndDateAfterStartDate(
         editModel.suspensionStartDate,
         editModel.suspensionExpiryDate
      );

   const hasValidLicenceDateRange =
      isValidDate(editModel.startDate) &&
      isValidDate(editModel.expiryDate) &&
      isValidEndDateAfterStartDate(editModel.startDate, editModel.expiryDate);
   const hasSuspensionRangeWithinLicenceRange =
      hasValidSuspensionRange &&
      hasValidLicenceDateRange &&
      isValidEndDateAfterStartDate(
         editModel.startDate,
         editModel.suspensionStartDate
      ) &&
      isValidEndDateAfterStartDate(
         editModel.suspensionExpiryDate,
         editModel.expiryDate
      );

   return !hasSuspensionDates || hasSuspensionRangeWithinLicenceRange;
};

interface EditLicencesProps {
   closeDialog: () => void;
   licenceId: number;
}

const EditLicences = ({
   closeDialog,
   licenceId,
}: Readonly<EditLicencesProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const updateLicenceResponse = useSelector(selectUpdateLicence);
   const updateLicenceResponseError = useSelector(selectUpdateLicenceError);
   const supervisionTypeCodes = useSelector(selectSupervisionTypeCodes);
   const licences = useSelector(selectLicenceHistory);
   const licenceYears = useSelector(selectLicenceYears);
   const supervisionSearchResult = useSelector(selectSupervisionSearchResult);
   const dispatch = useDispatch();

   const isCompany = contact.contactTypeCode === CONTACT_TYPE_EMPLOYER;
   const licence = licences.find((obj: LicenceDto): boolean => {
      return obj.licenceId === licenceId;
   });

   const model: UpdateLicenceDto = {
      licenceId: -1,
      contactId: contact.contactId,
      startDate: getCurrentDate(),
      expiryDate: stripTimeZone(
         Moment()
            .add(1, 'year')
            .month(2)
            .date(31)
            .tz(NZ_TIMEZONE)
            .format()
      ),
      licenceStatusCode: '',
      licenceTypeCode: '',
      licenceYearStr: '',
      licenceYearId: -1,
      supervisorId: null,
      licenceConditions: '',
      suspensionStartDate: null,
      suspensionExpiryDate: null,
      suspensionReason: '',
   };

   const isUpdating = useSelector(
      selectIsLoading([LicenceTypes.UPDATE_LICENCE_REQUEST])
   );

   const [supervisorStr, setSupervisorStr] = useState(
      !!licence && licence.supervisorId ? licence.supervisorId.toString() : ''
   );
   const [editModel, setEditModel] = useState(model);

   const validateModel = (
      editModel: UpdateLicenceDto,
      supervisorNumber: string
   ): UpdateLicenceInputValidationReadonly => {
      const hasSuspensionEndDate = !!editModel.suspensionExpiryDate;
      const hasValidStartDates =
         isValidDate(editModel.suspensionStartDate) &&
         isValidDate(editModel.startDate) &&
         isValidEndDateAfterStartDate(
            editModel.startDate,
            editModel.suspensionStartDate
         );
      const isValidSuspensionStartDate = hasSuspensionEndDate
         ? isValidSuspensionDates(editModel)
         : hasValidStartDates;

      const hasSuspensionStartDate = !!editModel.suspensionStartDate;
      const hasValidEndDates =
         isValidDate(editModel.suspensionExpiryDate) &&
         isValidDate(editModel.expiryDate) &&
         isValidEndDateAfterStartDate(
            editModel.suspensionExpiryDate,
            editModel.expiryDate
         );
      const isValidSuspensionEndDate = hasSuspensionStartDate
         ? isValidSuspensionDates(editModel)
         : hasValidEndDates;

      return {
         licenceTypeCode: !isNullOrWhiteSpace(editModel.licenceTypeCode),
         licenceStatusCode: !isNullOrWhiteSpace(editModel.licenceStatusCode),
         licenceYearStr:
            isNullOrWhiteSpace(editModel.licenceYearStr) ||
            (!isNullOrWhiteSpace(editModel.licenceYearStr) &&
               licenceYears.some(
                  (ly): boolean => ly.description === editModel.licenceYearStr
               )),
         supervisorId:
            !supervisionTypeCodes.includes(editModel.licenceTypeCode) ||
            isPractitionerNumber(supervisorNumber),
         expiryDate:
            !editModel.expiryDate ||
            (isValidDate(editModel.expiryDate) &&
               isValidEndDateAfterStartDate(
                  editModel.startDate,
                  editModel.expiryDate
               )),
         startDate: !!editModel.startDate,
         licenceConditions:
            !editModel.licenceConditions ||
            editModel.licenceConditions.length <= TEXT_MAX_LENGTH,
         suspensionReason:
            !editModel.suspensionReason ||
            editModel.suspensionReason.length <= TEXT_MAX_LENGTH_SUSPENSION,
         suspensionStartDate: isValidSuspensionStartDate,
         suspensionExpiryDate: isValidSuspensionEndDate,
      };
   };

   const [validationModel, setValidationModel] = useState(
      validateModel(editModel, supervisorStr)
   );

   const isModelValid = Object.values(validationModel).every(v => !!v);

   const saveLicence = (): void => {
      if (!isModelValid) {
         toast.error(VALIDATION_ERROR_MESSAGE);
         return;
      }
      dispatch(LicenceActions.updateLicenceRequest(editModel)); //This updates a licence
   };

   // For cleanup on unmount
   useEffect(() => {
      return (): void => {
         dispatch(SupervisionActions.searchSupervisorReset());
      };
   }, [dispatch]);

   useEffect((): void => {
      if (updateLicenceResponse !== null) {
         toast.success('Licence updated!');
         dispatch(
            LicenceActions.getLicenceHistoryByIdRequest(
               contact.registrationNumber.toString()
            )
         );
         closeDialog();
      }
      if (
         updateLicenceResponse !== null ||
         updateLicenceResponseError !== null
      ) {
         dispatch(LicenceActions.updateLicenceReset());
      }
   }, [
      updateLicenceResponse,
      closeDialog,
      updateLicenceResponseError,
      dispatch,
      contact.registrationNumber,
   ]);

   useEffect((): void => {
      if (updateLicenceResponseError) {
         setValidationModel({
            ...validationModel,
            [updateLicenceResponseError.fieldName as keyof UpdateLicenceInputValidationReadonly]: false,
         });

         toast.error(updateLicenceResponseError.message);
      }
   }, [updateLicenceResponseError, validationModel]);

   const licenceStatuses = useLicenceStatusList('Select a licence status');
   const companyLicenceTypes = useCompanyLicenceTypeList();
   const practitionerLicenceTypes = usePractitionerLicenceTypeList(
      'Select a licence type'
   );
   const licenceYearList = licenceYears.map(ly => ly.description);

   const licenceTypes = isCompany
      ? companyLicenceTypes
      : practitionerLicenceTypes;

   const isSupervisorValid =
      licence &&
      licence.supervision &&
      licence.supervision.length &&
      licence.supervision[0] &&
      licence.supervision[0].supervisionStatus.toString() !==
         SUPERVISION_STATUS_DECLINED;

   const updateEditModel = (
      newState: UpdateLicenceDto,
      supervisorNumber: string
   ): void => {
      setEditModel(newState);
      setValidationModel(validateModel(newState, supervisorNumber));
   };

   if (licence && !editModel.licenceStatusCode) {
      if (isSupervisorValid) {
         const supervisorRegNumber =
            licence.supervision[0].supervisor.registrationNumber;
         dispatch(
            SupervisionActions.searchSupervisorRequest(
               supervisorRegNumber ? supervisorRegNumber.toString() : ''
            )
         );
      }

      const supervisorRegNumber = isSupervisorValid
         ? Number(licence.supervision[0].supervisor.registrationNumber)
         : null;
      if (supervisorRegNumber) setSupervisorStr(supervisorRegNumber.toString());

      updateEditModel(
         {
            ...editModel,
            licenceId: licence.licenceId,
            startDate: stripTimeZone(
               Moment(licence.startDate)
                  .tz(NZ_TIMEZONE)
                  .format()
            ),
            expiryDate: licence.expiryDate
               ? stripTimeZone(
                    Moment(licence.expiryDate)
                       .tz(NZ_TIMEZONE)
                       .format()
                 )
               : '',
            licenceStatusCode: licence.licenceStatusCode,
            licenceTypeCode: licence.licenceTypeCode,
            licenceYearStr: licence.licenceYearNumberNavigation.description,
            licenceYearId: licence.licenceYearNumber,
            supervisorId: supervisorRegNumber,
            licenceConditions: licence.licenceConditions,
            suspensionStartDate: licence.suspensionStartDate
               ? stripTimeZone(
                    Moment(licence.suspensionStartDate)
                       .tz(NZ_TIMEZONE)
                       .format()
                 )
               : '',
            suspensionExpiryDate: licence.suspensionEndDate
               ? stripTimeZone(
                    Moment(licence.suspensionEndDate)
                       .tz(NZ_TIMEZONE)
                       .format()
                 )
               : '',
            suspensionReason: licence.suspensionReason,
         },
         supervisorStr
      );
   }

   return (
      <>
         <div className="md-grid md-cell--12">
            <div className="md-cell md-cell--6">
               <SelectField
                  id="select-licence-type"
                  label="Licence Type"
                  placeholder="Licence Type"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.licenceTypeCode
                  )}
                  menuItems={licenceTypes}
                  position={SelectField.Positions.BELOW}
                  required
                  errorText="Please select a licence type"
                  error={!validationModel.licenceTypeCode}
                  value={editModel.licenceTypeCode}
                  onChange={(val): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           licenceTypeCode: val.toString(),
                        },
                        supervisorStr
                     );
                  }}
                  disabled
               />
            </div>
            <div className="md-cell md-cell--6">
               <SelectField
                  id="select-status"
                  label="Status"
                  placeholder="Status"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.licenceStatusCode
                  )}
                  menuItems={licenceStatuses}
                  position={SelectField.Positions.BELOW}
                  required
                  errorText="Please select a licence status"
                  error={!validationModel.licenceStatusCode}
                  value={editModel.licenceStatusCode}
                  onChange={(val): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           licenceStatusCode: val.toString(),
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <DateInput
                  id="start-date"
                  label="Start Date"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.startDate
                  )}
                  required
                  errorText="Please select a valid start date"
                  error={!validationModel.startDate}
                  value={editModel.startDate}
                  onChange={(date): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           startDate: date,
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <DateInput
                  id="end-date"
                  label="End Date"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.expiryDate
                  )}
                  required
                  errorText="Please select a valid end date"
                  error={!validationModel.expiryDate}
                  value={editModel.expiryDate}
                  onChange={(date): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           expiryDate: date,
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <Autocomplete
                  id="licence-year"
                  label="Licence Year"
                  placeholder="Licence Year"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.licenceYearStr
                  )}
                  data={licenceYearList}
                  filter={Autocomplete.caseInsensitiveFilter}
                  error={!validationModel.licenceYearStr}
                  value={editModel.licenceYearStr}
                  onAutocomplete={(suggestion): void => {
                     const year = licenceYears.find(
                        (x): boolean => x.description === suggestion.toString()
                     );
                     const yearId = year ? year.licenceYearId : 0;

                     updateEditModel(
                        {
                           ...editModel,
                           licenceYearStr: suggestion.toString(),
                           licenceYearId: yearId,
                        },
                        supervisorStr
                     );
                  }}
                  onChange={(val): void => {
                     const year = licenceYears.find(
                        (x): boolean => x.description === val
                     );
                     const yearId = year ? year.licenceYearId : 0;

                     updateEditModel(
                        {
                           ...editModel,
                           licenceYearStr: val,
                           licenceYearId: yearId,
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--2">
               <TextField
                  floating
                  id="supervisor-id"
                  label="Supervisor #"
                  lineDirection="center"
                  placeholder="Supervisor #"
                  className="md-cell md-cell--12"
                  value={supervisorStr}
                  disabled
               />
            </div>
            <div className="md-cell md-cell--4">
               <TextField
                  floating
                  id="supervisor-name"
                  label="Supervisor Name"
                  lineDirection="center"
                  placeholder="Supervisor Name"
                  className="md-cell md-cell--12"
                  value={
                     supervisionTypeCodes.includes(editModel.licenceTypeCode)
                        ? supervisionSearchResult
                           ? supervisionSearchResult.fullName
                           : ''
                        : ''
                  }
                  disabled
               />
            </div>
            <div className="md-cell md-cell--12">
               <TextField
                  id="conditions-to-licence"
                  placeholder="Condition(s) to Licence"
                  label="Condition(s) to Licence"
                  rows={6}
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.licenceConditions
                  )}
                  value={editModel.licenceConditions}
                  error={!validationModel.licenceConditions}
                  maxLength={TEXT_MAX_LENGTH}
                  errorText={
                     'Please remove some text, there is a limit of ' +
                     TEXT_MAX_LENGTH +
                     ' characters'
                  }
                  onChange={(val): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           licenceConditions: val.toString(),
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
         </div>

         <div className="grey-background md-grid md-cell md-cell--12">
            <div className="md-cell md-cell--12">
               <div className="md-cell md-cell--12">
                  <h2>Suspension Details</h2>
               </div>
            </div>
            <div className="md-cell md-cell--6">
               <DateInput
                  id="suspension-start-date"
                  label="Suspension Start Date"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.suspensionStartDate
                  )}
                  errorText="Please select a valid suspension start date"
                  error={!validationModel.suspensionStartDate}
                  value={editModel.suspensionStartDate || ''}
                  onChange={(date): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           suspensionStartDate: date,
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <DateInput
                  id="Suspension-end-date"
                  label="Suspension End Date"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.suspensionExpiryDate
                  )}
                  errorText="Please select a valid suspension end date"
                  error={!validationModel.suspensionExpiryDate}
                  value={editModel.suspensionExpiryDate || ''}
                  onChange={(date): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           suspensionExpiryDate: date,
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--12">
               <TextField
                  id="suspension-reason"
                  label="Suspension Reason"
                  placeholder="Suspension Reason"
                  rows={6}
                  maxRows={6}
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validationModel.suspensionReason
                  )}
                  value={editModel.suspensionReason}
                  error={!validationModel.suspensionReason}
                  maxLength={TEXT_MAX_LENGTH_SUSPENSION}
                  errorText={
                     'Please remove some text, there is a limit of ' +
                     TEXT_MAX_LENGTH_SUSPENSION +
                     ' characters'
                  }
                  onChange={(val): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           suspensionReason: val.toString(),
                        },
                        supervisorStr
                     );
                  }}
               />
            </div>
         </div>

         <div className="md-cell md-cell--12">
            <LoadingButton
               flat
               isLoading={isUpdating}
               primary
               className="md-cell md-cell--2 md-cell--8-offset"
               onClick={(): void => saveLicence()}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) saveLicence();
               }}
               swapTheming
               disabled={!isModelValid}
            >
               Update
            </LoadingButton>
            <Button
               flat
               secondary
               className="red-btn md-cell md-cell--2"
               onClick={(): void => {
                  closeDialog();
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) closeDialog();
               }}
               swapTheming
            >
               Cancel
            </Button>
         </div>
      </>
   );
};

export default React.memo(EditLicences);
