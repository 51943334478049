import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import Contact from 'Models/Contact/Data/Contact';
import ContactNote from 'Models/Contact/Data/ContactNote';
import ApiError from 'Models/Other/ApiError';
import { ContactEditModel } from 'Models/Contact/Data/ContactEditModel';
import ContactNoteInputModel from 'Models/Contact/Data/ContactNoteInputModel';
import ContactSearchResult from 'Models/Contact/Data/ContactSearchResult';
import { ContactSearchQuery } from 'Models/Contact/Dto/ContactSearchQuery';
import Registration from 'Models/Registration/Registration';
import { REGISTRATION_STATUS_ACTIVE } from 'Util/Constants/RegistrationStatus';
import { PaymentSearchQuery } from 'Models/Contact/Dto/PaymentSearchQuery';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import RegistrationNumberContactValidationReadonly from 'Models/Contact/Dto/RegistrationNumberContactValidation';
import produce from 'immer';
import { sortBy } from 'lodash-es';
import { DisciplineCodeRank } from 'Util/Constants/Disciplines';
import UserEditModel from 'Models/SuperUser/UserEditModel';
import { CONTACT_TYPE_TRAINING_PROVIDER } from 'Util/Constants/Contact';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ContactState {
   contact: Contact;
   screenData?: ContactEditModel;
   newContactId?: number;
   newAdminUserId?: string;
   adminUser?: UserEditModel;
   adminUserSearchResult?: UserEditModel[];
   searchResults: ContactSearchResult | null;
   notes: readonly ContactNote[];
   newNote: ContactNote;
   paymentSearchResults: readonly PaymentSearchResult[];
   paymentHistory: readonly PaymentSearchResult[];
   trainingProviders: readonly Contact[];
   contactValidations: RegistrationNumberContactValidationReadonly[];
   noteExport: Blob;
}

export type ContactStateReadonly = Readonly<ContactState>;

interface TypeNames {
   GET_CONTACT_BY_ID_REQUEST: string;
   GET_CONTACT_BY_ID_SUCCESS: string;
   GET_CONTACT_BY_ID_FAILURE: string;

   GET_CONTACT_BY_REGISTRATION_NUMBER_REQUEST: string;
   GET_CONTACT_BY_REGISTRATION_NUMBER_SUCCESS: string;
   GET_CONTACT_BY_REGISTRATION_NUMBER_FAILURE: string;
   GET_CONTACT_BY_REGISTRATION_NUMBER_RESET: string;

   GET_CONTACT_SCREEN_DATA_REQUEST: string;
   GET_CONTACT_SCREEN_DATA_SUCCESS: string;
   GET_CONTACT_SCREEN_DATA_FAILURE: string;
   GET_CONTACT_SCREEN_DATA_RESET: string;

   SAVE_CONTACT_DATA_REQUEST: string;
   SAVE_CONTACT_DATA_SUCCESS: string;
   SAVE_CONTACT_DATA_FAILURE: string;

   SAVE_ADMIN_USER_REQUEST: string;
   SAVE_ADMIN_USER_SUCCESS: string;
   SAVE_ADMIN_USER_FAILURE: string;
   SAVE_ADMIN_USER_RESET: string;

   GET_ADMIN_USER_REQUEST: string;
   GET_ADMIN_USER_SUCCESS: string;
   GET_ADMIN_USER_FAILURE: string;

   SEARCH_ADMIN_USER_REQUEST: string;
   SEARCH_ADMIN_USER_SUCCESS: string;
   SEARCH_ADMIN_USER_FAILURE: string;
   SEARCH_ADMIN_USER_RESET: string;

   SEARCH_CONTACT_REQUEST: string;
   SEARCH_CONTACT_SUCCESS: string;
   SEARCH_CONTACT_FAILURE: string;

   GET_CONTACT_NOTES_REQUEST: string;
   GET_CONTACT_NOTES_SUCCESS: string;
   GET_CONTACT_NOTES_FAILURE: string;

   GET_CONTACT_PAYMENTS_HISTORY_REQUEST: string;
   GET_CONTACT_PAYMENTS_HISTORY_SUCCESS: string;
   GET_CONTACT_PAYMENTS_HISTORY_FAILURE: string;
   GET_CONTACT_PAYMENTS_HISTORY_RESET: string;

   SAVE_CONTACT_NOTE_REQUEST: string;
   SAVE_CONTACT_NOTE_SUCCESS: string;
   SAVE_CONTACT_NOTE_FAILURE: string;

   SEARCH_PAYMENTS_REQUEST: string;
   SEARCH_PAYMENTS_SUCCESS: string;
   SEARCH_PAYMENTS_FAILURE: string;

   GET_REGISTRATIONS_BY_CONTACT_REQUEST: string;
   GET_REGISTRATIONS_BY_CONTACT_SUCCESS: string;
   GET_REGISTRATIONS_BY_CONTACT_FAILURE: string;

   SAVE_REGISTRATION_REQUEST: string;
   SAVE_REGISTRATION_SUCCESS: string;
   SAVE_REGISTRATION_FAILURE: string;

   GET_TRAINING_PROVIDERS_REQUEST: string;
   GET_TRAINING_PROVIDERS_SUCCESS: string;
   GET_TRAINING_PROVIDERS_FAILURE: string;

   VALIDATE_CONTACT_REGISTRATION_NUMBERS_REQUEST: string;
   VALIDATE_CONTACT_REGISTRATION_NUMBERS_SUCCESS: string;
   VALIDATE_CONTACT_REGISTRATION_NUMBERS_FAILURE: string;

   ADD_VALIDATED_RESULTS_REQUEST: string;
   ADD_VALIDATED_RESULTS_SUCCESS: string;
   ADD_VALIDATED_RESULTS_FAILURE: string;

   SET_RESULT_VALIDATION_LINES: string;
   UPDATE_RESULT_VALIDATION_LINE: string;
   REMOVE_RESULT_VALIDATION_LINE: string;

   EXPORT_NOTES_BY_CONTACT_ID_REQUEST: string;
   EXPORT_NOTES_BY_CONTACT_ID_SUCCESS: string;
   EXPORT_NOTES_BY_CONTACT_ID_FAILURE: string;
}

type ContactTypeNames = Readonly<TypeNames>;

export interface ContactCreators {
   getContactByIdRequest: (id: string) => AnyAction;
   getContactByIdSuccess: (data: Contact | undefined) => AnyAction;
   getContactByIdFailure: (error: ApiError) => AnyAction;

   getContactByRegistrationNumberRequest: (id: string) => AnyAction;
   getContactByRegistrationNumberSuccess: (
      data: Contact | undefined
   ) => AnyAction;
   getContactByRegistrationNumberFailure: (error: ApiError) => AnyAction;
   getContactByRegistrationNumberReset: () => AnyAction;

   getContactScreenDataRequest: (id?: number) => AnyAction;
   getContactScreenDataSuccess: (data: Contact | undefined) => AnyAction;
   getContactScreenDataFailure: (error: ApiError) => AnyAction;
   getContactScreenDataReset: () => AnyAction;

   saveContactDataRequest: (data: ContactEditModel) => AnyAction;
   saveContactDataSuccess: (data: Contact | undefined) => AnyAction;
   saveContactDataFailure: (error: ApiError) => AnyAction;

   saveAdminUserRequest: (adminUserData: UserEditModel) => AnyAction;
   saveAdminUserSuccess: (data: number) => AnyAction;
   saveAdminUserFailure: (error: ApiError) => AnyAction;
   saveAdminUserReset: () => AnyAction;

   getAdminUserRequest: (adminUserId: string) => AnyAction;
   getAdminUserSuccess: (data: UserEditModel) => AnyAction;
   getAdminUserFailure: (error: ApiError) => AnyAction;

   searchAdminUserRequest: (adminUserSearchFilter: UserEditModel) => AnyAction;
   searchAdminUserSuccess: (data: UserEditModel[]) => AnyAction;
   searchAdminUserFailure: (error: ApiError) => AnyAction;
   searchAdminUserReset: () => AnyAction;

   searchContactRequest: (query: ContactSearchQuery) => AnyAction;
   searchContactSuccess: (data: readonly ContactSearchResult[]) => AnyAction;
   searchContactFailure: (error: ApiError) => AnyAction;

   getContactNotesRequest: (id: string) => AnyAction;
   getContactNotesSuccess: (
      data: readonly ContactNote[] | undefined
   ) => AnyAction;
   getContactNotesFailure: (error: ApiError) => AnyAction;

   getContactPaymentsHistoryRequest: (id: string) => AnyAction;
   getContactPaymentsHistorySuccess: (
      data: readonly PaymentSearchResult[] | undefined
   ) => AnyAction;
   getContactPaymentsHistoryFailure: (error: ApiError) => AnyAction;
   getContactPaymentsHistoryReset: () => AnyAction;

   saveContactNoteRequest: (data: ContactNoteInputModel) => AnyAction;
   saveContactNoteSuccess: (contactNote: ContactNote) => AnyAction;
   saveContactNoteFailure: (error: ApiError) => AnyAction;

   searchPaymentsRequest: (query: PaymentSearchQuery) => AnyAction;
   searchPaymentsSuccess: (data: readonly PaymentSearchResult[]) => AnyAction;
   searchPaymentsFailure: (error: ApiError) => AnyAction;

   getRegistrationsByContactRequest: (id: string) => AnyAction;
   getRegistrationsByContactSuccess: (
      data: readonly Registration[]
   ) => AnyAction;
   getRegistrationsByContactFailure: (error: ApiError) => AnyAction;

   saveRegistrationRequest: (data: Registration) => AnyAction;
   saveRegistrationSuccess: (registration: Registration) => AnyAction;
   saveRegistrationFailure: (error: ApiError) => AnyAction;

   getTrainingProvidersRequest: () => AnyAction;
   getTrainingProvidersSuccess: (data: readonly Contact[]) => AnyAction;
   getTrainingProvidersFailure: (error: ApiError) => AnyAction;

   validateContactRegistrationNumbersRequest: (
      contactValidations: RegistrationNumberContactValidationReadonly[]
   ) => AnyAction;
   validateContactRegistrationNumbersSuccess: (
      contactValidations: RegistrationNumberContactValidationReadonly[]
   ) => AnyAction;
   validateContactRegistrationNumbersFailure: (error: ApiError) => AnyAction;

   setResultValidationLines: (
      results: RegistrationNumberContactValidationReadonly[]
   ) => AnyAction;
   updateResultValidationLine: (
      result: RegistrationNumberContactValidationReadonly
   ) => AnyAction;
   removeResultValidationLine: (
      result: RegistrationNumberContactValidationReadonly
   ) => AnyAction;

   exportNotesByContactIdRequest: (id: string) => AnyAction;
   exportNotesByContactIdSuccess: (data: Blob | undefined) => AnyAction;
   exportNotesByContactIdFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ContactStateReadonly = {
   contact: ({} as unknown) as Contact,
   screenData: undefined,
   searchResults: null,
   notes: [],
   newNote: ({} as unknown) as ContactNote,
   paymentSearchResults: [],
   paymentHistory: [],
   trainingProviders: [],
   contactValidations: [],
   noteExport: new Blob(),
};

/* ------------- Reducers ------------- */
const getContactByIdSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, contact: action.data };
};

const getContactByIdReset = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, contact: ({} as unknown) as Contact };
};

const getContactScreenDataSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      return {
         ...state,
         screenData: action.data.contact,
      };
   }
   return state;
};

const getContactScreenDataReset = (
   state: ContactStateReadonly
): ContactStateReadonly => {
   return {
      ...state,
      screenData: undefined,
   };
};

const saveContactDataSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      const contact = action.data as Contact;
      return {
         ...state,
         newContactId: contact.contactId,
         contact: action.data,
         screenData: action.data,
      };
   }
   return state;
};

const saveAdminUserSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      return {
         ...state,
         newAdminUserId: action.data,
      };
   }
   return state;
};

const getAdminUserReset = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return {
      ...state,
      adminUser: undefined,
      newAdminUserId: undefined,
   };
};

const getAdminUserSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      return {
         ...state,
         adminUser: action.data,
      };
   }
   return state;
};

const searchAdminUserSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      return {
         ...state,
         adminUserSearchResult: action.data,
      };
   }
   return state;
};

const searchAdminUserReset = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return {
      ...state,
      adminUserSearchResult: undefined,
   };
};

const saveContactDataReset = (
   state: ContactStateReadonly
): ContactStateReadonly => {
   return {
      ...state,
      newContactId: undefined,
   };
};

const searchPaymentsReset = (
   state: ContactStateReadonly
): ContactStateReadonly => {
   return { ...state, paymentSearchResults: [] };
};

const searchPaymentsSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, paymentSearchResults: action.data || [] };
};

const searchContactsSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, searchResults: action.data || [] };
};

const getContactNotesSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, notes: action.data };
};

const getContactPaymentsHistorySuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, paymentHistory: action.data };
};

const getContactPaymentsHistoryReset = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, paymentHistory: [] };
};

const saveContactNoteSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.contactNote) {
      const updateNote = state.notes.filter(note => {
         return note.contactNoteId === action.contactNote.contactNoteId;
      });

      if (updateNote.length > 0) {
         return {
            ...state,
            newNote: action.contactNote,
            notes: state.notes
               ? [
                    ...state.notes.filter(note => {
                       return (
                          note.contactNoteId !==
                          action.contactNote.contactNoteId
                       );
                    }),
                    action.contactNote,
                 ]
               : [action.contactNote],
         };
      } else {
         return {
            ...state,
            newNote: action.contactNote,
            notes: state.notes
               ? [action.contactNote, ...state.notes]
               : [action.contactNote],
         };
      }
   }
   return state;
};

const getRegistrationsByContactSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      return {
         ...state,
         contact: {
            ...state.contact,
            registration: action.data,
         },
      };
   }
   return state;
};

const saveRegistrationSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   if (action && action.data) {
      const registrations = [...state.contact.registration];

      const index = registrations.findIndex(
         (e): boolean => e.registrationId === action.data.registrationId
      );

      if (index === -1) {
         registrations.push(action.data);
      } else {
         registrations[index] = action.data;
      }

      return {
         ...state,
         contact: {
            ...state.contact,
            registration: [...registrations],
         },
      };
   }
   return state;
};

const getTrainingProvidersSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, trainingProviders: action.data || [] };
};

const validateContactRegistrationNumbersSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, contactValidations: action.contactValidations };
};

const setResultValidationLines = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, contactValidations: action.results };
};

const updateResultValidationLine = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return produce(state, (draftState): void => {
      const result = draftState.contactValidations.find(
         (r): boolean => r.id === action.result.id
      );
      if (result) {
         if (result.registrationNumber !== action.result.registrationNumber)
            result.isValid = false;
         result.registrationNumber = action.result.registrationNumber;
         result.mark = action.result.mark;
         result.passFailCode = action.result.passFailCode;
         result.contactName = action.result.contactName;
         result.resultDate = action.result.resultDate;
         result.isValidated = false;
      }
   });
};

const removeResultValidationLine = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return produce(state, (draftState): void => {
      draftState.contactValidations = draftState.contactValidations.filter(
         r => r.id !== action.result.id
      );
   });
};

const exportNotesByContactIdSuccess = (
   state: ContactStateReadonly,
   action: AnyAction
): ContactStateReadonly => {
   return { ...state, noteExport: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ContactTypeNames,
   ContactCreators
>('contact', {
   getContactByIdRequest: ['id'],
   getContactByIdSuccess: ['data'],
   getContactByIdFailure: ['error'],

   getContactByRegistrationNumberRequest: ['id'],
   getContactByRegistrationNumberSuccess: ['data'],
   getContactByRegistrationNumberFailure: ['error'],
   getContactByRegistrationNumberReset: [],
   resetContactEditData: [],

   getContactScreenDataRequest: ['id'],
   getContactScreenDataSuccess: ['data'],
   getContactScreenDataFailure: ['error'],
   getContactScreenDataReset: [],

   saveContactDataRequest: ['data'],
   saveContactDataSuccess: ['data'],
   saveContactDataFailure: ['error'],

   saveAdminUserRequest: ['adminUserData'],
   saveAdminUserSuccess: ['data'],
   saveAdminUserFailure: ['error'],
   saveAdminUserReset: [],

   getAdminUserRequest: ['adminUserId'],
   getAdminUserSuccess: ['data'],
   getAdminUserFailure: ['error'],

   searchAdminUserRequest: ['adminUserSearchFilter'],
   searchAdminUserSuccess: ['data'],
   searchAdminUserFailure: ['error'],
   searchAdminUserReset: [],

   searchContactRequest: ['query'],
   searchContactSuccess: ['data'],
   searchContactFailure: ['error'],

   getContactNotesRequest: ['id'],
   getContactNotesSuccess: ['data'],
   getContactNotesFailure: ['error'],

   getContactPaymentsHistoryRequest: ['id'],
   getContactPaymentsHistorySuccess: ['data'],
   getContactPaymentsHistoryFailure: ['error'],
   getContactPaymentsHistoryReset: [],

   saveContactNoteRequest: ['noteData'],
   saveContactNoteSuccess: ['contactNote'],
   saveContactNoteFailure: ['error'],

   searchPaymentsRequest: ['query'],
   searchPaymentsSuccess: ['data'],
   searchPaymentsFailure: ['error'],

   getRegistrationsByContactRequest: ['id'],
   getRegistrationsByContactSuccess: ['data'],
   getRegistrationsByContactFailure: ['error'],

   saveRegistrationRequest: ['registration'],
   saveRegistrationSuccess: ['data'],
   saveRegistrationFailure: ['error'],

   getTrainingProvidersRequest: [],
   getTrainingProvidersSuccess: ['data'],
   getTrainingProvidersFailure: ['error'],

   validateContactRegistrationNumbersRequest: ['contactValidations'],
   validateContactRegistrationNumbersSuccess: ['contactValidations'],
   validateContactRegistrationNumbersFailure: ['error'],
   setResultValidationLines: ['results'],
   updateResultValidationLine: ['result'],
   removeResultValidationLine: ['result'],

   exportNotesByContactIdRequest: ['id'],
   exportNotesByContactIdSuccess: ['data'],
   exportNotesByContactIdFailure: ['error'],
});

export const ContactTypes = Types;
export const ContactActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_CONTACT_BY_ID_SUCCESS]: getContactByIdSuccess,
   [Types.GET_CONTACT_BY_REGISTRATION_NUMBER_RESET]: getContactByIdReset,
   [Types.GET_CONTACT_BY_REGISTRATION_NUMBER_SUCCESS]: getContactByIdSuccess, // does the same thing
   [Types.GET_CONTACT_SCREEN_DATA_RESET]: getContactScreenDataReset,
   [Types.GET_CONTACT_SCREEN_DATA_SUCCESS]: getContactScreenDataSuccess,
   [Types.SAVE_CONTACT_DATA_REQUEST]: saveContactDataReset,
   [Types.SAVE_CONTACT_DATA_SUCCESS]: saveContactDataSuccess,
   [Types.SAVE_ADMIN_USER_SUCCESS]: saveAdminUserSuccess,
   [Types.SAVE_ADMIN_USER_RESET]: getAdminUserReset,
   [Types.GET_ADMIN_USER_SUCCESS]: getAdminUserSuccess,
   [Types.GET_ADMIN_USER_REQUEST]: getAdminUserReset,
   [Types.SEARCH_ADMIN_USER_SUCCESS]: searchAdminUserSuccess,
   [Types.SEARCH_ADMIN_USER_REQUEST]: searchAdminUserReset,
   [Types.SEARCH_ADMIN_USER_RESET]: searchAdminUserReset,
   [Types.SEARCH_CONTACT_SUCCESS]: searchContactsSuccess,
   [Types.GET_CONTACT_NOTES_SUCCESS]: getContactNotesSuccess,
   [Types.GET_CONTACT_PAYMENTS_HISTORY_SUCCESS]: getContactPaymentsHistorySuccess,
   [Types.GET_CONTACT_PAYMENTS_HISTORY_RESET]: getContactPaymentsHistoryReset,
   [Types.SAVE_CONTACT_NOTE_SUCCESS]: saveContactNoteSuccess,
   [Types.SEARCH_PAYMENTS_SUCCESS]: searchPaymentsSuccess,
   [Types.SEARCH_PAYMENTS_REQUEST]: searchPaymentsReset,
   [Types.SAVE_REGISTRATION_SUCCESS]: saveRegistrationSuccess,
   [Types.GET_REGISTRATIONS_BY_CONTACT_SUCCESS]: getRegistrationsByContactSuccess,
   [Types.GET_TRAINING_PROVIDERS_SUCCESS]: getTrainingProvidersSuccess,
   [Types.VALIDATE_CONTACT_REGISTRATION_NUMBERS_SUCCESS]: validateContactRegistrationNumbersSuccess,
   [Types.SET_RESULT_VALIDATION_LINES]: setResultValidationLines,
   [Types.UPDATE_RESULT_VALIDATION_LINE]: updateResultValidationLine,
   [Types.REMOVE_RESULT_VALIDATION_LINE]: removeResultValidationLine,
   [Types.EXPORT_NOTES_BY_CONTACT_ID_SUCCESS]: exportNotesByContactIdSuccess,
});

/* ------------- Selectors ------------- */

export const selectContact = (state: StateReadonly): Contact =>
   state.contact.contact;

export const selectContactScreenData = (
   state: StateReadonly
): ContactEditModel | undefined => state.contact.screenData;

export const selectAllRegistrations = (
   state: StateReadonly
): readonly Registration[] => state.contact.contact.registration || [];

export const selectActiveRegistrations = (
   state: StateReadonly
): readonly Registration[] => {
   const registrations = state.contact.contact.registration || [];
   return sortBy(
      registrations.filter(
         (r): boolean => r.registrationStatusCode === REGISTRATION_STATUS_ACTIVE
      ),
      (e: Registration) => {
         return DisciplineCodeRank[e.registrationDiscipline];
      }
   );
};

export const selectSearchResults = (
   state: StateReadonly
): ContactSearchResult | null => state.contact.searchResults;

export const selectContactNotes = (
   state: StateReadonly
): readonly ContactNote[] => state.contact.notes;

export const selectContactPaymentHistory = (
   state: StateReadonly
): readonly PaymentSearchResult[] => state.contact.paymentHistory;

export const selectPaymentsSearchResults = (
   state: StateReadonly
): readonly PaymentSearchResult[] => state.contact.paymentSearchResults;

export const selectTrainingProviders = (
   state: StateReadonly
): readonly Contact[] => state.contact.trainingProviders;

export const selectLicenceTrainingProviders = (
   state: StateReadonly
): readonly Contact[] =>
   state.contact.trainingProviders.filter(
      tp =>
         tp.contactTypeCode === CONTACT_TYPE_TRAINING_PROVIDER &&
         tp.licensingTrainingProvider
   );

export const selectContactValidations = (
   state: StateReadonly
): RegistrationNumberContactValidationReadonly[] =>
   state.contact.contactValidations;

export const selectExportedNote = (state: StateReadonly): Blob =>
   state.contact.noteExport;

export const selectNewAdminUserId = (
   state: StateReadonly
): string | undefined => state.contact.newAdminUserId;

export const selectAdminUser = (
   state: StateReadonly
): UserEditModel | undefined => state.contact.adminUser;

export const selectAdminUserSearchResult = (
   state: StateReadonly
): UserEditModel[] | undefined => state.contact.adminUserSearchResult;
