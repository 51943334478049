import { ApplicationCondition } from 'Models/Application/Data/ApplicationCondition';

export const STYLED_SUPERVISION_CONDITION = 'SPV';
export const LICENCE_SUPERVISION_CONDITION_DESCRIPTION = 'Licence Supervisor';
export const LICENCE_SUPERVISION_REG_DESCRIPTION = 'Supervisor is Current';
export const LICENCE_SUPERVISOR_CONDITION = 'LCSP';
export const LICENCE_UP_SKILLING = 'CBL'; // CPD
export const LICENCE_SUPERVISION_LICENCE_ACTIVE = 'SCV';
export const LICENCE_SUPERVISOR_APPROVAL_CONDITION = 'SDSS';
export const LICENCE_CHECK_SUPERVISOR_CITY_CONDITION = 'CSC'; // check city and threshold (variable from a setting parameter)
export const SUPERVISION_CONDITIONS = ['SPV', 'SDS', 'SCV'];
export const SUPERVISION_CONDITIONS_WITH_ID = ['SPV', 'LCSP'];
export const F2P_DISPLAY_CONDITION = 'F2P';

export const reduceSupervisionConditions = (
   conditions: readonly ApplicationCondition[]
): readonly ApplicationCondition[] => {
   let excludeSupervisorApprovalCondition = false;
   if (
      conditions.some(c => c.conditionCode === LICENCE_SUPERVISOR_CONDITION) &&
      conditions.some(
         c => c.conditionCode === LICENCE_SUPERVISOR_APPROVAL_CONDITION
      )
   ) {
      //if licence supervisor exists then we want to match the SDSS supervisor approval within its section
      //and not have it bundled within the normal Criterias
      excludeSupervisorApprovalCondition = true;
   }

   return conditions.filter(
      (c): boolean =>
         (!SUPERVISION_CONDITIONS.includes(c.conditionCode) ||
            c.conditionCode === STYLED_SUPERVISION_CONDITION) &&
         c.conditionCode !== LICENCE_SUPERVISOR_CONDITION &&
         (excludeSupervisorApprovalCondition
            ? c.conditionCode !== LICENCE_SUPERVISOR_APPROVAL_CONDITION
            : true)
   );
};
