import React from 'react';
import Button from 'react-md/lib/Buttons';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import FeeRequestLineDisplay from 'Models/FeesPayment/FeeRequestLineDisplay';
import { Column, CellInfo } from 'react-table';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import { Link } from 'react-router-dom';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { usePermissions } from 'Util/Helpers/Permissions';

interface FeeRequestLinesTableProps {
   onAdd: () => void;
   onSave: (saveAndNew?: boolean) => void;
   onReset: () => void;
   tableData: FeeRequestLineDisplay[];
   onEdit: (cell: CellInfo) => void;
   onDeleteFeeRequestLine: (cell: CellInfo) => void;
   onDeleteFeeRequest?: () => void;
   editMode?: boolean;
   hideControls?: boolean;
   paymentUrl?: string;
}

const FeeRequestLinesTable = ({
   onAdd,
   onSave,
   tableData,
   onEdit,
   onDeleteFeeRequest,
   onDeleteFeeRequestLine,
   onReset,
   editMode,
   hideControls,
   paymentUrl,
}: FeeRequestLinesTableProps): JSX.Element => {
   const editPermission = usePermissions('FeeRequest.Update');
   const createPermission = usePermissions('FeeRequest.Create');
   const hasPermission = editMode ? editPermission : createPermission;

   const controlsCell = (cell: CellInfo): JSX.Element => {
      if (hideControls || cell.original.paid || !hasPermission) {
         return <></>;
      }
      return (
         <>
            <Button
               className="table-row-button"
               onClick={(): void => onEdit(cell)}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) onEdit(cell);
               }}
               iconEl={
                  <FaIcon
                     icon="edit"
                     className="small-icon fee-request-line-table-icon"
                  />
               }
            />
            <Button
               className="table-row-button"
               onClick={(): void => onDeleteFeeRequestLine(cell)}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) onDeleteFeeRequestLine(cell);
               }}
               iconEl={
                  <FaIcon
                     icon="trash"
                     className="small-icon fee-request-line-table-icon"
                  />
               }
            />
         </>
      );
   };
   const amountCell = (cell: CellInfo): JSX.Element => {
      return (
         <>
            {cell.value
               ? formatCurrencyString(Number(cell.value))
               : formatCurrencyString(0)}
         </>
      );
   };

   const feeTypeCell = (cell: CellInfo): JSX.Element => {
      const feeTypeElement = (
         <>
            {cell.value}
            {cell.original.optional && (
               <div className="optional-fee">Optional</div>
            )}
         </>
      );
      return paymentUrl && hasPermission ? (
         <Link to={paymentUrl}>
            <Button className="fee-type-link-button">{feeTypeElement}</Button>
         </Link>
      ) : (
         feeTypeElement
      );
   };
   const paidCell = (cell: CellInfo): JSX.Element => {
      return <> {cell.value ? 'Paid' : 'Unpaid'}</>;
   };
   const COLUMN_HEADERS: Column<FeeRequestLineDisplay>[] = [
      {
         Header: 'Item',
         accessor: 'feeTypeDescription',
         Cell: feeTypeCell,
      },
      {
         Header: 'Description',
         accessor: 'creditReason',
      },
      {
         Header: 'Status',
         accessor: 'paid',
         Cell: paidCell,
         width: 200,
      },
      {
         Header: 'Amount',
         accessor: 'feeRequestLineAmount',
         Cell: amountCell,
         width: 300,
      },
      {
         Header: '',
         Cell: controlsCell,
         width: 200,
      },
   ];

   const renderControls = (): JSX.Element => {
      if (hideControls || !hasPermission) {
         return <></>;
      }
      return (
         <div className="controls">
            <Button
               flat
               swapTheming
               iconBefore
               className="green-btn"
               onClick={onAdd}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) onAdd();
               }}
               iconEl={<FaIcon icon="plus" />}
            >
               Add
            </Button>
            <Button
               flat
               primary
               swapTheming
               onClick={(): void => onSave(false)}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) onSave(false);
               }}
               disabled={!tableData.length}
            >
               Save
            </Button>
            <Button
               flat
               primary
               swapTheming
               onClick={(): void => onSave(true)}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) onSave(true);
               }}
               disabled={!tableData.length}
            >
               {editMode ? 'Save + Pay' : 'Save + New'}
            </Button>
            {!editMode && (
               <Button
                  flat
                  secondary
                  className="red-btn"
                  swapTheming
                  onClick={onReset}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) onReset();
                  }}
               >
                  Reset
               </Button>
            )}
            {editMode && onDeleteFeeRequest && (
               <Button
                  flat
                  secondary
                  className="red-btn"
                  swapTheming
                  onClick={onDeleteFeeRequest}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) onDeleteFeeRequest();
                  }}
               >
                  Delete
               </Button>
            )}
         </div>
      );
   };
   return (
      <>
         <div className="fee-request-lines">
            <ClientSideTable
               columns={COLUMN_HEADERS}
               showPageSizeOptions={false}
               showPageJump={false}
               showPagination={false}
               minRows={0}
               data={tableData}
               noDataText=""
            />
         </div>
         {renderControls()}
      </>
   );
};

export default FeeRequestLinesTable;
