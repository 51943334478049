import {
   isNullOrWhiteSpace,
   isValidDate,
   isValidEndDateAfterStartDate,
} from 'Util/Helpers/Validation';
import { FeeType } from './FeeType';
import { ApplicationType } from './ApplicationType';
import { getCurrentDateWithoutTime } from 'Util/Helpers/Date';

export interface Fee {
   feeId: number;
   applicationTypeCode: string;
   feeTypeCode: string;
   feeAmount: number;
   startDate: string;
   endDate: string;
   optional: boolean;
   applicationTypeCodeNavigation: ApplicationType | null;
   feeTypeCodeNavigation: FeeType | null;
}

type FeeReadonly = Readonly<Fee>;

export default FeeReadonly;

export const DefaultFeeModel: Fee = {
   feeId: 0,
   applicationTypeCode: '',
   feeTypeCode: '',
   feeAmount: 0,
   startDate: getCurrentDateWithoutTime(),
   endDate: '',
   optional: false,
   applicationTypeCodeNavigation: null,
   feeTypeCodeNavigation: null,
};

interface ValidationModel {
   applicationTypeCode: boolean;
   feeTypeCode: boolean;
   feeAmount: boolean;
   startDate: boolean;
   endDate: boolean;
}

export type FeeValidation = Readonly<ValidationModel>;

export const validateFee = (fee: Fee): FeeValidation => {
   return {
      applicationTypeCode: !isNullOrWhiteSpace(fee.applicationTypeCode),
      feeTypeCode: !isNullOrWhiteSpace(fee.feeTypeCode),
      feeAmount: !isNullOrWhiteSpace(fee.feeAmount),
      startDate:
         !isNullOrWhiteSpace(fee.startDate) && isValidDate(fee.startDate),
      endDate:
         isValidDate(fee.endDate) &&
         isValidEndDateAfterStartDate(fee.startDate, fee.endDate),
   };
};
