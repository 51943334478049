interface ContactExamEligibilityOtherNotesDto {
   contactExamEligibilityOtherNotesId: number;
   otherNotes: string | null;
   contactId: number;
   contactExamEligibilityId: number | null;
   courseCriteriaId: number | null;
}

type ContactExamEligibilityOtherNotesDtoReadonly = Readonly<
   ContactExamEligibilityOtherNotesDto
>;

export default ContactExamEligibilityOtherNotesDtoReadonly;

export const DefaultContactExamEligibilityOtherNotesDtoModel: ContactExamEligibilityOtherNotesDto = {
   contactExamEligibilityOtherNotesId: 0,
   otherNotes: null,
   contactId: 0,
   contactExamEligibilityId: null,
   courseCriteriaId: null,
};
