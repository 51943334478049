// Course Criteria
export const CRITERIA_OTHER = 'Other';
export const CRITERIA_NOT_ELIGIBLE = 'Not Eligible';
export const NOT_ELIGIBLE_DISPLAY_ORDER = 99;
export const CURRENTLY_ENROLLED_DISPLAY_ORDER = 2;
export const COMPLETED_NATION_CERTIFICATE_DISPLAY_ORDER = 1;
export const CERTIFYING_SECTION_52_EXEMPTION_APPROVED = 3;

// Course Numbers
export const TRADESMAN_PLUMBER_COURSE_NUMBER_STRING = '9192';
export const TRADESMAN_GASFITTER_COURSE_NUMBER_STRING = '9193';
export const TRADESMAN_DRAINLAYER_COURSE_NUMBER_STRING = '9197';
export const CERTIFYING_PLUMBER_COURSE_NUMBER_STRING = '9195';
export const CERTIFYING_GASFITTER_COURSE_NUMBER_STRING = '9196';
export const CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING = '9198';

export type CRITERIA_NUMBERS =
   | '9192' // Tradesman Plumber Registration Examination
   | '9193' // Tradesman Gasfitter Registration Examination
   | '9197' // Tradesman Drainlayer Registration Examination
   | '9195' // Certifying Plumber Examination
   | '9196' // Certifying Gasfitter Examination
   | '9198'; // Certifying Drainlayer Examination
