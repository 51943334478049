import React from 'react';
import { Column, CellInfo } from 'react-table';
import Moment from 'moment-timezone';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { Link, useLocation } from 'react-router-dom';
import { CANCELLED } from 'Util/Constants/PaymentStatus';
import { get } from 'lodash-es';
import ApplicationSearchResultReadonly from 'Models/Application/Data/ApplicationSearchResult';
import { ServerSideTable } from 'Components/Common/ServerSideTable/ServerSideTable';
import { PagedQuery } from 'Models/Other/PagedQuery';

const getApplicationUrl = (pathname: string, applicationId: number): string => {
   return applicationId ? pathname + '/' + applicationId : pathname;
};

const IconHeader = (): JSX.Element => {
   return <FaIcon icon="ellipsis-h" />;
};

const DateRenderer = (cell: CellInfo): JSX.Element => {
   return (
      <div>
         {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
      </div>
   );
};

interface ResultsPanelProps {
   applications: ApplicationSearchResultReadonly | null;
   isLoading: boolean;
   paging: PagedQuery;
   onPagingChanged: (paging: PagedQuery) => void;
}

interface ApplicationDisplayModel {
   applicationId: number;
   licenceYear: string;
   applicationType: string;
   applicationStatus: string;
   dateCreated: string;
   paymentDate: string;
}

const generateApplicationSearchResults = (
   applications: ApplicationSearchResultReadonly | null
): ApplicationDisplayModel[] | [] => {
   if (!applications || !applications.applications) {
      return [];
   }

   return applications.applications.map(
      (application): ApplicationDisplayModel => {
         const paymentDateItem = get(
            application,
            'feeRequest[0].feeRequestLine[0].paymentFeeRequestLine[0].payment.paymentDate',
            null
         );
         const paymentStatus = get(
            application,
            'feeRequest[0].feeRequestLine[0].paymentFeeRequestLine[0].payment.paymentStatusCode',
            null
         );
         return {
            applicationId: application.applicationId,
            licenceYear: application.licenceYearDescription
               ? application.licenceYearDescription
               : ' - ',
            applicationType: application.applicationTypeDescription,
            applicationStatus: application.applicationStatusDescription,
            dateCreated: application.enteredDate,
            paymentDate: paymentStatus !== CANCELLED ? paymentDateItem : null,
         };
      }
   );
};

const ResultsPanel = ({
   applications,
   isLoading,
   paging,
   onPagingChanged,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const location = useLocation();
   const totalCount =
      applications && applications.totalCount ? applications.totalCount : 0;

   const COLUMN_HEADERS: Column<ApplicationDisplayModel>[] = [
      {
         Header: 'Application ID',
         accessor: 'applicationId',
      },
      {
         Header: 'Licence Year',
         accessor: 'licenceYear',
      },
      {
         Header: 'Application Type',
         accessor: 'applicationType',
      },
      {
         Header: 'Application Status',
         accessor: 'applicationStatus',
      },
      {
         Header: 'Created Date',
         accessor: 'dateCreated',
         Cell: DateRenderer,
      },
      {
         Header: 'Payment Date',
         accessor: 'paymentDate',
         Cell: DateRenderer,
      },
      {
         Header: IconHeader,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         sortable: false,
         accessor: FN_EMPTY_VOID,
         Cell: (cell: CellInfo): JSX.Element => {
            const applicationId =
               cell && cell.original && cell.original.applicationId
                  ? cell.original.applicationId
                  : 0;
            const link = getApplicationUrl(location.pathname, applicationId);

            return (
               <Link to={link} className="disable-link-styles">
                  <FaIcon
                     className="small-icon md-pointer--hover"
                     icon="edit"
                  />
               </Link>
            );
         },
      },
   ];

   return (
      <ServerSideTable
         data={generateApplicationSearchResults(applications)}
         onPagingChanged={onPagingChanged}
         paging={paging}
         headers={COLUMN_HEADERS}
         isLoading={isLoading}
         totalResults={totalCount}
      />
   );
};

export default ResultsPanel;
