import React from 'react';
import { ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import '../../Finance/Finance.scss';
import { FinancialExtractResultItem } from 'Models/BackOffice/FinancialExtractResultItem';
import { Grid, Cell } from 'react-md/lib/Grids';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import {
   CASH,
   CHEQUE,
   CREDIT_CARD,
   DIRECT_CREDIT,
} from 'Util/Constants/PaymentMethod';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface FinancialExtractTotalProps {
   totalResults: readonly FinancialExtractResultItem[];
}

const paymentMethodAmount = (
   inList: readonly FinancialExtractResultItem[],
   inPaymentMethodCode: string
): number => {
   let returnAmount = 0;
   returnAmount = inList
      .filter(x => x.paymentMethodCode?.trim() === inPaymentMethodCode)
      .reduce((sum, currentValue) => {
         return sum + currentValue.amount;
      }, 0);

   return returnAmount;
};

const paymentMethodGrandTotal = (
   inValue: readonly FinancialExtractResultItem[]
): number => {
   let totalGrandRow = 0;
   totalGrandRow = inValue.reduce((sum, currentValue) => {
      return sum + currentValue.amount;
   }, 0);

   return totalGrandRow;
};

const FinancialExtractTotals = ({
   totalResults,
}: Readonly<FinancialExtractTotalProps>): JSX.Element => {
   if (!totalResults.length) return <></>;

   return (
      <ExpansionPanel
         label="Total Summary"
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
      >
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-row-label" size={4}>
               Total Cheque
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(paymentMethodAmount(totalResults, CHEQUE))}
            </Cell>
         </Grid>
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-row-label" size={4}>
               Total Cash
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(paymentMethodAmount(totalResults, CASH))}
            </Cell>
         </Grid>
         <hr></hr>
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-cell-label" size={4}>
               Total Cheque and Cash
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(
                  paymentMethodAmount(totalResults, CHEQUE) +
                     paymentMethodAmount(totalResults, CASH)
               )}
            </Cell>
         </Grid>
         <hr></hr>
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-row-label" size={4}>
               Total Credit Cards
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(
                  paymentMethodAmount(totalResults, CREDIT_CARD)
               )}
            </Cell>
         </Grid>
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-row-label" size={4}>
               Total Direct Credit
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(
                  paymentMethodAmount(totalResults, DIRECT_CREDIT)
               )}
            </Cell>
         </Grid>
         <hr></hr>
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-cell-label" size={4}>
               Total Credit Cards and Direct Credit
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(
                  paymentMethodAmount(totalResults, CREDIT_CARD) +
                     paymentMethodAmount(totalResults, DIRECT_CREDIT)
               )}
            </Cell>
         </Grid>
         <hr className="grand-total-top"></hr>
         <Grid>
            <Cell size={5}></Cell>
            <Cell className="total-cell-label" size={4}>
               Grand Total
            </Cell>
            <Cell size={3}>
               {formatCurrencyString(paymentMethodGrandTotal(totalResults))}
            </Cell>
         </Grid>

         <hr className="grand-total-bottom"></hr>
      </ExpansionPanel>
   );
};

export default FinancialExtractTotals;
