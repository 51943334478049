import React from 'react';
import { FontIcon, FontIconProps } from 'react-md/lib/FontIcons';
import './FaIcon.scss';

interface FaIconProps extends Omit<FontIconProps, 'iconClassName'> {
   icon: string;
   className?: string;
}

const FaIcon = ({
   icon,
   className = '',
   ...rest
}: Readonly<FaIconProps>): JSX.Element => {
   return (
      <FontIcon iconClassName={`fa fa-fw fa-${icon} ${className}`} {...rest} />
   );
};

export default FaIcon;
