import React from 'react';
import { Switch, useLocation, matchPath } from 'react-router';
import Contact from 'Models/Contact/Data/Contact';
import { useSelector } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import {
   buildDocumentTitle,
   useDocumentTitle,
} from 'Util/Navigation/useDocumentTitle';
import { getDisplayContactName } from 'Util/Helpers/Contact';
import { ApplicationCreate } from './ApplicationCreate';
import { ApplicationOverview } from './ApplicationOverview';
import { ApplicationViewEdit } from './ApplicationViewEdit';
import { PgdbRoute } from 'Util/Navigation/PgdbRoute';

interface ApplicationParams {
   contactId: string;
   applicationId: string;
}

const getPageTitle = (contact: Contact): string[] => {
   const contactTitle = !contact
      ? ['Contact']
      : [getDisplayContactName(contact)];

   return ['Applications'].concat(contactTitle);
};

const ApplicationsTab = (): JSX.Element => {
   const contact = useSelector(
      (state: StateReadonly): Contact => state.contact.contact
   );
   const pageTitle = getPageTitle(contact);
   const docTitle = buildDocumentTitle(pageTitle);
   useDocumentTitle(docTitle);

   const location = useLocation();
   const routeParams = matchPath<ApplicationParams>(location.pathname, {
      path: '/contact/:contactId/applications/:applicationId',
   });

   return (
      <Switch>
         <PgdbRoute
            pageTitle={['Create'].concat(pageTitle)}
            permissions="Application.Create"
            permissionRedirectPath={`/contact/${contact.contactId}/applications`}
            component={ApplicationCreate}
            path="/contact/:contactId/applications/create"
         />
         <PgdbRoute
            pageTitle={[
               `Application #${
                  routeParams ? routeParams.params.applicationId : ''
               }`,
            ]}
            component={ApplicationViewEdit}
            path="/contact/:contactId/applications/:applicationId"
         />
         <PgdbRoute pageTitle={pageTitle} component={ApplicationOverview} />
      </Switch>
   );
};

export default ApplicationsTab;
