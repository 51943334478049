import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'react-md/lib/TextFields';
import {
   SupervisionActions,
   SupervisionTypes,
   selectSupervisionSearchResult,
} from 'State/Redux/SupervisionRedux';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import '../Supervision.scss';
import { selectContact } from 'State/Redux/ContactRedux';

import { useLicenceSupervisions } from 'Util/Helpers/Metadata';
import LoadingButton from 'Components/Common/LoadingButton';
import { toast } from 'react-toastify';
import SupervisionReadonly from 'Models/Supervision/Data/Supervision';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { getSupervisionStatus } from 'Util/Helpers/Supervision';
import { INVALID_SUPERVISOR_STATUS } from 'Models/Supervision/Dto/SupervisionStatusDto';

interface SupervisionInputCellProps {
   selectedSupervision: SupervisionReadonly;
}

const SupervisionInputCell = ({
   selectedSupervision,
}: Readonly<SupervisionInputCellProps>): JSX.Element => {
   const supervisionSearchResult = useSelector(selectSupervisionSearchResult);
   const currentContact = useSelector(selectContact);
   const isSuccess = useSelector(
      selectIsSuccess([SupervisionTypes.CHANGE_SUPERVISOR_REQUEST])
   );
   const isLoadingSearchSupervisor = useSelector(
      selectIsLoading([SupervisionTypes.SEARCH_SUPERVISOR_REQUEST])
   );
   const isLoadingChangeSupervisor = useSelector(
      selectIsLoading([SupervisionTypes.CHANGE_SUPERVISOR_REQUEST])
   );
   const dispatch = useDispatch();

   const [supervisorQuery, setSupervisorQuery] = useState('');
   const [shouldValidate, setShouldValidate] = useState(false);

   useEffect(() => {
      if (isSuccess) {
         setShouldValidate(false);
      }
   }, [isSuccess]);

   const licenceSupervisions = useLicenceSupervisions();

   const supervisionStatus = getSupervisionStatus(
      licenceSupervisions,
      supervisionSearchResult,
      selectedSupervision.licence.licenceTypeCode,
      selectedSupervision.licence.disciplineCode,
      selectedSupervision.licence.licenceYearNumber,
      currentContact
   );

   const invalidInput =
      INVALID_SUPERVISOR_STATUS.some(
         e => e === supervisionStatus.supervisorStatus
      ) &&
      !isLoadingSearchSupervisor &&
      shouldValidate &&
      !isSuccess;

   const changeDisabled = INVALID_SUPERVISOR_STATUS.some(
      e => e === supervisionStatus.supervisorStatus
   );
   const quickSearchErrorClass = invalidInput ? 'quick-search-error' : '';

   const searchSupervisor = (): void => {
      if (!isLoadingSearchSupervisor) {
         setShouldValidate(true);
         dispatch(SupervisionActions.searchSupervisorRequest(supervisorQuery));
      }
   };

   return (
      <>
         <TextField
            id="floating-center-title"
            label="Licence Number"
            lineDirection="center"
            placeholder="Supervisor"
            className="md-cell md-cell--6"
            inputClassName={getInputValidationClassName(invalidInput)}
            value={supervisorQuery}
            errorText={supervisionStatus.statusMessage}
            error={invalidInput}
            // style the height on the input element and remove any padding
            inlineIndicator={
               <FaIcon
                  icon="search"
                  className={`quick-search-icon ${quickSearchErrorClass}`}
                  onClick={(): void => {
                     searchSupervisor();
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) searchSupervisor();
                  }}
               />
            }
            onChange={(val): void => {
               setSupervisorQuery(val.toString());
            }}
            onBlur={(): void => {
               searchSupervisor();
            }}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) {
                  searchSupervisor();
               }
            }}
         />
         <TextField
            disabled
            id="floating-center-title"
            label="Supervisor Name"
            lineDirection="center"
            placeholder="Supervisor"
            className="md-cell md-cell--6"
            value={
               supervisionSearchResult ? supervisionSearchResult.fullName : ''
            }
         />
         <LoadingButton
            onClick={(): void => {
               if (
                  supervisionSearchResult &&
                  selectedSupervision.supervisionId &&
                  selectedSupervision.supervisorId
               ) {
                  dispatch(
                     SupervisionActions.changeSupervisorRequest({
                        supervisionId: selectedSupervision.supervisionId,
                        newSupervisorId: supervisionSearchResult.userId,
                     })
                  );
               } else {
                  toast.error('Please select a valid supervisor');
               }
            }}
            onKeyUp={(keyPress): void => {
               if (!isEnterKeyPress(keyPress)) return;
               if (
                  supervisionSearchResult &&
                  selectedSupervision.supervisionId &&
                  selectedSupervision.supervisorId
               ) {
                  dispatch(
                     SupervisionActions.changeSupervisorRequest({
                        supervisionId: selectedSupervision.supervisionId,
                        newSupervisorId: supervisionSearchResult.userId,
                     })
                  );
               } else {
                  toast.error('Please select a valid supervisor');
               }
            }}
            isLoading={isLoadingChangeSupervisor}
            className="md-cell md-cell--4"
            flat
            primary
            swapTheming
            disabled={changeDisabled}
         >
            Change
         </LoadingButton>
      </>
   );
};

export default React.memo(SupervisionInputCell);
