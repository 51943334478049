import React, { useState, useEffect } from 'react';
import TextField from 'react-md/lib/TextFields';
import { isValidPhoneNumber } from 'Util/Helpers/Validation';
import { InputProps } from '../Shared/InputProps';

interface PhoneInputProps extends InputProps {
   maxLength?: number;
   onChange: (val: string | number) => void;
}

const PhoneInput = ({
   error,
   onChange,
   floating,
   id,
   label,
   placeholder,
   className,
   inputClassName,
   errorText,
   maxLength,
   required,
   defaultValue,
   value,
   disabled,
}: Readonly<PhoneInputProps>): JSX.Element => {
   const [localVal, setLocalVal] = useState<string>();
   const [errorState, setError] = useState<boolean | undefined>(false);
   const [localErrorText, setLocalErrorText] = useState<string>();

   useEffect((): void => {
      setError(error);
      setLocalErrorText(errorText);
   }, [error, errorText]);

   const handleChange = (val: string | number): void => {
      if (onChange) {
         onChange(val);
      }
      setLocalVal(val.toString());
      setError(false);
   };

   const handleBlur = (): void => {
      if (localVal) {
         setError(!isValidPhoneNumber(localVal));
         setLocalErrorText(
            'Invalid Number. The number should start with 0 or +'
         );
      } else {
         setLocalErrorText(errorText);
      }
   };

   return (
      <TextField
         floating={floating}
         id={id}
         label={label}
         placeholder={placeholder}
         className={className}
         inputClassName={inputClassName}
         onChange={(val): void => handleChange(val)}
         errorText={localErrorText}
         error={errorState}
         maxLength={maxLength}
         onBlur={handleBlur}
         required={required ? required : localVal ? true : false}
         defaultValue={defaultValue}
         value={value}
         disabled={disabled}
      />
   );
};

export default PhoneInput;
