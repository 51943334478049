import {
   isValidFeeRequestNumber,
   isValidRegistrationNumber,
   isValidRequestStatus,
   isValidStartDate,
   isValidEndDate,
} from 'Util/Helpers/PaymentSearch';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { getCurrentDate } from 'Util/Helpers/Date';

interface Query {
   feeRequestNumber: string;
   registrationNumber: string;
   feeRequestStatus: string;
   startDate: string;
   endDate: string;
}

export type PaymentSearchQuery = Readonly<Query>;

interface QueryValidation {
   feeRequestNumber: boolean;
   registrationNumber: boolean;
   feeRequestStatus: boolean;
   startDate: boolean;
   endDate: boolean;
}

export type PaymentSearchQueryValidation = Readonly<QueryValidation>;

export const DefaultSearchModel: PaymentSearchQuery = {
   feeRequestNumber: '',
   registrationNumber: '',
   feeRequestStatus: '',
   startDate: '',
   endDate: getCurrentDate(),
};

export const DefaultValidationModel: PaymentSearchQueryValidation = {
   feeRequestNumber: false,
   registrationNumber: false,
   feeRequestStatus: false,
   startDate: false,
   endDate: false,
};

export const validateSearchQuery = (
   query: PaymentSearchQuery
): PaymentSearchQueryValidation => {
   return {
      feeRequestNumber:
         isNullOrWhiteSpace(query.feeRequestNumber) ||
         isValidFeeRequestNumber(query.feeRequestNumber),
      registrationNumber:
         isNullOrWhiteSpace(query.registrationNumber) ||
         isValidRegistrationNumber(query.registrationNumber),
      feeRequestStatus:
         isNullOrWhiteSpace(query.feeRequestStatus) ||
         isValidRequestStatus(query.feeRequestStatus),
      startDate:
         isNullOrWhiteSpace(query.startDate) ||
         isValidStartDate(query.startDate),
      endDate:
         isNullOrWhiteSpace(query.endDate) || isValidEndDate(query.endDate),
   };
};

export const filterToValidQuery = (
   query: PaymentSearchQuery
): PaymentSearchQuery => {
   return {
      feeRequestNumber: !isNullOrWhiteSpace(query.feeRequestNumber)
         ? query.feeRequestNumber
         : '',
      registrationNumber: !isNullOrWhiteSpace(query.registrationNumber)
         ? query.registrationNumber
         : '',
      feeRequestStatus: isValidRequestStatus(query.feeRequestStatus)
         ? query.feeRequestStatus
         : '',
      startDate: isValidStartDate(query.startDate) ? query.startDate : '',
      endDate: isValidEndDate(query.endDate) ? query.endDate : '',
   };
};

export const isValidSearchQuery = (query: PaymentSearchQuery): boolean => {
   return (
      !isNullOrWhiteSpace(query.feeRequestNumber) ||
      !isNullOrWhiteSpace(query.registrationNumber) ||
      !isNullOrWhiteSpace(query.feeRequestStatus) ||
      !isNullOrWhiteSpace(query.startDate) ||
      !isNullOrWhiteSpace(query.endDate)
   );
};
