import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import ViewPaymentMethod from './ViewPaymentMethod';
import AddPaymentMethod from './AddPaymentMethod';

const paymentMethodTabs: readonly RoutedTab[] = [
   {
      label: 'View',
      route: '',
      component: ViewPaymentMethod,
      pageTitle: 'View',
      exact: true,
      permissions: 'SystemParameter.Read',
   },
   {
      label: 'Add',
      route: '/add',
      component: AddPaymentMethod,
      pageTitle: 'Add',
      permissions: 'SystemParameter.Create',
   },
];

const PaymentMethod = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={paymentMethodTabs}
         baseUrl="/superuser/payment-method"
         panelClassName="paymentMethod"
         pageTitle="Payment Method"
      />
   );
};

export default PaymentMethod;
