import React, { MutableRefObject } from 'react';
import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ContactDetailsSection, {
   ReferenceApplicantForm,
} from './ContactDetailsSection';
import Section2, { FitAndProperApplicantForm } from './Section_2';
import './RegistrationApplication.scss';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import FileUploadSection from './FileUploadSection';

interface AccordionInputProps {
   isEditing: boolean;
   contactId: number;
   applicantDetailsRef: MutableRefObject<ReferenceApplicantForm>;
   applicantFitAndProperRef: MutableRefObject<FitAndProperApplicantForm>;
   userId: string;
   fitAndProper: QuestionDto[];
   applicationId: number;
}

//TODO rename this when nobody working on this file
const section1 = ({
   isEditing,
   contactId,
   applicantDetailsRef,
   applicantFitAndProperRef,
   userId,
   fitAndProper,
   applicationId,
}: Readonly<AccordionInputProps>): JSX.Element => {
   return (
      <Accordion allowMultipleExpanded allowZeroExpanded>
         <AccordionItem>
            <AccordionItemHeading>
               <AccordionItemButton>
                  {`Section 1/3: Applicant's and public registration details`}
               </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
               <ContactDetailsSection
                  isEditing={isEditing}
                  contactId={contactId}
                  ref={applicantDetailsRef}
               />
            </AccordionItemPanel>
         </AccordionItem>
         <AccordionItem>
            <AccordionItemHeading>
               <AccordionItemButton>
                  {`Section 2/3: Fit and proper person details`}
               </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
               <Section2
                  isEditing={isEditing}
                  userId={userId}
                  fitAndProper={fitAndProper}
                  ref={applicantFitAndProperRef}
               />
            </AccordionItemPanel>
         </AccordionItem>
         <AccordionItem>
            <AccordionItemHeading>
               <AccordionItemButton>
                  {`Section 3/3: Upload all documents`}
               </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
               <FileUploadSection applicationId={applicationId} />
            </AccordionItemPanel>
         </AccordionItem>
      </Accordion>
   );
};

export default section1;
