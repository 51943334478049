import React from 'react';
import Button from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import './CancelApplicationModalBody.scss';

interface CancelApplicationModalBodyProps {
   onConfirm: () => void;
   onCancel: () => void;
}

export const CancelApplicationModalBody = ({
   onConfirm,
   onCancel,
}: Readonly<CancelApplicationModalBodyProps>): JSX.Element => {
   return (
      <div className="md-grid md-cell--12">
         <div className="md-grid md-cell--12 grey-background cancel-application-modal">
            <div className="md-grid md-cell--12">
               Are you sure you want to cancel this application? This operation
               cannot be undone.
            </div>
            <div className="md-grid md-cell--12">
               <Button
                  className="btn-spacing"
                  onClick={onConfirm}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) onConfirm();
                  }}
                  flat
                  swapTheming
                  primary
               >
                  Yes
               </Button>
               <Button
                  className="red-btn btn-spacing"
                  onClick={onCancel}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) onCancel();
                  }}
                  flat
                  swapTheming
                  secondary
               >
                  No
               </Button>
            </div>
         </div>
      </div>
   );
};
