import React, { useState, useEffect, useCallback } from 'react';
import LoadingButton from '../LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import {
   ExaminationActions,
   selectExaminationEligibilityReportSent,
   ExaminationTypes,
} from 'State/Redux/ExaminationRedux';
import { selectContact } from 'State/Redux/ContactRedux';
import { toast } from 'react-toastify';
import {
   selectIsLoading,
   AsyncActions,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';

const SendReportToUserButton = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const examinationEligibilityReportSent = useSelector(
      selectExaminationEligibilityReportSent
   );
   const isSendingEmail = useSelector(
      selectIsLoading([
         ExaminationTypes.SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_REQUEST,
      ])
   );
   const isSentEmailError = useSelector(
      selectIsError([
         ExaminationTypes.SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_REQUEST,
      ])
   );
   const dispatch = useDispatch();

   const [sendEmailPressed, setSendEmailPressed] = useState<boolean>(false);

   const resetState = useCallback(() => {
      setSendEmailPressed(false);
      dispatch(
         AsyncActions.resetAsync([
            ExaminationTypes.SEND_EXAMINATION_ELIGIBILITY_REPORT_USER_REQUEST,
         ])
      );
      dispatch(ExaminationActions.resetExaminationEligibilityReportSentState());
   }, [setSendEmailPressed, dispatch]);

   useEffect((): void => {
      if (!isSendingEmail && sendEmailPressed) {
         if (examinationEligibilityReportSent) {
            toast.success(`Successfully Sent Email to ${contact.emailAddress}`);
            resetState();
         } else if (isSentEmailError) {
            toast.error(
               `Email could not be sent to ${contact.emailAddress}. Please try again later.`
            );
            resetState();
         }
      }
   }, [
      resetState,
      sendEmailPressed,
      isSentEmailError,
      isSendingEmail,
      examinationEligibilityReportSent,
      contact.emailAddress,
   ]);

   return (
      <LoadingButton
         flat
         primary
         onClick={(): void => {
            dispatch(
               ExaminationActions.resetExaminationEligibilityReportSentState()
            );
            dispatch(
               ExaminationActions.sendExaminationEligibilityReportUserRequest(
                  contact.contactId.toString()
               )
            );
            setSendEmailPressed(true);
         }}
         onKeyUp={(keyPress): void => {
            if (!isEnterKeyPress(keyPress)) return;
            dispatch(
               ExaminationActions.resetExaminationEligibilityReportSentState()
            );
            dispatch(
               ExaminationActions.sendExaminationEligibilityReportUserRequest(
                  contact.contactId.toString()
               )
            );
            setSendEmailPressed(true);
         }}
         isLoading={isSendingEmail}
      >
         Send Email
      </LoadingButton>
   );
};

export default React.memo(SendReportToUserButton);
