import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import SystemParametersReadonly, {
   SystemParameters,
} from 'Models/SystemParameters/Data/SystemParameters';
import { createScopedActions } from '.';
import produce from 'immer';

/* ------------- Interfaces for ReduxSauce ------------- */
interface SystemParametersState {
   systemParameters: SystemParametersReadonly[];
   systemParameter: SystemParametersReadonly | null;
}

export type SystemParametersStateReadonly = Readonly<SystemParametersState>;

interface TypeNames {
   UPDATE_SYSTEM_PARAMETERS_REQUEST: string;
   UPDATE_SYSTEM_PARAMETERS_SUCCESS: string;
   UPDATE_SYSTEM_PARAMETERS_FAILURE: string;

   GET_SYSTEM_PARAMETERS_REQUEST: string;
   GET_SYSTEM_PARAMETERS_SUCCESS: string;
   GET_SYSTEM_PARAMETERS_FAILURE: string;

   ADD_SYSTEM_PARAMETERS_REQUEST: string;
   ADD_SYSTEM_PARAMETERS_SUCCESS: string;
   ADD_SYSTEM_PARAMETERS_FAILURE: string;
}

type SystemParametersTypeNames = Readonly<TypeNames>;

export interface SystemParametersCreators {
   updateSystemParametersRequest: (
      boardMeetingDate: SystemParametersReadonly
   ) => AnyAction;
   updateSystemParametersSuccess: (data: SystemParametersReadonly) => AnyAction;
   updateSystemParametersFailure: (error: ApiError) => AnyAction;

   getSystemParametersRequest: () => AnyAction;
   getSystemParametersSuccess: (
      data: readonly SystemParameters[] | undefined
   ) => AnyAction;
   getSystemParametersFailure: (error: ApiError) => AnyAction;

   addSystemParametersRequest: (
      systemParameter: SystemParametersReadonly
   ) => AnyAction;
   addSystemParametersSuccess: (data: SystemParametersReadonly) => AnyAction;
   addSystemParametersFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: SystemParametersStateReadonly = {
   systemParameter: null,
   systemParameters: [],
};

/* ------------- Reducers ------------- */
const updateSystemParameterssSuccess = (
   state: SystemParametersStateReadonly,
   action: AnyAction
): SystemParametersStateReadonly => {
   return produce(state, (draftState): void => {
      const systemParameter = action.data;
      if (draftState.systemParameters) {
         const systemParameterFound = draftState.systemParameters.find(
            sysPram => {
               return sysPram.parameterCode === systemParameter.parameterCode;
            }
         );
         if (systemParameterFound) {
            const index = draftState.systemParameters.indexOf(
               systemParameterFound
            );
            draftState.systemParameters[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addSystemParameterssSuccess = (
   state: SystemParametersStateReadonly,
   action: AnyAction
): SystemParametersStateReadonly => {
   return { ...state, systemParameter: action.data };
};

const getSystemParameterssSuccess = (
   state: SystemParametersStateReadonly,
   action: AnyAction
): SystemParametersStateReadonly => {
   return { ...state, systemParameters: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   SystemParametersTypeNames,
   SystemParametersCreators
>('systemParameters', {
   updateSystemParametersRequest: ['systemParameter'],
   updateSystemParametersSuccess: ['data'],
   updateSystemParametersFailure: ['error'],

   getSystemParametersRequest: [],
   getSystemParametersSuccess: ['data'],
   getSystemParametersFailure: ['error'],

   addSystemParametersRequest: ['systemParameter'],
   addSystemParametersSuccess: ['data'],
   addSystemParametersFailure: ['error'],
});

export const SystemParametersTypes = Types;
export const SystemParametersActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_SYSTEM_PARAMETERS_SUCCESS]: updateSystemParameterssSuccess,
   [Types.GET_SYSTEM_PARAMETERS_SUCCESS]: getSystemParameterssSuccess,
   [Types.ADD_SYSTEM_PARAMETERS_SUCCESS]: addSystemParameterssSuccess,
});

/* ------------- Selectors ------------- */
export const selectSystemParameter = (
   state: StateReadonly
): SystemParametersReadonly | null => state.systemParameters.systemParameter;

export const selectSystemParameters = (
   state: StateReadonly
): readonly SystemParametersReadonly[] =>
   state.systemParameters.systemParameters;
