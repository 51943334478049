import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import LoadingButton from 'Components/Common/LoadingButton';
import AnswerHistoryDto from 'Models/FitAndProper/Dto/AnswerHistoryDto';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import {
   FitAndProperActions,
   FitAndProperTypes,
   selectFitAndProperHistory,
} from 'State/Redux/FitAndProperRedux';
import './FitAndProperHistory.scss';
import moment from 'moment-timezone';
import { NZ_TIMEZONE } from 'Util/Constants/Common';
interface FitAndProperHistoryProps {
   userId: string;
   questionId: number;
   question?: string;
}

interface FitAndProperHistoryDisplayModel {
   date: string;
   adminReason: string;
   updatedBy: string;
   answer: string;
   adminOverride: string;
}

const FitAndProperHistory = ({
   userId,
   questionId,
   question,
}: Readonly<FitAndProperHistoryProps>): JSX.Element => {
   const fitAndProperHistory = useSelector(selectFitAndProperHistory);
   const isLoadingFitAndProperHistory = useSelector(
      selectIsLoading([FitAndProperTypes.GET_ANSWER_HISTORY_BY_ID_REQUEST])
   );
   const dispatch = useDispatch();
   const loadFitAndProperHistory = (): void => {
      dispatch(
         FitAndProperActions.getAnswerHistoryByIdRequest(userId, questionId)
      );
   };
   useEffect(loadFitAndProperHistory, [userId, dispatch]);

   const generateFirAndProperHistoryResults = (): FitAndProperHistoryDisplayModel[] => {
      return fitAndProperHistory && fitAndProperHistory.length
         ? fitAndProperHistory.map(
              (
                 q: AnswerHistoryDto,
                 i: number
              ): FitAndProperHistoryDisplayModel => {
                 return {
                    date: moment(q.date)
                       .tz(NZ_TIMEZONE)
                       .format('DD/MM/YYYY H:mm'),
                    adminOverride: q.adminOverride === true ? 'Yes' : 'No',
                    adminReason: q.adminReason,
                    answer:
                       q.answer === true
                          ? 'Yes'
                          : q.answer === false
                          ? 'No'
                          : '',
                    updatedBy: q.updatedBy,
                 };
              }
           )
         : [];
   };

   const COLUMN_HEADERS: Column<FitAndProperHistoryDisplayModel>[] = [
      {
         Header: 'Date',
         accessor: 'date',
         sortable: false,
      },
      {
         Header: 'Updated By',
         accessor: 'updatedBy',
         sortable: false,
      },
      {
         Header: 'Admin Reason',
         accessor: 'adminReason',
         sortable: false,
         className: 'reason-column',
      },
      {
         Header: 'Answer',
         accessor: 'answer',
         sortable: false,
         className: 'answer-column',
         maxWidth: 100,
      },
   ];

   return (
      <div className="fit-and-proper-history">
         <h2>Answer History</h2>
         <div className="question-section">{question}</div>
         {isLoadingFitAndProperHistory ? (
            <div className="loading-button">
               <div className="loading-text">Loading History...</div>
               <LoadingButton isLoading={isLoadingFitAndProperHistory}>
                  Loding History...
               </LoadingButton>
            </div>
         ) : (
            <div>
               <ClientSideTable
                  data={generateFirAndProperHistoryResults()}
                  className="FitAndProperPanel"
                  columns={COLUMN_HEADERS}
                  defaultPageSize={
                     fitAndProperHistory ? fitAndProperHistory.length : 3
                  }
                  showPagination={false}
               />
            </div>
         )}
      </div>
   );
};

export default FitAndProperHistory;
