import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
   ResultActions,
   selectResultStatus,
   ResultTypes,
} from 'State/Redux/ResultRedux';
import { selectContact } from 'State/Redux/ContactRedux';
import './AssessmentResultsCard.scss';
import FaIcon from '../FaIcon/FaIcon';
import UserExaminationEligibilityDtoReadonly from 'Models/Examination/Dto/UserExaminationEligibilityDto';
import Button from 'react-md/lib/Buttons';
import DialogContainer from 'react-md/lib/Dialogs';
import ExamEligibilityPanel from '../ExamEligibilityPanel/ExamEligibilityPanel';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { ReactComponent as Spinner } from 'Components/Icons/loading-spinner.svg';

const loadingSection = (): JSX.Element => {
   return (
      <div className="assessment-result">
         <Spinner
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
         />{' '}
         Loading&hellip;
      </div>
   );
};

const getCPDSection = (completed: boolean, year: string): JSX.Element => {
   return (
      <div className="assessment-result">
         {getCheckIcon(completed)}
         <p>{`CPD ${year}`}</p>
      </div>
   );
};

const getCheckIcon = (isValid: boolean): JSX.Element => {
   return (
      <FaIcon
         icon={isValid ? 'check' : 'times'}
         className={isValid ? 'green' : 'red'}
      />
   );
};

const AssessmentsResultCard = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const resultStatus = useSelector(selectResultStatus);
   const isLoadingStatus = useSelector(
      selectIsLoading([ResultTypes.GET_RESULT_STATUS_BY_CONTACT_ID_REQUEST])
   );
   const dispatch = useDispatch();

   const [showExamModal, setShowExamModal] = useState(false);

   const getExamSection = (
      examEligibility: UserExaminationEligibilityDtoReadonly
   ): JSX.Element => {
      return (
         <div className="assessment-result">
            <Button
               flat
               className="exam-eligibility-btn"
               onClick={(
                  event: React.MouseEvent<HTMLElement, MouseEvent>
               ): void => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowExamModal(true);
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) setShowExamModal(true);
               }}
            >
               {getCheckIcon(examEligibility.isUserEligible)}
               <p>
                  Exam Eligibility <FaIcon icon="angle-down" />
               </p>
            </Button>
         </div>
      );
   };

   const closeExamModal = (): void => {
      dispatch(
         ResultActions.getResultStatusByContactIdRequest(
            contact.contactId.toString()
         )
      );
      setShowExamModal(false);
   };

   const content =
      isLoadingStatus || resultStatus === null ? (
         <>{loadingSection()}</>
      ) : (
         <>
            <div>
               {getCPDSection(
                  resultStatus.isPreviousYearCPDCompleted,
                  resultStatus.previousYearDescription
               )}
               {getCPDSection(
                  resultStatus.isCurrentYearCPDCompleted,
                  resultStatus.currentYearDescription
               )}
               {getExamSection(resultStatus.contactExaminationEligibility)}
            </div>
            <DialogContainer
               id="exam-dialog"
               className="exam-dialog"
               visible={showExamModal}
               title="Examination Eligibility"
               onHide={closeExamModal}
               focusOnMount={false}
               portal
               width={900}
            >
               <Button
                  icon
                  className="button-close"
                  onClick={closeExamModal}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) closeExamModal();
                  }}
               >
                  <FaIcon icon="times" />
               </Button>
               <ExamEligibilityPanel onClose={closeExamModal} />
            </DialogContainer>
         </>
      );

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--12 assessment-results-card">
            {content}
         </div>
      </div>
   );
};

export default AssessmentsResultCard;
