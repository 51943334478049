import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import PaymentDetailReadOnly from 'Models/Payment/Data/PaymentDetail';
import PaymentDetailFeeRequestLineReadOnly from 'Models/Payment/Data/PaymentDetailFeeRequestLine';
import PaymentEditDtoReadonly from 'Models/Payment/Dto/PaymentEditDto';
import PaymentResponseReadOnly from 'Models/Payment/Data/PaymentResponse';
import PaymentRedirectResponseReadOnly from 'Models/Payment/Data/PaymentRedirectResponse';
import PaymentRedirectRequestDtoReadOnly from 'Models/Payment/Dto/PaymentRedirectRequestDto';
import PaymentDataDtoReadOnly from 'Models/Payment/Dto/PaymentDataDto';
import PaymentCheckoutResponseReadOnly from 'Models/Payment/Data/PaymentCheckoutResponse';
import PaymentCheckoutRequestDtoReadOnly from 'Models/Payment/Dto/PaymentCheckoutRequestDto';

export interface PaymentServiceType {
   getPaymentDetail(
      applicationId: number | null,
      feeRequestId: number | null,
      feeRequestLineId: number | null,
      paymentId: number | null
   ): Promise<ApiResponse<PaymentDetailReadOnly>>;

   getPaymentDetailFeeRequestLine(
      feeRequestLineId: number | null
   ): Promise<ApiResponse<PaymentDetailFeeRequestLineReadOnly>>;

   updatePaymentDate(
      editModel: PaymentEditDtoReadonly
   ): Promise<ApiResponse<boolean>>;

   cancelPayment(
      editModel: PaymentEditDtoReadonly
   ): Promise<ApiResponse<boolean>>;

   getPaymentResponse(
      result: string
   ): Promise<ApiResponse<PaymentResponseReadOnly>>;

   getPaymentProcessingStatus(result: string): Promise<ApiResponse<boolean>>;

   savePaymentData(
      paymentData: PaymentDataDtoReadOnly
   ): Promise<ApiResponse<string>>;

   getPaymentRedirectUrl(
      redirectUrlRequestData: PaymentRedirectRequestDtoReadOnly
   ): Promise<ApiResponse<PaymentRedirectResponseReadOnly>>;

   saveNonOnlinePayment(
      paymentData: PaymentDataDtoReadOnly
   ): Promise<ApiResponse<number>>;

   getPaymentTransactionAmount(
      referenceNumber: string
   ): Promise<ApiResponse<number>>;

   getCardFee(referenceNumber: string): Promise<ApiResponse<number>>;

   submitPaymentCheckout(
      paymentCheckoutRequestData: PaymentCheckoutRequestDtoReadOnly
   ): Promise<ApiResponse<PaymentCheckoutResponseReadOnly>>;

   getAmIAllowed(): Promise<ApiResponse<boolean>>;
}

const create = (
   baseURL: string | undefined,
   practitionerApiBaseURL: string | undefined
): PaymentServiceType => {
   const api = createApi({ baseURL });
   const practitionerApi = createApi({ baseURL: practitionerApiBaseURL });
   const getPaymentDetail = (
      applicationId: number | null,
      feeRequestId: number | null,
      feeRequestLineId: number | null,
      paymentId: number | null
   ): Promise<ApiResponse<PaymentDetailReadOnly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      const parameters = {
         applicationId,
         feeRequestId,
         feeRequestLineId,
         paymentId,
      };
      const queryString = Object.entries(parameters)
         .filter(([_, value]): boolean => !!value)
         .map(([name, value]): string => `${name}=${value && value.toString()}`)
         .join('&');
      const url = '/PaymentAndFeeRequest/PaymentDetail?' + queryString;
      return authApi.get(url);
   };

   const getPaymentDetailFeeRequestLine = (
      feeRequestLineId: number
   ): Promise<ApiResponse<PaymentDetailFeeRequestLineReadOnly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(
         `/PaymentAndFeeRequest/PaymentDetailFeeRequestLine/${feeRequestLineId}`
      );
   };

   const updatePaymentDate = (
      editModel: PaymentEditDtoReadonly
   ): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put('/PaymentAndFeeRequest/UpdatePaymentDate', editModel);
   };

   const cancelPayment = (
      editModel: PaymentEditDtoReadonly
   ): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put('/PaymentAndFeeRequest/CancelPayment', editModel);
   };

   const getPaymentResponse = (
      result: string
   ): Promise<ApiResponse<PaymentResponseReadOnly>> => {
      return practitionerApi.get(`/Payment/GetResponse/${result}`);
   };

   const getPaymentProcessingStatus = (
      result: string
   ): Promise<ApiResponse<boolean>> => {
      return practitionerApi.get(`/Payment/GetStatus/${result}`);
   };

   const savePaymentData = (
      paymentData: PaymentDataDtoReadOnly
   ): Promise<ApiResponse<string>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(
         '/PaymentAndFeeRequest/SaveApplicationDataForCardPayment',
         paymentData
      );
   };

   const getPaymentRedirectUrl = (
      redirectUrlRequestData: PaymentRedirectRequestDtoReadOnly
   ): Promise<ApiResponse<PaymentRedirectResponseReadOnly>> => {
      const authApi = withAccessToken(practitionerApi, store.getState().oidc);
      return authApi.post(
         `/Payment/GetAuthorizedPaymentUrl/`,
         redirectUrlRequestData
      );
   };

   const saveNonOnlinePayment = (
      paymentData: PaymentDataDtoReadOnly
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(
         '/PaymentAndFeeRequest/CompleteNonCardPayment',
         paymentData
      );
   };

   const getPaymentTransactionAmount = (
      referenceNumber: string
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(practitionerApi, store.getState().oidc);
      return authApi.get(`/Payment/GetAmount/${referenceNumber}`);
   };

   const getCardFee = (
      referenceNumber: string
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(practitionerApi, store.getState().oidc);
      return authApi.get(`/Payment/CardFee/${referenceNumber}`);
   };

   const submitPaymentCheckout = (
      paymentCheckoutRequestData: PaymentCheckoutRequestDtoReadOnly
   ): Promise<ApiResponse<PaymentCheckoutResponseReadOnly>> => {
      const authApi = withAccessToken(practitionerApi, store.getState().oidc);
      return authApi.post(
         `/Payment/ProcessPayment/`,
         paymentCheckoutRequestData
      );
   };

   const getAmIAllowed = (): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(practitionerApi, store.getState().oidc);
      return authApi.get(`/Payment/AmIAllowed`);
   };

   return {
      getPaymentDetail,
      getPaymentDetailFeeRequestLine,
      updatePaymentDate,
      cancelPayment,
      getPaymentResponse,
      getPaymentProcessingStatus,
      savePaymentData,
      getPaymentRedirectUrl,
      saveNonOnlinePayment,
      getPaymentTransactionAmount,
      getCardFee,
      submitPaymentCheckout,
      getAmIAllowed,
   };
};

export default { create };
