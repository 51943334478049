import React, { useState, useCallback, useEffect } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectIsSuccess,
   selectIsLoading,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import { Checkbox } from 'react-md/lib/SelectionControls';
import {
   selectFeeType,
   FeeTypeTypes,
   FeeTypeActions,
} from 'State/Redux/FeeTypeRedux';
import {
   DefaultFeeTypeModel,
   validateFeeTypes,
} from 'Models/FeeType/Data/FeeType';

const AddFeeType = (): JSX.Element => {
   const feeType = useSelector(selectFeeType);
   const isLoading = useSelector(
      selectIsLoading([FeeTypeTypes.ADD_FEE_TYPE_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([FeeTypeTypes.ADD_FEE_TYPE_REQUEST])
   );

   const isError = useSelector(
      selectIsError([FeeTypeTypes.ADD_FEE_TYPE_REQUEST])
   );

   const dispatch = useDispatch();

   const [feeTypeModel, setFeeTypeModel] = useState(DefaultFeeTypeModel);

   const [addClicked, setAddClicked] = useState(false);

   const onAdd = useCallback((): void => {
      dispatch(
         FeeTypeActions.addFeeTypeRequest({
            ...feeTypeModel,
         })
      );
      setAddClicked(true);
   }, [dispatch, feeTypeModel]);

   if (addClicked && isSuccess && feeType !== null) {
      toast.success(`New fee type "${feeType.feeTypeCode}" created!`);
      setAddClicked(false);
      setFeeTypeModel(DefaultFeeTypeModel);
   }

   useEffect((): void => {
      if (addClicked && isError) {
         toast.error(`Cannot add fee, it may already exist.`);
         setAddClicked(false);
      }
   }, [addClicked, isError, feeType]);

   const isValidForm = Object.values(validateFeeTypes(feeTypeModel)).every(
      (v): boolean => !!v
   );

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add Fee Type"
            footer={null}
            expanded
            expanderIcon={<></>}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Code"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Code"
                     value={feeTypeModel.feeTypeCode}
                     maxLength={4}
                     onChange={(val): void => {
                        const newModel = {
                           ...feeTypeModel,
                           feeTypeCode: val.toString(),
                        };
                        setFeeTypeModel(newModel);
                     }}
                     required
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Description"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Description"
                     value={feeTypeModel.description}
                     onChange={(val): void => {
                        const newModel = {
                           ...feeTypeModel,
                           description: val.toString(),
                        };
                        setFeeTypeModel(newModel);
                     }}
                     required
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="GL Code"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="GL Code"
                     value={feeTypeModel.glcode}
                     onChange={(val): void => {
                        const newModel = {
                           ...feeTypeModel,
                           glcode: val.toString(),
                        };
                        setFeeTypeModel(newModel);
                     }}
                     required
                  />
               </div>

               <div className="md-cell md-cell--6">
                  <Checkbox
                     id="is-gst-inclusive"
                     className="md-cell md-cell--12"
                     label="GST Inclusive"
                     checked={feeTypeModel.isGstinclusive}
                     name="is-gst-inclusive"
                     onChange={(val): void => {
                        const newModel = {
                           ...feeTypeModel,
                           isGstinclusive: val,
                        };
                        setFeeTypeModel(newModel);
                     }}
                  />

                  <Checkbox
                     id="description-required"
                     className="md-cell md-cell--12"
                     label="Description Required"
                     checked={feeTypeModel.descriptionRequired}
                     name="description-required"
                     onChange={(val): void => {
                        const newModel = {
                           ...feeTypeModel,
                           descriptionRequired: val,
                        };
                        setFeeTypeModel(newModel);
                     }}
                  />

                  <Checkbox
                     id="is-deleted"
                     className="md-cell md-cell--12"
                     label="Deleted"
                     checked={feeTypeModel.isDeleted}
                     name="is-deleted"
                     onChange={(val): void => {
                        const newModel = {
                           ...feeTypeModel,
                           isDeleted: val,
                        };
                        setFeeTypeModel(newModel);
                     }}
                  />
               </div>

               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
                     disabled={!isValidForm}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        onAdd();
                     }}
                     onClick={(): void => {
                        onAdd();
                     }}
                  >
                     Add
                  </LoadingButton>
               </div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddFeeType;
