import React, { useState, useEffect } from 'react';
import { DatePickerProps, DatePicker } from 'react-md/lib/Pickers';
import moment from 'moment-timezone';
import {
   NZ_TIMEZONE,
   DISPLAY_DATE_FORMAT,
   SHORTYEAR_DATE_FORMAT,
   PARSE_DATE_FORMATS,
} from 'Util/Constants/Common';
import { stripTimeZone } from 'Util/Helpers/Date';
import { TextField, TextFieldProps } from 'react-md/lib/TextFields';
import { Button } from 'react-md/lib/Buttons';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { omit } from 'lodash-es';

const NZ_LOCALE = 'en-NZ';
const DEFAULT_DATE_LENGTH = 10;
const SHORT_DATE_LENGTH = 9;

interface DateInputProps
   extends Omit<
      DatePickerProps,
      | 'locales'
      | 'value'
      | 'defaultValue'
      | 'onChange'
      | 'portal'
      | 'lastChild'
      | 'disableScrollLocking'
      | 'renderNode'
      | 'inlineIndicator'
      | 'icon'
      | 'visible'
      | 'onVisibilityChange'
      | 'closeOnEsc'
   > {
   value?: string | null;
   defaultValue?: string | null;
   onChange?: (date: string, event: Event | undefined) => void;
   strict?: boolean;
}

const DateInput = ({
   value,
   defaultValue,
   onChange,
   strict,
   ...rest
}: Readonly<DateInputProps>): JSX.Element => {
   moment.tz.setDefault(NZ_TIMEZONE);

   const [textVal, setTextVal] = useState<string>(
      typeof value === 'string' && moment(value, PARSE_DATE_FORMATS).isValid()
         ? moment(value, PARSE_DATE_FORMATS).format(DISPLAY_DATE_FORMAT)
         : ''
   );

   useEffect(() => {
      if (
         value === undefined ||
         (typeof value === 'string' &&
            moment(value, PARSE_DATE_FORMATS).isValid() &&
            value.length >= DEFAULT_DATE_LENGTH)
      ) {
         setTextVal('');
         setLocalError(false);
         setTextVal(
            typeof value === 'string' &&
               moment(value, PARSE_DATE_FORMATS).isValid()
               ? moment(value, PARSE_DATE_FORMATS).format(DISPLAY_DATE_FORMAT)
               : ''
         );
      }
   }, [value]);

   const [localError, setLocalError] = useState<boolean>(
      rest.error ? rest.error : false
   );

   const [localErrorText, setLocalErrorText] = useState<React.ReactNode>(
      rest.errorText ? rest.errorText : ''
   );
   const wrappedValue =
      typeof value === 'string' && moment(value, PARSE_DATE_FORMATS).isValid()
         ? moment(value, PARSE_DATE_FORMATS).toDate()
         : '';
   const wrappedDefaultValue =
      typeof defaultValue === 'string' && moment(defaultValue).isValid()
         ? moment(defaultValue).toDate()
         : '';

   const wrappedOnChange = onChange
      ? (formattedDate: string, date: Date, event: Event): void => {
           setCalendarVisibility(false);
           const value = moment(date)
              .tz(NZ_TIMEZONE)
              .format(DISPLAY_DATE_FORMAT);
           validate(value);
           setTextVal(value);
           onChange(
              stripTimeZone(
                 moment(date)
                    .tz(NZ_TIMEZONE)
                    .format()
              ),
              event
           );
        }
      : undefined;

   const [calendarVisibility, setCalendarVisibility] = useState<boolean>(false);
   const showCalendar = (): void => {
      setCalendarVisibility(true);
   };

   const validate = (value: string): void => {
      let val = '';

      if (!rest.required && isNullOrWhiteSpace(value)) {
         if (onChange) {
            onChange(val, undefined);
         }
         return;
      }

      // This block will ensure that a user can enter a date in the format of d/mm/yyyy and it will change it to dd/mm/yyyy
      if (
         value.length === SHORT_DATE_LENGTH &&
         value.indexOf('/') === 1 &&
         !value.startsWith('0')
      ) {
         value = '0' + value;
         setTextVal(value);
      }

      const date = moment(
         value,
         [DISPLAY_DATE_FORMAT, SHORTYEAR_DATE_FORMAT],
         true
      );
      if (!date.isValid()) {
         setLocalError(true);
         setLocalErrorText('Please enter valid date');
         return;
      }

      setLocalError(false);
      val = stripTimeZone(
         moment(value, [DISPLAY_DATE_FORMAT, SHORTYEAR_DATE_FORMAT], true)
            .tz(NZ_TIMEZONE)
            .format()
      );

      if (onChange) {
         onChange(val, undefined);
      }

      if (rest.maxDate && date.isAfter(rest.maxDate)) {
         setLocalError(true);
         setLocalErrorText(
            `Date can't be later than ${moment(rest.maxDate).format(
               DISPLAY_DATE_FORMAT
            )}`
         );
         return;
      }

      if (rest.minDate && date.isBefore(rest.minDate)) {
         setLocalError(true);
         setLocalErrorText(
            `Date can't be earlier than ${moment(rest.minDate).format(
               DISPLAY_DATE_FORMAT
            )}`
         );
      }
   };

   const restForTextField: TextFieldProps = {
      ...omit(rest, ['maxDate', 'minDate']),
      inlineIndicator: (
         <Button
            icon
            className="text-fields__inline-btn"
            onClick={(): void => showCalendar()}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) showCalendar();
            }}
         >
            date_range
         </Button>
      ),
      onChange: (value: number | string, event: Event): void => {
         setCalendarVisibility(false);

         // Do not format until the date string length > DISPLAY_DATE_FORMAT.length
         const dateVal = value.toString();
         if (dateVal.length < DISPLAY_DATE_FORMAT.length) {
            if (onChange) {
               onChange(dateVal, event);
            }
            setTextVal(dateVal);
            return;
         }

         const date = moment(dateVal, DISPLAY_DATE_FORMAT, true);
         // set error on invalid date
         if (!date.isValid()) {
            if (onChange) {
               onChange('', event);
            }
            setTextVal('');
            setLocalError(true);
            return;
         }

         // format the date if valid
         const formattedDate = moment(date)
            .tz(NZ_TIMEZONE)
            .format();
         const textVal = date.tz(NZ_TIMEZONE).format(DISPLAY_DATE_FORMAT);
         validate(textVal);
         setTextVal(textVal);

         if (!onChange) return;

         onChange(stripTimeZone(formattedDate), event);
      },
      onBlur: (): void => {
         validate(textVal);
      },
      error: strict ? rest.error : localError,
      errorText: localErrorText,
      className: undefined,
      floating: true,
      placeholder: DISPLAY_DATE_FORMAT,
   };

   return (
      <>
         <div className={`date-input-container ${rest.className || ''}`}>
            <TextField value={textVal} {...restForTextField} />
         </div>

         <div hidden>
            <DatePicker
               {...rest}
               locales={NZ_LOCALE}
               value={wrappedValue}
               defaultValue={wrappedDefaultValue}
               onChange={wrappedOnChange}
               visible={calendarVisibility}
               closeOnEsc
               onVisibilityChange={(visible: boolean): void => {
                  setCalendarVisibility(visible);
               }}
               portal
               lastChild
               disableScrollLocking
               renderNode={document.body}
            />
         </div>
      </>
   );
};

export default DateInput;
