import React, { useEffect, useState } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import Button from 'react-md/lib/Buttons';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import ApplicationsPanel from './Panels/ApplicationsPanel';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationActions } from 'State/Redux/ApplicationRedux';
import { selectContact } from 'State/Redux/ContactRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { usePermissions } from 'Util/Helpers/Permissions';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import ApplicationsSearch from './ApplicationsSearch/ApplicationsSearch';
import HistoryPanel from './ApplicationsSearch/Panels/HistoryPanel';

export const ApplicationOverview = (): JSX.Element => {
   const location = useLocation();
   const targetUrl = location.pathname + '/create';

   const contact = useSelector(selectContact);
   const dispatch = useDispatch();
   const [advancedSearch, setAdvancedSearch] = useState(false);
   const permissions = usePermissions('Application.Create');

   useEffect((): void => {
      dispatch(
         ApplicationActions.getApplicationPendingRenewalRequest(
            contact.contactId.toString()
         )
      );
      dispatch(
         ApplicationActions.getApplicationHistoryRequest(
            contact.contactId.toString()
         )
      );
   }, [contact.contactId, dispatch]);

   return (
      <>
         {!advancedSearch ? (
            <ExpansionList className="md-cell md-cell--12">
               <ExpansionPanel
                  label={
                     <>
                        Applications{' '}
                        {permissions && (
                           <Link to={targetUrl}>
                              <Button className="plus-btn" icon>
                                 <FaIcon icon="plus" />
                                 <p>Create</p>
                              </Button>
                           </Link>
                        )}
                     </>
                  }
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ApplicationsPanel />
                  <div className="md-grid md-cell--12">
                     <div className="md-grid md-cell--6"></div>
                     <div className="md-grid md-cell--6">
                        <Button
                           flat
                           primary
                           className="advanced-filter-button"
                           onClick={(): void => setAdvancedSearch(true)}
                           onKeyUp={(keyPress): void => {
                              if (isEnterKeyPress(keyPress))
                                 setAdvancedSearch(true);
                           }}
                        >
                           Advanced Search
                        </Button>
                     </div>
                  </div>
               </ExpansionPanel>
               <ExpansionPanel
                  label="Application History"
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <HistoryPanel />
               </ExpansionPanel>
            </ExpansionList>
         ) : (
            <ApplicationsSearch setAdvancedSearch={setAdvancedSearch} />
         )}
      </>
   );
};
