import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { isValidAlphanumericText } from 'Util/Helpers/Search';
import { isValidRegistrationNumber } from 'Util/Helpers/RegistrationNumber';
import { PagedQuery, DefaultPagingModel } from 'Models/Other/PagedQuery';

interface Query extends PagedQuery {
   courseCode: string;
   courseTitle: string;
   licenceNumber: string;
   trainingProvider: string;
   status: string;
}

export type ResultSearchQuery = Readonly<Query>;

export const DefaultResultSearchQueryModel: ResultSearchQuery = {
   ...DefaultPagingModel,
   courseCode: '',
   courseTitle: '',
   licenceNumber: '',
   trainingProvider: '',
   status: '',
};

interface ResultSearchQueryValidationModel {
   courseCode: boolean;
   courseTitle: boolean;
   licenceNumber: boolean;
   trainingProvider: boolean;
   status: boolean;
}

export type ResultSearchQueryValidation = Readonly<
   ResultSearchQueryValidationModel
>;

export const DefaultComplaintSearchQueryValidation: ResultSearchQueryValidation = {
   courseCode: false,
   courseTitle: false,
   licenceNumber: false,
   trainingProvider: false,
   status: false,
};

export const validateSearch = (
   query: ResultSearchQuery
): ResultSearchQueryValidation => {
   return {
      courseCode: true,
      courseTitle:
         isNullOrWhiteSpace(query.courseTitle) ||
         isValidAlphanumericText(query.courseTitle),
      licenceNumber:
         isNullOrWhiteSpace(query.licenceNumber) ||
         isValidRegistrationNumber(query.licenceNumber),
      trainingProvider: true,
      status: true,
   };
};

export const filterToValidQuery = (
   query: ResultSearchQuery
): ResultSearchQuery => {
   return {
      ...query,
      courseCode: query.courseCode,
      courseTitle:
         isNullOrWhiteSpace(query.courseTitle) ||
         isValidAlphanumericText(query.courseTitle)
            ? query.courseTitle.trim()
            : '',
      licenceNumber:
         isNullOrWhiteSpace(query.licenceNumber) ||
         isValidRegistrationNumber(query.licenceNumber)
            ? query.licenceNumber.trim()
            : '',
      trainingProvider: isNullOrWhiteSpace(query.trainingProvider)
         ? query.trainingProvider.trim()
         : query.trainingProvider,
      status: isNullOrWhiteSpace(query.status)
         ? query.status.trim()
         : query.status,
   };
};
