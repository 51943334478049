import React, { useEffect, useState } from 'react';
import { ai } from './TelemetryService';
import { useHistory } from 'react-router';
interface TelemetryProviderProps {
   children: JSX.Element;
}
const AppInsightsInstrumentationKey =
   process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY;
const TelemetryProvider = (props: TelemetryProviderProps): JSX.Element => {
   const [initialized, setInitialized] = useState(false);
   //eslint-disable-next-line
   const [, setAppInsight]: any = useState();
   const history = useHistory();
   if (!AppInsightsInstrumentationKey) {
      //eslint-disable-next-line
      console.warn(
         // Expected error warning from DEV
         'Could not initialize Telemetry Service - instrumentation Key not found'
      );
   }
   useEffect((): void => {
      if (!initialized && AppInsightsInstrumentationKey && history) {
         const appInsight = ai.initialize(
            AppInsightsInstrumentationKey,
            history
         );
         setInitialized(true);
         setAppInsight(appInsight);
      }
   }, [initialized, history]);

   return <>{props.children}</>;
};
export default TelemetryProvider;
