import React, { useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import UserCard from 'Components/Common/UserCard/UserCard';
import SupervisorHistory from './SupervisorHistory';
import { useDispatch, useSelector } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import { SupervisionActions } from 'State/Redux/SupervisionRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import TabsContainer, { Tab, Tabs } from 'react-md/lib/Tabs';
import SuperviseeHistory from './SuperviseeHistory';
import { LicenceActions } from 'State/Redux/LicenceRedux';

const SupervisionTab = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const dispatch = useDispatch();

   useEffect((): void => {
      if (!contact.registrationNumber) return;
      dispatch(
         SupervisionActions.getSupervisorHistoryRequest(contact.contactId)
      );
      dispatch(
         SupervisionActions.getSuperviseeHistoryRequest(contact.contactId)
      );
      dispatch(
         LicenceActions.getLicenceHistoryByIdRequest(
            contact.registrationNumber.toString()
         )
      );
   }, [contact.registrationNumber, contact.contactId, dispatch]);

   return (
      <ExpansionList className="md-cell md-cell--12 contact-supervision">
         <ExpansionPanel
            label="Supervision"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <UserCard />
            </div>
         </ExpansionPanel>

         <ExpansionPanel
            label="Supervision History"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <TabsContainer panelClassName="md-grid" colored>
               <Tabs tabId="simple-tab">
                  <Tab label="Supervisors">
                     <SupervisorHistory />
                  </Tab>
                  <Tab label="Supervisees">
                     <SuperviseeHistory />
                  </Tab>
               </Tabs>
            </TabsContainer>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default SupervisionTab;
