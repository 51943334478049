import {
   isValidSearchName,
   isValidSearchLicenceNumber,
} from 'Util/Helpers/ContactSearch';
import {
   isNullOrWhiteSpace,
   isValidDate,
   isValidEndDateAfterStartDate,
} from 'Util/Helpers/Validation';
import { PagedQuery, DefaultPagingModel } from 'Models/Other/PagedQuery';

interface Query extends PagedQuery {
   registrantName: string;
   startDate: string;
   endDate: string;
   licenceNumber: string;
   applicationType: string;
   applicationStatus: string;
}

export type ApplicationSearchQuery = Readonly<Query>;

interface QueryValidation {
   registrantName: boolean;
   startDate: boolean;
   endDate: boolean;
   licenceNumber: boolean;
   applicationType: boolean;
   applicationStatus: boolean;
}

export type ApplicationSearchQueryValidation = Readonly<QueryValidation>;

export const DefaultSearchModel: ApplicationSearchQuery = {
   ...DefaultPagingModel,
   registrantName: '',
   startDate: '',
   endDate: '',
   licenceNumber: '',
   applicationType: '',
   applicationStatus: '',
};

export const DefaultValidationModel: ApplicationSearchQueryValidation = {
   registrantName: false,
   startDate: false,
   endDate: false,
   licenceNumber: false,
   applicationType: false,
   applicationStatus: false,
};

export const validateSearchQuery = (
   query: ApplicationSearchQuery
): ApplicationSearchQueryValidation => {
   return {
      registrantName:
         isNullOrWhiteSpace(query.registrantName) ||
         isValidSearchName(query.registrantName),
      startDate: isValidDate(query.startDate),
      endDate:
         isValidDate(query.endDate) &&
         isValidEndDateAfterStartDate(query.startDate, query.endDate),
      licenceNumber:
         isNullOrWhiteSpace(query.licenceNumber) ||
         isValidSearchLicenceNumber(query.licenceNumber),
      applicationType: true,
      applicationStatus: true,
   };
};

export const filterToValidQuery = (
   query: ApplicationSearchQuery
): ApplicationSearchQuery => {
   return {
      ...query,
      registrantName:
         isNullOrWhiteSpace(query.registrantName) ||
         isValidSearchName(query.registrantName)
            ? query.registrantName
            : '',
      startDate: query.startDate,
      endDate: query.endDate,
      licenceNumber:
         isNullOrWhiteSpace(query.licenceNumber) ||
         isValidSearchLicenceNumber(query.licenceNumber)
            ? query.licenceNumber
            : '',
      applicationType: !isNullOrWhiteSpace(query.applicationType)
         ? query.applicationType
         : '',
      applicationStatus: !isNullOrWhiteSpace(query.applicationStatus)
         ? query.applicationStatus
         : '',
   };
};

export const isValidSearchQuery = (query: ApplicationSearchQuery): boolean => {
   return (
      !isNullOrWhiteSpace(query.registrantName) ||
      !isNullOrWhiteSpace(query.startDate) ||
      !isNullOrWhiteSpace(query.endDate) ||
      !isNullOrWhiteSpace(query.licenceNumber) ||
      !isNullOrWhiteSpace(query.applicationType) ||
      !isNullOrWhiteSpace(query.applicationStatus)
   );
};
