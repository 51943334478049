import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import ContactReadonly from 'Models/Contact/Data/Contact';
import { withAccessToken, createApi } from '.';
import ChangeSupervisorDtoReadonly from 'Models/Supervision/Dto/ChangeSupervisionDto';
import ChangeSupervisionEndDateDtoReadOnly from 'Models/Supervision/Dto/ChangeSupervisionEndDateDto';
import { Supervision } from 'Models/Supervision/Data/Supervision';
import { Supervisee } from 'Models/Supervision/Data/Supervisee';

export interface SupervisionServiceType {
   getSupervisorHistory(
      id: number
   ): Promise<ApiResponse<readonly Supervision[]>>;
   getSuperviseeHistory(
      id: number
   ): Promise<ApiResponse<readonly Supervisee[]>>;
   searchSupervisor(
      registrationNumber: string
   ): Promise<ApiResponse<ContactReadonly>>;
   changeSupervisor(
      changeSupervisorDto: ChangeSupervisorDtoReadonly
   ): Promise<ApiResponse<ContactReadonly>>;
   changeSupervisionEndDate(
      changeEndDateDto: ChangeSupervisionEndDateDtoReadOnly
   ): Promise<ApiResponse<ContactReadonly>>;
   acceptSupervision(supervisionId: number): Promise<ApiResponse<Supervisee>>;
   declineSupervision(supervisionId: number): Promise<ApiResponse<Supervisee>>;
}

const create = (baseURL: string | undefined): SupervisionServiceType => {
   const api = createApi({ baseURL });

   const getSupervisorHistory = (
      id: number
   ): Promise<ApiResponse<readonly Supervision[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Supervision/SupervisorHistory/${id}`);
   };

   const getSuperviseeHistory = (
      id: number
   ): Promise<ApiResponse<readonly Supervisee[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Supervision/SuperviseeHistory/${id}`);
   };

   const searchSupervisor = (
      registrationNumber: string
   ): Promise<ApiResponse<ContactReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/Registration/${registrationNumber}`);
   };

   const changeSupervisor = (
      changeSupervisorDto: ChangeSupervisorDtoReadonly
   ): Promise<ApiResponse<ContactReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Supervision/ChangeSupervisor', changeSupervisorDto);
   };

   const changeSupervisionEndDate = (
      changeEndDateDto: ChangeSupervisionEndDateDtoReadOnly
   ): Promise<ApiResponse<ContactReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(
         '/Supervision/ChangeSupervisionEndDate',
         changeEndDateDto
      );
   };

   const acceptSupervision = (
      supervisionId: number
   ): Promise<ApiResponse<Supervisee>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Supervision/${supervisionId}/AcceptSupervision`);
   };

   const declineSupervision = (
      supervisionId: number
   ): Promise<ApiResponse<Supervisee>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Supervision/${supervisionId}/DeclineSupervision`);
   };

   return {
      getSupervisorHistory,
      getSuperviseeHistory,
      searchSupervisor,
      changeSupervisor,
      changeSupervisionEndDate,
      acceptSupervision,
      declineSupervision,
   };
};

export default {
   create,
};
