import { getCurrentDateWithoutTime } from 'Util/Helpers/Date';
import Moment from 'moment-timezone';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

interface BoardMeetingDate {
   boardMeetingDateId: number;
   boardMeetingDate: string;
   boardMeetingStatus: string;
   description: string;
}

type BoardMeetingDateReadonly = Readonly<BoardMeetingDate>;

export default BoardMeetingDateReadonly;

export const DESCRIPTION_MAX_LENGTH = 50;

export const DefaultBoardMeetingDateModel: BoardMeetingDate = {
   boardMeetingDateId: 0,
   boardMeetingDate: getCurrentDateWithoutTime(),
   boardMeetingStatus: 'Active',
   description: 'Board Meeting',
};

interface ValidationModel {
   boardMeetingDate: boolean;
   boardMeetingStatus: boolean;
   description: boolean;
}

export type BoardMeetingDateValidation = Readonly<ValidationModel>;

export const InitialValidationModel: BoardMeetingDateValidation = {
   description: true,
   boardMeetingDate: true,
   boardMeetingStatus: true,
};

export const validateBoardMeetingDate = (
   boardMeetingDate: BoardMeetingDate
): BoardMeetingDateValidation => {
   return {
      boardMeetingDate: Moment(
         boardMeetingDate.boardMeetingDate || ''
      ).isValid(),
      boardMeetingStatus: !isNullOrWhiteSpace(
         boardMeetingDate.boardMeetingStatus
      ),
      description:
         !isNullOrWhiteSpace(boardMeetingDate.description) &&
         boardMeetingDate.description.length <= DESCRIPTION_MAX_LENGTH,
   };
};
