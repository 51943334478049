import React, { useState, useCallback } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectIsSuccess,
   selectIsLoading,
   selectIsError,
   selectErrorMessage,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   selectFeeMaintenance,
   FeeMaintenanceActions,
   FeeMaintenanceTypes,
} from 'State/Redux/FeeMaintenanceRedux';
import { DefaultFeeModel, validateFee } from 'Models/FeeMaintenance/Dto/Fee';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import DateInput from 'Components/Common/DateInput/DateInput';
import SelectField from 'react-md/lib/SelectFields';
import { useApplicationTypeList, useFeeTypeList } from 'Util/Helpers/Metadata';

const AddFeeMaintenance = (): JSX.Element => {
   const systemParameter = useSelector(selectFeeMaintenance);
   const isLoading = useSelector(
      selectIsLoading([FeeMaintenanceTypes.ADD_FEE_MAINTENANCE_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([FeeMaintenanceTypes.ADD_FEE_MAINTENANCE_REQUEST])
   );
   const isError = useSelector(
      selectIsError([FeeMaintenanceTypes.ADD_FEE_MAINTENANCE_REQUEST])
   );
   const getErrorMessage = useSelector(
      selectErrorMessage(FeeMaintenanceTypes.ADD_FEE_MAINTENANCE_REQUEST)
   );

   const dispatch = useDispatch();

   const [systemParameterModel, setSystemParameterModel] = useState(
      DefaultFeeModel
   );

   const [addClicked, setAddClicked] = useState(false);

   const onAdd = useCallback((): void => {
      dispatch(
         FeeMaintenanceActions.addFeeMaintenanceRequest({
            ...systemParameterModel,
         })
      );
      setAddClicked(true);
   }, [dispatch, systemParameterModel]);

   if (addClicked && isSuccess && systemParameter !== null) {
      toast.success(`New fee created!`);
      setAddClicked(false);
      setSystemParameterModel(DefaultFeeModel);
   }

   if (addClicked && isError && systemParameter === null) {
      toast.error(getErrorMessage);
   }
   const isValidForm = Object.values(validateFee(systemParameterModel)).every(
      (v): boolean => !!v
   );
   const validation = validateFee(systemParameterModel);

   const applicationTypes = useApplicationTypeList('Select Application Type');
   const feeTypes = useFeeTypeList('Select Fee Type');

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <SelectField
                     floating
                     id="application"
                     placeholder="Select Application Type"
                     className="md-cell md-cell--12"
                     position={SelectField.Positions.BELOW}
                     value={systemParameterModel.applicationTypeCode}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           applicationTypeCode: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     errorText="Select Application"
                     menuItems={applicationTypes}
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <SelectField
                     floating
                     id="fee-type"
                     placeholder="Select Fee Type"
                     className="md-cell md-cell--12"
                     position={SelectField.Positions.BELOW}
                     value={systemParameterModel.feeTypeCode}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           feeTypeCode: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     errorText="Select Fee Type"
                     menuItems={feeTypes}
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     type="number"
                     step={0.1}
                     id="amount"
                     label="Amount or %"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Amount"
                     value={systemParameterModel.feeAmount}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           feeAmount: Number(val.toString()),
                        };
                        setSystemParameterModel(newModel);
                     }}
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <DateInput
                     id="start-date"
                     label="Start Date"
                     className="md-cell md-cell--12"
                     errorText="Please select a valid start date"
                     value={systemParameterModel.startDate}
                     error={!validation.startDate}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           startDate: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     strict
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <DateInput
                     id="end-date"
                     label="End Date"
                     className="md-cell md-cell--12"
                     errorText="Please select a valid end date"
                     value={systemParameterModel.endDate}
                     error={!validation.endDate}
                     onChange={(val): void => {
                        const newModel = {
                           ...systemParameterModel,
                           endDate: val.toString(),
                        };
                        setSystemParameterModel(newModel);
                     }}
                     strict
                  />
               </div>

               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
                     disabled={!isValidForm}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        onAdd();
                     }}
                     onClick={(): void => {
                        onAdd();
                     }}
                  >
                     Add
                  </LoadingButton>
               </div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddFeeMaintenance;
