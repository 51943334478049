import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

export interface RegistrationType {
   registrationTypeCode: string;
   description: string;
   cblcredits: string;
   disciplineCode: string;
}

type RegistrationTypeReadonly = Readonly<RegistrationType>;

export default RegistrationTypeReadonly;

export const DefaultRegistrationTypeModel: RegistrationType = {
   registrationTypeCode: '',
   description: '',
   cblcredits: '',
   disciplineCode: '',
};

interface ValidationModel {
   registrationTypeCode: boolean;
   description: boolean;
   disciplineCode: boolean;
}

export type RegistrationTypeValidation = Readonly<ValidationModel>;

export const validateRegistrationType = (
   registrationType: RegistrationType
): RegistrationTypeValidation => {
   return {
      registrationTypeCode:
         !isNullOrWhiteSpace(registrationType.registrationTypeCode) &&
         registrationType.registrationTypeCode.length <= 4,
      description: !isNullOrWhiteSpace(registrationType.description),
      disciplineCode: !isNullOrWhiteSpace(registrationType.disciplineCode),
   };
};
