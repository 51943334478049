import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import BoardMeetingDateReadonly from 'Models/BoardMeetingDate/BoardMeetingDate';
import { BoardMeetingDateSearchQuery } from 'Models/BoardMeetingDate/BoardMeetingDateSearchQuery';
import produce from 'immer';

/* ------------- Interfaces for ReduxSauce ------------- */
interface BoardMeetingDateState {
   boardMeetingDate: BoardMeetingDateReadonly | null;
   boardMeetingDates: BoardMeetingDateReadonly[];
}

export type BoardMeetingDateStateReadonly = Readonly<BoardMeetingDateState>;

interface TypeNames {
   UPDATE_BOARD_MEETING_DATE_REQUEST: string;
   UPDATE_BOARD_MEETING_DATE_SUCCESS: string;
   UPDATE_BOARD_MEETING_DATE_FAILURE: string;

   SEARCH_BOARD_MEETING_DATE_REQUEST: string;
   SEARCH_BOARD_MEETING_DATE_SUCCESS: string;
   SEARCH_BOARD_MEETING_DATE_FAILURE: string;

   GET_BOARD_MEETING_DATES_REQUEST: string;
   GET_BOARD_MEETING_DATES_SUCCESS: string;
   GET_BOARD_MEETING_DATES_FAILURE: string;

   ADD_BOARD_MEETING_DATE_REQUEST: string;
   ADD_BOARD_MEETING_DATE_SUCCESS: string;
   ADD_BOARD_MEETING_DATE_FAILURE: string;
}

type BoardMeetingDateTypeNames = Readonly<TypeNames>;

export interface BoardMeetingDateCreators {
   updateBoardMeetingDateRequest: (
      boardMeetingDate: BoardMeetingDateReadonly
   ) => AnyAction;
   updateBoardMeetingDateSuccess: (data: BoardMeetingDateReadonly) => AnyAction;
   updateBoardMeetingDateFailure: (error: ApiError) => AnyAction;

   searchBoardMeetingDateRequest: (
      query: BoardMeetingDateSearchQuery
   ) => AnyAction;
   searchBoardMeetingDateSuccess: (
      data: readonly BoardMeetingDateReadonly[]
   ) => AnyAction;
   searchBoardMeetingDateFailure: (error: ApiError) => AnyAction;

   getBoardMeetingDatesRequest: () => AnyAction;
   getBoardMeetingDatesSuccess: (
      data: readonly BoardMeetingDateReadonly[]
   ) => AnyAction;
   getBoardMeetingDatesFailure: (error: ApiError) => AnyAction;

   addBoardMeetingDateRequest: (
      boardMeetingDate: BoardMeetingDateReadonly
   ) => AnyAction;
   addBoardMeetingDateSuccess: (data: BoardMeetingDateReadonly) => AnyAction;
   addBoardMeetingDateFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: BoardMeetingDateStateReadonly = {
   boardMeetingDate: null,
   boardMeetingDates: [],
};

/* ------------- Reducers ------------- */
const updateBoardMeetingDateSuccess = (
   state: BoardMeetingDateStateReadonly,
   action: AnyAction
): BoardMeetingDateStateReadonly => {
   return produce(state, (draftState): void => {
      const updatedMeetingDate = action.data;
      // find search result to update
      if (draftState.boardMeetingDates) {
         const meetingDateFound = draftState.boardMeetingDates.find(
            boadMeetingDate => {
               return (
                  boadMeetingDate.boardMeetingDateId ===
                  updatedMeetingDate.boardMeetingDateId
               );
            }
         );
         if (meetingDateFound) {
            const index = draftState.boardMeetingDates.indexOf(
               meetingDateFound
            );
            draftState.boardMeetingDates[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addBoardMeetingDateSuccess = (
   state: BoardMeetingDateStateReadonly,
   action: AnyAction
): BoardMeetingDateStateReadonly => {
   return { ...state, boardMeetingDate: action.data };
};

const searchBoardMeetingDateSuccess = (
   state: BoardMeetingDateStateReadonly,
   action: AnyAction
): BoardMeetingDateStateReadonly => {
   return { ...state, boardMeetingDates: action.data || [] };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   BoardMeetingDateTypeNames,
   BoardMeetingDateCreators
>('boardMeetingDate', {
   updateBoardMeetingDateRequest: ['boardMeetingDate'],
   updateBoardMeetingDateSuccess: ['data'],
   updateBoardMeetingDateFailure: ['error'],

   searchBoardMeetingDateRequest: ['query'],
   searchBoardMeetingDateSuccess: ['data'],
   searchBoardMeetingDateFailure: ['error'],

   getBoardMeetingDatesRequest: [],
   getBoardMeetingDatesSuccess: ['data'],
   getBoardMeetingDatesFailure: ['error'],

   addBoardMeetingDateRequest: ['boardMeetingDate'],
   addBoardMeetingDateSuccess: ['data'],
   addBoardMeetingDateFailure: ['error'],
});

export const BoardMeetingDateTypes = Types;
export const BoardMeetingDateActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_BOARD_MEETING_DATE_SUCCESS]: updateBoardMeetingDateSuccess,
   [Types.SEARCH_BOARD_MEETING_DATE_SUCCESS]: searchBoardMeetingDateSuccess,
   [Types.GET_BOARD_MEETING_DATES_SUCCESS]: searchBoardMeetingDateSuccess,
   [Types.ADD_BOARD_MEETING_DATE_SUCCESS]: addBoardMeetingDateSuccess,
});

/* ------------- Selectors ------------- */

export const selectBoardMeetingDate = (
   state: StateReadonly
): BoardMeetingDateReadonly | null => state.boardMeetingDate.boardMeetingDate;

export const selectBoardMeetingDates = (
   state: StateReadonly
): readonly BoardMeetingDateReadonly[] =>
   state.boardMeetingDate.boardMeetingDates;
