import CourseDto from './CourseDto';
import { getCurrentDate } from 'Util/Helpers/Date';
import ContactLimitedDtoReadonly from 'Models/Contact/Dto/ContactLimitedDto';

interface ResultDto {
   resultId?: number;
   contactId?: number;
   courseId?: number;
   resultDate: string;
   passFailCode: string;
   resultStatusCode: string;
   resultStatusDescription: string;
   mark: number | null;
   examinationId: number | null;
   licenceYear: number | null;
   licenceYearDescription: string | null;
   createdBy: string;
   createdOn: string | null;
   updatedBy: string;
   updatedOn: string | null;
   creditTransferResultId: number | null;

   course: CourseDto | null;
   contact: ContactLimitedDtoReadonly | null;
}

type ResultDtoReadonly = Readonly<ResultDto>;

export default ResultDtoReadonly;

export const DefaultResultDtoModel: ResultDto = {
   resultId: 0,
   contactId: 0,
   courseId: 0,
   resultDate: getCurrentDate(),
   passFailCode: '',
   resultStatusCode: '',
   resultStatusDescription: '',
   mark: null,
   examinationId: null,
   licenceYear: null,
   licenceYearDescription: null,
   createdBy: '',
   createdOn: null,
   updatedBy: '',
   updatedOn: null,
   creditTransferResultId: null,

   course: null,
   contact: null,
};
