import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import { Complaint } from 'Models/Complaint/Complaint';
import Moment from 'moment-timezone';
import {
   selectContact,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import Loading from 'Components/Common/Loading';
import {
   getDisplayRegistrationNumber,
   getDisplayContactName,
} from 'Util/Helpers/Contact';
import { DISPLAY_DATE_FORMAT } from 'Util/Constants/Common';
import { ComplaintStatus } from 'Models/Metadata/ComplaintStatus';

const formatDate = (date: string | null): string => {
   return date && Moment(date).isValid()
      ? Moment(date).format(DISPLAY_DATE_FORMAT)
      : '';
};

interface ViewPanelProps {
   complaint: Complaint;
}

const ViewPanel = ({ complaint }: Readonly<ViewPanelProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.GET_CONTACT_BY_ID_REQUEST])
   );
   const isError = useSelector(
      selectIsError([ContactTypes.GET_CONTACT_BY_ID_REQUEST])
   );
   const dispatch = useDispatch();

   const complaintContactId = (complaint && complaint.contactId) || 0;
   const stateContactId = (contact && contact.contactId) || 0;
   const requiresLoading = complaintContactId !== stateContactId;

   const loadContact = (): void => {
      if (requiresLoading && complaint.contactId)
         dispatch(
            ContactActions.getContactByIdRequest(complaint.contactId.toString())
         );
   };
   useEffect(loadContact, [requiresLoading, complaint, dispatch]);

   const complaintStatuses = useSelector(
      (state: StateReadonly): ComplaintStatus[] =>
         state.metadata.complaintStatuses
   );

   const complaintStatus = complaintStatuses.find(
      (cs): boolean =>
         (complaint && cs.complaintStatusCode === complaint.statusCode) || false
   );
   const complaintStatusDescription = !complaintStatus
      ? ''
      : complaintStatus.description;

   const registrationNumber = getDisplayRegistrationNumber(contact);
   const contactName = getDisplayContactName(contact);

   const startDate = formatDate(complaint.startDate);
   const endDate = formatDate(complaint.endDate);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <div className="dm-section-header">Disciplinary Matter #</div>
            <div className="dm-section-value">{complaint.complaintId}</div>
         </div>
         <div className="md-cell md-cell--6">
            <div className="dm-section-header">Disciplinary Matter Status</div>
            <div className="dm-section-value">{complaintStatusDescription}</div>
         </div>

         <Loading
            isError={isError}
            isLoading={isLoading}
            tryReload={loadContact}
         >
            <>
               <div className="md-cell md-cell--6">
                  <div className="dm-section-header">Licence Number</div>
                  <div className="dm-section-value">{registrationNumber}</div>
               </div>
               <div className="md-cell md-cell--6">
                  <div className="dm-section-header">Respondent Name</div>
                  <div className="dm-section-value">{contactName}</div>
               </div>
            </>
         </Loading>

         <div className="md-cell md-cell--6">
            <div className="dm-section-header">Start Date</div>
            <div className="dm-section-value">{startDate}</div>
         </div>
         <div className="md-cell md-cell--6">
            <div className="dm-section-header">End Date</div>
            <div className="dm-section-value">{endDate}</div>
         </div>

         <div className="md-cell md-cell--12">
            <div className="dm-section-header">Description</div>
            <div className="dm-section-value">{complaint.description}</div>
         </div>
      </div>
   );
};

export default ViewPanel;
