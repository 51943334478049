import { put, call } from 'redux-saga/effects';
import { ExaminationActions } from '../Redux/ExaminationRedux';
import { ExaminationServiceType } from '../Services/ExaminationService';
import { ApiResponse } from 'apisauce';
import ExaminationDto from 'Models/Examination/Dto/ExaminationLimitedDto';
import ContactExamEligibilityDto from 'Models/Examination/Dto/ContactExamEligibilityDto';
import ContactExamEligibilityOtherNotesDtoReadonly from 'Models/Examination/Data/ContactExamEligibilityOtherNotesDto';

interface ExaminationParams {
   type: string;
   token: string;
   contactId: string;
   examination: ExaminationDto;
   examinationEligibility: ContactExamEligibilityDto[];
   examinationEligibilityReport: Blob;
   examinationEligibilityReportSent: boolean;
   contactExaminationEligibilityOtherNotes: ContactExamEligibilityOtherNotesDtoReadonly[];
}

export function* getExaminationEligibilityByContactId(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const { contactId } = action;
   const examinationResponse = yield call(
      examinationService.getExaminationEligibilityByContactId,
      contactId
   );
   const response = examinationResponse as ApiResponse<
      ContactExamEligibilityDto[]
   >;

   if (response.status && response.ok) {
      yield put(
         ExaminationActions.getExaminationEligibilityByContactIdSuccess(
            response.data
         )
      );
   } else {
      yield put(
         ExaminationActions.getExaminationEligibilityByContactIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateContactExaminationEligibility(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const resultResponse = yield call(
      examinationService.updateContactExaminationEligibility,
      action.contactId,
      action.examinationEligibility
   );
   const response = resultResponse as ApiResponse<ContactExamEligibilityDto[]>;

   if (response.status && response.ok && response.data) {
      yield put(
         ExaminationActions.updateContactExaminationEligibilitySuccess(
            response.data
         )
      );
   } else {
      yield put(
         ExaminationActions.updateContactExaminationEligibilityFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getExaminationEligibilityOtherNotesByContactId(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const { contactId } = action;
   const examinationResponse = yield call(
      examinationService.getExaminationEligibilityOtherNotesByContactId,
      contactId
   );
   const response = examinationResponse as ApiResponse<
      ContactExamEligibilityOtherNotesDtoReadonly[]
   >;

   if (response.status && response.ok) {
      yield put(
         ExaminationActions.getExaminationEligibilityOtherNotesByContactIdSuccess(
            response.data
         )
      );
   } else {
      yield put(
         ExaminationActions.getExaminationEligibilityOtherNotesByContactIdFailure(
            {
               code: 'Error',
               status: response.status,
            }
         )
      );
   }
}

export function* updateContactExaminationEligibilityOtherNotes(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const resultResponse = yield call(
      examinationService.updateContactExaminationEligibilityOtherNotes,
      action.contactExaminationEligibilityOtherNotes
   );
   const response = resultResponse as ApiResponse<
      ContactExamEligibilityOtherNotesDtoReadonly[]
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         ExaminationActions.updateContactExaminationEligibilityOtherNotesSuccess(
            response.data
         )
      );
   } else {
      yield put(
         ExaminationActions.updateContactExaminationEligibilityOtherNotesFailure(
            {
               code: 'Error',
               status: response.status,
            }
         )
      );
   }
}

export function* getUserExaminationEligibilityReportByContactId(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const { contactId } = action;
   const examinationResponse = yield call(
      examinationService.getUserExaminationEligibilityReportByContactId,
      contactId
   );
   const response = examinationResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(
         ExaminationActions.getUserExaminationEligibilityReportByContactIdSuccess(
            response.data
         )
      );
   } else {
      yield put(
         ExaminationActions.getUserExaminationEligibilityReportByContactIdFailure(
            {
               code: 'Error',
               status: response.status,
            }
         )
      );
   }
}

export function* sendExaminationEligibilityReportAspeq(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const { contactId } = action;
   const examinationResponse = yield call(
      examinationService.sendExaminationEligibilityReportAspeq,
      contactId
   );
   const response = examinationResponse as ApiResponse<boolean>;

   if (response.status && response.ok) {
      yield put(
         ExaminationActions.sendExaminationEligibilityReportAspeqSuccess(true)
      );
   } else {
      yield put(
         ExaminationActions.sendExaminationEligibilityReportAspeqFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* sendExaminationEligibilityReportUser(
   examinationService: Readonly<ExaminationServiceType>,
   action: Readonly<ExaminationParams>
): Generator {
   const { contactId } = action;
   const examinationResponse = yield call(
      examinationService.sendExaminationEligibilityReportUser,
      contactId
   );
   const response = examinationResponse as ApiResponse<boolean>;

   if (response.status && response.ok) {
      yield put(
         ExaminationActions.sendExaminationEligibilityReportUserSuccess(true)
      );
   } else {
      yield put(
         ExaminationActions.sendExaminationEligibilityReportUserFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
