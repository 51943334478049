import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Cell } from 'react-md/lib/Grids';
import { ExpansionList, ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import { TextField } from 'react-md/lib/TextFields';
import { Button } from 'react-md/lib/Buttons';
import {
   IdentityActions,
   selectPasswordResetResponse,
} from 'State/Redux/IdentityRedux';
import { UserReadonly, DefaultUser } from 'Models/Identity/User';
import { isEnterKeyPress } from '../../../../Util/Helpers/Input';
import { selectContact } from 'State/Redux/ContactRedux';
import { getDisplayRegistrationNumber } from 'Util/Helpers/Contact';
import { NOT_APPLICABLE } from 'Util/Constants/Common';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const ChangePasswordTab = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const passwordResetResponse = useSelector(selectPasswordResetResponse);
   const dispatch = useDispatch();

   const [user, setUser] = useState(DefaultUser);
   const [passwordHeader, setPasswordHeader] = useState('');
   const [passwordMessage, setPasswordMessage] = useState('');

   useEffect((): void => {
      if (contact.registrationNumber) {
         if (user.licenceNumber !== getDisplayRegistrationNumber(contact))
            setUser({
               ...user,
               licenceNumber: getDisplayRegistrationNumber(contact),
               email: contact.emailAddress,
            });
      } else if (user.licenceNumber !== NOT_APPLICABLE) {
         setUser({
            ...user,
            licenceNumber: NOT_APPLICABLE,
         });
      }
   }, [contact, user]);

   useEffect((): void => {
      if (passwordResetResponse.success) {
         setPasswordHeader('THANK YOU!');
         setPasswordMessage(
            'A password reset email has been sent out. Please remember to ask them ' +
               'to check their Junk email.'
         );
      } else if (passwordResetResponse.message) {
         setPasswordHeader('An error occurred');
         setPasswordMessage(passwordResetResponse.message);
      }

      dispatch(IdentityActions.passwordResetResponseReset());
   }, [passwordResetResponse, dispatch]);

   const handlePasswordResetSubmission = (
      event: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (!isEnterKeyPress(event)) return;
      dispatch(IdentityActions.postPasswordResetRequest(normalizedUser(user)));
   };

   const normalizedUser = (user: UserReadonly): UserReadonly => {
      return {
         ...user,
         licenceNumber: user.licenceNumber.replace(/^(0+)/g, ''),
      };
   };

   return (
      <ExpansionList className="change-password-tab md-cell md-cell--6 center">
         <ExpansionPanel
            label="Change Password"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <Grid className="grid-example center">
               <Cell size={12}>
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Licence Number"
                     lineDirection="center"
                     placeholder="Licence Number"
                     className="md-cell md-cell--bottom md-cell--12"
                     value={user.licenceNumber}
                     onKeyDown={(
                        event: React.KeyboardEvent<HTMLElement>
                     ): void => handlePasswordResetSubmission(event)}
                     disabled
                  />
               </Cell>
            </Grid>
            <Grid className="grid-example center">
               <Cell size={12}>
                  <TextField
                     onChange={(val): void => {
                        setUser({ ...user, email: val.toString() });
                     }}
                     floating
                     id="floating-center-title"
                     label="Email Address"
                     lineDirection="center"
                     placeholder="Email Address"
                     className="md-cell md-cell--bottom md-cell--12"
                     defaultValue={user.email}
                     onKeyUp={(keyPress): void => {
                        handlePasswordResetSubmission(keyPress);
                     }}
                  />
               </Cell>
            </Grid>
            <h3>{passwordHeader}</h3>
            <h5>{passwordMessage}</h5>
            <Grid className="grid-example center">
               <Cell size={12}>
                  {contact.registrationNumber ? (
                     <Button
                        flat
                        primary
                        swapTheming
                        onClick={(): void => {
                           dispatch(
                              IdentityActions.postPasswordResetRequest(
                                 normalizedUser(user)
                              )
                           );
                        }}
                        onKeyUp={(keyPress): void => {
                           if (!isEnterKeyPress(keyPress)) return;
                           dispatch(
                              IdentityActions.postPasswordResetRequest(
                                 normalizedUser(user)
                              )
                           );
                        }}
                     >
                        Send Password Reset
                     </Button>
                  ) : (
                     <h5>Invalid Licence Number</h5>
                  )}
               </Cell>
            </Grid>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default ChangePasswordTab;
