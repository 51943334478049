import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import FeeTypeReadonly from 'Models/FeeMaintenance/Dto/FeeType';
import FeeType from 'Models/FeeMaintenance/Dto/FeeType';

export interface FeeTypeServiceType {
   updateFeeType(
      boardMeetingDate: FeeTypeReadonly
   ): Promise<ApiResponse<readonly FeeTypeReadonly[]>>;
   getFeeTypes(): Promise<ApiResponse<readonly FeeType[]>>;
   addFeeType(feeType: FeeTypeReadonly): Promise<ApiResponse<FeeTypeReadonly>>;
}

const create = (baseURL: string | undefined): FeeTypeServiceType => {
   const api = createApi({ baseURL });

   const updateFeeType = (
      feeType: FeeTypeReadonly
   ): Promise<ApiResponse<readonly FeeTypeReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/FeeType`, feeType);
   };

   const getFeeTypes = (): Promise<ApiResponse<readonly FeeType[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/FeeType`);
   };

   const addFeeType = (
      feeType: FeeTypeReadonly
   ): Promise<ApiResponse<FeeTypeReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/FeeType`, feeType);
   };

   return {
      updateFeeType,
      getFeeTypes,
      addFeeType,
   };
};

export default {
   create,
};
