import { SelectItem, createSelectList } from 'Util/Helpers/Input';

export type GasExemptionClass = '80,0' | '90,0';
export type GasExemptionTypeCode = 'UNAU' | 'AUTH'; // 80,0 => UNAU, 90,0 => AUTH

export const useGasExemptionType = (defaultValue?: string): SelectItem[] => {
   return createSelectList(
      [
         { key: '80,0', value: '80,0' },
         { key: '90,0', value: '90,0' },
      ],
      (ge): string => ge.key,
      (ge): string => ge.value,
      defaultValue
   );
};
