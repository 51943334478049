import { put, call } from 'redux-saga/effects';
import { IdentityActions } from '../Redux/IdentityRedux';
import { IdentityServiceType } from '../Services/IdentityService';
import { ApiResponse } from 'apisauce';
import { UserReadonly } from 'Models/Identity/User';
import PasswordReset from 'Models/Identity/PasswordReset';

interface IdentityParams {
   type: string;
   token: string;
   user: UserReadonly;
   data: PasswordReset;
}

export function* postPasswordReset(
   identityService: Readonly<IdentityServiceType>,
   action: Readonly<IdentityParams>
): Generator {
   const { user } = action;
   const identityResponse = yield call(identityService.postPasswordReset, user);
   const response = identityResponse as ApiResponse<string>;

   let passwordReset: PasswordReset = { message: '', success: false };
   if (response.data) {
      passwordReset = {
         ...passwordReset,
         message: response.data.toString(),
      };
   }

   if (response.status && response.ok && response.data) {
      passwordReset = { ...passwordReset, success: true };
      yield put(IdentityActions.postPasswordResetSuccess(passwordReset));
   } else {
      yield put(
         IdentityActions.postPasswordResetFailure(
            {
               code: 'Error',
               status: response.status,
            },
            passwordReset
         )
      );
   }
}
