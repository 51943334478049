interface RegistrationInputValidation {
   registrationTypeCode: boolean;
   registrationStatusCode: boolean;
   registeredDate: boolean;
}

export type RegistrationInputValidationReadonly = Readonly<
   RegistrationInputValidation
>;

export const DefaultRegistrationInputValidation: RegistrationInputValidationReadonly = {
   registrationTypeCode: true,
   registrationStatusCode: true,
   registeredDate: true,
};
