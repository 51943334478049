import { useEffect } from 'react';

const DEFAULT_PAGE_TITLE = 'PGDB Administrator Portal';
const TITLE_SEPARATOR = ' - ';

const getDocumentTitlePieces = (
   pageTitle: string | readonly string[] | undefined
): readonly string[] => {
   if (!pageTitle) return [];

   if (Array.isArray(pageTitle)) {
      return pageTitle;
   } else {
      return [pageTitle as string];
   }
};

export const combinePageTitles = (
   firstPageTitle: string | readonly string[] | undefined,
   secondPageTitle: string | readonly string[] | undefined
): readonly string[] => {
   const firstPieces = getDocumentTitlePieces(firstPageTitle);
   const secondPieces = getDocumentTitlePieces(secondPageTitle);
   return firstPieces.concat(secondPieces);
};

export const buildDocumentTitle = (
   pageTitle: string | readonly string[] | undefined
): string => {
   const docTitlePieces = [...getDocumentTitlePieces(pageTitle)];
   docTitlePieces.push(DEFAULT_PAGE_TITLE);
   return docTitlePieces.join(TITLE_SEPARATOR);
};

export const useDocumentTitle = (title: string): void => {
   useEffect((): void => {
      document.title = title;
   }, [title]);
};
