import DropdownDto from 'Models/Enum/Dto/DropdownDto';
import { ApiResponse } from 'apisauce';
import { withAccessToken, createApi } from '.';
import PostCodeCityDto from 'Models/Enum/Dto/PostCodeCityDto';
import store from 'State/Redux';

export interface EnumServiceType {
   getCities(): Promise<ApiResponse<DropdownDto[]>>;
   getPostCodeCities(): Promise<ApiResponse<PostCodeCityDto[]>>;
}

const create = (baseURL: string | undefined): EnumServiceType => {
   const api = createApi({ baseURL });

   const getCities = (): Promise<ApiResponse<DropdownDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Enum/City`);
   };

   const getPostCodeCities = (): Promise<ApiResponse<PostCodeCityDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Enum/PostCode`);
   };

   return {
      getCities,
      getPostCodeCities,
   };
};

export default {
   create,
};
