import React from 'react';
import { Criteria } from 'Models/Application/Dto/CriteriaDto';
import { isCriteriaMet } from 'Util/Helpers/Application';
import { ConditionCell } from './Conditions/ConditionCell';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import {
   LICENCE_CHECK_SUPERVISOR_CITY_CONDITION,
   reduceSupervisionConditions,
   STYLED_SUPERVISION_CONDITION,
} from 'Util/Constants/Conditions';
import { ApplicationCondition } from 'Models/Application/Data/ApplicationCondition';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';

interface CriteriaViewProps {
   isEditing: boolean;
   application: ApplicationEdit;
   updateApplication: (application: ApplicationEdit) => void;
   criteria: Criteria;
   fitAndProper?: QuestionSaveBulkDto[];
   setFitAndProper?: (fitAndProper: QuestionSaveBulkDto[]) => void;
}

export const CriteriaView = ({
   isEditing,
   application,
   updateApplication,
   criteria,
   fitAndProper,
   setFitAndProper,
}: Readonly<CriteriaViewProps>): JSX.Element => {
   const isMet = isCriteriaMet(criteria.conditions, application.conditions);
   const criteriaStatusClass = isMet ? 'text-green' : 'text-red';
   const criteriaIcon = isMet ? '✔' : '✘';
   const title = `Criteria ${criteria.criteriaNumber}`;

   const criteriaConditions = application.conditions.filter((c): boolean =>
      criteria.conditions.includes(c.conditionCode)
   );
   // filter down the supervision conditions

   const filteredConditions = reduceSupervisionConditions(criteriaConditions);
   const found = filteredConditions.find(a => {
      return a.conditionCode === 'SPV';
   });

   let renderConditions = filteredConditions;

   if (found) {
      const supervisionConditions: ApplicationCondition[] = [];
      const nonSupervisionConditions: ApplicationCondition[] = [];

      filteredConditions.forEach(e => {
         if (
            e.conditionCode === 'SDSS' ||
            e.conditionCode === 'SPV' ||
            e.conditionCode === LICENCE_CHECK_SUPERVISOR_CITY_CONDITION
         ) {
            supervisionConditions.push(e);
         } else {
            nonSupervisionConditions.push(e);
         }
      });
      supervisionConditions.sort((a, b) => {
         if (a.conditionCode === 'SPV') return -1;
         else return 1;
      });
      const combinedConditions = supervisionConditions.concat(
         nonSupervisionConditions
      );
      renderConditions = combinedConditions;
   }

   if (!renderConditions.length) return <> </>;
   const conditionCells = renderConditions.map(
      (ac): JSX.Element => {
         return (
            <div key={ac.conditionCode} className="md-cell md-cell--12">
               <ConditionCell
                  application={application}
                  updateApplication={updateApplication}
                  appCondition={ac}
                  disabled={
                     !isEditing ||
                     (ac.isSystemControlled &&
                        ac.conditionCode !== STYLED_SUPERVISION_CONDITION)
                  }
                  fitAndProper={fitAndProper}
                  setFitAndProper={setFitAndProper}
               />
            </div>
         );
      }
   );

   return (
      <>
         <h2 className="criteria-header">
            <span className={criteriaStatusClass}>
               {title} {criteriaIcon}
            </span>
         </h2>
         {conditionCells}
      </>
   );
};
