import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import CourseCriteriaDto from 'Models/Examination/Dto/CourseCriteriaDto';
import { withAccessToken, createApi } from '.';
import CourseDtoReadonly from 'Models/Examination/Dto/CourseDto';
import CourseViewDtoReadonly from 'Models/Examination/Dto/CourseViewDto';

export interface CourseServiceType {
   getCourseCriteria(): Promise<ApiResponse<readonly CourseCriteriaDto[]>>;
   getCourseByCourseNumber(
      courseNumber: string
   ): Promise<ApiResponse<CourseDtoReadonly>>;
   getCourseNumbers(): Promise<ApiResponse<string[]>>;
   saveCourse(
      course: CourseDtoReadonly
   ): Promise<ApiResponse<CourseDtoReadonly>>;
   getAllCourses(): Promise<ApiResponse<CourseViewDtoReadonly[]>>;
   editCourse(
      course: CourseDtoReadonly
   ): Promise<ApiResponse<CourseDtoReadonly>>;
}

const create = (baseURL: string | undefined): CourseServiceType => {
   const api = createApi({ baseURL });

   const getAllCourses = (): Promise<ApiResponse<CourseViewDtoReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Course/All`);
   };

   const getCourseCriteria = (): Promise<ApiResponse<CourseCriteriaDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Course/GetCourseCriteria`);
   };

   const getCourseByCourseNumber = (
      courseNumber: string
   ): Promise<ApiResponse<CourseDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Course/GetCourseByCourseNumber/${courseNumber}`);
   };

   const getCourseNumbers = (): Promise<ApiResponse<string[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Course/GetCourseNumbers`);
   };

   const saveCourse = (
      course: CourseDtoReadonly
   ): Promise<ApiResponse<CourseDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Course/AddCourse', course);
   };

   const editCourse = (
      course: CourseDtoReadonly
   ): Promise<ApiResponse<CourseDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Course/EditCourse', course);
   };

   return {
      getAllCourses,
      getCourseCriteria,
      getCourseByCourseNumber,
      getCourseNumbers,
      saveCourse,
      editCourse,
   };
};

export default {
   create,
};
