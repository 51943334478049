import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

interface ScrollTopProps {
   children: JSX.Element;
}

const ScrollTop = ({ children }: Readonly<ScrollTopProps>): JSX.Element => {
   const location = useLocation();
   useEffect((): void => {
      window.scrollTo(0, 0);
   }, [location]);

   return <>{children}</>;
};

export default ScrollTop;
