import Contact from 'Models/Contact/Data/Contact';
import {
   DEFAULT_COUNTRY_CODE,
   getCountryName,
} from 'Util/Constants/CountryCode';

const buildAddressText = (values: readonly string[]): string => {
   const pieces: string[] = [];

   values.forEach((val): void => {
      if (val) pieces.push(val);
   });

   return pieces.join(', ');
};

type ContactMailingAddress = Readonly<
   Pick<
      Contact,
      | 'mailingAddressLine1'
      | 'mailingAddressLine2'
      | 'mailingSuburbTown'
      | 'mailingCity'
      | 'mailingPostalCode'
      | 'mailingCountryCode'
   >
>;

export const getMailingAddressLine = (
   contact: ContactMailingAddress
): string => {
   const mailingCity =
      contact.mailingCity && contact.mailingPostalCode
         ? `${contact.mailingCity} ${contact.mailingPostalCode}`
         : contact.mailingCity
         ? contact.mailingCity
         : '';

   const address = buildAddressText([
      contact.mailingAddressLine1,
      contact.mailingAddressLine2,
      contact.mailingSuburbTown,
      mailingCity,
   ]);

   const countryCode = contact.mailingCountryCode || DEFAULT_COUNTRY_CODE;
   return countryCode !== DEFAULT_COUNTRY_CODE
      ? `${address} ${getCountryName(countryCode)}`
      : address;
};

type ContactBusinessAddress = Readonly<
   Pick<
      Contact,
      | 'businessAddressLine1'
      | 'businessAddressLine2'
      | 'businessSuburbTown'
      | 'businessCity'
      | 'businessPostalCode'
      | 'businessCountryCode'
   >
>;

export const getBusinessAddressLine = (
   contact: ContactBusinessAddress
): string => {
   const businessCity =
      contact.businessCity && contact.businessPostalCode
         ? `${contact.businessCity} ${contact.businessPostalCode}`
         : contact.businessCity
         ? contact.businessCity
         : '';

   const address = buildAddressText([
      contact.businessAddressLine1,
      contact.businessAddressLine2,
      contact.businessSuburbTown,
      businessCity,
   ]);

   const countryCode = contact.businessCountryCode || DEFAULT_COUNTRY_CODE;
   return countryCode !== DEFAULT_COUNTRY_CODE
      ? `${address} ${getCountryName(countryCode)}`
      : address;
};

type ContactPhysicalAddress = Readonly<
   Pick<
      Contact,
      | 'physicalAddressLine1'
      | 'physicalAddressLine2'
      | 'physicalSuburbTown'
      | 'physicalCity'
      | 'physicalPostalCode'
      | 'physicalCountryCode'
   >
>;

export const getPhysicalAddressLine = (
   contact: ContactPhysicalAddress
): string => {
   const physicalCity =
      contact.physicalCity && contact.physicalPostalCode
         ? `${contact.physicalCity} ${contact.physicalPostalCode}`
         : contact.physicalCity
         ? contact.physicalCity
         : '';

   const address = buildAddressText([
      contact.physicalAddressLine1,
      contact.physicalAddressLine2,
      contact.physicalSuburbTown,
      physicalCity,
   ]);

   const countryCode = contact.physicalCountryCode || DEFAULT_COUNTRY_CODE;
   return countryCode !== DEFAULT_COUNTRY_CODE
      ? `${address} ${getCountryName(countryCode)}`
      : address;
};

export const isValidPhysicalAddress = (address: string): boolean => {
   return address ? !address.toLowerCase().includes('po box') : true;
};
