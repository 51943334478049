const formatter = new Intl.NumberFormat('en-NZ', {
   style: 'currency',
   currency: 'NZD',
});

export const formatCurrencyString = (
   value: number | null | undefined
): string => {
   if (!value && value !== 0) return '';

   return formatter.format(value);
};

export const currencyFormatWithoutSymbol = (value: number): string => {
   return formatter.format(value).replace('$', '');
};
