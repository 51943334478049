import React from 'react';
import Button from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import './UpdateApplicationStatusModalBody.scss';

interface UpdateApplicationStatusModalBodyProps {
   onConfirm: () => void;
   onCancel: () => void;
   status: string;
}

export const UpdateApplicationStatusModalBody = ({
   onConfirm,
   onCancel,
   status,
}: Readonly<UpdateApplicationStatusModalBodyProps>): JSX.Element => {
   return (
      <div className="md-grid md-cell--12">
         <div className="md-grid md-cell--12 grey-background final-status-application-modal">
            <div className="md-grid md-cell--12">
               Are you sure you want to update the status of this application?
               The status &lsquo;{status}&rsquo; is a final state and the
               operation cannot be undone.
            </div>
            <div className="md-grid md-cell--12">
               <Button
                  className="btn-spacing"
                  onClick={onConfirm}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) onConfirm();
                  }}
                  flat
                  swapTheming
                  primary
               >
                  Yes
               </Button>
               <Button
                  className="red-btn btn-spacing"
                  onClick={onCancel}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) onCancel();
                  }}
                  flat
                  swapTheming
                  secondary
               >
                  No
               </Button>
            </div>
         </div>
      </div>
   );
};
