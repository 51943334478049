import React from 'react';
import Moment from 'moment-timezone';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import Registration from 'Models/Registration/Registration';
import { Column, CellInfo, SortingRule } from 'react-table';
import { orderBy } from 'lodash-es';
import { NZ_TIMEZONE, DISPLAY_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import { ContactTypes } from 'State/Redux/ContactRedux';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';

interface RegistrationPanelProps {
   registrations: readonly Registration[];
   selectToEdit: (id: number) => void;
}

const IconHeader = (): JSX.Element => {
   return <FaIcon icon="ellipsis-h" />;
};

const DEFAULT_SORTING: SortingRule[] = [{ id: 'registeredDate', desc: true }];

const RegistrationPanel = ({
   registrations,
   selectToEdit,
}: Readonly<RegistrationPanelProps>): JSX.Element => {
   const orderedRegistrations: Registration[] = orderBy(
      registrations,
      'registeredDate',
      'desc'
   );

   const isLoading = useSelector(
      selectIsLoading([ContactTypes.GET_REGISTRATIONS_BY_CONTACT_REQUEST])
   );

   const COLUMN_HEADERS: Column<Registration>[] = [
      {
         Header: 'Registration Type',
         accessor: 'registrationDescription',
      },
      {
         Header: 'Status',
         accessor: 'registrationStatusDescription',
      },
      {
         Header: 'Registration Date',
         accessor: 'registeredDate',
         Cell: (cellInfo): string => {
            return Moment(cellInfo.value)
               .tz(NZ_TIMEZONE)
               .format(DISPLAY_DATE_FORMAT);
         },
      },
      {
         Header: 'Cancellation Date',
         accessor: 'struckOffDate',
         Cell: (cellInfo): string => {
            return cellInfo.value
               ? Moment(cellInfo.value)
                    .tz(NZ_TIMEZONE)
                    .format(DISPLAY_DATE_FORMAT)
               : 'Not Cancelled';
         },
      },
      {
         Header: IconHeader,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         accessor: FN_EMPTY_VOID,
         Cell: (cell: CellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     selectToEdit(cell.original.registrationId);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <ClientSideTable
         data={orderedRegistrations}
         defaultPageSize={20}
         columns={COLUMN_HEADERS}
         defaultSorted={DEFAULT_SORTING}
         loading={isLoading}
      />
   );
};

export default RegistrationPanel;
