import React from 'react';
import { ConditionValueProps } from './ConditionValueProps';
import { useSelector } from 'react-redux';
import { createSelectList } from 'Util/Helpers/Input';
import SelectField from 'react-md/lib/SelectFields';
import TextField from 'react-md/lib/TextFields';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { selectLicenceTrainingProviders } from 'State/Redux/ContactRedux';

interface ConditionTrainingProviderProps {
   onChange?: (val: string | number) => void;
}

export const ConditionTrainingProvider = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
}: Readonly<
   ConditionTrainingProviderProps & ConditionValueProps
>): JSX.Element => {
   const trainingProviders = useSelector(selectLicenceTrainingProviders);
   const selectListItems = createSelectList(
      trainingProviders,
      (tp): string => tp.contactId.toString(),
      (tp): string =>
         tp.companyName ||
         `Unnamed Training Provider (${tp.contactId.toString()})`,
      'Select a Training Provider'
   );

   const provider = trainingProviders.find(
      (tp): boolean => tp.contactId.toString() === textValue
   );
   const unselectedMessage =
      textValue && provider ? textValue : 'No Training Provider Selected';

   return provider || !disabled ? (
      <SelectField
         id={`app-${1}-${conditionCode}`}
         lineDirection="center"
         className="md-cell md-cell--12"
         label={description}
         inputClassName={getInputValidationClassName(!isChecked)}
         menuItems={selectListItems}
         position={SelectField.Positions.BELOW}
         value={textValue}
         defaultValue=" "
         disabled={disabled}
         onChange={onChange}
      />
   ) : (
      <TextField
         id={`app-${1}-${conditionCode}`}
         className="md-cell md-cell--12"
         label={description}
         lineDirection="center"
         defaultValue={unselectedMessage}
         disabled={disabled}
      />
   );
};
