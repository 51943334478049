import React, { useEffect } from 'react';
import './FileUploadSection.scss';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   ApplicationActions,
   selectRequestAnotherFiles,
} from 'State/Redux/ApplicationRedux';
import { toast } from 'react-toastify';

interface Props {
   applicationId: number;
}

const FileApplicationDocumentRequest = ({
   applicationId,
}: Props): JSX.Element => {
   const dispatch = useDispatch();
   const [documentName, setDocumentName] = useState<string | number>();

   const clearTextField = (): void => {
      setDocumentName('');
   };

   const resetRequestAnotherFiles = (): void => {
      dispatch(ApplicationActions.putRequestAnotherFilesReset());
   };

   const responseRequestAnotherFiles = useSelector(selectRequestAnotherFiles);

   useEffect((): void => {
      if (responseRequestAnotherFiles === false) {
         toast.error(
            `Please change the application status to 'Under review' before requesting document.`
         );
      }
      if (responseRequestAnotherFiles === true) {
         toast.success('Document Requested');
         clearTextField();
      }
      return resetRequestAnotherFiles();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [responseRequestAnotherFiles]);

   const submitRequestDocument = (): void => {
      if (documentName) {
         const regex = /^\s*$/;
         if (!regex.test(documentName.toString().trim())) {
            dispatch(
               ApplicationActions.putRequestAnotherFilesRequest({
                  applicationId: applicationId,
                  description: documentName.toString(),
               })
            );
         } else {
            toast.error('The input consists of only whitespace characters');
         }
      } else toast.error('You need to describe the file requested');
   };

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--7">
            <TextField
               floating
               id="document-request"
               label="What is the missing document?"
               lineDirection="center"
               placeholder="Document Name"
               className="md-cell--12"
               value={documentName}
               onChange={(val): void => {
                  setDocumentName(val);
               }}
               maxLength={100}
            />
         </div>
         <div className="md-cell md-cell--5 request-document-button">
            <Button
               flat
               primary
               swapTheming
               className="request-document-button-text"
               onClick={submitRequestDocument}
            >
               REQUEST DOCUMENT
            </Button>
         </div>
      </div>
   );
};

export default FileApplicationDocumentRequest;
