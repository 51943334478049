import React, { ReactNode, useState } from 'react';
import { getCountryName } from 'Util/Constants/CountryCode';
import './style.scss';
import FaIcon from '../FaIcon/FaIcon';
import { SelectField } from 'react-md';
import { getDropdownList } from 'Util/Constants/CountryCode';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const CountryListMultiSelect = ({
   disabled,
   selectedCountries = [],
   onUpdateSelectedCountries,
}: {
   disabled?: boolean;
   selectedCountries?: Array<{ value: string }>;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   onUpdateSelectedCountries: any;
}) => {
   const [country, setCountry] = useState('AFG');
   const countryCodes = [...getDropdownList()];

   const renderCountryListItems = (): ReactNode => {
      return selectedCountries.map(c => (
         <span className="country-item" key={c.value}>
            {getCountryName(c.value)}
            {!disabled && (
               <FaIcon
                  disabled={disabled}
                  className="delete-button"
                  icon="times-circle"
                  onClick={(): void => deleteItemFromList(c.value)}
               />
            )}
         </span>
      ));
   };

   // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
   const deleteItemFromList = (countryVal: string) => {
      const updatedList = selectedCountries.filter(f => f.value !== countryVal);
      if (updatedList) {
         onUpdateSelectedCountries(updatedList);
      }
   };

   return (
      <div className="country-multiselect row">
         {!disabled && (
            <div className="country-selector">
               <div className="country-dropdown">
                  <div className="countries-selected-list row">
                     <SelectField
                        disabled={disabled}
                        id="add-address-country"
                        label="Select Country"
                        placeholder="Select Country"
                        className=""
                        errorText="Please enter the Country"
                        required
                        floating
                        menuItems={countryCodes}
                        value={country}
                        onChange={(val): void => setCountry(val.toString())}
                     />
                  </div>
                  <div className="countries-selected-list row">
                     <span className="country-item">
                        <FaIcon
                           disabled={disabled}
                           className="add-button"
                           icon="plus-circle"
                           onClick={(): void => {
                              const alreadyAdded = selectedCountries.find(
                                 f => f.value === country
                              );

                              if (!alreadyAdded) {
                                 onUpdateSelectedCountries((prev: []) => [
                                    ...prev,
                                    { value: country },
                                 ]);
                              }
                           }}
                        />
                        Add
                     </span>
                  </div>
               </div>
            </div>
         )}
         <div className="countries-selected-list row">
            {renderCountryListItems()}
         </div>
      </div>
   );
};

export default CountryListMultiSelect;
