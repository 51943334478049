import SupervisionHistoryReadonly from './SupervisionHistory';
import {
   SupervisionStatusCode,
   SUPERVISION_STATUS_PENDING,
} from 'Util/Constants/SupervisionStatus';
import LicenceDtoReadonly, {
   DefaultLicenceDto,
} from 'Models/Licence/Dto/LicenceDto';

export interface Supervision {
   supervisionId: number | null;
   applicationId: number | null;
   licenceYearId: number | null;
   supervisorName: string;
   registrationNumber: number | null;
   supervisorRenewedLicence: boolean | null;
   supervisorId: number | null;
   startDate: string | null;
   endDate: string | null;
   supervisionStatus: SupervisionStatusCode;
   licenceStatus: string;
   licenceName: string;
   licenceDisciplineCode: string;
   licenceType: string;
   licenceTypeCode: string;
   licenceExpiryDate: string | null;
   licence: LicenceDtoReadonly;
   supervisionHistory: SupervisionHistoryReadonly[];
}

export const DefaultSupervision: Supervision = {
   supervisionId: 0,
   applicationId: null,
   licenceYearId: null,
   supervisorName: '',
   registrationNumber: null,
   supervisorRenewedLicence: null,
   supervisorId: 0,
   startDate: null,
   endDate: null,
   supervisionStatus: SUPERVISION_STATUS_PENDING,
   licenceStatus: '',
   licenceName: '',
   licenceDisciplineCode: '',
   licenceType: '',
   licenceTypeCode: '',
   licenceExpiryDate: null,
   licence: DefaultLicenceDto,
   supervisionHistory: [],
};

export type SupervisionReadonly = Readonly<Supervision>;

export default SupervisionReadonly;
