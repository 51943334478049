import {
   isValidRegistrationNumber,
   isValidLicenceType,
   isValidStatus,
   isValidSupervisor,
   isValidStartDate,
   isValidEndDate,
} from 'Util/Helpers/LicenceSearch';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { getCurrentDate } from 'Util/Helpers/Date';

interface Query {
   registrationNumber: string;
   licenceTypeCode: string;
   licenceStatusCode: string;
   supervisorRegistrationNumber: string;
   startDate: string;
   endDate: string;
}

export type LicenceSearchQuery = Readonly<Query>;

interface QueryValidation {
   registrationNumber: boolean;
   licenceTypeCode: boolean;
   licenceStatusCode: boolean;
   supervisorRegistrationNumber: boolean;
   startDate: boolean;
   endDate: boolean;
}

export type LicenceSearchQueryValidation = Readonly<QueryValidation>;

export const DefaultSearchModel: LicenceSearchQuery = {
   registrationNumber: '',
   licenceTypeCode: '',
   licenceStatusCode: '',
   supervisorRegistrationNumber: '',
   startDate: '',
   endDate: getCurrentDate(),
};

export const DefaultValidationModel: LicenceSearchQueryValidation = {
   registrationNumber: false,
   licenceTypeCode: false,
   licenceStatusCode: false,
   supervisorRegistrationNumber: false,
   startDate: false,
   endDate: false,
};

export const validateSearchQuery = (
   query: LicenceSearchQuery
): LicenceSearchQueryValidation => {
   return {
      registrationNumber:
         isNullOrWhiteSpace(query.registrationNumber) ||
         isValidRegistrationNumber(query.registrationNumber),
      licenceTypeCode:
         isNullOrWhiteSpace(query.licenceTypeCode) ||
         isValidLicenceType(query.licenceTypeCode),
      licenceStatusCode:
         isNullOrWhiteSpace(query.licenceStatusCode) ||
         isValidStatus(query.licenceStatusCode),
      supervisorRegistrationNumber:
         isNullOrWhiteSpace(query.supervisorRegistrationNumber) ||
         isValidSupervisor(query.supervisorRegistrationNumber),
      startDate:
         isNullOrWhiteSpace(query.startDate) ||
         isValidStartDate(query.startDate),
      endDate:
         isNullOrWhiteSpace(query.endDate) || isValidEndDate(query.endDate),
   };
};

export const filterToValidQuery = (
   query: LicenceSearchQuery
): LicenceSearchQuery => {
   return {
      registrationNumber: !isNullOrWhiteSpace(query.registrationNumber)
         ? query.registrationNumber
         : '',
      licenceTypeCode: !isNullOrWhiteSpace(query.licenceTypeCode)
         ? query.licenceTypeCode
         : '',
      licenceStatusCode: !isNullOrWhiteSpace(query.licenceStatusCode)
         ? query.licenceStatusCode
         : '',
      supervisorRegistrationNumber: isValidSupervisor(
         query.supervisorRegistrationNumber
      )
         ? query.supervisorRegistrationNumber
         : '',
      startDate: isValidStartDate(query.startDate) ? query.startDate : '',
      endDate: isValidEndDate(query.endDate) ? query.endDate : '',
   };
};

export const isValidSearchQuery = (query: LicenceSearchQuery): boolean => {
   return (
      !isNullOrWhiteSpace(query.registrationNumber) ||
      !isNullOrWhiteSpace(query.licenceTypeCode) ||
      !isNullOrWhiteSpace(query.licenceStatusCode) ||
      !isNullOrWhiteSpace(query.supervisorRegistrationNumber) ||
      !isNullOrWhiteSpace(query.startDate) ||
      !isNullOrWhiteSpace(query.endDate)
   );
};
