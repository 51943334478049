import React from 'react';
import UserCard from 'Components/Common/UserCard/UserCard';
import PendingRenewalApplication from 'Components/Common/PendingRenewalApplication';
import Loading from 'Components/Common/Loading';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import {
   ApplicationTypes,
   ApplicationActions,
   selectApplicationPendingRenewal,
} from 'State/Redux/ApplicationRedux';
import { useSelector, useDispatch } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';

const ApplicationsPanel = (): JSX.Element => {
   const dispatch = useDispatch();
   const contact = useSelector(selectContact);
   const isLoading = useSelector(
      selectIsLoading([
         ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST,
      ])
   );
   const isError = useSelector(
      selectIsError([ApplicationTypes.GET_APPLICATION_PENDING_RENEWAL_REQUEST])
   );
   const loadPendingApplications = (): void => {
      dispatch(
         ApplicationActions.getApplicationPendingRenewalRequest(
            contact.contactId.toString()
         )
      );
   };
   const pendingApplications = useSelector(selectApplicationPendingRenewal);
   const showPendingApplications =
      !!pendingApplications && pendingApplications.length > 0;

   return (
      <div className="ApplicationsPanel">
         <div className="md-grid md-cell--12">
            <UserCard />
         </div>
         <Loading
            isLoading={isLoading}
            isError={isError}
            tryReload={loadPendingApplications}
         >
            {showPendingApplications ? (
               <div className="md-grid md-cell--12">
                  <h2 className="md-grid md-cell--12">
                     Pending Renewal Application(s)
                  </h2>
                  <PendingRenewalApplication />
               </div>
            ) : (
               <></>
            )}
         </Loading>
      </div>
   );
};

export default ApplicationsPanel;
