import ValidationModel, {
   ValidationResultField,
   ValidationResult,
} from './ValidationModel';
import {
   ValidationModelItem,
   ValidationMessageFunction,
   ValidationFunction,
} from './ValidationModelItem';

import ValidationType from './ValidationType';
import { get, isFunction } from 'lodash-es';
import {
   isNullOrWhiteSpace,
   isValidEmail,
   isValidPhoneNumber,
   isNumber,
   isValidUrl,
   isValidLicenceNumber,
   isAmount,
} from 'Util/Helpers/Validation';

function isValidationType<T>(
   type?: ValidationType | ValidationFunction<T>
): type is ValidationType {
   if (!type) return false;
   // this is a constrained literal type so use string checks
   return (type as string).charCodeAt !== undefined;
}

const isValueInValid = (type: ValidationType, value: string): boolean => {
   switch (type) {
      case 'text' || 'date':
         return isNullOrWhiteSpace(value);
      case 'registration-number':
         return !isValidLicenceNumber(value.toString());
      case 'email':
         return !isValidEmail(value);
      case 'phone-number':
         return !isValidPhoneNumber(value);
      case 'number':
         return !isNumber(value.toString());
      case 'amount':
         return !isAmount(value.toString());
      case 'url':
         return !isValidUrl(value);
      default:
         return true;
   }
};

function isStringMessage<T>(
   message?: string | ValidationMessageFunction<T>
): message is string {
   if (!message) return false;
   return (message as string).charCodeAt !== undefined;
}

function isFunctionMessage<T>(
   message?: string | ValidationMessageFunction<T>
): message is string {
   if (!message) return false;
   return (message as string).charCodeAt !== undefined;
}

function validateFieldCore<T>(
   data: T,
   item: ValidationModelItem<T>,
   value: string
): ValidationResultField {
   let errorMessage = '';
   if (isStringMessage(item.errorMessage)) {
      errorMessage = item.errorMessage;
   } else if (isFunctionMessage(item.errorMessage)) {
      errorMessage = item.errorMessage(data);
   }

   if (item.required && isNullOrWhiteSpace(value)) {
      return {
         error: true,
         errorMessage: errorMessage,
      };
   } else if (!value) {
      return { error: false, errorMessage: '' };
   }

   let isError = true;
   if (!item.error) {
      if (isValidationType(item.validation)) {
         isError = isValueInValid(item.validation, value);
      } else if (item.validation) {
         isError = item.validation(data);
      }
   }

   return {
      error: isError,
      errorMessage: isError ? '' : errorMessage,
   };
}

function validateModelWithFields<T>(
   data: T,
   model: Readonly<ValidationModel<T>>,
   validationFields: Record<keyof T, ValidationResultField>
): ValidationResult<T> {
   const isForcedError =
      model.isModelValid !== undefined && !isFunction(model.isModelValid);
   let isValid = isForcedError && !!model.isModelValid;
   if (!isForcedError) {
      if (isFunction(model.isModelValid)) {
         isValid = model.isModelValid(data);
      } else {
         const fields: ValidationResultField[] = Object.values(
            validationFields
         );
         isValid = fields.every((f): boolean => !f.error);
      }
   }

   let errorMessage = '';
   if (!isValid) {
      errorMessage = isStringMessage(model.errorMessage)
         ? model.errorMessage
         : isFunctionMessage(model.errorMessage)
         ? model.errorMessage(data)
         : '';
   }

   return {
      fields: validationFields,
      error: !isValid,
      errorMessage: errorMessage,
   };
}

export function validateModel<T>(
   data: T,
   model: Readonly<ValidationModel<T>>
): ValidationResult<T> {
   const keys = Object.keys(model.fields) as [keyof T];

   const validationFields = keys.reduce(
      (acc, field): Record<keyof T, ValidationResultField> => {
         acc[field] = validateFieldCore(
            data,
            model.fields[field],
            get(data, field, '')
         );

         return acc;
      },
      {} as Record<keyof T, ValidationResultField> // eslint-disable-line
   );
   return validateModelWithFields(data, model, validationFields);
}

export function validateField<T>(
   data: T,
   model: Readonly<ValidationModel<T>>,
   field: keyof T,
   currentResult: ValidationResult<T>
): ValidationResult<T> {
   currentResult.fields[field] = validateFieldCore(
      data,
      model.fields[field],
      get(data, field, '')
   );

   return validateModelWithFields(data, model, currentResult.fields);
}
