interface UserEdit {
   userId?: string;
   userName: string;
   fullName: string;
   emailAddress: string;
   roleCode: string;
   isDisabled: boolean;
}

type UserEditModel = Readonly<UserEdit>;

export default UserEditModel;

export const DefaultUserEditModel: UserEditModel = {
   userName: '',
   fullName: '',
   emailAddress: '',
   roleCode: '',
   isDisabled: false,
};
