import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import FeeReadonly, { Fee } from 'Models/FeeMaintenance/Dto/Fee';
import { FeeMaintenanceSearchQuery } from 'Models/FeeMaintenance/Dto/FeeMaintenanceSearchQuery';

export interface FeeMaintenanceServiceType {
   updateFeeMaintenance(
      feeMaintenance: FeeReadonly
   ): Promise<ApiResponse<readonly FeeReadonly[]>>;
   getFeeMaintenance(
      query: FeeMaintenanceSearchQuery
   ): Promise<ApiResponse<readonly Fee[]>>;
   addFeeMaintenance(
      feeMaintenance: FeeReadonly
   ): Promise<ApiResponse<FeeReadonly>>;
}

const create = (baseURL: string | undefined): FeeMaintenanceServiceType => {
   const api = createApi({ baseURL });

   const updateFeeMaintenance = (
      feeMaintenance: FeeReadonly
   ): Promise<ApiResponse<readonly FeeReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/FeeMaintenance`, feeMaintenance);
   };

   const getFeeMaintenance = (
      query: FeeMaintenanceSearchQuery
   ): Promise<ApiResponse<readonly Fee[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/FeeMaintenance/Search`, query);
   };

   const addFeeMaintenance = (
      feeMaintenance: FeeReadonly
   ): Promise<ApiResponse<FeeReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/FeeMaintenance`, feeMaintenance);
   };

   return {
      updateFeeMaintenance,
      getFeeMaintenance,
      addFeeMaintenance,
   };
};

export default {
   create,
};
