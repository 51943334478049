import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import { UserReadonly } from 'Models/Identity/User';
import ApiError from 'Models/Other/ApiError';
import PasswordReset from 'Models/Identity/PasswordReset';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface IdentityState {
   user: UserReadonly;
   passwordResetResponse: PasswordReset;
}

export type IdentityStateReadonly = Readonly<IdentityState>;

interface TypeNames {
   POST_PASSWORD_RESET_REQUEST: string;
   POST_PASSWORD_RESET_SUCCESS: string;
   POST_PASSWORD_RESET_FAILURE: string;
   PASSWORD_RESET_RESPONSE_RESET: string;
}

type IdentityTypeNames = Readonly<TypeNames>;

export interface IdentityCreators {
   postPasswordResetRequest: (user: UserReadonly) => AnyAction;
   postPasswordResetSuccess: (
      passwordResetResponse: PasswordReset | undefined
   ) => AnyAction;
   postPasswordResetFailure: (
      error: ApiError,
      passwordResetResponse: PasswordReset | undefined
   ) => AnyAction;
   passwordResetResponseReset: () => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: IdentityStateReadonly = {
   user: ({} as unknown) as UserReadonly,
   passwordResetResponse: ({} as unknown) as PasswordReset,
};

/* ------------- Reducers ------------- */
const postPasswordResetSuccess = (
   state: IdentityStateReadonly,
   action: AnyAction
): IdentityStateReadonly => {
   if (action && action.response) {
      return {
         ...state,
         passwordResetResponse: action.response,
      };
   }
   return state;
};

const postPasswordResetFailure = (
   state: IdentityStateReadonly,
   action: AnyAction
): IdentityStateReadonly => {
   return {
      ...state,
      passwordResetResponse: action.response,
   };
};

const passwordResetResponseReset = (
   state: IdentityStateReadonly
): IdentityStateReadonly => {
   return {
      ...state,
      passwordResetResponse: INITIAL_STATE.passwordResetResponse,
   };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   IdentityTypeNames,
   IdentityCreators
>('identity', {
   postPasswordResetRequest: ['user'],
   postPasswordResetSuccess: ['response'],
   postPasswordResetFailure: ['error', 'response'],
   passwordResetResponseReset: [],
});

export const IdentityTypes = Types;
export const IdentityActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.POST_PASSWORD_RESET_SUCCESS]: postPasswordResetSuccess,
   [Types.POST_PASSWORD_RESET_FAILURE]: postPasswordResetFailure,
   [Types.PASSWORD_RESET_RESPONSE_RESET]: passwordResetResponseReset,
});

/* ------------- Selectors ------------- */

export const selectPasswordResetResponse = (
   state: StateReadonly
): PasswordReset => state.identity.passwordResetResponse;
