export const ConvertByteArrayStringToBlob = (
   base64Stream: string,
   contentType: string
): Blob => {
   // decode base64 string, remove space for IE compatibility
   const binary = atob(base64Stream.replace(/\s/g, ''));
   const len = binary.length;
   const buffer = new ArrayBuffer(len);
   const view = new Uint8Array(buffer);
   for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
   }
   return new Blob([view], {
      type: contentType,
   });
};
