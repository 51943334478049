import React from 'react';
import { Permission } from 'Util/Constants/Permission';
import { Authorized } from 'Components/Common/Authorized';
import { Grid, Cell } from 'react-md/lib/Grids';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { Link } from 'react-router-dom';
import Button from 'react-md/lib/Buttons';
import './ApplicationReviewPanel.scss';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const REGISTRAR_PERMISSION: Permission = 'ReviewRegistration.Read';

interface ApplicationReviewPanelProps {
   panelType: string;
   count: number;
}

const renderButtons = (panelType: string): JSX.Element => {
   if (panelType === 'admin') {
      return (
         <Link to="/registration/applications">
            <Button flat primary swapTheming>
               REVIEW APPLICATIONS
            </Button>
         </Link>
      );
   } else {
      return (
         <Link to="/review/applications">
            <Button flat primary swapTheming>
               REVIEW APPLICATIONS
            </Button>
         </Link>
      );
   }
};

const renderCount = (count: number): JSX.Element => {
   const textToRender =
      count === 0 ? `${count} application` : `${count} applications`;

   return <h4 className="md-title">You have {textToRender} pending review.</h4>;
};

const ApplicationReviewPanel = ({
   count,
   panelType,
}: Readonly<ApplicationReviewPanelProps>): JSX.Element => {
   return (
      <Authorized permissions={[REGISTRAR_PERMISSION]}>
         <Grid>
            <Cell size={12}>
               <ExpansionList>
                  <ExpansionPanel
                     label={
                        panelType === 'admin'
                           ? 'Pending Admin approval'
                           : 'Pending Registrar approval'
                     }
                     className="app-review-panel"
                     footer={null}
                     expanded
                     expanderIcon={<></>}
                     onExpandToggle={FN_EMPTY_VOID}
                  >
                     {renderCount(count)}
                     {renderButtons(panelType)}
                  </ExpansionPanel>
               </ExpansionList>
            </Cell>
         </Grid>
      </Authorized>
   );
};

export default ApplicationReviewPanel;
