import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import SearchBoardMeetingDates from './SearchBoardMeetingDates';
import AddBoardMeetingDate from './AddBoardMeetingDate';

const boardMeetingDateTabs: readonly RoutedTab[] = [
   {
      label: 'Search',
      route: '',
      component: SearchBoardMeetingDates,
      pageTitle: 'Search',
      exact: true,
   },
   {
      label: 'Add',
      route: '/add',
      component: AddBoardMeetingDate,
      pageTitle: 'Add',
   },
];

const BoardMeetingDate = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={boardMeetingDateTabs}
         baseUrl="/superuser/board-meeting-dates"
         panelClassName="boardMeetingDates"
         pageTitle="Board Meeting Dates"
      />
   );
};

export default BoardMeetingDate;
