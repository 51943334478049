import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import ViewRegistrationType from './ViewRegistrationType';
import AddRegistrationType from './AddRegistrationType';

const registrationTypeTabs: readonly RoutedTab[] = [
   {
      label: 'View',
      route: '',
      component: ViewRegistrationType,
      pageTitle: 'View',
      exact: true,
      permissions: 'SystemParameter.Read',
   },
   {
      label: 'Add',
      route: '/add',
      component: AddRegistrationType,
      pageTitle: 'Add',
      permissions: 'SystemParameter.Create',
   },
];

const RegistrationType = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={registrationTypeTabs}
         baseUrl="/superuser/registration-type"
         panelClassName="registrationType"
         pageTitle="Registration Type"
      />
   );
};

export default RegistrationType;
