import { REGISTRATION_STATUS_ACTIVE } from 'Util/Constants/RegistrationStatus';
import { getCurrentDate } from 'Util/Helpers/Date';

interface Registration {
   registrationId: number;
   registrationTypeCode: string;
   registrationDescription: string;
   registrationDiscipline: string;
   contactId: number;
   registeredDate: string;
   struckOffDate?: string;
   changeToDate?: string;
   applicationId: number;
   registrationStatusCode: string;
   registrationStatusDescription: string;
   conditions: string;
}

type RegistrationReadonly = Readonly<Registration>;

export default RegistrationReadonly;

export const DefaultRegistration: RegistrationReadonly = {
   registrationId: 0,
   registrationTypeCode: '',
   registrationDescription: '',
   registrationDiscipline: '',
   contactId: 0,
   registeredDate: getCurrentDate(),
   struckOffDate: '',
   changeToDate: '',
   applicationId: 0,
   registrationStatusCode: REGISTRATION_STATUS_ACTIVE,
   registrationStatusDescription: '',
   conditions: '',
};
