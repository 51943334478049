import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { SelectField } from 'react-md/lib/SelectFields';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import {
   selectSqlQueries,
   selectSqlQueryResults,
   ReportActions,
   ReportTypes,
} from 'State/Redux/ReportRedux';
import { Column } from 'react-table';
import { createSelectList, isEnterKeyPress } from 'Util/Helpers/Input';
import './SqlReporting.scss';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import Button from 'react-md/lib/Buttons';
import Moment from 'moment-timezone';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import TextField from 'react-md/lib/TextFields';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { createCsv } from 'Util/CSV/CSV';
import LoadingButton from 'Components/Common/LoadingButton';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';
import SqlReportingReadonly from 'Models/Report/SqlReporting';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

const BYTE_ORDER_MARK = '\uFEFF';

const downloadCsv = (csv: string, fileName: string): void => {
   const downloadFileName = `export_${fileName.replace(
      ' ',
      '_'
   )}_${Moment().format('YYYYMMDDF')}.csv`;

   const blob = new Blob([BYTE_ORDER_MARK, csv], { type: 'text/csv' });
   FileSaver.saveAs(blob, downloadFileName);
};

//eslint-disable-next-line
type SqlResultsDisplay = Readonly<Record<string, any>>;

const SqlReporting = (): JSX.Element => {
   const dispatch = useDispatch();
   const defaultQueryText = 'Please select a query to run';
   const sqlQueries = useSelector(selectSqlQueries);
   const queryIsLoading = useSelector(
      selectIsLoading([ReportTypes.GET_SQL_QUERY_RESULTS_REQUEST])
   );
   const queryIsSaving = useSelector(
      selectIsLoading([ReportTypes.EDIT_SQL_QUERY_REQUEST])
   );
   const [showConfirmationDefault, setShowConfirmationDefault] = useState<
      boolean
   >(false);
   const sqlResults = useSelector(selectSqlQueryResults);
   const [isEdit, setIsEdit] = useState<boolean>(false);
   const [modalBtnEnable, setModalBtnEnable] = useState<boolean>(false);
   const [selectedIndex, setSelectedIndex] = useState('');
   const [selectedName, setSelectedName] = useState('');
   const [selectedQuery, setSelectedQuery] = useState(defaultQueryText);
   const [selectedQueryResultData, setSelectedQueryResultData] = useState<
      readonly SqlResultsDisplay[]
   >([]);
   const [columnHeaders, setColumnHeaders] = useState<
      Column<SqlResultsDisplay>[]
   >([]);

   const [newName, setNewName] = useState('');
   const [newQuery, setNewQuery] = useState('');

   useEffect((): void => {
      dispatch(ReportActions.getSqlQueriesRequest());
   }, [dispatch]);

   useEffect((): void => {
      if (!queryIsSaving) {
         setIsEdit(false);
      }
   }, [queryIsSaving]);

   useEffect((): void => {
      if (!sqlResults || !sqlResults.length) return;

      setSelectedQueryResultData(sqlResults);
      const headers = Object.keys(sqlResults[0]);
      setColumnHeaders(
         headers.map(header => {
            return {
               Header: header,
               id: header,
               headerStyle: { textAlign: 'left' },
               accessor: (row: SqlResultsDisplay): string => {
                  return row[header] === undefined || row[header] === null
                     ? ''
                     : (row[header].toString() as string);
               },
            };
         })
      );
   }, [sqlResults]);

   const queryList = createSelectList(
      sqlQueries,
      msg => msg.sqlId.toString(),
      msg => msg.name
   );

   useEffect((): void => {
      if (isNullOrWhiteSpace(newName) || isNullOrWhiteSpace(newQuery))
         setModalBtnEnable(true);
      else setModalBtnEnable(false);
   }, [newName, newQuery]);

   const updateTextBox = (): void => {
      const selectedItem = sqlQueries.find(
         (msg): boolean => msg.sqlId.toString() === selectedIndex
      );

      if (selectedItem && selectedQuery !== selectedItem.query) {
         setSelectedQuery(selectedItem.query);
      }
   };

   const createQuery = (): void => {
      const newQueryItem: SqlReportingReadonly = {
         name: newName,
         query: newQuery,
         sqlId: -1,
      };

      dispatch(ReportActions.addSqlQueryRequest(newQueryItem));
      setNewName('');
      setNewQuery('');
   };

   const deleteQuery = (): void => {
      dispatch(ReportActions.deleteSqlQueryRequest(Number(selectedIndex)));
      setColumnHeaders([]);
      setSelectedQueryResultData([]);
      setSelectedIndex('');
      setSelectedName('');
      setSelectedQuery(defaultQueryText);
      setIsEdit(false);
   };

   return (
      <ExpansionList className="md-cell md-cell--12 sql-reporting">
         <ExpansionPanel
            label="SQL Reporting"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <div className="md-grid md-cell--12">
                  <SelectField
                     floating
                     id="select-field-6"
                     lineDirection="center"
                     label="Selected Query"
                     placeholder="Select Query"
                     className="md-cell md-cell--4"
                     position={SelectField.Positions.BELOW}
                     defaultValue=" "
                     value={selectedIndex}
                     menuItems={queryList}
                     onChange={(val): void => {
                        const selectedItem = sqlQueries.find(
                           (msg): boolean =>
                              msg.sqlId.toString() === val.toString()
                        );

                        setColumnHeaders([]);
                        setSelectedQueryResultData([]);

                        if (!selectedItem) return;
                        setSelectedQuery(selectedItem.query);
                        setSelectedIndex(val.toString());
                        setSelectedName(selectedItem.name);
                     }}
                  />
                  <Button
                     icon
                     className="add-btn"
                     onClick={(): void => {
                        setShowConfirmationDefault(true);
                     }}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        setShowConfirmationDefault(true);
                     }}
                  >
                     <FaIcon icon="plus" />
                     <p>Create</p>
                  </Button>
               </div>
               <div className="md-grid md-cell--12">
                  <div className="md-grid md-cell--12">
                     <h3>SQL Query</h3>
                  </div>
                  {!isEdit ? (
                     <>
                        {selectedName && (
                           <FaIcon
                              onClick={(): void => {
                                 setIsEdit(true);
                              }}
                              onKeyUp={(keyPress): void => {
                                 if (!isEnterKeyPress(keyPress)) return;
                                 setIsEdit(true);
                              }}
                              icon="edit"
                              className="edit-btn"
                           />
                        )}
                        <div className="md-grid md-cell--12">
                           <pre>
                              <code>{selectedQuery}</code>
                           </pre>
                        </div>
                     </>
                  ) : (
                     <TextField
                        floating
                        id="floating-center-title"
                        lineDirection="center"
                        placeholder="Query"
                        className="md-cell md-cell--12"
                        value={selectedQuery}
                        onChange={(val): void => {
                           setSelectedQuery(val.toString());
                        }}
                        rows={20}
                     />
                  )}
                  <div className="md-grid md-cell--12 container-outer">
                     {selectedIndex !== '' && (
                        <LoadingButton
                           flat
                           primary
                           swapTheming
                           isLoading={queryIsLoading}
                           disabled={isEdit}
                           onClick={(): void => {
                              setColumnHeaders([]);
                              setSelectedQueryResultData([]);

                              dispatch(
                                 ReportActions.getSqlQueryResultsRequest(
                                    Number(selectedIndex)
                                 )
                              );
                           }}
                           onKeyUp={(keyPress): void => {
                              if (!isEnterKeyPress(keyPress)) return;
                              setColumnHeaders([]);
                              setSelectedQueryResultData([]);

                              dispatch(
                                 ReportActions.getSqlQueryResultsRequest(
                                    Number(selectedIndex)
                                 )
                              );
                           }}
                        >
                           Execute
                        </LoadingButton>
                     )}
                     {isEdit && (
                        <div className="btn-group">
                           <Button
                              flat
                              primary
                              swapTheming
                              isLoading={false}
                              className="red-btn delete-btn"
                              onClick={(): void => {
                                 deleteQuery();
                              }}
                              onKeyUp={(keyPress): void => {
                                 if (!isEnterKeyPress(keyPress)) return;
                                 deleteQuery();
                              }}
                           >
                              Delete
                           </Button>
                           <Button
                              flat
                              primary
                              swapTheming
                              isLoading={false}
                              className="red-btn"
                              onClick={(): void => {
                                 updateTextBox();
                                 setIsEdit(false);
                              }}
                              onKeyUp={(keyPress): void => {
                                 if (!isEnterKeyPress(keyPress)) return;
                                 updateTextBox();
                                 setIsEdit(false);
                              }}
                           >
                              Cancel
                           </Button>
                           <LoadingButton
                              flat
                              primary
                              swapTheming
                              isLoading={queryIsSaving}
                              onClick={(): void => {
                                 dispatch(
                                    ReportActions.editSqlQueryRequest(
                                       Number(selectedIndex),
                                       selectedQuery
                                    )
                                 );
                              }}
                              onKeyUp={(keyPress): void => {
                                 if (!isEnterKeyPress(keyPress)) return;
                                 dispatch(
                                    ReportActions.editSqlQueryRequest(
                                       Number(selectedIndex),
                                       selectedQuery
                                    )
                                 );
                              }}
                           >
                              Save
                           </LoadingButton>
                        </div>
                     )}
                  </div>
               </div>
               <div className="md-grid md-cell--12">
                  <h3>Output</h3>
                  {selectedQueryResultData.length > 0 || queryIsLoading ? (
                     <>
                        <ClientSideTable
                           loading={queryIsLoading}
                           className="md-cell--12"
                           data={[...selectedQueryResultData]}
                           defaultPageSize={10}
                           columns={columnHeaders}
                        />
                        <Button
                           flat
                           primary
                           swapTheming
                           onClick={(): void => {
                              downloadCsv(
                                 createCsv([...selectedQueryResultData]),
                                 selectedName
                              );
                           }}
                           onKeyUp={(keyPress): void => {
                              if (!isEnterKeyPress(keyPress)) return;
                              downloadCsv(
                                 createCsv([...selectedQueryResultData]),
                                 selectedName
                              );
                           }}
                        >
                           Export CSV
                        </Button>
                     </>
                  ) : (
                     <div className="md-cell--12">
                        <i>No Results</i>
                     </div>
                  )}
               </div>
            </div>
         </ExpansionPanel>
         <ConfirmationDialog
            id="create-modal"
            disabled={modalBtnEnable}
            onConfirm={(): void => {
               createQuery();
               setShowConfirmationDefault(false);
            }}
            onCancel={(): void => {
               setShowConfirmationDefault(false);
               setNewName('');
               setNewQuery('');
            }}
            visible={showConfirmationDefault}
            title="Create new query"
            confirmLabel="Create"
            cancelLabel="Cancel"
            height={770}
         >
            <>
               <p>Please enter the details for your new query.</p>
               <br />
               <TextField
                  floating
                  id="query-name"
                  label="Query name"
                  lineDirection="center"
                  placeholder="Query name"
                  className="md-cell md-cell--12"
                  value={newName}
                  onChange={(val): void => {
                     setNewName(val.toString());
                  }}
                  errorText={'Please enter a query name'}
                  error={isNullOrWhiteSpace(newName)}
               />
               <TextField
                  floating
                  id="query"
                  lineDirection="center"
                  placeholder="Enter new query"
                  className="md-cell md-cell--12"
                  value={newQuery}
                  onChange={(val): void => {
                     setNewQuery(val.toString());
                  }}
                  rows={20}
                  errorText={'Please enter a query'}
                  error={isNullOrWhiteSpace(newQuery)}
               />
            </>
         </ConfirmationDialog>
      </ExpansionList>
   );
};

export default SqlReporting;
