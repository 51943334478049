import React from 'react';
import { TabsContainer, Tabs, Tab } from 'react-md/lib/Tabs';
import { Redirect, generatePath, useHistory, useParams } from 'react-router';
import { matchPath } from 'react-router';
import { RoutedTab } from './RoutedTab';
import {
   combinePageTitles,
   buildDocumentTitle,
} from 'Util/Navigation/useDocumentTitle';
import { Link } from 'react-router-dom';
import { PgdbRoute } from 'Util/Navigation/PgdbRoute';

interface RoutedTabContainerProps {
   baseUrl: string;
   tabs: readonly RoutedTab[];
   panelClassName: string | undefined;
   pageTitle?: string | readonly string[];
}

const getTabIndex = (
   baseUrl: string,
   currentUrl: string,
   tabs: readonly RoutedTab[]
): number => {
   const tabIndex = tabs.findIndex((tab): boolean => {
      if (!tab.route) return false;

      const fullPath = baseUrl + tab.route;
      const match = matchPath(currentUrl, fullPath);
      return match !== null;
   });

   if (tabIndex >= 0) return tabIndex;

   // find default route, because nothing matched
   // defaults to first route if we couldn't find a matching default
   const defaultIndex = tabs.findIndex((tab): boolean => {
      const fullPath = baseUrl + tab.route;
      return baseUrl === fullPath;
   });
   return Math.max(defaultIndex, 0);
};

export const RoutedTabContainer = ({
   baseUrl,
   tabs,
   panelClassName,
   pageTitle,
}: Readonly<RoutedTabContainerProps>): JSX.Element => {
   const history = useHistory();
   const params = useParams();

   const tabIndex = getTabIndex(baseUrl, history.location.pathname, tabs);
   if (tabIndex < 0) {
      const baseRoute = generatePath(baseUrl, params);
      return <Redirect to={baseRoute} />;
   }
   const activeTab = tabs[tabIndex];
   const fullTabRoute = baseUrl + activeTab.route;
   const matches = matchPath(history.location.pathname, fullTabRoute);
   if (!matches) return <Redirect to={baseUrl} />;
   const matchParams = matches.params;
   const documentTitle = buildDocumentTitle(
      combinePageTitles(activeTab.pageTitle, pageTitle)
   );
   // must use the dom directly -- a limitation of the hook requiring use every render
   if (document.title !== documentTitle) document.title = documentTitle;

   const routedTabs = tabs.map(
      (tab): JSX.Element => {
         const fullTabRoute = baseUrl + tab.route;
         const resolvedRoute = generatePath(fullTabRoute, matchParams);

         return (
            <Tab
               key={fullTabRoute}
               label={tab.label}
               component={Link}
               to={resolvedRoute}
            >
               <PgdbRoute
                  exact={!!tab.exact}
                  path={fullTabRoute}
                  component={tab.component}
                  permissions={tab.permissions}
                  permissionRedirectPath={tab.permissionRedirectPath}
               />
            </Tab>
         );
      }
   );

   const onTabChange = (
      activeTabIndex: number,
      tabId: number | string,
      tabControlsId: number | string,
      tabChildren: React.ReactNode,
      event: Event
   ): void => {
      if (tabIndex === activeTabIndex) return;

      const activeTab = tabs[activeTabIndex];
      const fullTabRoute = baseUrl + activeTab.route;

      const documentTitle = buildDocumentTitle(
         combinePageTitles(activeTab.pageTitle, pageTitle)
      );
      // must use the dom directly -- a limitation of the hook requiring use every render
      document.title = documentTitle;

      const resolvedRoute = generatePath(fullTabRoute, params);
      const currentUrl = history.location.pathname;

      // only push to history stack if the event is NOT a click event.
      // this is because there is a <Link> component that will already perform the push.
      // any other event is OK to push to the history stack
      const isClickEvent = !!event && event.type === 'click';
      if (!isClickEvent && currentUrl !== resolvedRoute)
         history.push(resolvedRoute);
   };

   const panelClasses = `md-grid ${panelClassName || ''}`;
   return (
      <TabsContainer
         panelClassName={panelClasses}
         colored
         activeTabIndex={tabIndex}
         onTabChange={onTabChange}
      >
         <Tabs tabId="simple-tab">{routedTabs}</Tabs>
      </TabsContainer>
   );
};
