import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import ViewFeeTypes from './ViewFeeType';
import AddFeeType from './AddFeeType';

const feeTypesTabs: readonly RoutedTab[] = [
   {
      label: 'View',
      route: '',
      component: ViewFeeTypes,
      pageTitle: 'View',
      exact: true,
      permissions: 'SystemParameter.Read',
   },
   {
      label: 'Add',
      route: '/add',
      component: AddFeeType,
      pageTitle: 'Add',
      permissions: 'SystemParameter.Create',
   },
];

const FeeType = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={feeTypesTabs}
         baseUrl="/superuser/fee-type"
         panelClassName="feeType"
         pageTitle="Fee Type"
      />
   );
};

export default FeeType;
