import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment-timezone';
import { Column, SortingRule, CellInfo } from 'react-table';
import {
   SupervisionTypes,
   selectSuperviseeHistory,
} from 'State/Redux/SupervisionRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { Supervisee } from 'Models/Supervision/Data/Supervisee';
import { selectLicenceYears } from 'State/Redux/MetadataRedux';
import {
   LicenceTypes,
   selectCurrentActiveLicences,
} from 'State/Redux/LicenceRedux';
import { useLicenceSupervisions } from 'Util/Helpers/Metadata';
import { checkAcceptedSupervisionActive } from 'Util/Helpers/Supervision';
import SupervisionStatus from 'Models/Supervision/Data/SupervisionStatus';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import DialogContainer from 'react-md/lib/Dialogs';
import EditSupervisee from '../EditSupervision/EditSuperviseeModalBody';
import {
   END_IMMEDIATELY,
   EditSuperviseeModalMode,
   ACCEPT_SUPERVISEE,
   DECLINE_SUPERVISEE,
   MODAL_MODE_MAPPING,
} from '../EditSupervision/SupervisionModalMode';
import { CheckTimesIcon } from 'Components/Common/TableIcons/CheckTimesIcon';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

const DEFAULT_SORTING: SortingRule[] = [{ id: 'licenceYear', desc: true }];

const getApplcationLink = (
   contactId: number,
   applicationId: number
): string => {
   return `/contact/${contactId}/applications/${applicationId}/`;
};

const SuperviseeHistory = (): JSX.Element => {
   const [showModal, setShowModal] = useState(false);
   const [
      selectedSupervisee,
      setSelectedSupervisee,
   ] = useState<Supervisee | null>(null);
   const [modalMode, setModalMode] = useState<EditSuperviseeModalMode>(
      END_IMMEDIATELY
   );
   const supervisees = useSelector(selectSuperviseeHistory);
   const licenceYears = useSelector(selectLicenceYears);
   const supervisorLicences = useSelector(selectCurrentActiveLicences);
   const licenceSupervisions = useLicenceSupervisions();

   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   const isLoading = useSelector(
      selectIsLoading([
         SupervisionTypes.GET_SUPERVISEE_HISTORY_REQUEST,
         LicenceTypes.GET_LICENCE_HISTORY_BY_ID_REQUEST,
         SupervisionTypes.ACCEPT_SUPERVISION_REQUEST,
         SupervisionTypes.CHANGE_SUPERVISION_END_DATE_REQUEST,
         SupervisionTypes.DECLINE_SUPERVISION_REQUEST,
      ])
   );

   const SuperviseeAction = (cellInfo: CellInfo): JSX.Element => {
      // Supervision is accepted
      if (cellInfo.original.supervisionStatus === SupervisionStatus.Accepted) {
         // Supervision is current and issued
         if (
            cellInfo.original.licenceIssued &&
            cellInfo.original.supervisionEnds &&
            moment(cellInfo.original.supervisionEnds).isAfter(moment())
         ) {
            return (
               <div
                  className="supervisee-action"
                  onClick={(): void => {
                     setSelectedSupervisee(cellInfo.original);
                     setModalMode(END_IMMEDIATELY);
                     setShowModal(true);
                  }}
               >
                  End Supervision
               </div>
            );
         } else {
            if (cellInfo.original.applicationId) {
               return (
                  <Link
                     className="disable-link-styles supervisee-action"
                     to={getApplcationLink(
                        cellInfo.original.superviseeId,
                        cellInfo.original.applicationId
                     )}
                  >
                     Link to application
                  </Link>
               );
            } else {
               return <div>No Action Available</div>;
            }
         }
      }
      // Supervision is pending
      else {
         return (
            <>
               <div className="supervisee-action-wrapper">
                  <div
                     className="supervisee-action"
                     onClick={(): void => {
                        setSelectedSupervisee(cellInfo.original);
                        setModalMode(ACCEPT_SUPERVISEE);
                        setShowModal(true);
                     }}
                  >
                     Accept
                  </div>
                  <div
                     className="supervisee-action"
                     onClick={(): void => {
                        setSelectedSupervisee(cellInfo.original);
                        setModalMode(DECLINE_SUPERVISEE);
                        setShowModal(true);
                     }}
                  >
                     Decline
                  </div>
               </div>
            </>
         );
      }
   };

   const COLUMN_HEADERS: Column<Supervisee>[] = [
      {
         Header: 'Licence Type',
         accessor: 'licenceDescription',
         width: 270,
      },
      {
         Header: 'Supervisee',
         id: 'name',
         accessor: (tableModel: Supervisee): string => {
            return `${tableModel.licenceNumber} - ${tableModel.name}`;
         },
         width: 260,
      },
      {
         Header: 'Licence Year',
         id: 'licenceYearId',
         accessor: (tableModel: Supervisee): string => {
            const licenceYear = licenceYears.find(l => {
               return l.licenceYearId === tableModel.licenceYearId;
            });
            return licenceYear?.description || '-';
         },
      },
      {
         Header: 'Supervision Starts',
         accessor: 'supervisionStarts',
         Cell: (cellInfo): string => {
            const date = Moment(cellInfo.value);
            return date.isValid() ? date.format(NAMED_DATE_FORMAT) : '-';
         },
      },
      {
         Header: 'Supervision Ends',
         accessor: 'supervisionEnds',
         Cell: (cellInfo): string => {
            const date = Moment(cellInfo.value);
            return date.isValid() ? date.format(NAMED_DATE_FORMAT) : '-';
         },
      },
      {
         Header: 'Licence Issued',
         accessor: 'licenceIssued',
         Cell: CheckTimesIcon,
      },
      {
         Header: 'Period Valid',
         id: 'periodValid',
         accessor: (tableModel: Supervisee): boolean => {
            return tableModel.supervisionEnds
               ? moment(tableModel.supervisionEnds).isAfter(moment())
               : false;
         },
         Cell: CheckTimesIcon,
      },
      {
         Header: 'Supervision Status',
         accessor: 'supervisionStatus',
         Cell: (cell: CellInfo): JSX.Element => {
            // If the supervision is finished, only display status value
            if (
               cell.original.supervisionEnds &&
               moment().isAfter(moment(cell.original.supervisionEnds))
            ) {
               return <div>{cell.value}</div>;
            }

            const supervisionActive = checkAcceptedSupervisionActive(
               cell.original,
               supervisorLicences,
               licenceSupervisions
            );

            return (
               <div>
                  <div>{cell.value}</div>
                  {!supervisionActive && (
                     <div className="text-orange">
                        Supervisor needs to renew their licence
                     </div>
                  )}
               </div>
            );
         },
      },
      {
         Header: <FaIcon icon="ellipsis-h" />,
         id: 'edit',
         className: 'center-icon',
         accessor: FN_EMPTY_VOID,
         Cell: SuperviseeAction,
         sortable: false,
      },
   ];

   return (
      <>
         <DialogContainer
            id="edit-supervisee-dialog"
            focusOnMount={false}
            visible={showModal}
            className="edit-dialog"
            title={MODAL_MODE_MAPPING.get(modalMode)}
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <EditSupervisee
               updateShowModal={updateShowModal}
               selectedSupervisee={selectedSupervisee}
               modalMode={modalMode}
            />
         </DialogContainer>

         <div className="supervisee-table">
            <ClientSideTable
               data={supervisees}
               defaultPageSize={10}
               columns={COLUMN_HEADERS}
               loading={isLoading}
               defaultSorted={DEFAULT_SORTING}
            />
         </div>
      </>
   );
};

export default React.memo(SuperviseeHistory);
