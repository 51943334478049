import { put, call } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { BatchServiceType } from 'State/Services/BatchService';
import { BatchItemReadonly } from 'Models/BackOffice/BatchItem';
import { BatchActions } from 'State/Redux/BatchRedux';
import { BatchSearchQuery } from 'Models/BackOffice/BatchSearchQuery';

interface BatchParams {
   type: string;
   token: string;
   id: string;
   query: BatchSearchQuery;
}

export function* getBatchItems(
   batchService: Readonly<BatchServiceType>,
   action: Readonly<BatchParams>
): Generator {
   const { query } = action;
   const batchResponse = yield call(batchService.getBatchItems, query);
   const response = batchResponse as ApiResponse<BatchItemReadonly[]>;

   if (response.status && response.ok) {
      yield put(BatchActions.getBatchItemsSuccess(response.data));
   } else {
      yield put(
         BatchActions.getBatchItemsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getFinancialItems(
   batchService: Readonly<BatchServiceType>,
   action: Readonly<BatchParams>
): Generator {
   const { query } = action;
   const batchResponse = yield call(batchService.getFinancialItems, query);
   const response = batchResponse as ApiResponse<BatchItemReadonly[]>;

   if (response.status && response.ok) {
      yield put(BatchActions.getFinancialItemsSuccess(response.data));
   } else {
      yield put(
         BatchActions.getFinancialItemsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* downloadBatchItem(
   batchService: Readonly<BatchServiceType>,
   action: Readonly<BatchParams>
): Generator {
   const { id } = action;
   const batchResponse = yield call(batchService.downloadBatchItem, id);
   const response = batchResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(BatchActions.downloadBatchItemSuccess(response.data));
   } else {
      yield put(
         BatchActions.downloadBatchItemFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
