import React from 'react';
import './LicenceCard.scss';

interface LicenceStatusProps {
   iconName: string;
   status: string;
}

export const LicenceStatus = ({
   iconName,
   status,
}: Readonly<LicenceStatusProps>): JSX.Element => {
   return (
      <>
         <i className={'material-icons ' + iconName} aria-hidden="true">
            {iconName}
         </i>
         <br />
         <span>{status}</span>
      </>
   );
};
