import React, { useEffect, useState } from 'react';
import TextField from 'react-md/lib/TextFields';
import DateInput from 'Components/Common/DateInput/DateInput';
import SelectField from 'react-md/lib/SelectFields';
import FeeRequestDetailDisplay from 'Models/FeesPayment/FeeRequestDetailDisplay';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import {
   isNullOrWhiteSpace,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import { useFeeRequestStatusList } from 'Util/Helpers/Metadata';
import UserCard from 'Components/Common/UserCard/UserCard';
import { useDispatch } from 'react-redux';
import { ContactActions } from 'State/Redux/ContactRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface FeeRequestDetailsProps {
   model: FeeRequestDetailDisplay;
   updateModel: (model: FeeRequestDetailDisplay) => void;
   isInvalidRegistration: boolean;
   updateRegistrationValidation: (isInvalid: boolean) => void;
   editMode?: boolean;
}

const FeeRequestDetails = ({
   model,
   updateModel,
   isInvalidRegistration,
   updateRegistrationValidation,
   editMode,
}: FeeRequestDetailsProps): JSX.Element => {
   const feeRequestStatuses = useFeeRequestStatusList();
   const dispatch = useDispatch();
   const [isContactRequested, setIsContactRequested] = useState(false);

   useEffect(() => {
      if (!isContactRequested && model && model.contactId) {
         dispatch(
            ContactActions.getContactByIdRequest(model.contactId.toString())
         );
         setIsContactRequested(true);
      }
   }, [isContactRequested, dispatch, model]);

   return (
      <>
         {editMode && isContactRequested && model.contactId && (
            <div className="md-grid md-cell--12">
               <UserCard showContactDetails={false} />
            </div>
         )}
         <div className="md-grid md-cell--12 fee-request-detail-container">
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="fee-request-number"
                  label="Number"
                  lineDirection="center"
                  className="md-cell md-cell--12"
                  disabled
                  value={model.feeRequestNumber.trim()}
                  errorText="Invalid Fee Request Number"
               />
               <DateInput
                  id="fee-request-date"
                  label="Date"
                  className="md-cell md-cell--12"
                  disabled
                  value={model.feeRequestDate}
               />
               <TextField
                  floating
                  id="fee-request-amount"
                  label="Amount"
                  className="md-cell md-cell--12 last-cell"
                  onChange={FN_EMPTY_VOID}
                  disabled
                  value={formatCurrencyString(model.totalAmount)}
               />
            </div>
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="fee-request-registration"
                  label="Licence Number"
                  lineDirection="center"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     isInvalidRegistration
                  )}
                  value={
                     model.registrationNumber ? model.registrationNumber : ''
                  }
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        registrationNumber: isNullOrWhiteSpace(val)
                           ? undefined
                           : Number(val),
                     });
                     updateRegistrationValidation(false);
                  }}
                  disabled={model.contactId ? true : false}
                  required={true}
                  error={isInvalidRegistration}
                  errorText="Please enter the valid Licence Number."
               />
               <SelectField
                  id="fee-request-status"
                  label="Status"
                  lineDirection="center"
                  className="md-cell md-cell--12"
                  disabled
                  defaultValue={model.feeRequestStatusCode}
                  menuItems={feeRequestStatuses}
               />
               <TextField
                  floating
                  id="fee-request-amount"
                  label="Total Paid"
                  className="md-cell md-cell--12 last-cell"
                  onChange={FN_EMPTY_VOID}
                  disabled
                  value={formatCurrencyString(model.paidAmount)}
               />
            </div>
         </div>
      </>
   );
};

export default FeeRequestDetails;
