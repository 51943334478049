import React from 'react';
import { ConditionValueProps } from './ConditionValueProps';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ConditionCheckboxProps {
   onChange?: (val: boolean) => void;
}

export const ConditionCheckbox = ({
   conditionCode,
   description,
   isChecked,
   disabled,
   onChange,
}: Readonly<ConditionCheckboxProps & ConditionValueProps>): JSX.Element => {
   return (
      <Checkbox
         id={`app-${1}-${conditionCode}`}
         className="md-cell md-cell--12"
         label={description}
         inputClassName={getInputValidationClassName(!isChecked)}
         checked={isChecked}
         name={conditionCode}
         disabled={disabled}
         onChange={onChange}
      />
   );
};
