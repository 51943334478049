import React, { useState, MutableRefObject } from 'react';
import './PhotoEditor.scss';
import PhotoController from './PhotoController';
import Button from 'react-md/lib/Buttons';
import AvatarEditor from 'react-avatar-editor';
import * as PhotoConstants from 'Util/Constants/Photo';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface PhotoEditorProps {
   zoomVal: number;
   rotateVal: number;
   height?: number;
   width?: number;
   isPreviewOpen: false;
   imageUrl: string;
   cancelPhotoEditor: Function;
   photoEditorRef: MutableRefObject<AvatarEditor | null>;
}

const PhotoEditor = ({
   zoomVal,
   rotateVal,
   imageUrl,
   cancelPhotoEditor,
   photoEditorRef,
}: PhotoEditorProps): JSX.Element => {
   const [zoomValue, setZoomValue] = useState(zoomVal);
   const [rotateValue, setRotateValue] = useState(rotateVal);
   const [height] = useState(PhotoConstants.INITIAL_HEIGHT);
   const [width] = useState(PhotoConstants.INITIAL_WIDTH);

   const setEditorRef = (photoEditor: AvatarEditor): void => {
      photoEditorRef.current = photoEditor;
   };

   const zoom = (val: number): void => {
      if (
         (val < 0 && zoomValue <= PhotoConstants.ZOOM_MIN) ||
         (val > 0 && zoomValue >= PhotoConstants.ZOOM_MAX)
      ) {
         return;
      }
      setZoomValue(zoomValue + val);
   };

   const cancelPhoto = (): void => {
      cancelPhotoEditor();
   };

   const rotate = (val: number): void => {
      const rval = rotateValue + val;
      setRotateValue(rval);
   };

   const reset = (): void => {
      setRotateValue(0);
      setZoomValue(PhotoConstants.ZOOM_MIN);
   };

   return (
      <div className="container photo-editor-container md-cell--6">
         <div className="row photo-editor">
            <div className="col-3 avatar-frame">
               <AvatarEditor
                  image={imageUrl}
                  width={width}
                  height={height}
                  color={PhotoConstants.BACK_COLOR}
                  scale={zoomValue}
                  rotate={rotateValue}
                  border={50}
                  ref={setEditorRef}
               ></AvatarEditor>
            </div>
            <div className="avatar-control-frame">
               <PhotoController
                  onZoom={zoom}
                  onRotate={rotate}
                  onReset={reset}
                  zoomIncrement={PhotoConstants.ZOOM_INCREMENT}
                  rotateIncrement={PhotoConstants.ROTATE_INCREMENT}
               />
            </div>
         </div>
         <div className="row photo-footer">
            <Button
               flat
               primary
               swapTheming
               onClick={cancelPhoto}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) cancelPhoto();
               }}
            >
               Cancel
            </Button>
         </div>
      </div>
   );
};

export default PhotoEditor;
