import React from 'react';
import Button from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import './GenericModalBody.scss';

interface Props {
   onConfirm: () => void;
   onCancel: () => void;
   textModal: string;
}

export const GenericModalBody = ({
   onConfirm,
   onCancel,
   textModal,
}: Readonly<Props>): JSX.Element => {
   return (
      <div className="md-grid md-cell--12">
         <div className="md-grid md-cell--12 grey-background cancel-application-modal">
            <div className="md-grid md-cell--12">{textModal}</div>
            <div className="md-grid md-cell--12">
               <Button
                  className="red-btn btn-spacing"
                  onClick={onCancel}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) onCancel();
                  }}
                  flat
                  swapTheming
                  secondary
               >
                  No
               </Button>
               <Button
                  className="btn-spacing"
                  onClick={onConfirm}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) onConfirm();
                  }}
                  flat
                  swapTheming
                  primary
               >
                  Yes
               </Button>
            </div>
         </div>
      </div>
   );
};
