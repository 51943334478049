import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'react-md/lib/TextFields';
import { Button } from 'react-md/lib/Buttons';
import { SelectField } from 'react-md/lib/SelectFields';
import { toast } from 'react-toastify';
import Registration, {
   DefaultRegistration,
} from 'Models/Registration/Registration';
import {
   selectContact,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import '../RegistrationTab.scss';
import {
   DefaultRegistrationInputValidation,
   RegistrationInputValidationReadonly,
} from 'Models/Validator/RegistrationInputValidationModel';
import {
   isNullOrWhiteSpace,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import { REGISTRATION_STATUS_ACTIVE } from 'Util/Constants/RegistrationStatus';
import {
   selectIsLoading,
   selectIsError,
   selectErrorMessage,
} from 'State/Redux/AsyncRedux';
import Loading from 'Components/Common/Loading';
import {
   useRegistrationStatusList,
   useRegistrationTypeList,
} from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import { getCurrentDate } from 'Util/Helpers/Date';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface RegistrationDetailsProps {
   registrationModel: Registration;
   editMode: boolean;
   closeDialog: () => void;
   updateDialogTitle: (title: string) => void;
}

const RegistrationDetailsPanel = ({
   registrationModel,
   closeDialog,
   updateDialogTitle,
   editMode,
}: Readonly<RegistrationDetailsProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SAVE_REGISTRATION_REQUEST])
   );
   const isError = useSelector(
      selectIsError([ContactTypes.SAVE_REGISTRATION_REQUEST])
   );
   const errorMessage = useSelector(
      selectErrorMessage(ContactTypes.SAVE_REGISTRATION_REQUEST)
   );
   const dispatch = useDispatch();

   const [editModel, setEditModel] = useState(registrationModel);
   const [updatedField, setUpdatedField] = useState<
      keyof Registration | undefined | null
   >(null);
   const [validationModel, setValidationModel] = useState(
      DefaultRegistrationInputValidation
   );

   const [saveSubmitted, setSaveSubmitted] = useState(false);
   const [saveAndNew, setSaveAndNew] = useState(false);

   const registrationStatuses = useRegistrationStatusList();
   const registrationTypes = useRegistrationTypeList();

   const updateEditModel = (
      newState: Registration,
      field: keyof Registration | undefined | null
   ): void => {
      setUpdatedField(field);
      setEditModel(newState);
   };

   const SaveNewButton = (): JSX.Element => {
      if (!editMode) {
         return (
            <Button
               flat
               primary
               swapTheming
               onClick={(): void => validateAndSave(true)}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) validateAndSave(true);
               }}
            >
               Save + New
            </Button>
         );
      }
      return <></>;
   };

   const validateFormFields = useCallback((): boolean => {
      let validity: RegistrationInputValidationReadonly = validationModel;

      if (
         isNullOrWhiteSpace(updatedField) ||
         updatedField === 'registrationTypeCode'
      ) {
         validity = {
            ...validity,
            registrationTypeCode: !isNullOrWhiteSpace(
               editModel.registrationTypeCode
            ),
         };
      }

      if (
         isNullOrWhiteSpace(updatedField) ||
         updatedField === 'registrationStatusCode'
      ) {
         validity = {
            ...validity,
            registrationStatusCode: !isNullOrWhiteSpace(
               editModel.registrationStatusCode
            ),
         };
      }

      if (
         isNullOrWhiteSpace(updatedField) ||
         updatedField === 'registeredDate'
      ) {
         validity = {
            ...validity,
            registeredDate: !(
               editModel.registeredDate === null ||
               editModel.registeredDate === undefined
            ),
         };
      }

      setValidationModel(validity);
      setUpdatedField(null);
      return Object.values(validity).every((v): boolean => !!v);
   }, [updatedField, editModel, validationModel]);

   useEffect((): void => {
      if (updatedField && editModel) {
         validateFormFields();
      }
   }, [updatedField, editModel, validateFormFields]);

   const validateAndSave = (saveAndNew: boolean): void => {
      if (!validateFormFields()) {
         toast.error('Validation Failed: Please enter all required fields');
         return;
      }

      const registration: Registration = {
         ...editModel,
         contactId: contact.contactId,
      };
      setSaveSubmitted(true);
      setSaveAndNew(saveAndNew);
      dispatch(ContactActions.saveRegistrationRequest(registration));
   };

   useEffect((): void => {
      if (saveSubmitted && !isLoading && !isError) {
         toast.success('Registration saved.');
         if (saveAndNew) {
            updateDialogTitle('Add New Registration');
            setEditModel({
               ...DefaultRegistration,
               registeredDate: getCurrentDate(),
               registrationStatusCode: REGISTRATION_STATUS_ACTIVE,
            });
         } else {
            closeDialog();
         }

         setSaveSubmitted(false);
      }

      if (saveSubmitted && !isLoading && isError) {
         if (!isNullOrWhiteSpace(errorMessage)) {
            toast.error(errorMessage);
         } else {
            toast.error('Failed to save registration.');
         }
         setSaveSubmitted(false);
      }
   }, [
      saveSubmitted,
      isLoading,
      isError,
      saveAndNew,
      closeDialog,
      updateDialogTitle,
      errorMessage,
   ]);

   return (
      <Loading
         tryReload={FN_EMPTY_VOID}
         isLoading={isLoading}
         isError={false}
         overlayOnChildren
      >
         <>
            <br />
            <SelectField
               id="select-registration-type"
               label="Registration Type"
               placeholder="Select Registration Type"
               className="md-cell md-cell--6"
               inputClassName={getInputValidationClassName(
                  !validationModel.registrationTypeCode
               )}
               menuItems={registrationTypes}
               position={SelectField.Positions.BELOW}
               onChange={(val): void => {
                  updateEditModel(
                     {
                        ...editModel,
                        registrationTypeCode: val.toString(),
                     },
                     'registrationTypeCode'
                  );
               }}
               value={editModel.registrationTypeCode}
               required
               errorText="Please select the registration type"
               error={!validationModel.registrationTypeCode}
            />
            <SelectField
               id="select-registration-status"
               label="Status"
               placeholder="Select Status"
               className="md-cell md-cell--6"
               inputClassName={getInputValidationClassName(
                  !validationModel.registrationStatusCode
               )}
               menuItems={registrationStatuses}
               position={SelectField.Positions.BELOW}
               onChange={(val): void => {
                  updateEditModel(
                     {
                        ...editModel,
                        registrationStatusCode: val.toString(),
                     },
                     'registrationStatusCode'
                  );
               }}
               value={
                  isNullOrWhiteSpace(editModel.registrationStatusCode)
                     ? REGISTRATION_STATUS_ACTIVE
                     : editModel.registrationStatusCode
               }
               required
               errorText="Please select the status"
               error={!validationModel.registrationStatusCode}
            />
            <DateInput
               id="datepicker-registration-date"
               label="Registration Date"
               className="md-cell md-cell--6"
               inputClassName={getInputValidationClassName(
                  !validationModel.registeredDate
               )}
               onChange={(date): void => {
                  updateEditModel(
                     {
                        ...editModel,
                        registeredDate: date,
                     },
                     'registeredDate'
                  );
               }}
               required
               error={!validationModel.registeredDate}
               errorText="Please enter the registration date"
               value={editModel.registeredDate}
            />
            <TextField
               id="textfield-conditions"
               label="Condition(s) to Registration"
               className="md-cell md-cell--12"
               type="text"
               onChange={(val): void => {
                  updateEditModel(
                     {
                        ...editModel,
                        conditions: val.toString(),
                     },
                     null
                  );
               }}
               value={editModel.conditions}
               rows={5}
               block
               maxRows={5}
            />
            <div className="md-cell md-cell--12 control-panel">
               <SaveNewButton />
               <Button
                  flat
                  primary
                  swapTheming
                  onClick={(): void => validateAndSave(false)}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) validateAndSave(false);
                  }}
               >
                  Save
               </Button>
               <Button
                  flat
                  secondary
                  swapTheming
                  className="red-btn"
                  onClick={closeDialog}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) closeDialog();
                  }}
               >
                  Cancel
               </Button>
            </div>
         </>
      </Loading>
   );
};

export default RegistrationDetailsPanel;
