import { createSelectList, SelectItem } from 'Util/Helpers/Input';

export type CountryCode =
   | 'AFG'
   | 'ALB'
   | 'DZA'
   | 'ASM'
   | 'AND'
   | 'AGO'
   | 'AIA'
   | 'ATA'
   | 'ATG'
   | 'ARG'
   | 'ARM'
   | 'ABW'
   | 'AUS'
   | 'AUT'
   | 'AZE'
   | 'BHS'
   | 'BHR'
   | 'BGD'
   | 'BRB'
   | 'BLR'
   | 'BEL'
   | 'BLZ'
   | 'BEN'
   | 'BMU'
   | 'BTN'
   | 'BOL'
   | 'BIH'
   | 'BWA'
   | 'BVT'
   | 'BRA'
   | 'IOT'
   | 'VGB'
   | 'BRN'
   | 'BGR'
   | 'BFA'
   | 'BDI'
   | 'KHM'
   | 'CMR'
   | 'CAN'
   | 'CPV'
   | 'CYM'
   | 'CAF'
   | 'TCD'
   | 'CHL'
   | 'CHN'
   | 'CXR'
   | 'CCK'
   | 'COL'
   | 'COM'
   | 'COD'
   | 'COK'
   | 'CRI'
   | 'CIV'
   | 'CUB'
   | 'CYP'
   | 'CZE'
   | 'DNK'
   | 'DJI'
   | 'DMA'
   | 'DOM'
   | 'ECU'
   | 'EGY'
   | 'SLV'
   | 'GNQ'
   | 'ERI'
   | 'EST'
   | 'ETH'
   | 'FLK'
   | 'FRO'
   | 'FJI'
   | 'FIN'
   | 'FRA'
   | 'GUF'
   | 'PYF'
   | 'ATF'
   | 'GAB'
   | 'GMB'
   | 'GEO'
   | 'DEU'
   | 'GHA'
   | 'GIB'
   | 'GRC'
   | 'GRL'
   | 'GRD'
   | 'GLP'
   | 'GUM'
   | 'GTM'
   | 'GIN'
   | 'GNB'
   | 'GUY'
   | 'HTI'
   | 'HMD'
   | 'VAT'
   | 'HND'
   | 'HKG'
   | 'HRV'
   | 'HUN'
   | 'ISL'
   | 'IND'
   | 'IDN'
   | 'IRN'
   | 'IRQ'
   | 'IRL'
   | 'ISR'
   | 'ITA'
   | 'JAM'
   | 'JPN'
   | 'JOR'
   | 'KAZ'
   | 'KEN'
   | 'KIR'
   | 'KOR'
   | 'KWT'
   | 'KGZ'
   | 'LAO'
   | 'LVA'
   | 'LBN'
   | 'LSO'
   | 'LBR'
   | 'LBY'
   | 'LIE'
   | 'LTU'
   | 'LUX'
   | 'MAC'
   | 'MKD'
   | 'MDG'
   | 'MWI'
   | 'MYS'
   | 'MDV'
   | 'MLI'
   | 'MLT'
   | 'MHL'
   | 'MTQ'
   | 'MRT'
   | 'MUS'
   | 'MYT'
   | 'MEX'
   | 'FSM'
   | 'MDA'
   | 'MCO'
   | 'MNG'
   | 'MSR'
   | 'MAR'
   | 'MOZ'
   | 'MMR'
   | 'NAM'
   | 'NRU'
   | 'NPL'
   | 'NLD'
   | 'ANT'
   | 'NCL'
   | 'NZL'
   | 'NIC'
   | 'NER'
   | 'NGA'
   | 'NIU'
   | 'NFK'
   | 'MNP'
   | 'NOR'
   | 'OMN'
   | 'PAK'
   | 'PLW'
   | 'PSE'
   | 'PAN'
   | 'PNG'
   | 'PRY'
   | 'PER'
   | 'PHL'
   | 'PCN'
   | 'POL'
   | 'PRT'
   | 'PRI'
   | 'QAT'
   | 'ROU'
   | 'RUS'
   | 'RWA'
   | 'WSM'
   | 'SMR'
   | 'STP'
   | 'SAU'
   | 'SEN'
   | 'SCG'
   | 'SYC'
   | 'SLE'
   | 'SGP'
   | 'SVK'
   | 'SVN'
   | 'SLB'
   | 'SOM'
   | 'ZAF'
   | 'SGS'
   | 'ESP'
   | 'LKA'
   | 'SHN'
   | 'KNA'
   | 'LCA'
   | 'SPM'
   | 'VCT'
   | 'SDN'
   | 'SUR'
   | 'SJM'
   | 'SWZ'
   | 'SWE'
   | 'CHE'
   | 'SYR'
   | 'TWN'
   | 'TJK'
   | 'TZA'
   | 'THA'
   | 'TLS'
   | 'TGO'
   | 'TKL'
   | 'TON'
   | 'TTO'
   | 'TUN'
   | 'TUR'
   | 'TKM'
   | 'TCA'
   | 'TUV'
   | 'UGA'
   | 'UKR'
   | 'ARE'
   | 'GBR'
   | 'UMI'
   | 'USA'
   | 'URY'
   | 'VIR'
   | 'UZB'
   | 'VUT'
   | 'VEN'
   | 'VNM'
   | 'WLF'
   | 'ESH'
   | 'YEM'
   | 'ZMB'
   | 'ZWE';

export const COUNTRY_CODES: ReadonlyMap<string, string> = new Map<
   CountryCode,
   string
>([
   ['AFG', 'Afghanistan'],
   ['ALB', 'Albania'],
   ['DZA', 'Algeria'],
   ['ASM', 'American Samoa'],
   ['AND', 'Andorra'],
   ['AGO', 'Angola'],
   ['AIA', 'Anguilla'],
   ['ATA', 'Antarctica (the territory South of 60 deg S)'],
   ['ATG', 'Antigua and Barbuda'],
   ['ARG', 'Argentina'],
   ['ARM', 'Armenia'],
   ['ABW', 'Aruba'],
   ['AUS', 'Australia'],
   ['AUT', 'Austria'],
   ['AZE', 'Azerbaijan'],
   ['BHS', 'The Bahamas'],
   ['BHR', 'Bahrain'],
   ['BGD', 'Bangladesh'],
   ['BRB', 'Barbados'],
   ['BLR', 'Belarus'],
   ['BEL', 'Belgium'],
   ['BLZ', 'Belize'],
   ['BEN', 'Benin'],
   ['BMU', 'Bermuda'],
   ['BTN', 'Bhutan'],
   ['BOL', 'Bolivia'],
   ['BIH', 'Bosnia and Herzegovina'],
   ['BWA', 'Botswana'],
   ['BVT', 'Bouvet Island (Bouvetoya)'],
   ['BRA', 'Brazil'],
   ['IOT', 'British Indian Ocean Territory (Chagos Archipelago)'],
   ['VGB', 'British Virgin Islands'],
   ['BRN', 'Brunei'],
   ['BGR', 'Bulgaria'],
   ['BFA', 'Burkina Faso'],
   ['BDI', 'Burundi'],
   ['KHM', 'Cambodia'],
   ['CMR', 'Cameroon'],
   ['CAN', 'Canada'],
   ['CPV', 'Cape Verde'],
   ['CYM', 'Cayman Islands'],
   ['CAF', 'Central African Republic'],
   ['TCD', 'Chad'],
   ['CHL', 'Chile'],
   ['CHN', 'China'],
   ['CXR', 'Christmas Island'],
   ['CCK', 'Cocos(Keeling) Islands'],
   ['COL', 'Colombia'],
   ['COM', 'Comoros'],
   ['COD', 'Congo'],
   ['COK', 'Cook Islands'],
   ['CRI', 'Costa Rica'],
   ['CIV', "Cote d'Ivoire"],
   ['CUB', 'Cuba'],
   ['CYP', 'Cyprus'],
   ['CZE', 'Czech Republic'],
   ['DNK', 'Denmark'],
   ['DJI', 'Djibouti'],
   ['DMA', 'Dominica'],
   ['DOM', 'Dominican Republic'],
   ['ECU', 'Ecuador'],
   ['EGY', 'Egypt'],
   ['SLV', 'El Salvador'],
   ['GNQ', 'Equatorial Guinea'],
   ['ERI', 'Eritrea'],
   ['EST', 'Estonia'],
   ['ETH', 'Ethiopia'],
   ['FLK', 'Falkland Islands'],
   ['FRO', 'Faroe Islands'],
   ['FJI', 'Fiji'],
   ['FIN', 'Finland'],
   ['FRA', 'France'],
   ['GUF', 'French Guiana'],
   ['PYF', 'French Polynesia'],
   ['ATF', 'French Southern Territories'],
   ['GAB', 'Gabon'],
   ['GMB', 'The Gambia'],
   ['GEO', 'Georgia'],
   ['DEU', 'Germany'],
   ['GHA', 'Ghana'],
   ['GIB', 'Gibraltar'],
   ['GRC', 'Greece'],
   ['GRL', 'Greenland'],
   ['GRD', 'Grenada'],
   ['GLP', 'Guadeloupe'],
   ['GUM', 'Guam'],
   ['GTM', 'Guatemala'],
   ['GIN', 'Guinea'],
   ['GNB', 'Guinea , Bissau'],
   ['GUY', 'Guyana'],
   ['HTI', 'Haiti'],
   ['HMD', 'Heard and McDonald Islands'],
   ['VAT', 'Holy See (Vatican City State)'],
   ['HND', 'Honduras'],
   ['HKG', 'Hong Kong'],
   ['HRV', 'Hrvatska (Croatia)'],
   ['HUN', 'Hungary'],
   ['ISL', 'Iceland'],
   ['IND', 'India'],
   ['IDN', 'Indonesia'],
   ['IRN', 'Iran'],
   ['IRQ', 'Iraq'],
   ['IRL', 'Ireland'],
   ['ISR', 'Israel'],
   ['ITA', 'Italy'],
   ['JAM', 'Jamaica'],
   ['JPN', 'Japan'],
   ['JOR', 'Jordan'],
   ['KAZ', 'Kazakhstan'],
   ['KEN', 'Kenya'],
   ['KIR', 'Kiribati'],
   ['KOR', 'Korea'],
   ['KWT', 'Kuwait'],
   ['KGZ', 'Kyrgyzstan'],
   ['LAO', 'Laos'],
   ['LVA', 'Latvia'],
   ['LBN', 'Lebanon'],
   ['LSO', 'Lesotho'],
   ['LBR', 'Liberia'],
   ['LBY', 'Libyan Arab Jamahiriya'],
   ['LIE', 'Liechtenstein'],
   ['LTU', 'Lithuania'],
   ['LUX', 'Luxembourg'],
   ['MAC', 'Macau'],
   ['MKD', 'Macedonia'],
   ['MDG', 'Madagascar'],
   ['MWI', 'Malawi'],
   ['MYS', 'Malaysia'],
   ['MDV', 'Maldives'],
   ['MLI', 'Mali'],
   ['MLT', 'Malta'],
   ['MHL', 'Marshall Islands'],
   ['MTQ', 'Martinique'],
   ['MRT', 'Mauritania'],
   ['MUS', 'Mauritius'],
   ['MYT', 'Mayotte'],
   ['MEX', 'Mexico'],
   ['FSM', 'Micronesia'],
   ['MDA', 'Moldova'],
   ['MCO', 'Monaco'],
   ['MNG', 'Mongolia'],
   ['MSR', 'Montserrat'],
   ['MAR', 'Morocco'],
   ['MOZ', 'Mozambique'],
   ['MMR', 'Myanmar'],
   ['NAM', 'Namibia'],
   ['NRU', 'Nauru'],
   ['NPL', 'Nepal'],
   ['NLD', 'Netherlands'],
   ['ANT', 'Netherlands Antilles'],
   ['NCL', 'New Caledonia'],
   ['NZL', 'New Zealand'],
   ['NIC', 'Nicaragua'],
   ['NER', 'Niger'],
   ['NGA', 'Nigeria'],
   ['NIU', 'Niue'],
   ['NFK', 'Norfolk Island'],
   ['MNP', 'Northern Mariana'],
   ['NOR', 'Norway'],
   ['OMN', 'Oman'],
   ['PAK', 'Pakistan'],
   ['PLW', 'Palau'],
   ['PSE', 'Palestine'],
   ['PAN', 'Panama'],
   ['PNG', 'Papua New Guinea'],
   ['PRY', 'Paraguay'],
   ['PER', 'Peru'],
   ['PHL', 'Philippines'],
   ['PCN', 'Pitcairn Islands'],
   ['POL', 'Poland'],
   ['PRT', 'Portugal'],
   ['PRI', 'Puerto Rico'],
   ['QAT', 'Qatar'],
   ['ROU', 'Romania'],
   ['RUS', 'Russia'],
   ['RWA', 'Rwanda'],
   ['WSM', 'Samoa'],
   ['SMR', 'San Marino'],
   ['STP', 'Sao Tome and Principe'],
   ['SAU', 'Saudi Arabia'],
   ['SEN', 'Senegal'],
   ['SCG', 'Serbia'],
   ['SYC', 'Seychelles'],
   ['SLE', 'Sierra Leone'],
   ['SGP', 'Singapore'],
   ['SVK', 'Slovakia'],
   ['SVN', 'Slovenia'],
   ['SLB', 'Solomon Islands'],
   ['SOM', 'Somalia'],
   ['ZAF', 'South Africa'],
   ['SGS', 'South Georgia and the South Sandwich Islands'],
   ['ESP', 'Spain'],
   ['LKA', 'Sri Lanka'],
   ['SHN', 'St. Helena'],
   ['KNA', 'St. Kitts and Nevis'],
   ['LCA', 'St. Lucia'],
   ['SPM', 'St. Pierre and Miquelon'],
   ['VCT', 'St. Vincent and the Grenadines'],
   ['SDN', 'Sudan'],
   ['SUR', 'Suriname'],
   ['SJM', 'Svalbard'],
   ['SWZ', 'Swaziland'],
   ['SWE', 'Sweden'],
   ['CHE', 'Switzerland'],
   ['SYR', 'Syria'],
   ['TWN', 'Taiwan'],
   ['TJK', 'Tajikistan'],
   ['TZA', 'Tanzania'],
   ['THA', 'Thailand'],
   ['TLS', 'Timor , Leste'],
   ['TGO', 'Togo'],
   ['TKL', 'Tokelau'],
   ['TON', 'Tonga'],
   ['TTO', 'Trinidad and Tobago'],
   ['TUN', 'Tunisia'],
   ['TUR', 'Turkey'],
   ['TKM', 'Turkmenistan'],
   ['TCA', 'Turks and Caicos Islands'],
   ['TUV', 'Tuvalu'],
   ['UGA', 'Uganda'],
   ['UKR', 'Ukraine'],
   ['ARE', 'United Arab Emirates'],
   ['GBR', 'United Kingdom'],
   ['UMI', 'United States Minor Outlying Islands'],
   ['USA', 'United States of America'],
   ['URY', 'Uruguay'],
   ['VIR', 'US Virgin Islands'],
   ['UZB', 'Uzbekistan'],
   ['VUT', 'Vanuatu'],
   ['VEN', 'Venezuela'],
   ['VNM', 'Vietnam'],
   ['WLF', 'Wallis and Futuna'],
   ['ESH', 'Western Sahara'],
   ['YEM', 'Yemen'],
   ['ZMB', 'Zambia'],
   ['ZWE', 'Zimbabwe'],
]);

export const DEFAULT_COUNTRY_CODE = 'NZL';
export const DEFAULT_COUNTRY_NAME =
   COUNTRY_CODES.get(DEFAULT_COUNTRY_CODE) || 'New Zealand';

export const getCountryName = (countryCode: string): string => {
   if (!countryCode) countryCode = DEFAULT_COUNTRY_CODE;
   return COUNTRY_CODES.get(countryCode) || DEFAULT_COUNTRY_NAME;
};

export const getDropdownList = (): SelectItem[] => {
   return createSelectList(
      Array.from(COUNTRY_CODES.keys()),
      (cc): string => cc,
      (cc): string => COUNTRY_CODES.get(cc) || 'Unknown'
   );
};
