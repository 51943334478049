import React, {
   forwardRef,
   useCallback,
   useEffect,
   useImperativeHandle,
} from 'react';
import {
   ContactEditModel,
   DefaultContactEditModel,
} from 'Models/Contact/Data/ContactEditModel';
import {
   selectContactScreenData,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import { toast } from 'react-toastify';
import {
   PersonalSection,
   PublicRegisterSection,
} from '../../SubMenu/CreateContact/Views';
import { validateModel } from 'Components/Common/ModelValidator/Helper';
import { CONTACT_VALIDATION_MODEL } from '../../SubMenu/CreateContact/CreateContact';
import { useDispatch, useSelector } from 'react-redux';
import {
   getBusinessAddressLine,
   getPhysicalAddressLine,
   getMailingAddressLine,
   isValidPhysicalAddress,
} from 'Util/Helpers/Address';
import { selectErrorMessage, selectIsError } from 'State/Redux/AsyncRedux';
import {
   ERROR_POBOX,
   ERROR_REGISTRATION_EXIST,
} from '../../SubMenu/CreateContact';
import ValidationModel from 'Components/Common/ModelValidator/ValidationModel';
import { isEqual } from 'lodash-es';

interface Props {
   contactId: number;
   isEditing: boolean;
}

export type ContactValidationFields = Pick<
   ContactEditModel,
   | 'registrationNumber'
   | 'firstName'
   | 'lastName'
   | 'emailAddress'
   | 'contactStatusCode'
>;

export type ReferenceApplicantForm = {
   valid: () => string;
   submit: () => void;
};

const ContactDetailsSection = forwardRef<ReferenceApplicantForm, Props>(
   ({ contactId, isEditing }, ref) => {
      const screenData = useSelector(selectContactScreenData);

      const [inputModel, setInputModel] = React.useState(
         DefaultContactEditModel
      );
      const [validationModel, setValidationModel] = React.useState(
         CONTACT_VALIDATION_MODEL
      );

      const dispatch = useDispatch();

      const isError = useSelector(
         selectIsError([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
      );

      const saveErrorMessage = useSelector(
         selectErrorMessage(ContactTypes.SAVE_CONTACT_DATA_REQUEST)
      );

      let validationResult = undefined;
      useImperativeHandle(ref, () => {
         return {
            valid: (): string => {
               validationResult = validateModel<ContactValidationFields>(
                  inputModel,
                  validationModel
               );
               return validationResult.errorMessage;
            },
            submit: (): void => {
               if (!validateAddress()) {
                  return;
               }
               const newInputModel = { ...inputModel };
               setInputModel(newInputModel);
               if (!newInputModel.genderId) {
                  newInputModel.genderId = newInputModel.gender?.id;
               }
               if (!newInputModel.ethnicityId) {
                  newInputModel.ethnicityId = newInputModel.ethnicity?.id;
               }

               dispatch(ContactActions.saveContactDataRequest(newInputModel));
            },
         };
      });

      const validateAddress = (): boolean => {
         // physical address validation:
         if (!isValidPhysicalAddress(inputModel.physicalAddressLine1)) {
            toast.error(ERROR_POBOX);
            return false;
         }
         return true;
      };

      const tryFetchData = useCallback((): void => {
         if (contactId) {
            dispatch(ContactActions.getContactScreenDataRequest(contactId));
         }
      }, [dispatch, contactId]);

      useEffect((): (() => void) => {
         tryFetchData();
         return (): void => {
            dispatch(ContactActions.getContactScreenDataReset());
         };
      }, [dispatch, tryFetchData]);

      const isResetting = !isEditing && !isEqual(screenData, inputModel);

      useEffect(() => {
         if (screenData) {
            const newContact = {
               ...screenData,
               // set full address
               mailingAddressFull: getMailingAddressLine(screenData),
               physicalAddressFull: getPhysicalAddressLine(screenData),
               businessAddressFull: getBusinessAddressLine(screenData),
               contactId: screenData.contactId,
            };
            setInputModel(newContact);
         }
      }, [screenData, isResetting, dispatch]);

      useEffect((): void => {
         if (isError && saveErrorMessage) {
            const errorMessage = saveErrorMessage;
            toast.error(errorMessage);
            if (
               errorMessage &&
               errorMessage.includes(ERROR_REGISTRATION_EXIST)
            ) {
               const newValidationModel: ValidationModel<ContactValidationFields> = {
                  ...validationModel,
                  fields: {
                     ...validationModel.fields,
                     registrationNumber: {
                        validation: 'number',
                        error: true,
                        errorMessage: errorMessage as string,
                     },
                  },
               };

               // force registration number to be invalid
               setValidationModel(newValidationModel);
            }
         }
      }, [saveErrorMessage, validationModel, isError]);

      return screenData ? (
         <>
            <PersonalSection
               model={inputModel}
               updateModel={setInputModel}
               validationResult={validationResult}
               companyProfile
               hideCheckbok
               disabled={!isEditing}
            />
            <PublicRegisterSection
               model={inputModel}
               updateModel={setInputModel}
               validationResult={validationResult}
               hidePublicRegister
               hideCheckboxesPublicRegister
               disabled={!isEditing}
            />
         </>
      ) : null;
   }
);

export default ContactDetailsSection;
