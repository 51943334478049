import React, { useEffect } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { Button } from 'react-md/lib/Buttons';
import { SelectField } from 'react-md/lib/SelectFields';
import {
   PaymentSearchQuery,
   DefaultSearchModel,
   validateSearchQuery,
   isValidSearchQuery,
   filterToValidQuery,
} from 'Models/Contact/Dto/PaymentSearchQuery';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import Contact from 'Models/Contact/Data/Contact';
import './SearchPanel.scss';
import { useFeeRequestStatusList } from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface SearchPanelProps {
   query: PaymentSearchQuery;
   contact: Contact;
   setQuery: (query: PaymentSearchQuery) => void;
   setIsSearching: (isSearching: boolean) => void;
   reset: () => void;
   isSearching: boolean;
}

const SearchPanel = ({
   contact,
   query,
   setQuery,
   setIsSearching,
   reset,
   isSearching,
}: Readonly<SearchPanelProps>): JSX.Element => {
   const feeRequestStatuses = useFeeRequestStatusList(
      'Select Fee Request Status'
   );

   const validation = validateSearchQuery(query);
   const filteredQuery = filterToValidQuery(query);
   const canSearch = isValidSearchQuery(filteredQuery);

   useEffect((): void => {
      setQuery({
         ...DefaultSearchModel,
         registrationNumber: contact.registrationNumber.toString(),
      });
   }, [contact, setQuery]);

   return (
      <div className="md-grid md-cell--12 invoice-search-panel">
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Fee Request Number"
               lineDirection="center"
               placeholder="Fee Request Number"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.feeRequestNumber
               )}
               type="search"
               value={query.feeRequestNumber}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     feeRequestNumber: value.toString(),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.feeRequestNumber}
               errorText="Invalid fee request number"
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               className="md-cell md-cell--12"
               disabled
               inputClassName={getInputValidationClassName(
                  !validation.registrationNumber
               )}
               type="search"
               value={query.registrationNumber}
               error={!validation.registrationNumber}
               errorText="Invalid Licence Number"
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="payment-end-date"
               label="Start Date"
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     startDate: value.toString(),
                  });
               }}
               value={query.startDate}
               className="md-cell md-cell--12"
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="payment-start-date"
               label="End Date"
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     endDate: value.toString(),
                  });
               }}
               value={query.endDate}
               className="md-cell md-cell--12"
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               id="fee-request-status-select-field"
               label="Fee Request Status"
               placeholder="Select Status"
               className="md-cell md-cell--12"
               menuItems={feeRequestStatuses}
               value={query.feeRequestStatus}
               position={SelectField.Positions.BELOW}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     feeRequestStatus: value.toString(),
                  });
               }}
            />
         </div>
         <div className="md-cell md-cell--6">
            <Button
               flat
               disabled={isSearching}
               secondary
               className="md-cell md-cell--4 red-btn search-button"
               swapTheming
               onClick={(): void => {
                  setIsSearching(false);
                  setQuery({
                     ...DefaultSearchModel,
                     registrationNumber: contact.registrationNumber.toString(),
                  });
                  reset();
               }}
               onKeyUp={(event): void => {
                  if (!isEnterKeyPress(event)) return;

                  setIsSearching(false);
                  setQuery({
                     ...DefaultSearchModel,
                     registrationNumber: contact.registrationNumber.toString(),
                  });
                  reset();
               }}
            >
               Reset
            </Button>
            <Button
               flat
               primary
               swapTheming
               disabled={!canSearch || isSearching}
               className="md-cell md-cell--4 search-button btn-right"
               onClick={(): void => {
                  setIsSearching(true);
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
            >
               Search
            </Button>
         </div>
      </div>
   );
};

export default SearchPanel;
