import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import ReceiptRequestReadOnly from 'Models/Payment/Data/ReceiptRequest';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ReceiptState {
   receipt: Blob;
}

export type ReceiptStateReadonly = Readonly<ReceiptState>;

interface TypeNames {
   GET_RECEIPT_REQUEST: string;
   GET_RECEIPT_SUCCESS: string;
   GET_RECEIPT_FAILURE: string;

   GET_RECEIPT_BY_PAYMENT_ID_REQUEST: string;
   GET_RECEIPT_BY_PAYMENT_ID_SUCCESS: string;
   GET_RECEIPT_BY_PAYMENT_ID_FAILURE: string;
}

type ReceiptTypeNames = Readonly<TypeNames>;

export interface ReceiptCreators {
   getReceiptRequest: (request: ReceiptRequestReadOnly) => AnyAction;
   getReceiptSuccess: (data: Blob | undefined) => AnyAction;
   getReceiptFailure: (error: ApiError) => AnyAction;

   getReceiptByPaymentIdRequest: (paymentId: number) => AnyAction;
   getReceiptByPaymentIdSuccess: (data: Blob | undefined) => AnyAction;
   getReceiptByPaymentIdFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ReceiptStateReadonly = {
   receipt: new Blob(),
};

/* ------------- Reducers ------------- */
const getReceiptSuccess = (
   state: ReceiptStateReadonly,
   action: AnyAction
): ReceiptStateReadonly => {
   return { ...state, receipt: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ReceiptTypeNames,
   ReceiptCreators
>('receipt', {
   getReceiptRequest: ['request'],
   getReceiptSuccess: ['data'],
   getReceiptFailure: ['error'],

   getReceiptByPaymentIdRequest: ['paymentId'],
   getReceiptByPaymentIdSuccess: ['data'],
   getReceiptByPaymentIdFailure: ['error'],
});

export const ReceiptTypes = Types;
export const ReceiptActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_RECEIPT_SUCCESS]: getReceiptSuccess,
   [Types.GET_RECEIPT_BY_PAYMENT_ID_SUCCESS]: getReceiptSuccess,
});

/* ------------- Selectors ------------- */
export const selectReceipt = (state: StateReadonly): Blob =>
   state.receipt.receipt;
