import {
   isValidEndDateAfterStartDate,
   isValidDate,
} from 'Util/Helpers/Validation';
import { getCurrentDate } from 'Util/Helpers/Date';

interface BoardMeetingDateSearchQueryModel {
   startDate: string;
   endDate: string;
}

export type BoardMeetingDateSearchQuery = Readonly<
   BoardMeetingDateSearchQueryModel
>;

export const DefaultBoardMeetingDateSearchQuery: BoardMeetingDateSearchQuery = {
   startDate: '',
   endDate: getCurrentDate(),
};

interface BoardMeetingDateSearchQueryValidationModel {
   startDate: boolean;
   endDate: boolean;
}

export type BoardMeetingDateSearchQueryValidation = Readonly<
   BoardMeetingDateSearchQueryValidationModel
>;

export const DefaultBoardMeetingDateSearchQueryValidation: BoardMeetingDateSearchQueryValidation = {
   startDate: false,
   endDate: false,
};

export const validateSearch = (
   query: BoardMeetingDateSearchQuery
): BoardMeetingDateSearchQueryValidation => {
   return {
      startDate: isValidDate(query.startDate), // don't trust the datepicker
      endDate:
         isValidDate(query.endDate) &&
         isValidEndDateAfterStartDate(query.startDate, query.endDate),
   };
};

export const filterToValidQuery = (
   query: BoardMeetingDateSearchQuery
): BoardMeetingDateSearchQuery => {
   return {
      startDate: query.startDate, // trust the datepicker
      endDate: query.endDate,
   };
};

export const isValidQuery = (query: BoardMeetingDateSearchQuery): boolean => {
   return !!query.startDate || !!query.endDate;
};
