interface WebMessageDto {
   webMessageCode: string;
   description: string;
   value: string;
   availableParameters: string[];
   canManuallyTrigger: boolean;
}

type WebMessageDtoReadonly = Readonly<WebMessageDto>;

export const isValidItem = (
   webMessage: WebMessageDtoReadonly | null
): boolean => {
   if (!webMessage) return false;

   let validParams = true;

   let result: RegExpExecArray | null;
   const params: string[] = [];
   const regex = /{{(.*?)}}/g;

   while ((result = regex.exec(webMessage.value.toString()))) {
      const found = result[1].toString().trim();
      params.push(found); // Adds it to array
   }

   if (params) {
      params.map(item => {
         if (
            webMessage.availableParameters &&
            !webMessage.availableParameters.includes(item)
         ) {
            validParams = false;
         }
         return item;
      });
   }

   return webMessage.value.trim().length > 0 && validParams;
};

export default WebMessageDtoReadonly;
