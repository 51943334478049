import React, { useEffect, useState } from 'react';
import './InvoicesPaymentsPanel.scss';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import {
   selectContact,
   selectContactPaymentHistory,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import { useSelector, useDispatch } from 'react-redux';
import { SortingRule, Column, CellInfo } from 'react-table';
import moment from 'moment-timezone';
import { formatCurrencyString } from 'Util/Helpers/Currency';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import LoadingButton from 'Components/Common/LoadingButton';
import ReceiptRequestReadOnly from 'Models/Payment/Data/ReceiptRequest';
import {
   ReceiptActions,
   selectReceipt,
   ReceiptTypes,
} from 'State/Redux/ReceiptRedux';
import FileSaver from 'file-saver';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';

const CANCELLED_PAYMENT_STATUS = 'CN';

interface PaymentDisplayModel {
   feeRequestNumber: string;
   feeRequestStatus: string;
   totalAmount: string;
   feeRequestDate: string;
   paidAmount: string;
   amountUnpaid: string;
   licencingYear: string;
   paymentId: number | null;
   paymentStatusCode: string;
   paymentStatusDescription: string;
}

const generatePaymentResults = (
   payments: readonly PaymentSearchResult[]
): PaymentDisplayModel[] => {
   if (!payments || !payments.length) {
      return [];
   }

   return payments.map(
      (payment): PaymentDisplayModel => {
         const totalAmount = formatCurrencyString(payment.totalAmount);
         const paidAmount = formatCurrencyString(payment.paidAmount);
         const amountUnpaid = formatCurrencyString(payment.amountUnpaid);
         const requestStatus = payment.requestStatusDescription
            ? payment.requestStatusDescription.trim()
            : '';

         return {
            feeRequestNumber: payment.feeRequestNumber,
            feeRequestStatus: requestStatus,
            totalAmount: totalAmount,
            feeRequestDate: payment.feeRequestDate,
            paidAmount: paidAmount,
            amountUnpaid: amountUnpaid,
            licencingYear: payment.licencingYear,
            paymentId: payment.paymentId,
            paymentStatusCode: payment.paymentStatusCode,
            paymentStatusDescription: payment.paymentStatusDescription,
         };
      }
   );
};

const InvoicesPaymentsPanel = (): JSX.Element => {
   const [selectedPaymentId, setSelectedPaymentId] = useState(0);
   const contact = useSelector(selectContact);
   const payments = useSelector(selectContactPaymentHistory);
   const receipt = useSelector(selectReceipt);
   const isDownloading = useSelector(
      selectIsLoading([ReceiptTypes.GET_RECEIPT_REQUEST])
   );
   const isDownloaded = useSelector(
      selectIsSuccess([ReceiptTypes.GET_RECEIPT_REQUEST])
   );
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.GET_CONTACT_PAYMENTS_HISTORY_REQUEST])
   );
   const dispatch = useDispatch();

   const DateRenderer = (cell: CellInfo): JSX.Element => {
      return (
         <div>
            {cell.value ? moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
         </div>
      );
   };

   const DownloadRenderer = (cell: CellInfo): JSX.Element => {
      if (!cell.value) return <div>Unable to download</div>;

      if (cell.original.paymentStatusCode === CANCELLED_PAYMENT_STATUS)
         return <></>;

      return (
         <LoadingButton
            isLoading={isDownloading && selectedPaymentId === cell.value}
            disabled={isDownloading && selectedPaymentId !== cell.value}
            flat
            primary
            className="download-receipt-button"
            onClick={(): void => {
               setSelectedPaymentId(cell.value);

               getReceipt({
                  paymentId: cell.value,
                  contactId: contact.contactId,
               });
            }}
            onKeyUp={(keyPress): void => {
               if (!isEnterKeyPress(keyPress)) return;
               setSelectedPaymentId(cell.value);
               getReceipt({
                  paymentId: cell.value,
                  contactId: contact.contactId,
               });
            }}
         >
            Download
         </LoadingButton>
      );
   };

   const getReceipt = (request: ReceiptRequestReadOnly): void => {
      dispatch(ReceiptActions.getReceiptRequest(request));
   };

   const COLUMN_HEADERS: Column<PaymentDisplayModel>[] = [
      {
         Header: 'Request Number',
         accessor: 'feeRequestNumber',
      },
      {
         Header: 'Request Status',
         accessor: 'feeRequestStatus',
      },
      {
         Header: 'Payment Status',
         accessor: 'paymentStatusDescription',
      },
      {
         Header: 'Total Fee Amount',
         accessor: 'totalAmount',
      },
      {
         Header: 'Payment Date',
         accessor: 'feeRequestDate',
         Cell: DateRenderer,
      },
      {
         Header: 'Paid Amount',
         accessor: 'paidAmount',
      },
      {
         Header: 'Amount Unpaid',
         accessor: 'amountUnpaid',
      },
      {
         Header: 'Licence Year',
         accessor: 'licencingYear',
      },
      {
         Header: '',
         accessor: 'paymentId',
         Cell: DownloadRenderer,
      },
   ];

   const DEFAULT_SORTING: SortingRule[] = [{ id: 'licencingYear', desc: true }];

   useEffect((): void => {
      if (isDownloaded) {
         const blob = new Blob([receipt], {
            type: 'application/pdf',
         });

         FileSaver.saveAs(blob, 'Receipt.pdf');
      }
   }, [isDownloaded, receipt]);

   return (
      <ClientSideTable
         data={generatePaymentResults(payments)}
         defaultPageSize={10}
         columns={COLUMN_HEADERS}
         defaultSorted={DEFAULT_SORTING}
         loading={isLoading}
      />
   );
};

export default InvoicesPaymentsPanel;
