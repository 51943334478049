import React, { useEffect, useState } from 'react';
import { ConditionValueProps } from './ConditionValueProps';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from 'Util/Helpers/Permissions';
import '../../../ContactOverview/Panels/FitAndProper/FitAndProperPanel.scss';
import {
   FitAndProperActions,
   selectFitAndProper,
} from 'State/Redux/FitAndProperRedux';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';
import './ConditionF2p.scss';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';

interface ConditionF2pProps {
   userId: string;
   disabled?: boolean;
   setFitAndProper?: (fitAndProper: QuestionSaveBulkDto[]) => void;
   fitAndProper?: QuestionSaveBulkDto[];
   application: ApplicationEdit;
}

const OPTION_YES = 'Yes';
const OPTION_NO = 'No';

export const ConditionF2p = ({
   conditionCode,
   description,
   isChecked,
   application,
   userId,
   setFitAndProper,
   fitAndProper,
   disabled,
}: Readonly<ConditionF2pProps & ConditionValueProps>): JSX.Element => {
   const [initialLoad, setInitialLoad] = useState(true);

   const hasReadPermission = usePermissions(['F2P.Answer.Read']);
   const hasWritePermission = usePermissions(['F2P.Answer.Create']);

   const fitAndProperAnswers = useSelector(selectFitAndProper);

   const dispatch = useDispatch();

   const canEdit = hasWritePermission && !disabled;

   const loadFitAndProper = (): void => {
      dispatch(FitAndProperActions.getQuestionsAndAnswersByIdRequest(userId));
   };

   useEffect(loadFitAndProper, [userId, dispatch]);

   const loadData = (): void => {
      if (
         initialLoad &&
         fitAndProperAnswers &&
         fitAndProperAnswers.length > 0
      ) {
         const newVaules: QuestionSaveBulkDto[] = [];
         // eslint-disable-next-line array-callback-return
         fitAndProperAnswers.map((f): void => {
            newVaules.push({ id: f.id, latestAnswer: f.latestAnswer });
         });
         if (setFitAndProper) {
            setFitAndProper(newVaules);
         }
      }
   };

   useEffect(loadData, [fitAndProperAnswers, setFitAndProper, initialLoad]);

   useEffect((): void => {
      if (disabled) {
         dispatch(FitAndProperActions.getQuestionsAndAnswersByIdReset());
         dispatch(
            FitAndProperActions.getQuestionsAndAnswersByIdRequest(userId)
         );
         setInitialLoad(true);
      }
   }, [disabled, dispatch, userId]);

   const onValueChange = (val: string, id: string): void => {
      const currentVal = val === OPTION_YES ? true : false;
      if (fitAndProper) {
         const selectedVal = fitAndProper.find(f => f.id === Number(id));
         if (selectedVal) {
            selectedVal.latestAnswer = currentVal;
         }
         if (setFitAndProper) {
            setFitAndProper(fitAndProper);
         }
      }
      setInitialLoad(false);
   };

   const renderRadioOption = (
      id: string,
      groupName: string,
      option: string,
      defaultValue?: string
   ): JSX.Element => {
      const isChecked =
         initialLoad && defaultValue && defaultValue === option
            ? {
                 checked: true,
              }
            : undefined;

      return (
         <label className="bold black-text">
            <span className="radio-option-label">{option}</span>
            <input
               id={id}
               radioGroup={groupName}
               className="with-gap"
               name={groupName}
               type="radio"
               onChange={(e): void => onValueChange(option, groupName)}
               disabled={!canEdit}
               {...isChecked}
            />
            <span />
         </label>
      );
   };

   return hasReadPermission ? (
      <>
         {fitAndProperAnswers ? (
            <div className="fit-and-proper-inline">
               <div className="title">Fit & Proper Questions</div>
               {fitAndProperAnswers.map(a => {
                  const defaultVal =
                     !a || a.latestAnswer === null
                        ? ''
                        : a.latestAnswer === true
                        ? OPTION_YES
                        : OPTION_NO;

                  return (
                     <div className="section" key={a.id}>
                        <div className="col-3 question-section">
                           {a.questionText}
                        </div>
                        <div className="col-2 option-section">
                           {renderRadioOption(
                              OPTION_YES,
                              a.id.toString(),
                              OPTION_YES,
                              defaultVal
                           )}
                           {renderRadioOption(
                              OPTION_NO,
                              a.id.toString(),
                              OPTION_NO,
                              defaultVal
                           )}
                        </div>
                     </div>
                  );
               })}
            </div>
         ) : (
            <></>
         )}
      </>
   ) : (
      <></>
   );
};
