import React, { useState, useEffect, Fragment } from 'react';
import Card, { CardTitle, CardText } from 'react-md/lib/Cards';
import TextField from 'react-md/lib/TextFields';
import ContactExamEligibilityDto, {
   DefaultContactExamEligibilityDtoModel,
} from 'Models/Examination/Dto/ContactExamEligibilityDto';
import CourseCriteriaDto from 'Models/Examination/Dto/CourseCriteriaDto';
import { Cell } from 'react-md/lib/Grids';
import SelectionControl from 'react-md/lib/SelectionControls';
import Button from 'react-md/lib/Buttons';
import FaIcon from '../FaIcon/FaIcon';
import { useSelector, useDispatch } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import {
   ExaminationActions,
   selectOrderedAndGroupedExaminationEligibility,
} from 'State/Redux/ExaminationRedux';
import ContactExamEligibilityOtherNotesDtoReadonly, {
   DefaultContactExamEligibilityOtherNotesDtoModel,
} from 'Models/Examination/Data/ContactExamEligibilityOtherNotesDto';
import {
   CRITERIA_OTHER,
   CRITERIA_NOT_ELIGIBLE,
   CRITERIA_NUMBERS,
   NOT_ELIGIBLE_DISPLAY_ORDER,
   COMPLETED_NATION_CERTIFICATE_DISPLAY_ORDER,
   CERTIFYING_SECTION_52_EXEMPTION_APPROVED,
   CERTIFYING_PLUMBER_COURSE_NUMBER_STRING,
   CERTIFYING_GASFITTER_COURSE_NUMBER_STRING,
   CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING,
} from 'Util/Constants/Examination';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface ExamEligibilityCardProps {
   className: string;
   title: string;
   courseNumber: CRITERIA_NUMBERS;
   courseCriteria: readonly CourseCriteriaDto[];
   otherNotes: readonly ContactExamEligibilityOtherNotesDtoReadonly[] | null;
}

const ExamEligibilityCard = ({
   className,
   title,
   courseNumber,
   courseCriteria,
   otherNotes,
}: Readonly<ExamEligibilityCardProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const examinationEligibility = useSelector(
      selectOrderedAndGroupedExaminationEligibility
   );
   const dispatch = useDispatch();

   const [otherNote, setOtherNote] = useState<
      ContactExamEligibilityOtherNotesDtoReadonly
   >();
   const [notEligible, setNotEligible] = useState<boolean>(false);
   const [examinationEligibilityList, setExaminationEligibilityList] = useState<
      ContactExamEligibilityDto[]
   >();
   const [otherEligibility, setOtherEligibility] = useState<
      ContactExamEligibilityDto
   >();

   useEffect((): void => {
      if (!examinationEligibility || !examinationEligibility[courseNumber])
         return;
      setExaminationEligibilityList(examinationEligibility[courseNumber]);
      setOtherEligibility(
         examinationEligibility[courseNumber].find(
            (e): boolean => e.courseCriteria.displayText === CRITERIA_OTHER
         )
      );
   }, [examinationEligibility, courseNumber]);

   useEffect((): void => {
      if (
         otherNotes &&
         examinationEligibilityList &&
         otherEligibility &&
         otherEligibility.isCriteriaMet
      ) {
         const note = otherNotes.find(
            (note): boolean =>
               note.contactExamEligibilityId ===
               otherEligibility.contactExamEligibilityId
         );

         note
            ? setOtherNote(note)
            : setOtherNote({
                 ...DefaultContactExamEligibilityOtherNotesDtoModel,
                 contactExamEligibilityId:
                    otherEligibility.contactExamEligibilityId,
                 contactId: contact.contactId,
                 courseCriteriaId: otherEligibility.courseCriteriaId,
              });
      }
   }, [
      examinationEligibilityList,
      otherNotes,
      otherEligibility,
      contact.contactId,
   ]);

   useEffect((): void => {
      if (examinationEligibilityList) {
         const notEligibility = examinationEligibilityList.find(
            (e): boolean =>
               e.courseCriteria.displayText === CRITERIA_NOT_ELIGIBLE
         );
         setNotEligible(!!notEligibility && notEligibility.isCriteriaMet);
      }
   }, [examinationEligibilityList]);

   const createContactExamEligibility = (): void => {
      const contactExamEligibilityList: ContactExamEligibilityDto[] = [];
      courseCriteria.forEach((criteria): void => {
         const isCertifying = [
            CERTIFYING_PLUMBER_COURSE_NUMBER_STRING,
            CERTIFYING_GASFITTER_COURSE_NUMBER_STRING,
            CERTIFYING_DRAINLAYER_COURSE_NUMBER_STRING,
         ].some(e => e === courseNumber);

         contactExamEligibilityList.push({
            ...DefaultContactExamEligibilityDtoModel,
            contactId: contact.contactId,
            courseCriteria: criteria,
            courseCriteriaId: criteria.courseCriteriaId,
            isCriteriaMet: !isCertifying
               ? criteria.displayOrder ===
                 COMPLETED_NATION_CERTIFICATE_DISPLAY_ORDER
               : criteria.displayOrder ===
                 CERTIFYING_SECTION_52_EXEMPTION_APPROVED,
         });
      });

      dispatch(
         ExaminationActions.updateContactExaminationEligibilityRequest(
            contact.contactId.toString(),
            contactExamEligibilityList
         )
      );
   };

   if (notEligible || !examinationEligibilityList) {
      return (
         <Cell size={4}>
            <Button
               className="not-eligible"
               onClick={(): void => createContactExamEligibility()}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) createContactExamEligibility();
               }}
            >
               <Card className="not-eligible-card">
                  <CardTitle title={title} />
                  <CardText>
                     <FaIcon icon="times" className="not-eligible-icon" />
                     <h3>Not Eligible</h3>
                     Make Eligible
                  </CardText>
               </Card>
            </Button>
         </Cell>
      );
   }

   const generateCheckbox = (examinationNumber: string): JSX.Element => {
      while (examinationEligibilityList.length < courseCriteria.length) {
         //there is a default course criteria in the system that has not been associated with
         //this user due to possible bad data manipulation in the db.
         //find and create the default course criteria using the Examination Eligibility
         //properties for the contact.
         const missingCriteria = courseCriteria.find(
            (cc): boolean =>
               !examinationEligibilityList.some(
                  (ee): boolean => ee.courseCriteriaId === cc.courseCriteriaId
               )
         );

         if (missingCriteria === undefined) {
            break;
         }
         examinationEligibilityList.push({
            ...DefaultContactExamEligibilityDtoModel,
            contactId: contact.contactId,
            courseCriteria: missingCriteria,
            courseCriteriaId: missingCriteria.courseCriteriaId,
         });
      }

      return (
         <>
            {examinationEligibilityList.map(
               (examinationEligibility): JSX.Element => {
                  const key = `${examinationEligibility.courseCriteria.courseCriteriaId}-${examinationNumber}-${examinationEligibility.contactExamEligibilityId}`;
                  if (
                     examinationEligibility.courseCriteria.displayOrder ===
                     NOT_ELIGIBLE_DISPLAY_ORDER
                  )
                     return <Fragment key={key}></Fragment>;
                  return (
                     <div key={key}>
                        <SelectionControl
                           id={`${examinationNumber}-${examinationEligibility.contactExamEligibilityId}`}
                           key={`${examinationNumber}-${examinationEligibility.contactExamEligibilityId}`}
                           name="criteria-selection"
                           type="checkbox"
                           defaultChecked={examinationEligibility.isCriteriaMet}
                           label={
                              examinationEligibility.courseCriteria.displayText
                           }
                           checked={examinationEligibility.isCriteriaMet}
                           disabled={
                              examinationEligibility.courseCriteria
                                 .isSystemControlled === true
                           }
                           onChange={(val): void => {
                              dispatch(
                                 ExaminationActions.updateContactExaminationEligibilityState(
                                    {
                                       ...examinationEligibility,
                                       isCriteriaMet: val as boolean,
                                    }
                                 )
                              );
                           }}
                        >
                           {examinationEligibility.courseCriteria.displayText}
                        </SelectionControl>
                        {examinationEligibility.courseCriteria.displayText ===
                           CRITERIA_OTHER &&
                           otherEligibility &&
                           otherEligibility.isCriteriaMet && (
                              <TextField
                                 required
                                 id="other-text-field"
                                 className="other-text-field"
                                 inputClassName={getInputValidationClassName(
                                    examinationEligibility.isError
                                 )}
                                 placeholder="Other Description"
                                 error={examinationEligibility.isError}
                                 onBlur={(val): void => {
                                    dispatch(
                                       ExaminationActions.updateContactExaminationEligibilityOtherNoteState(
                                          {
                                             ...(otherNote as ContactExamEligibilityOtherNotesDtoReadonly),
                                             otherNotes: (val.target as HTMLInputElement)
                                                .value,
                                          }
                                       )
                                    );
                                 }}
                                 defaultValue={
                                    otherNote && otherNote.otherNotes
                                       ? otherNote.otherNotes
                                       : ''
                                 }
                              />
                           )}
                     </div>
                  );
               }
            )}
         </>
      );
   };

   return (
      <>
         {examinationEligibilityList && (
            <Cell size={4}>
               <Card className={className}>
                  <CardTitle title={title} />
                  <CardText>{generateCheckbox(courseNumber)}</CardText>
               </Card>
            </Cell>
         )}
      </>
   );
};

export default ExamEligibilityCard;
