import React, { useState } from 'react';
import { Button } from 'react-md/lib/Buttons';

import { isEnterKeyPress } from 'Util/Helpers/Input';
import DateInput from 'Components/Common/DateInput/DateInput';
import {
   BoardMeetingDateSearchQuery,
   DefaultBoardMeetingDateSearchQuery,
} from 'Models/BoardMeetingDate/BoardMeetingDateSearchQuery';
import LoadingButton from 'Components/Common/LoadingButton';

interface SearchPanelProps {
   searchBoardMeetingDates: (query: BoardMeetingDateSearchQuery) => void;
   isSearching: boolean;
   resetSearch: () => void;
}

const SearchPanel = ({
   searchBoardMeetingDates,
   isSearching,
   resetSearch,
}: Readonly<SearchPanelProps>): JSX.Element => {
   const [searchQuery, setSearchQuery] = useState(
      DefaultBoardMeetingDateSearchQuery
   );

   const searchOnKeyPress = (
      keyPress: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (isEnterKeyPress(keyPress)) searchBoardMeetingDates(searchQuery);
   };

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <DateInput
               id="payment-start-date"
               label="Start Date"
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     startDate: value.toString(),
                  });
               }}
               value={searchQuery.startDate}
               className="md-cell md-cell--12"
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="payment-start-date"
               label="End Date"
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     endDate: value.toString(),
                  });
               }}
               value={searchQuery.endDate}
               className="md-cell md-cell--12"
            />
         </div>
         <div className="md-cell md-cell--12 search-buttons">
            <LoadingButton
               isLoading={isSearching}
               flat
               primary
               swapTheming
               onClick={(): void => searchBoardMeetingDates(searchQuery)}
               onKeyUp={searchOnKeyPress}
               className="search"
            >
               Search
            </LoadingButton>
            <Button
               flat
               secondary
               className="red-btn reset"
               swapTheming
               onClick={(): void => {
                  setSearchQuery(DefaultBoardMeetingDateSearchQuery);
                  resetSearch();
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) {
                     setSearchQuery(DefaultBoardMeetingDateSearchQuery);
                     resetSearch();
                  }
               }}
            >
               Reset
            </Button>
         </div>
      </div>
   );
};

export default SearchPanel;
