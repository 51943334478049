import React from 'react';
import FinancialExtract from './Finance/FinanceExtract';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import ExportsTab from './Export/ExportsTab';
import { usePermissions } from 'Util/Helpers/Permissions';

const BASE_URL = '/financial-extract';

const FINANCE_TAB: RoutedTab = {
   label: 'Finance',
   route: '',
   component: FinancialExtract,
   pageTitle: 'Search',
   permissions: 'BackOfficeFinance.Read',
   exact: true,
};

const EXPORT_TAB: RoutedTab = {
   label: 'Export',
   route: '/exports',
   component: ExportsTab,
   pageTitle: 'Export',
   permissions: 'BackOfficeExport.Read',
   exact: true,
};

const BackOfficeMain = (): JSX.Element => {
   const hasFinancePermission = usePermissions('BackOfficeFinance.Read');
   const hasExportPermission = usePermissions('BackOfficeExport.Create');
   const tabs: RoutedTab[] = [];

   // Load FinancialExtract screen if the user only has access to BackOfficeFinance
   if (hasFinancePermission) {
      if (!hasExportPermission) {
         return <FinancialExtract />;
      } else {
         tabs.push(FINANCE_TAB);
      }
   }

   // Load ExportsTab screen if the user only has access to BackOfficeExport
   if (hasExportPermission) {
      if (!hasFinancePermission) {
         return <ExportsTab />;
      } else {
         tabs.push(EXPORT_TAB);
      }
   }

   return (
      <RoutedTabContainer
         tabs={tabs}
         baseUrl={BASE_URL}
         panelClassName="financial-extract"
         pageTitle="Financial Extracts"
      />
   );
};

export default BackOfficeMain;
