import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import { BatchItemReadonly } from 'Models/BackOffice/BatchItem';
import { BatchSearchQuery } from 'Models/BackOffice/BatchSearchQuery';

export interface BatchServiceType {
   getBatchItems(
      query: BatchSearchQuery
   ): Promise<ApiResponse<BatchItemReadonly[]>>;
   getFinancialItems(
      query: BatchSearchQuery
   ): Promise<ApiResponse<BatchItemReadonly[]>>;
   downloadBatchItem(id: string): Promise<ApiResponse<Blob>>;
}

const create = (baseURL: string | undefined): BatchServiceType => {
   const api = createApi({ baseURL });
   const blobApi = createApi({ baseURL: baseURL, responseType: 'blob' });

   const getBatchItems = (
      query: BatchSearchQuery
   ): Promise<ApiResponse<BatchItemReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Batch`, query);
   };

   const getFinancialItems = (
      query: BatchSearchQuery
   ): Promise<ApiResponse<BatchItemReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Batch/GetFinancialFiles`, query);
   };

   const downloadBatchItem = (id: string): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.get(`/Batch/Download/${id}`);
   };

   return {
      getBatchItems,
      downloadBatchItem,
      getFinancialItems,
   };
};

export default {
   create,
};
