import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import SystemParametersReadonly, {
   SystemParameters,
} from 'Models/SystemParameters/Data/SystemParameters';

export interface SystemParametersServiceType {
   updateSystemParameters(
      boardMeetingDate: SystemParametersReadonly
   ): Promise<ApiResponse<readonly SystemParametersReadonly[]>>;
   getSystemParameters(): Promise<ApiResponse<readonly SystemParameters[]>>;
   addSystemParameters(
      systemParameter: SystemParametersReadonly
   ): Promise<ApiResponse<SystemParametersReadonly>>;
}

const create = (baseURL: string | undefined): SystemParametersServiceType => {
   const api = createApi({ baseURL });

   const updateSystemParameters = (
      systemParameters: SystemParametersReadonly
   ): Promise<ApiResponse<readonly SystemParametersReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/SystemParameter`, systemParameters);
   };

   const getSystemParameters = (): Promise<ApiResponse<
      readonly SystemParameters[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/SystemParameter`);
   };

   const addSystemParameters = (
      systemParameter: SystemParametersReadonly
   ): Promise<ApiResponse<SystemParametersReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/SystemParameter`, systemParameter);
   };

   return {
      updateSystemParameters,
      getSystemParameters,
      addSystemParameters,
   };
};

export default {
   create,
};
