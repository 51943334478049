import React from 'react';
import Button from 'react-md/lib/Buttons';
import { usePermissions } from 'Util/Helpers/Permissions';
import { Permission } from 'Util/Constants/Permission';
import './style.scss';
import { useHistory } from 'react-router';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface AuthorizedProps {
   permissions?: Permission | readonly Permission[];
   children: JSX.Element;
   showWarning?: boolean;
}

export const Authorized = ({
   permissions,
   children,
   showWarning,
}: Readonly<AuthorizedProps>): JSX.Element => {
   const history = useHistory();
   const hasPermissions = usePermissions(permissions);

   if (hasPermissions) return children;
   if (!showWarning) return <></>;

   return (
      <div className="permission-guard-main">
         <div className="permission-guard-content">
            <div className="permission-guard-oops-text">
               Nothing to see here&hellip;
            </div>
            <Button
               flat
               primary
               swapTheming
               onClick={(): void => history.push('/')}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) history.push('/');
               }}
            >
               #takemeback
            </Button>
         </div>
      </div>
   );
};
