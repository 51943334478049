import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import UserCard from 'Components/Common/UserCard/UserCard';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectCurrentLicenceYear,
   selectLicenceYears,
   selectCanEditYear,
} from 'State/Redux/MetadataRedux';
import { useApplicationTypeList } from 'Util/Helpers/Metadata';
import Autocomplete from 'react-md/lib/Autocompletes';
import SelectField from 'react-md/lib/SelectFields';
import Button from 'react-md/lib/Buttons';
import {
   isNullOrWhiteSpace,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import { Link, useParams, Redirect } from 'react-router-dom';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   selectIsLoading,
   selectIsSuccess,
   selectIsError,
} from 'State/Redux/AsyncRedux';
import {
   ApplicationTypes,
   ApplicationActions,
   selectApplicationForOverview,
   selectCreateApplicationError,
   selectApplicationTypes,
} from 'State/Redux/ApplicationRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { toast } from 'react-toastify';
import { RegistrationApplicationTypes } from 'Util/Constants/ApplicationTypes';
import DialogContainer from 'react-md/lib/Dialogs';
import { CreateLicenceModalBody } from './CreateLicenceModalBody';
import Loading from 'Components/Common/Loading';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface ContactParams {
   contactId?: string;
}

interface ApplicationCreateModel {
   contactId: number;
   applicationTypeCode: string;
   licenceYearId: number;
   createLicence: boolean | null;
}

interface ApplicationCreateValidation {
   contactId: boolean;
   applicationTypeCode: boolean;
   licenceYearId: boolean;
}

const validateModel = (
   model: Readonly<ApplicationCreateModel>
): ApplicationCreateValidation => {
   return {
      contactId: !!model.contactId,
      applicationTypeCode: !isNullOrWhiteSpace(model.applicationTypeCode),
      licenceYearId: !!model.licenceYearId,
   };
};

const ApplicationCreatePanel = (): JSX.Element => {
   const [hasApplicationType, setHasApplicationType] = useState(false);
   const [hasRequestedCreate, setHasRequestedCreate] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const currentLicenceYear = useSelector(selectCurrentLicenceYear);
   const licenceYears = useSelector(selectLicenceYears);
   const licenceYearDescriptions = licenceYears.map(
      (ly): string => ly.description
   );
   const isLoading = useSelector(
      selectIsLoading([ApplicationTypes.CREATE_APPLICATION_REQUEST])
   );
   const hasCreated = useSelector(
      selectIsSuccess([ApplicationTypes.CREATE_APPLICATION_REQUEST])
   );
   const hasCreateError = useSelector(
      selectIsError([ApplicationTypes.CREATE_APPLICATION_REQUEST])
   );

   const isAppTypesLoading = useSelector(
      selectIsLoading([ApplicationTypes.GET_APPLICATION_TYPES_REQUEST])
   );
   const hasAppTypesError = useSelector(
      selectIsError([ApplicationTypes.GET_APPLICATION_TYPES_REQUEST])
   );
   const filteredAppTypes = useSelector(selectApplicationTypes);
   const applicationTypes = useApplicationTypeList().filter(at =>
      filteredAppTypes.includes(at.value)
   );

   const dispatch = useDispatch();
   const routeParams = useParams<ContactParams>();
   const contactId = routeParams.contactId || '';

   const getApplicationTypes = (): void => {
      if (contactId)
         dispatch(ApplicationActions.getApplicationTypesRequest(contactId));
   };
   useEffect(getApplicationTypes, [contactId]);

   const [licenceYearDescription, setLicenceYearDescription] = useState(
      currentLicenceYear.description
   );
   const [appTypeCode, setAppTypeCode] = useState('');
   const [createModel, setCreateModel] = useState<
      Readonly<ApplicationCreateModel>
   >({
      contactId: Number(contactId),
      applicationTypeCode: ' ',
      licenceYearId: currentLicenceYear.licenceYearId,
      createLicence: null,
   });

   const validation = validateModel(createModel);
   let displayValidation: ApplicationCreateValidation = {
      contactId: validation.contactId,
      applicationTypeCode:
         !hasApplicationType || validation.applicationTypeCode,
      licenceYearId: validation.licenceYearId,
   };
   const isValid = Object.values(validation).every((v): boolean => !!v);

   const canCreateLicence =
      createModel.applicationTypeCode &&
      RegistrationApplicationTypes.includes(createModel.applicationTypeCode);

   const createApplicationRequest = (): void => {
      if (!isValid) return;

      dispatch(ApplicationActions.createApplicationRequest(createModel));
      setHasRequestedCreate(true);
   };
   const createAppWithLicenceRequest = (create: boolean | null): void => {
      const newModel = { ...createModel, createLicence: create };
      setCreateModel(newModel);
      if (create === null) return;

      dispatch(ApplicationActions.createApplicationRequest(newModel));
      setHasRequestedCreate(true);
   };

   const shouldRedirect = hasRequestedCreate && hasCreated;
   const createdApp = useSelector(selectApplicationForOverview);
   const hasErrorToDisplay = hasRequestedCreate && hasCreateError;
   const createError = useSelector(selectCreateApplicationError);
   const canEditYear = useSelector(selectCanEditYear);

   if (shouldRedirect) {
      toast.success(
         `Application #${createdApp.applicationId} has been created!`
      );
      return (
         <Redirect
            to={`/contact/${contactId}/applications/${createdApp.applicationId}`}
         />
      );
   } else if (hasErrorToDisplay && createError) {
      toast.error(createError.errorMessage);
      displayValidation = {
         ...displayValidation,
         applicationTypeCode: isNullOrWhiteSpace(
            createError.applicationTypeCode
         ),
         licenceYearId: isNullOrWhiteSpace(createError.licenceYearId),
      };
      setHasRequestedCreate(false);
   }

   const createButtonClick = (): void => {
      if (!canCreateLicence) createApplicationRequest();
      else setShowModal(true);
   };

   return (
      <Loading
         isError={hasAppTypesError}
         isLoading={isAppTypesLoading}
         tryReload={getApplicationTypes}
      >
         <>
            <DialogContainer
               id="create-licence-dialog"
               visible={showModal}
               className="edit-dialog"
               title="Create Licence"
               onHide={(): void => {
                  setShowModal(false);
               }}
               width={600}
               portal
            >
               <CreateLicenceModalBody
                  updateCreateLicence={createAppWithLicenceRequest}
                  updateShowModal={setShowModal}
               />
            </DialogContainer>
            <div className="md-grid md-cell md-cell--12">
               <div className="md-cell md-cell--6">
                  <Autocomplete
                     showUnfilteredData
                     id="select-application-type"
                     label="Application Type"
                     helpText="Please select an Application Type"
                     className="md-cell md-cell--12 extra-small"
                     inputClassName={getInputValidationClassName(
                        !displayValidation.applicationTypeCode
                     )}
                     data={applicationTypes.map(at => at.label)}
                     position={SelectField.Positions.BELOW}
                     error={!displayValidation.applicationTypeCode}
                     defaultValue=" "
                     value={appTypeCode}
                     onAutocomplete={(suggestion): void => {
                        const appType = applicationTypes.find(
                           (at): boolean => at.label === suggestion.toString()
                        );
                        const appTypeCode = appType
                           ? appType.value.toString()
                           : '';

                        setCreateModel({
                           ...createModel,
                           applicationTypeCode: appTypeCode,
                        });
                        setAppTypeCode(suggestion.toString());
                        setHasApplicationType(!!appType);
                     }}
                     onChange={(val): void => {
                        const appType = applicationTypes.find(
                           (at): boolean => at.label === val.toString()
                        );
                        const appTypeCode = appType
                           ? appType.value.toString()
                           : '';

                        setCreateModel({
                           ...createModel,
                           applicationTypeCode: appTypeCode,
                        });
                        setAppTypeCode(val.toString());
                        setHasApplicationType(!!appType);
                     }}
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <Autocomplete
                     showUnfilteredData
                     id="licence-year"
                     label="Licence Year"
                     placeholder="Licence Year"
                     className="md-cell md-cell--12"
                     inputClassName={getInputValidationClassName(
                        !displayValidation.licenceYearId
                     )}
                     disabled={!canEditYear}
                     data={licenceYearDescriptions}
                     error={!displayValidation.licenceYearId}
                     filter={Autocomplete.caseInsensitiveFilter}
                     defaultValue={currentLicenceYear.description}
                     value={licenceYearDescription}
                     onAutocomplete={(suggestion): void => {
                        const year = licenceYears.find(
                           (ly): boolean =>
                              ly.description === suggestion.toString()
                        );
                        const yearId = year ? year.licenceYearId : 0;

                        setCreateModel({
                           ...createModel,
                           licenceYearId: yearId,
                        });
                        setLicenceYearDescription(suggestion.toString());
                     }}
                     onChange={(val): void => {
                        const year = licenceYears.find(
                           (ly): boolean => ly.description === val.toString()
                        );
                        const yearId = year ? year.licenceYearId : 0;

                        setCreateModel({
                           ...createModel,
                           licenceYearId: yearId,
                        });
                        setLicenceYearDescription(val.toString());
                     }}
                  />
               </div>
               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     className="md-cell md-cell--2 md-cell--8-offset"
                     swapTheming
                     isLoading={isLoading}
                     onClick={(): void => {
                        createButtonClick();
                     }}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) createButtonClick();
                     }}
                     disabled={!isValid}
                  >
                     Create
                  </LoadingButton>
                  <Link to={`/contact/${routeParams.contactId}/applications`}>
                     <Button
                        flat
                        secondary
                        className="red-btn md-cell md-cell--2"
                        swapTheming
                     >
                        Cancel
                     </Button>
                  </Link>
               </div>
            </div>
         </>
      </Loading>
   );
};

export const ApplicationCreate = (): JSX.Element => {
   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Applications"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <UserCard />
            </div>
         </ExpansionPanel>
         <ExpansionPanel
            label="Create Application"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <ApplicationCreatePanel />
         </ExpansionPanel>
      </ExpansionList>
   );
};
