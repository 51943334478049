export const NOT_RENEWED = 'Not Renewed';
export const PENDING = 'Pending';

// Renewal licence card
export const PAID = 'Paid';
export const RENEWED = 'Renewed';
export const RENEWING_LATER = 'RenewingLater';
export const NOT_RENEWING_FOR_PERIOD = 'NotRenewingForPeriod';
export const NOT_RENEWING_EVER = 'NotRenewingEver';

export const RENEWING = 'Renewing'; // for undetermined state

export const RENEWING_ACTIONS: readonly string[] = [RENEWED, PAID];

export const NOT_RENEWING_ACTIONS: readonly string[] = [
   RENEWING_LATER,
   NOT_RENEWING_EVER,
   NOT_RENEWING_FOR_PERIOD,
];
