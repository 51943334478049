import React from 'react';
import { ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import { Button } from 'react-md/lib/Buttons';
import { SelectField } from 'react-md/lib/SelectFields';
import { useFinancialExtractTypeList } from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import LoadingButton from 'Components/Common/LoadingButton';
import {
   FinancialExtractSearchQuery,
   isValidQuery,
} from 'Models/BackOffice/FinancialExtractInput';
import { useSelector } from 'react-redux';
import { FinancialExtractTypes } from 'State/Redux/FinancialExtractRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { BatchTypes } from 'State/Redux/BatchRedux';

interface FinancialExtractProps {
   searchInput: FinancialExtractSearchQuery;
   updateSearchInput: (model: FinancialExtractSearchQuery) => void;
   onSearch: () => void;
   onReset: () => void;
}

const FinancialExtracts = ({
   searchInput,
   updateSearchInput,
   onSearch,
   onReset,
}: Readonly<FinancialExtractProps>): JSX.Element => {
   const isLoadingSearch = useSelector(
      selectIsLoading([FinancialExtractTypes.GET_SEARCH_RESULT_REQUEST])
   );

   const isLoadingHistory = useSelector(
      selectIsLoading([BatchTypes.GET_FINANCIAL_ITEMS_REQUEST])
   );

   const isLoading = isLoadingSearch || isLoadingHistory;

   const financialReportType = useFinancialExtractTypeList('');
   const isValid = isValidQuery(searchInput);

   return (
      <ExpansionPanel
         label="Extractions"
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
         className="search-panel"
      >
         <div className="md-grid md-cell--12">
            <SelectField
               floating
               id="bo-search-input-act-type"
               label="Action Type"
               lineDirection="center"
               placeholder="Select an Action"
               className="md-cell md-cell--6"
               position={SelectField.Positions.BELOW}
               menuItems={financialReportType}
               onChange={(e): void =>
                  updateSearchInput({
                     ...searchInput,
                     financialExtractTypeCode: e ? e.toString() : '',
                  })
               }
               value={searchInput.financialExtractTypeCode}
            />
            <div className="md-cell md-cell--6" />
            <DateInput
               id="bo-search-input-start-date"
               label="Start Date"
               className="md-cell md-cell--6"
               maxDate={new Date()}
               value={searchInput.startDate ? searchInput.startDate : undefined}
               onChange={(val): void => {
                  updateSearchInput({
                     ...searchInput,
                     startDate: val ? val : undefined,
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />
            <DateInput
               id="bo-search-input-end-date"
               label="End Date"
               className="md-cell md-cell--6"
               errorText="Please enter a valid end date"
               value={searchInput.endDate ? searchInput.endDate : undefined}
               onChange={(val): void => {
                  updateSearchInput({
                     ...searchInput,
                     endDate: val ? val : undefined,
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) onSearch();
               }}
            />
            <div className="md-cell md-cell--12">
               <div className="controls">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     onClick={(): void => onSearch()}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) onSearch();
                     }}
                     isLoading={isLoading}
                     disabled={!isValid}
                     className="md-cell md-cell--2 md-cell--8-offset"
                  >
                     Search
                  </LoadingButton>
                  <Button
                     flat
                     secondary
                     className="md-cell md-cell--2 red-btn"
                     swapTheming
                     onClick={(): void => {
                        onReset();
                     }}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) onReset();
                     }}
                  >
                     Reset
                  </Button>
               </div>
            </div>
         </div>
      </ExpansionPanel>
   );
};

export default FinancialExtracts;
