import DashboardModelReadonly from 'Models/Dashboard/Dashboard';
import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import { createScopedActions } from '.';
import StateReadonly from './StateModel';

/* ------------- Interfaces for ReduxSauce ------------- */

interface DashboardState {
   dashboardData: readonly DashboardModelReadonly[];
}

export type DashboardStateReadonly = Readonly<DashboardState>;

interface TypeNames {
   GET_DASHBOARD_DATA_REQUEST: string;
   GET_DASHBOARD_DATA_SUCCESS: string;
   GET_DASHBOARD_DATA_FAILURE: string;
}

type DashbaordTypeNames = Readonly<TypeNames>;

export interface DashboardCreators {
   getDashboardDataRequest: () => AnyAction;
   getDashboardDataSuccess: (
      data: DashboardModelReadonly[] | undefined
   ) => AnyAction;
   getDashboardDataFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: DashboardStateReadonly = {
   dashboardData: [],
};

/* ------------- Reducers ------------- */
const getDashboardDataSuccess = (
   state: DashboardStateReadonly,
   action: AnyAction
): DashboardStateReadonly => {
   return { ...state, dashboardData: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   DashbaordTypeNames,
   DashboardCreators
>('dashboard', {
   getDashboardDataRequest: [],
   getDashboardDataSuccess: ['data'],
   getDashboardDataFailure: ['error'],
});

export const DashboardTypes = Types;
export const DashboardActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_DASHBOARD_DATA_SUCCESS]: getDashboardDataSuccess,
});

/* ------------- Selectors ------------- */
export const selectDashboarddata = (
   state: StateReadonly
): readonly DashboardModelReadonly[] => state.dashboard.dashboardData;
