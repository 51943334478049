import './LicencesTab.scss';
import React, { useState, useEffect } from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import { Button } from 'react-md/lib/Buttons';
import { DialogContainer } from 'react-md/lib/Dialogs';
import LicencePanel from './Panels/LicencePanel';
import CreateLicences from './CreateLicences';
import EditLicences from './EditLicences';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { selectContact } from 'State/Redux/ContactRedux';
import { useSelector, useDispatch } from 'react-redux';
import {
   LicenceActions,
   selectLicenceHistory,
   LicenceTypes,
} from 'State/Redux/LicenceRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { usePermissions } from 'Util/Helpers/Permissions';
import LicencesSearch from './LicencesSearch/LicencesSearch';
import ResultsPanel from './LicencesSearch/Panels/ResultsPanel';
import { selectIsLoading } from 'State/Redux/AsyncRedux';

const LicencesTab = (): JSX.Element => {
   const [showModal, setShowModal] = useState(false);
   const [editModal, setEditModal] = useState<number | null>(null);
   const [advancedSearch, setAdvancedSearch] = useState(false);
   const licences = useSelector(selectLicenceHistory);
   const isLoading = useSelector(
      selectIsLoading([LicenceTypes.GET_LICENCE_HISTORY_BY_ID_REQUEST])
   );

   const editLicence = (licenceId: number): void => {
      setEditModal(licenceId);
      setShowModal(true);
   };

   const contact = useSelector(selectContact);
   const dispatch = useDispatch();

   const permissions = usePermissions('Licence.Create');

   useEffect((): void => {
      if (contact.registrationNumber)
         dispatch(
            LicenceActions.getLicenceHistoryByIdRequest(
               contact.registrationNumber.toString()
            )
         );
   }, [contact.registrationNumber, dispatch]);

   return (
      <>
         {!advancedSearch ? (
            <ExpansionList className="md-cell md-cell--12">
               <DialogContainer
                  id="simple-list-dialog"
                  visible={showModal}
                  title={
                     editModal === null ? 'Add New Licence' : 'Edit Licence'
                  }
                  width={1500}
                  portal
                  onHide={FN_EMPTY_VOID}
               >
                  {editModal === null ? (
                     <CreateLicences
                        closeDialog={(): void => setShowModal(false)}
                     />
                  ) : (
                     <EditLicences
                        licenceId={editModal}
                        closeDialog={(): void => setShowModal(false)}
                     />
                  )}
               </DialogContainer>
               <ExpansionPanel
                  label={
                     <>
                        Licences{' '}
                        {permissions && (
                           <Button
                              icon
                              className="plus-btn"
                              onClick={(): void => {
                                 setEditModal(null);
                                 setShowModal(true);
                              }}
                              onKeyUp={(keyPress): void => {
                                 if (!isEnterKeyPress(keyPress)) return;
                                 setEditModal(null);
                                 setShowModal(true);
                              }}
                           >
                              <FaIcon icon="plus" />
                              <p>Create</p>
                           </Button>
                        )}
                     </>
                  }
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <LicencePanel />
                  <div className="md-grid md-cell--12">
                     <div className="md-grid md-cell--6"></div>
                     <div className="md-grid md-cell--6">
                        <Button
                           flat
                           primary
                           className="advanced-filter-button"
                           onClick={(): void => setAdvancedSearch(true)}
                           onKeyUp={(keyPress): void => {
                              if (isEnterKeyPress(keyPress))
                                 setAdvancedSearch(true);
                           }}
                        >
                           Advanced Search
                        </Button>
                     </div>
                  </div>
               </ExpansionPanel>
               <ExpansionPanel
                  label="Licensing History"
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ResultsPanel
                     licences={licences}
                     isLoading={isLoading}
                     editLicence={(licenceId: number): void =>
                        editLicence(licenceId)
                     }
                  />
               </ExpansionPanel>
            </ExpansionList>
         ) : (
            <LicencesSearch setAdvancedSearch={setAdvancedSearch} />
         )}
      </>
   );
};

export default LicencesTab;
