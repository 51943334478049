import React from 'react';
import { Button, ButtonProps } from 'react-md/lib/Buttons';
import { ReactComponent as Spinner } from 'Components/Icons/loading-spinner.svg';
import './style.scss';

interface LoadingButtonProps extends ButtonProps {
   isLoading: boolean;
}

const LoadingButton = ({
   isLoading,
   children,
   disabled,
   ...rest
}: Readonly<LoadingButtonProps>): JSX.Element => {
   return (
      <Button {...rest} disabled={isLoading || disabled}>
         <>
            <div
               className={
                  isLoading
                     ? 'loading-button-loading'
                     : 'loading-button-not-loading'
               }
            >
               {children}
            </div>
            {isLoading && <Spinner className="loading-button-spinner" />}
         </>
      </Button>
   );
};
export default LoadingButton;
