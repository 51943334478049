import React, { useEffect } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import DateInput from 'Components/Common/DateInput/DateInput';
import {
   ApplicationSearchQuery,
   validateSearchQuery,
   filterToValidQuery,
   isValidSearchQuery,
   DefaultSearchModel,
} from 'Models/Application/Dto/ApplicationSearchQuery';
import {
   useApplicationTypeList,
   useApplicationStatusesSearchList,
} from 'Util/Helpers/Metadata';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import SelectField from 'react-md/lib/SelectFields';
import LoadingButton from 'Components/Common/LoadingButton';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import Contact from 'Models/Contact/Data/Contact';

interface ApplicationSearchPanelProps {
   contact?: Contact;
   query: ApplicationSearchQuery;
   setQuery: (query: ApplicationSearchQuery) => void;
   isSearching: boolean;
   setIsSearching: (isSearching: boolean) => void;
   reset: () => void;
}

const ApplicationSearchPanel = ({
   contact,
   query,
   setQuery,
   isSearching,
   setIsSearching,
   reset,
}: Readonly<ApplicationSearchPanelProps>): JSX.Element => {
   const applicationTypes = useApplicationTypeList('Select Application Type');
   const applicationStatuses = useApplicationStatusesSearchList(
      'Select Application Status'
   );

   const validation = validateSearchQuery(query);
   const filteredQuery = filterToValidQuery(query);
   const canSearch = isValidSearchQuery(filteredQuery);

   useEffect((): void => {
      if (!contact) return;
      setQuery({
         ...DefaultSearchModel,
         licenceNumber: contact.registrationNumber.toString(),
      });
   }, [contact, setQuery]);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="app-licence-number"
               label="Licence Number"
               lineDirection="center"
               placeholder="Licence Number"
               className="md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.licenceNumber
               )}
               type="search"
               value={query.licenceNumber}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, licenceNumber: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.licenceNumber}
               errorText="Invalid licence number"
               disabled={contact !== undefined}
            />
         </div>
         <div className="md-cell md-cell--6">
            {contact === undefined ? (
               <TextField
                  floating
                  id="app-registrant-name"
                  label="Registrant's Name"
                  lineDirection="center"
                  placeholder="Registrant's Name"
                  className="md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !validation.registrantName
                  )}
                  type="search"
                  value={query.registrantName}
                  onChange={(value): void => {
                     setIsSearching(false);
                     setQuery({ ...query, registrantName: value.toString() });
                  }}
                  onKeyUp={(event): void => {
                     if (isEnterKeyPress(event)) setIsSearching(true);
                  }}
                  error={!validation.registrantName}
                  errorText="Invalid registrant name"
               />
            ) : (
               <></>
            )}
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="app-start-date"
               label="Start Date"
               className="md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.startDate
               )}
               value={query.startDate ? query.startDate : undefined}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, startDate: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.startDate}
               errorText="Invalid start date"
               defaultValue=" "
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="app-end-date"
               label="End Date"
               className="md-cell--12"
               inputClassName={getInputValidationClassName(!validation.endDate)}
               value={query.endDate ? query.endDate : undefined}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, endDate: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               error={!validation.endDate}
               errorText="Invalid end date"
               defaultValue=" "
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               floating
               id="app-app-type"
               label="Application Type"
               placeholder="Select Application Type"
               className="md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.applicationType
               )}
               menuItems={applicationTypes}
               position={SelectField.Positions.BELOW}
               value={query.applicationType}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({ ...query, applicationType: value.toString() });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               defaultValue=" "
               error={!validation.applicationType}
               errorText="Invalid application type"
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               floating
               id="app-app-status"
               label="Application Status"
               placeholder="Select Application Status"
               className="md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.applicationStatus
               )}
               menuItems={applicationStatuses}
               position={SelectField.Positions.BELOW}
               value={query.applicationStatus}
               onChange={(value): void => {
                  setIsSearching(false);
                  setQuery({
                     ...query,
                     applicationStatus: value.toString(),
                  });
               }}
               onKeyUp={(event): void => {
                  if (isEnterKeyPress(event)) setIsSearching(true);
               }}
               defaultValue=" "
               error={!validation.applicationStatus}
               errorText="Invalid application status"
            />
            <div className="md-cell md-cell--12">
               <LoadingButton
                  flat
                  primary
                  swapTheming
                  isLoading={isSearching}
                  disabled={!canSearch}
                  className="md-cell md-cell--4 md-cell--4-offset search-button"
                  onClick={(): void => {
                     setIsSearching(true);
                  }}
                  onKeyUp={(event): void => {
                     if (isEnterKeyPress(event)) setIsSearching(true);
                  }}
               >
                  Search
               </LoadingButton>
               <Button
                  flat
                  secondary
                  className="md-cell md-cell--4 red-btn search-button"
                  swapTheming
                  onClick={(): void => {
                     setIsSearching(false);
                     setQuery({
                        ...DefaultSearchModel,
                        licenceNumber:
                           contact && contact.registrationNumber
                              ? contact.registrationNumber.toString()
                              : '',
                     });
                     reset();
                  }}
                  onKeyUp={(event): void => {
                     if (!isEnterKeyPress(event)) return;

                     setIsSearching(false);
                     setQuery({
                        ...DefaultSearchModel,
                        licenceNumber:
                           contact && contact.registrationNumber
                              ? contact.registrationNumber.toString()
                              : '',
                     });
                     reset();
                  }}
               >
                  Reset
               </Button>
            </div>
         </div>
      </div>
   );
};

export default ApplicationSearchPanel;
