import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { RegistrationFitAndProper } from '../Review/RegistrationFitAndProper';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import { FitAndProperActions } from 'State/Redux/FitAndProperRedux';
import { useDispatch } from 'react-redux';

interface Section1InputProps {
   userId: string;
   fitAndProper?: QuestionDto[];
   isEditing: boolean;
}
export const QUESTION_TYPE_COUNTRY = '13';
export const QUESTION_TYPE_REGISTRATION = 1;

export type FitAndProperApplicantForm = {
   valid: () => string;
   submit: () => void;
};

const section2 = forwardRef<FitAndProperApplicantForm, Section1InputProps>(
   ({ userId, fitAndProper, isEditing }, ref) => {
      const answer: Array<{
         id: number;
         checkbox: boolean;
         text: string | undefined;
         latestOtherAnswer?: string;
      }> = [];
      const countryList: Array<{ value: string }> = [];

      const [selectedCountriesList, setSelectedCountriesList] = useState(
         countryList
      );

      const [answerData, setAnswerData] = useState(answer);

      const dispatch = useDispatch();

      useImperativeHandle(ref, () => {
         return {
            valid: (): string => {
               if (
                  answerData[+QUESTION_TYPE_COUNTRY] &&
                  answerData[+QUESTION_TYPE_COUNTRY].checkbox &&
                  selectedCountriesList.length === 0
               ) {
                  return 'Please select at least one country';
               } else {
                  return '';
               }
            },
            submit: (): void => {
               const fitAndProperData = [];
               for (const key in answerData) {
                  fitAndProperData.push({
                     id: parseInt(key),
                     questionText: '',
                     latestAnswer: answerData[key].checkbox,
                     latestOtherAnswer:
                        key === QUESTION_TYPE_COUNTRY &&
                        answerData[key].checkbox
                           ? JSON.stringify(selectedCountriesList)
                           : '',
                  });
               }

               if (fitAndProperData && fitAndProperData.length) {
                  dispatch(
                     FitAndProperActions.saveAnswerListByIdAndQuestionIdRequest(
                        userId,
                        fitAndProperData,
                        QUESTION_TYPE_REGISTRATION
                     )
                  );
               }
            },
         };
      });
      return (
         <RegistrationFitAndProper
            fitAndProper={fitAndProper}
            answerData={answerData}
            setAnswerData={setAnswerData}
            selectedCountriesList={selectedCountriesList}
            setSelectedCountriesList={setSelectedCountriesList}
            disabled={!isEditing}
         />
      );
   }
);
export default section2;
