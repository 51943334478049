import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import store from 'State/Redux';
import userManager from 'Util/Auth/AuthManager';

import Main from './Main';
import SignInCallback from 'Components/Common/Auth/SignInCallback';
import Login from 'Components/Common/Auth/Login';
import Logout from 'Components/Common/Auth/Logout';
import { ToastContainer } from 'react-toastify';
import TelemetryProvider from 'AppInsight/TelemetryProvider';
import ScrollTop from 'Components/Common/ScrollTop';

const App = (): JSX.Element => {
   return (
      <Provider store={store}>
         <OidcProvider store={store} userManager={userManager}>
            <BrowserRouter>
               <ScrollTop>
                  <TelemetryProvider>
                     <Switch>
                        <Route path="/login" component={Login} />
                        <Route
                           path="/signincallback"
                           component={SignInCallback}
                        />
                        <Route path="/logout" component={Logout} />
                        <Route component={Main} />
                     </Switch>
                  </TelemetryProvider>
               </ScrollTop>
            </BrowserRouter>
         </OidcProvider>
         <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
         />
      </Provider>
   );
};

export default App;
