import React from 'react';
import { Column, SortingRule, RowInfo } from 'react-table';
import { ComplaintSearchResult } from 'Models/Complaint/ComplaintSearchResult';
import Moment from 'moment-timezone';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';

const getComplaintUrl = (complaint: ComplaintSearchResult): string => {
   return `/disciplinary-matters/${complaint.complaintId}`;
};

const COLUMN_HEADERS: Column<ComplaintSearchResult>[] = [
   {
      Header: 'Disciplinary Matter #',
      accessor: 'complaintId',
   },
   {
      Header: 'Licence Number',
      accessor: 'registrationNumber',
   },
   {
      Header: 'Name',
      accessor: 'name',
   },
   {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: (cellInfo): string => {
         return Moment(cellInfo.value).format(NAMED_DATE_FORMAT);
      },
   },
   {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: (cellInfo): string => {
         return Moment(cellInfo.value).format(NAMED_DATE_FORMAT);
      },
   },
   {
      Header: 'Status',
      accessor: 'status',
   },
];

const DEFAULT_SORTING: SortingRule[] = [{ id: 'startDate', desc: true }];

interface ComplaintResultsPanelProps {
   isLoading: boolean;
   searchResults: readonly ComplaintSearchResult[];
}

const ComplaintResultsPanel = ({
   isLoading,
   searchResults,
}: Readonly<ComplaintResultsPanelProps>): JSX.Element => {
   return (
      <ClientSideTable
         data={[...searchResults]}
         className="-striped -highlight"
         defaultPageSize={20}
         defaultSorted={DEFAULT_SORTING}
         columns={COLUMN_HEADERS}
         loading={isLoading}
         TrComponent={TableRowLink}
         getTrProps={(
            finalState: any, //eslint-disable-line
            rowInfo?: RowInfo
         ): object => {
            if (!rowInfo) return {};
            const complaint =
               rowInfo && (rowInfo.original as ComplaintSearchResult);
            if (!complaint) return {};
            if (complaint.complaintId <= 0) return {};
            const targetUrl = getComplaintUrl(complaint);

            return {
               to: targetUrl,
            };
         }}
      />
   );
};

export default ComplaintResultsPanel;
