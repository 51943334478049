import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import {
   LicenceActions,
   selectCreateLicence,
   selectCreateLicenceError,
   LicenceTypes,
} from 'State/Redux/LicenceRedux';
import {
   SupervisionActions,
   selectSupervisionSearchResult,
} from 'State/Redux/SupervisionRedux';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import { SelectField } from 'react-md/lib/SelectFields';
import { Autocomplete } from 'react-md/lib/Autocompletes';
import CreateLicenceDto from 'Models/Licence/Dto/CreateLicenceDto';
import { toast } from 'react-toastify';
import { LicenceInputValidationReadonly } from 'Models/Validator/LicenceInputValidationModel';
import {
   isNullOrWhiteSpace,
   isValidEndDateAfterStartDate,
   isValidDate,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import {
   useLicenceStatusList,
   usePractitionerLicenceTypeList,
   useCompanyLicenceTypeList,
} from 'Util/Helpers/Metadata';
import { CONTACT_TYPE_EMPLOYER } from 'Util/Constants/Contact';
import {
   VALIDATION_ERROR_MESSAGE,
   TEXT_MAX_LENGTH,
} from 'Util/Constants/Licence';
import DateInput from 'Components/Common/DateInput/DateInput';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { isPractitionerNumber } from 'Util/Helpers/RegistrationNumber';
import { EMPLOYER_LICENCE_TYPE } from 'Util/Constants/LicenceTypes';
import { getCurrentDate } from 'Util/Helpers/Date';
import {
   selectLicenceYears,
   selectSupervisionTypeCodes,
   selectCurrentLicenceYear,
} from 'State/Redux/MetadataRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import LoadingButton from 'Components/Common/LoadingButton';

const buildDisplayValidationModel = (
   validationModel: LicenceInputValidationReadonly,
   updatedFields: Record<keyof LicenceInputValidationReadonly, boolean>
): LicenceInputValidationReadonly => {
   return {
      licenceTypeCode:
         !updatedFields['licenceTypeCode'] || validationModel.licenceTypeCode,
      startDate: !updatedFields['startDate'] || validationModel.startDate,
      licenceYearStr:
         !updatedFields['licenceYearStr'] || validationModel.licenceYearStr,
      licenceStatusCode:
         !updatedFields['licenceStatusCode'] ||
         validationModel.licenceStatusCode,
      expiryDate: !updatedFields['expiryDate'] || validationModel.expiryDate,
      supervisorId:
         !updatedFields['supervisorId'] || validationModel.supervisorId,
      licenceConditions:
         !updatedFields['licenceConditions'] ||
         validationModel.licenceConditions,
   };
};

const NoUpdatedFields: LicenceInputValidationReadonly = {
   startDate: false,
   expiryDate: false,
   licenceStatusCode: false,
   licenceTypeCode: false,
   licenceYearStr: false,
   supervisorId: false,
   licenceConditions: false,
};

interface CreateDetailsProps {
   closeDialog: () => void;
}

const CreateLicences = ({
   closeDialog,
}: Readonly<CreateDetailsProps>): JSX.Element => {
   const contact = useSelector(selectContact);
   const createLicenceResponse = useSelector(selectCreateLicence);
   const createLicenceResponseError = useSelector(selectCreateLicenceError);
   const supervisionTypeCodes = useSelector(selectSupervisionTypeCodes);
   const licenceYears = useSelector(selectLicenceYears);
   const supervisionSearchResult = useSelector(selectSupervisionSearchResult);
   const currentLicenceYear = useSelector(selectCurrentLicenceYear);

   const isCreating = useSelector(
      selectIsLoading([LicenceTypes.CREATE_LICENCE_REQUEST])
   );

   const dispatch = useDispatch();

   const isCompany = contact.contactTypeCode === CONTACT_TYPE_EMPLOYER;
   const [supervisorStr, setSupervisorStr] = useState('');

   const model: CreateLicenceDto = {
      contactId: contact.contactId,
      startDate: getCurrentDate(),
      expiryDate: currentLicenceYear.endDate,
      licenceStatusCode: '',
      licenceTypeCode: isCompany ? EMPLOYER_LICENCE_TYPE : '',
      licenceYearStr: '',
      licenceYearId: -1,
      supervisorId: -1,
      licenceConditions: '',
   };

   const validateModel = (
      editModel: CreateLicenceDto,
      supervisorNumber: string
   ): LicenceInputValidationReadonly => {
      return {
         licenceTypeCode: !isNullOrWhiteSpace(editModel.licenceTypeCode),
         licenceStatusCode: !isNullOrWhiteSpace(editModel.licenceStatusCode),
         licenceYearStr:
            isNullOrWhiteSpace(editModel.licenceYearStr) ||
            (!isNullOrWhiteSpace(editModel.licenceYearStr) &&
               licenceYears.some(
                  (ly): boolean => ly.description === editModel.licenceYearStr
               )),
         supervisorId:
            !supervisionTypeCodes.includes(editModel.licenceTypeCode) ||
            isPractitionerNumber(supervisorNumber),
         expiryDate:
            !editModel.expiryDate ||
            (isValidDate(editModel.expiryDate) &&
               isValidEndDateAfterStartDate(
                  editModel.startDate,
                  editModel.expiryDate
               )),
         startDate: !!editModel.startDate,
         licenceConditions:
            !editModel.licenceConditions ||
            editModel.licenceConditions.length <= TEXT_MAX_LENGTH,
      };
   };

   const [editModel, setEditModel] = useState(model);
   const [updatedFields, setUpdatedFields] = useState(NoUpdatedFields);
   const [validationModel, setValidationModel] = useState(
      validateModel(editModel, supervisorStr)
   );
   const isModelValid = Object.values(validationModel).every(v => !!v);
   const displayValidationModel = buildDisplayValidationModel(
      validationModel,
      updatedFields
   );

   const saveLicence = (): void => {
      if (!isModelValid) {
         toast.error(VALIDATION_ERROR_MESSAGE);
         return;
      }
      dispatch(LicenceActions.createLicenceRequest(editModel)); //This creates a licence
   };

   // For cleanup on unmount
   useEffect(() => {
      return (): void => {
         dispatch(SupervisionActions.searchSupervisorReset());
      };
   }, [dispatch]);

   useEffect((): void => {
      if (createLicenceResponse !== null) {
         toast.success('Licence created!');
         dispatch(
            LicenceActions.getLicenceHistoryByIdRequest(
               contact.registrationNumber.toString()
            )
         );
         closeDialog();
      }
      if (
         createLicenceResponse !== null ||
         createLicenceResponseError !== null
      ) {
         dispatch(LicenceActions.createLicenceReset());
      }
   }, [
      createLicenceResponse,
      closeDialog,
      createLicenceResponseError,
      contact.registrationNumber,
      dispatch,
   ]);

   useEffect((): void => {
      if (createLicenceResponseError !== null) {
         let validity: LicenceInputValidationReadonly = validationModel;
         validity = {
            ...validity,
            [createLicenceResponseError.fieldName as keyof LicenceInputValidationReadonly]: false,
         };
         setValidationModel(validity);

         toast.error(createLicenceResponseError.message);
      }
   }, [createLicenceResponseError, validationModel]);

   const licenceStatuses = useLicenceStatusList('Select a licence status');
   const companyLicenceTypes = useCompanyLicenceTypeList();
   const practitionerLicenceTypes = usePractitionerLicenceTypeList(
      'Select a licence type'
   );
   const licenceYearList = licenceYears.map(ly => ly.description);

   const licenceTypes = isCompany
      ? companyLicenceTypes
      : practitionerLicenceTypes;

   const isSupervisedLicence = supervisionTypeCodes.includes(
      editModel.licenceTypeCode
   );

   const updateEditModel = (
      newState: CreateLicenceDto,
      updatedFields: LicenceInputValidationReadonly
   ): void => {
      setEditModel(newState);
      setUpdatedFields(updatedFields);
      setValidationModel(validateModel(newState, supervisorStr));
   };

   return (
      <>
         <div className="md-grid md-cell--12">
            <div className="md-cell md-cell--6">
               <SelectField
                  id="select-licence-type"
                  label="Licence Type"
                  placeholder="Licence Type"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.licenceTypeCode
                  )}
                  menuItems={licenceTypes}
                  position={SelectField.Positions.BELOW}
                  required
                  errorText="Please select a licence type"
                  error={!displayValidationModel.licenceTypeCode}
                  value={editModel.licenceTypeCode}
                  defaultValue=" "
                  onChange={(val): void => {
                     const resetsSupervisor = !supervisionTypeCodes.includes(
                        val.toString()
                     );
                     if (resetsSupervisor) {
                        dispatch(SupervisionActions.searchSupervisorReset());
                        setSupervisorStr('');
                     }
                     updateEditModel(
                        {
                           ...editModel,
                           licenceTypeCode: val.toString(),
                           supervisorId: resetsSupervisor
                              ? null
                              : editModel.supervisorId,
                        },
                        {
                           ...updatedFields,
                           licenceTypeCode: true,
                           supervisorId: true,
                        }
                     );
                  }}
                  disabled={contact.contactTypeCode === CONTACT_TYPE_EMPLOYER}
               />
            </div>
            <div className="md-cell md-cell--6">
               <SelectField
                  id="select-status"
                  label="Status"
                  placeholder="Status"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.licenceStatusCode
                  )}
                  menuItems={licenceStatuses}
                  position={SelectField.Positions.BELOW}
                  required
                  errorText="Please select a licence status"
                  error={!displayValidationModel.licenceStatusCode}
                  value={editModel.licenceStatusCode}
                  defaultValue=" "
                  onChange={(val): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           licenceStatusCode: val.toString(),
                        },
                        { ...updatedFields, licenceStatusCode: true }
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <DateInput
                  id="start-date"
                  label="Start Date"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.startDate
                  )}
                  required
                  errorText="Please select a valid start date"
                  error={!displayValidationModel.startDate}
                  value={editModel.startDate}
                  onChange={(date): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           startDate: date,
                        },
                        { ...updatedFields, startDate: true, expiryDate: true }
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <DateInput
                  id="end-date"
                  label="End Date"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.expiryDate
                  )}
                  required
                  errorText="Please select a valid end date"
                  error={!displayValidationModel.expiryDate}
                  value={editModel.expiryDate}
                  onChange={(date): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           expiryDate: date,
                        },
                        { ...updatedFields, startDate: true, expiryDate: true }
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--6">
               <Autocomplete
                  id="licence-year"
                  label="Licence Year"
                  placeholder="Licence Year"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.licenceYearStr
                  )}
                  data={licenceYearList}
                  filter={Autocomplete.caseInsensitiveFilter}
                  error={!displayValidationModel.licenceYearStr}
                  onAutocomplete={(suggestion): void => {
                     const tmp = licenceYears.find(
                        (x): boolean => x.description === suggestion.toString()
                     );
                     const yearId = tmp ? tmp.licenceYearId : 0;

                     updateEditModel(
                        {
                           ...editModel,
                           licenceYearStr: suggestion.toString(),
                           licenceYearId: yearId,
                        },
                        { ...updatedFields, licenceYearStr: true }
                     );
                  }}
                  onChange={(val): void => {
                     const tmp = licenceYears.find(
                        (x): boolean => x.description === val
                     );
                     const yearId = tmp ? tmp.licenceYearId : 0;

                     updateEditModel(
                        {
                           ...editModel,
                           licenceYearStr: val,
                           licenceYearId: yearId,
                        },
                        { ...updatedFields, licenceYearStr: true }
                     );
                  }}
               />
            </div>
            <div className="md-cell md-cell--2">
               <TextField
                  floating
                  id="supervisor-id"
                  label="Supervisor #"
                  lineDirection="center"
                  placeholder="Supervisor #"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.supervisorId
                  )}
                  errorText="Please enter a valid supervisor licence number"
                  error={!displayValidationModel.supervisorId}
                  value={supervisorStr}
                  required={isSupervisedLicence}
                  onChange={(val): void => {
                     setSupervisorStr(val.toString());
                     updateEditModel(
                        {
                           ...editModel,
                           supervisorId: isPractitionerNumber(val.toString())
                              ? Number(val)
                              : null,
                        },
                        { ...updatedFields, supervisorId: true }
                     );
                  }}
                  onKeyUp={(keyPress): void => {
                     if (
                        isEnterKeyPress(keyPress) &&
                        isPractitionerNumber(supervisorStr)
                     )
                        dispatch(
                           SupervisionActions.searchSupervisorRequest(
                              supervisorStr
                           )
                        );
                  }}
                  onBlur={(): void => {
                     if (isPractitionerNumber(supervisorStr))
                        dispatch(
                           SupervisionActions.searchSupervisorRequest(
                              supervisorStr
                           )
                        );
                  }}
                  disabled={!isSupervisedLicence}
               />
            </div>
            <div className="md-cell md-cell--4">
               <TextField
                  floating
                  id="supervisor-name"
                  label="Supervisor Name"
                  lineDirection="center"
                  placeholder="Supervisor Name"
                  className="md-cell md-cell--12"
                  value={
                     isSupervisedLicence
                        ? supervisionSearchResult
                           ? supervisionSearchResult.fullName
                           : ''
                        : ''
                  }
                  disabled
               />
            </div>
            <div className="md-cell md-cell--12">
               <TextField
                  id="conditions-to-licence"
                  label="Condition(s) to Licence"
                  placeholder="Condition(s) to Licence"
                  rows={6}
                  maxRows={6}
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     !displayValidationModel.licenceConditions
                  )}
                  maxLength={TEXT_MAX_LENGTH}
                  errorText={
                     'Please remove some text, there is a limit of ' +
                     TEXT_MAX_LENGTH +
                     ' characters'
                  }
                  error={!displayValidationModel.licenceConditions}
                  onChange={(val): void => {
                     updateEditModel(
                        {
                           ...editModel,
                           licenceConditions: val.toString(),
                        },
                        { ...updatedFields, licenceConditions: true }
                     );
                  }}
               />
            </div>
         </div>

         <div className="md-cell md-cell--12">
            <LoadingButton
               flat
               isLoading={isCreating}
               primary
               className="md-cell md-cell--2 md-cell--8-offset"
               onClick={(): void => saveLicence()}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) saveLicence();
               }}
               swapTheming
               disabled={!isModelValid}
            >
               Create
            </LoadingButton>
            <Button
               flat
               secondary
               className="red-btn md-cell md-cell--2"
               onClick={(): void => {
                  closeDialog();
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) closeDialog();
               }}
               swapTheming
            >
               Cancel
            </Button>
         </div>
      </>
   );
};

export default React.memo(CreateLicences);
