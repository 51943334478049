import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useDispatch, useSelector } from 'react-redux';
import SystemParameterResultsPanel from './Panels/SystemParameterResultsPanel';
import './AddSystemParameter.scss';
import {
   SystemParametersActions,
   selectSystemParameters,
   SystemParametersTypes,
} from 'State/Redux/SystemParametersRedux';

const ViewSystemParameter = (): JSX.Element => {
   const searchResults = useSelector(selectSystemParameters);
   const isLoading = useSelector(
      selectIsLoading([SystemParametersTypes.GET_SYSTEM_PARAMETERS_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   useEffect((): void => {
      dispatch(SystemParametersActions.getSystemParametersRequest());
      setHasSearched(true);
   }, [dispatch]);

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={(): void => {}}
            >
               <SystemParameterResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default ViewSystemParameter;
