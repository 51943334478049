import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

export interface SystemParameters {
   parameterCode: string;
   description: string;
   value: string;
}

type SystemParametersReadonly = Readonly<SystemParameters>;

export default SystemParametersReadonly;

export const DefaultSystemParametersModel: SystemParameters = {
   parameterCode: '',
   description: '',
   value: '',
};

interface ValidationModel {
   parameterCode: boolean;
   description: boolean;
   value: boolean;
}

export type BoardMeetingDateValidation = Readonly<ValidationModel>;

export const validateSystemParameters = (
   systemParameters: SystemParameters
): BoardMeetingDateValidation => {
   return {
      parameterCode:
         !isNullOrWhiteSpace(systemParameters.parameterCode) &&
         systemParameters.parameterCode.length <= 4,
      description: !isNullOrWhiteSpace(systemParameters.description),
      value: !isNullOrWhiteSpace(systemParameters.value),
   };
};
