import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import produce from 'immer';
import FeeTypeReadonly from 'Models/FeeMaintenance/Dto/FeeType';
import FeeType from 'Models/FeeMaintenance/Dto/FeeType';

/* ------------- Interfaces for ReduxSauce ------------- */
interface FeeTypeState {
   feeTypes: FeeTypeReadonly[];
   feeType: FeeTypeReadonly | null;
}

export type FeeTypeStateReadonly = Readonly<FeeTypeState>;

interface TypeNames {
   UPDATE_FEE_TYPE_REQUEST: string;
   UPDATE_FEE_TYPE_SUCCESS: string;
   UPDATE_FEE_TYPE_FAILURE: string;

   GET_FEE_TYPES_REQUEST: string;
   GET_FEE_TYPES_SUCCESS: string;
   GET_FEE_TYPES_FAILURE: string;

   ADD_FEE_TYPE_REQUEST: string;
   ADD_FEE_TYPE_SUCCESS: string;
   ADD_FEE_TYPE_FAILURE: string;
}

type FeeTypeTypeNames = Readonly<TypeNames>;

export interface FeeTypeCreators {
   updateFeeTypeRequest: (feeType: FeeTypeReadonly) => AnyAction;
   updateFeeTypeSuccess: (data: FeeTypeReadonly) => AnyAction;
   updateFeeTypeFailure: (error: ApiError) => AnyAction;

   getFeeTypesRequest: () => AnyAction;
   getFeeTypesSuccess: (data: readonly FeeType[] | undefined) => AnyAction;
   getFeeTypesFailure: (error: ApiError) => AnyAction;

   addFeeTypeRequest: (feeType: FeeTypeReadonly) => AnyAction;
   addFeeTypeSuccess: (data: FeeTypeReadonly) => AnyAction;
   addFeeTypeFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: FeeTypeStateReadonly = {
   feeType: null,
   feeTypes: [],
};

/* ------------- Reducers ------------- */
const updateFeeTypeSuccess = (
   state: FeeTypeStateReadonly,
   action: AnyAction
): FeeTypeStateReadonly => {
   return produce(state, (draftState): void => {
      const feeType = action.data;
      if (draftState.feeTypes) {
         const feeTypeFound = draftState.feeTypes.find(feType => {
            return feType.feeTypeCode === feeType.feeTypeCode;
         });
         if (feeTypeFound) {
            const index = draftState.feeTypes.indexOf(feeTypeFound);
            draftState.feeTypes[index] = {
               ...action.data,
            };
         }
      }
   });
};

const addFeeTypeSuccess = (
   state: FeeTypeStateReadonly,
   action: AnyAction
): FeeTypeStateReadonly => {
   return { ...state, feeType: action.data };
};

const getFeeTypesSuccess = (
   state: FeeTypeStateReadonly,
   action: AnyAction
): FeeTypeStateReadonly => {
   return { ...state, feeTypes: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   FeeTypeTypeNames,
   FeeTypeCreators
>('feeType', {
   updateFeeTypeRequest: ['feeType'],
   updateFeeTypeSuccess: ['data'],
   updateFeeTypeFailure: ['error'],

   getFeeTypesRequest: [],
   getFeeTypesSuccess: ['data'],
   getFeeTypesFailure: ['error'],

   addFeeTypeRequest: ['feeType'],
   addFeeTypeSuccess: ['data'],
   addFeeTypeFailure: ['error'],
});

export const FeeTypeTypes = Types;
export const FeeTypeActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.UPDATE_FEE_TYPE_SUCCESS]: updateFeeTypeSuccess,
   [Types.GET_FEE_TYPES_SUCCESS]: getFeeTypesSuccess,
   [Types.ADD_FEE_TYPE_SUCCESS]: addFeeTypeSuccess,
});

/* ------------- Selectors ------------- */
export const selectFeeType = (state: StateReadonly): FeeTypeReadonly | null =>
   state.feeType.feeType;

export const selectFeeTypes = (
   state: StateReadonly
): readonly FeeTypeReadonly[] => state.feeType.feeTypes;
