interface FeeRequestLineDisplay {
   feeRequestId?: number;
   feeRequestLineId?: number;
   feeTypeCode: string;
   feeTypeDescription: string;
   feeRequestLineAmount: number;
   optional: boolean;
   paid: boolean;
   creditReason?: string;
}

export default FeeRequestLineDisplay;

export const DefaultFeeRequestLineDisplay: FeeRequestLineDisplay = {
   feeRequestLineAmount: 0,
   feeTypeCode: '',
   feeTypeDescription: '',
   optional: false,
   paid: false,
};

export const getDefaultFeeRequestLineDisplayList = (): FeeRequestLineDisplay[] => {
   return [];
};
