import React from 'react';
import {
   SUPERVISION_STATUS_DECLINED,
   SUPERVISION_STATUS_PENDING,
} from 'Util/Constants/SupervisionStatus';
import {
   isSupervisionDateCurrentActive,
   isSupervisionDateFutureActive,
} from 'Util/Helpers/Supervision';
import SupervisionTableModel from './SupervisorTableModel';

interface SupervisionStatusProps {
   tableModel: SupervisionTableModel;
}

const SupervisionStatusMessage = ({
   tableModel,
}: SupervisionStatusProps): JSX.Element => {
   const supervision = tableModel.supervision;
   if (!supervision) return <div>No Supervision</div>;
   if (
      supervision.supervisionStatus.toString() === SUPERVISION_STATUS_DECLINED
   ) {
      return (
         <div className="text-orange">
            Supervisor has declined the nomination
         </div>
      );
   }
   if (
      !isSupervisionDateCurrentActive(supervision) &&
      !isSupervisionDateFutureActive(supervision)
   ) {
      return (
         <div className="text-orange">
            Supervision period has ended. New supervisor needs to be nominated
         </div>
      );
   }

   if (
      supervision.supervisionStatus === SUPERVISION_STATUS_PENDING ||
      !supervision.supervisorRenewedLicence
   ) {
      return (
         <div>
            <div className="text-orange">
               {supervision.supervisionStatus === SUPERVISION_STATUS_PENDING &&
                  'Supervisor needs to accept nomination'}
            </div>
            <div className="text-orange">
               {!supervision.supervisorRenewedLicence &&
                  'Supervisor needs to renew their licence'}
            </div>
         </div>
      );
   }
   if (isSupervisionDateCurrentActive(supervision)) {
      return <div>Supervision currently active</div>;
   }
   return <div>Supervision will be active in the future</div>;
};

export default SupervisionStatusMessage;
