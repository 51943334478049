export type ApplicationSupervisorStatus =
   | 'Invalid'
   | 'Pending'
   | 'Valid'
   | 'Not Found';

export const INVALID_SUPERVISOR_STATUS: ApplicationSupervisorStatus[] = [
   'Not Found',
   'Invalid',
];

interface SupervisionStatusDto {
   supervisorStatus: ApplicationSupervisorStatus;
   statusMessage: string;
}

type SupervisionStatusDtoReadonly = Readonly<SupervisionStatusDto>;

export default SupervisionStatusDtoReadonly;
