import {
   isNullOrWhiteSpace,
   isValidEndDateAfterStartDate,
   isValidDate,
} from 'Util/Helpers/Validation';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'Util/Constants/Common';

interface BatchSearchQueryModel {
   batchProcessCode: string;
   startDate: string;
   endDate: string;
}

export type BatchSearchQuery = Readonly<BatchSearchQueryModel>;

export const DefaultBatchSearchQuery: BatchSearchQuery = {
   batchProcessCode: '',
   startDate: '',
   endDate: moment().format(DATEPICKER_DATE_FORMAT),
};

interface BatchSearchQueryValidationModel {
   batchProcessCode: boolean;
   startDate: boolean;
   endDate: boolean;
}

export type BatchSearchQueryValidation = Readonly<
   BatchSearchQueryValidationModel
>;

export const DefaultBatchSearchQueryValidation: BatchSearchQueryValidation = {
   batchProcessCode: false,
   startDate: false,
   endDate: false,
};

export const validateSearch = (
   query: BatchSearchQuery
): BatchSearchQueryValidation => {
   return {
      batchProcessCode: true,
      startDate: isValidDate(query.startDate), // don't trust the datepicker
      endDate:
         isValidDate(query.endDate) &&
         isValidEndDateAfterStartDate(query.startDate, query.endDate),
   };
};

export const filterToValidQuery = (
   query: BatchSearchQuery
): BatchSearchQuery => {
   return {
      batchProcessCode: isNullOrWhiteSpace(query.batchProcessCode)
         ? ''
         : query.batchProcessCode,
      startDate: query.startDate, // trust the datepicker
      endDate: query.endDate,
   };
};

export const isValidQuery = (query: BatchSearchQuery): boolean => {
   return !!query.batchProcessCode || !!query.startDate || !!query.endDate;
};
