import React, { useState } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { SelectField } from 'react-md/lib/SelectFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useBatchProcessesList } from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import LoadingButton from 'Components/Common/LoadingButton';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import {
   BatchSearchQuery,
   DefaultBatchSearchQuery,
   validateSearch,
   filterToValidQuery,
   isValidQuery,
} from 'Models/BackOffice/BatchSearchQuery';

interface ExportHistorySearchPanelProps {
   searchBatch: (query: BatchSearchQuery) => void;
   isSearching: boolean;
   resetSearch: () => void;
   displayFileList: boolean;
}

const ExportHistorySearch = ({
   searchBatch,
   isSearching,
   resetSearch,
   displayFileList,
}: Readonly<ExportHistorySearchPanelProps>): JSX.Element => {
   const batchProcesses = useBatchProcessesList('Select File Type');

   const [searchQuery, setSearchQuery] = useState(DefaultBatchSearchQuery);
   const validation = validateSearch(searchQuery);
   const filteredQuery = filterToValidQuery(searchQuery);
   const isValid = isValidQuery(filteredQuery);

   const searchOnKeyPress = (
      keyPress: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (isEnterKeyPress(keyPress) && isValid) searchBatch(filteredQuery);
   };

   return (
      <div className="md-grid md-cell--12">
         {displayFileList && (
            <div className="md-cell md-cell--6">
               <SelectField
                  id="select-field-6"
                  lineDirection="center"
                  label="File Type"
                  placeholder="File Type"
                  className="md-cell md-cell--12"
                  menuItems={batchProcesses}
                  position={SelectField.Positions.BELOW}
                  value={searchQuery.batchProcessCode}
                  onChange={(val): void => {
                     setSearchQuery({
                        ...searchQuery,
                        batchProcessCode: val.toString(),
                     });
                  }}
                  onKeyUp={searchOnKeyPress}
                  error={!validation.batchProcessCode}
                  errorText="Please enter a valid status"
               />
            </div>
         )}
         <div className="md-cell md-cell--6">
            <DateInput
               id="complaint-start-date"
               label="Start Date"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validation.startDate
               )}
               error={!validation.startDate}
               errorText="Please enter a valid start date"
               value={searchQuery.startDate ? searchQuery.startDate : undefined}
               onChange={(date): void => {
                  setSearchQuery({
                     ...searchQuery,
                     startDate: date,
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>

         <div className="md-cell md-cell--6">
            <DateInput
               id="complaint-end-date"
               label="End Date"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(!validation.endDate)}
               error={!validation.endDate}
               errorText="Please enter a valid end date"
               value={searchQuery.endDate}
               onChange={(date): void => {
                  setSearchQuery({
                     ...searchQuery,
                     endDate: date,
                  });
               }}
               onKeyUp={searchOnKeyPress}
            />
         </div>
         <div className="md-cell md-cell--8"></div>
         <div className="md-cell md-cell--4">
            <LoadingButton
               flat
               primary
               className="md-cell md-cell--6 search-button"
               swapTheming
               isLoading={isSearching}
               disabled={!isValid}
               onClick={(): void => searchBatch(filteredQuery)}
               onKeyUp={searchOnKeyPress}
            >
               Search
            </LoadingButton>
            <Button
               flat
               secondary
               className="md-cell md-cell--6 red-btn search-button"
               swapTheming
               onClick={(): void => {
                  setSearchQuery(DefaultBatchSearchQuery);
                  resetSearch();
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) {
                     setSearchQuery(DefaultBatchSearchQuery);
                     resetSearch();
                  }
               }}
            >
               Reset
            </Button>
         </div>
      </div>
   );
};

export default ExportHistorySearch;
