import { createUserManager } from 'redux-oidc';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';

/* eslint-disable @typescript-eslint/camelcase */
const userManagerConfig: UserManagerSettings = {
   client_id: 'admin-portal',
   redirect_uri: process.env.REACT_APP_ADMIN_REDIRECT_URL,
   response_type: 'id_token token',
   scope: 'openid profile email pgdbAPI.full_access IdentityServerApi',
   authority: process.env.REACT_APP_IDENTITY_AUTHORITY as string,
   silent_redirect_uri: process.env.REACT_APP_ADMIN_SILENT_REDIRECT_URL,
   post_logout_redirect_uri:
      process.env.REACT_APP_ADMIN_POSTLOGOUT_REDIRECT_URL,
   automaticSilentRenew: true,
   filterProtocolClaims: true,
   loadUserInfo: true,
   userStore: new WebStorageStateStore({ store: localStorage }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
