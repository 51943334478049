import FeesPaymentSearchInput from 'Models/FeesPayment/FeesPaymentSearchInput';
import FeesPaymentSearchResult from 'Models/FeesPayment/FeesPaymentSearchResult';
import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import FeeRequestInput from 'Models/FeesPayment/FeeRequestInput';
import FeeRequestLineInput from 'Models/FeesPayment/FeeRequestLineInput';
import FeeRequestLineDisplay from 'Models/FeesPayment/FeeRequestLineDisplay';
import FeeRequestDtoReadonly from 'Models/FeeRequest/Dto/FeeRequestDto';

export interface FeesPaymentServiceType {
   getSearchResult(
      searchInput: FeesPaymentSearchInput
   ): Promise<ApiResponse<FeesPaymentSearchResult>>;
   saveFeeRequest(data: FeeRequestInput): Promise<ApiResponse<number>>;
   saveFeeRequestLine(data: FeeRequestLineInput): Promise<ApiResponse<number>>;
   getFeeRequestLine(id: number): Promise<ApiResponse<FeeRequestLineDisplay>>;
   getFeeRequest(id: number): Promise<ApiResponse<FeeRequestDtoReadonly>>;
}

const create = (baseURL: string | undefined): FeesPaymentServiceType => {
   const api = createApi({ baseURL });
   const payRequestUrl = '/PaymentAndFeeRequest';
   const getSearchResult = (
      searchInput: FeesPaymentSearchInput
   ): Promise<ApiResponse<FeesPaymentSearchResult>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`${payRequestUrl}/Search`, searchInput);
   };

   const saveFeeRequest = (
      data: FeeRequestInput
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return data.feeRequestId
         ? authApi.put(`${payRequestUrl}/FeeRequest`, data)
         : authApi.post(`${payRequestUrl}/FeeRequest`, data);
   };

   const getFeeRequest = (
      id: number
   ): Promise<ApiResponse<FeeRequestDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`${payRequestUrl}/FeeRequest/${id}`);
   };

   const getFeeRequestLine = (
      id: number
   ): Promise<ApiResponse<FeeRequestLineDisplay>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`${payRequestUrl}/FeeRequestLine/${id}`);
   };

   const saveFeeRequestLine = (
      data: FeeRequestLineInput
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return data.feeRequestLineId
         ? authApi.put(`${payRequestUrl}/FeeRequestLine`, data)
         : authApi.post(`${payRequestUrl}/FeeRequestLine`, data);
   };

   return {
      getSearchResult,
      saveFeeRequest,
      saveFeeRequestLine,
      getFeeRequestLine,
      getFeeRequest,
   };
};

export default { create };
