import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { SelectField } from 'react-md/lib/SelectFields';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelectList, isEnterKeyPress } from 'Util/Helpers/Input';
import './NotificationMaintenance.scss';
import {
   selectWebMessage,
   NotificationActions,
   NotificationTypes,
} from 'State/Redux/NotificationRedux';
import TextField from 'react-md/lib/TextFields';
import $ from 'jquery';
import WebMessageDtoReadonly, {
   isValidItem,
} from 'Models/Notifications/WebMessageDto';
import LoadingButton from 'Components/Common/LoadingButton';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import './NotificationMaintenance.scss';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';
import { Permission } from 'Util/Constants/Permission';
import { Authorized } from 'Components/Common/Authorized';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const NOTIFICATION_PERMISSION: Permission = 'NotificationMaintenance.Update';

interface NotificationMessageDisplayModel {
   messagecode: string;
   description: string | JSX.Element;
   canManuallyTrigger: boolean;
}

type NotificationMessageDisplay = Readonly<NotificationMessageDisplayModel>;

const NotificationMaintenance = (): JSX.Element => {
   const webMessages = useSelector(selectWebMessage);
   const dispatch = useDispatch();
   const [selectedDescription, setSelectedDescription] = useState('');
   const [backdropText, setbackdropText] = useState('');
   const [changed, setChanged] = useState(false);
   const [hasUpdated, setHasUpdated] = useState(false);
   const [
      selectedTemplate,
      setSelectedTemplate,
   ] = useState<WebMessageDtoReadonly | null>(null);
   const isLoading = useSelector(
      selectIsLoading([NotificationTypes.UPDATE_WEB_MESSAGE_REQUEST])
   );
   const isValid = isValidItem(selectedTemplate);
   const [selectedNotification, setSelectedNotification] = useState<
      NotificationMessageDisplay[]
   >([
      {
         messagecode: '',
         description: '',
         canManuallyTrigger: false,
      },
   ]);
   const [showConfirmationDefault, setShowConfirmationDefault] = useState<
      boolean
   >(false);

   useEffect((): void => {
      dispatch(NotificationActions.getWebMessagesRequest());
   }, [dispatch]);

   useEffect((): void => {
      if (selectedTemplate) {
         const $textarea = $('#floating-center-title');
         const $backdrop = $('.backdrop');

         $textarea.on({
            scroll: () => {
               const scrollTop = $textarea.scrollTop();

               if (scrollTop) {
                  $backdrop.scrollTop(scrollTop);
               }
            },
         });
      }
   }, [selectedTemplate]);

   const webMessageList = createSelectList(
      webMessages,
      msg => msg.webMessageCode,
      msg => msg.description
   );

   useEffect((): void => {
      if (hasUpdated && !isLoading) {
         toast.success('Notification template updated!');
         dispatch(NotificationActions.getWebMessagesRequest());
      }

      if (isLoading) {
         setHasUpdated(true);
      }
   }, [isLoading, hasUpdated, dispatch]);

   const createMarkup = (text: string): { __html: string } => {
      return { __html: `${text}` };
   };

   const updateBackdropText = (
      val: string,
      selectedItem: WebMessageDtoReadonly
   ): void => {
      let text = val
         .replace(/</g, '&lt;')
         .replace(/>/g, '&gt;')
         .replace(/\n$/g, '\n\n')
         .replace(/{%([\S\s]*?)-%}/g, '<mark class="for-loop">$&</mark>');

      if (!selectedItem.availableParameters)
         text = text.replace(
            /{{(.*?)}}/g,
            '<mark class="invalid-param">$&</mark>'
         );
      else text = text.replace(/{{(.*?)}}/g, '<mark>$&</mark>');

      const params = getParametersFromText(val);

      if (params) {
         params.map(item => {
            if (
               selectedItem &&
               selectedItem.availableParameters &&
               !selectedItem.availableParameters.includes(item)
            ) {
               const regex = new RegExp('{{(\\s*' + item + '\\s*)}}', 'g');

               text = text.replace(
                  regex,
                  '<mark class="invalid-param">$&</mark>'
               );
            }

            return item;
         });
      }

      setbackdropText(text);
   };

   const updateTemplateParameters = (
      text: string,
      template: WebMessageDtoReadonly
   ): WebMessageDtoReadonly => {
      const regex = /{% for( .*?)in/g;
      let result: RegExpExecArray | null;
      const indices: string[] = [];

      while ((result = regex.exec(text.toString()))) {
         const found = result[1].toString().trim();
         indices.push(found); // Adds it to array
      }

      if (template) {
         let availableParameters = template.availableParameters;

         if (availableParameters) {
            availableParameters.forEach(item => {
               if (item.toLowerCase().indexOf('model.') === -1) {
                  availableParameters.splice(
                     availableParameters.indexOf(item),
                     1
                  );
               }
            });
         }

         indices.forEach(item => {
            if (!availableParameters) availableParameters = [];

            if (!availableParameters.some(arr => arr === item)) {
               availableParameters.push(item);
            }
         });

         setSelectedTemplate({
            ...template,
            value: text,
            availableParameters: availableParameters,
         });

         return { ...template, availableParameters: availableParameters };
      }

      return template;
   };

   const getParametersFromText = (text: string): string[] => {
      const regex = /{{(.*?)}}/g;
      let result: RegExpExecArray | null;
      const indices: string[] = [];

      while ((result = regex.exec(text.toString()))) {
         const found = result[1].toString().trim();
         indices.push(found); // Adds it to array
      }

      return indices;
   };

   return (
      <Authorized permissions={[NOTIFICATION_PERMISSION]} showWarning>
         <ExpansionList className="md-cell md-cell--12 notification-maintenance">
            <ExpansionPanel
               label="Notification Maintenance"
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <div className="md-grid md-cell--12">
                     <SelectField
                        floating
                        id="select-field-6"
                        lineDirection="center"
                        label="Notification Type"
                        placeholder="Select Notification"
                        className="md-cell md-cell--4"
                        position={SelectField.Positions.BELOW}
                        defaultValue=" "
                        value={selectedDescription}
                        menuItems={webMessageList}
                        onChange={(val): void => {
                           setSelectedDescription(val.toString());
                           const selectedItem = webMessages.find(
                              (msg): boolean =>
                                 msg.webMessageCode === val.toString()
                           );
                           if (selectedItem) {
                              updateBackdropText(
                                 selectedItem.value,
                                 updateTemplateParameters(
                                    selectedItem.value,
                                    selectedItem
                                 )
                              );
                              setChanged(false);
                              setSelectedNotification([
                                 {
                                    messagecode: val.toString(),
                                    description: (
                                       <pre>
                                          <code>{selectedItem.value}</code>
                                       </pre>
                                    ),
                                    canManuallyTrigger:
                                       selectedItem.canManuallyTrigger,
                                 },
                              ]);
                           }
                        }}
                     />
                  </div>
                  {selectedNotification[0].canManuallyTrigger && (
                     <LoadingButton
                        flat
                        primary
                        className="md-cell md-cell--2 md-cell--10-offset trigger-btn"
                        swapTheming
                        onClick={(): void => {
                           setShowConfirmationDefault(true);
                        }}
                        onKeyUp={(keyPress): void => {
                           if (isEnterKeyPress(keyPress))
                              setShowConfirmationDefault(true);
                        }}
                        isLoading={showConfirmationDefault}
                     >
                        Trigger Notification
                     </LoadingButton>
                  )}
                  {selectedTemplate && (
                     <div className="md-grid md-cell--12">
                        <div className="md-cell--9 notification-edit-area">
                           <div className="md-cell--12 ">
                              <div
                                 className="backdrop highlights"
                                 dangerouslySetInnerHTML={createMarkup(
                                    backdropText
                                 )}
                              ></div>
                           </div>
                           <TextField
                              floating
                              id="floating-center-title"
                              lineDirection="center"
                              placeholder="Description"
                              className="md-cell md-cell--12"
                              errorText="Please enter a valid description"
                              rows={20}
                              maxRows={20}
                              value={selectedTemplate.value}
                              onChange={(val): void => {
                                 updateTemplateParameters(
                                    val.toString(),
                                    selectedTemplate
                                 );

                                 setChanged(true);
                                 updateBackdropText(
                                    val.toString(),
                                    selectedTemplate
                                 );
                              }}
                           />
                        </div>
                        <div className="md-cell--3">
                           <h3 className="center">Available Parameters</h3>
                           <ul>
                              {selectedTemplate.availableParameters &&
                                 selectedTemplate.availableParameters.map(
                                    item => {
                                       return <li key={item}>{item}</li>;
                                    }
                                 )}
                           </ul>
                        </div>
                        <LoadingButton
                           flat
                           primary
                           className="md-cell md-cell--3 md-cell--9-offset search-button"
                           swapTheming
                           disabled={!changed || !isValid}
                           onClick={(): void => {
                              dispatch(
                                 NotificationActions.updateWebMessageRequest({
                                    ...selectedTemplate,
                                 })
                              );
                           }}
                           onKeyUp={(keyPress): void => {
                              if (isEnterKeyPress(keyPress))
                                 dispatch(
                                    NotificationActions.updateWebMessageRequest(
                                       {
                                          ...selectedTemplate,
                                       }
                                    )
                                 );
                           }}
                           isLoading={isLoading}
                        >
                           Update
                        </LoadingButton>
                     </div>
                  )}
               </div>
            </ExpansionPanel>
            <ConfirmationDialog
               id="example-confirmation-default"
               onConfirm={(): void => {
                  dispatch(
                     NotificationActions.triggerNotificationRequest(
                        selectedNotification[0].messagecode
                     )
                  );
                  setShowConfirmationDefault(false);
                  toast.success(`Notifications have been triggered`);
               }}
               onCancel={(): void => {
                  setShowConfirmationDefault(false);
               }}
               visible={showConfirmationDefault}
            >
               <>Are you sure you want to trigger this notification?</>
            </ConfirmationDialog>
         </ExpansionList>
      </Authorized>
   );
};

export default NotificationMaintenance;
