import React from 'react';
import { useSelector } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import './PersonalDetailsPanel.scss';
import {
   selectContact,
   selectActiveRegistrations,
} from 'State/Redux/ContactRedux';
import {
   getMailingAddressLine,
   getBusinessAddressLine,
   getPhysicalAddressLine,
} from 'Util/Helpers/Address';
import { REGISTRATION_STATUS_ACTIVE } from 'Util/Constants/RegistrationStatus';
import { getTradesmanDisciplineName } from 'Util/Helpers/LicenceCard';
import DefaultIfNull from 'Components/Common/DefaultIfNull';
import UserCard from 'Components/Common/UserCard/UserCard';
import { COMPANY_CONTACT_TYPES } from 'Util/Constants/Contact';

const PersonalDetailsPanel = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const registrations = useSelector(selectActiveRegistrations);

   const activeRegistrations = registrations
      .filter(
         (r): boolean => r.registrationStatusCode === REGISTRATION_STATUS_ACTIVE
      )
      .map(
         (r): JSX.Element => {
            return (
               <div className="contact-list-item" key={r.registrationId}>
                  <img
                     className="icon-size list-icon"
                     alt="Discipline icon"
                     src={`/images/icon-${getTradesmanDisciplineName(
                        r.registrationDiscipline
                     ).toLowerCase()}.svg`}
                  />
                  {r.registrationDescription}
               </div>
            );
         }
      );

   const isPreferredMobile: boolean =
      contact.preferredContactInformation &&
      contact.preferredContactInformation === contact.mobileNumber
         ? true
         : false;

   const isPreferredPhone: boolean =
      contact.preferredContactInformation &&
      contact.preferredContactInformation === contact.phoneNumber &&
      !isPreferredMobile
         ? true
         : false;

   const shouldRenderSubscriptions =
      !contact.optOutEmailNotifications ||
      !contact.optOutSmsnotifications ||
      contact.subscribeFlag;

   const subscriptions = shouldRenderSubscriptions ? (
      <div>
         {!contact.optOutEmailNotifications && (
            <div>Email for Applications</div>
         )}
         {!contact.optOutSmsnotifications && <div>SMS for Applications</div>}
         {contact.subscribeFlag && <div>Subscribed</div>}
      </div>
   ) : (
      '-'
   );

   const renderPublicRegisterDetails = (): JSX.Element => {
      return (
         <>
            <h2 className="panel-header">
               <div>
                  <FaIcon
                     icon="universal-access"
                     className="icon-outline-header header-icon"
                  />
               </div>
               Public Register Details
            </h2>

            <div className="contact-list-item">
               <FaIcon icon="at" className="icon-outline list-icon" />
               <DefaultIfNull displayElement={contact.publicEmail} />
            </div>

            <div className="contact-list-item">
               <FaIcon icon="mobile-alt" className="icon-outline list-icon" />
               <DefaultIfNull displayElement={contact.publicPhone} />
            </div>

            <div className="contact-list-item">
               <FaIcon
                  icon="map-marker-alt"
                  className="icon-outline list-icon"
               />
               <DefaultIfNull
                  displayElement={getBusinessAddressLine(contact)}
               />
            </div>

            {activeRegistrations.length > 0 && (
               <>
                  <h2 className="panel-subheader">Active Registration(s)</h2>
                  {activeRegistrations}
               </>
            )}
         </>
      );
   };

   const renderCompanyDetails = (): JSX.Element => {
      return (
         <>
            <div className="contact-list-item">
               <FaIcon icon="globe" className="icon-outline list-icon" />
               <DefaultIfNull displayElement={contact.url} />
            </div>
            <div className="contact-list-item">
               <FaIcon icon="user" className="icon-outline list-icon" />
               <DefaultIfNull displayElement={contact.fullName} />
            </div>
         </>
      );
   };

   return (
      <div className="md-grid md-cell--12 personal-details-panel">
         <div className="md-cell md-cell--6">
            <UserCard showContactDetails={false} />

            <div className="contact-list-item">
               <FaIcon icon="phone-alt" className="icon-outline list-icon" />
               <div className="star-wrapper">
                  <DefaultIfNull displayElement={contact.phoneNumber} />
                  {isPreferredPhone && (
                     <FaIcon icon="star" className="icon-size green-star" />
                  )}
               </div>
            </div>

            <div className="contact-list-item">
               <FaIcon icon="mobile-alt" className="icon-outline list-icon" />
               <div className="star-wrapper">
                  <DefaultIfNull displayElement={contact.mobileNumber} />
                  {isPreferredMobile && (
                     <FaIcon icon="star" className="icon-size green-star" />
                  )}
               </div>
            </div>

            <div className="contact-list-item">
               <FaIcon icon="at" className="icon-outline list-icon" />

               <DefaultIfNull displayElement={contact.emailAddress} />
            </div>

            <div className="contact-list-item">
               <FaIcon icon="paper-plane" className="icon-outline list-icon" />

               <DefaultIfNull displayElement={getMailingAddressLine(contact)} />
            </div>

            <div className="contact-list-item">
               <FaIcon
                  icon="map-marker-alt"
                  className="icon-outline list-icon"
               />

               <DefaultIfNull
                  displayElement={getPhysicalAddressLine(contact)}
               />
            </div>

            <div className="contact-list-item">
               <FaIcon icon="venus-mars" className="icon-outline list-icon" />
               <DefaultIfNull
                  displayElement={contact.gender ? contact.gender.name : null}
               />
            </div>

            <div className="contact-list-item">
               <FaIcon icon="user" className="icon-outline list-icon" />

               <DefaultIfNull
                  displayElement={
                     contact.ethnicity ? contact.ethnicity.name : null
                  }
               />
            </div>

            {COMPANY_CONTACT_TYPES.some(
               ct => ct === contact.contactTypeCode
            ) && (
               <div className="contact-list-item">
                  <FaIcon
                     icon="comment-dots"
                     className="icon-outline list-icon"
                  />
                  {subscriptions}
               </div>
            )}
         </div>
         <div className="md-cell md-cell--6 rightpanel">
            {COMPANY_CONTACT_TYPES.some(ct => ct === contact.contactTypeCode)
               ? renderCompanyDetails()
               : renderPublicRegisterDetails()}
         </div>
      </div>
   );
};

export default PersonalDetailsPanel;
