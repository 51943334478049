import React from 'react';
import SelectField from 'react-md/lib/SelectFields';
import { getDropdownList } from 'Util/Constants/CountryCode';
import { ConditionValueProps } from './ConditionValueProps';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

interface ConditionCountryProps {
   onChange: (val: string | number) => void;
}

export const ConditionCountry = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
}: Readonly<ConditionCountryProps & ConditionValueProps>): JSX.Element => {
   const countryValues = getDropdownList();

   return (
      <SelectField
         floating
         id={`app-${1}-${conditionCode}`}
         lineDirection="center"
         className="md-cell md-cell--12"
         label={description}
         inputClassName={getInputValidationClassName(!isChecked)}
         menuItems={countryValues}
         position={SelectField.Positions.BELOW}
         value={textValue}
         defaultValue=" "
         required
         error={!isChecked}
         errorText="Please select a country"
         disabled={disabled}
         onChange={onChange}
      />
   );
};
