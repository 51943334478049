import { createSelectList, SelectItem } from './Input';
import { useSelector } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import { EMPLOYER_LICENCE_TYPE } from 'Util/Constants/LicenceTypes';
import moment from 'moment';
import { ApplicationTypeGroups } from 'Util/Constants/ApplicationTypeGroups';
import {
   ApplicationStatusByFilterRegistration,
   ApplicationStatusGroups,
} from 'Util/Constants/ApplicationStatusGroups';
import { ApplicationStatus } from 'Models/Metadata/ApplicationStatus';

// the following are convenience selectors to create
// select lists for use within components.
// Because of the reliance upon hooks, these must also follow hook rules -
// i.e. they must be used every render
export const useApplicationStatusesSearchList = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.applicationStatuses,
         (at): string => at.applicationStatusCode,
         (at): string => at.description,
         defaultValue
      );
   });
};

const extractStatusesFromMetadata = (
   state: StateReadonly,
   listStatusRetrieve: string[],
   defaultValue?: string
): SelectItem[] => {
   const filteredStatuses: ApplicationStatus[] = [];
   listStatusRetrieve.forEach(s =>
      state.metadata.applicationStatuses.forEach(a => {
         if (a.applicationStatusCode === s) {
            filteredStatuses.push(a);
         }
      })
   );
   return createSelectList(
      filteredStatuses,
      (at): string => at.applicationStatusCode,
      (at): string => at.description,
      defaultValue
   );
};

export const useApplicationStatusesList = (
   defaultValue: string
): SelectItem[] => {
   const typeGroupId = ApplicationTypeGroups.findIndex(
      t => t.findIndex(a => a === defaultValue) >= 0
   );
   const statusGroup = ApplicationStatusGroups[typeGroupId];
   return useSelector((state: StateReadonly): SelectItem[] => {
      return extractStatusesFromMetadata(state, statusGroup);
   });
};

export const useRegistrationApplicationStatusesFilter = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return extractStatusesFromMetadata(
         state,
         ApplicationStatusByFilterRegistration,
         defaultValue
      );
   });
};

export const useApplicationTypeList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.applicationTypes,
         (at): string => at.applicationTypeCode,
         (at): string => at.description,
         defaultValue
      );
   });
};

export const useComplaintStatusList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.complaintStatuses,
         (cs): string => cs.complaintStatusCode,
         (cs): string => cs.description,
         defaultValue
      );
   });
};

export const useConditionList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.conditions,
         (cc): string => cc.conditionCode,
         (cc): string => cc.description,
         defaultValue
      );
   });
};

export const useContactStatusList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.contactStatuses,
         (cs): string => cs.contactStatusCode,
         (cs): string => cs.description,
         defaultValue
      );
   });
};

export const useContactTypeList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.contactTypes,
         (ct): string => ct.contactTypeCode,
         (ct): string => ct.description,
         defaultValue
      );
   });
};

export const useCourseTypeList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.courseTypes,
         (ct): string => ct.courseTypeId.toString(),
         (ct): string => ct.description,
         defaultValue
      );
   });
};

export const useDisciplineList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.disciplines,
         (d): string => d.disciplineCode,
         (d): string => d.description,
         defaultValue
      );
   });
};

export const useFeeRequestStatusList = (
   defaultValue?: string,
   showAllOption?: boolean
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      const result = createSelectList(
         state.metadata.feeRequestStatuses,
         (frs): string => frs.feeRequestStatusCode,
         (frs): string => frs.description,
         defaultValue
      );

      return showAllOption ? [{ label: 'All', value: '' }, ...result] : result;
   });
};

export const useFeeTypeList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.feeTypes,
         (ft): string => ft.feeTypeCode,
         (ft): string => ft.description,
         defaultValue
      );
   });
};

export const useLicenceStatusList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.licenceStatuses,
         (ls): string => ls.licenceStatusCode,
         (ls): string => ls.description,
         defaultValue
      );
   });
};

export const useLicenceTypeList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.licenceTypes,
         (lt): string => lt.licenceTypeCode,
         (lt): string => lt.description,
         defaultValue
      );
   });
};

export const usePractitionerLicenceTypeList = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.licenceTypes.filter(
            (lt): boolean => lt.licenceTypeCode !== EMPLOYER_LICENCE_TYPE
         ),
         (lt): string => lt.licenceTypeCode,
         (lt): string => lt.description,
         defaultValue
      );
   });
};

export const useCompanyLicenceTypeList = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.licenceTypes.filter(
            (lt): boolean => lt.licenceTypeCode === EMPLOYER_LICENCE_TYPE
         ),
         (lt): string => lt.licenceTypeCode,
         (lt): string => lt.description,
         defaultValue
      );
   });
};

export const useLicenceYearList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.licenceYears,
         (ly): string => ly.licenceYearId.toString(),
         (ly): string => ly.description,
         defaultValue
      );
   });
};

// Returns select list of licence years without future licence years
export const useLicenceYearListWithNoFuture = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.licenceYears.filter(year => {
            return moment().isAfter(moment(year.startDate));
         }),
         (ly): string => ly.licenceYearId.toString(),
         (ly): string => ly.description,
         defaultValue
      );
   });
};

export const usePaymentStatusList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.paymentStatuses,
         (ps): string => ps.paymentStatusCode,
         (ps): string => ps.description,
         defaultValue
      );
   });
};

export const useRegistrationStatusList = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.registrationStatuses,
         (rs): string => rs.registrationStatusCode,
         (rs): string => rs.description,
         defaultValue
      );
   });
};

export const useRegistrationTypeList = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.registrationTypes,
         (rt): string => rt.registrationTypeCode,
         (rt): string => rt.description,
         defaultValue
      );
   });
};

export const useResultStatusList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.resultStatuses,
         (rs): string => rs.resultStatusCode,
         (rs): string => rs.description,
         defaultValue
      );
   });
};

export const useLicenceSupervision = (): Record<string, string> => {
   return useSelector(
      (state: StateReadonly): Record<string, string> => {
         return state.metadata.licenceSupervision;
      }
   );
};

// Fix for the issue of PPMA-1702 - Section 20 exemption and Supervising
//PGDB confirmed that ExemptionGasfittingUnderSupervision and Section21EUS license holders can be supervised by either a
// CertifyingGasfitter or ExemptionSpecificGasfittingEquipment license holder, So, a new Dictionary<string, List<string>> licenceSupervisions
// was created in API metadata. Hence, created this funtion to use the newly created licenceSupervisions to check the supervisionstatus in the
// front end  code
export const useLicenceSupervisions = (): Record<string, string[]> => {
   return useSelector(
      (state: StateReadonly): Record<string, string[]> => {
         return state.metadata.licenceSupervisions;
      }
   );
};

export const usePaymentMethodList = (
   defaultValue?: string,
   showAllOption?: boolean
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      const result = createSelectList(
         state.metadata.paymentMethods,
         (rs): string => rs.paymentMethodCode,
         (rs): string => rs.description,
         defaultValue
      );

      return showAllOption ? [{ label: 'All', value: '' }, ...result] : result;
   });
};

export const useFinancialExtractTypeList = (
   defaultValue?: string
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.financialReportType,
         (frs): string => frs.financialReportTypeCode,
         (frs): string => frs.description,
         defaultValue
      );
   });
};

export const useBatchProcessesList = (defaultValue?: string): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      return createSelectList(
         state.metadata.batchProcesses,
         (lt): string => lt.batchProcessCode,
         (lt): string => lt.description,
         defaultValue
      );
   });
};

export const useUserRoleList = (
   defaultValue?: string,
   showAllOption?: boolean
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      const result = createSelectList(
         state.metadata.roles,
         (ft): string => ft.roleCode,
         (ft): string => ft.roleDescription,
         defaultValue
      );

      return showAllOption ? [{ label: 'All', value: '' }, ...result] : result;
   });
};

export const useGenderTypeList = (
   defaultValue?: string,
   showAllOption?: boolean
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      const result = createSelectList(
         state.metadata.genderTypes,
         (ft): string => ft.id,
         (ft): string => ft.name,
         defaultValue
      );

      return showAllOption ? [{ label: 'All', value: '' }, ...result] : result;
   });
};

export const useEthnicityTypeList = (
   defaultValue?: string,
   showAllOption?: boolean
): SelectItem[] => {
   return useSelector((state: StateReadonly): SelectItem[] => {
      const result = createSelectList(
         state.metadata.ethnicityTypes,
         (ft): string => ft.id,
         (ft): string => ft.name,
         defaultValue
      );

      return showAllOption ? [{ label: 'All', value: '' }, ...result] : result;
   });
};
