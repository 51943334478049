import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-md/lib/Buttons';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import UserCard from 'Components/Common/UserCard/UserCard';
import PaymentDetail from './Panels/PaymentDetail';
import FeeRequestTable from './Panels/FeeRequestTable';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import {
   isNullOrWhiteSpace,
   isValidDateBeforeNow,
} from 'Util/Helpers/Validation';
import './Payment.scss';
import FeeRequestUIModel from './Models/FeeRequestUIModel';
import FeeRequestLineUIModel from './Models/FeeRequestLineUIModel';
import PaymentDetailFeeRequestLineReadOnly from 'Models/Payment/Data/PaymentDetailFeeRequestLine';
import {
   selectPaymentDetail,
   PaymentActions,
   PaymentTypes,
   selectUpdatedFeeRequestLine,
   selectUpdatingPaymentDateStatus,
   selectCancellingPaymentStatus,
   selectMultiPayDetail,
   selectTransactionNumber,
   selectProcessedPaymentId,
   selectLoadedMultiPay,
} from 'State/Redux/PaymentRedux';
import {
   selectIsLoading,
   selectIsError,
   selectIsSuccess,
   selectErrorMessage,
   AsyncActions,
} from 'State/Redux/AsyncRedux';
import { ContactActions } from 'State/Redux/ContactRedux';
import Loading from 'Components/Common/Loading';
import { Link } from 'react-router-dom';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import PaymentDetailPaymentRecordReadOnly, {
   DefaultPaymentDetailPaymentRecord,
} from 'Models/Payment/Data/PaymentDetailPaymentRecord';
import { CREDIT_CARD_STAFF } from 'Util/Constants/PaymentMethod';
import ValidationModel, {
   ValidationResult,
} from 'Components/Common/ModelValidator/ValidationModel';
import {
   validateModel,
   validateField,
} from 'Components/Common/ModelValidator/Helper';
import { toast } from 'react-toastify';
import { usePermissions } from 'Util/Helpers/Permissions';
import PaymentDetailFeeRequestReadOnly from 'Models/Payment/Data/PaymentDetailFeeRequest';
import DialogContainer from 'react-md/lib/Dialogs';
import FeeRequestLine from 'Components/Pages/FeesPayment/FeeRequest/FeeRequestLine/Views/FeeRequestLine';
import FeeRequestLineDisplay, {
   DefaultFeeRequestLineDisplay,
} from 'Models/FeesPayment/FeeRequestLineDisplay';
import { isEqual, cloneDeep, isEmpty } from 'lodash-es';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';
import LoadingButton from 'Components/Common/LoadingButton';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import PaymentDataFeeRequestDtoReadonly from 'Models/Payment/Dto/PaymentDataFeeRequestDto';
import PaymentDataFeeRequestLineDtoReadonly from 'Models/Payment/Dto/PaymentDataFeeRequestLineDto';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import PaymentDetailReadOnly from 'Models/Payment/Data/PaymentDetail';
import {
   ReceiptTypes,
   selectReceipt,
   ReceiptActions,
} from 'State/Redux/ReceiptRedux';
import FileSaver from 'file-saver';
import DateInput from 'Components/Common/DateInput/DateInput';
import { ApplicationActions } from 'State/Redux/ApplicationRedux';

export type PaymentValidationFields = Pick<
   PaymentDetailPaymentRecordReadOnly,
   | 'paymentDate'
   | 'paymentMethod'
   | 'payerName'
   | 'bankBranch'
   | 'referenceNumber'
   | 'paymentStatus'
>;

type ConfirmationType = 'UpdatePaymentDate' | 'CancelPayment';

const multiPayUrl = '/payment/multipay';

const Payment = (): JSX.Element => {
   const paymentDetail = useSelector(selectPaymentDetail);
   const multiPayDetail = useSelector(selectMultiPayDetail);
   const loadedMultiPay = useSelector(selectLoadedMultiPay);
   const updatedFeeRequestLine = useSelector(selectUpdatedFeeRequestLine);
   const isLoading = useSelector(
      selectIsLoading([PaymentTypes.GET_PAYMENT_DETAIL_REQUEST])
   );
   const updatingPaymentDateStatus = useSelector(
      selectUpdatingPaymentDateStatus
   );
   const isLoadingUpdatingPaymentDateStatus = useSelector(
      selectIsLoading([PaymentTypes.UPDATE_PAYMENT_DATE_REQUEST])
   );
   const isErrorUpdatingPaymentDateStatus = useSelector(
      selectIsError([PaymentTypes.UPDATE_PAYMENT_DATE_REQUEST])
   );

   const cancellingPaymentStatus = useSelector(selectCancellingPaymentStatus);
   const isLoadingCancellingPaymentStatus = useSelector(
      selectIsLoading([PaymentTypes.CANCEL_PAYMENT_REQUEST])
   );
   const isErrorCancellingPaymentStatus = useSelector(
      selectIsError([PaymentTypes.CANCEL_PAYMENT_REQUEST])
   );

   const transactionNumber = useSelector(selectTransactionNumber);
   const isLoadingSavingPaymentData = useSelector(
      selectIsLoading([PaymentTypes.SAVE_PAYMENT_DATA_REQUEST])
   );
   const isErrorSavingPaymentData = useSelector(
      selectIsError([PaymentTypes.SAVE_PAYMENT_DATA_REQUEST])
   );

   const processedPaymentId = useSelector(selectProcessedPaymentId);
   const isLoadingSavingNonOnlinePayment = useSelector(
      selectIsLoading([PaymentTypes.SAVE_NON_ONLINE_PAYMENT_REQUEST])
   );
   const isErrorSavingNonOnlinePayment = useSelector(
      selectIsError([PaymentTypes.SAVE_NON_ONLINE_PAYMENT_REQUEST])
   );
   const errorSavingNonOnlinePaymentMessage = useSelector(
      selectErrorMessage(PaymentTypes.SAVE_NON_ONLINE_PAYMENT_REQUEST)
   );

   const receipt = useSelector(selectReceipt);
   const isDownloading = useSelector(
      selectIsLoading([
         ReceiptTypes.GET_RECEIPT_REQUEST,
         ReceiptTypes.GET_RECEIPT_BY_PAYMENT_ID_REQUEST,
      ])
   );
   const isDownloadedWithContactAndPayment = useSelector(
      selectIsSuccess([ReceiptTypes.GET_RECEIPT_REQUEST])
   );

   const isDownloadedWithPayment = useSelector(
      selectIsSuccess([ReceiptTypes.GET_RECEIPT_BY_PAYMENT_ID_REQUEST])
   );

   const dispatch = useDispatch();

   const canCancelPayment = usePermissions('CancelPayment');
   const canBackOfficeFinanceCreate = usePermissions(
      'BackOfficeFinance.Create'
   );
   const canBackOfficeFinanceUpdate = usePermissions(
      'BackOfficeFinance.Update'
   );
   const canReadInvoice = usePermissions('Invoice.Read');

   const history = useHistory();
   const location = useLocation();
   const queryStringValues = queryString.parse(location.search);
   const returnUrl =
      queryStringValues &&
      queryStringValues.returnUrl &&
      !Array.isArray(queryStringValues.returnUrl) &&
      !isNullOrWhiteSpace(queryStringValues.returnUrl)
         ? queryStringValues.returnUrl
         : '';
   const returnUrlForCheckout = `${location.pathname}${location.search}`;

   const isMultiPayInUrl = location.pathname.startsWith(multiPayUrl)
      ? true
      : false;
   const [isMultiPay, setIsMultiPay] = useState<boolean>(isMultiPayInUrl);

   const [feeRequests, setFeeRequests] = useState<FeeRequestUIModel[]>([]);
   const [payment, setPayment] = useState<PaymentDetailPaymentRecordReadOnly>({
      ...DefaultPaymentDetailPaymentRecord,
   });
   const [initiated, setInitiated] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [loadMultiPayList, setLoadMultiPayList] = useState(false);
   const [receivedDate, setReceivedDate] = useState('');
   const [showReceivedDateModal, setShowReceivedDateModal] = useState(false);

   const updateShowModal = useCallback((show: boolean): void => {
      setShowReceivedDateModal(show);
   }, []);

   const [paymentDetailLocalModel, setPaymentDetailLocalModel] = useState<
      PaymentDetailReadOnly
   >(({} as unknown) as PaymentDetailReadOnly);

   const [editFeeRequestLineModel, setEditFeeRequestLineModel] = useState<
      FeeRequestLineDisplay
   >({
      ...DefaultFeeRequestLineDisplay,
   });

   const [updateRequested, setUpdateRequested] = useState(false);
   const [updateRequestedId, setUpdateRequestedId] = useState(0);
   const [
      updatedFeeRequestLineLocalModel,
      setUpdatedFeeRequestLineLocalModel,
   ] = useState<PaymentDetailFeeRequestLineReadOnly>(
      ({} as unknown) as PaymentDetailFeeRequestLineReadOnly
   );

   const confirmationTextForUpdatePaymentDate =
      'Are you sure you want to update payment date for this payment?';
   const confirmationTextForCancelPayment =
      'Are you sure you want to cancel the payment?';
   const [confirmationType, setConfirmationType] = useState<ConfirmationType>();
   const [showConfirmationDialog, setShowConfirmationDialog] = useState<
      boolean
   >(false);

   const [updatePaymentDateSubmitted, setUpdatePaymentDateSubmitted] = useState<
      boolean
   >(false);

   const [cancelPaymentSubmitted, setCancelPaymentSubmitted] = useState<
      boolean
   >(false);

   const [processingPayment, setProcessingPayment] = useState<boolean>(false);
   const [savingPaymentDataSubmitted, setSavingPaymentDataSubmitted] = useState<
      boolean
   >(false);

   const [
      savingNonOnlinePaymentSubmitted,
      setSavingNonOnlinePaymentSubmitted,
   ] = useState<boolean>(false);

   const [gettingReceiptSubmitted, setGettingReceiptSubmitted] = useState<
      boolean
   >(false);

   const getValiationModel = (
      mode: 'default' | 'creditCard' | 'rest'
   ): ValidationModel<PaymentValidationFields> => {
      return {
         errorMessage: 'The model is not valid',
         fields: {
            paymentDate: {
               validation: 'text',
               required: mode === 'rest' || mode === 'creditCard',
               error: false,
            },
            paymentMethod: {
               validation: 'text',
               required: mode === 'rest' || mode === 'creditCard',
               error: false,
            },
            payerName: {
               validation: 'text',
               required: mode === 'rest',
               error: false,
            },
            bankBranch: {
               validation: 'text',
               required: mode === 'rest',
               error: false,
            },
            referenceNumber: {
               validation: 'text',
               required: mode === 'rest',
               error: false,
            },
            paymentStatus: {
               validation: 'text',
               required: mode === 'rest',
               error: false,
            },
         },
      };
   };

   const [validationModel, setValidationModel] = useState(
      getValiationModel('rest')
   );
   const [validationResult, setValidationResult] = useState<
      ValidationResult<PaymentValidationFields>
   >(
      validateModel<PaymentValidationFields>(
         payment,
         getValiationModel('default')
      )
   );

   const updateModel = (
      newModel: PaymentDetailPaymentRecordReadOnly,
      field: keyof PaymentValidationFields
   ): void => {
      let vModel: ValidationModel<PaymentValidationFields>;
      if (newModel.paymentMethod === CREDIT_CARD_STAFF) {
         vModel = getValiationModel('creditCard');
      } else {
         vModel = getValiationModel('rest');
      }
      setValidationResult(
         validateField<PaymentValidationFields>(
            newModel,
            vModel,
            field,
            validationResult
         )
      );
      setValidationModel(vModel);
      setPayment(newModel);
   };

   useEffect((): void => {
      setIsMultiPay(isMultiPayInUrl);
      setInitiated(false);
      dispatch(
         AsyncActions.resetAsync([
            ReceiptTypes.GET_RECEIPT_REQUEST,
            ReceiptTypes.GET_RECEIPT_BY_PAYMENT_ID_REQUEST,
         ])
      );
      if (isMultiPayInUrl && loadedMultiPay) {
         dispatch(PaymentActions.getPaymentDetailReset());
         dispatch(PaymentActions.emptyMultiPay());
         setLoadMultiPayList(true);
      }
   }, [
      location.search,
      location.pathname,
      dispatch,
      isMultiPayInUrl,
      loadedMultiPay,
   ]);

   const updateTotalAmount = useCallback(
      (
         fr: FeeRequestUIModel[],
         payment: PaymentDetailPaymentRecordReadOnly,
         isPaid: boolean
      ): number => {
         if (isPaid) return payment.amount;

         let totalAmount = 0;
         fr.forEach(f => {
            f.feeRequestLines.forEach(frl => {
               if (frl.isChecked && !frl.data.isPaid) {
                  totalAmount += frl.data.amount;
               }
            });
         });
         return totalAmount;
      },
      []
   );

   const setMultiPayList = useCallback((): void => {
      if (isMultiPay) {
         const multiPayList: FeeRequestUIModel[] = [];
         multiPayDetail.forEach(f => multiPayList.push(cloneDeep(f)));
         setFeeRequests(multiPayList);
         const totalAmount = updateTotalAmount(
            multiPayList,
            paymentDetail.payment,
            paymentDetail.isPaid
         );

         if (paymentDetail.isPaid) {
            setPayment({ ...paymentDetail.payment, amount: totalAmount });
         } else {
            setPayment({
               ...DefaultPaymentDetailPaymentRecord,
               amount: totalAmount,
            });
         }
      }
   }, [multiPayDetail, paymentDetail, updateTotalAmount, isMultiPay]);

   useEffect((): void => {
      if (!initiated) {
         let applicationId = null;
         let feeRequestId = null;
         let feeRequestLineId = null;
         let paymentId = null;

         if (
            queryStringValues &&
            queryStringValues.applicationId &&
            !Array.isArray(queryStringValues.applicationId) &&
            !isNullOrWhiteSpace(queryStringValues.applicationId)
         ) {
            applicationId = Number(queryStringValues.applicationId);
         }

         if (
            queryStringValues &&
            queryStringValues.feeRequestId &&
            !Array.isArray(queryStringValues.feeRequestId) &&
            !isNullOrWhiteSpace(queryStringValues.feeRequestId)
         ) {
            feeRequestId = Number(queryStringValues.feeRequestId);
         }

         if (
            queryStringValues &&
            queryStringValues.feeRequestLineId &&
            !Array.isArray(queryStringValues.feeRequestLineId) &&
            !isNullOrWhiteSpace(queryStringValues.feeRequestLineId)
         ) {
            feeRequestLineId = Number(queryStringValues.feeRequestLineId);
         }

         if (
            queryStringValues &&
            queryStringValues.paymentId &&
            !Array.isArray(queryStringValues.paymentId) &&
            !isNullOrWhiteSpace(queryStringValues.paymentId)
         ) {
            paymentId = Number(queryStringValues.paymentId);
         }

         if (isMultiPay) {
            setMultiPayList();
         } else {
            dispatch(
               PaymentActions.getPaymentDetailRequest(
                  applicationId,
                  feeRequestId,
                  feeRequestLineId,
                  paymentId
               )
            );
         }
         setInitiated(true);
      }
   }, [dispatch, queryStringValues, initiated, isMultiPay, setMultiPayList]);

   useEffect((): void => {
      if (isMultiPayInUrl && loadedMultiPay) {
         dispatch(PaymentActions.emptyMultiPay());
         setLoadMultiPayList(true);
      }
   }, [dispatch, isMultiPayInUrl, loadedMultiPay]);

   useEffect((): void => {
      if (!initiated) return;

      if (
         !isEmpty(paymentDetail) &&
         !isEmpty(paymentDetailLocalModel) &&
         isEqual(paymentDetail, paymentDetailLocalModel)
      )
         return;

      setPaymentDetailLocalModel(paymentDetail);

      if (
         paymentDetail &&
         paymentDetail.contactId &&
         !paymentDetail.isMultiPay &&
         !isMultiPayInUrl
      ) {
         dispatch(
            ContactActions.getContactByIdRequest(
               paymentDetail.contactId.toString()
            )
         );
      }

      if (
         paymentDetail &&
         paymentDetail.feeRequests &&
         paymentDetail.feeRequests.length > 0 &&
         !isMultiPayInUrl
      ) {
         let feeRequests: FeeRequestUIModel[] = [];

         const selectedFeeRequests = paymentDetail.feeRequests.filter(
            f => f.requiredDefaultSelection
         );

         if (selectedFeeRequests && selectedFeeRequests.length > 0) {
            feeRequests = feeRequests.concat(
               mapToUIModel(true, selectedFeeRequests)
            );
         }

         const restFeeRequests = paymentDetail.feeRequests.filter(
            f => !f.requiredDefaultSelection
         );

         if (restFeeRequests && restFeeRequests.length > 0) {
            feeRequests = feeRequests.concat(
               mapToUIModel(
                  paymentDetail.isCancelled || paymentDetail.isPaid,
                  restFeeRequests
               )
            );
         }

         const totalAmount = updateTotalAmount(
            feeRequests,
            paymentDetail.payment,
            paymentDetail.isPaid
         );

         if (!isMultiPayInUrl && !paymentDetail.isMultiPay) {
            setFeeRequests(feeRequests);
         }

         if (paymentDetail.isPaid || paymentDetail.isCancelled) {
            setIsMultiPay(paymentDetail.isMultiPay);

            setPayment({ ...paymentDetail.payment, amount: totalAmount });

            if (paymentDetail.isMultiPay && loadedMultiPay) {
               dispatch(PaymentActions.addMultiPayFee(feeRequests, true));
               setLoadMultiPayList(true);
            }
         } else {
            setPayment({
               ...DefaultPaymentDetailPaymentRecord,
               amount: totalAmount,
            });
         }
      }
   }, [
      initiated,
      paymentDetail,
      dispatch,
      updateTotalAmount,
      isMultiPayInUrl,
      paymentDetailLocalModel,
      loadedMultiPay,
   ]);

   const mapToUIModel = (
      check: boolean,
      feeRequests: PaymentDetailFeeRequestReadOnly[]
   ): FeeRequestUIModel[] => {
      const mappedFeeRequests: FeeRequestUIModel[] = [];
      feeRequests.forEach(fr => {
         const mappedFeeRequestLines: FeeRequestLineUIModel[] = [];
         fr.feeRequestLines.forEach(frl => {
            mappedFeeRequestLines.push({
               isChecked: frl.isPaid || check,
               data: frl,
            });
         });

         mappedFeeRequests.push({
            isExpanded: check,
            isChecked: check,
            data: fr,
            feeRequestLines: mappedFeeRequestLines,
         });
      });
      return mappedFeeRequests;
   };

   const showHideFeeRequestLine = (feeRequestId: number): void => {
      const feeRequest = feeRequests.find(
         f => f.data.feeRequestId === feeRequestId
      );
      if (feeRequest) {
         const index = feeRequests.findIndex(
            f => f.data.feeRequestId === feeRequestId
         );
         feeRequest.isExpanded = !feeRequest.isExpanded;
         feeRequests[index] = feeRequest;
         setFeeRequests([...feeRequests]);
      }
   };

   const checkFeeRequest = (feeRequestId: number): void => {
      const feeRequest = feeRequests.find(
         f => f.data.feeRequestId === feeRequestId
      );
      if (feeRequest) {
         feeRequest.isChecked = !feeRequest.isChecked;
         feeRequest.feeRequestLines.forEach(frl => {
            frl.isChecked = frl.data.isPaid || feeRequest.isChecked;

            checkDisciplinaryFee(frl, feeRequest, feeRequests);
         });

         if (feeRequest.isChecked) {
            feeRequest.isExpanded = true;
         }

         const index = feeRequests.findIndex(
            f => f.data.feeRequestId === feeRequestId
         );
         feeRequests[index] = feeRequest;
         const totalAmount = updateTotalAmount(
            feeRequests,
            payment,
            paymentDetail.isPaid
         );
         setPayment({ ...payment, amount: totalAmount });
         setFeeRequests([...feeRequests]);
      }
   };

   const checkFeeRequestLine = (
      feeRequestId: number,
      feeRequestLineId: number
   ): void => {
      const feeRequest = feeRequests.find(
         f => f.data.feeRequestId === feeRequestId
      );
      if (feeRequest) {
         const feeRequestLine = feeRequest.feeRequestLines.find(
            f => f.data.feeRequestLineId === feeRequestLineId
         );

         if (feeRequestLine) {
            feeRequestLine.isChecked = !feeRequestLine.isChecked;

            checkDisciplinaryFee(feeRequestLine, feeRequest, feeRequests);

            // update fee request checkbox
            feeRequest.isChecked =
               feeRequest.feeRequestLines.filter(v => v.isChecked).length > 0
                  ? true
                  : false;

            const index = feeRequests.findIndex(
               f => f.data.feeRequestId === feeRequestId
            );
            feeRequests[index] = feeRequest;

            const totalAmount = updateTotalAmount(
               feeRequests,
               payment,
               paymentDetail.isPaid
            );
            setPayment({ ...payment, amount: totalAmount });

            setFeeRequests([...feeRequests]);
         }
      }
   };

   const checkDisciplinaryFee = (
      feeRequestLine: FeeRequestLineUIModel,
      feeRequest: FeeRequestUIModel,
      fr: FeeRequestUIModel[]
   ): void => {
      if (
         feeRequestLine.isChecked &&
         feeRequestLine.data.requiredDisciplinaryCheck
      ) {
         const disciplinaryFeeRequest = feeRequests.find(f =>
            f.feeRequestLines.some(
               l =>
                  l.data.isDisciplinaryFee &&
                  l.data.licenceYear === feeRequest.data.licenceYear
            )
         );

         if (disciplinaryFeeRequest) {
            disciplinaryFeeRequest.isExpanded = true;
            disciplinaryFeeRequest.isChecked = true;
            disciplinaryFeeRequest.feeRequestLines.forEach(frl => {
               frl.isChecked = disciplinaryFeeRequest.isChecked;
            });
            const index = fr.findIndex(
               f =>
                  f.data.feeRequestId ===
                  disciplinaryFeeRequest.data.feeRequestId
            );
            fr[index] = disciplinaryFeeRequest;
         }
      }
   };

   const validateFormFields = (): boolean => {
      const vResult = validateModel<PaymentValidationFields>(
         payment,
         validationModel
      );

      if (!vResult || vResult.error) {
         setValidationResult(vResult);
         toast.error('Validation Failed: Please enter all required fields');
         return false;
      }

      return true;
   };

   const getSelectedCount = (): number => {
      return feeRequests.filter(f => f.isChecked).length;
   };

   const getFeeRequestLineSelectedCount = (): number => {
      let feeLineCount = 0;
      feeRequests.forEach(f => {
         feeLineCount += f.feeRequestLines.filter(
            l => l.isChecked && !l.data.isPaid
         ).length;
      });
      return feeLineCount;
   };

   const getSelectedFeeRequests = (): FeeRequestUIModel[] => {
      const selectedFeeRequests: FeeRequestUIModel[] = [];
      feeRequests.forEach(f => {
         if (
            f.isChecked &&
            f.feeRequestLines.filter(l => l.isChecked).length ===
               f.feeRequestLines.length
         ) {
            const feeRequestItem = cloneDeep(f);
            feeRequestItem.feeRequestLines = feeRequestItem.feeRequestLines.filter(
               i => !i.data.isPaid
            );
            selectedFeeRequests.push(feeRequestItem);
         } else {
            const selectedFeeRequestLines = f.feeRequestLines.filter(
               l => l.isChecked && !l.data.isPaid
            );
            if (selectedFeeRequests && selectedFeeRequestLines.length) {
               const feeRequestItem = cloneDeep(f);
               // assign only the checked fee request lines
               feeRequestItem.feeRequestLines = selectedFeeRequestLines;
               selectedFeeRequests.push(feeRequestItem);
            }
         }
      });
      return selectedFeeRequests;
   };

   const addToMultiPay = (): void => {
      // addToMultiPay logic goes here
      dispatch(PaymentActions.addMultiPayFee(getSelectedFeeRequests(), false));
      toast.success('Selected Fee Request details are added to Multi-Pay');
   };

   const proceedPayment = (): void => {
      if (!validateFormFields()) {
         return;
      }

      setProcessingPayment(true);

      const selectedFeeRequests = getSelectedFeeRequests();
      const frDto: PaymentDataFeeRequestDtoReadonly[] = [];
      selectedFeeRequests.forEach(fr => {
         const frlDto: PaymentDataFeeRequestLineDtoReadonly[] = [];
         fr.feeRequestLines.forEach(frl => {
            frlDto.push({
               feeRequestLineId: frl.data.feeRequestLineId,
               feeRequestId: frl.data.feeRequestId,
               isPaid: frl.data.isPaid,
               amount: frl.data.amount,
            });
         });
         frDto.push({
            feeRequestId: fr.data.feeRequestId,
            feeRequestLines: frlDto,
         });
      });

      if (payment.paymentMethod === CREDIT_CARD_STAFF) {
         setSavingPaymentDataSubmitted(true);
         dispatch(
            PaymentActions.savePaymentDataRequest({
               isMultiPay: isMultiPay,
               paymentDetail: payment,
               feeRequests: frDto,
            })
         );
      } else {
         setSavingNonOnlinePaymentSubmitted(true);
         dispatch(
            PaymentActions.saveNonOnlinePaymentRequest({
               isMultiPay: isMultiPay,
               paymentDetail: payment,
               feeRequests: frDto,
            })
         );
      }
   };

   useEffect((): void => {
      if (
         savingPaymentDataSubmitted &&
         !isLoadingSavingPaymentData &&
         !isErrorSavingPaymentData &&
         transactionNumber !== undefined &&
         !isNullOrWhiteSpace(transactionNumber)
      ) {
         setSavingPaymentDataSubmitted(false);
         history.push(
            `/payment-checkout/${transactionNumber}?returnUrl=${encodeURIComponent(
               returnUrlForCheckout
            )}`
         );
      }

      if (
         savingPaymentDataSubmitted &&
         !isLoadingSavingPaymentData &&
         (isErrorSavingPaymentData || isNullOrWhiteSpace(transactionNumber))
      ) {
         toast.error('Failed to save payment data.');
         setSavingPaymentDataSubmitted(false);
         setProcessingPayment(false);
      }
   }, [
      savingPaymentDataSubmitted,
      transactionNumber,
      isLoadingSavingPaymentData,
      isErrorSavingPaymentData,
      dispatch,
      isMultiPay,
      returnUrl,
      history,
      returnUrlForCheckout,
   ]);

   useEffect((): void => {
      if (
         savingNonOnlinePaymentSubmitted &&
         !isLoadingSavingNonOnlinePayment &&
         !isErrorSavingNonOnlinePayment &&
         processedPaymentId !== undefined &&
         processedPaymentId > 0
      ) {
         updateShowModal(true);

         toast.success('Payment has been processed successfully');
         history.push(
            `/payment?paymentId=${processedPaymentId}&returnUrl=${returnUrl}`
         );
      }

      if (
         savingNonOnlinePaymentSubmitted &&
         !isLoadingSavingNonOnlinePayment &&
         (isErrorSavingNonOnlinePayment ||
            processedPaymentId === undefined ||
            processedPaymentId <= 0)
      ) {
         toast.error(
            <>
               Failed to process payment.
               {!isNullOrWhiteSpace(errorSavingNonOnlinePaymentMessage) ? (
                  <>
                     <br />
                     {errorSavingNonOnlinePaymentMessage}
                  </>
               ) : null}
            </>
         );
         setSavingNonOnlinePaymentSubmitted(false);
         setProcessingPayment(false);
      }
   }, [
      savingNonOnlinePaymentSubmitted,
      processedPaymentId,
      isLoadingSavingNonOnlinePayment,
      isErrorSavingNonOnlinePayment,
      returnUrl,
      history,
      errorSavingNonOnlinePaymentMessage,
      updateShowModal,
   ]);

   const onConfirm = (confirmationType: ConfirmationType): void => {
      setConfirmationType(confirmationType);
      setShowConfirmationDialog(true);
   };

   const onConfirmationDialogConfirm = (): void => {
      setShowConfirmationDialog(false);

      if (confirmationType === 'UpdatePaymentDate') {
         updatePaymentDate();
      } else if (confirmationType === 'CancelPayment') {
         cancelPayment();
      }
   };

   const onConfirmationDialogCancel = (): void => {
      setShowConfirmationDialog(false);
   };

   const updatePaymentDate = (): void => {
      dispatch(
         PaymentActions.updatePaymentDateRequest({
            paymentId: payment.paymentId,
            paymentDate: payment.paymentDate,
         })
      );
      setUpdatePaymentDateSubmitted(true);
   };

   const cancelPayment = (): void => {
      dispatch(
         PaymentActions.cancelPaymentRequest({
            paymentId: payment.paymentId,
            paymentDate: payment.paymentDate,
         })
      );
      setCancelPaymentSubmitted(true);
   };

   useEffect((): void => {
      if (
         updatePaymentDateSubmitted &&
         !isLoadingUpdatingPaymentDateStatus &&
         !isErrorUpdatingPaymentDateStatus &&
         updatingPaymentDateStatus !== undefined &&
         updatingPaymentDateStatus === true
      ) {
         toast.success('Payment Date has been updated.');
         setUpdatePaymentDateSubmitted(false);
      }

      if (
         updatePaymentDateSubmitted &&
         !isLoadingUpdatingPaymentDateStatus &&
         (isErrorUpdatingPaymentDateStatus ||
            (updatingPaymentDateStatus !== undefined &&
               updatingPaymentDateStatus === false))
      ) {
         toast.error('Failed to update Payment Date.');
         setUpdatePaymentDateSubmitted(false);
      }
   }, [
      updatePaymentDateSubmitted,
      updatingPaymentDateStatus,
      isLoadingUpdatingPaymentDateStatus,
      isErrorUpdatingPaymentDateStatus,
   ]);

   useEffect((): void => {
      if (
         cancelPaymentSubmitted &&
         !isLoadingCancellingPaymentStatus &&
         !isErrorCancellingPaymentStatus &&
         cancellingPaymentStatus !== undefined &&
         cancellingPaymentStatus === true
      ) {
         dispatch(
            PaymentActions.getPaymentDetailRequest(
               null,
               null,
               null,
               paymentDetail.payment.paymentId
            )
         );
         setInitiated(true);

         toast.success('Payment has been cancelled.');
         setCancelPaymentSubmitted(false);
      }

      if (
         cancelPaymentSubmitted &&
         !isLoadingCancellingPaymentStatus &&
         (isErrorCancellingPaymentStatus ||
            (cancellingPaymentStatus !== undefined &&
               cancellingPaymentStatus === false))
      ) {
         toast.error('Failed to cancel the payment.');
         setCancelPaymentSubmitted(false);
      }
   }, [
      cancelPaymentSubmitted,
      cancellingPaymentStatus,
      isLoadingCancellingPaymentStatus,
      isErrorCancellingPaymentStatus,
      dispatch,
      paymentDetail,
   ]);

   const showFeeRequestLineModal = (
      feeRequestId?: number,
      feeRequestLineId?: number
   ): void => {
      setEditFeeRequestLineModel({
         ...editFeeRequestLineModel,
         feeRequestId: feeRequestId,
         feeRequestLineId: feeRequestLineId,
      });
      setShowModal(true);
   };

   const onModalCancel = (): void => {
      setEditFeeRequestLineModel({
         ...DefaultFeeRequestLineDisplay,
      });
      setShowModal(false);
   };

   const onModalSave = (model: FeeRequestLineDisplay): void => {
      // get PaymentFeeRequestLineModel by Model
      setShowModal(false);

      if (model.feeRequestLineId && model.feeRequestLineId > 0) {
         setUpdateRequested(true);
         setUpdateRequestedId(model.feeRequestLineId);
      }
   };

   useEffect((): void => {
      if (updateRequestedId > 0) {
         dispatch(
            PaymentActions.getPaymentDetailFeeRequestLineRequest(
               updateRequestedId
            )
         );
      }
   }, [updateRequestedId, dispatch]);

   useEffect((): void => {
      if (
         updateRequested &&
         !isEqual(updatedFeeRequestLineLocalModel, updatedFeeRequestLine) &&
         updatedFeeRequestLine.feeRequestId &&
         updatedFeeRequestLine.feeRequestId > 0 &&
         updatedFeeRequestLine.feeRequestLineId &&
         updatedFeeRequestLine.feeRequestId > 0
      ) {
         const feeRequest = feeRequests.find(
            f => f.data.feeRequestId === updatedFeeRequestLine.feeRequestId
         );

         if (feeRequest) {
            const feeRequestLine = feeRequest.feeRequestLines.find(
               f =>
                  f.data.feeRequestLineId ===
                  updatedFeeRequestLine.feeRequestLineId
            );

            if (feeRequestLine) {
               feeRequestLine.data = updatedFeeRequestLine;

               const index = feeRequest.feeRequestLines.findIndex(
                  f =>
                     f.data.feeRequestLineId ===
                     updatedFeeRequestLine.feeRequestLineId
               );
               feeRequest.feeRequestLines[index] = feeRequestLine;
            } else {
               feeRequest.feeRequestLines.push({
                  data: updatedFeeRequestLine,
                  isChecked: isMultiPay,
               });
            }

            const index = feeRequests.findIndex(
               f => f.data.feeRequestId === updatedFeeRequestLine.feeRequestId
            );
            feeRequests[index] = feeRequest;

            const totalAmount = updateTotalAmount(
               feeRequests,
               payment,
               paymentDetail.isPaid
            );
            setPayment({ ...payment, amount: totalAmount });
            setFeeRequests([...feeRequests]);

            // sync with multi pay model on update
            if (isMultiPay) {
               dispatch(PaymentActions.addMultiPayFee(feeRequests, true));
            }

            setUpdateRequestedId(0);
            setUpdateRequested(false);
         }
      }
      setUpdatedFeeRequestLineLocalModel(updatedFeeRequestLine);
   }, [
      updatedFeeRequestLine,
      feeRequests,
      payment,
      paymentDetail.isPaid,
      updateRequested,
      updateRequestedId,
      updateTotalAmount,
      updatedFeeRequestLineLocalModel,
      isMultiPay,
      dispatch,
   ]);

   useEffect((): void => {
      if (isMultiPay && loadMultiPayList) {
         setMultiPayList();
         setLoadMultiPayList(false);
      } else if (loadMultiPayList) {
         setLoadMultiPayList(false);
      }
   }, [isMultiPay, multiPayDetail, loadMultiPayList, setMultiPayList]);

   const getReceipt = (paymentId: number, contactId?: number): void => {
      if (contactId) {
         dispatch(
            ReceiptActions.getReceiptRequest({
               contactId: contactId,
               paymentId: paymentId,
            })
         );
      } else {
         dispatch(ReceiptActions.getReceiptByPaymentIdRequest(paymentId));
      }
   };

   useEffect((): void => {
      if (
         (isDownloadedWithContactAndPayment || isDownloadedWithPayment) &&
         gettingReceiptSubmitted
      ) {
         const blob = new Blob([receipt], {
            type: 'application/pdf',
         });
         setGettingReceiptSubmitted(false);
         FileSaver.saveAs(blob, 'Receipt.pdf');
      }
   }, [
      isDownloadedWithContactAndPayment,
      isDownloadedWithPayment,
      receipt,
      gettingReceiptSubmitted,
   ]);

   return (
      <Loading tryReload={FN_EMPTY_VOID} isLoading={isLoading} isError={false}>
         <div className="payment-container">
            {!isNullOrWhiteSpace(returnUrl) && (
               <div className="md-cell md-cell--12">
                  <Link to={returnUrl}>
                     <Button
                        flat
                        primary
                        iconChildren="arrow_back"
                        className="btn-small"
                     >
                        Back
                     </Button>
                  </Link>
               </div>
            )}
            {feeRequests.length > 0 ? (
               <>
                  <DialogContainer
                     id="dialog-container-payment-feerequest-line-add-edit"
                     visible={showModal}
                     focusOnMount={false}
                     containFocus={false}
                     onHide={(): void => {
                        setShowModal(false);
                     }}
                     title="Add/Edit Fee Request Line"
                     contentClassName="md-grid"
                     lastChild
                     disableScrollLocking
                     renderNode={document.body}
                     className="fee-request-line-dialog"
                  >
                     <FeeRequestLine
                        onCancel={onModalCancel}
                        onSave={onModalSave}
                        model={editFeeRequestLineModel}
                        editMode={
                           editFeeRequestLineModel.feeRequestLineId &&
                           editFeeRequestLineModel.feeRequestLineId > 0
                              ? true
                              : false
                        }
                        saveOnClose
                     />
                  </DialogContainer>

                  <ExpansionList className="md-cell md-cell--12">
                     <ExpansionPanel
                        label={`${
                           isMultiPay && !paymentDetail.isPaid
                              ? 'Multi-Pay'
                              : isMultiPay && paymentDetail.isPaid
                              ? 'Payment Detail - Multi-Pay'
                              : paymentDetail.isCancelled &&
                                !paymentDetail.isPaid
                              ? 'Cancelled Fee Request'
                              : paymentDetail.isCancelled &&
                                paymentDetail.isPaid
                              ? 'Cancelled Payment'
                              : !paymentDetail.isPaid && paymentDetail.contactId
                              ? 'Would you like to make a payment for:'
                              : 'Payment Detail'
                        }`}
                        footer={null}
                        expanded
                        expanderIcon={<></>}
                        onExpandToggle={FN_EMPTY_VOID}
                     >
                        {paymentDetail &&
                        paymentDetail.contactId &&
                        !isMultiPay ? (
                           <div className="md-grid md-cell--12">
                              <UserCard />
                           </div>
                        ) : null}
                        {paymentDetail &&
                        !paymentDetail.isPaid &&
                        paymentDetail.isCancelled ? null : (
                           <PaymentDetail
                              isPaid={paymentDetail.isPaid}
                              isCancelled={paymentDetail.isCancelled}
                              payment={payment}
                              updateModel={updateModel}
                              validationResult={validationResult}
                              canUpdatePaymentDate={canBackOfficeFinanceUpdate}
                           />
                        )}
                     </ExpansionPanel>
                  </ExpansionList>
                  {!paymentDetail.isPaid &&
                     !paymentDetail.isCancelled &&
                     !isMultiPay && (
                        <div className="md-grid md-cell--12 create-fee-request-container">
                           <Link
                              to={{
                                 pathname: `/fees-payments/create-fee-request/${paymentDetail &&
                                    paymentDetail.contactId}`,
                                 state: {
                                    returnUrl: `${location.pathname}${location.search}`,
                                 },
                              }}
                              className="link-create-fee-request"
                           >
                              <FaIcon icon="plus" className="small-icon" />
                              Create New Fee Request
                           </Link>
                        </div>
                     )}
                  <div className="md-grid md-cell--12">
                     <FeeRequestTable
                        isPaid={paymentDetail.isPaid}
                        isCancelled={paymentDetail.isCancelled}
                        payment={payment}
                        feeRequests={feeRequests}
                        showHideFeeRequestLine={showHideFeeRequestLine}
                        checkFeeRequest={checkFeeRequest}
                        checkFeeRequestLine={checkFeeRequestLine}
                        returnUrl={returnUrl}
                        showFeeRequestLineModal={showFeeRequestLineModal}
                        isMultiPay={isMultiPay}
                        removeFeeRequest={(feeRequestId: number): void => {
                           dispatch(
                              PaymentActions.removeMultiPayFee(feeRequestId)
                           );
                           setLoadMultiPayList(true);
                        }}
                        removeFeeRequestLine={(
                           feeRequestId: number,
                           feeRequestLineId: number
                        ): void => {
                           dispatch(
                              PaymentActions.removeMultiPayFeeLine(
                                 feeRequestId,
                                 feeRequestLineId
                              )
                           );
                           setLoadMultiPayList(true);
                        }}
                     />
                  </div>
                  <ConfirmationDialog
                     id="payment-detail-confirmation"
                     onConfirm={onConfirmationDialogConfirm}
                     onCancel={onConfirmationDialogCancel}
                     visible={showConfirmationDialog}
                  >
                     <>
                        {confirmationType === 'UpdatePaymentDate'
                           ? confirmationTextForUpdatePaymentDate
                           : confirmationTextForCancelPayment}
                     </>
                  </ConfirmationDialog>
                  <div className="md-cell md-cell--12 control-panel">
                     {!paymentDetail.isCancelled &&
                     paymentDetail.isPaid &&
                     canReadInvoice ? (
                        <LoadingButton
                           isLoading={isDownloading}
                           flat
                           primary
                           swapTheming
                           className="right"
                           onClick={(): void => {
                              setGettingReceiptSubmitted(true);
                              getReceipt(
                                 paymentDetail.payment.paymentId,
                                 paymentDetail.contactId
                              );
                           }}
                           onKeyUp={(keyPress): void => {
                              if (!isEnterKeyPress(keyPress)) return;
                              setGettingReceiptSubmitted(true);
                              getReceipt(
                                 paymentDetail.payment.paymentId,
                                 paymentDetail.contactId
                              );
                           }}
                        >
                           Download Receipt
                        </LoadingButton>
                     ) : null}

                     {!paymentDetail.isCancelled ? (
                        <>
                           {!paymentDetail.isPaid ? (
                              <>
                                 {!isMultiPay && multiPayDetail.length > 0 && (
                                    <Link
                                       to={`${multiPayUrl}?returnUrl=${
                                          location.pathname
                                       }${
                                          // get the while search string except return url
                                          location.search.replace(
                                             /&returnUrl.*/,
                                             ''
                                          )
                                       }`}
                                    >
                                       <Button
                                          flat
                                          swapTheming
                                          className="btn-add-to-multi-pay"
                                       >
                                          <FaIcon
                                             icon="shopping-cart"
                                             className="small-icon white"
                                          />
                                          View Multi-Pay
                                       </Button>
                                    </Link>
                                 )}
                                 {!isMultiPay && (
                                    <Button
                                       flat
                                       secondary
                                       swapTheming
                                       onClick={(): void => addToMultiPay()}
                                       onKeyUp={(keyPress): void => {
                                          if (isEnterKeyPress(keyPress))
                                             addToMultiPay();
                                       }}
                                       disabled={
                                          getFeeRequestLineSelectedCount() === 0
                                       }
                                       className="btn-add-to-multi-pay"
                                    >
                                       <FaIcon
                                          icon="cart-plus"
                                          className="small-icon white"
                                       />
                                       Add to Multi-Pay
                                    </Button>
                                 )}
                                 {canBackOfficeFinanceCreate && (
                                    <LoadingButton
                                       flat
                                       primary
                                       swapTheming
                                       onClick={(): void => proceedPayment()}
                                       onKeyUp={(keyPress): void => {
                                          if (isEnterKeyPress(keyPress))
                                             proceedPayment();
                                       }}
                                       disabled={
                                          isNullOrWhiteSpace(
                                             payment.paymentMethod
                                          ) ||
                                          payment.paymentMethod === 'POLI' ||
                                          (!isMultiPay &&
                                             getSelectedCount() === 0)
                                       }
                                       isLoading={processingPayment}
                                    >
                                       {payment.paymentMethod ===
                                       CREDIT_CARD_STAFF
                                          ? 'Proceed to Payment Gateway'
                                          : 'Proceed to Payment'}
                                    </LoadingButton>
                                 )}
                              </>
                           ) : (
                              <>
                                 {canBackOfficeFinanceUpdate && (
                                    <LoadingButton
                                       flat
                                       primary
                                       swapTheming
                                       onClick={(): void => {
                                          onConfirm('UpdatePaymentDate');
                                       }}
                                       onKeyUp={(keyPress): void => {
                                          if (isEnterKeyPress(keyPress))
                                             onConfirm('UpdatePaymentDate');
                                       }}
                                       isLoading={
                                          isLoadingUpdatingPaymentDateStatus
                                       }
                                    >
                                       Update Payment Date
                                    </LoadingButton>
                                 )}
                                 {canCancelPayment && (
                                    <LoadingButton
                                       flat
                                       primary
                                       swapTheming
                                       onClick={(): void => {
                                          onConfirm('CancelPayment');
                                       }}
                                       onKeyUp={(keyPress): void => {
                                          if (isEnterKeyPress(keyPress))
                                             onConfirm('CancelPayment');
                                       }}
                                       isLoading={
                                          isLoadingCancellingPaymentStatus
                                       }
                                    >
                                       Cancel Payment
                                    </LoadingButton>
                                 )}
                              </>
                           )}
                        </>
                     ) : null}
                  </div>
               </>
            ) : (
               <ExpansionList className="md-cell md-cell--12">
                  <ExpansionPanel
                     label={
                        isMultiPay
                           ? 'No Multi-Pay Fee Request Found'
                           : 'No Fee Request Found'
                     }
                     footer={null}
                     expanded
                     expanderIcon={<></>}
                     onExpandToggle={FN_EMPTY_VOID}
                  >
                     <div className="md-grid md-cell--12">
                        <div className="md-cell md-cell--12">
                           The fee requests for the requested contact or
                           application have not been found. <br />
                           <br />
                           Please try again by selecting one of the other
                           applications or using fee request search
                           functionality.
                        </div>
                     </div>
                  </ExpansionPanel>
               </ExpansionList>
            )}

            <DialogContainer
               id="simple-list-dialog"
               visible={showReceivedDateModal}
               className={'edit-dialog'}
               title="Application Received"
               onHide={(): void => {
                  //updateShowModal(false);
               }}
               width={600}
               portal
            >
               <div className="md-grid md-cell--12 system-parameter-modal">
                  <div className="md-grid md-cell--12 grey-background form-section">
                     <h3 className="md-cell md-cell--12">Date Received</h3>
                     <DateInput
                        id="end-date"
                        className="md-cell--12 form-input"
                        errorText="Please select a valid end date"
                        value={receivedDate}
                        onChange={(val): void => {
                           setReceivedDate(val);
                        }}
                        maxDate={new Date()}
                        strict
                     />
                  </div>
                  <Button
                     disabled={
                        !(
                           receivedDate === '' ||
                           (isValidDateBeforeNow(receivedDate) &&
                              (receivedDate.length === 10 ||
                                 (receivedDate.length &&
                                    !receivedDate.startsWith('0'))))
                        )
                     }
                     onClick={(): void => {
                        if (receivedDate === '') {
                           updateShowModal(false);
                           return;
                        }

                        dispatch(
                           ApplicationActions.updateApplicationReceivedDateRequest(
                              {
                                 paymentId: Number(processedPaymentId),
                                 date: receivedDate,
                              }
                           )
                        );
                        updateShowModal(false);
                     }}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        if (receivedDate === '') {
                           updateShowModal(false);
                           return;
                        }

                        dispatch(
                           ApplicationActions.updateApplicationReceivedDateRequest(
                              {
                                 paymentId: Number(processedPaymentId),
                                 date: receivedDate,
                              }
                           )
                        );
                        updateShowModal(false);
                     }}
                     flat
                     primary
                     swapTheming
                  >
                     Continue
                  </Button>
               </div>
            </DialogContainer>
         </div>
      </Loading>
   );
};

export default React.memo(Payment);
