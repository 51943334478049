import {
   ApplicationInsights,
   ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
//eslint-disable-next-line
const createTelemetryService = (): any => {
   let reactPlugin = null;
   let appInsights = null;
   /**
    * Initialize the Application Insights class
    * @param {string} instrumentationKey - Application Insights Instrumentation Key
    * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
    * @return {void}
    */
   const LOG_LEVEL_DEFAULT = 0; // OFF
   const PAGE_TITLE = 'PGDB Administrator Portal';

   const telemetryInitializer = (envelope: ITelemetryItem): void => {
      if (envelope.baseData && envelope.baseData.name) {
         // override the page titel to group all the admin telemetries
         envelope.baseData.name = PAGE_TITLE;
      }
   };

   //eslint-disable-next-line
   const initialize = (instrumentationKey: any, browserHistory: any): any => {
      if (!browserHistory) {
         throw new Error(
            'Could not initialize Telemetry Service - Browser history not found'
         );
      }
      reactPlugin = new ReactPlugin();
      appInsights = new ApplicationInsights({
         config: {
            instrumentationKey: instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            disableCorrelationHeaders: false,
            disableAjaxTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            distributedTracingMode: 0, // AI Tracking mode

            extensions: [reactPlugin],
            correlationHeaderExcludedDomains: process.env
               .REACT_APP_INSIGHTS_EXCLUSION_DOMAINS
               ? process.env.REACT_APP_INSIGHTS_EXCLUSION_DOMAINS.split(',')
               : [],
            loggingLevelConsole: process.env
               .REACT_APP_INSIGHTS_LOG_LEVEL_CONSOLE
               ? Number(process.env.REACT_APP_INSIGHTS_LOG_LEVEL_CONSOLE)
               : LOG_LEVEL_DEFAULT,
            loggingLevelTelemetry: process.env
               .REACT_APP_INSIGHTS_LOG_LEVEL_TELEMETRY
               ? Number(process.env.REACT_APP_INSIGHTS_LOG_LEVEL_TELEMETRY)
               : LOG_LEVEL_DEFAULT,
            extensionConfig: {
               [reactPlugin.identifier]: {
                  history: browserHistory,
               },
            },
         },
      });
      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer(telemetryInitializer);
      return appInsights;
   };
   return { reactPlugin, appInsights, initialize };
};
export const ai = createTelemetryService();
