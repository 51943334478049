import React, { useEffect, useState, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import ContactSearchPanel from './Panels/ContactSearchPanel';
import ContactSearchResultsPanel from './Panels/ContactSearchResultsPanel';
import ContactSearchResult from 'Models/Contact/Data/ContactSearchResult';
import {
   ContactSearchQuery,
   DefaultSearchModel,
   isValidSearchQuery,
   filterToValidQuery,
} from 'Models/Contact/Dto/ContactSearchQuery';
import { isValidSearchLicenceNumber } from 'Util/Helpers/Search';
import { DefaultPagingModel, PagedQuery } from 'Models/Other/PagedQuery';
import { isEqual } from 'lodash-es';
import { isValidSearchName } from 'Util/Helpers/ContactSearch';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import {
   selectSearchResults,
   ContactTypes,
   ContactActions,
} from 'State/Redux/ContactRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const DEFAULT_PAGING: PagedQuery = {
   ...DefaultPagingModel,
   orderBy: 'fullName',
};

const ContactCompanySearch = (): JSX.Element => {
   const searchResults = useSelector(selectSearchResults);
   const isSuccess = useSelector(
      selectIsSuccess([ContactTypes.SEARCH_CONTACT_REQUEST])
   );
   const dispatch = useDispatch();
   const location = useLocation();

   const [paging, setPaging] = useState(DefaultPagingModel);
   const [searchedPaging, setSearchedPaging] = useState(DefaultPagingModel);
   const updatePaging = useCallback(
      (updatedPaging: Readonly<PagedQuery>): void => {
         if (!isEqual(searchedPaging, updatedPaging)) setPaging(updatedPaging);
      },
      [searchedPaging]
   );

   const [searchModel, setSearchModel] = useState(DefaultSearchModel);
   const updateSearchModel = useCallback(
      (searchModel: ContactSearchQuery): void => setSearchModel(searchModel),
      []
   );
   const [searchedModel, setSearchedModel] = useState(DefaultSearchModel);

   const [hasSearched, setHasSearched] = useState(false);
   const [
      localSearchResults,
      setSearchResults,
   ] = useState<ContactSearchResult | null>(null);
   const [searchButtonClicked, setSearchButtonClicked] = useState(false);
   const updateSearchButtonClicked = useCallback(
      (isClicked: boolean): void => setSearchButtonClicked(isClicked),
      []
   );

   // transform the 'quick' search into a regular 'complex' search
   const quickSearchQuery =
      location.state && location.state.quickSearchQuery
         ? location.state.quickSearchQuery
         : '';
   if (quickSearchQuery) {
      if (isValidSearchLicenceNumber(quickSearchQuery)) {
         setSearchModel({
            ...DefaultSearchModel,
            licenceNumber: quickSearchQuery,
         });
      } else if (isValidSearchName(quickSearchQuery)) {
         setSearchModel({ ...DefaultSearchModel, name: quickSearchQuery });
      }
      location.state = { quickSearchQuery: '' };
      setSearchButtonClicked(true);
   }

   const searchContacts = (): void => {
      const updatedPagingQuery = filterToValidQuery({
         ...searchedModel,
         ...paging,
      });
      const newQuery = filterToValidQuery({
         ...searchModel,
         ...DEFAULT_PAGING,
      });

      if (
         searchButtonClicked &&
         isValidSearchQuery(newQuery) &&
         !isEqual(searchedModel, newQuery)
      ) {
         dispatch(ContactActions.searchContactRequest(newQuery));
         setHasSearched(true);
         setPaging(DEFAULT_PAGING);
         setSearchedPaging(DEFAULT_PAGING);
         setSearchedModel(newQuery);
         setSearchResults(null);
      } else if (
         !isEqual(searchedPaging, paging) &&
         isValidSearchQuery(updatedPagingQuery)
      ) {
         dispatch(
            ContactActions.searchContactRequest({ ...searchedModel, ...paging })
         );
         setHasSearched(true);
         setSearchedPaging(paging);
      }
      setSearchButtonClicked(false);
   };

   useEffect(searchContacts, [
      searchButtonClicked,
      dispatch,
      searchModel,
      searchedModel,
      paging,
      searchedPaging,
   ]);

   const reset = useCallback((): void => {
      setHasSearched(false);
      setSearchButtonClicked(false);
      setSearchResults(null);
      setPaging(DEFAULT_PAGING);
      setSearchedPaging(DEFAULT_PAGING);
      setSearchModel(DefaultSearchModel);
      setSearchedModel(DefaultSearchModel);
   }, []);

   const hasResults =
      isSuccess && hasSearched && searchResults !== localSearchResults;
   if (hasResults) setSearchResults(searchResults);

   const [resultsPanel, setResultsPanel] = useState<JSX.Element>(<></>);
   useEffect((): void => {
      if (!hasSearched) {
         setResultsPanel(<></>);
         return;
      }

      const totalCount =
         searchResults && searchResults.totalCount
            ? searchResults.totalCount
            : 0;
      const panelTitle = searchResults ? `Results (${totalCount})` : 'Results';
      const resultPanel = (
         <ExpansionPanel
            label={panelTitle}
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <ContactSearchResultsPanel
               searchResults={localSearchResults}
               paging={paging}
               onPagingChanged={updatePaging}
            />
         </ExpansionPanel>
      );

      setResultsPanel(resultPanel);
   }, [hasSearched, localSearchResults, updatePaging, paging, searchResults]);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Contact/Company Search"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <ContactSearchPanel
               query={searchModel}
               setQuery={updateSearchModel}
               isSearching={hasSearched}
               setIsSearching={updateSearchButtonClicked}
               reset={reset}
            />
         </ExpansionPanel>
         {resultsPanel}
      </ExpansionList>
   );
};

export default ContactCompanySearch;
