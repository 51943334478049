import React from 'react';
import Moment from 'moment-timezone';
import './LicenceCard.scss';
import { LicenceStatus } from './LicenceStatus';
import { LicenceTitle } from './LicenceTitle';
import {
   getColorForLicences,
   getIconPath,
   getIconForStatus,
} from 'Util/Helpers/LicenceCard';
import { NOT_RENEWED } from 'Util/Constants/LicenceCard';
import {
   NZ_TIMEZONE,
   DISPLAY_DATE_FORMAT,
   YEAR_FORMAT,
} from 'Util/Constants/Common';

interface LicenceCardProps {
   level: string;
   discipline: string;
   status: string;
   expiryDate: string;
}

const LicenceCard = (
   {
      level, // e.g. Certifying
      discipline, // e.g. Plumber
      status,
      expiryDate,
   }: LicenceCardProps // e.g. 'Active', 'Not Renewed', 'Pending' etc
): JSX.Element => {
   const icon = getIconForStatus(status);
   const iconPath = getIconPath(status, discipline);
   const color = getColorForLicences(status, discipline);

   const disabled = status === NOT_RENEWED;
   const opacityModifier = disabled ? ' opacity-01' : '';

   const cardClasses = `licence-card ${color}-licence`;
   const imageClass = `card-image${opacityModifier}`;

   return (
      <div className="col l4">
         <div className={cardClasses}>
            <img
               alt={`Plumber Licence Card`}
               className={imageClass}
               src={iconPath}
            />
            <LicenceTitle level={level} discipline={discipline} />
            <p className="year">
               {Moment(expiryDate)
                  .tz(NZ_TIMEZONE)
                  .subtract(1, 'years')
                  .format(YEAR_FORMAT)}
            </p>
            <LicenceStatus status={status} iconName={icon} />
            {status !== NOT_RENEWED && (
               <p className="date">
                  {Moment(expiryDate)
                     .tz(NZ_TIMEZONE)
                     .format(DISPLAY_DATE_FORMAT)}
               </p>
            )}
         </div>
      </div>
   );
};

export default LicenceCard;
