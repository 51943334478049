import apisauce from 'apisauce';
import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import ContactExamEligibilityDto from 'Models/Examination/Dto/ContactExamEligibilityDto';
import ContactExamEligibilityOtherNotesDtoReadonly from 'Models/Examination/Data/ContactExamEligibilityOtherNotesDto';
import { withAccessToken, createApi } from '.';

export interface ExaminationServiceType {
   getExaminationEligibilityByContactId(
      contactId: string
   ): Promise<ApiResponse<readonly ContactExamEligibilityDto[]>>;

   updateContactExaminationEligibility(
      contactId: string,
      examinationEligibility: ContactExamEligibilityDto[]
   ): Promise<ApiResponse<readonly ContactExamEligibilityDto[]>>;

   getExaminationEligibilityOtherNotesByContactId(
      contactId: string
   ): Promise<
      ApiResponse<readonly ContactExamEligibilityOtherNotesDtoReadonly[]>
   >;

   updateContactExaminationEligibilityOtherNotes(
      contactExamEligibilityOtherNotes: ContactExamEligibilityOtherNotesDtoReadonly[]
   ): Promise<
      ApiResponse<readonly ContactExamEligibilityOtherNotesDtoReadonly[]>
   >;

   getUserExaminationEligibilityReportByContactId(
      contactId: string
   ): Promise<ApiResponse<Blob>>;

   sendExaminationEligibilityReportAspeq(
      contactId: string
   ): Promise<ApiResponse<boolean>>;

   sendExaminationEligibilityReportUser(
      contactId: string
   ): Promise<ApiResponse<boolean>>;
}

const create = (baseURL: string | undefined): ExaminationServiceType => {
   const api = createApi({ baseURL });
   const blobApi = apisauce.create({ baseURL, responseType: 'blob' });

   const getExaminationEligibilityByContactId = (
      contactId: string
   ): Promise<ApiResponse<ContactExamEligibilityDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(
         `/Examination/GetExaminationEligibilityByContactId/${contactId}`
      );
   };

   const updateContactExaminationEligibility = (
      contactId: string,
      data: ContactExamEligibilityDto[]
   ): Promise<ApiResponse<ContactExamEligibilityDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(
         `/Examination/UpdateContactExaminationEligibility/${contactId}`,
         data
      );
   };

   const getExaminationEligibilityOtherNotesByContactId = (
      contactId: string
   ): Promise<ApiResponse<ContactExamEligibilityOtherNotesDtoReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(
         `/Examination/GetExaminationEligibilityOtherNotesByContactId/${contactId}`
      );
   };

   const updateContactExaminationEligibilityOtherNotes = (
      data: ContactExamEligibilityOtherNotesDtoReadonly[]
   ): Promise<ApiResponse<ContactExamEligibilityOtherNotesDtoReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(
         `/Examination/UpdateContactExaminationEligibilityOtherNotes`,
         data
      );
   };

   const getUserExaminationEligibilityReportByContactId = (
      contactId: string
   ): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.get(
         `/Examination/GetUserExamEligibilityReportByContactId/${contactId}`
      );
   };

   const sendExaminationEligibilityReportAspeq = (
      contactId: string
   ): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.post(
         `/Examination/SendExaminationEligibilityReport/${contactId}?sendToUser=false`
      );
   };

   const sendExaminationEligibilityReportUser = (
      contactId: string
   ): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.post(
         `/Examination/SendExaminationEligibilityReport/${contactId}?sendToUser=true`
      );
   };

   return {
      getExaminationEligibilityByContactId,
      updateContactExaminationEligibility,
      getExaminationEligibilityOtherNotesByContactId,
      updateContactExaminationEligibilityOtherNotes,
      getUserExaminationEligibilityReportByContactId,
      sendExaminationEligibilityReportAspeq,
      sendExaminationEligibilityReportUser,
   };
};

export default {
   create,
};
