import React from 'react';
import { Button } from 'react-md/lib/Buttons';
import './style.scss';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { Link } from 'react-router-dom';

interface LoadingProps {
   tryReload: () => void;
   children: JSX.Element;
   isLoading: boolean;
   isError: boolean;
   overlayOnChildren?: boolean;
}

const Loading = ({
   tryReload,
   children,
   isLoading,
   isError,
   overlayOnChildren,
}: Readonly<LoadingProps>): JSX.Element => {
   if (isError) {
      return (
         <div className="loading-wrapper-main">
            <div className="loading-wrapper-content">
               <div className="loading-wrapper-oops-text">
                  Oops! Loading failed.
               </div>
               <div className="buttons-group">
                  <Button
                     flat
                     primary
                     swapTheming
                     onClick={tryReload}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) tryReload();
                     }}
                  >
                     Reload
                  </Button>
                  <Link to="/logout">
                     <Button flat primary swapTheming>
                        Logout
                     </Button>
                  </Link>
               </div>
            </div>
         </div>
      );
   }
   return isLoading ? (
      overlayOnChildren ? (
         <div className="loading-overlay-container">
            {children}
            <div className="loading-overlay-animator"></div>
         </div>
      ) : (
         <div className="loading-wrapper-main">
            <div className="loading-wrapper-content">
               <img src="/images/loading.svg" alt="loading" />
            </div>
         </div>
      )
   ) : (
      children
   );
};
export default Loading;
