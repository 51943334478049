interface ContactLimitedDto {
   contactId: number;
   registrationNumber: number | null;
   firstName: string;
   lastName: string;
   fullName: string;
   companyName: string;
}

type ContactLimitedDtoReadonly = Readonly<ContactLimitedDto>;

export default ContactLimitedDtoReadonly;

export const DefaultContactLimitedDtoModel: ContactLimitedDto = {
   contactId: 0,
   registrationNumber: null,
   firstName: '',
   lastName: '',
   fullName: '',
   companyName: '',
};
