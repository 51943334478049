import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import ResultDto from 'Models/Examination/Dto/ResultDto';
import ResultStatusDtoReadonly from 'Models/Examination/Dto/ResultStatusDto';
import { withAccessToken, createApi } from '.';
import { ResultSearchQuery } from 'Models/Examination/Data/ResultSearchQuery';
import ResultSearchResultReadOnly from 'Models/Examination/Data/ResultSearchResult';
import ResultSearchResultDtoReadOnly from 'Models/Examination/Dto/ResultSearchResultDto';
import ResultDtoReadonly from 'Models/Examination/Dto/ResultDto';

export interface ResultServiceType {
   getResultsByContactId(
      contactId: string
   ): Promise<ApiResponse<readonly ResultDto[]>>;

   getResultStatusByContactId(
      contactId: string
   ): Promise<ApiResponse<ResultStatusDtoReadonly>>;

   updateResult(result: ResultDto): Promise<ApiResponse<Readonly<ResultDto>>>;

   updateResultBySearchResult(
      data: ResultSearchResultDtoReadOnly
   ): Promise<ApiResponse<ResultSearchResultDtoReadOnly>>;

   searchResult(
      query: ResultSearchQuery
   ): Promise<ApiResponse<readonly ResultSearchResultReadOnly[]>>;

   saveValidatedResults(
      results: ResultDto[]
   ): Promise<ApiResponse<ResultDtoReadonly[]>>;
}

const create = (baseURL: string | undefined): ResultServiceType => {
   const api = createApi({ baseURL });

   const getResultsByContactId = (
      contactId: string
   ): Promise<ApiResponse<ResultDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Result/GetResultsByContactId/${contactId}`);
   };

   const getResultStatusByContactId = (
      contactId: string
   ): Promise<ApiResponse<ResultStatusDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Result/GetResultStatusByContactId/${contactId}`);
   };

   const updateResult = (data: ResultDto): Promise<ApiResponse<ResultDto>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Result`, data);
   };

   const updateResultBySearchResult = (
      data: ResultSearchResultDtoReadOnly
   ): Promise<ApiResponse<ResultSearchResultDtoReadOnly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put('/Result/BySearchResult', data);
   };

   const searchResult = (
      query: ResultSearchQuery
   ): Promise<ApiResponse<ResultSearchResultReadOnly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Result/Search', query);
   };

   const saveValidatedResults = (
      results: ResultDto[]
   ): Promise<ApiResponse<ResultDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Result/AddResults', results);
   };

   return {
      getResultsByContactId,
      getResultStatusByContactId,
      updateResult,
      updateResultBySearchResult,
      searchResult,
      saveValidatedResults,
   };
};

export default {
   create,
};
