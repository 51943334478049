import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import CourseCriteriaDto from 'Models/Examination/Dto/CourseCriteriaDto';
import { groupBy } from 'lodash-es';
import { CRITERIA_NUMBERS } from 'Util/Constants/Examination';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import CourseDtoReadonly from 'Models/Examination/Dto/CourseDto';
import CourseCreateErrorDtoReadonly from 'Models/Examination/Dto/CourseCreateErrorDto';
import CourseViewDtoReadonly from 'Models/Examination/Dto/CourseViewDto';
import produce from 'immer';

/* ------------- Interfaces for ReduxSauce ------------- */
interface CourseState {
   allCourses: readonly CourseViewDtoReadonly[];
   courseCriteria: CourseCriteriaDto[];
   searchedCourse: CourseDtoReadonly | null;
   courseNumbers: string[];
   course: CourseDtoReadonly | null;
   createError: CourseCreateErrorDtoReadonly | null;
}

export type CourseStateReadonly = Readonly<CourseState>;

interface TypeNames {
   GET_ALL_COURSES_REQUEST: string;
   GET_ALL_COURSES_SUCCESS: string;
   GET_ALL_COURSES_FAILURE: string;

   GET_COURSE_CRITERIA_REQUEST: string;
   GET_COURSE_CRITERIA_SUCCESS: string;
   GET_COURSE_CRITERIA_FAILURE: string;

   GET_COURSE_BY_COURSE_NUMBER_REQUEST: string;
   GET_COURSE_BY_COURSE_NUMBER_SUCCESS: string;
   GET_COURSE_BY_COURSE_NUMBER_FAILURE: string;

   GET_COURSE_NUMBERS_REQUEST: string;
   GET_COURSE_NUMBERS_SUCCESS: string;
   GET_COURSE_NUMBERS_FAILURE: string;

   SAVE_COURSE_REQUEST: string;
   SAVE_COURSE_SUCCESS: string;
   SAVE_COURSE_FAILURE: string;

   EDIT_COURSE_REQUEST: string;
   EDIT_COURSE_SUCCESS: string;
   EDIT_COURSE_FAILURE: string;

   RESET_SEARCHED_COURSE: string;
}

type CourseTypeNames = Readonly<TypeNames>;

export interface CourseCreators {
   getAllCoursesRequest: () => AnyAction;
   getAllCoursesSuccess: (
      data: CourseViewDtoReadonly[] | undefined
   ) => AnyAction;
   getAllCoursesFailure: (error: ApiError) => AnyAction;

   getCourseCriteriaRequest: () => AnyAction;
   getCourseCriteriaSuccess: (
      data: CourseCriteriaDto[] | undefined
   ) => AnyAction;
   getCourseCriteriaFailure: (error: ApiError) => AnyAction;

   getCourseByCourseNumberRequest: (courseNumber: string) => AnyAction;
   getCourseByCourseNumberSuccess: (
      data: CourseDtoReadonly | undefined
   ) => AnyAction;
   getCourseByCourseNumberFailure: (error: ApiError) => AnyAction;

   getCourseNumbersRequest: () => AnyAction;
   getCourseNumbersSuccess: (data: string[] | undefined) => AnyAction;
   getCourseNumbersFailure: (error: ApiError) => AnyAction;

   resetSearchedCourse: () => AnyAction;

   saveCourseRequest: (course: CourseDtoReadonly) => AnyAction;
   saveCourseSuccess: (data: CourseDtoReadonly | undefined) => AnyAction;
   saveCourseFailure: (error: CourseCreateErrorDtoReadonly) => AnyAction;

   editCourseRequest: (course: CourseDtoReadonly) => AnyAction;
   editCourseSuccess: (data: CourseDtoReadonly | undefined) => AnyAction;
   editCourseFailure: (error: CourseCreateErrorDtoReadonly) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: CourseStateReadonly = {
   allCourses: [],
   courseCriteria: [],
   searchedCourse: null,
   courseNumbers: [],
   course: null,
   createError: null,
};

/* ------------- Reducers ------------- */
const getAllCoursesSuccess = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return { ...state, allCourses: action.data };
};

const getCourseCriteriaSuccess = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return { ...state, courseCriteria: action.data };
};

const getCourseByCourseNumberSuccess = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return { ...state, searchedCourse: action.data };
};

const getCourseNumbersSuccess = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return { ...state, courseNumbers: action.data };
};

const resetSearchedCourse = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return { ...state, searchedCourse: null };
};

const saveCourseSuccess = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return { ...state, course: action.data };
};

const saveCourseFailure = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return {
      ...state,
      createError: action.error as CourseCreateErrorDtoReadonly,
   };
};

const editCourseSuccess = (
   state: CourseStateReadonly,
   action: AnyAction
): CourseStateReadonly => {
   return produce(state, (draftState): void => {
      const course = action.data;
      if (draftState.allCourses) {
         const courseFound = draftState.allCourses.find(c => {
            return c.courseId === course.courseId;
         });
         if (courseFound) {
            const index = draftState.allCourses.indexOf(courseFound);
            draftState.allCourses[index] = {
               ...action.data,
            };
         }
      }
   });
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   CourseTypeNames,
   CourseCreators
>('course', {
   getAllCoursesRequest: [],
   getAllCoursesSuccess: ['data'],
   getAllCoursesFailure: ['error'],

   getCourseCriteriaRequest: [],
   getCourseCriteriaSuccess: ['data'],
   getCourseCriteriaFailure: ['error'],

   getCourseByCourseNumberRequest: ['courseNumber'],
   getCourseByCourseNumberSuccess: ['data'],
   getCourseByCourseNumberFailure: ['error'],

   getCourseNumbersRequest: [],
   getCourseNumbersSuccess: ['data'],
   getCourseNumbersFailure: ['error'],

   resetSearchedCourse: [],

   saveCourseRequest: ['course'],
   saveCourseSuccess: ['data'],
   saveCourseFailure: ['error'],

   editCourseRequest: ['course'],
   editCourseSuccess: ['data'],
   editCourseFailure: ['error'],
});

export const CourseTypes = Types;
export const CourseActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_ALL_COURSES_SUCCESS]: getAllCoursesSuccess,
   [Types.GET_COURSE_CRITERIA_SUCCESS]: getCourseCriteriaSuccess,
   [Types.GET_COURSE_BY_COURSE_NUMBER_SUCCESS]: getCourseByCourseNumberSuccess,
   [Types.GET_COURSE_NUMBERS_SUCCESS]: getCourseNumbersSuccess,
   [Types.RESET_SEARCHED_COURSE]: resetSearchedCourse,
   [Types.SAVE_COURSE_SUCCESS]: saveCourseSuccess,
   [Types.SAVE_COURSE_FAILURE]: saveCourseFailure,
   [Types.EDIT_COURSE_SUCCESS]: editCourseSuccess,
});

/* ------------- Selectors ------------- */
export const selectAllCourses = (
   state: StateReadonly
): readonly CourseViewDtoReadonly[] => {
   return state.course.allCourses;
};

export const selectCourseCriteria = (
   state: StateReadonly
): CourseCriteriaDto[] => {
   return state.course.courseCriteria;
};

export const selectOrderedAndGroupedCourseCriteria = (
   state: StateReadonly
): Record<CRITERIA_NUMBERS, CourseCriteriaDto[]> | null => {
   if (!state.course || !state.course.courseCriteria) return null;
   return groupBy(state.course.courseCriteria, (c): string =>
      c.course ? c.course.courseNumber || '0' : '0'
   ) as Record<CRITERIA_NUMBERS, CourseCriteriaDto[]>;
};

export const selectSearchedCourse = (
   state: StateReadonly
): CourseDtoReadonly | null => {
   return state.course.searchedCourse;
};

export const selectCourseNumbers = (state: StateReadonly): string[] => {
   return state.course.courseNumbers;
};

export const selectCourse = (
   state: StateReadonly
): CourseDtoReadonly | null => {
   return state.course.course;
};

export const selectCreateCourseError = (
   state: StateReadonly
): CourseCreateErrorDtoReadonly | null => state.course.createError;
