import { put, call } from 'redux-saga/effects';
import { ApplicationActions, File } from '../Redux/ApplicationRedux';
import {
   ApplicationServiceType,
   SupervisorRequest,
} from '../Services/ApplicationService';
import { ApiResponse } from 'apisauce';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import { ApplicationCreate } from 'Models/Application/Data/ApplicationCreate';
import PendingApplicationResponse from 'Models/Application/Data/PendingApplicationResponse';
import ApplicationDtoReadOnly from 'Models/Application/Dto/ApplicationDto';
import { ApplicationCreateError } from 'Models/Application/Data/ApplicationCreateError';
import { ApplicationSupervisor } from 'Models/Application/Data/ApplicationSupervisor';
import ApplicationSearchResultReadonly from 'Models/Application/Data/ApplicationSearchResult';
import { ApplicationSearchQuery } from 'Models/Application/Dto/ApplicationSearchQuery';
import { ApplicationEditError } from 'Models/Application/Data/ApplicationEditError';
import { ApplicationPendingReviewReadonly } from 'Models/Application/Data/ApplicationPendingReview';
import { ApplicationStatusEdit } from 'Models/Application/Data/ApplicationStatusEdit';
import ApiErrorReadonly from 'Models/Other/ApiError';
import { ApplicationReceivedDateReadonly } from 'Models/Application/Data/ApplicationReceivedDateUpdate';
import { PendingRegistrationApplicationsReadonly } from 'Models/Application/Data/PendingRegistrationApplications';
import { ApplicationRequestFiles } from 'Models/Application/Data/ApplicationRequestFilesModel';

interface ApplicationParams {
   type: string;
   token: string;
   id: string;
   applicationIds: readonly number[];
   supervisorRequest: SupervisorRequest;
   createParams: ApplicationCreate;
   updatedApplication: ApplicationEdit;
   applicationStatusEdit: ApplicationStatusEdit;
   model: ApplicationReceivedDateReadonly;
   data: ApplicationRequestFiles;
   appId: string;
   registrationPaymentType: number;
   status: string;
}

export function* getApplicationById(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;
   const applicationResponse = yield call(
      applicationService.getApplicationById,
      id
   );
   const response = applicationResponse as ApiResponse<ApplicationDtoReadOnly>;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.getApplicationByIdSuccess(response.data));
   } else {
      yield put(
         ApplicationActions.getApplicationByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getApplicationPendingRenewal(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;

   yield put(ApplicationActions.getApplicationPendingRenewalReset());

   const applicationResponse = yield call(
      applicationService.getApplicationPendingRenewal,
      id
   );
   const response = applicationResponse as ApiResponse<
      PendingApplicationResponse
   >;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getApplicationPendingRenewalSuccess(response.data)
      );
   } else {
      yield put(
         ApplicationActions.getApplicationPendingRenewalFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getRegistrationFilesByApplication(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;

   yield put(ApplicationActions.getRegistrationFilesByApplicationReset());

   const applicationResponse = yield call(
      applicationService.getRegistrationFilesByApplication,
      id
   );
   const response = applicationResponse as ApiResponse<File[]>;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getRegistrationFilesByApplicationSuccess(
            response.data ? response.data : []
         )
      );
   } else {
      yield put(
         ApplicationActions.getRegistrationFilesByApplicationFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getApplicationHistory(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;

   yield put(ApplicationActions.getApplicationHistoryReset());

   const applicationResponse = yield call(
      applicationService.getApplicationHistory,
      id
   );
   const response = applicationResponse as ApiResponse<
      readonly ApplicationDtoReadOnly[]
   >;

   if (response.status && response.ok) {
      yield put(ApplicationActions.getApplicationHistorySuccess(response.data));
   } else {
      yield put(
         ApplicationActions.getApplicationHistoryFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getApplicationForOverview(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;
   const applicationResponse = yield call(
      applicationService.getApplicationForOverview,
      id
   );
   const response = applicationResponse as ApiResponse<ApplicationEdit>;

   if (response.status && response.ok && response.data) {
      yield put(
         ApplicationActions.getApplicationForOverviewSuccess(response.data)
      );
   } else {
      yield put(
         ApplicationActions.getApplicationForOverviewFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* cancelApplication(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;
   const applicationResponse = yield call(
      applicationService.cancelApplication,
      id
   );
   const response = applicationResponse as ApiResponse<
      ApplicationEdit,
      ApplicationEditError
   >;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.cancelApplicationSuccess(response.data));
   } else {
      yield put(
         ApplicationActions.cancelApplicationFailure(
            response.data as ApplicationEditError
         )
      );
   }
}

export function* getApplicationSupervisor(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { supervisorRequest } = action;
   const supervisorResponse = yield call(
      applicationService.getApplicationSupervisor,
      supervisorRequest
   );
   const response = supervisorResponse as ApiResponse<ApplicationSupervisor>;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getApplicationSupervisorSuccess(response.data)
      );
   } else {
      yield put(
         ApplicationActions.getApplicationSupervisorFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* createApplication(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { createParams } = action;
   const applicationResponse = yield call(
      applicationService.createApplication,
      createParams
   );
   const response = applicationResponse as ApiResponse<
      ApplicationEdit,
      ApplicationCreateError
   >;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.createApplicationSuccess(response.data));
   } else {
      yield put(
         ApplicationActions.createApplicationFailure(
            response.data as ApplicationCreateError
         )
      );
   }
}

export function* updateApplication(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { updatedApplication } = action;
   const applicationResponse = yield call(
      applicationService.updateApplication,
      updatedApplication
   );
   const response = applicationResponse as ApiResponse<
      ApplicationEdit,
      ApplicationEditError
   >;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.updateApplicationSuccess(response.data));
   } else {
      yield put(
         ApplicationActions.updateApplicationFailure(
            response.data as ApplicationEditError
         )
      );
   }
}

export function* updateApplicationStatus(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { applicationStatusEdit } = action;
   const applicationResponse = yield call(
      applicationService.updateApplicationStatus,
      applicationStatusEdit
   );
   const response = applicationResponse as ApiResponse<
      ApplicationPendingReviewReadonly[],
      ApplicationEditError
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         ApplicationActions.updateApplicationStatusSuccess(response.data)
      );
   } else {
      yield put(
         ApplicationActions.updateApplicationStatusFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getApplicationTypes(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { id } = action;
   const applicationTypeResponse = yield call(
      applicationService.getApplicationTypes,
      id
   );
   const response = applicationTypeResponse as ApiResponse<string[]>;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.getApplicationTypesSuccess(response.data));
   } else {
      yield put(
         ApplicationActions.getApplicationTypesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* overwriteApplicationStatus(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { appId, status } = action;
   const applicationResponse = yield call(
      applicationService.overwriteApplicationStatus,
      appId,
      status
   );
   const response = applicationResponse as ApiResponse<
      ApplicationEdit,
      ApplicationEditError
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         ApplicationActions.overwriteApplicationStatusSuccess(response.data)
      );
   } else {
      yield put(
         ApplicationActions.overwriteApplicationStatusFailure(
            response.data as ApplicationEditError
         )
      );
   }
}

interface SearchParams extends ApplicationParams {
   query: ApplicationSearchQuery;
}

export function* searchApplications(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<SearchParams>
): Generator {
   const { query } = action;
   const searchResponse = yield call(
      applicationService.searchApplications,
      query
   );
   const response = searchResponse as ApiResponse<
      ApplicationSearchResultReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.searchApplicationsSuccess(response.data));
   } else {
      yield put(
         ApplicationActions.searchApplicationsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPendingReviewApplications(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   yield put(ApplicationActions.getPendingReviewApplicationsReset());

   const applicationResponse = yield call(
      applicationService.getPendingReviewApplications
   );
   const response = applicationResponse as ApiResponse<
      readonly ApplicationPendingReviewReadonly[]
   >;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getPendingReviewApplicationsSuccess(
            response.data ? response.data : []
         )
      );
   } else {
      yield put(
         ApplicationActions.getPendingReviewApplicationsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateApplicationReceivedDate(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { model } = action;
   const applicationResponse = yield call(
      applicationService.updateApplicationReceivedDate,
      model
   );
   const response = applicationResponse as ApiResponse<
      string,
      ApiErrorReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.updateApplicationReceivedDateSuccess());
   } else {
      yield put(
         ApplicationActions.updateApplicationReceivedDateFailure(
            response.data as ApiErrorReadonly
         )
      );
   }
}

export function* getPendingRegistrationApplications(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   yield put(ApplicationActions.getPendingRegistrationApplicationsReset());

   const applicationResponse = yield call(
      applicationService.getPendingRegistrationApplications
   );
   const response = applicationResponse as ApiResponse<
      readonly PendingRegistrationApplicationsReadonly[]
   >;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getPendingRegistrationApplicationsSuccess(
            response.data ? response.data : []
         )
      );
   } else {
      yield put(
         ApplicationActions.getPendingRegistrationApplicationsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPendingRegistrationApplicationCount(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   yield call(applicationService.getPendingRegistrationApplicationCount);

   const applicationResponse = yield call(
      applicationService.getPendingRegistrationApplicationCount
   );
   const response = applicationResponse as ApiResponse<number>;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getPendingRegistrationApplicationCountSuccess(
            response.data ? response.data : 0
         )
      );
   } else {
      yield put(
         ApplicationActions.getPendingRegistrationApplicationCountFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* putAcceptFile(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   yield put(ApplicationActions.cleanRegistrationFileReset());

   const { id, appId } = action;

   const applicationResponse = yield call(
      applicationService.putAcceptFile,
      id,
      appId
   );

   const response = applicationResponse as ApiResponse<Blob>;
   if (response.status && response.ok) {
      yield put(
         ApplicationActions.putAcceptFileSuccess(
            response.data ? response.data : new Blob()
         )
      );
   } else {
      yield put(
         ApplicationActions.putAcceptFileFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getRegistrationFileDownload(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   yield put(ApplicationActions.cleanRegistrationFileReset());

   const { id } = action;

   const applicationResponse = yield call(
      applicationService.getRegistrationFileDownload,
      id
   );

   const response = applicationResponse as ApiResponse<Blob>;
   if (response.status && response.ok) {
      yield put(
         ApplicationActions.getRegistrationFileDownloadSuccess(
            response.data ? response.data : new Blob()
         )
      );
   } else {
      yield put(
         ApplicationActions.getRegistrationFileDownloadFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* deleteRegistrationFile(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   yield put(ApplicationActions.cleanRegistrationFileReset());

   const { id, appId } = action;

   const applicationResponse = yield call(
      applicationService.deleteRegistrationFile,
      id,
      appId
   );
   const response = applicationResponse as ApiResponse<boolean>;

   if (response.status && response.ok) {
      yield put(
         ApplicationActions.deleteRegistrationFileSuccess(
            response.data ? response.data : false,
            id
         )
      );
   } else {
      yield put(
         ApplicationActions.deleteRegistrationFileFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* putRequestAnotherFiles(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { data } = action;

   yield put(ApplicationActions.putRequestAnotherFilesReset());

   const applicationResponse = yield call(
      applicationService.putRequestAnotherFiles,
      data
   );
   const response = applicationResponse as ApiResponse<boolean>;

   if (response.status && response.ok) {
      yield put(ApplicationActions.putRequestAnotherFilesSuccess(true));
   } else {
      yield put(
         ApplicationActions.putRequestAnotherFilesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updateFeeRequest(
   applicationService: Readonly<ApplicationServiceType>,
   action: Readonly<ApplicationParams>
): Generator {
   const { appId, registrationPaymentType } = action;
   const applicationResponse = yield call(
      applicationService.updateFeeRequest,
      appId,
      registrationPaymentType
   );
   const response = applicationResponse as ApiResponse<
      ApplicationPendingReviewReadonly[],
      ApplicationEditError
   >;

   if (response.status && response.ok && response.data) {
      yield put(ApplicationActions.updateFeeRequestSuccess());
   } else {
      yield put(
         ApplicationActions.updateFeeRequestFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
