import React from 'react';
import { useSelector } from 'react-redux';
import userManager from 'Util/Auth/AuthManager';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import './auth.scss';
import { useLocation } from 'react-router';
import { selectUser } from 'State/Redux/OidcRedux';

const Login = (): JSX.Element => {
   const location = useLocation();
   const user = useSelector(selectUser);

   if (!user || user.expired) {
      const returnUrlParam =
         location.state.returnUrl &&
         !isNullOrWhiteSpace(location.state.returnUrl)
            ? '?returnUrl=' +
              encodeURIComponent(location.state.returnUrl.trim())
            : '';
      userManager.signinRedirect({
         // eslint-disable-next-line
         redirect_uri:
            process.env.REACT_APP_ADMIN_REDIRECT_URL + returnUrlParam,
      });
   }
   return <div className="auth-loading"></div>;
};

export default Login;
