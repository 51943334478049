import React, { useEffect, useState, useCallback } from 'react';
import {
   CourseActions,
   CourseTypes,
   selectAllCourses,
} from 'State/Redux/CourseRedux';
import { useDispatch, useSelector } from 'react-redux';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import Moment from 'moment-timezone';
import { CellInfo, Column, SortingRule } from 'react-table';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import CourseViewDtoReadonly from 'Models/Examination/Dto/CourseViewDto';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import {
   isEnterKeyPress,
   SelectItem,
   createSelectList,
} from 'Util/Helpers/Input';
import DialogContainer from 'react-md/lib/Dialogs';
import TextField from 'react-md/lib/TextFields';
import Button from 'react-md/lib/Buttons';
import SelectField from 'react-md/lib/SelectFields';
import { ContactActions } from 'State/Redux/ContactRedux';
import StateReadonly from 'State/Redux/StateModel';
import ContactReadonly from 'Models/Contact/Data/Contact';
import DateInput from 'Components/Common/DateInput/DateInput';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import CourseDtoReadonly from 'Models/Examination/Dto/CourseDto';

const ViewCourses = (): JSX.Element => {
   const DateRenderer = (cell: CellInfo): JSX.Element => {
      return (
         <div>
            {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
         </div>
      );
   };

   const [editTarget, setEditTarget] = useState<CourseDtoReadonly | null>(null);
   const [trainingProvidersList, setTrainingProvidersList] = useState<
      SelectItem[]
   >([]);
   const [editCourseNumber, setEditCourseNumber] = useState('');
   const [editCourseName, setEditCourseName] = useState('');
   const [editTrainingProviderId, setEditTrainingProviderId] = useState(0);
   const [editStartDate, setEditStartDate] = useState('');
   const [editEndDate, setEditEndDate] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);
   const [isValid, setIsValid] = useState<boolean>(true);

   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   const isSuccess = useSelector(
      selectIsSuccess([CourseTypes.EDIT_COURSE_REQUEST])
   );

   const trainingProviders = useSelector(
      (state: StateReadonly): readonly ContactReadonly[] =>
         state.contact.trainingProviders
   );

   const COLUMN_HEADERS: Column<CourseViewDtoReadonly>[] = [
      {
         Header: 'Course ID',
         accessor: 'courseId',
         width: 90,
      },
      {
         Header: 'Course Number',
         accessor: 'courseNumber',
      },
      {
         Header: 'Course Name',
         accessor: 'courseName',
         width: 500,
      },
      {
         Header: 'Training Provider ID',
         accessor: 'trainingProviderId',
      },
      {
         Header: 'Training Provider Name',
         accessor: 'trainingProviderName',
         width: 350,
      },
      {
         Header: 'Start Date',
         accessor: 'startDate',
         Cell: DateRenderer,
      },
      {
         Header: 'End Date',
         accessor: 'endDate',
         Cell: DateRenderer,
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: 50,
         accessor: 'courseId',
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCourseNumber(cellInfo.original.courseNumber);
                     setEditCourseName(cellInfo.original.courseName);
                     setEditTrainingProviderId(
                        cellInfo.original.trainingProviderId
                     );
                     setEditStartDate(cellInfo.original.startDate);
                     setEditEndDate(cellInfo.original.endDate);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditCourseNumber(cellInfo.original.courseNumber);
                     setEditCourseName(cellInfo.original.courseName);
                     setEditTrainingProviderId(
                        cellInfo.original.trainingProviderId
                     );
                     setEditStartDate(cellInfo.original.startDate);
                     setEditEndDate(cellInfo.original.endDate);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   if (updateClicked && isSuccess) {
      toast.success(`Course updated successfully`);
      setUpdateClicked(false);
   }

   const isLoading = useSelector(
      selectIsLoading([CourseTypes.GET_ALL_COURSES_REQUEST])
   );
   const dispatch = useDispatch();
   const courseResults = useSelector(selectAllCourses);

   const validateCourse = useCallback((): boolean => {
      const validation = {
         courseCode: !!editCourseNumber,
         trainingProvider: !!editTrainingProviderId,
         title: !!editCourseName,
         startDate:
            !!editStartDate &&
            editEndDate !== null &&
            moment(editStartDate).isBefore(editEndDate),
         endDate:
            !!editEndDate &&
            editStartDate !== null &&
            moment(editEndDate).isAfter(editStartDate),
      };
      return Object.values(validation).every((v): boolean => !!v);
   }, [
      editCourseNumber,
      editTrainingProviderId,
      editCourseName,
      editStartDate,
      editEndDate,
   ]);

   useEffect(() => {
      setIsValid(validateCourse());
   }, [
      editCourseNumber,
      editTrainingProviderId,
      editCourseName,
      editStartDate,
      editEndDate,
      validateCourse,
   ]);

   useEffect((): void => {
      dispatch(CourseActions.getAllCoursesRequest());
   }, [dispatch]);

   useEffect(() => {
      dispatch(ContactActions.getTrainingProvidersRequest());
   }, [dispatch]);

   useEffect(() => {
      setTrainingProvidersList(
         createSelectList(
            trainingProviders,
            (tp): string => tp.contactId.toString(),
            (tp): string =>
               tp.companyName ||
               `Unnamed Training Provider (${tp.contactId.toString()})`,
            'Select a Training Provider'
         )
      );
   }, [trainingProviders]);

   const DEFAULT_SORTING: SortingRule[] = [{ id: 'endDate', desc: true }];

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className={'edit-dialog'}
            title="Edit Course"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 edit-course-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Code</h3>
                  <TextField
                     floating
                     id="course-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCourseNumber}
                     required
                     placeholder="Code"
                     onChange={(value): void => {
                        setEditCourseNumber(value.toString());
                     }}
                     maxLength={4}
                     disabled
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Title</h3>
                  <TextField
                     floating
                     id="course-name"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editCourseName}
                     required
                     placeholder="Title"
                     onChange={(value): void => {
                        setEditCourseName(value.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Training Provider</h3>
                  <SelectField
                     floating
                     id="edit-course-training-provider"
                     lineDirection="center"
                     className="md-cell md-cell--bottom md-cell--12"
                     position={SelectField.Positions.BELOW}
                     menuItems={trainingProvidersList}
                     required
                     onChange={(val): void => {
                        setEditTrainingProviderId(Number(val));
                     }}
                     value={editTrainingProviderId.toString()}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--6">Start Date</h3>
                  <h3 className="md-cell md-cell--6">End Date</h3>
                  <div className="md-cell md-cell--6">
                     <DateInput
                        id="edit-course-start-date"
                        required
                        onChange={(val): void => {
                           setEditStartDate(val);
                        }}
                        value={editStartDate ? editStartDate : undefined}
                        maxDate={
                           editEndDate ? new Date(editEndDate) : undefined
                        }
                     />
                  </div>
                  <div className="md-cell md-cell--6">
                     <DateInput
                        id="edit-course-end-date"
                        required
                        onChange={(val): void => {
                           setEditEndDate(val);
                        }}
                        value={editEndDate ? editEndDate : undefined}
                        minDate={
                           editStartDate ? new Date(editStartDate) : undefined
                        }
                     />
                  </div>
               </div>
               <Button
                  disabled={!isValid}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           CourseActions.editCourseRequest({
                              ...editTarget,
                              courseNumber: editCourseNumber,
                              courseName: editCourseName,
                              trainingProviderId: editTrainingProviderId,
                              startDate: editStartDate,
                              endDate: editEndDate,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           CourseActions.editCourseRequest({
                              ...editTarget,
                              courseNumber: editCourseNumber,
                              courseName: editCourseName,
                              trainingProviderId: editTrainingProviderId,
                              startDate: editStartDate,
                              endDate: editEndDate,
                           })
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ExpansionList className="md-cell md-cell--12 find-tab">
            <ExpansionPanel
               label="View Courses"
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <ClientSideTable
                  data={courseResults}
                  defaultPageSize={20}
                  columns={COLUMN_HEADERS}
                  defaultSorted={DEFAULT_SORTING}
                  loading={isLoading}
               />
            </ExpansionPanel>
         </ExpansionList>
      </>
   );
};

export default ViewCourses;
