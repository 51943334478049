import React, { useState, useCallback } from 'react';
import { Column, SortingRule } from 'react-table';
import { TableRowLink } from 'Components/Common/TableRowLink/TableRowLink';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import Moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import BoardMeetingDateReadonly, {
   DESCRIPTION_MAX_LENGTH,
   validateBoardMeetingDate,
} from 'Models/BoardMeetingDate/BoardMeetingDate';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import DateInput from 'Components/Common/DateInput/DateInput';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import TextField from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields';
import {
   BoardMeetingDateActions,
   BoardMeetingDateTypes,
} from 'State/Redux/BoardMeetingDateRedux';
import DialogContainer from 'react-md/lib/Dialogs';
import Button from 'react-md/lib/Buttons';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { BOARD_MEETING_DATE_STATUSES } from 'Util/Constants/BoardMeetingDate';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';

interface ResultsPanelProps {
   searchResults: readonly BoardMeetingDateReadonly[];
   isLoading: boolean;
}

const BoardMeetingDateResultsPanel = ({
   searchResults,
   isLoading,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const dispatch = useDispatch();
   const [
      editTarget,
      setEditTarget,
   ] = useState<BoardMeetingDateReadonly | null>(null);
   const isSuccess = useSelector(
      selectIsSuccess([BoardMeetingDateTypes.ADD_BOARD_MEETING_DATE_REQUEST])
   );

   const [editPendingMeetingDate, setEditPendingMeetingDate] = useState('');
   const [editPendingDescription, setEditPendingDescription] = useState('');
   const [editPendingStatus, setEditPendingStatus] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [updateClicked, setUpdateClicked] = useState(false);

   const setEditPendingMeetingDateCallback = useCallback((value: string) => {
      setEditPendingMeetingDate(value);
   }, []);
   const setEditPendingDescriptionCallback = useCallback((value: string) => {
      setEditPendingDescription(value);
   }, []);
   const setEditPendingStatusCallback = useCallback((value: string) => {
      setEditPendingStatus(value);
   }, []);
   const updateShowModal = useCallback((showModal: boolean): void => {
      setShowModal(showModal);
   }, []);

   if (updateClicked && isSuccess !== null) {
      toast.success(`Board Meeting Date updated successfully`);
      setUpdateClicked(false);
   }
   const isValidForm = editTarget
      ? Object.values(
           validateBoardMeetingDate({
              ...editTarget,
              description: editPendingDescription,
              boardMeetingStatus: editPendingStatus,
              boardMeetingDate: editPendingMeetingDate,
           })
        ).every((v): boolean => !!v)
      : false;

   const DEFAULT_SORTING: SortingRule[] = [
      { id: 'boardMeetingDate', desc: true },
   ];

   const COLUMN_HEADERS: Column<BoardMeetingDateReadonly>[] = [
      {
         Header: 'Board Meeting Date',
         accessor: 'boardMeetingDate',
         Cell: (cellInfo): JSX.Element | string => {
            return Moment(cellInfo.value).format(NAMED_DATE_FORMAT);
         },
      },
      {
         Header: 'Status',
         accessor: 'boardMeetingStatus',
      },
      {
         Header: 'Description',
         accessor: 'description',
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  onClick={(): void => {
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditPendingMeetingDate(
                        cellInfo.original.boardMeetingDate
                     );
                     setEditPendingDescription(cellInfo.original.description);
                     setEditPendingStatus(cellInfo.original.boardMeetingStatus);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     updateShowModal(true);
                     setEditTarget(cellInfo.original);
                     setEditPendingMeetingDate(
                        cellInfo.original.boardMeetingDate
                     );
                     setEditPendingDescription(cellInfo.original.description);
                     setEditPendingStatus(cellInfo.original.boardMeetingStatus);
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            className="edit-dialog"
            title="Edit Board Meeting Date"
            onHide={(): void => {
               updateShowModal(false);
            }}
            width={600}
            portal
         >
            <div className="md-grid md-cell--12 board-meeting-modal">
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Date</h3>
                  <DateInput
                     className="md-cell--12"
                     id="edit-board-meeting-date"
                     value={editPendingMeetingDate}
                     onChange={(value): void => {
                        setEditPendingMeetingDateCallback(value.toString());
                     }}
                     defaultValue=" "
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Status</h3>
                  <SelectField
                     id="edit-board-meeting-date-status"
                     floating
                     lineDirection="center"
                     className="md-cell md-cell--12 form-input"
                     menuItems={BOARD_MEETING_DATE_STATUSES}
                     value={editPendingStatus}
                     position={SelectField.Positions.BELOW}
                     onChange={(val): void => {
                        setEditPendingStatusCallback(val.toString());
                     }}
                  />
               </div>
               <div className="md-grid md-cell--12 grey-background form-section">
                  <h3 className="md-cell md-cell--12">Description</h3>
                  <TextField
                     floating
                     id="app-licence-number"
                     lineDirection="center"
                     className="md-cell--12 form-input"
                     value={editPendingDescription}
                     required
                     placeholder="Description"
                     onChange={(value): void => {
                        setEditPendingDescriptionCallback(value.toString());
                     }}
                     maxLength={DESCRIPTION_MAX_LENGTH}
                  />
               </div>
               <Button
                  disabled={!isValidForm}
                  onClick={(): void => {
                     updateShowModal(false);
                     if (editTarget) {
                        dispatch(
                           BoardMeetingDateActions.updateBoardMeetingDateRequest(
                              {
                                 ...editTarget,
                                 boardMeetingStatus: editPendingStatus,
                                 description: editPendingDescription,
                                 boardMeetingDate: editPendingMeetingDate,
                              }
                           )
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (editTarget) {
                        dispatch(
                           BoardMeetingDateActions.updateBoardMeetingDateRequest(
                              {
                                 ...editTarget,
                                 boardMeetingStatus: editPendingStatus,
                                 description: editPendingDescription,
                                 boardMeetingDate: editPendingMeetingDate,
                              }
                           )
                        );
                     }
                     updateShowModal(false);
                     setEditTarget(null);
                     setUpdateClicked(true);
                  }}
                  flat
                  primary
                  swapTheming
               >
                  Save
               </Button>
               <Button
                  onClick={(): void => {
                     updateShowModal(false);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) updateShowModal(false);
                  }}
                  className="cancel-button"
                  flat
                  secondary
                  swapTheming
               >
                  Cancel
               </Button>
            </div>
         </DialogContainer>
         <ClientSideTable
            data={searchResults}
            defaultPageSize={10}
            columns={COLUMN_HEADERS}
            defaultSorted={DEFAULT_SORTING}
            loading={isLoading}
            TrComponent={TableRowLink}
         />
      </>
   );
};

export default BoardMeetingDateResultsPanel;
