export const ADDRESS_FINDER_KEY = 'UNGEYJQHR7XFC3BMD89V';
export const ADDRESS_FINDER_DEFAULT_COUNTRY = 'NZ';
export const DEFAULT_COUNTRY_CODE = 'NZL';
export const DEFAULT_COUNTRY = 'New Zealand';
export const NO_RESULTS_USER_STRING =
   'No addresses were found. Please click Enter Address Manually';
export const PO_BOX_LOWER = 'po box';
export const PRIVATE_BAG_LOWER = 'private bag';
export const WIDGET_NO_RESULTS_SELECTED = 'results:empty';
export const WIDGET_UPDATED_AND_NO_RESULTS_SELECTED = 'results:update';
export const WIDGET_RESULT_SELECTED = 'result:select';
