import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import FeePaymentResultsPanel from './Panels/FeePaymentResultsPanel';
import FeePaymentSearchPanel from './Panels/FeePaymentSearchPanel';
import { DefaultPagingModel, PagedQuery } from 'Models/Other/PagedQuery';
import { DefaultFeesPaymentSearchInput } from 'Models/FeesPayment/FeesPaymentSearchInput';
import {
   FeesPaymentActions,
   FeesPaymentTypes,
   selectSearchResult,
} from 'State/Redux/FeesPaymentRedux';
import { useSelector, useDispatch } from 'react-redux';
import { selectErrorMessage } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import { isValidEndDateAfterStartDate } from 'Util/Helpers/Validation';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const FeePaymentFindTab = (): JSX.Element => {
   const searchError = useSelector(
      selectErrorMessage(FeesPaymentTypes.GET_SEARCH_RESULT_REQUEST)
   );
   const dispatch = useDispatch();
   const [paging, setPaging] = useState(DefaultPagingModel);
   const [searchInput, setSearchInput] = useState(
      DefaultFeesPaymentSearchInput
   );

   const search = (): void => {
      // validate the date
      if (
         searchInput.startDate &&
         searchInput.endDate &&
         !isValidEndDateAfterStartDate(
            searchInput.startDate,
            searchInput.endDate
         )
      ) {
         toast.error('Start date should be less than End date');
         return;
      }

      // Validate the Licence & Types
      // User should choose any of these below filters
      if (
         !searchInput.licenceNumber &&
         !searchInput.paymentStatusCode &&
         !searchInput.paymentTypeCode
      ) {
         toast.error(
            'Please choose either Licence Number (or) Payment Status (or) Payment Type'
         );
         return;
      }

      // reset the page number
      setPaging({ ...paging, pageNumber: 0 });
      const searchQuery = { ...searchInput, ...paging };
      dispatch(FeesPaymentActions.getSearchResultRequest(searchQuery));
   };

   const reset = (): void => {
      setSearchInput(DefaultFeesPaymentSearchInput);
      dispatch(FeesPaymentActions.getSearchResultReset());
   };

   const onPaging = (newPaging: PagedQuery): void => {
      const searchQuery = { ...searchInput, ...newPaging };
      setPaging(newPaging);
      dispatch(FeesPaymentActions.getSearchResultRequest(searchQuery));
   };

   useEffect((): void => {
      if (searchError) {
         toast.error(searchError);
      }
   }, [searchError]);

   const searchResult = useSelector(selectSearchResult);
   const panelTitle =
      searchResult && searchResult.totalCount
         ? `Results (${searchResult.totalCount})`
         : 'Results';
   const resultsPanel =
      !searchResult || !searchResult.results ? (
         <></>
      ) : (
         <ExpansionPanel
            label={panelTitle}
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
            className="search-result"
         >
            <FeePaymentResultsPanel
               paging={paging}
               onPagingChanged={onPaging}
            />
         </ExpansionPanel>
      );

   return (
      <ExpansionList className="md-cell md-cell--12 search-container">
         <ExpansionPanel
            label="Search"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
            className="search-panel"
         >
            <FeePaymentSearchPanel
               searchInput={searchInput}
               updateSearchInput={setSearchInput}
               onSearch={search}
               onReset={reset}
            />
         </ExpansionPanel>
         {resultsPanel}
      </ExpansionList>
   );
};

export default React.memo(FeePaymentFindTab);
