export const ApplicationStatusGroup1: string[] = [
   'BAU', //Batch Application Unpaid
   'BIP', //Batch Insufficient Prerequisites
   'BTA', //Batch Approved
   'ERR', //Entered In Error
   'EXPD', //Expired
   'N', //New
   'PBR', //Rejected
];

export const ApplicationStatusGroup2: string[] = [
   'BA', //Board Approved
   'BD', //Board Declined
   'BPM', //Board Pending Meeting
   'FIR', //Further Info Required
   'ERR', //Entered In Error
   'N', //New
   'DSUB', //Documents submitted
   'DECL', //Declined
   'PBAP', //Pending Board Approval
   'BIP', //Batch Insufficient Requisites
   'PPAY', //On Hold Pending Payment
   'PREG', //On Hold Pending Registrar
   'PDOC', //On Hold Pending Document
   'REVW', //Under Review
];

export const ApplicationStatusGroup3: string[] = [
   'BD', //Board Declined
   'BPM', //Board Pending Meeting
   'FIR', //Further Info Required
   'TA', //TTMRA Approved
   'ERR', //Entered In Error
   'N', //New
];

export const ApplicationStatusGroup4: string[] = [
   'PBA', //Approved
   'EXPD', //Expired
   'PBR', //Rejected
   'ERR', //Entered In Error
   'N', //New
];

export const ApplicationStatusByFilterRegistration: string[] = [
   'REVW', // Under review
   'PREG', // On hold pending registrar
   'PPAY', // On hold pending payment
   'PDOC', // On hold pending document
   'BIP', // Batch insufficient prerequisites
   'DECL', // Declined
   'DSUB', // Document submitted
   'N', //New
];

export const ApplicationStatusGroups: readonly string[][] = [
   ApplicationStatusGroup1,
   ApplicationStatusGroup2,
   ApplicationStatusGroup3,
   ApplicationStatusGroup4,
];
