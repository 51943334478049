import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { FontIcon } from 'react-md/lib/FontIcons';
import { ListItem } from 'react-md/lib/Lists';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import ExternalLink from 'Components/Common/ExternalLink/ExternalLink';
import { NavItemReadonly, NavItemLinkReadonly } from 'Models/Other/NavItem';
import { usePermissions } from 'Util/Helpers/Permissions';

interface ExternalNavLinkProps {
   icon?: JSX.Element;
   to: string;
   label: string;
}

const ExternalNavLink = ({
   icon,
   to,
   label,
}: Readonly<ExternalNavLinkProps>): JSX.Element => {
   return (
      <div className="menu-item" title={label}>
         <li className="md-list-item">
            <ExternalLink
               className="md-fake-btn md-pointer--hover md-fake-btn--no-outline md-list-tile md-list-tile--icon md-text"
               href={to}
            >
               <div className="md-ink-container" />
               <div className="md-tile-addon md-tile-addon--icon">{icon}</div>
               <div className="md-tile-content md-tile-content--left-icon">
                  <div className="md-tile-text--primary md-text">{label}</div>
               </div>
            </ExternalLink>
         </li>
      </div>
   );
};

interface NavLinkProps {
   entry: NavItemReadonly;
}

const NavLink = ({ entry }: Readonly<NavLinkProps>): JSX.Element => {
   const leftIcon = entry.icon ? (
      entry.icon.includes('fa-') ? (
         <FaIcon icon={entry.icon.replace('fa-', '')} />
      ) : (
         <FontIcon>{entry.icon}</FontIcon>
      )
   ) : (
      <></>
   );

   const isAuthed = usePermissions((entry as NavItemLinkReadonly).permissions);

   if (!(entry as NavItemLinkReadonly).to) {
      return (
         <div className="menu-item" title={entry.label}>
            <ListItem
               primaryText={entry.label}
               leftIcon={leftIcon}
               nestedItems={
                  entry.nested &&
                  entry.nested.map(
                     (item): JSX.Element => (
                        <NavLink entry={item} key={item.label} />
                     )
                  )
               }
            />
         </div>
      );
   }

   const { to, exact, external, label, nested } = entry as NavItemLinkReadonly;

   if (!isAuthed) return <></>;

   return (
      <Route path={to} exact={exact}>
         {({ match }: { match: string }): JSX.Element => {
            if (external)
               return <ExternalNavLink to={to} icon={leftIcon} label={label} />;

            return (
               <div className="menu-item" title={label}>
                  <ListItem
                     component={RouterLink}
                     active={!!match}
                     to={to}
                     primaryText={label}
                     leftIcon={leftIcon}
                     nestedItems={
                        nested &&
                        nested.map(
                           (item): JSX.Element => (
                              <NavLink entry={item} key={item.label} />
                           )
                        )
                     }
                  />
               </div>
            );
         }}
      </Route>
   );
};

export default NavLink;
