import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import WebMessageDtoReadonly from 'Models/Notifications/WebMessageDto';
import { withAccessToken, createApi } from '.';
import SqlReportingReadonly from 'Models/Report/SqlReporting';

export interface ReportServiceType {
   getSqlQueries(): Promise<ApiResponse<WebMessageDtoReadonly>>;
   getSqlQueryResults(id: number): Promise<ApiResponse<string>>;
   editSqlQuery(
      id: number,
      newQuery: string
   ): Promise<ApiResponse<SqlReportingReadonly>>;
   addSqlQuery(
      query: SqlReportingReadonly
   ): Promise<ApiResponse<SqlReportingReadonly>>;
   deleteSqlQuery(id: number): Promise<ApiResponse<boolean>>;
}

const create = (baseURL: string | undefined): ReportServiceType => {
   const api = createApi({ baseURL });

   const getSqlQueries = (): Promise<ApiResponse<WebMessageDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Report/GetSqlQueries`);
   };

   const getSqlQueryResults = (id: number): Promise<ApiResponse<string>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Report/GetSqlQueryResults?queryId=${id}`);
   };

   const editSqlQuery = (
      id: number,
      newQuery: string
   ): Promise<ApiResponse<SqlReportingReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(
         `/Report/SqlQuery/${id}`,
         `"${newQuery.replace(/"/g, '\\"')}"`
      );
   };

   const addSqlQuery = (
      query: SqlReportingReadonly
   ): Promise<ApiResponse<SqlReportingReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Report/SqlQuery`, query);
   };

   const deleteSqlQuery = (id: number): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.delete(`/Report/SqlQuery/${id}`);
   };

   return {
      getSqlQueries,
      getSqlQueryResults,
      editSqlQuery,
      addSqlQuery,
      deleteSqlQuery,
   };
};

export default {
   create,
};
