import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { usePermissions } from 'Util/Helpers/Permissions';
import { NavItemLinkReadonly, NavItemReadonly } from 'Models/Other/NavItem';
import { Permission } from 'Util/Constants/Permission';

interface NavItemsProps {
   navItems: NavItemReadonly[];
   override: boolean;
}

function HasPermission(permission: Permission): boolean {
   return usePermissions(permission);
}

//eslint-disable-next-line
const NavigateToFirstAuthenticatedLink = (props: any): JSX.Element => {
   let links: NavItemReadonly[] = [];
   const { navItems, override } = props;

   if (override) {
      return <></>;
   }

   const menuRoot = '/';

   const getRedirect = (links: NavItemReadonly[]): string => {
      let itemLink = menuRoot;
      let itemFound = false;
      if (links.length > 0) {
         links.forEach((link: NavItemReadonly) => {
            const itemPermissions = (link as any).permissions; //eslint-disable-line
            if (HasPermission(itemPermissions) && !itemFound) {
               itemFound = true;
               itemLink = (link as NavItemLinkReadonly).to;
            }
         });
      }

      return itemLink;
   };

   const parentNavItems = navItems.filter((nl: NavItemReadonly) =>
      Object.prototype.hasOwnProperty.call(nl, 'to')
   );

   if (parentNavItems) {
      links = [...links, ...parentNavItems];
   }

   const nestedNavItems = navItems.find((nl: NavItemReadonly) =>
      Object.prototype.hasOwnProperty.call(nl, 'nested')
   );

   if (nestedNavItems && nestedNavItems.nested) {
      const nestedLinks = nestedNavItems.nested.filter((nl: NavItemReadonly) =>
         Object.prototype.hasOwnProperty.call(nl, 'to')
      );
      if (nestedLinks) {
         links = [...links, ...nestedLinks];
      }
   }

   const itemLink = getRedirect(links);
   return itemLink === menuRoot ? <></> : <Redirect to={itemLink} />;
};

export default withRouter(NavigateToFirstAuthenticatedLink);
