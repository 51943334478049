import React from 'react';
import { ConditionValueProps } from './ConditionValueProps';
import { useSelector } from 'react-redux';
import StateReadonly from 'State/Redux/StateModel';
import {
   isValidRegistrationNumber,
   isPractitionerNumber,
   REGISTRATION_NUMBER_LENGTH,
} from 'Util/Helpers/RegistrationNumber';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import TextField from 'react-md/lib/TextFields';
import { ApplicationSupervisor } from 'Models/Application/Data/ApplicationSupervisor';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { getSupervisionStatus } from 'Util/Helpers/Supervision';
import {} from 'Util/Constants/LicenceTypes';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import {
   selectApplicationTypes,
   selectLicenceTypesMetadata,
} from 'State/Redux/MetadataRedux';
import { useLicenceSupervisions } from 'Util/Helpers/Metadata';
import { selectContact } from 'State/Redux/ContactRedux';
import { selectShouldSupervisionValidate } from 'State/Redux/ApplicationRedux';

interface ConditionSupervisionProps {
   onChange?: (val: string | number) => void;
   onSubmit?: (registrationNumber: string) => void;
   application: ApplicationEdit;
}

export const ConditionSupervision = ({
   conditionCode,
   description,
   textValue,
   isChecked,
   disabled,
   onChange,
   onSubmit,
   application,
}: Readonly<ConditionSupervisionProps & ConditionValueProps>): JSX.Element => {
   const supervisorSearchResult = useSelector(
      (state: StateReadonly): ApplicationSupervisor | null => {
         return state.application.editModel.supervisor || null;
      }
   );
   const currentContact = useSelector(selectContact);
   const licenceSupervisions = useLicenceSupervisions();
   const shouldSupervisionValidate = useSelector(
      selectShouldSupervisionValidate
   );

   const appTypes = useSelector(selectApplicationTypes);
   const licenceTypesMetadata = useSelector(selectLicenceTypesMetadata);

   // This shouldn't be null if application type exists
   const appTypeModel = appTypes.find(a => {
      return a.applicationTypeCode === application.applicationTypeCode;
   });
   const licenceTypeCode = appTypeModel ? appTypeModel.licenceTypeCode : '';
   const licenceTypeCodeNotNull = licenceTypeCode ? licenceTypeCode : '';

   const licenceTypeMetadata = licenceTypesMetadata.find(l => {
      return l.licenceTypeCode === licenceTypeCodeNotNull;
   });

   // If historicSupervisionStatus is not null, then the application is historic and we rely on status from API
   const supervisionStatus =
      supervisorSearchResult && supervisorSearchResult.historicSupervisionStatus
         ? supervisorSearchResult.historicSupervisionStatus
         : getSupervisionStatus(
              licenceSupervisions,
              supervisorSearchResult
                 ? supervisorSearchResult.supervisorContact
                 : null,
              licenceTypeCodeNotNull,
              licenceTypeMetadata ? licenceTypeMetadata.disciplineCode : '',
              application.licenceYear,
              currentContact
           );

   let icon = 'times';
   if (supervisionStatus.supervisorStatus === 'Valid') icon = 'check';
   if (supervisionStatus.supervisorStatus === 'Pending') icon = 'sync';

   const isValidRegNumber = isValidRegistrationNumber(textValue);
   if (!isValidRegNumber) icon = 'times';

   const supervisorName =
      !supervisorSearchResult || !isValidRegNumber
         ? ''
         : supervisorSearchResult.contactName;

   const isError =
      supervisionStatus.supervisorStatus !== 'Valid' &&
      shouldSupervisionValidate &&
      textValue.length > 0;

   return (
      <div className="md-grid">
         <div className="md-cell md-cell--1">
            <FaIcon icon={icon} className="supervision-status" />
         </div>
         <TextField
            id={`app-${1}-${conditionCode}`}
            className="md-cell md-cell--3"
            label="Supervisor #"
            lineDirection="center"
            value={textValue}
            disabled={disabled}
            onChange={onChange}
            maxLength={REGISTRATION_NUMBER_LENGTH}
            onBlur={(): void => {
               if (onSubmit) onSubmit(textValue);
            }}
            onKeyUp={(keyPress): void => {
               if (!isPractitionerNumber(textValue) || !onSubmit) return;
               if (isEnterKeyPress(keyPress)) onSubmit(textValue);
            }}
         />
         <TextField
            id={`app-${1}-${conditionCode}`}
            className="md-cell md-cell--8"
            label="Supervisor Name"
            errorText={supervisionStatus.statusMessage}
            error={isError}
            lineDirection="center"
            value={supervisorName}
            disabled
         />
      </div>
   );
};
