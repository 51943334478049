import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useDispatch, useSelector } from 'react-redux';
import PaymentMethodResultsPanel from './Panels/PaymentMethodResultsPanel';
import './AddPaymentMethod.scss';
import {
   PaymentMethodActions,
   selectPaymentMethods,
   PaymentMethodTypes,
} from 'State/Redux/PaymentMethodRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const ViewPaymentMethod = (): JSX.Element => {
   const searchResults = useSelector(selectPaymentMethods);
   const isLoading = useSelector(
      selectIsLoading([PaymentMethodTypes.GET_PAYMENT_METHOD_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   useEffect((): void => {
      dispatch(PaymentMethodActions.getPaymentMethodRequest());
      setHasSearched(true);
   }, [dispatch]);

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <PaymentMethodResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default ViewPaymentMethod;
