import React from 'react';
import './FileUploadSection.scss';
import FileApplicationApproval from './FileApplicationApproval';
import FileApplicationDocumentRequest from './FileApplicationDocumentRequest';

import {
   ApplicationActions,
   selectRegistrationFilesByApplication,
} from 'State/Redux/ApplicationRedux';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContainer } from 'react-md';
import { GenericModalBody } from '../GenericModalBody';

interface Props {
   applicationId: number;
}

const FileUploadSection = ({ applicationId }: Readonly<Props>): JSX.Element => {
   const listFiles = useSelector(selectRegistrationFilesByApplication);
   const [showDeleteFileModal, setShowDeleteFileModal] = React.useState<
      number | undefined
   >(undefined);
   const dispatch = useDispatch();

   const removeFile = (fileId: number): void => {
      dispatch(
         ApplicationActions.deleteRegistrationFileRequest(
            fileId.toString(),
            applicationId.toString()
         )
      );
      dispatch(
         ApplicationActions.getRegistrationFilesByApplicationRequest(
            applicationId.toString()
         )
      );
   };

   return (
      <>
         <DialogContainer
            id="remove-file-dialog"
            visible={showDeleteFileModal !== undefined}
            className="edit-dialog"
            title="Remove File"
            onHide={(): void => {
               setShowDeleteFileModal(undefined);
            }}
            width={600}
            portal
         >
            <GenericModalBody
               textModal="Are you sure you want to delete this document? Deleting it will permanently remove it from the application."
               onConfirm={(): void => {
                  showDeleteFileModal && removeFile(showDeleteFileModal);
                  setShowDeleteFileModal(undefined);
               }}
               onCancel={(): void => {
                  setShowDeleteFileModal(undefined);
               }}
            />
         </DialogContainer>

         <div className="md-cell md-cell--12">
            <p>{`The documents uploaded by the applicant:`}</p>
         </div>

         {listFiles &&
            listFiles.map(x => {
               return (
                  <FileApplicationApproval
                     key={x.fileID}
                     fileName={x.fileName}
                     fileId={x.fileID}
                     appId={applicationId}
                     removeFile={(): void => setShowDeleteFileModal(x.fileID)}
                     isApproved={
                        x.fileMappings?.filter(
                           y =>
                              y.applicationID === applicationId && y.isApproved
                        ).length > 0
                     }
                  />
               );
            })}

         <div className="md-cell md-cell--12">
            <p>{`+ Request more documents`}</p>
         </div>
         <FileApplicationDocumentRequest applicationId={applicationId} />
      </>
   );
};

export default FileUploadSection;
