import { createReducer } from 'reduxsauce';
import ApiError from 'Models/Other/ApiError';
import { AnyAction } from 'redux';
import StateReadonly from './StateModel';
import PaymentDetailReadOnly from 'Models/Payment/Data/PaymentDetail';
import PaymentEditDtoReadOnly from 'Models/Payment/Dto/PaymentEditDto';
import PaymentDetailFeeRequestLineReadOnly from 'Models/Payment/Data/PaymentDetailFeeRequestLine';
import { createScopedActions } from '.';
import FeeRequestUIModel from 'Components/Pages/Payment/Models/FeeRequestUIModel';
import PaymentResponseReadOnly from 'Models/Payment/Data/PaymentResponse';
import PaymentRedirectResponseReadOnly from 'Models/Payment/Data/PaymentRedirectResponse';
import PaymentRedirectRequestDtoReadOnly from 'Models/Payment/Dto/PaymentRedirectRequestDto';
import PaymentDataDtoReadonly from 'Models/Payment/Dto/PaymentDataDto';
import PaymentCheckoutResponseReadOnly from 'Models/Payment/Data/PaymentCheckoutResponse';
import PaymentCheckoutRequestDtoReadOnly from 'Models/Payment/Dto/PaymentCheckoutRequestDto';

interface PaymentState {
   paymentDetail: PaymentDetailReadOnly;
   updatedFeeRequestLine: PaymentDetailFeeRequestLineReadOnly;
   multiPayFeeRequests: FeeRequestUIModel[];
   updatingPaymentDateStatus?: boolean;
   cancellingPaymentStatus?: boolean;
   paymentResponse: PaymentResponseReadOnly;
   processingStatus?: boolean;
   transactionNumber?: string;
   paymentRedirectResponse: PaymentRedirectResponseReadOnly;
   processedPaymentId?: number;
   loadedMultiPay: boolean;
   transactionAmount?: number;
   cardFee?: number;
   paymentCheckoutResponse: PaymentCheckoutResponseReadOnly;
   amIAllowedToProceed?: boolean;
}

export type PaymentStateReadOnly = Readonly<PaymentState>;

interface TypeNames {
   GET_PAYMENT_DETAIL_REQUEST: string;
   GET_PAYMENT_DETAIL_SUCCESS: string;
   GET_PAYMENT_DETAIL_FAILURE: string;
   GET_PAYMENT_DETAIL_RESET: string;

   GET_PAYMENT_DETAIL_FEE_REQUEST_LINE_REQUEST: string;
   GET_PAYMENT_DETAIL_FEE_REQUEST_LINE_SUCCESS: string;
   GET_PAYMENT_DETAIL_FEE_REQUEST_LINE_FAILURE: string;

   UPDATE_PAYMENT_DATE_REQUEST: string;
   UPDATE_PAYMENT_DATE_SUCCESS: string;
   UPDATE_PAYMENT_DATE_FAILURE: string;

   CANCEL_PAYMENT_REQUEST: string;
   CANCEL_PAYMENT_SUCCESS: string;
   CANCEL_PAYMENT_FAILURE: string;

   ADD_MULTI_PAY_FEE: string;
   REMOVE_MULTI_PAY_FEE: string;
   REMOVE_MULTI_PAY_FEE_LINE: string;
   EMPTY_MULTI_PAY: string;

   GET_PAYMENT_RESPONSE_REQUEST: string;
   GET_PAYMENT_RESPONSE_SUCCESS: string;
   GET_PAYMENT_RESPONSE_FAILURE: string;

   GET_PAYMENT_PROCESSING_STATUS_REQUEST: string;
   GET_PAYMENT_PROCESSING_STATUS_SUCCESS: string;
   GET_PAYMENT_PROCESSING_STATUS_FAILURE: string;

   SAVE_PAYMENT_DATA_REQUEST: string;
   SAVE_PAYMENT_DATA_SUCCESS: string;
   SAVE_PAYMENT_DATA_FAILURE: string;
   SAVE_PAYMENT_DATA_RESET: string;

   GET_PAYMENT_REDIRECT_URL_REQUEST: string;
   GET_PAYMENT_REDIRECT_URL_SUCCESS: string;
   GET_PAYMENT_REDIRECT_URL_FAILURE: string;
   GET_PAYMENT_REDIRECT_URL_RESET: string;

   SAVE_NON_ONLINE_PAYMENT_REQUEST: string;
   SAVE_NON_ONLINE_PAYMENT_SUCCESS: string;
   SAVE_NON_ONLINE_PAYMENT_FAILURE: string;
   SAVE_NON_ONLINE_PAYMENT_RESET: string;

   GET_PAYMENT_TRANSACTION_AMOUNT_REQUEST: string;
   GET_PAYMENT_TRANSACTION_AMOUNT_SUCCESS: string;
   GET_PAYMENT_TRANSACTION_AMOUNT_FAILURE: string;

   GET_CARD_FEE_REQUEST: string;
   GET_CARD_FEE_SUCCESS: string;
   GET_CARD_FEE_FAILURE: string;

   SUBMIT_PAYMENT_CHECKOUT_REQUEST: string;
   SUBMIT_PAYMENT_CHECKOUT_SUCCESS: string;
   SUBMIT_PAYMENT_CHECKOUT_FAILURE: string;
   SUBMIT_PAYMENT_CHECKOUT_RESET: string;

   GET_AM_I_ALLOWED_REQUEST: string;
   GET_AM_I_ALLOWED_SUCCESS: string;
   GET_AM_I_ALLOWED_FAILURE: string;
}

type PaymentTypeNames = Readonly<TypeNames>;

export interface PaymentCreators {
   getPaymentDetailRequest: (
      applicationId: number | null,
      feeRequestId: number | null,
      feeRequestLineId: number | null,
      paymentId: number | null
   ) => AnyAction;
   getPaymentDetailSuccess: (
      data: PaymentDetailReadOnly | undefined
   ) => AnyAction;
   getPaymentDetailFailure: (error: ApiError) => AnyAction;
   getPaymentDetailReset: () => AnyAction;

   getPaymentDetailFeeRequestLineRequest: (
      feeRequestLineId: number
   ) => AnyAction;
   getPaymentDetailFeeRequestLineSuccess: (
      data: PaymentDetailFeeRequestLineReadOnly | undefined
   ) => AnyAction;
   getPaymentDetailFeeRequestLineFailure: (error: ApiError) => AnyAction;

   updatePaymentDateRequest: (editModel: PaymentEditDtoReadOnly) => AnyAction;
   updatePaymentDateSuccess: (status: boolean) => AnyAction;
   updatePaymentDateFailure: (error: ApiError) => AnyAction;

   cancelPaymentRequest: (editModel: PaymentEditDtoReadOnly) => AnyAction;
   cancelPaymentSuccess: (status: boolean) => AnyAction;
   cancelPaymentFailure: (error: ApiError) => AnyAction;

   addMultiPayFee: (
      data: FeeRequestUIModel[],
      initialisation: boolean
   ) => AnyAction;
   removeMultiPayFee: (feeRequestId: number) => AnyAction;
   removeMultiPayFeeLine: (
      feeRequestId: number,
      feeRequestLineId: number
   ) => AnyAction;
   emptyMultiPay: () => AnyAction;

   getPaymentResponseRequest: (result: string) => AnyAction;
   getPaymentResponseSuccess: (
      data: PaymentResponseReadOnly | undefined
   ) => AnyAction;
   getPaymentResponseFailure: (error: ApiError) => AnyAction;

   getPaymentProcessingStatusRequest: (result: string) => AnyAction;
   getPaymentProcessingStatusSuccess: (status: boolean) => AnyAction;
   getPaymentProcessingStatusFailure: (error: ApiError) => AnyAction;

   savePaymentDataRequest: (paymentData: PaymentDataDtoReadonly) => AnyAction;
   savePaymentDataSuccess: (transactionNumber: string) => AnyAction;
   savePaymentDataFailure: (error: ApiError) => AnyAction;
   savePaymentDataReset: () => AnyAction;

   getPaymentRedirectUrlRequest: (
      redirectUrlRequestData: PaymentRedirectRequestDtoReadOnly
   ) => AnyAction;
   getPaymentRedirectUrlSuccess: (
      data: PaymentRedirectResponseReadOnly | undefined
   ) => AnyAction;
   getPaymentRedirectUrlFailure: (
      data: PaymentRedirectResponseReadOnly | undefined
   ) => AnyAction;
   getPaymentRedirectUrlReset: () => AnyAction;

   saveNonOnlinePaymentRequest: (
      paymentData: PaymentDataDtoReadonly
   ) => AnyAction;
   saveNonOnlinePaymentSuccess: (processedPaymentId: number) => AnyAction;
   saveNonOnlinePaymentFailure: (error: ApiError) => AnyAction;
   saveNonOnlinePaymentReset: () => AnyAction;

   getPaymentTransactionAmountRequest: (referenceNumber: string) => AnyAction;
   getPaymentTransactionAmountSuccess: (status: number) => AnyAction;
   getPaymentTransactionAmountFailure: (error: ApiError) => AnyAction;

   getCardFeeRequest: (referenceNumber: string) => AnyAction;
   getCardFeeSuccess: (status: number) => AnyAction;
   getCardFeeFailure: (error: ApiError) => AnyAction;

   submitPaymentCheckoutRequest: (
      paymentCheckoutRequestData: PaymentCheckoutRequestDtoReadOnly
   ) => AnyAction;
   submitPaymentCheckoutSuccess: (
      data: PaymentCheckoutResponseReadOnly | undefined
   ) => AnyAction;
   submitPaymentCheckoutFailure: (
      data: PaymentCheckoutResponseReadOnly | undefined
   ) => AnyAction;
   submitPaymentCheckoutReset: () => AnyAction;

   getAmIAllowedRequest: () => AnyAction;
   getAmIAllowedSuccess: (status: boolean) => AnyAction;
   getAmIAllowedFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: PaymentStateReadOnly = {
   paymentDetail: ({} as unknown) as PaymentDetailReadOnly,
   updatedFeeRequestLine: ({} as unknown) as PaymentDetailFeeRequestLineReadOnly,
   multiPayFeeRequests: ([] as unknown) as FeeRequestUIModel[],
   updatingPaymentDateStatus: undefined,
   cancellingPaymentStatus: undefined,
   paymentResponse: ({} as unknown) as PaymentResponseReadOnly,
   processingStatus: undefined,
   transactionNumber: undefined,
   paymentRedirectResponse: ({} as unknown) as PaymentRedirectResponseReadOnly,
   processedPaymentId: undefined,
   loadedMultiPay: false,
   transactionAmount: undefined,
   cardFee: undefined,
   paymentCheckoutResponse: ({} as unknown) as PaymentCheckoutResponseReadOnly,
   amIAllowedToProceed: true,
};

/* ------------- Reducers ------------- */
const getPaymentDetailSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         paymentDetail: action.data,
         multiPayFeeRequests: state.loadedMultiPay
            ? []
            : state.multiPayFeeRequests,
         loadedMultiPay: action.data.isMultiPay,
      };
   }
   return state;
};

const getPaymentDetailReset = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   return { ...state, paymentDetail: ({} as unknown) as PaymentDetailReadOnly };
};

const getPaymentDetailFeeRequestLineSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         updatedFeeRequestLine: action.data,
      };
   }
   return state;
};

const updatePaymentDateSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         updatingPaymentDateStatus: action.data,
      };
   }
   return state;
};

const cancelPaymentSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         cancellingPaymentStatus: action.data,
      };
   }
   return state;
};

const AddMultiPayFee = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      let multiPayList: FeeRequestUIModel[] = [];
      if (!state.loadedMultiPay) {
         multiPayList = state.multiPayFeeRequests;
      }
      const newItmes: FeeRequestUIModel[] = [];
      action.data.forEach((e: FeeRequestUIModel) => {
         // remove existing feerequest
         const existingFeeRequest = multiPayList.find(
            f => f.data.feeRequestId === e.data.feeRequestId
         );
         if (existingFeeRequest) {
            multiPayList.splice(multiPayList.indexOf(existingFeeRequest), 1);
         }
         newItmes.push(e);
      });
      multiPayList = multiPayList.concat(newItmes);
      return {
         ...state,
         multiPayFeeRequests: multiPayList,
         loadedMultiPay:
            action.data.initialisation === false ? false : state.loadedMultiPay,
      };
   }
   return state;
};

const removeMultiPayFee = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.feeRequestId) {
      const feeRequestItems = state.multiPayFeeRequests;
      const index = feeRequestItems.findIndex(
         f => f.data.feeRequestId === Number(action.feeRequestId)
      );
      feeRequestItems.splice(index, 1);
      return {
         ...state,
         multiPayFeeRequests: feeRequestItems,
      };
   }
   return state;
};

const removeMultiPayFeeLine = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.feeRequestId && action.feeRequestLineId) {
      const feeRequestItems = state.multiPayFeeRequests;
      const feerequest = feeRequestItems.find(
         f => f.data.feeRequestId === Number(action.feeRequestId)
      );

      if (feerequest) {
         const index = feerequest.feeRequestLines.findIndex(
            f => f.data.feeRequestLineId === Number(action.feeRequestLineId)
         );
         if (index >= 0) {
            if (feerequest.feeRequestLines.length === 1) {
               feeRequestItems.splice(feeRequestItems.indexOf(feerequest), 1);
            } else {
               feerequest.feeRequestLines.splice(index, 1);
            }
            return {
               ...state,
               multiPayFeeRequests: feeRequestItems,
            };
         }
      }
   }
   return state;
};

const emptyMultiPay = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   return {
      ...state,
      multiPayFeeRequests: [],
      loadedMultiPay: false,
   };
};

const getPaymentResponseSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         paymentResponse: action.data,
      };
   }
   return state;
};

const getPaymentProcessingStatusSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data !== undefined) {
      return {
         ...state,
         processingStatus: action.data,
      };
   }
   return state;
};

const savePaymentDataSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         transactionNumber: action.data,
      };
   }
   return state;
};

const savePaymentDataReset = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   return { ...state, transactionNumber: undefined };
};

const getPaymentRedirectUrl = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         paymentRedirectResponse: action.data,
      };
   }
   return state;
};

const getPaymentRedirectUrlReset = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         paymentRedirectResponse: ({} as unknown) as PaymentRedirectResponseReadOnly,
      };
   }
   return state;
};

const saveNonOnlinePaymentSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         processedPaymentId: action.data,
      };
   }
   return state;
};

const saveNonOnlinePaymentReset = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   return { ...state, processedPaymentId: undefined };
};

const getPaymentTransactionAmountSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data !== undefined) {
      return {
         ...state,
         transactionAmount: action.data,
      };
   }
   return state;
};

const submitPaymentCheckout = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         paymentCheckoutResponse: action.data,
      };
   }
   return state;
};

const submitPaymentCheckoutReset = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         paymentCheckoutResponse: ({} as unknown) as PaymentCheckoutResponseReadOnly,
      };
   }
   return state;
};

const getAmIAllowedSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data !== undefined) {
      return {
         ...state,
         amIAllowedToProceed: action.data,
      };
   }
   return state;
};

const getCardFeeSuccess = (
   state: PaymentStateReadOnly,
   action: AnyAction
): PaymentStateReadOnly => {
   if (action && action.data !== undefined) {
      return {
         ...state,
         cardFee: action.data,
      };
   }
   return state;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   PaymentTypeNames,
   PaymentCreators
>('payment', {
   getPaymentDetailRequest: [
      'applicationId',
      'feeRequestId',
      'feeRequestLineId',
      'paymentId',
   ],
   getPaymentDetailSuccess: ['data'],
   getPaymentDetailFailure: ['error'],
   getPaymentDetailReset: [],

   getPaymentDetailFeeRequestLineRequest: ['feeRequestLineId'],
   getPaymentDetailFeeRequestLineSuccess: ['data'],
   getPaymentDetailFeeRequestLineFailure: ['error'],

   updatePaymentDateRequest: ['editModel'],
   updatePaymentDateSuccess: ['data'],
   updatePaymentDateFailure: ['error'],

   cancelPaymentRequest: ['editModel'],
   cancelPaymentSuccess: ['data'],
   cancelPaymentFailure: ['error'],

   addMultiPayFee: ['data', 'initialisation'],
   removeMultiPayFee: ['feeRequestId'],
   removeMultiPayFeeLine: ['feeRequestId', 'feeRequestLineId'],
   emptyMultiPay: [],

   getPaymentResponseRequest: ['result'],
   getPaymentResponseSuccess: ['data'],
   getPaymentResponseFailure: ['error'],

   getPaymentProcessingStatusRequest: ['result'],
   getPaymentProcessingStatusSuccess: ['data'],
   getPaymentProcessingStatusFailure: ['error'],

   savePaymentDataRequest: ['paymentData'],
   savePaymentDataSuccess: ['data'],
   savePaymentDataFailure: ['error'],
   savePaymentDataReset: [],

   getPaymentRedirectUrlRequest: ['redirectUrlRequestData'],
   getPaymentRedirectUrlSuccess: ['data'],
   getPaymentRedirectUrlFailure: ['data'],
   getPaymentRedirectUrlReset: [],

   saveNonOnlinePaymentRequest: ['paymentData'],
   saveNonOnlinePaymentSuccess: ['data'],
   saveNonOnlinePaymentFailure: ['error'],
   saveNonOnlinePaymentReset: [],

   getPaymentTransactionAmountRequest: ['referenceNumber'],
   getPaymentTransactionAmountSuccess: ['data'],
   getPaymentTransactionAmountFailure: ['error'],

   getCardFeeRequest: ['referenceNumber'],
   getCardFeeSuccess: ['data'],
   getCardFeeFailure: ['error'],

   submitPaymentCheckoutRequest: ['paymentCheckoutRequestData'],
   submitPaymentCheckoutSuccess: ['data'],
   submitPaymentCheckoutFailure: ['data'],
   submitPaymentCheckoutReset: [],

   getAmIAllowedRequest: [],
   getAmIAllowedSuccess: ['data'],
   getAmIAllowedFailure: ['error'],
});

export const PaymentTypes = Types;
export const PaymentActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_PAYMENT_DETAIL_SUCCESS]: getPaymentDetailSuccess,
   [Types.GET_PAYMENT_DETAIL_RESET]: getPaymentDetailReset,
   [Types.GET_PAYMENT_DETAIL_FEE_REQUEST_LINE_SUCCESS]: getPaymentDetailFeeRequestLineSuccess,
   [Types.UPDATE_PAYMENT_DATE_SUCCESS]: updatePaymentDateSuccess,
   [Types.CANCEL_PAYMENT_SUCCESS]: cancelPaymentSuccess,
   [Types.ADD_MULTI_PAY_FEE]: AddMultiPayFee,
   [Types.REMOVE_MULTI_PAY_FEE]: removeMultiPayFee,
   [Types.REMOVE_MULTI_PAY_FEE_LINE]: removeMultiPayFeeLine,
   [Types.EMPTY_MULTI_PAY]: emptyMultiPay,
   [Types.GET_PAYMENT_RESPONSE_SUCCESS]: getPaymentResponseSuccess,
   [Types.GET_PAYMENT_PROCESSING_STATUS_SUCCESS]: getPaymentProcessingStatusSuccess,
   [Types.SAVE_PAYMENT_DATA_SUCCESS]: savePaymentDataSuccess,
   [Types.SAVE_PAYMENT_DATA_RESET]: savePaymentDataReset,
   [Types.GET_PAYMENT_REDIRECT_URL_SUCCESS]: getPaymentRedirectUrl,
   [Types.GET_PAYMENT_REDIRECT_URL_FAILURE]: getPaymentRedirectUrl,
   [Types.GET_PAYMENT_REDIRECT_URL_RESET]: getPaymentRedirectUrlReset,
   [Types.SAVE_NON_ONLINE_PAYMENT_SUCCESS]: saveNonOnlinePaymentSuccess,
   [Types.SAVE_NON_ONLINE_PAYMENT_RESET]: saveNonOnlinePaymentReset,
   [Types.GET_PAYMENT_TRANSACTION_AMOUNT_SUCCESS]: getPaymentTransactionAmountSuccess,
   [Types.GET_CARD_FEE_SUCCESS]: getCardFeeSuccess,
   [Types.SUBMIT_PAYMENT_CHECKOUT_SUCCESS]: submitPaymentCheckout,
   [Types.SUBMIT_PAYMENT_CHECKOUT_FAILURE]: submitPaymentCheckout,
   [Types.SUBMIT_PAYMENT_CHECKOUT_RESET]: submitPaymentCheckoutReset,
   [Types.GET_AM_I_ALLOWED_SUCCESS]: getAmIAllowedSuccess,
});

/* ------------- Selectors ------------- */
export const selectPaymentDetail = (
   state: StateReadonly
): PaymentDetailReadOnly => state.payment.paymentDetail;

export const selectMultiPayDetail = (
   state: StateReadonly
): Readonly<FeeRequestUIModel[]> => state.payment.multiPayFeeRequests;

export const selectUpdatedFeeRequestLine = (
   state: StateReadonly
): PaymentDetailFeeRequestLineReadOnly => state.payment.updatedFeeRequestLine;

export const selectUpdatingPaymentDateStatus = (
   state: StateReadonly
): boolean | undefined => state.payment.updatingPaymentDateStatus;

export const selectCancellingPaymentStatus = (
   state: StateReadonly
): boolean | undefined => state.payment.cancellingPaymentStatus;

export const selectPaymentResponse = (
   state: StateReadonly
): PaymentResponseReadOnly => state.payment.paymentResponse;

export const selectProcessingStatus = (
   state: StateReadonly
): boolean | undefined => state.payment.processingStatus;

export const selectTransactionNumber = (
   state: StateReadonly
): string | undefined => state.payment.transactionNumber;

export const selectPaymentRedirectResponse = (
   state: StateReadonly
): PaymentRedirectResponseReadOnly => state.payment.paymentRedirectResponse;

export const selectProcessedPaymentId = (
   state: StateReadonly
): number | undefined => state.payment.processedPaymentId;

export const selectLoadedMultiPay = (state: StateReadonly): boolean =>
   state.payment.loadedMultiPay;

export const selectTransactionAmount = (
   state: StateReadonly
): number | undefined => state.payment.transactionAmount;

export const selectCardFee = (state: StateReadonly): number | undefined =>
   state.payment.cardFee;

export const selectPaymentCheckoutResponse = (
   state: StateReadonly
): PaymentCheckoutResponseReadOnly => state.payment.paymentCheckoutResponse;

export const selectAmIAllowedToProceed = (
   state: StateReadonly
): boolean | undefined => state.payment.amIAllowedToProceed;
