import React from 'react';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { Grid, Cell } from 'react-md/lib/Grids';
import Moment from 'moment-timezone';
import './Dashboard.scss';
import { CellInfo, Column } from 'react-table';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import DashboardModelReadonly from 'Models/Dashboard/Dashboard';

interface DasboardDataProps {
   dashboardData: readonly DashboardModelReadonly[];
}

const DATETIME_FORMAT = 'DD/MM/YYYY h:mma';
const PEOPLE_LICENSED = 'DPLI';

const Dashboard = ({
   dashboardData,
}: Readonly<DasboardDataProps>): JSX.Element => {
   const NotApplicableCell = (cell: CellInfo): JSX.Element => {
      return <>{cell.value ? cell.value : 'N/A'}</>;
   };
   const filteredDashboardData = dashboardData.filter(function(data) {
      return data.code === PEOPLE_LICENSED;
   });

   const dashboardLabel = (
      <>
         Dashboard
         <span className="dashboard-title">
            Last updated:{' '}
            {filteredDashboardData.map(function(data) {
               return data.lastUpdated
                  ? Moment(data.lastUpdated).format(DATETIME_FORMAT)
                  : '';
            })}
         </span>
      </>
   );

   const COLUMN_HEADERS: Column<DashboardModelReadonly>[] = [
      {
         Header: '',
         accessor: 'display',
      },
      {
         Header: 'Today',
         accessor: 'today',
      },
      {
         Header: 'Same date last year',
         accessor: 'lastYear',
         Cell: NotApplicableCell,
      },
      {
         Header: 'Differential',
         accessor: 'differential',
         Cell: NotApplicableCell,
      },
      {
         Header: 'Auckland',
         accessor: 'auckland',
      },
      {
         Header: 'North Island (w/o AKL)',
         accessor: 'northIsland',
      },
      {
         Header: 'South Island',
         accessor: 'southIsland',
      },
   ];

   if (!dashboardData) {
      return <></>;
   }
   return (
      <Grid>
         <Cell size={12}>
            <ExpansionList className="dashboard-data">
               <ExpansionPanel
                  label={dashboardLabel}
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <ClientSideTable
                     data={dashboardData}
                     className="-striped -highlight"
                     columns={COLUMN_HEADERS}
                     showPagination={false}
                     minRows={dashboardData.length}
                  />
               </ExpansionPanel>
            </ExpansionList>
         </Cell>
      </Grid>
   );
};

export default Dashboard;
