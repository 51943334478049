import React, { useEffect, useState } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import AssessmentResultsCard from 'Components/Common/AssessmentResultsCard/AssessmentResultsCard';
import LicencesPanel from './Panels/LicencesPanel';
import AssessmentsHistory from './Panels/AssessmentsHistory';
import './AssessmentsTab.scss';
import { ResultActions, selectResults } from 'State/Redux/ResultRedux';
import { useDispatch, useSelector } from 'react-redux';
import { selectContact } from 'State/Redux/ContactRedux';
import { isEqual } from 'lodash-es';
import ResultDtoReadonly from 'Models/Examination/Dto/ResultDto';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const AssessmentsTab = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const results = useSelector(selectResults);
   const dispatch = useDispatch();

   const [hasLoadedStatus, setHasLoadedStatus] = useState(false);
   const [loadedResults, setLoadedResults] = useState<
      readonly ResultDtoReadonly[]
   >([]);

   useEffect((): void => {
      dispatch(ResultActions.resetResults());
      dispatch(ResultActions.resetResultStatus());
   }, [dispatch]);

   useEffect((): void => {
      dispatch(
         ResultActions.getResultByContactIdRequest(contact.contactId.toString())
      );
   }, [contact.contactId, dispatch]);

   useEffect((): void => {
      if (hasLoadedStatus && isEqual(loadedResults, results)) return;
      dispatch(
         ResultActions.getResultStatusByContactIdRequest(
            contact.contactId.toString()
         )
      );
      setHasLoadedStatus(true);
      setLoadedResults(results);
   }, [contact.contactId, dispatch, results, loadedResults, hasLoadedStatus]);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label={<>Assessments </>}
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <LicencesPanel />
            <AssessmentResultsCard />
         </ExpansionPanel>

         <ExpansionPanel
            label="Assessments History"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <AssessmentsHistory />
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AssessmentsTab;
