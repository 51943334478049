import React from 'react';
import FaIcon from '../FaIcon/FaIcon';

interface PhotoControllerProps {
   zoomIncrement: number;
   rotateIncrement: number;
   onZoom: (val: number) => void;
   onRotate: (val: number) => void;
   onReset: () => void;
}

const PhotoController = ({
   zoomIncrement,
   rotateIncrement,
   onZoom,
   onRotate,
   onReset,
}: Readonly<PhotoControllerProps>): JSX.Element => {
   return (
      <ul className="control-button-list">
         <li
            className="link"
            onClick={(): void => onZoom(zoomIncrement)}
            onKeyPress={(): void => onZoom(zoomIncrement)}
         >
            <FaIcon icon="search-plus" />
            <span>Zoom In</span>
         </li>
         <li
            className="link"
            onClick={(): void => onZoom(-zoomIncrement)}
            onKeyPress={(): void => onZoom(-zoomIncrement)}
         >
            <FaIcon icon="search-minus" />
            <span>Zoom Out</span>
         </li>
         <li
            className="link"
            onClick={(): void => onRotate(-rotateIncrement)}
            onKeyPress={(): void => onRotate(-rotateIncrement)}
         >
            <FaIcon icon="undo" />
            <span>Rotate Left</span>
         </li>
         <li
            className="link"
            onClick={(): void => onRotate(rotateIncrement)}
            onKeyPress={(): void => onRotate(rotateIncrement)}
         >
            <FaIcon icon="redo" />
            <span>Rotate Right</span>
         </li>
         <li
            className="link"
            onClick={(): void => onReset()}
            onKeyPress={(): void => onReset()}
         >
            <FaIcon icon="retweet" />
            <span>Reset</span>
         </li>
      </ul>
   );
};

export default PhotoController;
