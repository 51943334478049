import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation, Link } from 'react-router-dom';
import NavigationDrawer from 'react-md/lib/NavigationDrawers';
import { TextField } from 'react-md/lib/TextFields';
import Menu, { MenuButton } from 'react-md/lib/Menus';
import {
   IconSeparator,
   AccessibleFakeButton,
   Layover,
} from 'react-md/lib/Helpers';
import NavLink from './NavLink';
import Home from 'Components/Pages/Home/Home';
import Contact from 'Components/Pages/Contact/Contact';
import ContactCompanySearch from 'Components/Pages/Contact/SubMenu/ContactCompanySearch/ContactCompanySearch';
import DisciplinaryMatters from 'Components/Pages/DisciplinaryMatters/DisciplinaryMatters';
import ApplicationSearch from 'Components/Pages/Applications/ApplicationSearch';
import ReviewPhotos from 'Components/Pages/Review/ReviewPhotos';
import FeesPayment from 'Components/Pages/FeesPayment/FeesPayment';
import Payment from 'Components/Pages/Payment/Payment';
import PaymentCheckout from 'Components/Pages/Payment/Checkout';
import CpdResults from 'Components/Pages/CPD/Results/Results';
import CpdCourse from 'Components/Pages/CPD/Course/Course';
import NotificationMaintenance from 'Components/Pages/NotificationMaintenance/NotificationMaintenance';
import BackOffice from 'Components/Pages/BackOffice/BackOfficeMain';
import SqlReporting from 'Components/Pages/Reports/SqlReporting/SqlReporting';
import Reports from 'Components/Pages/Reports/Reports';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import StateReadonly from 'State/Redux/StateModel';
import { MetadataActions } from 'State/Redux/MetadataRedux';
import {
   isValidSearchName,
   isValidQuickSearch,
} from 'Util/Helpers/ContactSearch';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { CreateContact } from 'Components/Pages/Contact/SubMenu/CreateContact';
import {
   isNullOrWhiteSpace,
   isValidSearchLicenceNumber,
} from 'Util/Helpers/Validation';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import { MetadataTypes } from 'State/Redux/MetadataRedux';
import Loading from 'Components/Common/Loading';
import { PgdbRoute } from 'Util/Navigation/PgdbRoute';
import { CreateCompanyContact } from 'Components/Pages/Contact/SubMenu/CreateCompanyContact';
import { ReactComponent as PlumberIcon } from 'Components/Icons/icon-plumber-white.svg';
import { ReactComponent as GasfitterIcon } from 'Components/Icons/icon-gasfitter-white.svg';
import { ReactComponent as DrainlayerIcon } from 'Components/Icons/icon-drainlayer-white.svg';
import './Main.scss';
import { NON_PROD_ENV } from 'Util/Constants/Environment';
import { FeeRequest } from 'Components/Pages/FeesPayment';
import GlobalNotification from 'Components/Pages/GlobalNotification/GlobalNotification';
import EditFeeRequest from 'Components/Pages/FeesPayment/FeeRequest/EditFeeRequest';
import { selectUser, selectIsUserLoading } from 'State/Redux/OidcRedux';
import ReviewApplications from 'Components/Pages/Review/ReviewApplications';
import { Authorized } from 'Components/Common/Authorized';
import { NavItemReadonly, NavItemLinkReadonly } from 'Models/Other/NavItem';
import BoardMeetingDate from '../Superuser/BoardMeetingDate/BoardMeetingDate';
import SystemParameter from '../Superuser/SystemParameter/SystemParameter';
import LicenceType from '../Superuser/LicenceType/LicenceTypes';
import PaymentMethod from '../Superuser/PaymentMethod/PaymentMethod';
import RegistrationType from '../Superuser/RegistrationType/RegistrationType';
import FeeMaintenance from '../Superuser/FeeMaintenance/FeeMaintenance';
import AnnualRenewalJob from '../Superuser/AnnualRenewalJob/AnnualRenewalJob';
import { ListItemProps } from 'react-md/lib/Lists';
import { usePermissions } from 'Util/Helpers/Permissions';
import PaymentFail from 'Components/Common/Payment/PaymentFail';
import PaymentSuccess from 'Components/Common/Payment/PaymentSuccess';
import { AddUser, EditUser } from '../Superuser/UserMaintenance/EditUser';
import SearchUser from '../Superuser/UserMaintenance/SearchUser/SearchUser';
import CreateTrainingProvider from '../CPD/TrainingProvider/CreateTrainingProvider';
import FeeType from '../Superuser/FeeType/FeeTypes';
import NavigateToFirstAuthenticatedLink from './NavigateToFirstAuthenticatedLink';
import RegistrationApplication from 'Components/Pages/RegistrationApplication';

const CONTACT_SEARCH_URL = '/contact/search';

const navItems: NavItemReadonly[] = [
   {
      exact: true,
      label: 'Dashboard',
      to: '/',
      icon: 'fa-tachometer-alt',
      permissions: 'Dashboard.Read',
   },
   {
      label: 'Contact',
      icon: 'fa-id-card',
      permissions: 'Contact.Read',
      nested: [
         {
            exact: false,
            label: 'Search',
            to: CONTACT_SEARCH_URL,
            icon: 'fa-search',
            permissions: 'Contact.Read',
         },
         {
            exact: false,
            label: 'Create Contact',
            to: '/contact/create/contact',
            icon: 'fa-user-plus',
            permissions: 'Contact.Create',
         },
         {
            exact: false,
            label: 'Create EL Company',
            to: '/contact/create/company',
            icon: 'fa-city',
            permissions: 'Contact.Create',
         },
      ],
   },
   {
      exact: false,
      label: 'Disciplinary Matters',
      to: '/disciplinary-matters',
      icon: 'fa-exclamation-circle',
      permissions: 'DisciplinaryMatter.Read',
   },
   {
      exact: false,
      label: 'Public Register',
      to: `${process.env.REACT_APP_PRACTITIONER_URL}/public-register`,
      icon: 'fa-universal-access',
      permissions: 'PublicRegister.Read',
      external: true,
   },
   {
      exact: false,
      label: 'Applications',
      to: '/applications/search',
      icon: 'fa-envelope-open-text',
      permissions: 'Application.Read',
   },
   {
      exact: false,
      label: 'Registration Applications',
      to: '/registration/applications',
      icon: 'note_add',
      permissions: 'RegistrationApplication.Read',
   },
   {
      label: 'Fees/Payments',
      icon: 'fa-dollar-sign',
      permissions: 'FeeRequest.Read',
      nested: [
         {
            exact: true,
            label: 'Search',
            to: '/fees-payments/search',
            icon: 'fa-search',
            permissions: 'FeeRequest.Read',
         },
         {
            exact: false,
            label: 'Create Fee Request',
            to: '/fees-payments/create-fee-request',
            icon: 'fa-file-invoice-dollar',
            permissions: 'FeeRequest.Create',
         },
         {
            exact: false,
            label: 'Multi-Pay',
            to: '/payment/multipay',
            icon: 'fa-cart-plus',
            permissions: 'FeeRequest.Create',
         },
      ],
   },
   {
      label: 'Report',
      icon: 'fa-chart-bar',
      permissions: 'Report.Access',
      nested: [
         {
            exact: false,
            label: 'SQL Reporting',
            to: '/sql-reporting',
            icon: 'fa-database',
         },
      ],
   },
   {
      label: 'CPD',
      icon: 'fa-graduation-cap',
      permissions: 'CPD.Read',
      nested: [
         {
            exact: false,
            label: 'Results',
            to: '/cpd/results',
            icon: 'fa-poll',
            permissions: 'CPD.Read',
         },
         {
            exact: false,
            label: 'Course',
            to: '/cpd/course',
            icon: 'fa-book-open',
            permissions: 'CPD.Read',
         },
         {
            exact: false,
            label: 'Create Training Provider',
            to: '/cpd/create-training-provider',
            icon: 'fa-clipboard-check',
            permissions: 'CPD.Create',
         },
      ],
   },
   {
      label: 'Review',
      icon: 'fa-thumbs-up',
      permissions: ['ReviewPhoto.Read' && 'ReviewRegistration.Read'],
      nested: [
         {
            exact: false,
            label: 'Photos',
            to: '/review/photos',
            icon: 'fa-camera',
            permissions: 'ReviewPhoto.Read',
         },
         {
            exact: false,
            label: 'Applications',
            to: '/review/applications',
            icon: 'fa-envelope-open-text',
            permissions: 'ReviewRegistration.Read',
         },
      ],
   },
   {
      exact: false,
      label: 'Global Notification',
      to: '/global-notification',
      icon: 'fa-exclamation-triangle',
      permissions: 'NotificationMaintenance.Update',
   },
   {
      label: 'Super User',
      icon: 'fa-user-ninja',
      permissions: [
         'SystemParameter.Read' &&
            'UserMaintenance.Read' &&
            'FeeMaintenance.Read',
      ],
      nested: [
         {
            exact: false,
            label: 'Search Users',
            to: '/superuser/search-users',
            icon: 'fa-search',
            permissions: 'UserMaintenance.Read',
         },
         {
            exact: false,
            label: 'Add User',
            to: '/superuser/add-user',
            icon: 'fa-user-plus',
            permissions: 'UserMaintenance.Create',
         },
         {
            exact: false,
            label: 'Board Meeting Dates',
            to: '/superuser/board-meeting-dates',
            icon: 'fa-calendar-week',
            permissions: 'SystemParameter.Read',
         },
         {
            exact: false,
            label: 'System Parameter',
            to: '/superuser/system-parameter',
            icon: 'fa-cogs',
            permissions: 'SystemParameter.Read',
         },
         {
            exact: false,
            label: 'Licence Type',
            to: '/superuser/licence-type',
            icon: 'fa-id-badge',
            permissions: 'SystemParameter.Read',
         },
         {
            exact: false,
            label: 'Fee Type',
            to: '/superuser/fee-type',
            icon: 'fa-funnel-dollar',
            permissions: 'SystemParameter.Read',
         },
         {
            exact: false,
            label: 'Payment Method',
            to: '/superuser/payment-method',
            icon: 'fa-dollar-sign',
            permissions: 'SystemParameter.Read',
         },
         {
            exact: false,
            label: 'Registration Type',
            to: '/superuser/registration-type',
            icon: 'fa-address-card',
            permissions: 'SystemParameter.Read',
         },
         {
            exact: false,
            label: 'Fee Maintenance',
            to: '/superuser/fee-maintenance',
            icon: 'fa-file-invoice-dollar',
            permissions: 'FeeMaintenance.Read',
         },
         {
            exact: false,
            label: 'Annual Renewal Job',
            to: '/superuser/annual-renewal-job',
            icon: 'fa-sync-alt',
            permissions: 'SuperUser.Create',
         },
      ],
   },
];

interface MenuProps {
   userName: string;
}

const KebabMenu = ({ userName }: Readonly<MenuProps>): JSX.Element => {
   const menu: ListItemProps[] = [];

   const hasBackOfficeFinancePremission = usePermissions(
      'BackOfficeFinance.Read'
   );
   const hasBackOfficeExportPremission = usePermissions(
      'BackOfficeExport.Read'
   );

   // Display items that belong to specific permissions, due to limitations in React MD Menu button, if statements need to be used.
   if (usePermissions('NotificationMaintenance.Update'))
      menu.push({
         primaryText: 'Notification Maintenance',
         leftIcon: <FaIcon icon="comment-dots" />,
         to: '/notification-maintenance',
         component: Link,
      });

   if (usePermissions('SystemParameter.Read'))
      menu.push({
         primaryText: 'System Parameters',
         leftIcon: <FaIcon icon="server" />,
         to: '/superuser/system-parameter',
         component: Link,
      });

   if (usePermissions('FeeMaintenance.Read'))
      menu.push({
         primaryText: 'Fee Maintenance',
         leftIcon: <FaIcon icon="file-invoice-dollar" />,
         to: '/superuser/fee-maintenance',
         component: Link,
      });

   if (usePermissions('UserMaintenance.Read'))
      menu.push({
         primaryText: 'User Maintenance',
         leftIcon: <FaIcon icon="wrench" />,
         to: '/superuser/search-users',
         component: Link,
      });

   if (hasBackOfficeFinancePremission || hasBackOfficeExportPremission)
      menu.push({
         primaryText: 'Back Office',
         leftIcon: <FaIcon icon="building" />,
         to: '/financial-extract',
         component: Link,
      });

   menu.push({
      primaryText: 'Log Out',
      leftIcon: <FaIcon icon="lock" />,
      to: '/logout',
      component: Link,
   });

   return (
      <MenuButton
         id="toolbar-title-menu-kebab"
         flat
         anchor={{
            x: Layover.HorizontalAnchors.INNER_LEFT,
            y: Layover.VerticalAnchors.BOTTOM,
         }}
         position={Menu.Positions.BELOW}
         sameWidth
         menuItems={menu}
      >
         <AccessibleFakeButton
            component={IconSeparator}
            iconBefore
            label={
               <IconSeparator label={`Hi ${userName}`}>
                  <FaIcon icon="angle-down" />
               </IconSeparator>
            }
         >
            <></>
         </AccessibleFakeButton>
      </MenuButton>
   );
};

const Logo = (): JSX.Element => (
   <>
      {process.env.REACT_APP_ENV &&
      NON_PROD_ENV.includes(process.env.REACT_APP_ENV) ? (
         <img
            alt="Unicorn Logo"
            className="pgdb-env-indicator"
            src={`/images/unicorn-${process.env.REACT_APP_ENV}.png`}
         />
      ) : (
         <div className="center-logo">
            <PlumberIcon className="pgdb-logo" />
            <GasfitterIcon className="pgdb-logo" />
            <DrainlayerIcon className="pgdb-logo" />
         </div>
      )}
   </>
);

const TemporaryIcon = (): JSX.Element => {
   return (
      <>
         {process.env.REACT_APP_ENV &&
         NON_PROD_ENV.includes(process.env.REACT_APP_ENV) ? (
            <img
               alt="Unicorn Mini Logo"
               className="pgdb-env-indicator-mini"
               src={`/images/mini-unicorn-${process.env.REACT_APP_ENV}.png`}
            />
         ) : (
            <img
               alt="PGDB Mini Logo"
               className="pgdb-env-indicator-mini"
               src={`/images/combined-pgdb-icon.png`}
            />
         )}
      </>
   );
};

const getSearchValue = (searchValue: string): string => {
   const validNumber = isValidSearchLicenceNumber(searchValue);
   const validName = isValidSearchName(searchValue);
   if (validNumber || validName) return searchValue.trim();

   return '';
};

const getSearchValueFromKeyPress = (
   keyPress: React.KeyboardEvent<HTMLElement>,
   searchValue: string
): string => {
   if (!isEnterKeyPress(keyPress)) return '';

   return getSearchValue(searchValue);
};

interface SearchRedirectProps {
   searchValue: string;
   clearInput: () => void;
}

const SearchRedirect = ({
   searchValue,
   clearInput,
}: Readonly<SearchRedirectProps>): JSX.Element => {
   if (!searchValue || searchValue.length === 0) return <></>;

   clearInput();
   return (
      <Redirect
         push
         to={{
            pathname: CONTACT_SEARCH_URL,
            state: { quickSearchQuery: searchValue },
         }}
      />
   );
};

const Main = (): JSX.Element => {
   const user = useSelector(selectUser);
   const isUserLoading = useSelector(selectIsUserLoading);
   const isLoading = useSelector(
      selectIsLoading([MetadataTypes.GET_METADATA_REQUEST])
   );
   const isError = useSelector(
      selectIsError([MetadataTypes.GET_METADATA_REQUEST])
   );
   const metadata = useSelector((state: StateReadonly) => {
      return state.metadata;
   });
   const dispatch = useDispatch();

   const [searchValue, setSearchValue] = useState('');
   const [searchTextValue, setSearchTextValue] = useState('');
   const location = useLocation();

   const isValidQuickSearchValue =
      isNullOrWhiteSpace(searchTextValue) ||
      isValidQuickSearch(searchTextValue);
   const quickSearchErrorClass = !isValidQuickSearchValue
      ? 'quick-search-error'
      : '';

   const metadataNotLoaded = Object.keys(metadata).length === 0;
   useEffect((): void => {
      if (user && !user.expired && metadataNotLoaded)
         dispatch(MetadataActions.getMetadataRequest());
   }, [user, metadataNotLoaded, dispatch]);

   if (isUserLoading) return <></>;

   if (!user || user.expired) {
      const returnUrl = location.pathname;
      const redirectPath = !isNullOrWhiteSpace(returnUrl) ? returnUrl : '';

      return (
         <Redirect
            to={{
               pathname: '/login',
               state: { returnUrl: redirectPath },
            }}
         />
      );
   }

   const navLinks = navItems.map(
      (item): JSX.Element => (
         <Authorized
            key={`${(item as NavItemLinkReadonly).to}_${item.label}`}
            permissions={(item as NavItemLinkReadonly).permissions}
         >
            <NavLink
               entry={item}
               key={`${(item as NavItemLinkReadonly).to}_${item.label}`}
            />
         </Authorized>
      )
   );

   return (
      <Loading
         isError={isError}
         isLoading={metadataNotLoaded || isLoading}
         tryReload={(): void => {
            dispatch(MetadataActions.getMetadataRequest());
         }}
      >
         <Route
            render={({ location }): JSX.Element => (
               <NavigationDrawer
                  drawerTitle={<Logo />}
                  toolbarActions={
                     <KebabMenu
                        userName={
                           user.profile.name
                              ? user.profile.name.toString()
                              : 'Admin User'
                        }
                     />
                  }
                  temporaryIcon={<TemporaryIcon />}
                  desktopDrawerType={
                     NavigationDrawer.DrawerTypes.PERSISTENT_MINI
                  }
                  toolbarChildren={[
                     <div
                        className="header-search"
                        key={0}
                        onKeyPress={(e): void => {
                           const val = getSearchValueFromKeyPress(
                              e,
                              searchTextValue
                           );
                           setSearchValue(val.length > 0 ? val : '');
                        }}
                     >
                        <TextField
                           id="search-quick"
                           placeholder="Search by name or number"
                           type="search"
                           className="search-container"
                           inputClassName={quickSearchErrorClass}
                           value={searchTextValue}
                           // style the height on the input element and remove any padding
                           inlineIndicator={
                              <FaIcon
                                 icon="search"
                                 className={`quick-search-icon ${quickSearchErrorClass}`}
                                 onClick={(): void => {
                                    const val = getSearchValue(searchTextValue);
                                    setSearchValue(val.length > 0 ? val : '');
                                 }}
                                 onKeyUp={(keyPress): void => {
                                    if (isEnterKeyPress(keyPress)) {
                                       const val = getSearchValue(
                                          searchTextValue
                                       );
                                       setSearchValue(
                                          val.length > 0 ? val : ''
                                       );
                                    }
                                 }}
                              />
                           }
                           onChange={(value): void => {
                              setSearchTextValue(value.toString());
                           }}
                        />
                     </div>,
                  ]}
                  navItems={navLinks}
               >
                  <>
                     <NavigateToFirstAuthenticatedLink
                        navItems={navItems}
                        override={!location.key}
                     />
                     <Switch>
                        <PgdbRoute
                           exact
                           path="/"
                           location={location}
                           component={Home}
                           pageTitle="Dashboard"
                        />
                        <PgdbRoute
                           path={['/contact/create/contact']}
                           location={location}
                           component={CreateContact}
                           pageTitle="Create Contact"
                           permissions="Contact.Create"
                        />
                        <PgdbRoute
                           path={['/contact/create/company']}
                           location={location}
                           component={CreateCompanyContact}
                           pageTitle="Create Company"
                           permissions="Contact.Create"
                        />
                        <PgdbRoute
                           path={CONTACT_SEARCH_URL}
                           location={location}
                           component={ContactCompanySearch}
                           pageTitle="Contact Search"
                           permissions="Contact.Read"
                        />
                        <PgdbRoute
                           path="/contact/:contactId"
                           location={location}
                           component={Contact}
                           pageTitle="Contact"
                           permissions="Contact.Read"
                        />
                        <PgdbRoute
                           path="/disciplinary-matters"
                           location={location}
                           component={DisciplinaryMatters}
                           pageTitle={['Search', 'Disciplinary Matters']}
                           permissions="DisciplinaryMatter.Read"
                        />
                        <PgdbRoute
                           path="/applications/search"
                           location={location}
                           component={ApplicationSearch}
                           pageTitle={['Search', 'Applications']}
                           exact
                           permissions="Application.Read"
                        />
                        <PgdbRoute
                           path="/fees-payments/search"
                           location={location}
                           component={FeesPayment}
                           pageTitle="Fees &amp; Payments"
                           exact
                           permissions="FeeRequest.Read"
                        />
                        <PgdbRoute
                           path="/fees-payments/create-fee-request/:contactId?"
                           location={location}
                           component={FeeRequest}
                           pageTitle="Fee Request"
                           permissions="FeeRequest.Create"
                           permissionRedirectPath="/fees-payments/search"
                        />
                        <PgdbRoute
                           path="/fees-payments/edit-fee-request/:feeRequestId"
                           location={location}
                           component={EditFeeRequest}
                           pageTitle="Fee Request"
                           permissions="FeeRequest.Read"
                           permissionRedirectPath="/fees-payments/search"
                        />
                        <PgdbRoute
                           path="/payment"
                           location={location}
                           component={Payment}
                           pageTitle="Payment"
                           permissions={[
                              'BackOfficeFinance.Create' &&
                                 'BackOfficeFinance.Update' &&
                                 'FeeRequest.Read',
                           ]}
                        />
                        <PgdbRoute
                           path="/payment/multipay"
                           location={location}
                           component={Payment}
                           pageTitle="Multi-Pay"
                           permissions={[
                              'BackOfficeFinance.Create' &&
                                 'BackOfficeFinance.Update' &&
                                 'FeeRequest.Read',
                           ]}
                        />
                        <PgdbRoute
                           path="/payment-checkout/:referenceNumber"
                           location={location}
                           component={PaymentCheckout}
                           pageTitle="Payment Checkout"
                           permissions={'BackOfficeFinance.Create'}
                        />
                        <PgdbRoute
                           path="/report"
                           location={location}
                           component={Reports}
                           pageTitle="Reports"
                        />
                        <PgdbRoute
                           path="/cpd/results"
                           location={location}
                           component={CpdResults}
                           pageTitle="CPD Results"
                           permissions="CPD.Read"
                        />
                        <PgdbRoute
                           path="/cpd/course"
                           location={location}
                           component={CpdCourse}
                           pageTitle="CPD Courses"
                           permissions="CPD.Read"
                        />
                        <PgdbRoute
                           path="/cpd/create-training-provider"
                           location={location}
                           component={CreateTrainingProvider}
                           pageTitle="CPD Training"
                           permissions="CPD.Create"
                        />
                        <PgdbRoute
                           path="/cpd"
                           location={location}
                           component={CpdResults}
                           pageTitle="CPD"
                           permissions="CPD.Read"
                        />
                        <PgdbRoute
                           path="/notification-maintenance"
                           location={location}
                           component={NotificationMaintenance}
                           pageTitle="Notification Maintenance"
                           permissions="NotificationMaintenance.Update"
                        />
                        <PgdbRoute
                           path="/sql-reporting"
                           location={location}
                           component={SqlReporting}
                           pageTitle="SQL Reporting"
                        />
                        <PgdbRoute
                           path="/review/photos"
                           location={location}
                           component={ReviewPhotos}
                           pageTitle="Review Photos"
                           permissions="ReviewPhoto.Read"
                        />
                        <PgdbRoute
                           path="/review/applications"
                           location={location}
                           component={ReviewApplications}
                           pageTitle="Review Applications"
                           permissions="ReviewRegistration.Read"
                        />
                        <PgdbRoute
                           path="/global-notification"
                           location={location}
                           component={GlobalNotification}
                           pageTitle="Global Notification"
                           permissions="NotificationMaintenance.Read"
                        />
                        <PgdbRoute
                           path="/financial-extract"
                           location={location}
                           component={BackOffice}
                           pageTitle="Financial Extracts"
                        />
                        <PgdbRoute
                           path="/payment-unsuccessful"
                           location={location}
                           component={PaymentFail}
                           pageTitle="Payment Unsuccessful"
                        />
                        <PgdbRoute
                           path="/payment-successful/:returnUrl?"
                           location={location}
                           component={PaymentSuccess}
                           pageTitle="Payment Successful"
                        />
                        <PgdbRoute
                           path="/superuser/board-meeting-dates"
                           location={location}
                           component={BoardMeetingDate}
                           pageTitle="Board Meeting Dates"
                           permissions="SystemParameter.Read"
                        />
                        <PgdbRoute
                           path="/superuser/system-parameter"
                           location={location}
                           component={SystemParameter}
                           pageTitle="System Parameter"
                           permissions="SystemParameter.Read"
                        />
                        <PgdbRoute
                           path="/superuser/licence-type"
                           location={location}
                           component={LicenceType}
                           pageTitle="Licence Type"
                           permissions="SystemParameter.Read"
                        />
                        <PgdbRoute
                           path="/superuser/fee-type"
                           location={location}
                           component={FeeType}
                           pageTitle="Fee type"
                           permissions="SystemParameter.Read"
                        />
                        <PgdbRoute
                           path="/superuser/payment-method"
                           location={location}
                           component={PaymentMethod}
                           pageTitle="Payment Method"
                           permissions="SystemParameter.Read"
                        />
                        <PgdbRoute
                           path="/superuser/registration-type"
                           location={location}
                           component={RegistrationType}
                           pageTitle="Registration Type"
                           permissions="SystemParameter.Read"
                        />
                        <PgdbRoute
                           path="/superuser/fee-maintenance"
                           location={location}
                           component={FeeMaintenance}
                           pageTitle="Fee Maintenance"
                           permissions="FeeMaintenance.Read"
                        />
                        <PgdbRoute
                           path="/superuser/annual-renewal-job"
                           location={location}
                           component={AnnualRenewalJob}
                           pageTitle="AnnualRenewalJob"
                           permissions="SuperUser.Create"
                        />
                        <PgdbRoute
                           path="/superuser/add-user"
                           location={location}
                           component={AddUser}
                           pageTitle="Add User"
                           permissions="UserMaintenance.Create"
                        />
                        <PgdbRoute
                           path="/superuser/edit-user/:userId"
                           location={location}
                           component={EditUser}
                           pageTitle="Edit User"
                           permissions="UserMaintenance.Update"
                        />
                        <PgdbRoute
                           path="/superuser/search-users"
                           location={location}
                           component={SearchUser}
                           pageTitle="Search Users"
                           permissions="UserMaintenance.Read"
                        />
                        <PgdbRoute
                           path="/registration/applications"
                           location={location}
                           component={RegistrationApplication}
                           pageTitle="Registration Applications"
                           permissions="RegistrationApplication.Read" // TODO change this with the correct Role
                        />
                     </Switch>
                     <SearchRedirect
                        searchValue={searchValue}
                        clearInput={(): void => {
                           setSearchValue('');
                           setSearchTextValue('');
                        }}
                     />
                  </>
               </NavigationDrawer>
            )}
         />
      </Loading>
   );
};

export default Main;
