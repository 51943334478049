import { put, call } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { BoardMeetingDateServiceType } from 'State/Services/BoardMeetingDateService';
import { BoardMeetingDateSearchQuery } from 'Models/BoardMeetingDate/BoardMeetingDateSearchQuery';
import BoardMeetingDateReadonly from 'Models/BoardMeetingDate/BoardMeetingDate';
import { BoardMeetingDateActions } from 'State/Redux/BoardMeetingDateRedux';

interface BoardMeetingDateParams {
   type: string;
   token: string;
   id: string;
   query: BoardMeetingDateSearchQuery;
   boardMeetingDate: BoardMeetingDateReadonly;
}

export function* updateBoardMeetingDate(
   boardMeetingDateService: Readonly<BoardMeetingDateServiceType>,
   action: Readonly<BoardMeetingDateParams>
): Generator {
   const { boardMeetingDate } = action;
   const boardMeetingDateResponse = yield call(
      boardMeetingDateService.updateBoardMeetingDate,
      boardMeetingDate
   );
   const response = boardMeetingDateResponse as ApiResponse<
      BoardMeetingDateReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         BoardMeetingDateActions.updateBoardMeetingDateSuccess(response.data)
      );
   } else {
      yield put(
         BoardMeetingDateActions.updateBoardMeetingDateFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* searchBoardMeetingDate(
   boardMeetingDateService: Readonly<BoardMeetingDateServiceType>,
   action: Readonly<BoardMeetingDateParams>
): Generator {
   const { query } = action;
   const boardMeetingDateResponse = yield call(
      boardMeetingDateService.searchBoardMeetingDate,
      query
   );
   const response = boardMeetingDateResponse as ApiResponse<
      readonly BoardMeetingDateReadonly[]
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         BoardMeetingDateActions.searchBoardMeetingDateSuccess(response.data)
      );
   } else {
      yield put(
         BoardMeetingDateActions.searchBoardMeetingDateFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getBoardMeetingDates(
   boardMeetingDateService: Readonly<BoardMeetingDateServiceType>,
   action: Readonly<BoardMeetingDateParams>
): Generator {
   const boardMeetingDateResponse = yield call(
      boardMeetingDateService.searchBoardMeetingDate,
      { startDate: '', endDate: '' }
   );
   const response = boardMeetingDateResponse as ApiResponse<
      readonly BoardMeetingDateReadonly[]
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         BoardMeetingDateActions.getBoardMeetingDatesSuccess(response.data)
      );
   } else {
      yield put(
         BoardMeetingDateActions.getBoardMeetingDatesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* addBoardMeetingDate(
   boardMeetingDateService: Readonly<BoardMeetingDateServiceType>,
   action: Readonly<BoardMeetingDateParams>
): Generator {
   const { boardMeetingDate } = action;
   const boardMeetingDateResponse = yield call(
      boardMeetingDateService.addBoardMeetingDate,
      boardMeetingDate
   );
   const response = boardMeetingDateResponse as ApiResponse<
      BoardMeetingDateReadonly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         BoardMeetingDateActions.addBoardMeetingDateSuccess(response.data)
      );
   } else {
      yield put(
         BoardMeetingDateActions.addBoardMeetingDateFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
