import React from 'react';
import ApplicationDto from 'Models/Application/Dto/ApplicationDto';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import {
   selectApplicationHistory,
   ApplicationTypes,
} from 'State/Redux/ApplicationRedux';
import { useSelector } from 'react-redux';
import { CellInfo, Column, SortingRule } from 'react-table';
import Moment from 'moment-timezone';
import { get } from 'lodash-es';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useLocation, Link } from 'react-router-dom';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import { CANCELLED } from 'Util/Constants/PaymentStatus';

const getApplicationUrl = (pathname: string, applicationId: number): string => {
   return applicationId ? pathname + '/' + applicationId : pathname;
};

const IconHeader = (): JSX.Element => {
   return <FaIcon icon="ellipsis-h" />;
};

const DateRenderer = (cell: CellInfo): JSX.Element => {
   return (
      <div>
         {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
      </div>
   );
};

interface ApplicationDisplayModel {
   applicationId: number;
   licenceYear: string;
   applicationType: string;
   applicationStatus: string;
   dateCreated: string;
   paymentDate: string;
}

const DEFAULT_SORTING: SortingRule[] = [{ id: 'dateCreated', desc: true }];

const generateApplicationModels = (
   applications: readonly ApplicationDto[]
): ApplicationDisplayModel[] => {
   return applications.map(
      (application): ApplicationDisplayModel => {
         const paymentDateItem = get(
            application,
            'feeRequest[0].feeRequestLine[0].paymentFeeRequestLine[0].payment.paymentDate',
            null
         );

         const paymentStatus = get(
            application,
            'feeRequest[0].feeRequestLine[0].paymentFeeRequestLine[0].payment.paymentStatusCode',
            null
         );

         return {
            applicationId: application.applicationId,
            licenceYear: application.licenceYear
               ? application.licenceYear.description
               : ' - ',
            applicationType: application.applicationTypeDescription,
            applicationStatus: application.applicationStatusDescription,
            dateCreated: application.enteredDate,
            paymentDate: paymentStatus !== CANCELLED ? paymentDateItem : null,
         };
      }
   );
};

const HistoryPanel = (): JSX.Element => {
   const application = useSelector(selectApplicationHistory);
   const isLoading = useSelector(
      selectIsLoading([ApplicationTypes.GET_APPLICATION_HISTORY_REQUEST])
   );
   const location = useLocation();

   const COLUMN_HEADERS: Column<ApplicationDisplayModel>[] = [
      {
         Header: 'Application ID',
         accessor: 'applicationId',
      },
      {
         Header: 'Licence Year',
         accessor: 'licenceYear',
      },
      {
         Header: 'Application Type',
         accessor: 'applicationType',
      },
      {
         Header: 'Application Status',
         accessor: 'applicationStatus',
      },
      {
         Header: 'Created Date',
         accessor: 'dateCreated',
         Cell: DateRenderer,
      },
      {
         Header: 'Payment Date',
         accessor: 'paymentDate',
         Cell: DateRenderer,
      },
      {
         Header: IconHeader,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         sortable: false,
         accessor: FN_EMPTY_VOID,
         Cell: (cell: CellInfo): JSX.Element => {
            const applicationId =
               cell && cell.original && cell.original.applicationId
                  ? cell.original.applicationId
                  : 0;
            const link = getApplicationUrl(location.pathname, applicationId);

            return (
               <Link to={link} className="disable-link-styles">
                  <FaIcon
                     className="small-icon md-pointer--hover"
                     icon="edit"
                  />
               </Link>
            );
         },
      },
   ];

   return (
      <ClientSideTable
         data={generateApplicationModels(application)}
         defaultPageSize={20}
         columns={COLUMN_HEADERS}
         defaultSorted={DEFAULT_SORTING}
         loading={isLoading}
      />
   );
};

export default HistoryPanel;
