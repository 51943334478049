export const SUPERVISION_STATUS_ACCEPTED = 'Accepted';
export const SUPERVISION_STATUS_DECLINED = 'Declined';
export const SUPERVISION_STATUS_PENDING = 'Pending';
export const SUPERVISION_STATUS_CANCELLED = 'Cancelled';

export type SupervisionStatusCode =
   | 'Accepted'
   | 'Reprint'
   | 'Pending'
   | 'Cancelled';
