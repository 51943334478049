import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import Licence from 'Models/Licence/Data/LicenceGroup';
import LicenceDto from 'Models/Licence/Dto/LicenceDto';
import ApiError from 'Models/Other/ApiError';
import CreateLicenceDto from 'Models/Licence/Dto/CreateLicenceDto';
import UpdateLicenceDto from 'Models/Licence/Dto/UpdateLicenceDto';
import StatusDto from 'Models/Licence/Dto/StatusDto';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import DownloadFileItem from 'Models/BackOffice/DownloadFileItem';
import { LicenceSearchQuery } from 'Models/Licence/Dto/LicenceSearchQuery';
import LicenceDtoReadonly from 'Models/Licence/Dto/LicenceDto';
import PeopleDataReadonly from 'Models/Licence/Data/PeopleData';
import { ActiveLicenceStatuses } from 'Util/Constants/LicenceStatus';

/* ------------- Interfaces for ReduxSauce ------------- */
interface LicenceState {
   licence: Licence;
   licenceDto: readonly LicenceDto[];
   createLicence: StatusDto | null;
   createLicenceError: StatusDto | null;
   updateLicence: StatusDto | null;
   updateLicenceError: StatusDto | null;
   licenceCardExtract: readonly DownloadFileItem[];
   annualRenewal: string | null;
   licenceSearchResults: LicenceDtoReadonly[];
}

export type LicenceStateReadonly = Readonly<LicenceState>;

interface TypeNames {
   GET_LICENCE_BY_ID_REQUEST: string;
   GET_LICENCE_BY_ID_SUCCESS: string;
   GET_LICENCE_BY_ID_FAILURE: string;

   GET_LICENCE_HISTORY_BY_ID_REQUEST: string;
   GET_LICENCE_HISTORY_BY_ID_SUCCESS: string;
   GET_LICENCE_HISTORY_BY_ID_FAILURE: string;
   GET_LICENCE_HISTORY_BY_ID_RESET: string;

   CREATE_LICENCE_REQUEST: string;
   CREATE_LICENCE_SUCCESS: string;
   CREATE_LICENCE_FAILURE: string;
   CREATE_LICENCE_RESET: string;

   UPDATE_LICENCE_REQUEST: string;
   UPDATE_LICENCE_SUCCESS: string;
   UPDATE_LICENCE_FAILURE: string;
   UPDATE_LICENCE_RESET: string;

   EXPORT_LICENCE_CARDS_REQUEST: string;
   EXPORT_LICENCE_CARDS_SUCCESS: string;
   EXPORT_LICENCE_CARDS_FAILURE: string;

   ANNUAL_RENEWAL_REQUEST: string;
   ANNUAL_RENEWAL_SUCCESS: string;
   ANNUAL_RENEWAL_FAILURE: string;

   SEARCH_LICENCES_REQUEST: string;
   SEARCH_LICENCES_SUCCESS: string;
   SEARCH_LICENCES_FAILURE: string;
}

type LicenceTypeNames = Readonly<TypeNames>;

export interface LicenceCreators {
   getLicenceByIdRequest: (id: string) => AnyAction;
   getLicenceByIdSuccess: (data: Licence | undefined) => AnyAction;
   getLicenceByIdFailure: (error: ApiError) => AnyAction;

   getLicenceHistoryByIdRequest: (id: string) => AnyAction;
   getLicenceHistoryByIdSuccess: (data: LicenceDto | undefined) => AnyAction;
   getLicenceHistoryByIdFailure: (error: ApiError) => AnyAction;
   getLicenceHistoryByIdReset: () => AnyAction;

   createLicenceRequest: (data: CreateLicenceDto) => AnyAction;
   createLicenceSuccess: (data: StatusDto | undefined) => AnyAction;
   createLicenceFailure: (error: StatusDto | undefined) => AnyAction;
   createLicenceReset: () => AnyAction;

   updateLicenceRequest: (data: UpdateLicenceDto) => AnyAction;
   updateLicenceSuccess: (data: StatusDto | undefined) => AnyAction;
   updateLicenceFailure: (error: StatusDto | undefined) => AnyAction;
   updateLicenceReset: () => AnyAction;

   exportLicenceCardsRequest: (data?: string) => AnyAction;
   exportLicenceCardsSuccess: (
      data: DownloadFileItem[] | undefined
   ) => AnyAction;
   exportLicenceCardsFailure: (error: ApiError) => AnyAction;

   annualRenewalRequest: () => AnyAction;
   annualRenewalSuccess: (data: string | undefined) => AnyAction;
   annualRenewalFailure: (error: ApiError) => AnyAction;

   searchLicencesRequest: (query: LicenceSearchQuery) => AnyAction;
   searchLicencesSuccess: (data: LicenceDto[] | undefined) => AnyAction;
   searchLicencesFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: LicenceStateReadonly = {
   licence: {
      currentLicences: [],
      nextLicences: [],
      otherLicences: [],
      currentData: {} as PeopleDataReadonly,
      nextData: {} as PeopleDataReadonly,
   },
   licenceDto: [],
   createLicence: null,
   createLicenceError: null,
   updateLicence: null,
   updateLicenceError: null,
   licenceCardExtract: [],
   annualRenewal: null,
   licenceSearchResults: [],
};

/* ------------- Reducers ------------- */
const getLicenceByIdSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, licence: action.data };
};

const getLicenceHistoryByIdSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, licenceDto: action.data };
};

const getLicenceHistoryByIdReset = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, licenceDto: [] };
};

const createLicenceSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, createLicence: action.data };
};

const createLicenceFailure = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, createLicenceError: action.data };
};
const createLicenceReset = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, createLicenceError: null, createLicence: null };
};

const updateLicenceSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, updateLicence: action.data };
};

const updateLicenceFailure = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, updateLicenceError: action.data };
};
const updateLicenceReset = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, updateLicenceError: null, updateLicence: null };
};

const exportLicenceCardsSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, licenceCardExtract: action.data };
};

const annualRenewalSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, annualRenewal: action.data };
};

const annualRenewalFailure = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, annualRenewal: action.data };
};

const searchLicencesSuccess = (
   state: LicenceStateReadonly,
   action: AnyAction
): LicenceStateReadonly => {
   return { ...state, licenceSearchResults: action.data.results };
};

const searchLicencesReset = (
   state: LicenceStateReadonly
): LicenceStateReadonly => {
   return { ...state, licenceSearchResults: [] };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   LicenceTypeNames,
   LicenceCreators
>('licence', {
   getLicenceByIdRequest: ['id'],
   getLicenceByIdSuccess: ['data'],
   getLicenceByIdFailure: ['error'],

   getLicenceHistoryByIdRequest: ['id'],
   getLicenceHistoryByIdSuccess: ['data'],
   getLicenceHistoryByIdFailure: ['error'],
   getLicenceHistoryByIdReset: [],

   createLicenceRequest: ['data'],
   createLicenceSuccess: ['data'],
   createLicenceFailure: ['data'],
   createLicenceReset: [],

   updateLicenceRequest: ['updateData'],
   updateLicenceSuccess: ['data'],
   updateLicenceFailure: ['data'],
   updateLicenceReset: [],

   exportLicenceCardsRequest: ['exportType'],
   exportLicenceCardsSuccess: ['data'],
   exportLicenceCardsFailure: ['error'],

   annualRenewalRequest: [],
   annualRenewalSuccess: ['data'],
   annualRenewalFailure: ['error'],

   searchLicencesRequest: ['query'],
   searchLicencesSuccess: ['data'],
   searchLicencesFailure: ['error'],
});

export const LicenceTypes = Types;
export const LicenceActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_LICENCE_BY_ID_SUCCESS]: getLicenceByIdSuccess,
   [Types.GET_LICENCE_HISTORY_BY_ID_SUCCESS]: getLicenceHistoryByIdSuccess,
   [Types.GET_LICENCE_HISTORY_BY_ID_RESET]: getLicenceHistoryByIdReset,
   [Types.CREATE_LICENCE_SUCCESS]: createLicenceSuccess,
   [Types.CREATE_LICENCE_FAILURE]: createLicenceFailure,
   [Types.CREATE_LICENCE_RESET]: createLicenceReset,
   [Types.UPDATE_LICENCE_SUCCESS]: updateLicenceSuccess,
   [Types.UPDATE_LICENCE_FAILURE]: updateLicenceFailure,
   [Types.UPDATE_LICENCE_RESET]: updateLicenceReset,
   [Types.EXPORT_LICENCE_CARDS_SUCCESS]: exportLicenceCardsSuccess,
   [Types.SEARCH_LICENCES_SUCCESS]: searchLicencesSuccess,
   [Types.SEARCH_LICENCES_REQUEST]: searchLicencesReset,

   [Types.ANNUAL_RENEWAL_SUCCESS]: annualRenewalSuccess,
   [Types.ANNUAL_RENEWAL_FAILURE]: annualRenewalFailure,
});

/* ------------- Selectors ------------- */

export const selectLicence = (state: StateReadonly): Licence =>
   state.licence.licence;

export const selectLicenceHistory = (
   state: StateReadonly
): readonly LicenceDto[] => state.licence.licenceDto;

export const selectCreateLicence = (state: StateReadonly): StatusDto | null =>
   state.licence.createLicence;

export const selectCreateLicenceError = (
   state: StateReadonly
): StatusDto | null => state.licence.createLicenceError;

export const selectUpdateLicence = (state: StateReadonly): StatusDto | null =>
   state.licence.updateLicence;

export const selectUpdateLicenceError = (
   state: StateReadonly
): StatusDto | null => state.licence.updateLicenceError;

export const selectExportedLicenceCards = (
   state: StateReadonly
): readonly DownloadFileItem[] => state.licence.licenceCardExtract;

export const selectLicenceSearchResults = (
   state: StateReadonly
): LicenceDtoReadonly[] => state.licence.licenceSearchResults;

export const selectCurrentActiveLicences = (
   state: StateReadonly
): readonly LicenceDtoReadonly[] =>
   state.licence.licenceDto.filter(l => {
      return ActiveLicenceStatuses.some(s => {
         return s === l.licenceStatusCode;
      });
   });
