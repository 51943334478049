import { ApplicationCondition } from 'Models/Application/Data/ApplicationCondition';
import { ApplicationStatus } from 'Models/Metadata/ApplicationStatus';

export const isCriteriaMet = (
   conditionCodes: readonly string[],
   applicationConditions: readonly ApplicationCondition[]
): boolean => {
   if (conditionCodes.length === 0) return true;

   return conditionCodes.every((cc): boolean => {
      const condition = applicationConditions.find(
         ac => ac.conditionCode === cc
      );
      return condition ? condition.isChecked : false;
   });
};

export const isApplicationEditable = (
   statuses: readonly ApplicationStatus[],
   applicationStatusCode: string
): boolean => {
   const appStatus = statuses.find(
      appStatus => appStatus.applicationStatusCode === applicationStatusCode
   );
   return !appStatus ? true : !appStatus.isFinalState;
};

export const containsTrainingProviderCondition = (
   appConditions: readonly ApplicationCondition[]
): boolean => {
   return appConditions.some(ac => ac.styleCode === 'DDT');
};
