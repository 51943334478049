import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { ReadonlyGlobalNotification } from 'Models/Notifications/GlobalNotification';
import { withAccessToken, createApi } from '.';

export interface GlobalNotificationServiceType {
   updateGlobalNotification(
      notification: ReadonlyGlobalNotification
   ): Promise<ApiResponse<ReadonlyGlobalNotification>>;
   getGlobalNotification(): Promise<ApiResponse<ReadonlyGlobalNotification>>;
}

const create = (baseURL: string | undefined): GlobalNotificationServiceType => {
   const api = createApi({ baseURL });

   const updateGlobalNotification = (
      notification: ReadonlyGlobalNotification
   ): Promise<ApiResponse<ReadonlyGlobalNotification>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/GlobalNotification`, notification);
   };

   const getGlobalNotification = (): Promise<ApiResponse<
      ReadonlyGlobalNotification
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/GlobalNotification`);
   };

   return {
      updateGlobalNotification,
      getGlobalNotification,
   };
};

export default {
   create,
};
