import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import LetterReadonly from 'Models/Letters/Data/Letter';
import FileDtoReadonly from 'Models/Letters/Dto/FileDto';

export interface LetterServiceType {
   getContactLetter(
      contactId: string,
      letterId: string
   ): Promise<ApiResponse<FileDtoReadonly>>;

   getLetters(): Promise<ApiResponse<LetterReadonly>>;
}

const create = (baseURL: string | undefined): LetterServiceType => {
   const api = createApi({ baseURL });

   const getContactLetter = (
      contactId: string,
      letterId: string
   ): Promise<ApiResponse<FileDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Letter/GetContactLetter/${contactId}/${letterId}`);
   };

   const getLetters = (): Promise<ApiResponse<LetterReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get('/Letter/GetLetters');
   };

   return {
      getContactLetter,
      getLetters,
   };
};

export default {
   create,
};
