import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
   EDIT_SUPERVISION,
   EditSupervisorModalMode,
} from './SupervisionModalMode';
import Button from 'react-md/lib/Buttons';
import {
   SupervisionActions,
   SupervisionTypes,
} from 'State/Redux/SupervisionRedux';
import {
   AsyncActions,
   selectIsLoading,
   selectIsSuccess,
} from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import LoadingButton from 'Components/Common/LoadingButton';
import { getCurrentDate } from 'Util/Helpers/Date';
import SupervisionReadonly from 'Models/Supervision/Data/Supervision';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface EndImmediatelyModalBodyProps {
   selectedSupervision: SupervisionReadonly | null;
   updateShowModal: (showModal: boolean) => void;
   updateModalMode: (modalMode: EditSupervisorModalMode) => void;
}

const EndImmediatelyModalBody = ({
   selectedSupervision,
   updateShowModal,
   updateModalMode,
}: Readonly<EndImmediatelyModalBodyProps>): JSX.Element => {
   const isSuccess = useSelector(
      selectIsSuccess([SupervisionTypes.CHANGE_SUPERVISION_END_DATE_REQUEST])
   );
   const isLoading = useSelector(
      selectIsLoading([SupervisionTypes.CHANGE_SUPERVISION_END_DATE_REQUEST])
   );
   const dispatch = useDispatch();

   useEffect((): (() => void) => {
      if (isSuccess) {
         toast.success('Supervision end date changed successfully');
         updateShowModal(false);
      }
      return (): void => {
         dispatch(
            AsyncActions.resetAsync([
               SupervisionTypes.CHANGE_SUPERVISION_END_DATE_REQUEST,
            ])
         );
      };
   }, [dispatch, updateShowModal, isSuccess]);

   return (
      <div className="md-grid md-cell--12 supervision-modal">
         <div className="md-grid md-cell--12 grey-background form-section">
            <h3 className="md-cell md-cell--12">End Immediately</h3>
            <div className="md-grid md-cell--12">
               Are you sure you want to end the supervision period?
            </div>
            <div className="md-grid md-cell--12">
               <LoadingButton
                  isLoading={isLoading}
                  onClick={(): void => {
                     if (
                        selectedSupervision &&
                        selectedSupervision.supervisionId
                     ) {
                        dispatch(
                           SupervisionActions.changeSupervisionEndDateRequest({
                              supervisionId: selectedSupervision.supervisionId,
                              endDate: getCurrentDate(),
                              endImmediately: true,
                           })
                        );
                     }
                  }}
                  onKeyUp={(keyPress): void => {
                     if (
                        isEnterKeyPress(keyPress) &&
                        selectedSupervision &&
                        selectedSupervision.supervisionId
                     ) {
                        dispatch(
                           SupervisionActions.changeSupervisionEndDateRequest({
                              supervisionId: selectedSupervision.supervisionId,
                              endDate: getCurrentDate(),
                              endImmediately: true,
                           })
                        );
                     }
                  }}
                  flat
                  swapTheming
                  primary
               >
                  Yes
               </LoadingButton>
               <Button
                  onClick={(): void => {
                     updateModalMode(EDIT_SUPERVISION);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress))
                        updateModalMode(EDIT_SUPERVISION);
                  }}
                  flat
                  swapTheming
                  secondary
               >
                  Cancel
               </Button>
            </div>
         </div>
      </div>
   );
};

export default React.memo(EndImmediatelyModalBody);
