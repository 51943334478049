import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import userManager from 'Util/Auth/AuthManager';
import { selectIsUserLoading, selectUser } from 'State/Redux/OidcRedux';
import './auth.scss';

const Logout = (): JSX.Element => {
   const user = useSelector(selectUser);
   const isUserLoading = useSelector(selectIsUserLoading);
   const [isRedirected, setIsRedirected] = useState<boolean>(false);

   if (isUserLoading) {
      return <></>;
   }

   if (user) {
      /* eslint-disable @typescript-eslint/camelcase */
      const { id_token } = user;
      if (!isRedirected) {
         userManager.signoutRedirect({ id_token_hint: id_token });
         setIsRedirected(true);
         userManager.removeUser();
      }
   } else {
      if (!isRedirected) {
         userManager.signoutRedirect();
         setIsRedirected(true);
      }
   }

   return <div className="auth-loading"></div>;
};

export default Logout;
