import { Condition } from 'Models/Metadata/Condition';

// style codes
export const STYLECODE_CHECKBOX = 'CB';
export const STYLECODE_COUNTRIES = 'DDC';
export const STYLECODE_TRAINING_PROVIDER = 'DDT';
export const STYLECODE_GAS_EXEMPTION_CLASS = 'DDG';
export const STYLECODE_YEAR = 'DDY';
export const STYLECODE_YEAR5 = 'DDY5';
export const STYLECODE_DATEPICKER = 'DP';
export const STYLECODE_EXAM_LOCATION = 'EXDL';
export const STYLECODE_EMPLOYER = 'RO';
export const STYLECODE_SUPERVISION = 'SP';
export const STYLECODE_SYSTEM = 'SY';
export const STYLECODE_F2P = 'F2P';

export type ConditionFieldType =
   | 'checkbox'
   | 'textbox'
   | 'country-list'
   | 'gas-exemption-class-list'
   | 'licence-year-list'
   | 'training-provider-list'
   | 'exam-location-list'
   | 'employer-list'
   | 'supervision'
   | 'datepicker'
   | 'f2p';

export const getFieldType = (condition: Condition): ConditionFieldType => {
   if (condition.styleCode === STYLECODE_CHECKBOX) return 'checkbox';
   if (condition.styleCode === STYLECODE_DATEPICKER) return 'datepicker';
   if (condition.styleCode === STYLECODE_COUNTRIES) return 'country-list';
   if (
      condition.styleCode === STYLECODE_YEAR ||
      condition.styleCode === STYLECODE_YEAR5
   )
      return 'licence-year-list';
   if (condition.styleCode === STYLECODE_TRAINING_PROVIDER)
      return 'training-provider-list';
   if (condition.styleCode === STYLECODE_GAS_EXEMPTION_CLASS)
      return 'gas-exemption-class-list';
   if (condition.styleCode === STYLECODE_EXAM_LOCATION)
      return 'exam-location-list';
   if (condition.styleCode === STYLECODE_EMPLOYER) return 'employer-list';
   if (condition.styleCode === STYLECODE_SUPERVISION) return 'supervision';
   if (condition.styleCode === STYLECODE_SYSTEM) return 'checkbox';
   if (condition.styleCode === STYLECODE_F2P) return 'f2p';

   return 'textbox';
};
