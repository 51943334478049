import { call, put } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { PaymentServiceType } from '../Services/PaymentService';
import PaymentDetailReadOnly from 'Models/Payment/Data/PaymentDetail';
import { PaymentActions } from 'State/Redux/PaymentRedux';
import PaymentDetailFeeRequestLineReadOnly from 'Models/Payment/Data/PaymentDetailFeeRequestLine';
import PaymentEditDtoReadonly from 'Models/Payment/Dto/PaymentEditDto';
import PaymentResponseReadonly from 'Models/Payment/Data/PaymentResponse';
import PaymentRedirectRequestReadonly from 'Models/Payment/Dto/PaymentRedirectRequestDto';
import PaymentRedirectResponseReadOnly from 'Models/Payment/Data/PaymentRedirectResponse';
import PaymentDataDtoReadOnly from 'Models/Payment/Dto/PaymentDataDto';
import PaymentCheckoutRequestReadonly from 'Models/Payment/Dto/PaymentCheckoutRequestDto';
import PaymentCheckoutResponseReadOnly from 'Models/Payment/Data/PaymentCheckoutResponse';

interface PaymentParams {
   type: string;
   token: string;
   applicationId: number | null;
   feeRequestId: number | null;
   feeRequestLineId: number | null;
   paymentId: number | null;
   editModel: PaymentEditDtoReadonly;
   result: string;
   paymentData: PaymentDataDtoReadOnly;
   redirectUrlRequestData: PaymentRedirectRequestReadonly;
   referenceNumber: string;
   paymentCheckoutRequestData: PaymentCheckoutRequestReadonly;
}

export function* getPaymentDetail(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { applicationId, feeRequestId, feeRequestLineId, paymentId } = action;
   const paymentResponse = yield call(
      paymentService.getPaymentDetail,
      applicationId,
      feeRequestId,
      feeRequestLineId,
      paymentId
   );
   const response = paymentResponse as ApiResponse<PaymentDetailReadOnly>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.getPaymentDetailSuccess(response.data));
   } else {
      yield put(
         PaymentActions.getPaymentDetailFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPaymentDetailFeeRequestLine(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { feeRequestLineId } = action;
   const paymentResponse = yield call(
      paymentService.getPaymentDetailFeeRequestLine,
      feeRequestLineId || 0
   );
   const response = paymentResponse as ApiResponse<
      PaymentDetailFeeRequestLineReadOnly
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         PaymentActions.getPaymentDetailFeeRequestLineSuccess(response.data)
      );
   } else {
      yield put(
         PaymentActions.getPaymentDetailFeeRequestLineFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* updatePaymentDate(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { editModel } = action;
   const paymentResponse = yield call(
      paymentService.updatePaymentDate,
      editModel
   );

   const response = paymentResponse as ApiResponse<boolean>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.updatePaymentDateSuccess(response.data));
   } else {
      yield put(
         PaymentActions.updatePaymentDateFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* cancelPayment(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { editModel } = action;
   const paymentResponse = yield call(paymentService.cancelPayment, editModel);

   const response = paymentResponse as ApiResponse<boolean>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.cancelPaymentSuccess(response.data));
   } else {
      yield put(
         PaymentActions.cancelPaymentFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPaymentResponse(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { result } = action;
   const paymentResponse = yield call(
      paymentService.getPaymentResponse,
      result
   );
   const response = paymentResponse as ApiResponse<PaymentResponseReadonly>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.getPaymentResponseSuccess(response.data));
   } else {
      yield put(
         PaymentActions.getPaymentResponseFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPaymentProcessingStatus(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { result } = action;
   const paymentResponse = yield call(
      paymentService.getPaymentProcessingStatus,
      result
   );
   const response = paymentResponse as ApiResponse<boolean>;

   if (response.status && response.ok && response.data !== undefined) {
      yield put(
         PaymentActions.getPaymentProcessingStatusSuccess(response.data)
      );
   } else {
      yield put(
         PaymentActions.getPaymentProcessingStatusFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* savePaymentData(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   yield put(PaymentActions.savePaymentDataReset());

   const { paymentData } = action;
   const paymentResponse = yield call(
      paymentService.savePaymentData,
      paymentData
   );

   const response = paymentResponse as ApiResponse<string>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.savePaymentDataSuccess(response.data));
   } else {
      yield put(
         PaymentActions.savePaymentDataFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPaymentRedirectUrl(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   yield put(PaymentActions.getPaymentRedirectUrlReset());

   const { redirectUrlRequestData } = action;
   const paymentResponse = yield call(
      paymentService.getPaymentRedirectUrl,
      redirectUrlRequestData
   );

   const response = paymentResponse as ApiResponse<
      PaymentRedirectResponseReadOnly
   >;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.getPaymentRedirectUrlSuccess(response.data));
   } else {
      yield put(PaymentActions.getPaymentRedirectUrlFailure(response.data));
   }
}

export function* saveNonOnlinePayment(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   yield put(PaymentActions.saveNonOnlinePaymentReset());

   const { paymentData } = action;
   const paymentResponse = yield call(
      paymentService.saveNonOnlinePayment,
      paymentData
   );

   const response = paymentResponse as ApiResponse<number>;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.saveNonOnlinePaymentSuccess(response.data));
   } else {
      yield put(
         PaymentActions.saveNonOnlinePaymentFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : '',
         })
      );
   }
}

export function* getPaymentTransactionAmount(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { referenceNumber } = action;
   const paymentResponse = yield call(
      paymentService.getPaymentTransactionAmount,
      referenceNumber
   );
   const response = paymentResponse as ApiResponse<number>;

   if (response.status && response.ok && response.data !== undefined) {
      yield put(
         PaymentActions.getPaymentTransactionAmountSuccess(response.data)
      );
   } else {
      yield put(
         PaymentActions.getPaymentTransactionAmountFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getCardFee(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const { referenceNumber } = action;
   const paymentResponse = yield call(
      paymentService.getCardFee,
      referenceNumber
   );
   const response = paymentResponse as ApiResponse<number>;
   if (response.status && response.ok && response.data !== undefined) {
      yield put(PaymentActions.getCardFeeSuccess(response.data));
   } else {
      yield put(
         PaymentActions.getCardFeeFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* submitPaymentCheckout(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   yield put(PaymentActions.submitPaymentCheckoutReset());

   const { paymentCheckoutRequestData } = action;
   const paymentResponse = yield call(
      paymentService.submitPaymentCheckout,
      paymentCheckoutRequestData
   );

   const response = paymentResponse as ApiResponse<
      PaymentCheckoutResponseReadOnly
   >;

   if (response.status && response.ok && response.data) {
      yield put(PaymentActions.submitPaymentCheckoutSuccess(response.data));
   } else {
      yield put(PaymentActions.submitPaymentCheckoutFailure(response.data));
   }
}

export function* getAmIAllowed(
   paymentService: Readonly<PaymentServiceType>,
   action: Readonly<PaymentParams>
): Generator {
   const paymentResponse = yield call(paymentService.getAmIAllowed);
   const response = paymentResponse as ApiResponse<boolean>;

   if (response.status && response.ok && response.data !== undefined) {
      yield put(PaymentActions.getAmIAllowedSuccess(response.data));
   } else {
      yield put(
         PaymentActions.getAmIAllowedFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
