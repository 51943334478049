import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DialogContainer } from 'react-md/lib/Dialogs';
import { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { Button } from 'react-md/lib/Buttons';
import RegistrationPanel from './Panels/RegistrationPanel';
import RegistrationDetailsPanel from './Panels/RegistrationDetailsPanel';
import { DefaultRegistration } from 'Models/Registration/Registration';
import {
   selectAllRegistrations,
   selectContact,
   ContactActions,
} from 'State/Redux/ContactRedux';
import { find } from 'lodash-es';
import './RegistrationTab.scss';
import UserCard from 'Components/Common/UserCard/UserCard';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { usePermissions } from 'Util/Helpers/Permissions';

const RegistrationTab = (): JSX.Element => {
   const registrations = useSelector(selectAllRegistrations);
   const [showModal, setShowModal] = useState(false);
   const [editModel, setEditModel] = useState({
      ...DefaultRegistration,
   });
   const [dialogEditMode, setDialogEditMode] = useState(false);
   const [dialogTitle, setDialogTitle] = useState('Add New Registration');

   const contact = useSelector(selectContact);
   const dispatch = useDispatch();

   useEffect((): void => {
      if (contact.contactId)
         dispatch(
            ContactActions.getRegistrationsByContactRequest(
               contact.contactId.toString()
            )
         );
   }, [contact.contactId, dispatch]);

   const setSelectedModel = (id: number): void => {
      const model = find(registrations, ['registrationId', id]);
      if (model) {
         setEditModel({ ...model });
         setDialogEditMode(true);
         setDialogTitle('Edit Registration');
         setShowModal(true);
      }
   };

   return (
      <>
         <DialogContainer
            id="registration-dialog"
            className="registration-dialog"
            visible={showModal}
            title={dialogTitle}
            onHide={(): void => {
               setShowModal(false);
            }}
            focusOnMount={false}
            portal
            width={900}
         >
            <RegistrationDetailsPanel
               registrationModel={editModel}
               closeDialog={(): void => {
                  setShowModal(false);
               }}
               updateDialogTitle={(title: string): void => {
                  setDialogTitle(title);
               }}
               editMode={dialogEditMode}
            />
         </DialogContainer>

         <ExpansionList className="registration-tab md-cell md-cell--12">
            <ExpansionPanel
               label={
                  <>
                     <div>Registration </div>
                     {usePermissions('Registration.Create') && (
                        <Button
                           icon
                           className="plus-btn"
                           onClick={(
                              event: React.MouseEvent<HTMLElement, MouseEvent>
                           ): void => {
                              event.preventDefault();
                              event.stopPropagation();
                              setEditModel({
                                 ...DefaultRegistration,
                              });
                              setDialogEditMode(false);
                              setDialogTitle('Add New Registration');
                              setShowModal(true);
                           }}
                           onKeyUp={(keyPress): void => {
                              if (!isEnterKeyPress(keyPress)) return;
                              setEditModel({
                                 ...DefaultRegistration,
                              });
                              setDialogEditMode(false);
                              setDialogTitle('Add New Registration');
                              setShowModal(true);
                           }}
                        >
                           <FaIcon icon="plus" />
                           <p>Create</p>
                        </Button>
                     )}
                  </>
               }
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <div className="md-grid md-cell--12">
                  <UserCard />
               </div>
            </ExpansionPanel>
            <ExpansionPanel
               label="Registration History"
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <RegistrationPanel
                  registrations={registrations}
                  selectToEdit={setSelectedModel}
               />
            </ExpansionPanel>
         </ExpansionList>
      </>
   );
};

export default RegistrationTab;
