import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import DashboardModelReadonly from 'Models/Dashboard/Dashboard';
import { withAccessToken, createApi } from '.';

export interface DashboardServiceType {
   getDashboardData(): Promise<ApiResponse<DashboardModelReadonly[]>>;
}

const create = (baseURL: string | undefined): DashboardServiceType => {
   const api = createApi({ baseURL });

   const getDashboardData = (): Promise<ApiResponse<
      DashboardModelReadonly[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get('/Dashboard/GetDashboardData');
   };

   return {
      getDashboardData,
   };
};

export default {
   create,
};
