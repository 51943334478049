import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import AddFeeMaintenance from './AddFeeMaintenance';
import SearchFeeMaintenance from './SearchFeeMaintenance';

const feeMaintenanceTabs: readonly RoutedTab[] = [
   {
      label: 'Search',
      route: '',
      component: SearchFeeMaintenance,
      pageTitle: 'Search',
      exact: true,
      permissions: 'FeeMaintenance.Read',
   },
   {
      label: 'Add',
      route: '/add',
      component: AddFeeMaintenance,
      pageTitle: 'Add',
      permissions: 'FeeMaintenance.Create',
   },
];

const FeeMaintenance = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={feeMaintenanceTabs}
         baseUrl="/superuser/fee-maintenance"
         panelClassName="feeMaintenance"
         pageTitle="Fee Maintenance"
      />
   );
};

export default FeeMaintenance;
