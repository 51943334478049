import { put, call } from 'redux-saga/effects';
import { ContactActions } from '../Redux/ContactRedux';
import { ContactServiceType } from '../Services/ContactService';
import { ApiResponse } from 'apisauce';
import Contact from 'Models/Contact/Data/Contact';
import ContactSearchResult from 'Models/Contact/Data/ContactSearchResult';
import { ContactSearchQuery } from 'Models/Contact/Dto/ContactSearchQuery';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import { PaymentSearchQuery } from 'Models/Contact/Dto/PaymentSearchQuery';
import ContactNote from 'Models/Contact/Data/ContactNote';
import { ContactEditModel } from 'Models/Contact/Data/ContactEditModel';
import ContactNoteInputModelReadonly from 'Models/Contact/Data/ContactNoteInputModel';
import Registration from 'Models/Registration/Registration';
import RegistrationNumberContactValidationReadonly from 'Models/Contact/Dto/RegistrationNumberContactValidation';
import UserEditModel from 'Models/SuperUser/UserEditModel';
import { ApplicationActions } from 'State/Redux/ApplicationRedux';
import { CONTACT_TYPE_PRACTITIONER } from 'Util/Constants/Contact';

interface ContactParams {
   type: string;
   token: string;
   id: string;
   applicationIds: readonly number[];
   data: ContactEditModel;
   adminUserData: UserEditModel;
   adminUserId: string;
   adminUserSearchFilter: UserEditModel;
   noteData: ContactNoteInputModelReadonly;
   registration: Registration;
   contactValidations: RegistrationNumberContactValidationReadonly[];
}

export function* getContactById(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { id } = action;
   const contactResponse = yield call(contactService.getContactById, id);
   const response = contactResponse as ApiResponse<Contact>;

   if (response.status && response.ok) {
      yield put(ContactActions.getContactByIdSuccess(response.data));
   } else {
      yield put(
         ContactActions.getContactByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getContactByRegistrationNumber(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { id } = action;
   const contactResponse = yield call(
      contactService.getContactByRegistrationNumber,
      id
   );
   const response = contactResponse as ApiResponse<Contact>;

   if (response.status && response.ok) {
      yield put(
         ContactActions.getContactByRegistrationNumberSuccess(response.data)
      );
   } else {
      yield put(
         ContactActions.getContactByRegistrationNumberFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getContactScreenData(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { id } = action;

   yield put(ContactActions.getContactScreenDataReset());

   const contactResponse = yield call(contactService.getContactScreenData, id);
   const response = contactResponse as ApiResponse<Contact>;

   if (response.status && response.ok) {
      yield put(ContactActions.getContactScreenDataSuccess(response.data));
   } else {
      yield put(
         ContactActions.getContactScreenDataFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveContactData(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const contactResponse = yield call(
      contactService.saveContactData,
      action.data
   );
   const response = contactResponse as ApiResponse<Contact>;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.saveContactDataSuccess(response.data));
      // If a practitioner contact was edited,
      if (response.data.contactTypeCode === CONTACT_TYPE_PRACTITIONER) {
         yield put(
            ApplicationActions.getApplicationPendingRenewalRequest(
               response.data.contactId.toString()
            )
         );
      }
   } else {
      yield put(
         ContactActions.saveContactDataFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Saving Contact',
         })
      );
   }
}

export function* saveAdminUser(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const contactResponse = yield call(
      contactService.saveAdminUser,
      action.adminUserData
   );
   const response = contactResponse as ApiResponse<number>;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.saveAdminUserSuccess(response.data));
   } else {
      yield put(
         ContactActions.saveAdminUserFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Saving User',
         })
      );
   }
}

export function* getAdminUser(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const contactResponse = yield call(
      contactService.getAdminUser,
      action.adminUserId
   );
   const response = contactResponse as ApiResponse<UserEditModel>;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.getAdminUserSuccess(response.data));
   } else {
      yield put(
         ContactActions.getAdminUserFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error getting User',
         })
      );
   }
}

export function* searchAdminUser(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const contactResponse = yield call(
      contactService.searchAdminUser,
      action.adminUserSearchFilter
   );
   const response = contactResponse as ApiResponse<UserEditModel[]>;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.searchAdminUserSuccess(response.data));
   } else {
      yield put(
         ContactActions.searchAdminUserFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : 'Error Searching User',
         })
      );
   }
}

export function* getContactNotes(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { id } = action;
   const contactNotesResponse = yield call(contactService.getContactNotes, id);
   const response = contactNotesResponse as ApiResponse<readonly ContactNote[]>;

   if (response.status && response.ok) {
      yield put(ContactActions.getContactNotesSuccess(response.data));
   } else {
      yield put(
         ContactActions.getContactNotesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getContactPaymentsHistory(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { id } = action;

   yield put(ContactActions.getContactPaymentsHistoryReset());

   const contactPaymentsHistoryResponse = yield call(
      contactService.getContactPaymentsHistory,
      id
   );
   const response = contactPaymentsHistoryResponse as ApiResponse<
      readonly PaymentSearchResult[]
   >;

   if (response.status && response.ok) {
      yield put(ContactActions.getContactPaymentsHistorySuccess(response.data));
   } else {
      yield put(
         ContactActions.getContactNotesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

interface SearchParams extends ContactParams {
   query: ContactSearchQuery;
}

export function* searchContact(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<SearchParams>
): Generator {
   const contactResponse = yield call(
      contactService.searchContact,
      action.query
   );
   const response = contactResponse as ApiResponse<
      readonly ContactSearchResult[]
   >;

   if (response.status && response.ok) {
      yield put(ContactActions.searchContactSuccess(response.data || []));
   } else {
      yield put(
         ContactActions.searchContactFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

interface PaymentSearchParams extends ContactParams {
   query: PaymentSearchQuery;
}

export function* searchPayments(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<PaymentSearchParams>
): Generator {
   const contactResponse = yield call(
      contactService.searchPayments,
      action.query
   );

   const response = contactResponse as ApiResponse<
      readonly PaymentSearchResult[]
   >;

   if (response.status && response.ok) {
      yield put(ContactActions.searchPaymentsSuccess(response.data || []));
   } else {
      yield put(
         ContactActions.searchContactFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveContactNote(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { noteData } = action;
   const contactNoteResponse = yield call(
      contactService.saveContactNote,
      noteData
   );
   const response = contactNoteResponse as ApiResponse<ContactNote>;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.saveContactNoteSuccess(response.data));
   } else {
      yield put(
         ContactActions.saveContactNoteFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getRegistrationsByContact(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const registrationResponse = yield call(
      contactService.getRegistrationsByContact,
      action.id
   );
   const response = registrationResponse as ApiResponse<
      readonly Registration[]
   >;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.getRegistrationsByContactSuccess(response.data));
   } else {
      yield put(
         ContactActions.getRegistrationsByContactFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : '',
         })
      );
   }
}

export function* saveRegistration(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const registrationResponse = yield call(
      contactService.saveRegistration,
      action.registration
   );
   const response = registrationResponse as ApiResponse<Registration>;

   if (response.status && response.ok && response.data) {
      yield put(ContactActions.saveRegistrationSuccess(response.data));
   } else {
      yield put(
         ContactActions.saveRegistrationFailure({
            code: 'Error',
            status: response.status,
            message:
               response.data && typeof response.data === 'string'
                  ? response.data
                  : '',
         })
      );
   }
}

export function* getTrainingProviders(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const contactResponse = yield call(contactService.getTrainingProviders);
   const response = contactResponse as ApiResponse<readonly Contact[]>;

   if (response.status && response.ok) {
      yield put(
         ContactActions.getTrainingProvidersSuccess(response.data || [])
      );
   } else {
      yield put(
         ContactActions.getTrainingProvidersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* validateContactRegistrationNumbers(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { contactValidations } = action;
   const contactResponse = yield call(
      contactService.validateContactRegistrationNumbers,
      contactValidations
   );
   const response = contactResponse as ApiResponse<
      RegistrationNumberContactValidationReadonly[]
   >;

   if (response.status && response.ok && response.data) {
      yield put(
         ContactActions.validateContactRegistrationNumbersSuccess(response.data)
      );
   } else {
      yield put(
         ContactActions.validateContactRegistrationNumbersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* exportNotesByContactId(
   contactService: Readonly<ContactServiceType>,
   action: Readonly<ContactParams>
): Generator {
   const { id } = action;
   const notesResponse = yield call(contactService.exportNotesByContactId, id);
   const response = notesResponse as ApiResponse<Blob>;

   if (response.status && response.ok) {
      yield put(ContactActions.exportNotesByContactIdSuccess(response.data));
   } else {
      yield put(
         ContactActions.exportNotesByContactIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
