import React from 'react';
import { CellInfo } from 'react-table';
import FaIcon from '../FaIcon/FaIcon';

export const CheckTimesIcon = (cell: CellInfo): JSX.Element => {
   if (cell.value === null) return <>-</>;

   const iconName = cell.value ? 'check' : 'times';
   return <FaIcon icon={iconName} />;
};
