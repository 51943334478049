import { combineReducers, AnyAction, Reducer } from 'redux';
import { reducer as oidcReducer, loadUser } from 'redux-oidc';
import {
   createActions,
   CreatedActions,
   Actions,
   DefaultActionTypes,
   DefaultActionCreators,
} from 'reduxsauce';
import configureStore from './CreateStore';
import rootSaga from '../Saga';
import { reducer as contactReducer } from './ContactRedux';
import { reducer as licenceReducer } from './LicenceRedux';
import { reducer as identityReducer } from './IdentityRedux';
import { reducer as resultReducer } from './ResultRedux';
import { reducer as asyncReducer } from './AsyncRedux';
import { reducer as supervisionReducer } from './SupervisionRedux';
import { reducer as systemParametersReducer } from './SystemParametersRedux';
import { reducer as paymentMethodReducer } from './PaymentMethodRedux';
import { reducer as registrationTypeReducer } from './RegistrationTypeRedux';
import { reducer as feeMaintenanceReducer } from './FeeMaintenanceRedux';
import { reducer as applicationReducer } from './ApplicationRedux';
import { reducer as complaintReducer } from './ComplaintRedux';
import { reducer as metadataReducer } from './MetadataRedux';
import { reducer as examinationReducer } from './ExaminationRedux';
import { reducer as courseReducer } from './CourseRedux';
import { reducer as contactPhotoReducer } from './ContactPhotoRedux';
import { reducer as feesPaymentReducer } from './FeesPaymentRedux';
import { reducer as paymentReducer } from './PaymentRedux';
import { reducer as letterReducer } from './LetterRedux';
import { reducer as globalNotificationReducer } from './GlobalNotificationRedux';
import { reducer as notificationReducer } from './NotificationRedux';
import { reducer as reportReducer } from './ReportRedux';
import { reducer as receiptReducer } from './ReceiptRedux';
import { reducer as boardMeetingDateReducer } from './BoardMeetingDateRedux';
import { reducer as financialExtractReducer } from './FinancialExtractRedux';
import { reducer as batchReducer } from './BatchRedux';
import { reducer as licenceTypeReducer } from './LicenceTypeRedux';
import { reducer as feeTypeReducer } from './FeeTypeRedux';
import { reducer as dashboardReducer } from './DashboardRedux';
import { reducer as fitAndProperReducer } from './FitAndProperRedux';
import { reducer as enumReducer } from './EnumRedux';
import userManager from 'Util/Auth/AuthManager';
import StateReadonly from './StateModel';

export function createScopedActions<
   T = DefaultActionTypes,
   C = DefaultActionCreators
>(reducerName: keyof StateReadonly, actions: Actions): CreatedActions<T, C> {
   const normalizedName = reducerName.toString().toUpperCase() + '/';
   return createActions<T, C>(actions, { prefix: normalizedName });
}

export type StateReducer = Reducer<StateReadonly, AnyAction>;

const finalReducers: StateReducer = combineReducers({
   oidc: oidcReducer,
   complaint: complaintReducer,
   contact: contactReducer,
   application: applicationReducer,
   licence: licenceReducer,
   supervision: supervisionReducer,
   systemParameters: systemParametersReducer,
   paymentMethod: paymentMethodReducer,
   registrationType: registrationTypeReducer,
   feeMaintenance: feeMaintenanceReducer,
   identity: identityReducer,
   examination: examinationReducer,
   course: courseReducer,
   metadata: metadataReducer,
   result: resultReducer,
   async: asyncReducer,
   contactPhoto: contactPhotoReducer,
   feePayment: feesPaymentReducer,
   payment: paymentReducer,
   letter: letterReducer,
   globalNotification: globalNotificationReducer,
   notification: notificationReducer,
   report: reportReducer,
   receipt: receiptReducer,
   boardMeetingDate: boardMeetingDateReducer,
   financialExtract: financialExtractReducer,
   batch: batchReducer,
   licenceType: licenceTypeReducer,
   feeType: feeTypeReducer,
   dashboard: dashboardReducer,
   fitAndProper: fitAndProperReducer,
   enum: enumReducer,
});

// eslint-disable-next-line prefer-const
let { store, sagasManager, sagaMiddleware } = configureStore(
   finalReducers,
   rootSaga
);
if (module.hot) {
   module.hot.accept((): void => {
      const nextRootReducer: StateReducer = require('./').reducers;

      store.replaceReducer(nextRootReducer);

      const newYieldedSagas: () => Generator = require('../Saga').default;
      sagasManager.cancel();
      sagasManager.toPromise().then((): void => {
         sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
   });
}

loadUser(store, userManager);

const finalStore = store;

export default finalStore;
