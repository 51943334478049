import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import InvoicesPaymentsPanel from './Panels/InvoicesPaymentsPanel';
import InvoicesPaymentsSearch from './InvoicesPaymentsSearch/InvoicesPaymentsSearch';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import UserCard from 'Components/Common/UserCard/UserCard';
import { Button } from 'react-md/lib/Buttons';
import {
   selectContactPaymentHistory,
   selectContact,
   ContactActions,
} from 'State/Redux/ContactRedux';
import { useSelector, useDispatch } from 'react-redux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface AdvancedSearchButtonProps {
   setAdvancedSearch: (setting: boolean) => void;
   registrationNumber: number | null;
   payments: readonly PaymentSearchResult[];
}

const AdvancedSearchButton = ({
   setAdvancedSearch,
   registrationNumber,
   payments,
}: Readonly<AdvancedSearchButtonProps>): JSX.Element => {
   if (payments && payments.length && registrationNumber) {
      return (
         <Button
            flat
            primary
            className="advanced-filter-button"
            onClick={(): void => setAdvancedSearch(true)}
            onKeyUp={(keyPress): void => {
               if (isEnterKeyPress(keyPress)) setAdvancedSearch(true);
            }}
         >
            Advanced Search
         </Button>
      );
   } else return <></>;
};

const InvoicesPaymentsTab = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const payments = useSelector(selectContactPaymentHistory);
   const dispatch = useDispatch();
   const [advancedSearch, setAdvancedSearch] = useState(false);

   useEffect((): void => {
      dispatch(
         ContactActions.getContactPaymentsHistoryRequest(
            contact.contactId.toString()
         )
      );
   }, [contact.contactId, dispatch]);

   return (
      <>
         {!advancedSearch ? (
            <ExpansionList className="md-cell md-cell--12">
               <ExpansionPanel
                  label="Invoices/Payments"
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <div className="md-grid md-cell--12">
                     <UserCard />
                  </div>
                  <div className="md-grid md-cell--12">
                     <div className="md-grid md-cell--6"></div>
                     <div className="md-grid md-cell--6">
                        <AdvancedSearchButton
                           payments={payments}
                           registrationNumber={contact.registrationNumber}
                           setAdvancedSearch={setAdvancedSearch}
                        />
                     </div>
                  </div>
               </ExpansionPanel>

               <ExpansionPanel
                  label="Invoices/Payments History"
                  footer={null}
                  expanded
                  expanderIcon={<></>}
                  onExpandToggle={FN_EMPTY_VOID}
               >
                  <InvoicesPaymentsPanel />
               </ExpansionPanel>
            </ExpansionList>
         ) : (
            <InvoicesPaymentsSearch setAdvancedSearch={setAdvancedSearch} />
         )}
      </>
   );
};

export default InvoicesPaymentsTab;
