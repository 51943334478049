import React, { useState, ChangeEvent, useRef, MutableRefObject } from 'react';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { SelectField } from 'react-md/lib/SelectFields';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { ContactEditModel } from 'Models/Contact/Data/ContactEditModel';
import * as PROFILE_HELPERS from 'Util/Helpers/Photo';
import * as PhotoConstants from 'Util/Constants/Photo';
import { toast } from 'react-toastify';
import PhotoEditor from 'Components/Common/PhotoEditor';
import AvatarEditor from 'react-avatar-editor';
import ContactPhotoReadonly from 'Models/Contact/Data/ContactPhoto';
import { useContactStatusList } from 'Util/Helpers/Metadata';
import { ValidationResult } from 'Components/Common/ModelValidator/ValidationModel';
import TextField from 'react-md/lib/TextFields';
import { ContactValidationFields } from '../CreateContact';
import {
   isNullOrWhiteSpace,
   getInputValidationClassName,
} from 'Util/Helpers/Validation';
import { REGISTRATION_NUMBER_LENGTH } from 'Util/Helpers/RegistrationNumber';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface PhotoSectionProps {
   model: ContactEditModel;
   updateModel: (model: ContactEditModel) => void;
   validationResult?: ValidationResult<ContactValidationFields>;
   isEditMode?: boolean;
   photoEditor: MutableRefObject<AvatarEditor | null>;
   contactPhoto?: ContactPhotoReadonly;
}

const PhotoSection = ({
   model,
   updateModel,
   validationResult,
   isEditMode,
   photoEditor,
   contactPhoto,
}: Readonly<PhotoSectionProps>): JSX.Element => {
   const inputOpenFileRef = useRef<HTMLInputElement>(null);

   const [imageUrl, setImageUrl] = useState('');

   const showOpenFileDialog = (): void => {
      const inputFileNode = inputOpenFileRef.current;
      if (inputFileNode) {
         inputFileNode.click();
      }
   };

   const validateFile = async (file: File): Promise<boolean> => {
      const fileExtension = file.name
         .substring(file.name.lastIndexOf('.') + 1)
         .toLowerCase();
      if (PhotoConstants.FILE_EXTENSION.indexOf(fileExtension) < 0) {
         toast.error(
            `The selected file is not supported. Please make sure that the file extension must be ${PROFILE_HELPERS.getSupportedFileExtension()}.`
         );
         return false;
      }

      if (file.size > PhotoConstants.MAX_FILE_SIZE_IN_BYTE) {
         toast.error(
            `File size must be less than ${PhotoConstants.MAX_FILE_SIZE_IN_MB}MB. Please select again.`
         );
         return false;
      }

      // check the image size
      const img = await PROFILE_HELPERS.loadImage(URL.createObjectURL(file));

      if (
         img.naturalWidth < PhotoConstants.MIN_PHOTO_WIDTH ||
         img.naturalWidth > PhotoConstants.MAX_PHOTO_WIDTH ||
         img.naturalHeight < PhotoConstants.MIN_PHOTO_HEIGHT ||
         img.naturalHeight > PhotoConstants.MAX_PHOTO_HEIGHT
      ) {
         toast.error(
            `Image size should be between ${PhotoConstants.MIN_PHOTO_WIDTH}x${PhotoConstants.MIN_PHOTO_HEIGHT} to ${PhotoConstants.MAX_PHOTO_WIDTH}x${PhotoConstants.MAX_PHOTO_HEIGHT} pixels`
         );
         return false;
      }

      return true;
   };

   const fileValueChanged = async (
      e: ChangeEvent<HTMLInputElement>
   ): Promise<void> => {
      const file = e.target.files ? e.target.files[0] : null;
      if (!file) return;

      const isValidFile = await validateFile(file);
      if (isValidFile) {
         setImageUrl(URL.createObjectURL(file));
      }
   };

   const cancelPhotoEditor = (): void => {
      setImageUrl('');
   };

   const renderPhoto = (): JSX.Element => {
      return (
         <>
            <p>Photo</p>
            <input
               ref={inputOpenFileRef}
               type="file"
               style={{ display: 'none' }}
               onChange={fileValueChanged}
            />
            {contactPhoto && contactPhoto.fileLocation ? (
               <div
                  className="upload-box profile-photo"
                  onClick={(): void => showOpenFileDialog()}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) showOpenFileDialog();
                  }}
                  style={{
                     backgroundImage: `url(${contactPhoto.fileLocation})`,
                  }}
               >
                  <p className="photo-text">Change/Add Photo</p>
               </div>
            ) : (
               <div
                  className="upload-box"
                  onClick={showOpenFileDialog}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) showOpenFileDialog();
                  }}
               >
                  <FaIcon icon="camera" />
                  <p className="photo-text">Add Photo</p>
               </div>
            )}
         </>
      );
   };

   const contactStatuses = useContactStatusList();

   return (
      <>
         <hr />
         <div className="md-grid md-cell--12">
            {imageUrl ? (
               <div className="md-cell md-cell--6">
                  <PhotoEditor
                     zoomVal={1}
                     rotateVal={1}
                     isPreviewOpen={false}
                     imageUrl={imageUrl}
                     cancelPhotoEditor={cancelPhotoEditor}
                     photoEditorRef={photoEditor}
                  ></PhotoEditor>
               </div>
            ) : (
               <div className="md-cell md-cell--6">{renderPhoto()}</div>
            )}

            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="floating-center-title"
                  label="Licence Number"
                  lineDirection="center"
                  placeholder="Licence Number"
                  className="md-cell md-cell--bottom md-cell--12"
                  inputClassName={getInputValidationClassName(
                     validationResult &&
                        validationResult.fields.registrationNumber.error
                  )}
                  maxLength={REGISTRATION_NUMBER_LENGTH}
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        registrationNumber: isNullOrWhiteSpace(val)
                           ? undefined
                           : Number(val),
                     });
                  }}
                  type="number"
                  disabled={
                     isEditMode && model.registrationNumber ? true : false
                  }
                  defaultValue={model.registrationNumber}
                  error={
                     validationResult &&
                     validationResult.fields.registrationNumber.error
                  }
               />
               <SelectField
                  floating
                  id="select-contact-status"
                  label="Status"
                  placeholder="Select Status"
                  className="md-cell md-cell--12 contact-status-select"
                  inputClassName={getInputValidationClassName(
                     validationResult &&
                        validationResult.fields.contactStatusCode.error
                  )}
                  menuItems={contactStatuses}
                  position={SelectField.Positions.BELOW}
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        contactStatusCode: val.toString(),
                     });
                  }}
                  value={model.contactStatusCode}
                  required
                  errorText="Please select the Status"
                  error={
                     validationResult &&
                     validationResult.fields.contactStatusCode.error
                  }
               />
               <h3>Notifications</h3>
               <small>(You may select Multiple)</small>
               <div className="md-grid md-cell--12">
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-no-sms"
                        name="simple-checkboxes[]"
                        label="SMS Notifications for Applications"
                        value="material-design"
                        onChange={(e): void => {
                           updateModel({
                              ...model,
                              optOutSmsnotifications: !e,
                           });
                        }}
                        defaultChecked={!model.optOutSmsnotifications}
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-no-email"
                        name="simple-checkboxes[]"
                        label="Email Notifications for Applications"
                        value="material-design"
                        onChange={(e): void => {
                           updateModel({
                              ...model,
                              optOutEmailNotifications: !e,
                           });
                        }}
                        defaultChecked={!model.optOutEmailNotifications}
                     />
                  </div>
                  <div className="md-cell md-cell--4">
                     <Checkbox
                        id="checkbox-subscribed"
                        name="simple-checkboxes[]"
                        label="Subscribed"
                        value="material-design"
                        onChange={(e): void => {
                           updateModel({
                              ...model,
                              subscribeFlag: e,
                           });
                        }}
                        defaultChecked={model.subscribeFlag}
                     />
                  </div>
               </div>
            </div>
         </div>
         <hr />
      </>
   );
};

export default PhotoSection;
