import './NoteItem.scss';
import ContactNote from 'Models/Contact/Data/ContactNote';
import moment, { Moment } from 'moment-timezone';
import React from 'react';
import ExternalLink from 'Components/Common/ExternalLink/ExternalLink';

interface NoteItemProps {
   noteItem: ContactNote;
}

const formatDate = (dateValue: string | Moment): string => {
   const momentDate =
      dateValue instanceof moment ? (dateValue as Moment) : moment(dateValue);
   return momentDate.format('DD/MM/YYYY h:mma');
};

const getNoteDate = (noteItem: ContactNote): string => {
   const noteDate: moment.Moment = moment(noteItem.createdOn);
   if (noteDate.isSame(moment.now(), 'day')) {
      return 'Today';
   }
   return formatDate(noteDate);
};

const NoteItem = ({ noteItem }: Readonly<NoteItemProps>): JSX.Element => {
   const noteDate = getNoteDate(noteItem);
   const createdOnDate = formatDate(noteItem.createdOn);

   return (
      <div className="md-cell md-cell--12 note-item">
         <h3 className="header">{noteDate}</h3>
         <div className="content">{noteItem.note}</div>
         <div className="details-container">
            <div className="detail">
               {noteItem.hasAttachment ? (
                  <ExternalLink
                     href={noteItem.attachmentUrl}
                     className="md-btn md-btn--flat md-btn--text md-pointer--hover md-background--primary md-background--primary-hover md-inline-block download-button"
                  >
                     File Available
                  </ExternalLink>
               ) : (
                  ''
               )}
            </div>
            <div className="detail md-text-right">
               {noteItem.createdBy &&
                  noteItem.createdOn &&
                  `${noteItem.createdBy} ${createdOnDate}`}
            </div>
         </div>
      </div>
   );
};

export default NoteItem;
