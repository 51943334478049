import { FinancialExtractResultItem } from 'Models/BackOffice/FinancialExtractResultItem';
import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import { FinancialExtractSearchQuery } from 'Models/BackOffice/FinancialExtractInput';

export interface FinancialExtractServiceType {
   getSearchResult(
      query: FinancialExtractSearchQuery
   ): Promise<ApiResponse<readonly FinancialExtractResultItem[]>>;

   updateFinancialExtract(
      model: FinancialExtractResultItem[],
      financialType: string,
      fileName: string
   ): Promise<ApiResponse<Blob>>;
}

const create = (baseURL: string | undefined): FinancialExtractServiceType => {
   const api = createApi({ baseURL });

   const getSearchResult = (
      query: FinancialExtractSearchQuery
   ): Promise<ApiResponse<readonly FinancialExtractResultItem[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`FinancialExtract/Search`, query);
   };

   const updateFinancialExtract = (
      model: FinancialExtractResultItem[],
      financialType: string,
      fileName: string
   ): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(
         `FinancialExtract/UpdateFinancialExtract/${financialType}/${fileName}`,
         model
      );
   };

   return {
      getSearchResult,
      updateFinancialExtract,
   };
};

export default { create };
