import React from 'react';
import UserCard from 'Components/Common/UserCard/UserCard';

const LicencePanel = (): JSX.Element => {
   return (
      <div className="md-grid md-cell--12">
         <UserCard />
      </div>
   );
};

export default LicencePanel;
