import { put, call } from 'redux-saga/effects';
import { DashboardActions } from '../Redux/DashboardRedux';
import { DashboardServiceType } from '../Services/DashboardService';
import { ApiResponse } from 'apisauce';
import DashboardModelReadonly from 'Models/Dashboard/Dashboard';

export function* getDashboardData(
   dashboardService: Readonly<DashboardServiceType>
): Generator {
   const dashboardResponse = yield call(dashboardService.getDashboardData);
   const response = dashboardResponse as ApiResponse<DashboardModelReadonly[]>;
   if (response.status && response.ok) {
      yield put(DashboardActions.getDashboardDataSuccess(response.data));
   } else {
      yield put(
         DashboardActions.getDashboardDataFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
