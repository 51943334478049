import LicenceYear, { DefaultLicenceYear } from 'Models/Other/LicenceYear';
import {
   LicenceStatus,
   DefaultLicenceStatusModel,
} from 'Models/Metadata/LicenceStatus';
import SupervisionLimitedDto from 'Models/Supervision/Dto/SupervisionLimitedDto';

interface LicenceDto {
   licenceId: number;
   licenceTypeCode: string;
   licenceYearNumber: number;
   licenceStatusCode: string;
   disciplineCode: string;
   type: string;
   contactId: number;
   applicationId: number | null;
   startDate: string;
   printDate: string | null;
   expiryDate: string | null;
   supervisorId: number | null;
   invoiceId: number | null;
   createdBy: string;
   createdOn: string;
   updatedBy: string;
   updatedOn: string;
   trainingProviderId: number | null;
   suspensionStartDate: string | null;
   suspensionEndDate: string | null;
   suspensionReason: string;
   licenceConditions: string;
   licenceYearNumberNavigation: LicenceYear;
   licenceStatusCodeNavigation: LicenceStatus;
   supervision: readonly SupervisionLimitedDto[];
}

export const DefaultLicenceDto: LicenceDto = {
   licenceId: 0,
   licenceTypeCode: '',
   licenceYearNumber: 0,
   licenceStatusCode: '',
   disciplineCode: '',
   type: '',
   contactId: 0,
   applicationId: null,
   startDate: '',
   printDate: null,
   expiryDate: null,
   supervisorId: null,
   invoiceId: null,
   createdBy: '',
   createdOn: '',
   updatedBy: '',
   updatedOn: '',
   trainingProviderId: null,
   suspensionStartDate: null,
   suspensionEndDate: null,
   suspensionReason: '',
   licenceConditions: '',
   licenceYearNumberNavigation: DefaultLicenceYear,
   licenceStatusCodeNavigation: DefaultLicenceStatusModel,
   supervision: [],
};

type LicenceDtoReadonly = Readonly<LicenceDto>;

export default LicenceDtoReadonly;
