import React from 'react';

interface DefaultIfNullProps {
   displayElement: React.ReactNode | null;
   defaultValue?: React.ReactNode;
}

const DefaultIfNull = ({
   displayElement,
   defaultValue = '-',
}: DefaultIfNullProps): JSX.Element => {
   return <>{displayElement || defaultValue}</>;
};

export default DefaultIfNull;
