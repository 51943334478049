import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { withAccessToken, createApi } from '.';
import LicenceTypeReadonly from 'Models/LicenceType/Data/LienceType';
import LicenceType from 'Models/LicenceType/Data/LienceType';

export interface LicenceTypeServiceType {
   updateLicenceType(
      boardMeetingDate: LicenceTypeReadonly
   ): Promise<ApiResponse<readonly LicenceTypeReadonly[]>>;
   getLicenceTypes(): Promise<ApiResponse<readonly LicenceType[]>>;
   addLicenceType(
      licenceType: LicenceTypeReadonly
   ): Promise<ApiResponse<LicenceTypeReadonly>>;
}

const create = (baseURL: string | undefined): LicenceTypeServiceType => {
   const api = createApi({ baseURL });

   const updateLicenceType = (
      licenceType: LicenceTypeReadonly
   ): Promise<ApiResponse<readonly LicenceTypeReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/LicenceType`, licenceType);
   };

   const getLicenceTypes = (): Promise<ApiResponse<readonly LicenceType[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/LicenceType`);
   };

   const addLicenceType = (
      licenceType: LicenceTypeReadonly
   ): Promise<ApiResponse<LicenceTypeReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/LicenceType`, licenceType);
   };

   return {
      updateLicenceType,
      getLicenceTypes,
      addLicenceType,
   };
};

export default {
   create,
};
