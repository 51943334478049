import { ApiResponse } from 'apisauce';
import apisauce from 'apisauce';
import store from 'State/Redux';
import { withAccessToken } from '.';
import ReceiptRequestReadOnly from 'Models/Payment/Data/ReceiptRequest';

export interface ReceiptServiceType {
   getReceipt(request: ReceiptRequestReadOnly): Promise<ApiResponse<Blob>>;
   getReceiptByPaymentId(paymentId: number): Promise<ApiResponse<Blob>>;
}

const create = (baseURL: string | undefined): ReceiptServiceType => {
   const blobApi = apisauce.create({ baseURL, responseType: 'blob' });

   const getReceipt = (
      request: ReceiptRequestReadOnly
   ): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.get(
         `/Receipts/Details/${request.contactId}/${request.paymentId}`
      );
   };

   const getReceiptByPaymentId = (
      paymentId: number
   ): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.get(`/Receipts/Details/${paymentId}`);
   };

   return {
      getReceipt,
      getReceiptByPaymentId,
   };
};

export default {
   create,
};
