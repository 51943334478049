import React, { useEffect, useState, useCallback } from 'react';

import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import {
   selectExportedLicenceCards,
   LicenceTypes,
   LicenceActions,
} from 'State/Redux/LicenceRedux';
import {
   selectExportedContactPhotos,
   ContactPhotoTypes,
   ContactPhotoActions,
} from 'State/Redux/ContactPhotoRedux';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import FileSaver from 'file-saver';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { toast } from 'react-toastify';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import {
   selectBatchItems,
   BatchActions,
   BatchTypes,
   selectDownloadedBatchItem,
} from 'State/Redux/BatchRedux';
import ExportHistorySearch from './Panels/SearchPanel';
import ExportHistoryResultsPanel from './Panels/ResultsPanel';
import {
   BatchSearchQuery,
   DefaultBatchSearchQuery,
} from 'Models/BackOffice/BatchSearchQuery';
import { SelectField } from 'react-md/lib/SelectFields';
import './Export.scss';

const ExportsTab = (): JSX.Element => {
   const [exportLicenceClicked, setExportLicenceClicked] = useState(false);
   const [downloadItemClicked, setDownloadItemClicked] = useState(false);
   const [extensionFile, setExtensionFile] = useState<string>('txt');
   const [exportContactPhotosClicked, setExportContactPhotosClicked] = useState(
      false
   );
   const [selectedFileName, setSelectedFileName] = useState('');
   const [currentQuery, setCurrentQuery] = useState(DefaultBatchSearchQuery);

   const licenceCardExtract = useSelector(selectExportedLicenceCards);
   const contactPhotoExtract = useSelector(selectExportedContactPhotos);
   const downloadedBatchItem = useSelector(selectDownloadedBatchItem);
   const [hasSearched, setHasSearched] = useState(false);
   const [softRefresh, setSoftRefresh] = useState(false);
   const resetSearch = useCallback((): void => {
      setHasSearched(false);
      setSoftRefresh(false);
   }, []);

   const isLicenceCardsDownloading = useSelector(
      selectIsLoading([LicenceTypes.EXPORT_LICENCE_CARDS_REQUEST])
   );
   const isLicenceCardsDownloaded = useSelector(
      selectIsSuccess([LicenceTypes.EXPORT_LICENCE_CARDS_REQUEST])
   );

   const isContactPhotosDownloading = useSelector(
      selectIsLoading([ContactPhotoTypes.EXPORT_CONTACT_PHOTOS_REQUEST])
   );
   const isContactPhotosDownloaded = useSelector(
      selectIsSuccess([ContactPhotoTypes.EXPORT_CONTACT_PHOTOS_REQUEST])
   );

   const isFileHistoryLoading = useSelector(
      selectIsLoading([BatchTypes.GET_BATCH_ITEMS_REQUEST])
   );
   const isBatchItemDownloading = useSelector(
      selectIsLoading([BatchTypes.DOWNLOAD_BATCH_ITEM_REQUEST])
   );
   const isBatchItemDownloaded = useSelector(
      selectIsSuccess([BatchTypes.DOWNLOAD_BATCH_ITEM_REQUEST])
   );

   const isSearching = isFileHistoryLoading && hasSearched;

   const dispatch = useDispatch();

   const fileHistory = useSelector(selectBatchItems);

   const panelTitle = fileHistory
      ? `Results (${fileHistory.length})`
      : 'Results';

   useEffect((): void => {
      if (isLicenceCardsDownloaded && exportLicenceClicked) {
         setExportLicenceClicked(false);
         if (licenceCardExtract === null || licenceCardExtract.length < 1) {
            toast.error(`No data available for extract`);
         } else {
            licenceCardExtract.forEach(item => {
               const blob = new Blob([item.fileData], {
                  type: 'text/plain',
               });

               FileSaver.saveAs(blob, item.fileName);
            });
         }
      }
   }, [isLicenceCardsDownloaded, licenceCardExtract, exportLicenceClicked]);

   useEffect((): void => {
      if (isContactPhotosDownloaded && exportContactPhotosClicked) {
         setExportContactPhotosClicked(false);
         if (contactPhotoExtract === null || contactPhotoExtract.size < 1) {
            toast.error(`No data available for extract`);
         } else {
            const blob = new Blob([contactPhotoExtract], {
               type: 'application/zip',
            });

            FileSaver.saveAs(blob, `PhotoExtract.zip`);
         }
      }
   }, [
      isContactPhotosDownloaded,
      contactPhotoExtract,
      exportContactPhotosClicked,
   ]);

   useEffect((): void => {
      if (isBatchItemDownloaded && downloadItemClicked) {
         setDownloadItemClicked(false);
         if (downloadedBatchItem === null || downloadedBatchItem.size < 1) {
            toast.error(`No data available for extract`);
         } else {
            const blob = new Blob([downloadedBatchItem]);

            FileSaver.saveAs(blob, selectedFileName);
         }
         setSoftRefresh(true);
         dispatch(BatchActions.getBatchItemsRequest(currentQuery));
      }
   }, [
      isBatchItemDownloaded,
      downloadedBatchItem,
      downloadItemClicked,
      selectedFileName,
      currentQuery,
      dispatch,
   ]);

   return (
      <ExpansionList className="md-cell md-cell--12 search-container">
         <ExpansionPanel
            label="Exports"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
            className="search-panel"
         >
            <div className="md-cell md-cell--12">
               <SelectField
                  id="select-extension-file"
                  lineDirection="center"
                  label="Extension file"
                  className="md-cell md-cell--1"
                  menuItems={['txt', 'csv']}
                  listClassName={'max-height-50'}
                  position={SelectField.Positions.BELOW}
                  value={extensionFile}
                  onChange={(val): void => setExtensionFile(val.toString())}
               />
               <LoadingButton
                  flat
                  primary
                  swapTheming
                  onClick={(): void => {
                     dispatch(
                        LicenceActions.exportLicenceCardsRequest(extensionFile)
                     );
                     setExportLicenceClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     dispatch(
                        LicenceActions.exportLicenceCardsRequest(extensionFile)
                     );
                     setExportLicenceClicked(true);
                  }}
                  isLoading={isLicenceCardsDownloading}
                  className="md-cell md-cell--2"
                  disabled={
                     isContactPhotosDownloading || isBatchItemDownloading
                  }
               >
                  Export Licence Cards
               </LoadingButton>
               <LoadingButton
                  flat
                  primary
                  swapTheming
                  onClick={(): void => {
                     dispatch(ContactPhotoActions.exportContactPhotosRequest());
                     setExportContactPhotosClicked(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     dispatch(ContactPhotoActions.exportContactPhotosRequest());
                     setExportContactPhotosClicked(true);
                  }}
                  isLoading={isContactPhotosDownloading}
                  className="md-cell md-cell--2"
                  disabled={isLicenceCardsDownloading || isBatchItemDownloading}
               >
                  Export Contact Photos
               </LoadingButton>
            </div>
         </ExpansionPanel>
         <ExpansionPanel
            label="Export History Search"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
            className="search-panel"
         >
            <ExportHistorySearch
               searchBatch={(query: BatchSearchQuery): void => {
                  setCurrentQuery(query);
                  setSoftRefresh(false);
                  setHasSearched(true);
                  dispatch(BatchActions.getBatchItemsRequest(query));
               }}
               isSearching={isSearching}
               resetSearch={resetSearch}
               displayFileList
            />
         </ExpansionPanel>
         {(hasSearched && !isFileHistoryLoading) || softRefresh ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <ExportHistoryResultsPanel
                  downloadFile={(index: number): void => {
                     setDownloadItemClicked(true);
                     setSelectedFileName(fileHistory[index].filename);
                     dispatch(
                        BatchActions.downloadBatchItemRequest(
                           fileHistory[index].batchId.toString()
                        )
                     );
                  }}
                  isBatchItemDownloading={isBatchItemDownloading}
                  searchResults={fileHistory || []}
                  isRefreshing={isFileHistoryLoading}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default ExportsTab;
