import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';

export interface LicenceType {
   licenceTypeCode: string;
   description: string;
   disciplineCode: string;
   disciplineDescription: string;
   includeInWebRegister: boolean;
   isAudited: boolean;
   type: string;
}

type LicenceTypeReadonly = Readonly<LicenceType>;

export default LicenceTypeReadonly;

export const DefaultLicenceTypeModel: LicenceType = {
   licenceTypeCode: '',
   description: '',
   disciplineCode: '',
   disciplineDescription: '',
   includeInWebRegister: false,
   isAudited: false,
   type: 'Unknown',
};

interface ValidationModel {
   licenceTypeCode: boolean;
   description: boolean;
   disciplineCode: boolean;
   disciplineDescription: boolean;
   includeInWebRegister: boolean;
   isAudited: boolean;
}

export type LicenceTypeValidation = Readonly<ValidationModel>;

export const validateLicenceTypes = (
   licenceType: LicenceType
): LicenceTypeValidation => {
   return {
      licenceTypeCode:
         !isNullOrWhiteSpace(licenceType.licenceTypeCode) &&
         licenceType.licenceTypeCode.length <= 4,
      description: !isNullOrWhiteSpace(licenceType.description),
      disciplineCode: !isNullOrWhiteSpace(licenceType.disciplineCode),
      disciplineDescription: true,
      includeInWebRegister: true,
      isAudited: true,
   };
};
