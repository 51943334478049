import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchPanel from './Panels/SearchPanel';
import { ExpansionList } from 'react-md/lib/ExpansionPanels';
import './Results.scss';
import ResultsPanel from './Panels/ResultsPanel';
import {
   ResultSearchQuery,
   DefaultResultSearchQueryModel,
   filterToValidQuery,
} from 'Models/Examination/Data/ResultSearchQuery';
import {
   ResultActions,
   selectSearchResults,
   ResultTypes,
} from 'State/Redux/ResultRedux';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { DefaultPagingModel, PagedQuery } from 'Models/Other/PagedQuery';
import { isEqual } from 'lodash-es';
import ResultSearchResultReadOnly from 'Models/Examination/Data/ResultSearchResult';

const DEFAULT_PAGING: PagedQuery = {
   ...DefaultPagingModel,
   orderBy: 'date',
   isDescending: true,
};

const SearchResults = (): JSX.Element => {
   const dispatch = useDispatch();
   const isLoading = useSelector(
      selectIsLoading([ResultTypes.SEARCH_RESULT_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ResultTypes.SEARCH_RESULT_REQUEST])
   );
   const selectedSearchResults = useSelector(selectSearchResults);
   const [hasSearched, setHasSearched] = useState(false);
   const [
      localSearchResults,
      setSearchResults,
   ] = useState<ResultSearchResultReadOnly | null>(null);
   const [paging, setPaging] = useState(DefaultPagingModel);
   const [searchedPaging, setSearchedPaging] = useState(DefaultPagingModel);
   const updatePaging = useCallback(
      (updatedPaging: Readonly<PagedQuery>): void => {
         if (!isEqual(searchedPaging, updatedPaging)) setPaging(updatedPaging);
      },
      [searchedPaging]
   );
   const [searchModel, setSearchModel] = useState(
      DefaultResultSearchQueryModel
   );
   const updateSearchModel = useCallback(
      (searchModel: ResultSearchQuery): void => setSearchModel(searchModel),
      []
   );
   const [searchedModel, setSearchedModel] = useState(
      DefaultResultSearchQueryModel
   );
   const [searchButtonClicked, setSearchButtonClicked] = useState(false);
   const updateSearchButtonClicked = useCallback(
      (isClicked: boolean): void => setSearchButtonClicked(isClicked),
      []
   );

   const search = (): void => {
      const newQuery = filterToValidQuery({
         ...searchModel,
         ...DEFAULT_PAGING,
      });

      if (searchButtonClicked && !isEqual(searchedModel, newQuery)) {
         dispatch(ResultActions.searchResultRequest(newQuery));
         setHasSearched(true);
         setPaging(DEFAULT_PAGING);
         setSearchedPaging(DEFAULT_PAGING);
         setSearchedModel(newQuery);
         setSearchResults(null);
      } else if (!isEqual(searchedPaging, paging)) {
         dispatch(
            ResultActions.searchResultRequest({ ...searchedModel, ...paging })
         );
         setHasSearched(true);
         setSearchedPaging(paging);
      }
      setSearchButtonClicked(false);
   };

   useEffect(search, [
      searchButtonClicked,
      searchModel,
      searchedModel,
      paging,
      searchedPaging,
   ]);

   const reset = useCallback((): void => {
      setHasSearched(false);
      setSearchButtonClicked(false);
      setSearchResults(null);
      setPaging(DEFAULT_PAGING);
      setSearchedPaging(DEFAULT_PAGING);
      setSearchModel(DefaultResultSearchQueryModel);
      setSearchedModel(DefaultResultSearchQueryModel);
   }, []);

   const hasResults =
      isSuccess && hasSearched && selectedSearchResults !== localSearchResults;
   if (hasResults) setSearchResults(selectedSearchResults);

   const [resultsPanel, setResultsPanel] = useState<JSX.Element>(<></>);
   useEffect((): void => {
      setResultsPanel(
         hasSearched ? (
            <ResultsPanel
               searchResults={localSearchResults}
               paging={paging}
               onPagingChanged={updatePaging}
               isLoading={isLoading}
            />
         ) : (
            <></>
         )
      );
   }, [hasSearched, isLoading, localSearchResults, updatePaging, paging]);

   return (
      <ExpansionList className="md-cell md-cell--12 search-container">
         <SearchPanel
            query={searchModel}
            setQuery={updateSearchModel}
            setIsSearching={updateSearchButtonClicked}
            reset={reset}
         />
         {resultsPanel}
      </ExpansionList>
   );
};

export default SearchResults;
