import {
   isValidSearchName,
   isValidSearchPostcode,
   isValidSearchLicenceNumber,
   isValidSearchLicenceType,
   isValidSearchRegistrationType,
} from 'Util/Helpers/ContactSearch';
import {
   isValidEmail,
   isValidPhoneNumber,
   isNullOrWhiteSpace,
   isValidDate,
} from 'Util/Helpers/Validation';
import moment from 'moment-timezone';
import { PagedQuery, DefaultPagingModel } from 'Models/Other/PagedQuery';

interface Query extends PagedQuery {
   name: string;
   companyName: string;
   postcode: string;
   email: string;
   phone: string;
   dateOfBirth: string;
   licenceNumber: string;
   licenceType: string;
   registrationType: string;
   gender: number | null;
   ethnicity: number | null;
}

export type ContactSearchQuery = Readonly<Query>;

interface QueryValidation {
   name: boolean;
   companyName: boolean;
   postcode: boolean;
   email: boolean;
   phone: boolean;
   dateOfBirth: boolean;
   licenceNumber: boolean;
   licenceType: boolean;
   registrationType: boolean;
   gender: boolean;
   ethnicity: boolean;
}

export type ContactSearchQueryValidation = Readonly<QueryValidation>;

export const DefaultSearchModel: ContactSearchQuery = {
   ...DefaultPagingModel,
   name: '',
   companyName: '',
   postcode: '',
   email: '',
   phone: '',
   dateOfBirth: '',
   licenceNumber: '',
   licenceType: '',
   registrationType: '',
   gender: null,
   ethnicity: null,
};

export const DefaultValidationModel: ContactSearchQueryValidation = {
   name: false,
   companyName: false,
   postcode: false,
   email: false,
   phone: false,
   dateOfBirth: false,
   licenceNumber: false,
   licenceType: false,
   registrationType: false,
   gender: false,
   ethnicity: false,
};

export const validateSearchQuery = (
   query: ContactSearchQuery
): ContactSearchQueryValidation => {
   return {
      name: isNullOrWhiteSpace(query.name) || isValidSearchName(query.name),
      companyName:
         isNullOrWhiteSpace(query.companyName) ||
         isValidSearchName(query.companyName),
      postcode:
         isNullOrWhiteSpace(query.postcode) ||
         isValidSearchPostcode(query.postcode),
      email: isNullOrWhiteSpace(query.email) || isValidEmail(query.email),
      phone: isNullOrWhiteSpace(query.phone) || isValidPhoneNumber(query.phone),
      dateOfBirth:
         isNullOrWhiteSpace(query.dateOfBirth) ||
         moment(query.dateOfBirth).isValid(),
      licenceNumber:
         isNullOrWhiteSpace(query.licenceNumber) ||
         isValidSearchLicenceNumber(query.licenceNumber),
      licenceType:
         isNullOrWhiteSpace(query.licenceType) ||
         isValidSearchLicenceType(query.licenceType),
      registrationType:
         isNullOrWhiteSpace(query.registrationType) ||
         isValidSearchRegistrationType(query.registrationType),
      gender: isNullOrWhiteSpace(query.gender),
      ethnicity: isNullOrWhiteSpace(query.ethnicity),
   };
};

export const filterToValidQuery = (
   query: ContactSearchQuery
): ContactSearchQuery => {
   return {
      ...query,
      name: isValidSearchName(query.name) ? query.name : '',
      companyName: isValidSearchName(query.companyName)
         ? query.companyName
         : '',
      postcode: isValidSearchPostcode(query.postcode) ? query.postcode : '',
      email: isValidEmail(query.email) ? query.email : '',
      phone: isValidPhoneNumber(query.phone) ? query.phone : '',
      dateOfBirth: moment(query.dateOfBirth).isValid() ? query.dateOfBirth : '',
      licenceNumber: isValidSearchLicenceNumber(query.licenceNumber)
         ? query.licenceNumber
         : '',
      licenceType: isValidSearchLicenceType(query.licenceType)
         ? query.licenceType
         : '',
      registrationType: isValidSearchRegistrationType(query.registrationType)
         ? query.registrationType
         : '',
   };
};

export const isValidSearchQuery = (query: ContactSearchQuery): boolean => {
   return (
      !isNullOrWhiteSpace(query.name) ||
      !isNullOrWhiteSpace(query.companyName) ||
      !isNullOrWhiteSpace(query.postcode) ||
      !isNullOrWhiteSpace(query.email) ||
      !isNullOrWhiteSpace(query.phone) ||
      (!isNullOrWhiteSpace(query.dateOfBirth) &&
         isValidDate(query.dateOfBirth)) ||
      !isNullOrWhiteSpace(query.licenceNumber) ||
      !isNullOrWhiteSpace(query.licenceType) ||
      !isNullOrWhiteSpace(query.registrationType) ||
      !isNullOrWhiteSpace(query.gender) ||
      !isNullOrWhiteSpace(query.ethnicity)
   );
};
