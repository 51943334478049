import React from 'react';
import { RoutedTabContainer } from 'Components/Common/RoutedTabs/RoutedTabContainer';
import { RoutedTab } from 'Components/Common/RoutedTabs/RoutedTab';
import AddCourse from './AddCourse';
import ViewCourses from './ViewCourses';

const BASE_URL = '/cpd/course';

const courseTabs: readonly RoutedTab[] = [
   {
      label: 'View',
      route: '',
      component: ViewCourses,
      pageTitle: 'Search',
      exact: true,
   },
   {
      label: 'Add Course',
      route: '/add',
      component: AddCourse,
      pageTitle: 'Add Course',
      exact: true,
   },
];

const Course = (): JSX.Element => {
   return (
      <RoutedTabContainer
         tabs={courseTabs}
         baseUrl={BASE_URL}
         panelClassName="courseTabs"
         pageTitle="Course"
      />
   );
};

export default Course;
