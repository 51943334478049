import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import ContactPhotoResponse from 'Models/Contact/Dto/ContactPhotoResponse';
import { withAccessToken, createApi } from '.';
import ContactPhotoReview from 'Models/Contact/Data/ContactPhotoReview';

export interface ContactPhotoServiceType {
   getContactPhotoReview(): Promise<ApiResponse<ContactPhotoResponse>>;
   saveContactPhotoReview(
      data: ContactPhotoReview
   ): Promise<ApiResponse<ContactPhotoResponse>>;
   exportContactPhotos(): Promise<ApiResponse<Blob>>;
}

const create = (baseURL: string | undefined): ContactPhotoServiceType => {
   const api = createApi({ baseURL });
   const blobApi = createApi({ baseURL: baseURL, responseType: 'blob' });

   const getContactPhotoReview = (): Promise<ApiResponse<
      ContactPhotoResponse
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/ContactPhoto/ContactPhotoReview/`);
   };

   const saveContactPhotoReview = (
      data: ContactPhotoReview
   ): Promise<ApiResponse<ContactPhotoResponse>> => {
      const authApi = withAccessToken(api, store.getState().oidc);

      // update if contact id is undefined
      return authApi.post('/ContactPhoto/ContactPhotoReview', data);
   };

   const exportContactPhotos = (): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.get(`/ContactPhoto/ExtractContactPhotos`);
   };

   return {
      getContactPhotoReview,
      saveContactPhotoReview,
      exportContactPhotos,
   };
};

export default {
   create,
};
