import ContactDto, {
   DefaultContactLimitedDtoModel,
} from 'Models/Contact/Dto/ContactLimitedDto';

interface CourseDto {
   courseId: number;
   courseNumber: string;
   courseName: string;
   courseDescription: string;
   courseUrl: string | null;
   trainingProviderId: number;
   startDate: string | null;
   endDate: string | null;
   courseFee: number | null;
   createdBy: string;
   createdOn: string | null;
   updatedBy: string | null;
   updatedOn: string | null;
   credits: number | string | null;
   courseTypeId: number | null;
   published: boolean | null;
   healthAndSafety: boolean | null;
   nationWide: boolean | null;
   onlineCorrespondence: boolean | null;
   licenceYearId: number | null;

   trainingProvider: ContactDto;
}

type CourseDtoReadonly = Readonly<CourseDto>;

export default CourseDtoReadonly;

export const DefaultCourseDtoModel: CourseDtoReadonly = {
   courseId: 0,
   courseNumber: '',
   courseName: '',
   courseDescription: '',
   courseUrl: '',
   trainingProviderId: 0,
   startDate: null,
   endDate: null,
   courseFee: null,
   createdBy: '',
   createdOn: null,
   updatedBy: null,
   updatedOn: null,
   credits: null,
   courseTypeId: null,
   published: null,
   healthAndSafety: null,
   nationWide: null,
   onlineCorrespondence: null,
   licenceYearId: null,
   trainingProvider: DefaultContactLimitedDtoModel,
};
