import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import { UserReadonly } from 'Models/Identity/User';
import { withAccessToken, createApi } from '.';

export interface IdentityServiceType {
   postPasswordReset(data: UserReadonly): Promise<ApiResponse<number>>;
}

const create = (baseURL: string | undefined): IdentityServiceType => {
   const api = createApi({ baseURL });

   const postPasswordReset = (
      user: UserReadonly
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/admin/resetpassword', user);
   };

   return {
      postPasswordReset,
   };
};

export default {
   create,
};
