import React, { useEffect } from 'react';
import { ReactComponent as PlumberIcon } from 'Components/Icons/icon-plumber-white.svg';
import { ReactComponent as GasfitterIcon } from 'Components/Icons/icon-gasfitter-white.svg';
import { ReactComponent as DrainlayerIcon } from 'Components/Icons/icon-drainlayer-white.svg';
import './Home.scss';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import {
   ApplicationTypes,
   ApplicationActions,
   selectPendingReviewApplications,
   selectPendingRegistrationApplicationCount,
} from 'State/Redux/ApplicationRedux';
import {
   DashboardTypes,
   DashboardActions,
   selectDashboarddata,
} from 'State/Redux/DashboardRedux';
import Loading from 'Components/Common/Loading';
import { usePermissions } from 'Util/Helpers/Permissions';
import { Permission } from 'Util/Constants/Permission';
import ApplicationReviewPanel from './ApplicationReviewPanel';
import Dashboard from './Dashboard';

const REGISTRAR_PERMISSION: Permission = 'ReviewRegistration.Read';

const Home = (): JSX.Element => {
   const isLoading = useSelector(
      selectIsLoading([
         ApplicationTypes.GET_PENDING_REVIEW_APPLICATIONS_REQUEST,
         DashboardTypes.GET_DASHBOARD_DATA_REQUEST,
      ])
   );
   const isDashbardDataError = useSelector(
      selectIsError([DashboardTypes.GET_DASHBOARD_DATA_REQUEST])
   );
   const isPendingRenewalError = useSelector(
      selectIsError([ApplicationTypes.GET_PENDING_REVIEW_APPLICATIONS_REQUEST])
   );

   const reviewApplications = useSelector(selectPendingReviewApplications);
   const dashboardData = useSelector(selectDashboarddata);
   const pendingRegistrationApplicationCount = useSelector(
      selectPendingRegistrationApplicationCount
   );

   const dispatch = useDispatch();
   const isRegistrar = usePermissions(REGISTRAR_PERMISSION);

   const loadPendingReviewApplications = (): void => {
      if (!isRegistrar) return;
      dispatch(ApplicationActions.getPendingReviewApplicationsRequest());
      dispatch(
         ApplicationActions.getPendingRegistrationApplicationCountRequest()
      );
   };

   const loadDashboardData = (): void => {
      dispatch(DashboardActions.getDashboardDataRequest());
   };

   useEffect(loadPendingReviewApplications, [isRegistrar, dispatch]);
   useEffect(loadDashboardData, [dispatch]);

   const isError = isDashbardDataError || isPendingRenewalError;

   const reload = (): void => {
      if (isDashbardDataError) loadDashboardData();
      if (isPendingRenewalError) loadPendingReviewApplications();
   };

   return (
      <Loading isLoading={isLoading} isError={isError} tryReload={reload}>
         <>
            <div className="home">
               <PlumberIcon className="home-logo" />
               <GasfitterIcon className="home-logo" />
               <DrainlayerIcon className="home-logo" />
               <h1 className="home-header">Welcome to PGDB</h1>
            </div>
            {usePermissions('RegistrationApplication.Read') && (
               <ApplicationReviewPanel
                  panelType={'admin'}
                  count={pendingRegistrationApplicationCount}
               />
            )}
            {usePermissions('Application.Read') && (
               <ApplicationReviewPanel
                  panelType={'registrar'}
                  count={reviewApplications.length}
               />
            )}
            {usePermissions('Dashboard.Read') && (
               <Dashboard dashboardData={dashboardData} />
            )}
         </>
      </Loading>
   );
};
export default Home;
