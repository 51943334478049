import * as REGEX from 'Util/Constants/Regex';

const COMPANY_RENEWAL_CONTACT_PREFIX = 'CRC';
const UNREGISTERED_PREFIX = 'UN';
const EMPLOYER_PREFIX = 'E';
const TRAINING_PROVIDER_PREFIX = 'TP';

export const REGISTRATION_NUMBER_LENGTH = 5;

export const isValidRegistrationNumber = (value: string): boolean => {
   if (!value) return false;
   return value.match(REGEX.IS_SEARCH_LICENCE_NUMBER) ? true : false;
};

const isEmployerNumber = (value: string): boolean => {
   if (!isValidRegistrationNumber(value)) return false;
   return value
      .trim()
      .toUpperCase()
      .startsWith(EMPLOYER_PREFIX);
};

export const isCompanyRenewalContactNumber = (value: string): boolean => {
   if (!isValidRegistrationNumber(value)) return false;
   return value
      .trim()
      .toUpperCase()
      .startsWith(COMPANY_RENEWAL_CONTACT_PREFIX);
};

export const isUnregisteredNumber = (value: string): boolean => {
   if (!isValidRegistrationNumber(value)) return false;
   return value
      .trim()
      .toUpperCase()
      .startsWith(UNREGISTERED_PREFIX);
};

export const isTrainingProviderNumber = (value: string): boolean => {
   if (!isValidRegistrationNumber(value)) return false;
   return value
      .trim()
      .toUpperCase()
      .startsWith(TRAINING_PROVIDER_PREFIX);
};

export const isPractitionerNumber = (value: string): boolean => {
   if (!isValidRegistrationNumber(value)) return false;
   return !!Number(value.trim());
};

export const formatRegistrationNumber = (value: string): string => {
   if (!isValidRegistrationNumber(value)) return '';

   if (isCompanyRenewalContactNumber(value))
      return (
         COMPANY_RENEWAL_CONTACT_PREFIX +
         value
            .trim()
            .substring(COMPANY_RENEWAL_CONTACT_PREFIX.length)
            .padStart(REGISTRATION_NUMBER_LENGTH, '0')
      );

   if (isCompanyRenewalContactNumber(value))
      return (
         COMPANY_RENEWAL_CONTACT_PREFIX +
         value
            .trim()
            .substring(COMPANY_RENEWAL_CONTACT_PREFIX.length)
            .padStart(REGISTRATION_NUMBER_LENGTH, '0')
      );

   if (isEmployerNumber(value))
      return (
         EMPLOYER_PREFIX +
         value
            .trim()
            .substring(EMPLOYER_PREFIX.length)
            .padStart(REGISTRATION_NUMBER_LENGTH, '0')
      );

   if (isTrainingProviderNumber(value))
      return (
         TRAINING_PROVIDER_PREFIX +
         value
            .trim()
            .substring(TRAINING_PROVIDER_PREFIX.length)
            .padStart(REGISTRATION_NUMBER_LENGTH, '0')
      );

   if (isUnregisteredNumber(value))
      return (
         UNREGISTERED_PREFIX +
         value
            .trim()
            .substring(UNREGISTERED_PREFIX.length)
            .padStart(REGISTRATION_NUMBER_LENGTH, '0')
      );

   return value.trim().padStart(REGISTRATION_NUMBER_LENGTH, '0');
};
