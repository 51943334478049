import React, { useState, useEffect } from 'react';
import FeeRequestDetails from './Views/FeeRequestDetails';
import FeeRequestLinesTable from './Views/FeeRequestLinesTable';
import Loading from 'Components/Common/Loading';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import DialogContainer from 'react-md/lib/Dialogs';
import FeeRequestLine from './FeeRequestLine/Views/FeeRequestLine';
import { CellInfo } from 'react-table';
import { cloneDeep } from 'lodash-es';
import {
   FeesPaymentTypes,
   FeesPaymentActions,
   selectCreatedFeeRequestId,
} from 'State/Redux/FeesPaymentRedux';
import {
   selectIsSuccess,
   selectErrorMessage,
   selectIsLoading,
} from 'State/Redux/AsyncRedux';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FeeRequestInput from 'Models/FeesPayment/FeeRequestInput';
import FeeRequestDetailDisplay, {
   DefaultFeeRequestDetail,
} from 'Models/FeesPayment/FeeRequestDetailDisplay';
import FeeRequestLineDisplay, {
   getDefaultFeeRequestLineDisplayList,
   DefaultFeeRequestLineDisplay,
} from 'Models/FeesPayment/FeeRequestLineDisplay';
import { selectContact } from 'State/Redux/ContactRedux';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useParams } from 'react-router';
import Button from 'react-md/lib/Buttons';
import {
   FEE_REQUEST_UNPAID,
   FEE_REQUEST_CANCELLED,
   FEE_REQUEST_PAID,
} from 'Util/Constants/FeeRequestStatus';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';
import './style.scss';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface ContactIdUrlParam {
   contactId?: string;
}

interface FeeRequestParam {
   editMode?: boolean;
   feeRequestDisplayInput?: FeeRequestDetailDisplay;
   feeRequestLinesInput?: FeeRequestLineDisplay[];
}

const EMPTY_INDEX = -1;
const FEE_REQUEST_EDIT_URL = '/fees-payments/edit-fee-request/';
const PAYMENT_EDIT_URL = '/payment?feeRequestId';

const FeeRequest = ({
   editMode,
   feeRequestDisplayInput,
   feeRequestLinesInput,
}: Readonly<FeeRequestParam>): JSX.Element => {
   const contact = useSelector(selectContact);
   const saveError = useSelector(
      selectErrorMessage(FeesPaymentTypes.SAVE_FEE_REQUEST_REQUEST)
   );
   const saveSuccess = useSelector(
      selectIsSuccess([FeesPaymentTypes.SAVE_FEE_REQUEST_REQUEST])
   );
   const isLoading = useSelector(
      selectIsLoading([FeesPaymentTypes.SAVE_FEE_REQUEST_REQUEST])
   );
   const createdFeeRequestId = useSelector(selectCreatedFeeRequestId);
   const saveErrorMessage = useSelector(
      selectErrorMessage(FeesPaymentTypes.SAVE_FEE_REQUEST_REQUEST)
   );
   const dispatch = useDispatch();

   const [feeRequestLineInputVisible, setFeeRequestLineInputVisible] = useState(
      false
   );
   const [feeRequest, setFeeRequest] = useState<FeeRequestDetailDisplay>(
      editMode && feeRequestDisplayInput
         ? feeRequestDisplayInput
         : DefaultFeeRequestDetail
   );
   const [feeRequestLines, setFeeRequestLines] = useState(
      editMode && feeRequestLinesInput
         ? feeRequestLinesInput
         : getDefaultFeeRequestLineDisplayList()
   );
   const [feeRequestLineEditModel, setFeeRequestLineEditModel] = useState(
      DefaultFeeRequestLineDisplay
   );
   const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
   const [calcTotal, setCalcTotal] = useState(true);
   const [saveClicked, setSaveClicked] = useState(false);
   const [feeLineEditMode, setFeeLineEditMode] = useState(false);
   const [saveAndNew, setSaveAndNew] = useState(false);
   const [isInvalidRegistration, setIsInvalidRegistration] = useState(false);
   const [editIndex, setEditIndex] = useState(EMPTY_INDEX);
   const feeRequestLineTitle = 'Add Fee Request Line';
   const saveSuccessMessage = editMode
      ? 'Fee Request Updated'
      : 'Fee Request Created';
   const history = useHistory();
   const params = useParams<ContactIdUrlParam>();

   const addNewRow = (e: FeeRequestLineDisplay): void => {
      if (feeLineEditMode && editIndex >= 0) {
         const newState = feeRequestLines;
         newState[editIndex] = e;
         setFeeRequestLines(newState);
      } else {
         const newState = [...feeRequestLines, e];
         setFeeRequestLines(newState);
      }
      setFeeRequestLineInputVisible(false);
      setCalcTotal(true);
   };

   const deleteRow = (e: CellInfo): void => {
      const feeLines = cloneDeep(feeRequestLines);
      feeLines.splice(e.index, 1);
      setFeeRequestLines(feeLines);
      setCalcTotal(true);
   };

   const showEdit = (cell: CellInfo): void => {
      const editModel = feeRequestLines[cell.index];
      setEditIndex(cell.index);
      setFeeLineEditMode(true);
      setFeeRequestLineEditModel(editModel);
      setFeeRequestLineInputVisible(true);
   };

   const handleSave = (saveAndNew?: boolean): void => {
      // validate the model
      const inputModel = getFeeRequestInputModel();

      if (!inputModel.registrationNumber) {
         toast.error('Please enter the valid Licence Number.');
         setIsInvalidRegistration(true);
         return;
      }

      setSaveClicked(true);
      setSaveAndNew(saveAndNew ? saveAndNew : false);
      dispatch(FeesPaymentActions.saveFeeRequestRequest(inputModel));
   };

   const handleReset = (): void => {
      setFeeRequestLines(getDefaultFeeRequestLineDisplayList());
      setFeeRequest(DefaultFeeRequestDetail);
   };

   const handleDeleteFeeRequest = (): void => {
      setShowDeleteConfirm(true);
   };

   const handleCancel = (): void => {
      const inputModel = getFeeRequestInputModel(true);
      setSaveClicked(true);
      setFeeRequest({
         ...feeRequest,
         feeRequestStatusCode: FEE_REQUEST_CANCELLED,
      });
      dispatch(FeesPaymentActions.saveFeeRequestRequest(inputModel));
   };

   const getFeeRequestInputModel = (isCancelled?: boolean): FeeRequestInput => {
      return {
         feeRequestId: feeRequest.feeRequestId,
         applicationId: feeRequest.applicationId,
         feeRequestDate: '',
         feeRequestNumber: feeRequest.feeRequestNumber,
         registrationNumber: feeRequest.registrationNumber,
         feeRequestStatusCode: isCancelled
            ? FEE_REQUEST_CANCELLED
            : feeRequest.feeRequestStatusCode,
         paidAmount: feeRequest.paidAmount,
         totalAmount: feeRequest.totalAmount,
         feeRequestLine: feeRequestLines,
      };
   };

   const returnUrl =
      history &&
      history.location &&
      history.location.state &&
      history.location.state.returnUrl
         ? history.location.state.returnUrl
         : undefined;

   useEffect((): void => {
      if (
         !feeRequest.contactId &&
         history.location &&
         params.contactId &&
         !editMode
      ) {
         const contactIdFromUrl = Number(params.contactId);
         if (contact && contact.contactId === contactIdFromUrl) {
            setFeeRequest({
               ...feeRequest,
               contactId: contactIdFromUrl,
               registrationNumber: contact.registrationNumber,
            });
         }
      }
   }, [feeRequest, setFeeRequest, history, contact, params, editMode]);

   useEffect((): void => {
      if (saveClicked && saveSuccess) {
         toast.success(saveSuccessMessage);
         setSaveClicked(false);

         if (saveAndNew) {
            handleReset();
         }
      }
   }, [saveSuccess, saveClicked, history, saveAndNew, saveSuccessMessage]);

   useEffect((): void => {
      if (saveClicked && saveError) {
         toast.error(
            saveErrorMessage ? saveErrorMessage : 'Error Saving Fee Request'
         );

         if (
            saveErrorMessage &&
            saveErrorMessage.toLocaleLowerCase().includes('licence number')
         ) {
            setIsInvalidRegistration(true);
         }
         setSaveClicked(false);
      }
   }, [saveError, saveClicked, saveErrorMessage]);

   useEffect((): void => {
      if (feeRequestLines && calcTotal) {
         let totalPaid = 0;
         let total = 0;
         feeRequestLines
            .filter(f => f.paid)
            .forEach(p => (totalPaid += p.feeRequestLineAmount));
         feeRequestLines.forEach(p => (total += p.feeRequestLineAmount));
         setFeeRequest({
            ...feeRequest,
            totalAmount: total,
            paidAmount: totalPaid,
         });
         setCalcTotal(false);
      }
   }, [feeRequestLines, calcTotal, feeRequest]);

   if (
      editMode &&
      feeRequest.feeRequestId &&
      saveAndNew &&
      saveClicked &&
      saveSuccess
   ) {
      // 1. Edit mode and Save + Pay button pressed => Route to Payment Edit Screen
      return (
         <Redirect
            to={`${PAYMENT_EDIT_URL}=${feeRequest.feeRequestId}&returnUrl=${history.location.pathname}`}
         />
      );
   } else if (
      !editMode &&
      !saveAndNew &&
      saveClicked &&
      saveSuccess &&
      returnUrl
   ) {
      // 2. New Mode save => Route back to Return Url
      return <Redirect to={returnUrl} />;
   } else if (
      !editMode &&
      !saveAndNew &&
      saveClicked &&
      saveSuccess &&
      createdFeeRequestId &&
      createdFeeRequestId > 0
   ) {
      // 3. New Mode save => Route to Edit screen
      return <Redirect to={`${FEE_REQUEST_EDIT_URL}${createdFeeRequestId}`} />;
   }

   const renderConfirmDialog = (): JSX.Element => {
      return (
         <ConfirmationDialog
            id="example-confirmation-default"
            onConfirm={(): void => {
               setShowDeleteConfirm(false);
               handleCancel();
            }}
            onCancel={(): void => {
               setShowDeleteConfirm(false);
            }}
            visible={showDeleteConfirm}
            cancelLabel="Cancel"
            confirmLabel="OK"
            title="Confirm Delete"
         >
            <>Do you want to Delete the Fee Request ?</>
         </ConfirmationDialog>
      );
   };

   return (
      <>
         {returnUrl && (
            <div className="md-cell md-cell--12">
               <Link to={returnUrl}>
                  <Button
                     flat
                     primary
                     iconChildren="arrow_back"
                     className="btn-small"
                  >
                     Back
                  </Button>
               </Link>
            </div>
         )}
         <Loading
            isLoading={isLoading}
            isError={false}
            tryReload={FN_EMPTY_VOID}
         >
            <>
               {renderConfirmDialog()}
               <ExpansionList className="md-cell md-cell--12 fee-request-container">
                  <ExpansionPanel
                     label={
                        editMode ? 'Edit Fee Request' : 'Create Fee Request'
                     }
                     footer={null}
                     expanded
                     expanderIcon={<></>}
                     onExpandToggle={FN_EMPTY_VOID}
                     className="fee-request-details"
                  >
                     <FeeRequestDetails
                        model={feeRequest}
                        updateModel={setFeeRequest}
                        isInvalidRegistration={isInvalidRegistration}
                        updateRegistrationValidation={setIsInvalidRegistration}
                        editMode={editMode}
                     />
                  </ExpansionPanel>
                  <ExpansionPanel
                     label="Fee Request Lines"
                     footer={null}
                     expanded
                     expanderIcon={<></>}
                     onExpandToggle={FN_EMPTY_VOID}
                     className="fee-request-lines"
                  >
                     <FeeRequestLinesTable
                        tableData={feeRequestLines}
                        onSave={handleSave}
                        onReset={handleReset}
                        onAdd={(): void => {
                           setEditIndex(EMPTY_INDEX);
                           setFeeLineEditMode(false);
                           setFeeRequestLineInputVisible(true);
                        }}
                        onEdit={showEdit}
                        onDeleteFeeRequestLine={deleteRow}
                        onDeleteFeeRequest={
                           editMode &&
                           feeRequest.feeRequestStatusCode ===
                              FEE_REQUEST_UNPAID &&
                           !feeRequest.applicationId
                              ? handleDeleteFeeRequest
                              : undefined
                        }
                        editMode={editMode}
                        hideControls={
                           feeRequest.feeRequestStatusCode ===
                              FEE_REQUEST_CANCELLED ||
                           feeRequest.feeRequestStatusCode === FEE_REQUEST_PAID
                        }
                        paymentUrl={`${PAYMENT_EDIT_URL}=${feeRequest.feeRequestId}&returnUrl=${history.location.pathname}`}
                     />
                     <DialogContainer
                        id="dialog-container-feerequest-line-edit"
                        visible={feeRequestLineInputVisible}
                        focusOnMount={false}
                        containFocus={false}
                        onHide={FN_EMPTY_VOID}
                        contentClassName="md-grid"
                        title={feeRequestLineTitle}
                        lastChild
                        disableScrollLocking
                        renderNode={document.body}
                        className="fee-request-line-dialog"
                     >
                        <FeeRequestLine
                           onCancel={(): void =>
                              setFeeRequestLineInputVisible(false)
                           }
                           onSave={(e: FeeRequestLineDisplay): void =>
                              addNewRow(e)
                           }
                           model={feeRequestLineEditModel}
                           editMode={feeLineEditMode}
                        />
                     </DialogContainer>
                  </ExpansionPanel>
               </ExpansionList>
            </>
         </Loading>
      </>
   );
};

export default React.memo(FeeRequest);
