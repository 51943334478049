import React, { useEffect } from 'react';
import { ContactEditModel } from 'Models/Contact/Data/ContactEditModel';
import TextField from 'react-md/lib/TextFields';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { ValidationResult } from 'Components/Common/ModelValidator/ValidationModel';
import { useContactStatusList } from 'Util/Helpers/Metadata';
import SelectField from 'react-md/lib/SelectFields';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { TrainingProviderValidationFields } from './CreateTrainingProvider';

interface TrainingProviderHeaderSectionProps {
   model: ContactEditModel;
   updateModel: (model: ContactEditModel) => void;
   isEditMode?: boolean;
   validationResult?: ValidationResult<TrainingProviderValidationFields>;
}

const TrainingProviderHeaderSection = ({
   model,
   updateModel,
   isEditMode,
   validationResult,
}: Readonly<TrainingProviderHeaderSectionProps>): JSX.Element => {
   const contactStatuses = useContactStatusList();

   useEffect((): void => {
      if (!model.contactStatusCode && contactStatuses.length) {
         updateModel({ ...model, contactStatusCode: contactStatuses[0].value });
      }
   }, [model, contactStatuses, updateModel]);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-company-name"
               label="Company Trading Name"
               lineDirection="center"
               placeholder="Company Trading Name"
               className="md-cell md-cell--bottom md-cell--12"
               inputClassName={getInputValidationClassName(
                  validationResult && validationResult.fields.companyName.error
               )}
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     companyName: val.toString(),
                  });
               }}
               defaultValue={model.companyName}
               required
               errorText="Please enter the company trading name"
               error={
                  validationResult && validationResult.fields.companyName.error
               }
               maxLength={100}
            />
            <TextField
               floating
               id="floating-center-company-url"
               label="URL"
               lineDirection="center"
               placeholder="URL"
               className="md-cell md-cell--bottom md-cell--12"
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     url: val.toString(),
                  });
               }}
               defaultValue={model.url}
               maxLength={200}
            />
            <Checkbox
               id="checkbox-licensing-tp"
               name="simple-checkboxes[]"
               label="Licensing Training Provider"
               value="material-design"
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     licensingTrainingProvider: val,
                  });
               }}
               checked={model.licensingTrainingProvider}
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="training-provider-id"
               label="Training Provider Id"
               lineDirection="center"
               placeholder="Training Provider Id"
               className="md-cell md-cell--bottom md-cell--12"
               type="number"
               maxLength={200}
               disabled
               defaultValue={model.contactId}
            />
            <SelectField
               floating
               id="select-contact-status"
               label="Status"
               placeholder="Select Status"
               className="md-cell md-cell--12 contact-status-select"
               inputClassName={getInputValidationClassName(
                  validationResult &&
                     validationResult.fields.contactStatusCode.error
               )}
               menuItems={contactStatuses}
               position={SelectField.Positions.BELOW}
               onChange={(val): void => {
                  updateModel({
                     ...model,
                     contactStatusCode: val.toString(),
                  });
               }}
               value={model.contactStatusCode}
               required
               errorText="Please select the Status"
               error={
                  validationResult &&
                  validationResult.fields.contactStatusCode.error
               }
            />
         </div>
      </div>
   );
};

export default TrainingProviderHeaderSection;
