import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
// import WebMessageDtoReadonly from 'Models/Notifications/WebMessageDto';
import SqlReportingReadonly from 'Models/Report/SqlReporting';
import { createScopedActions } from '.';
import produce from 'immer';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ReportState {
   sqlQueries: readonly SqlReportingReadonly[];
   sqlQueryResults: readonly Readonly<Record<string, any>>[]; // eslint-disable-line
}

export type ReportStateReadonly = Readonly<ReportState>;

interface TypeNames {
   GET_SQL_QUERIES_REQUEST: string;
   GET_SQL_QUERIES_SUCCESS: string;
   GET_SQL_QUERIES_FAILURE: string;

   GET_SQL_QUERY_RESULTS_REQUEST: string;
   GET_SQL_QUERY_RESULTS_SUCCESS: string;
   GET_SQL_QUERY_RESULTS_FAILURE: string;

   EDIT_SQL_QUERY_REQUEST: string;
   EDIT_SQL_QUERY_SUCCESS: string;
   EDIT_SQL_QUERY_FAILURE: string;

   ADD_SQL_QUERY_REQUEST: string;
   ADD_SQL_QUERY_SUCCESS: string;
   ADD_SQL_QUERY_FAILURE: string;

   DELETE_SQL_QUERY_REQUEST: string;
   DELETE_SQL_QUERY_SUCCESS: string;
   DELETE_SQL_QUERY_FAILURE: string;
}

type ReportTypeNames = Readonly<TypeNames>;

export interface ReportCreators {
   getSqlQueriesRequest: () => AnyAction;
   getSqlQueriesSuccess: (data: SqlReportingReadonly | undefined) => AnyAction;
   getSqlQueriesFailure: (error: ApiError) => AnyAction;

   getSqlQueryResultsRequest: (id: number) => AnyAction;
   getSqlQueryResultsSuccess: (data: string | undefined) => AnyAction;
   getSqlQueryResultsFailure: (error: ApiError) => AnyAction;

   editSqlQueryRequest: (id: number, newQuery: string) => AnyAction;
   editSqlQuerySuccess: (data: string | undefined) => AnyAction;
   editSqlQueryFailure: (error: ApiError) => AnyAction;

   addSqlQueryRequest: (query: SqlReportingReadonly) => AnyAction;
   addSqlQuerySuccess: (data: string | undefined) => AnyAction;
   addSqlQueryFailure: (error: ApiError) => AnyAction;

   deleteSqlQueryRequest: (id: number) => AnyAction;
   deleteSqlQuerySuccess: (data: string | undefined) => AnyAction;
   deleteSqlQueryFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ReportStateReadonly = {
   sqlQueries: [],
   sqlQueryResults: [],
};

/* ------------- Reducers ------------- */
const getSqlQueriesSuccess = (
   state: ReportState,
   action: AnyAction
): ReportStateReadonly => {
   return { ...state, sqlQueries: action.data };
};

const getSqlQueryResultsSuccess = (
   state: ReportState,
   action: AnyAction
): ReportStateReadonly => {
   return { ...state, sqlQueryResults: action.data };
};

const editSqlQuerySuccess = (
   state: ReportState,
   action: AnyAction
): ReportStateReadonly => {
   return produce(state, (draftState): void => {
      const updatedSql = action.data;
      const sqlFound = draftState.sqlQueries.find(
         (sql): boolean => sql.sqlId === updatedSql.sqlId
      );
      if (sqlFound) {
         const index = draftState.sqlQueries.indexOf(sqlFound);
         draftState.sqlQueries[index] = {
            ...updatedSql,
         };
      }
   });
};

const addSqlQuerySuccess = (
   state: ReportState,
   action: AnyAction
): ReportStateReadonly => {
   return produce(state, (draftState): void => {
      draftState.sqlQueries.push(action.data);
   });
};

const deleteSqlQuerySuccess = (
   state: ReportState,
   action: AnyAction
): ReportStateReadonly => {
   return produce(state, (draftState): void => {
      const id = action.data;
      const sqlFound = draftState.sqlQueries.find(
         (sql): boolean => sql.sqlId === id
      );
      if (sqlFound) {
         const index = draftState.sqlQueries.indexOf(sqlFound);
         draftState.sqlQueries.splice(index, 1);
      }
   });
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ReportTypeNames,
   ReportCreators
>('report', {
   getSqlQueriesRequest: [],
   getSqlQueriesSuccess: ['data'],
   getSqlQueriesFailure: ['error'],

   getSqlQueryResultsRequest: ['id'],
   getSqlQueryResultsSuccess: ['data'],
   getSqlQueryResultsFailure: ['error'],

   editSqlQueryRequest: ['id', 'newQuery'],
   editSqlQuerySuccess: ['data'],
   editSqlQueryFailure: ['error'],

   addSqlQueryRequest: ['query'],
   addSqlQuerySuccess: ['data'],
   addSqlQueryFailure: ['error'],

   deleteSqlQueryRequest: ['id'],
   deleteSqlQuerySuccess: ['data'],
   deleteSqlQueryFailure: ['error'],
});

export const ReportTypes = Types;
export const ReportActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_SQL_QUERIES_SUCCESS]: getSqlQueriesSuccess,
   [Types.GET_SQL_QUERY_RESULTS_SUCCESS]: getSqlQueryResultsSuccess,
   [Types.EDIT_SQL_QUERY_SUCCESS]: editSqlQuerySuccess,
   [Types.ADD_SQL_QUERY_SUCCESS]: addSqlQuerySuccess,
   [Types.DELETE_SQL_QUERY_SUCCESS]: deleteSqlQuerySuccess,
});

/* ------------- Selectors ------------- */
export const selectSqlQueries = (
   state: StateReadonly
): readonly SqlReportingReadonly[] => state.report.sqlQueries;

export const selectSqlQueryResults = (
   state: StateReadonly
): readonly Readonly<Record<string, any>>[] => state.report.sqlQueryResults; // eslint-disable-line
