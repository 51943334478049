import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { LicenceYear } from 'Models/Metadata/LicenceYear';
import { Condition } from 'Models/Metadata/Condition';
import { FeeRequestStatus } from 'Models/Metadata/FeeRequestStatus';
import { FinancialReportType } from 'Models/Metadata/FinancialReportType';
import { Metadata } from 'Models/Metadata';
import { ApplicationStatus } from 'Models/Metadata/ApplicationStatus';
import { createScopedActions } from '.';
import { ApplicationType } from 'Models/Metadata/ApplicationType';
import { MetadataTypeModel } from 'Models/Metadata/MetadataByType';
import { MetadataType } from 'Util/Constants/MetadataType';
import { camelCase } from 'lodash-es';
import { LicenceType } from 'Models/Metadata/LicenceType';
import { GenericType } from 'Models/Metadata/GenericType';

/* ------------- Interfaces for ReduxSauce ------------- */

// We may want to change the structure of this.
// However, for now we're just storing and accessing the data the same as the API result
//eslint-disable-next-line
interface MetadataState extends Metadata {}

export type MetadataStateReadonly = Readonly<MetadataState>;

interface TypeNames {
   GET_METADATA_REQUEST: string;
   GET_METADATA_SUCCESS: string;
   GET_METADATA_FAILURE: string;

   GET_METADATA_BY_TYPE_REQUEST: string;
   GET_METADATA_BY_TYPE_SUCCESS: string;
   GET_METADATA_BY_TYPE_FAILURE: string;
}

type MetadataTypeNames = Readonly<TypeNames>;

export interface MetadataCreators {
   getMetadataRequest: () => AnyAction;
   getMetadataSuccess: (data: Metadata) => AnyAction;
   getMetadataFailure: (error: ApiError) => AnyAction;

   getMetadataByTypeRequest: (metadataType: MetadataType) => AnyAction;
   getMetadataByTypeSuccess: (data: MetadataTypeModel) => AnyAction;
   getMetadataByTypeFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = ({} as unknown) as MetadataStateReadonly;

/* ------------- Reducers ------------- */
const getMetadataSuccess = (
   state: MetadataStateReadonly,
   action: AnyAction
): MetadataStateReadonly => {
   return { ...state, ...action.data };
};

const getMetadataByTypeuccess = (
   state: MetadataStateReadonly,
   action: AnyAction
): MetadataStateReadonly => {
   if (action && action.data) {
      return {
         ...state,
         [camelCase(action.data.type)]: action.data.data,
      };
   }
   return state;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   MetadataTypeNames,
   MetadataCreators
>('metadata', {
   getMetadataRequest: [],
   getMetadataSuccess: ['data'],
   getMetadataFailure: ['error'],

   getMetadataByTypeRequest: ['metadataType'],
   getMetadataByTypeSuccess: ['data'],
   getMetadataByTypeFailure: ['error'],
});

export const MetadataTypes = Types;
export const MetadataActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_METADATA_SUCCESS]: getMetadataSuccess,
   [Types.GET_METADATA_BY_TYPE_SUCCESS]: getMetadataByTypeuccess,
});

/* ------------- Selectors ------------- */
export const selectCurrentLicenceYear = (state: StateReadonly): LicenceYear =>
   state.metadata.currentLicenceYear;

export const selectLicenceYears = (
   state: StateReadonly
): readonly LicenceYear[] => state.metadata.licenceYears;

export const selectApplicationTypes = (
   state: StateReadonly
): readonly ApplicationType[] => state.metadata.applicationTypes;

export const selectLicenceTypesMetadata = (
   state: StateReadonly
): readonly LicenceType[] => state.metadata.licenceTypes;

export const selectApplicationStatuses = (
   state: StateReadonly
): readonly ApplicationStatus[] => state.metadata.applicationStatuses;

export const selectConditions = (state: StateReadonly): readonly Condition[] =>
   state.metadata.conditions;

export const selectFeeRequestStatuses = (
   state: StateReadonly
): readonly FeeRequestStatus[] => state.metadata.feeRequestStatuses;

export const selectSupervisionTypeCodes = (
   state: StateReadonly
): readonly string[] => Object.keys(state.metadata.licenceSupervision);

export const selectFinancialReportTypes = (
   state: StateReadonly
): readonly FinancialReportType[] => state.metadata.financialReportType;

export const selectCanEditYear = (state: StateReadonly): boolean =>
   state.metadata.canEditLicenceYear;

export const selectGenderList = (state: StateReadonly): GenericType[] =>
   state.metadata.genderTypes;

export const selectEthnicityList = (state: StateReadonly): GenericType[] =>
   state.metadata.genderTypes;
