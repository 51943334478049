import { DEFAULT_COUNTRY_CODE } from 'Util/Constants/Address';
import { COUNTRY_CODES } from 'Util/Constants/CountryCode';

interface AddressModelComponents {
   addressLine1: string;
   addressLine2: string;
   suburb: string;
   city: string;
   postcode: string;
   fullAddress: string;
   countryCode: string;
}

export type AddressModel = Readonly<AddressModelComponents>;

export const DefaultAddressModel: AddressModel = {
   addressLine1: '',
   addressLine2: '',
   suburb: '',
   city: 'Select a City',
   postcode: '',
   fullAddress: '',
   countryCode: DEFAULT_COUNTRY_CODE,
};

export const getFullAddress = (address: AddressModel): string => {
   const items: string[] = [];
   items.push(address.addressLine1);
   items.push(address.addressLine2);
   items.push(address.suburb);
   items.push(address.city);

   let country: string | undefined;
   if (address.countryCode && address.countryCode !== DEFAULT_COUNTRY_CODE) {
      country = COUNTRY_CODES.get(address.countryCode);
      if (country) {
         items.push(country);
      }
   }

   let result = items.filter((f): boolean => f !== '').join(',');
   result = address.postcode ? `${result} ${address.postcode}` : result;
   return country ? `${result}, ${country}` : result;
};
