import React, { useState, useCallback } from 'react';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import SelectField from 'react-md/lib/SelectFields';
import Moment from 'moment-timezone';
import { selectIsSuccess, selectIsLoading } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';
import DateInput from 'Components/Common/DateInput/DateInput';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import {
   DefaultBoardMeetingDateModel,
   validateBoardMeetingDate,
   DESCRIPTION_MAX_LENGTH,
} from 'Models/BoardMeetingDate/BoardMeetingDate';
import {
   BoardMeetingDateActions,
   BoardMeetingDateTypes,
   selectBoardMeetingDate,
} from 'State/Redux/BoardMeetingDateRedux';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import LoadingButton from 'Components/Common/LoadingButton';
import { BOARD_MEETING_DATE_STATUSES } from 'Util/Constants/BoardMeetingDate';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const formatDate = (date: string | null): string => {
   return date && Moment(date).isValid()
      ? Moment(date).format(NAMED_DATE_FORMAT)
      : '';
};

const AddBoardMeetingDate = (): JSX.Element => {
   const boardMeetingDate = useSelector(selectBoardMeetingDate);
   const isLoading = useSelector(
      selectIsLoading([BoardMeetingDateTypes.ADD_BOARD_MEETING_DATE_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([BoardMeetingDateTypes.ADD_BOARD_MEETING_DATE_REQUEST])
   );

   const dispatch = useDispatch();

   const [boardMeetingDateModel, setBoardMeetingDateModel] = useState(
      DefaultBoardMeetingDateModel
   );

   const [addClicked, setAddClicked] = useState(false);

   const onAdd = useCallback((): void => {
      dispatch(
         BoardMeetingDateActions.addBoardMeetingDateRequest({
            ...boardMeetingDateModel,
         })
      );
      setAddClicked(true);
   }, [dispatch, boardMeetingDateModel]);

   if (addClicked && isSuccess && boardMeetingDate !== null) {
      toast.success(
         `Board meeting date on ${formatDate(
            boardMeetingDate.boardMeetingDate
         )} created!`
      );
      setAddClicked(false);
      setBoardMeetingDateModel(DefaultBoardMeetingDateModel);
   }
   const isValidForm = Object.values(
      validateBoardMeetingDate(boardMeetingDateModel)
   ).every((v): boolean => !!v);

   return (
      <ExpansionList className="md-cell md-cell--12">
         <ExpansionPanel
            label="Add"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <div className="md-grid md-cell--12">
               <div className="md-cell md-cell--6">
                  <SelectField
                     floating
                     id="select-field-6"
                     lineDirection="center"
                     label="Board Meeting Status"
                     placeholder="Board Meeting Status"
                     className="md-cell md-cell--12"
                     menuItems={BOARD_MEETING_DATE_STATUSES}
                     position={SelectField.Positions.BELOW}
                     value={boardMeetingDateModel.boardMeetingStatus}
                     onChange={(val): void => {
                        const newModel = {
                           ...boardMeetingDateModel,
                           boardMeetingStatus: val.toString(),
                        };
                        setBoardMeetingDateModel(newModel);
                     }}
                     defaultValue=" "
                     required
                     errorText="Please enter a valid status"
                  />
               </div>
               <div className="md-cell md-cell--6">
                  <TextField
                     floating
                     id="floating-center-title"
                     label="Description"
                     lineDirection="center"
                     className="md-cell md-cell--12"
                     placeholder="Description"
                     value={boardMeetingDateModel.description}
                     maxLength={DESCRIPTION_MAX_LENGTH}
                     onChange={(val): void => {
                        const newModel = {
                           ...boardMeetingDateModel,
                           description: val.toString(),
                        };
                        setBoardMeetingDateModel(newModel);
                     }}
                     required
                  />
               </div>

               <div className="md-cell md-cell--6">
                  <DateInput
                     id="add-start-date"
                     className="md-cell md-cell--12"
                     label="Board Meeting Date"
                     value={boardMeetingDateModel.boardMeetingDate}
                     onChange={(val): void => {
                        const newModel = {
                           ...boardMeetingDateModel,
                           boardMeetingDate: val.toString(),
                        };
                        setBoardMeetingDateModel(newModel);
                     }}
                     required
                  />
               </div>

               <div className="md-cell md-cell--12">
                  <LoadingButton
                     flat
                     primary
                     swapTheming
                     isLoading={isLoading}
                     className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
                     disabled={!isValidForm}
                     onKeyUp={(keyPress): void => {
                        if (!isEnterKeyPress(keyPress)) return;
                        onAdd();
                     }}
                     onClick={(): void => {
                        onAdd();
                     }}
                  >
                     Add
                  </LoadingButton>
               </div>
            </div>
         </ExpansionPanel>
      </ExpansionList>
   );
};

export default AddBoardMeetingDate;
