import React from 'react';
import Button from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import './CreateLicenceModalBody.scss';

interface CreateLicenceModalBodyProps {
   updateCreateLicence: (state: boolean | null) => void;
   updateShowModal: (state: boolean) => void;
}

export const CreateLicenceModalBody = ({
   updateCreateLicence,
   updateShowModal,
}: Readonly<CreateLicenceModalBodyProps>): JSX.Element => {
   const createLicence = (): void => {
      updateCreateLicence(true);
      updateShowModal(false);
   };
   const noLicence = (): void => {
      updateCreateLicence(false);
      updateShowModal(false);
   };
   const hideModal = (): void => {
      updateCreateLicence(null);
      updateShowModal(false);
   };

   return (
      <div className="md-grid md-cell--12">
         <div className="md-grid md-cell--12 grey-background create-licence-modal">
            <div className="md-grid md-cell--12">
               When the registration is approved, would you like a licence to be
               created?
            </div>
            <div className="md-grid md-cell--12">
               <Button
                  className="btn-spacing"
                  onClick={createLicence}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) createLicence();
                  }}
                  flat
                  swapTheming
                  primary
               >
                  Yes
               </Button>
               <Button
                  className="red-btn btn-spacing"
                  onClick={noLicence}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) noLicence();
                  }}
                  flat
                  swapTheming
                  secondary
               >
                  No
               </Button>
               <Button
                  className="btn-grey btn-spacing"
                  onClick={hideModal}
                  onKeyUp={(evt): void => {
                     if (isEnterKeyPress(evt)) hideModal();
                  }}
                  flat
                  swapTheming
                  secondary
               >
                  Cancel
               </Button>
            </div>
         </div>
      </div>
   );
};
