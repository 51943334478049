import { put, call } from 'redux-saga/effects';
import { SupervisionActions } from '../Redux/SupervisionRedux';
import { SupervisionServiceType } from '../Services/SupervisionService';
import { ApiResponse } from 'apisauce';
import ContactReadonly from 'Models/Contact/Data/Contact';
import { Supervision } from 'Models/Supervision/Data/Supervision';
import ChangeSupervisorDtoReadonly from 'Models/Supervision/Dto/ChangeSupervisionDto';
import ChangeSupervisionEndDateDtoReadOnly from 'Models/Supervision/Dto/ChangeSupervisionEndDateDto';
import { Supervisee } from 'Models/Supervision/Data/Supervisee';

interface SupervisionParams {
   type: string;
   token: string;
   id: number;
   supervisionId: number;
   registrationNumber: string;
   changeSupervisorDto: ChangeSupervisorDtoReadonly;
   changeEndDateDto: ChangeSupervisionEndDateDtoReadOnly;
}

export function* getSupervisorHistory(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { id } = action;

   yield put(SupervisionActions.getSupervisorHistoryReset());

   const supervisionResponse = yield call(
      supervisionService.getSupervisorHistory,
      id
   );
   const response = supervisionResponse as ApiResponse<readonly Supervision[]>;

   if (response.status && response.ok) {
      yield put(SupervisionActions.getSupervisorHistorySuccess(response.data));
   } else {
      yield put(
         SupervisionActions.getSupervisorHistoryFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getSuperviseeHistory(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { id } = action;

   const supervisionResponse = yield call(
      supervisionService.getSuperviseeHistory,
      id
   );
   const response = supervisionResponse as ApiResponse<readonly Supervisee[]>;

   if (response.status && response.ok) {
      yield put(SupervisionActions.getSuperviseeHistorySuccess(response.data));
   } else {
      yield put(
         SupervisionActions.getSuperviseeHistoryFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* searchSupervisor(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { registrationNumber } = action;
   const supervisionResponse = yield call(
      supervisionService.searchSupervisor,
      registrationNumber
   );
   const response = supervisionResponse as ApiResponse<ContactReadonly>;

   if (response.status && response.ok) {
      yield put(SupervisionActions.searchSupervisorSuccess(response.data));
   } else {
      yield put(
         SupervisionActions.searchSupervisorFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* changeSupervisor(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { changeSupervisorDto } = action;
   const supervisionResponse = yield call(
      supervisionService.changeSupervisor,
      changeSupervisorDto
   );
   const response = supervisionResponse as ApiResponse<Supervision>;

   if (response.status && response.ok) {
      yield put(SupervisionActions.changeSupervisorSuccess(response.data));
   } else {
      yield put(
         SupervisionActions.changeSupervisorFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* changeSupervisionEndDate(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { changeEndDateDto } = action;
   const supervisionResponse = yield call(
      supervisionService.changeSupervisionEndDate,
      changeEndDateDto
   );
   const response = supervisionResponse as ApiResponse<Supervision>;

   if (response.status && response.ok) {
      yield put(
         SupervisionActions.changeSupervisionEndDateSuccess(response.data)
      );
   } else {
      yield put(
         SupervisionActions.changeSupervisionEndDateFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* acceptSupervision(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { supervisionId } = action;
   const supervisionResponse = yield call(
      supervisionService.acceptSupervision,
      supervisionId
   );
   const response = supervisionResponse as ApiResponse<Supervisee>;

   if (response.status && response.ok) {
      yield put(SupervisionActions.acceptSupervisionSuccess(response.data));
   } else {
      yield put(
         SupervisionActions.acceptSupervisionFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* declineSupervision(
   supervisionService: Readonly<SupervisionServiceType>,
   action: Readonly<SupervisionParams>
): Generator {
   const { supervisionId } = action;
   const supervisionResponse = yield call(
      supervisionService.declineSupervision,
      supervisionId
   );
   const response = supervisionResponse as ApiResponse<Supervisee>;

   if (response.status && response.ok) {
      yield put(SupervisionActions.declineSupervisionSuccess(response.data));
   } else {
      yield put(
         SupervisionActions.declineSupervisionFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
