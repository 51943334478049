import {
   isNullOrWhiteSpace,
   isValidEndDateAfterStartDate,
   isValidDate,
} from 'Util/Helpers/Validation';
import moment from 'moment';
import {
   DESCRIPTION_MAX_LENGTH,
   TITLE_MAX_LENGTH,
} from 'Components/Pages/GlobalNotification/GlobalNotification';

interface GlobalNotification {
   globalNotificationId: number;
   title: string;
   description: string;
   startTime: string;
   endTime: string;
   forceLogout: boolean;
}

export type ReadonlyGlobalNotification = Readonly<GlobalNotification>;

export const DefaultGlobalNotification: ReadonlyGlobalNotification = {
   globalNotificationId: 0,
   title: '',
   description: '',
   startTime: '',
   endTime: '',
   forceLogout: false,
};

interface GlobalNotificationValidationModel {
   title: boolean;
   description: boolean;
   startTime: boolean;
   endTime: boolean;
}

export type GlobalNotificationValidation = Readonly<
   GlobalNotificationValidationModel
>;

export const DefaultComplaintSearchQueryValidation: GlobalNotificationValidation = {
   title: false,
   description: false,
   startTime: false,
   endTime: false,
};

export const validateUpdate = (
   query: ReadonlyGlobalNotification,
   formChanged: boolean
): GlobalNotificationValidation => {
   if (!formChanged) {
      return {
         title: true,
         description: true,
         startTime: true,
         endTime: true,
      };
   }

   return {
      title:
         !isNullOrWhiteSpace(query.title) &&
         query.title.length <= TITLE_MAX_LENGTH,
      description:
         !isNullOrWhiteSpace(query.description) &&
         query.description.length <= DESCRIPTION_MAX_LENGTH,
      startTime:
         isValidDate(query.startTime) &&
         isValidEndDateAfterStartDate(
            moment()
               .subtract(1, 'd') // need to do this due to time of day.
               .toString(),
            query.startTime
         ), // don't trust the datepicker
      endTime:
         isValidDate(query.endTime) &&
         isValidEndDateAfterStartDate(query.startTime, query.endTime),
   };
};

export const filterToValidUpdate = (
   query: ReadonlyGlobalNotification
): ReadonlyGlobalNotification => {
   return {
      globalNotificationId: query.globalNotificationId,
      title:
         !isNullOrWhiteSpace(query.title) &&
         query.title.length <= TITLE_MAX_LENGTH
            ? query.title.trim()
            : '',
      description:
         !isNullOrWhiteSpace(query.description) &&
         query.description.length <= DESCRIPTION_MAX_LENGTH
            ? query.description.trim()
            : '',
      startTime:
         query.startTime &&
         isValidEndDateAfterStartDate(
            moment()
               .subtract(1, 'd') // need to do this due to time of day.
               .toString(),
            query.startTime
         )
            ? query.startTime
            : '', // trust the datepicker
      endTime:
         query.endTime &&
         isValidEndDateAfterStartDate(query.startTime, query.endTime)
            ? query.endTime
            : '',
      forceLogout: query.forceLogout,
   };
};

export const isValidUpdate = (query: ReadonlyGlobalNotification): boolean => {
   return (
      !!query.title &&
      !!query.description &&
      !!query.startTime &&
      !!query.endTime
   );
};
