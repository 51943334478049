import { call, put } from 'redux-saga/effects';
import { ApiResponse } from 'apisauce';
import { EnumServiceType } from 'State/Services/EnumService';
import DropdownDto from 'Models/Enum/Dto/DropdownDto';
import PostCodeCityDto from 'Models/Enum/Dto/PostCodeCityDto';
import { EnumDtoActions } from 'State/Redux/EnumRedux';

export function* getCities(
   enumService: Readonly<EnumServiceType>,
   /*eslint-disable */
   action: any  //don't need a parameter but saga is complaining
): Generator {
   // make the call to the api
   const enumResponse = yield call(enumService.getCities);
   const response = enumResponse as ApiResponse<DropdownDto[]>;

   if (response.status && response.ok) {
      yield put(EnumDtoActions.getCitiesSuccess(response.data));
   } else {
      yield put(
         EnumDtoActions.getCitiesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getPostCodeCities(
   enumService: Readonly<EnumServiceType>,
   /*eslint-disable */
   action: any //don't need a parameter but saga is complaining
): Generator {
   const enumResponse = yield call(enumService.getPostCodeCities);
   const response = enumResponse as ApiResponse<PostCodeCityDto[]>;
   // make the call to the api

   if (response.status && response.ok) {
      yield put(EnumDtoActions.getPostCodeCitiesSuccess(response.data));
   } else {
      yield put(
         EnumDtoActions.getPostCodeCitiesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
