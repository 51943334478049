import React from 'react';
import {
   DataTable,
   TableHeader,
   TableBody,
   TableRow,
   TableColumn,
} from 'react-md/lib/DataTables';
import { Checkbox } from 'react-md/lib/SelectionControls';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { Button } from 'react-md/lib/Buttons';
import '../Payment.scss';
import FeeRequestUIModel from '../Models/FeeRequestUIModel';
import { sumBy } from 'lodash-es';
import { currencyFormatWithoutSymbol } from 'Util/Helpers/Currency';
import { FEE_REQUEST_PAID } from 'Util/Constants/FeeRequestStatus';
import { Link } from 'react-router-dom';
import PaymentDetailPaymentRecordReadOnly from 'Models/Payment/Data/PaymentDetailPaymentRecord';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector } from 'react-redux';
import { selectMultiPayDetail } from 'State/Redux/PaymentRedux';
import FeeRequestLineUIModel from '../Models/FeeRequestLineUIModel';

const MULTI_PAY_STYLE_NAME = 'added-in-multipay';
interface FeeRequestTableProps {
   isPaid: boolean;
   isCancelled: boolean;
   payment: PaymentDetailPaymentRecordReadOnly;
   feeRequests: FeeRequestUIModel[];
   showHideFeeRequestLine: (feeRequestId: number) => void;
   checkFeeRequest: (feeRequestId: number) => void;
   checkFeeRequestLine: (
      feeRequestId: number,
      feeRequestLineId: number
   ) => void;
   returnUrl: string;
   showFeeRequestLineModal: (
      feeRequestId?: number,
      feeRequestLineId?: number
   ) => void;
   isMultiPay: boolean;
   removeFeeRequest?: (feeRequestId: number) => void;
   removeFeeRequestLine?: (
      feeRequestId: number,
      feeRequestLineId: number
   ) => void;
}

const FeeRequestTable = ({
   isPaid,
   isCancelled,
   payment,
   feeRequests,
   showHideFeeRequestLine,
   checkFeeRequest,
   checkFeeRequestLine,
   returnUrl,
   showFeeRequestLineModal,
   isMultiPay,
   removeFeeRequest,
   removeFeeRequestLine,
}: Readonly<FeeRequestTableProps>): JSX.Element => {
   const multiPayDetail = useSelector(selectMultiPayDetail);

   const getMultiPayStyle = (feeRequestLineId: number): string => {
      if (isMultiPay) {
         return '';
      }
      // update the style for multi pay selected rows
      let returnStyle = '';
      if (multiPayDetail) {
         multiPayDetail.forEach(mf => {
            if (
               mf.feeRequestLines.find(
                  ml => ml.data.feeRequestLineId === feeRequestLineId
               )
            ) {
               returnStyle = MULTI_PAY_STYLE_NAME;
            }
         });
      }
      return returnStyle;
   };

   const renderRemoveMultiPayIcon = (): JSX.Element => {
      return (
         <>
            <FaIcon icon="cart-arrow-down" className="small-icon cart" />
            <span>
               <FaIcon icon="minus-circle" className="small-icon red" />
            </span>
         </>
      );
   };

   const removeFeeRequestOnclick = (fr: FeeRequestUIModel): (() => void) => {
      return (): void => {
         removeFeeRequest && removeFeeRequest(fr.data.feeRequestId);
      };
   };
   const removeFeeRequestOnEnter = (
      fr: FeeRequestUIModel
   ): ((keyPress: React.KeyboardEvent<HTMLElement>) => void) => {
      return (keyPress: React.KeyboardEvent<HTMLElement>): void => {
         if (!isEnterKeyPress(keyPress)) return;
         removeFeeRequest && removeFeeRequest(fr.data.feeRequestId);
      };
   };

   const removeFeeRequestLineOnclick = (
      frl: FeeRequestLineUIModel
   ): (() => void) => {
      return (): void => {
         removeFeeRequestLine &&
            removeFeeRequestLine(
               frl.data.feeRequestId,
               frl.data.feeRequestLineId
            );
      };
   };
   const removeFeeRequestLineOnEnter = (
      frl: FeeRequestLineUIModel
   ): ((keyPress: React.KeyboardEvent<HTMLElement>) => void) => {
      return (keyPress: React.KeyboardEvent<HTMLElement>): void => {
         if (!isEnterKeyPress(keyPress)) return;
         removeFeeRequestLine &&
            removeFeeRequestLine(
               frl.data.feeRequestId,
               frl.data.feeRequestLineId
            );
      };
   };

   return (
      <DataTable
         baseId="fee-request-table"
         className="md-cell md-cell--12 fee-request-table"
      >
         <TableHeader>
            <TableRow selectable={false}>
               {!isPaid && !isCancelled ? <TableColumn></TableColumn> : null}
               <TableColumn>I am paying for</TableColumn>
               <TableColumn>Type of Application</TableColumn>
               <TableColumn>Licence Year</TableColumn>
               <TableColumn>Fee</TableColumn>
               <TableColumn className="currency-cell">
                  Amount to Pay
               </TableColumn>
               {!isCancelled && <TableColumn></TableColumn>}
            </TableRow>
         </TableHeader>
         <TableBody>
            {feeRequests.map((fr, i) => {
               const frAmount = sumBy(fr.feeRequestLines, 'data.amount');
               const oddRow = (i + 1) % 2 > 0 ? 'odd-row' : '';
               const isPaidFr = fr.data.feeRequestStatus === FEE_REQUEST_PAID;
               const frlRows = fr.feeRequestLines.map(frl => {
                  if (
                     isCancelled &&
                     frl.data.paymentId &&
                     frl.data.paymentId !== payment.paymentId
                  ) {
                     return null;
                  }
                  const feeLineStyle = getMultiPayStyle(
                     frl.data.feeRequestLineId
                  );

                  return (
                     <TableRow
                        key={`frl-${frl.data.feeRequestLineId}`}
                        selectable={false}
                        className={`${oddRow} fee-request-line-row ${feeLineStyle}`}
                     >
                        {!isPaid && !isCancelled ? (
                           <TableColumn></TableColumn>
                        ) : null}

                        <TableColumn></TableColumn>
                        <TableColumn></TableColumn>
                        <TableColumn className="fee-request-line-checkbox-cell">
                           {isMultiPay &&
                           !isPaid &&
                           !isCancelled &&
                           !frl.data.isPaid ? (
                              <Button
                                 icon
                                 className="action-button"
                                 onClick={removeFeeRequestLineOnclick(frl)}
                                 onKeyUp={removeFeeRequestLineOnEnter(frl)}
                              >
                                 <FaIcon icon="trash" className="small-icon" />
                              </Button>
                           ) : !isMultiPay && feeLineStyle ? (
                              <Button
                                 icon
                                 className="action-button btn-remove-multi-pay"
                                 onClick={removeFeeRequestLineOnclick(frl)}
                                 onKeyUp={removeFeeRequestLineOnEnter(frl)}
                              >
                                 {renderRemoveMultiPayIcon()}
                              </Button>
                           ) : !isPaid && !isCancelled && !frl.data.isPaid ? (
                              <Checkbox
                                 label=""
                                 id={`fr-chk-${frl.data.feeRequestLineId}`}
                                 name={`fr-chk-${frl.data.feeRequestLineId}`}
                                 checked={
                                    !isMultiPay && feeLineStyle
                                       ? true
                                       : frl.isChecked
                                 }
                                 disabled={
                                    !isMultiPay && feeLineStyle ? true : false
                                 }
                                 onChange={(): void =>
                                    checkFeeRequestLine(
                                       fr.data.feeRequestId,
                                       frl.data.feeRequestLineId
                                    )
                                 }
                              />
                           ) : (
                              isPaid &&
                              !frl.data.isPaid && (
                                 <span className="unpiad-label">UNPAID</span>
                              )
                           )}
                        </TableColumn>
                        <TableColumn
                           className={`${
                              frl.data.paymentId &&
                              frl.data.paymentId !== payment.paymentId
                                 ? 'grey-out-fee-request-line'
                                 : ''
                           }`}
                        >
                           {frl.data.feeTypeDescription}
                        </TableColumn>
                        <TableColumn
                           className={`currency-cell ${
                              frl.data.paymentId &&
                              frl.data.paymentId !== payment.paymentId
                                 ? 'grey-out-fee-request-line'
                                 : ''
                           }`}
                        >
                           <label className="currency-symbol">$</label>
                           {currencyFormatWithoutSymbol(frl.data.amount)}
                        </TableColumn>
                        {!isCancelled && (
                           <TableColumn>
                              {frl.data.isPaid ? (
                                 <>
                                    {frl.data.paymentId &&
                                    frl.data.paymentId !== payment.paymentId ? (
                                       <Link
                                          to={`payment?${
                                             isPaid
                                                ? `feeRequestLineId=${frl.data.feeRequestLineId}`
                                                : `paymentId=${frl.data.paymentId}`
                                          }&returnUrl=${returnUrl}`}
                                          className="link-button"
                                       >
                                          <Button
                                             className="action-button"
                                             flat
                                          >
                                             <FaIcon
                                                icon="file-invoice-dollar"
                                                className="small-icon fee-request-line-view"
                                             />
                                          </Button>
                                       </Link>
                                    ) : null}
                                 </>
                              ) : isPaid ? (
                                 <Link
                                    to={`payment?feeRequestLineId=${frl.data.feeRequestLineId}&returnUrl=${returnUrl}`}
                                 >
                                    <Button primary>Pay Now</Button>
                                 </Link>
                              ) : (
                                 <Button
                                    flat
                                    className="action-button"
                                    onClick={(): void =>
                                       showFeeRequestLineModal(
                                          fr.data.feeRequestId,
                                          frl.data.feeRequestLineId
                                       )
                                    }
                                    onKeyUp={(keyPress): void => {
                                       if (isEnterKeyPress(keyPress))
                                          showFeeRequestLineModal(
                                             fr.data.feeRequestId,
                                             frl.data.feeRequestLineId
                                          );
                                    }}
                                 >
                                    <FaIcon
                                       className="small-icon fee-request-line-edit"
                                       icon="edit"
                                    />
                                 </Button>
                              )}
                           </TableColumn>
                        )}
                     </TableRow>
                  );
               });

               const feeStyle =
                  multiPayDetail.find(
                     f => f.data.feeRequestId === fr.data.feeRequestId
                  ) &&
                  !isPaid &&
                  !isMultiPay
                     ? MULTI_PAY_STYLE_NAME
                     : '';
               const frRow = (
                  <TableRow
                     key={`fr-${fr.data.feeRequestId}`}
                     selectable={false}
                     className={`${oddRow} fee-request-row ${feeStyle}`}
                  >
                     {!isPaid && !isCancelled ? (
                        <TableColumn>
                           <Button
                              flat
                              className="action-button"
                              onClick={(): void =>
                                 showHideFeeRequestLine(fr.data.feeRequestId)
                              }
                              onKeyUp={(keyPress): void => {
                                 if (isEnterKeyPress(keyPress))
                                    showHideFeeRequestLine(
                                       fr.data.feeRequestId
                                    );
                              }}
                           >
                              <FaIcon
                                 icon={`caret-${fr.isExpanded ? 'up' : 'down'}`}
                              />
                           </Button>
                           {isMultiPay ? (
                              <Button
                                 icon
                                 className="action-button"
                                 onClick={removeFeeRequestOnclick(fr)}
                                 onKeyUp={removeFeeRequestOnEnter(fr)}
                              >
                                 <FaIcon icon="trash" className="small-icon" />
                              </Button>
                           ) : !isMultiPay && feeStyle ? (
                              <Button
                                 icon
                                 className="action-button btn-remove-multi-pay"
                                 onClick={removeFeeRequestOnclick(fr)}
                                 onKeyUp={removeFeeRequestOnEnter(fr)}
                              >
                                 {renderRemoveMultiPayIcon()}
                              </Button>
                           ) : (
                              <Checkbox
                                 label=""
                                 id={`fr-chk-${fr.data.feeRequestId}`}
                                 name={`fr-chk-${fr.data.feeRequestId}`}
                                 checked={fr.isChecked}
                                 onChange={(): void =>
                                    checkFeeRequest(fr.data.feeRequestId)
                                 }
                              />
                           )}
                        </TableColumn>
                     ) : null}
                     <TableColumn>{fr.data.contactDetail}</TableColumn>
                     <TableColumn>{fr.data.application}</TableColumn>
                     <TableColumn>{fr.data.licenceYear}</TableColumn>
                     <TableColumn></TableColumn>
                     <TableColumn className="currency-cell">
                        <label className="currency-symbol">$</label>
                        {currencyFormatWithoutSymbol(frAmount)}
                     </TableColumn>
                     {!isCancelled && (
                        <TableColumn>
                           {!isPaid && !isPaidFr && (
                              <Button
                                 flat
                                 className="action-button"
                                 onClick={(): void =>
                                    showFeeRequestLineModal(
                                       fr.data.feeRequestId
                                    )
                                 }
                                 onKeyUp={(keyPress): void => {
                                    if (isEnterKeyPress(keyPress))
                                       showFeeRequestLineModal(
                                          fr.data.feeRequestId
                                       );
                                 }}
                              >
                                 <FaIcon className="small-icon" icon="plus" />
                              </Button>
                           )}
                        </TableColumn>
                     )}
                  </TableRow>
               );

               return (
                  // react doesn't like without a key in here
                  // Warning: Each child in a list should have a unique "key" prop.
                  <React.Fragment key={`frg-fr-${fr.data.feeRequestId}`}>
                     {frRow}
                     {fr.isExpanded && frlRows}
                  </React.Fragment>
               );
            })}
         </TableBody>
      </DataTable>
   );
};

export default FeeRequestTable;
