import React, { useState, useEffect } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { useDispatch, useSelector } from 'react-redux';
import './AddFeeType.scss';
import {
   selectFeeTypes,
   FeeTypeTypes,
   FeeTypeActions,
} from 'State/Redux/FeeTypeRedux';
import FeeTypeResultsPanel from './Panels/FeeTypeResultsPanels';

const ViewSystemParameter = (): JSX.Element => {
   const searchResults = useSelector(selectFeeTypes);
   const isLoading = useSelector(
      selectIsLoading([FeeTypeTypes.GET_FEE_TYPES_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   useEffect((): void => {
      dispatch(FeeTypeActions.getFeeTypesRequest());
      setHasSearched(true);
   }, [dispatch]);

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
            >
               <FeeTypeResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default ViewSystemParameter;
