import React, { useState } from 'react';
import {
   DataTable,
   TableRow,
   TableColumn,
   TableBody,
} from 'react-md/lib/DataTables';
import { Card, CardText } from 'react-md/lib/Cards';

import './ReviewCard.scss';
import FaIcon from '../FaIcon/FaIcon';
import UserCard from '../UserCard/UserCard';
import ContactPhotoResponse from 'Models/Contact/Dto/ContactPhotoResponse';
import {
   getMailingAddressLine,
   getPhysicalAddressLine,
} from 'Util/Helpers/Address';
import Button from 'react-md/lib/Buttons';
import ContactPhotoReview, {
   DefaultContactPhotoReview,
} from 'Models/Contact/Data/ContactPhotoReview';
import DialogContainer from 'react-md/lib/Dialogs';
import TextField from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import SelectField from 'react-md/lib/SelectFields';
import { CONTACT_PHOTO_REJECT_REASONS } from 'Util/Constants/ContactPhotoRejectReason';
import {
   CONTACT_PHOTO_STATUS_APPROVED,
   CONTACT_PHOTO_STATUS_EXTRACTED,
} from 'Util/Constants/ContactPhotoConstants';
import PhotoUpdatedImage from './PhotoUpdatedImage';

interface ReviewCardProps {
   contactPhoto: ContactPhotoResponse;
   reviewPhoto: (review: ContactPhotoReview) => void;
}

const ReviewCard = ({
   contactPhoto,
   reviewPhoto,
}: Readonly<ReviewCardProps>): JSX.Element => {
   const [reviewState, setReviewState] = useState(DefaultContactPhotoReview);
   const [showModal, setShowModal] = useState(false);
   const [selectedReason, setSelectedReason] = useState('');
   const firstPhoto = contactPhoto.contact.contactPhotos.find(
      c =>
         c.status === CONTACT_PHOTO_STATUS_APPROVED ||
         c.status === CONTACT_PHOTO_STATUS_EXTRACTED
   );
   const secondPhoto = firstPhoto
      ? contactPhoto.contact.contactPhotos.find(
           c =>
              (c.status === CONTACT_PHOTO_STATUS_APPROVED ||
                 c.status === CONTACT_PHOTO_STATUS_EXTRACTED) &&
              c.contactPhotoId !== firstPhoto.contactPhotoId
        )
      : null;

   const handleApprove = (): void => {
      reviewPhoto({
         ...reviewState,
         approved: true,
         contactPhotoId: contactPhoto.contactPhotoId,
      });
   };

   const handleDecline = (): void => {
      reviewPhoto({
         rejectReason: reviewState.rejectReason || selectedReason,
         approved: false,
         contactPhotoId: contactPhoto.contactPhotoId,
      });
   };

   return (
      <>
         <DialogContainer
            id="simple-list-dialog"
            visible={showModal}
            title="Please choose a reason for declining:"
            onHide={(): void => {
               setShowModal(false);
            }}
            width={700}
            portal
            focusOnMount={false}
         >
            <div>
               <SelectField
                  id="select-field-6"
                  label="Reason"
                  placeholder="Select Reason"
                  className="md-cell md-cell--12"
                  menuItems={CONTACT_PHOTO_REJECT_REASONS}
                  position={SelectField.Positions.BELOW}
                  onChange={(value): void => {
                     setSelectedReason(value.toString());

                     setReviewState({
                        ...reviewState,
                        rejectReason: '',
                     });
                  }}
                  onKeyUp={(event): void => {
                     if (isEnterKeyPress(event)) handleDecline();
                  }}
               />
               <br />
               <br />
               <TextField
                  floating
                  id="floating-center-title"
                  label="Other"
                  lineDirection="center"
                  placeholder="Reason for declining"
                  className="md-cell md-cell--12"
                  type="search"
                  value={reviewState.rejectReason}
                  disabled={selectedReason !== CONTACT_PHOTO_REJECT_REASONS[3]}
                  onChange={(value): void => {
                     setReviewState({
                        ...reviewState,
                        rejectReason: value.toString(),
                     });
                  }}
                  onKeyUp={(event): void => {
                     if (isEnterKeyPress(event)) handleDecline();
                  }}
               />
               <br />
               <div className="process-review">
                  <Button
                     flat
                     primary
                     swapTheming
                     disabled={
                        reviewState.rejectReason.length < 1 && !selectedReason
                     }
                     onClick={handleDecline}
                     onKeyUp={(keyPress): void => {
                        if (isEnterKeyPress(keyPress)) handleDecline();
                     }}
                  >
                     Process
                  </Button>
               </div>
            </div>
         </DialogContainer>
         <Card className="review-card md-block-centered md-cell--6">
            <CardText>
               <img
                  src={contactPhoto.fileLocation}
                  alt="Profile"
                  className="profile-image-main"
               />
               <UserCard
                  showContactDetails={false}
                  overrideContact={contactPhoto.contact}
               />

               <DataTable baseId="simple-pagination">
                  <TableBody>
                     <TableRow selectable={false}>
                        <TableColumn>
                           <FaIcon icon="phone-alt" />{' '}
                           {contactPhoto.contact.phoneNumber}
                        </TableColumn>
                     </TableRow>
                     <TableRow selectable={false}>
                        <TableColumn>
                           <FaIcon icon="mobile-alt" />{' '}
                           {contactPhoto.contact.mobileNumber}
                        </TableColumn>
                     </TableRow>
                     <TableRow selectable={false}>
                        <TableColumn>
                           <FaIcon icon="at" />{' '}
                           {contactPhoto.contact.emailAddress}
                        </TableColumn>
                     </TableRow>
                     <TableRow selectable={false}>
                        <TableColumn>
                           <FaIcon icon="paper-plane" />{' '}
                           {getMailingAddressLine(contactPhoto.contact)}
                        </TableColumn>
                     </TableRow>
                     <TableRow selectable={false}>
                        <TableColumn>
                           <FaIcon icon="map-marker-alt" />{' '}
                           {getPhysicalAddressLine(contactPhoto.contact)}
                        </TableColumn>
                     </TableRow>
                  </TableBody>
               </DataTable>
               {firstPhoto && (
                  <div className="grey-background md-grid md-cell--12">
                     <div className="md-cell md-cell--6 right-border">
                        <PhotoUpdatedImage
                           photoUrl={firstPhoto.fileLocation}
                           updatedDate={firstPhoto.uploadedDate}
                        />
                     </div>
                     {secondPhoto && (
                        <div className="md-cell md-cell--6">
                           <PhotoUpdatedImage
                              photoUrl={secondPhoto.fileLocation}
                              updatedDate={secondPhoto.uploadedDate}
                           />
                        </div>
                     )}
                  </div>
               )}

               <Button
                  flat
                  primary
                  swapTheming
                  className="md-cell md-cell--4 md-cell--4-offset search-button"
                  onClick={handleApprove}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) handleApprove();
                  }}
               >
                  Approve
               </Button>
               <Button
                  flat
                  secondary
                  className="md-cell md-cell--4 red-btn search-button"
                  swapTheming
                  onClick={(): void => setShowModal(true)}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) setShowModal(true);
                  }}
               >
                  Decline
               </Button>
            </CardText>
         </Card>
      </>
   );
};

export default ReviewCard;
