import { put, call } from 'redux-saga/effects';
import { FitAndProperActions } from '../Redux/FitAndProperRedux';
import { FitAndProperServiceType } from '../Services/FitAndProperService';
import { ApiResponse } from 'apisauce';
import QuestionDto from 'Models/FitAndProper/Dto/QuestionDto';
import QuestionSaveDto from 'Models/FitAndProper/Dto/QuestionSaveDto';
import AnswerHistoryDto from 'Models/FitAndProper/Dto/AnswerHistoryDto';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';

interface FitAndProperParams {
   type: string;
   token: string;
   id: string;
   userId: string;
   questionId: number;
   question: QuestionSaveDto;
   questions: QuestionSaveBulkDto[];
}

export function* getQuestions(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const qsAndAsResponse = yield call(fitAndProperService.getQuestions);
   const response = qsAndAsResponse as ApiResponse<QuestionDto[]>;

   if (response.status && response.ok) {
      yield put(FitAndProperActions.getQuestionsSuccess(response.data));
   } else {
      yield put(
         FitAndProperActions.getQuestionsFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getQuestionsAndAnswersById(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const { id } = action;
   const qsAndAsResponse = yield call(
      fitAndProperService.getQuestionsAndAnswersById,
      id
   );
   const response = qsAndAsResponse as ApiResponse<QuestionDto[]>;

   if (response.status && response.ok) {
      yield put(
         FitAndProperActions.getQuestionsAndAnswersByIdSuccess(response.data)
      );
   } else {
      yield put(
         FitAndProperActions.getQuestionsAndAnswersByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getQuestionsAndAnswersByIdAndQuestionId(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const { userId, questionId } = action;
   const qsAndAsResponse = yield call(
      fitAndProperService.getQuestionsAndAnswersByIdAndQuestionId,
      userId,
      questionId
   );
   const response = qsAndAsResponse as ApiResponse<QuestionDto[]>;

   if (response.status && response.ok) {
      yield put(
         FitAndProperActions.getQuestionsAndAnswersByIdAndQuestionIdSuccess(
            response.data
         )
      );
   } else {
      yield put(
         FitAndProperActions.getQuestionsAndAnswersByIdAndQuestionIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getQuestionHistoryById(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const { userId, questionId } = action;
   const qsAndAsResponse = yield call(
      fitAndProperService.getQuestionHistoryById,
      userId,
      questionId
   );
   const response = qsAndAsResponse as ApiResponse<AnswerHistoryDto[]>;

   if (response.status && response.ok) {
      yield put(FitAndProperActions.getAnswerHistoryByIdSuccess(response.data));
   } else {
      yield put(
         FitAndProperActions.getAnswerHistoryByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveAnswerById(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const { question } = action;
   const saveAnswerResponse = yield call(
      fitAndProperService.saveAnswerById,
      question
   );
   const response = saveAnswerResponse as ApiResponse<{}>;

   if (response.status && response.ok) {
      yield put(FitAndProperActions.saveAnswerByIdSuccess(true));
   } else {
      yield put(
         FitAndProperActions.saveAnswerByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveAnswerListById(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const { userId, questions } = action;
   const saveAnswerResponse = yield call(
      fitAndProperService.saveAnswerListById,
      userId,
      questions
   );
   const response = saveAnswerResponse as ApiResponse<QuestionDto[]>;

   if (response.status && response.ok) {
      yield put(FitAndProperActions.saveAnswerListByIdSuccess(response.data));
   } else {
      yield put(
         FitAndProperActions.saveAnswerListByIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveAnswerListByIdAndQuestionId(
   fitAndProperService: Readonly<FitAndProperServiceType>,
   action: Readonly<FitAndProperParams>
): Generator {
   const { userId, questions, questionId } = action;
   const saveAnswerResponse = yield call(
      fitAndProperService.saveAnswerListByIdAndQuestionId,
      userId,
      questions,
      questionId
   );
   const response = saveAnswerResponse as ApiResponse<QuestionDto[]>;

   if (response.status && response.ok) {
      yield put(
         FitAndProperActions.saveAnswerListByIdAndQuestionIdSuccess(
            response.data
         )
      );
   } else {
      yield put(
         FitAndProperActions.saveAnswerListByIdAndQuestionIdFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}
