interface LicenceStatusModel {
   licenceStatusCode: string;
   description: string;
}

export const DefaultLicenceStatusModel: LicenceStatusModel = {
   licenceStatusCode: '',
   description: '',
};

export type LicenceStatus = Readonly<LicenceStatusModel>;
