import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import Contact from 'Models/Contact/Data/Contact';
import ContactScreen from 'Models/Contact/Data/ContactScreen';
import ContactSearchResult from 'Models/Contact/Data/ContactSearchResult';
import { ContactSearchQuery } from 'Models/Contact/Dto/ContactSearchQuery';
import PaymentSearchResult from 'Models/Contact/Data/PaymentSearchResult';
import { PaymentSearchQuery } from 'Models/Contact/Dto/PaymentSearchQuery';
import ContactNote from 'Models/Contact/Data/ContactNote';
import { ContactEditModel } from 'Models/Contact/Data/ContactEditModel';
import ContactNoteInputModelReadonly from 'Models/Contact/Data/ContactNoteInputModel';
import Registration from 'Models/Registration/Registration';
import { withAccessToken, createApi } from '.';
import RegistrationNumberContactValidationReadonly from 'Models/Contact/Dto/RegistrationNumberContactValidation';
import UserEditModel from 'Models/SuperUser/UserEditModel';

export interface ContactServiceType {
   getContactById(id: string): Promise<ApiResponse<Contact>>;
   getContactByRegistrationNumber(id: string): Promise<ApiResponse<Contact>>;
   getContactScreenData(id?: string): Promise<ApiResponse<ContactScreen>>;
   saveContactData(data: ContactEditModel): Promise<ApiResponse<Contact>>;
   searchContact(
      query: ContactSearchQuery
   ): Promise<ApiResponse<readonly ContactSearchResult[]>>;
   searchPayments(
      query: PaymentSearchQuery
   ): Promise<ApiResponse<readonly PaymentSearchResult[]>>;
   getContactNotes(id: string): Promise<ApiResponse<readonly ContactNote[]>>;
   getContactPaymentsHistory(
      id: string
   ): Promise<ApiResponse<readonly PaymentSearchResult[]>>;
   saveContactNote(
      data: ContactNoteInputModelReadonly
   ): Promise<ApiResponse<ContactNote>>;
   getRegistrationsByContact(
      id: string
   ): Promise<ApiResponse<readonly Registration[]>>;
   saveRegistration(data: Registration): Promise<ApiResponse<Registration>>;
   getTrainingProviders(): Promise<ApiResponse<readonly Contact[]>>;
   validateContactRegistrationNumbers(
      data: RegistrationNumberContactValidationReadonly[]
   ): Promise<ApiResponse<RegistrationNumberContactValidationReadonly>>;
   exportNotesByContactId(id: string): Promise<ApiResponse<Blob>>;
   saveAdminUser(employeeData: UserEditModel): Promise<ApiResponse<number>>;
   getAdminUser(userId: string): Promise<ApiResponse<UserEditModel>>;
   searchAdminUser(
      searchModel: UserEditModel
   ): Promise<ApiResponse<UserEditModel[]>>;
}

const create = (baseURL: string | undefined): ContactServiceType => {
   const api = createApi({ baseURL });

   const getContactById = (id: string): Promise<ApiResponse<Contact>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/${id}`);
   };

   const getContactByRegistrationNumber = (
      id: string
   ): Promise<ApiResponse<Contact>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/Registration/${id}`);
   };

   const getContactScreenData = (
      id?: string
   ): Promise<ApiResponse<ContactScreen>> => {
      const authApi = withAccessToken(api, store.getState().oidc);

      return id
         ? authApi.get(`/Contact/ScreenData/${id}`)
         : authApi.get('/Contact/ScreenData');
   };

   const saveContactData = (
      data: ContactEditModel
   ): Promise<ApiResponse<Contact>> => {
      const authApi = withAccessToken(api, store.getState().oidc);

      // update if contact id is undefined
      return data.contactId
         ? authApi.put('/Contact', data)
         : authApi.post('/Contact', data);
   };

   const searchContact = (
      query: ContactSearchQuery
   ): Promise<ApiResponse<readonly ContactSearchResult[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Contact/Search', query);
   };

   const searchPayments = (
      query: PaymentSearchQuery
   ): Promise<ApiResponse<readonly PaymentSearchResult[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Contact/PaymentSearch', query);
   };

   const getContactNotes = (
      id: string
   ): Promise<ApiResponse<readonly ContactNote[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/Notes/${id}`);
   };

   const getContactPaymentsHistory = (
      id: string
   ): Promise<ApiResponse<readonly PaymentSearchResult[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/${id}/PaymentHistory`);
   };

   const saveContactNote = (
      noteData: ContactNoteInputModelReadonly
   ): Promise<ApiResponse<ContactNote>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Contact/Notes', noteData);
   };

   const getRegistrationsByContact = (
      id: string
   ): Promise<ApiResponse<readonly Registration[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`Registration/GetRegistrationsByContact/${id}`);
   };

   const saveRegistration = (
      data: Registration
   ): Promise<ApiResponse<Registration>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return data.registrationId && data.registrationId > 0
         ? authApi.put('/Registration', data)
         : authApi.post('/Registration', data);
   };

   const getTrainingProviders = (): Promise<ApiResponse<
      readonly Contact[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/TrainingProviders`);
   };

   const validateContactRegistrationNumbers = (
      data: RegistrationNumberContactValidationReadonly[]
   ): Promise<ApiResponse<RegistrationNumberContactValidationReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Contact/Registration/Validate', data);
   };

   const exportNotesByContactId = (id: string): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Contact/Notes/${id}/Export`);
   };

   const saveAdminUser = (
      userData: UserEditModel
   ): Promise<ApiResponse<number>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      // update if userId id is undefined
      return userData.userId
         ? authApi.put('/User', userData)
         : authApi.post('/User', userData);
   };

   const getAdminUser = (
      userId: string
   ): Promise<ApiResponse<UserEditModel>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/User/${userId}`);
   };

   const searchAdminUser = (
      searchModel: UserEditModel
   ): Promise<ApiResponse<UserEditModel[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/User/search`, searchModel);
   };

   return {
      getContactById,
      getContactByRegistrationNumber,
      getContactScreenData,
      saveContactData,
      searchContact,
      searchPayments,
      getContactNotes,
      getContactPaymentsHistory,
      saveContactNote,
      getRegistrationsByContact,
      saveRegistration,
      getTrainingProviders,
      validateContactRegistrationNumbers,
      exportNotesByContactId,
      saveAdminUser,
      getAdminUser,
      searchAdminUser,
   };
};

export default {
   create,
};
