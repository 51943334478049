import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import WebMessageDtoReadonly from 'Models/Notifications/WebMessageDto';
import { withAccessToken, createApi } from '.';

export interface NotificationServiceType {
   getWebMessages(): Promise<ApiResponse<WebMessageDtoReadonly>>;
   updateWebMessage(
      message: WebMessageDtoReadonly
   ): Promise<ApiResponse<WebMessageDtoReadonly>>;
   triggerNotification(type: string): Promise<ApiResponse<string>>;
}

const create = (baseURL: string | undefined): NotificationServiceType => {
   const api = createApi({ baseURL });

   const getWebMessages = (): Promise<ApiResponse<WebMessageDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Notification/GetWebMessages`);
   };

   const updateWebMessage = (
      message: WebMessageDtoReadonly
   ): Promise<ApiResponse<WebMessageDtoReadonly>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Notification/UpdateWebMessage`, message);
   };

   const triggerNotification = (type: string): Promise<ApiResponse<string>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Notification/TriggerNotification/${type}`);
   };

   return {
      getWebMessages,
      updateWebMessage,
      triggerNotification,
   };
};

export default {
   create,
};
