interface LicenceYear {
   startDate: string;
   endDate: string;
   description: string;
}

export const DefaultLicenceYear: LicenceYear = {
   startDate: '',
   endDate: '',
   description: '',
};

export type LicenceYearReadonly = Readonly<LicenceYear>;

export default LicenceYearReadonly;
