import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-md/lib/Buttons';
import { TextField } from 'react-md/lib/TextFields';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { useSelector, useDispatch } from 'react-redux';
import SelectField from 'react-md/lib/SelectFields';
import {
   DefaultComplaint,
   validateComplaint,
   InitialValidationModel,
} from 'Models/Complaint/Complaint';
import Moment from 'moment-timezone';
import { isValidRegistrationNumber } from 'Util/Helpers/RegistrationNumber';
import { Redirect } from 'react-router-dom';
import {
   selectComplaint,
   ComplaintTypes,
   ComplaintActions,
} from 'State/Redux/ComplaintRedux';
import { selectIsSuccess } from 'State/Redux/AsyncRedux';
import { selectContact, ContactActions } from 'State/Redux/ContactRedux';
import { getDisplayContactName } from 'Util/Helpers/Contact';
import { toast } from 'react-toastify';
import { useComplaintStatusList } from 'Util/Helpers/Metadata';
import DateInput from 'Components/Common/DateInput/DateInput';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { getInputValidationClassName } from 'Util/Helpers/Validation';

const DESCRIPTION_MAX_LENGTH = 1000;

const formatDate = (date: string | null): string => {
   return date && Moment(date).isValid()
      ? Moment(date).format(NAMED_DATE_FORMAT)
      : '';
};

const AddPanel = (): JSX.Element => {
   const complaint = useSelector(selectComplaint);
   const contact = useSelector(selectContact);
   const isSuccess = useSelector(
      selectIsSuccess([ComplaintTypes.CREATE_COMPLAINT_REQUEST])
   );
   const dispatch = useDispatch();

   const [complaintModel, setComplaintModel] = useState(DefaultComplaint);
   const [validationModel, setValidationModel] = useState(
      InitialValidationModel
   );
   const [addClicked, setAddClicked] = useState(false);
   const [inputRegistrationNumber, setRegistrationNumber] = useState('');
   const [searchedRegNumber, setSearchedRegNumber] = useState('');

   // pull in when updating reg #
   useEffect((): void => {
      if (searchedRegNumber && isValidRegistrationNumber(searchedRegNumber))
         dispatch(
            ContactActions.getContactByRegistrationNumberRequest(
               searchedRegNumber
            )
         );
   }, [searchedRegNumber, dispatch]);

   const complaintStatuses = useComplaintStatusList(
      'Select Disciplinary Matter Status'
   );

   const startDate = formatDate(complaintModel.startDate);
   const endDate = formatDate(complaintModel.endDate);

   const validation = validateComplaint(complaintModel);
   const isValidContact =
      isValidRegistrationNumber(inputRegistrationNumber) &&
      inputRegistrationNumber === searchedRegNumber &&
      !!contact;
   const contactName = isValidContact ? getDisplayContactName(contact) : '';

   if (
      isValidContact &&
      contact &&
      contact.contactId !== complaintModel.contactId
   )
      setComplaintModel({ ...complaintModel, contactId: contact.contactId });

   if (isValidContact !== validationModel.contactId)
      setValidationModel({ ...validationModel, contactId: isValidContact });

   const validContactInput =
      !inputRegistrationNumber ||
      isValidRegistrationNumber(inputRegistrationNumber) ||
      (!searchedRegNumber && isValidContact);

   const isValidForm = Object.values(validation).every((v): boolean => !!v);
   const isValidDisplayForm = Object.values(validationModel).every(
      (v): boolean => !!v
   );

   const onSearch = useCallback((): void => {
      let contactId = complaintModel.contactId;
      if (
         searchedRegNumber &&
         isValidRegistrationNumber(searchedRegNumber) &&
         contact
      )
         contactId = contact.contactId;

      dispatch(
         ComplaintActions.createComplaintRequest({
            ...complaintModel,
            contactId: contactId,
         })
      );
      setAddClicked(true);
   }, [complaintModel, contact, dispatch, searchedRegNumber]);

   if (addClicked && isSuccess) {
      toast.success(`Disciplinary Matter #${complaint.complaintId} created!`);
      return (
         <Redirect push to={`/disciplinary-matters/${complaint.complaintId}`} />
      );
   }

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <SelectField
               floating
               id="select-field-6"
               lineDirection="center"
               label="Disciplinary Matter Status"
               placeholder="Disciplinary Matter Status"
               className="md-cell md-cell--12"
               inputClassName={getInputValidationClassName(
                  !validationModel.statusCode
               )}
               menuItems={complaintStatuses}
               position={SelectField.Positions.BELOW}
               value={complaintModel.statusCode}
               onChange={(val): void => {
                  const newModel = {
                     ...complaintModel,
                     statusCode: val.toString(),
                  };
                  const newValidation = validateComplaint(newModel);

                  setComplaintModel(newModel);
                  setValidationModel({
                     ...validationModel,
                     statusCode: newValidation.statusCode,
                  });
               }}
               defaultValue=" "
               required
               error={!validationModel.statusCode}
               errorText="Please enter a valid status"
            />
         </div>
         <div className="md-cell md-cell--6"></div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="floating-center-title"
               label="Licence Number"
               lineDirection="center"
               className="md-cell md-cell--12"
               placeholder="Licence Number"
               inputClassName={getInputValidationClassName(!validContactInput)}
               value={inputRegistrationNumber}
               onChange={(val): void => {
                  setRegistrationNumber(val.toString());
               }}
               onKeyUp={(keyPress): void => {
                  if (
                     isEnterKeyPress(keyPress) &&
                     isValidRegistrationNumber(inputRegistrationNumber)
                  )
                     setSearchedRegNumber(inputRegistrationNumber);
               }}
               onBlur={(): void => {
                  setSearchedRegNumber(inputRegistrationNumber);
               }}
               required
               error={!validContactInput}
               errorText="Please enter a valid licence number"
            />
         </div>
         <div className="md-cell md-cell--6">
            <TextField
               floating
               id="respondent-name"
               className="md-cell md-cell--12"
               label="Respondent Name"
               lineDirection="center"
               placeholder="Respondent Name"
               value={contactName}
               disabled
            />
         </div>

         <div className="md-cell md-cell--6">
            <DateInput
               id="add-start-date"
               className="md-cell md-cell--12"
               label="Start Date"
               inputClassName={getInputValidationClassName(
                  !validationModel.startDate
               )}
               value={startDate}
               onChange={(val): void => {
                  const newModel = {
                     ...complaintModel,
                     startDate: val,
                  };
                  const newValidation = validateComplaint(newModel);

                  setComplaintModel(newModel);
                  setValidationModel({
                     ...validationModel,
                     startDate: newValidation.startDate,
                  });
               }}
               required
               error={!validationModel.startDate}
               errorText="Please enter a valid start date"
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="add-end-date"
               className="md-cell md-cell--12"
               label="End Date"
               inputClassName={getInputValidationClassName(
                  !validationModel.endDate
               )}
               value={endDate}
               onChange={(val): void => {
                  const newModel = {
                     ...complaintModel,
                     endDate: val,
                  };
                  const newValidation = validateComplaint(newModel);

                  setComplaintModel(newModel);
                  setValidationModel({
                     ...validationModel,
                     endDate: newValidation.endDate,
                  });
               }}
               required
               error={!validationModel.endDate}
               errorText="Please enter a valid end date"
            />
         </div>

         <div className="md-cell md-cell--12">
            <TextField
               id="complaint-description"
               className="md-cell md-cell--12"
               label="Description"
               rows={4}
               lineDirection="center"
               placeholder="Please enter a description"
               maxLength={DESCRIPTION_MAX_LENGTH}
               inputClassName={getInputValidationClassName(
                  !validationModel.description
               )}
               value={complaintModel.description}
               onChange={(val): void => {
                  const newModel = {
                     ...complaintModel,
                     description: val.toString(),
                  };
                  const newValidation = validateComplaint(newModel);

                  setComplaintModel(newModel);
                  setValidationModel({
                     ...validationModel,
                     description: newValidation.description,
                  });
               }}
               error={!validationModel.description}
               errorText="Please enter a valid description"
            />
         </div>
         <div className="md-cell md-cell--12">
            <Button
               flat
               primary
               swapTheming
               disabled={!isValidDisplayForm}
               className="md-cell md-cell--2 md-cell--10-offset add-matter-button"
               onKeyUp={(keyPress): void => {
                  setValidationModel({
                     ...validation,
                  });
                  if (!isEnterKeyPress(keyPress) || !isValidForm) return;

                  onSearch();
               }}
               onClick={(): void => {
                  setValidationModel({
                     ...validation,
                  });
                  if (!isValidForm) return;

                  onSearch();
               }}
            >
               Add
            </Button>
         </div>
      </div>
   );
};

export default AddPanel;
