import React, { useState, useCallback } from 'react';
import ExpansionPanel, { ExpansionList } from 'react-md/lib/ExpansionPanels';
import SearchPanel from './Panels/SearchPanel';
import ComplaintResultsPanel from './Panels/ResultsPanel';
import './FindTab.scss';
import {
   selectSearchResults,
   ComplaintTypes,
} from 'State/Redux/ComplaintRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { ComplaintActions } from 'State/Redux/ComplaintRedux';
import { useDispatch, useSelector } from 'react-redux';
import { ComplaintSearchQuery } from 'Models/Complaint/ComplaintSearchQuery';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const FindTab = (): JSX.Element => {
   const searchResults = useSelector(selectSearchResults);
   const isLoading = useSelector(
      selectIsLoading([ComplaintTypes.SEARCH_COMPLAINT_REQUEST])
   );
   const dispatch = useDispatch();

   const [hasSearched, setHasSearched] = useState(false);
   const resetSearch = useCallback((): void => setHasSearched(false), []);
   const showAndSearchComplaints = useCallback(
      (query: ComplaintSearchQuery): void => {
         dispatch(ComplaintActions.searchComplaintRequest(query));
         setHasSearched(true);
      },
      [dispatch]
   );

   const panelTitle = searchResults
      ? `Results (${searchResults.length})`
      : 'Results';

   const isSearching = isLoading && hasSearched;

   return (
      <ExpansionList className="md-cell md-cell--12 find-tab">
         <ExpansionPanel
            label="Search"
            footer={null}
            expanded
            expanderIcon={<></>}
            onExpandToggle={FN_EMPTY_VOID}
         >
            <SearchPanel
               searchComplaints={showAndSearchComplaints}
               isSearching={isSearching}
               resetSearch={resetSearch}
            />
         </ExpansionPanel>
         {hasSearched ? (
            <ExpansionPanel
               label={panelTitle}
               footer={null}
               expanded
               expanderIcon={<></>}
               onExpandToggle={FN_EMPTY_VOID}
            >
               <ComplaintResultsPanel
                  isLoading={isLoading}
                  searchResults={searchResults || []}
               />
            </ExpansionPanel>
         ) : (
            <></>
         )}
      </ExpansionList>
   );
};

export default FindTab;
