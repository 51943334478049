import {
   DisciplineCodes,
   UNKNOWN,
   DisciplineColors,
   TradesmanDisciplines,
} from 'Util/Constants/Disciplines';
import { PENDING, NOT_RENEWED } from 'Util/Constants/LicenceCard';

export const getIconForStatus = (status: string): string => {
   switch (status) {
      case 'Not Renewed':
      case "Don't Renew":
         return 'clear';
      case 'Pending':
         return 'cached';
      case 'Paid':
      case 'Renewing':
      case 'Renewed':
         return 'check';
      default:
         return 'check';
   }
};

export const getStatusViewAction = (status: string): string => {
   switch (status) {
      case 'Not Renewed':
      case "Don't Renew":
         return 'Renew';
      case 'Pending':
         return '';
      case 'Renewing':
      case 'Renewed':
         return 'View';
      default:
         return 'View';
   }
};

export const normalizeDisciplineCode = (disciplineCode: string): string => {
   if (!disciplineCode) return '';

   const trimmed = disciplineCode;
   if (!trimmed.length) return trimmed;

   const upper = trimmed.toUpperCase();
   if (!DisciplineCodes.includes(upper)) return '';

   return upper;
};

export const getIconPath = (status: string, discipline: string): string => {
   const disabledStatuses = ['not renewed', 'pending', 'error'];
   const loweredStatus = status.toLowerCase();

   const prefix = `/images/icon-${discipline.toLowerCase()}`;
   const suffix = disabledStatuses.includes(loweredStatus) ? 'black' : 'white';

   return `${prefix}-${suffix}.svg`;
};

export const getDisciplineColor = (disciplineCode: string): string => {
   const code = normalizeDisciplineCode(disciplineCode);
   return DisciplineColors.get(code) || UNKNOWN;
};

export const getColorForLicences = (
   status: string,
   discipline: string
): string => {
   if (status === NOT_RENEWED) return 'disabled';
   if (status === PENDING) return 'pending';
   return getDisciplineColor(discipline[0]);
};

export const getTradesmanDisciplineName = (disciplineCode: string): string => {
   const code = normalizeDisciplineCode(disciplineCode);
   return TradesmanDisciplines.get(code) || UNKNOWN;
};
