import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import ContactPhotoResponse from 'Models/Contact/Dto/ContactPhotoResponse';
import ContactPhotoReview from 'Models/Contact/Data/ContactPhotoReview';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ContactPhotoState {
   contactPhotosForReview: readonly ContactPhotoResponse[];
   contactPhotoExtract: Blob;
}

export type ContactPhotoStateReadonly = Readonly<ContactPhotoState>;

interface TypeNames {
   GET_CONTACT_PHOTO_REVIEW_REQUEST: string;
   GET_CONTACT_PHOTO_REVIEW_SUCCESS: string;
   GET_CONTACT_PHOTO_REVIEW_FAILURE: string;

   SAVE_CONTACT_PHOTO_REVIEW_REQUEST: string;
   SAVE_CONTACT_PHOTO_REVIEW_SUCCESS: string;
   SAVE_CONTACT_PHOTO_REVIEW_FAILURE: string;

   EXPORT_CONTACT_PHOTOS_REQUEST: string;
   EXPORT_CONTACT_PHOTOS_SUCCESS: string;
   EXPORT_CONTACT_PHOTOS_FAILURE: string;
}

type ContactPhotoTypeNames = Readonly<TypeNames>;

export interface ContactPhotoCreators {
   getContactPhotoReviewRequest: () => AnyAction;
   getContactPhotoReviewSuccess: (
      data: ContactPhotoResponse | undefined
   ) => AnyAction;
   getContactPhotoReviewFailure: (error: ApiError) => AnyAction;

   saveContactPhotoReviewRequest: (data: ContactPhotoReview) => AnyAction;
   saveContactPhotoReviewSuccess: () => AnyAction;
   saveContactPhotoReviewFailure: (error: ApiError) => AnyAction;

   exportContactPhotosRequest: () => AnyAction;
   exportContactPhotosSuccess: (data: Blob | undefined) => AnyAction;
   exportContactPhotosFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ContactPhotoStateReadonly = {
   contactPhotosForReview: [],
   contactPhotoExtract: new Blob(),
};

/* ------------- Reducers ------------- */
const getContactPhotoReviewSuccess = (
   state: ContactPhotoStateReadonly,
   action: AnyAction
): ContactPhotoStateReadonly => {
   return { ...state, contactPhotosForReview: action.data };
};

const exportContactPhotosSuccess = (
   state: ContactPhotoStateReadonly,
   action: AnyAction
): ContactPhotoStateReadonly => {
   return { ...state, contactPhotoExtract: action.data };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ContactPhotoTypeNames,
   ContactPhotoCreators
>('contactPhoto', {
   getContactPhotoReviewRequest: [],
   getContactPhotoReviewSuccess: ['data'],
   getContactPhotoReviewFailure: ['error'],

   saveContactPhotoReviewRequest: ['data'],
   saveContactPhotoReviewSuccess: [],
   saveContactPhotoReviewFailure: ['error'],

   exportContactPhotosRequest: [],
   exportContactPhotosSuccess: ['data'],
   exportContactPhotosFailure: ['error'],
});

export const ContactPhotoTypes = Types;
export const ContactPhotoActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_CONTACT_PHOTO_REVIEW_SUCCESS]: getContactPhotoReviewSuccess,
   [Types.EXPORT_CONTACT_PHOTOS_SUCCESS]: exportContactPhotosSuccess,
});

/* ------------- Selectors ------------- */
export const selectContactPhotosForReview = (
   state: StateReadonly
): readonly ContactPhotoResponse[] => state.contactPhoto.contactPhotosForReview;

export const selectExportedContactPhotos = (state: StateReadonly): Blob =>
   state.contactPhoto.contactPhotoExtract;
