import React, { useState, useEffect } from 'react';
import { AddUser } from '.';
import { useHistory, useParams } from 'react-router';
import UserEditModel, {
   DefaultUserEditModel,
} from 'Models/SuperUser/UserEditModel';
import { useDispatch, useSelector } from 'react-redux';
import {
   ContactActions,
   selectAdminUser,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import Loading from 'Components/Common/Loading';

interface EditUserUrlParam {
   userId?: string;
}

interface EditUserParam {
   editUserId?: string;
   onCancel?: () => void;
   onSave?: () => void;
}

const EditUser = ({
   editUserId,
   onCancel,
   onSave,
}: Readonly<EditUserParam>): JSX.Element => {
   const history = useHistory();
   const params = useParams<EditUserUrlParam>();
   const [userId, setUserId] = useState(editUserId ? editUserId : undefined);
   const [userDetailsRequested, setUserDetailsRequested] = useState(false);
   const [userDetailsLoaded, setUserDetailsLoaded] = useState(false);
   const [userEditModel, setUserEditModel] = useState<UserEditModel>(
      DefaultUserEditModel
   );
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.GET_ADMIN_USER_REQUEST])
   );
   const dispatch = useDispatch();
   const adminUser = useSelector(selectAdminUser);

   useEffect((): void => {
      if (history.location && params.userId && !editUserId) {
         setUserId(params.userId);
      }
   }, [history, params, editUserId]);

   useEffect((): void => {
      if (userId && !userDetailsRequested && !userDetailsLoaded) {
         dispatch(ContactActions.getAdminUserRequest(userId));
         setUserDetailsRequested(true);
      }
   }, [userId, userDetailsRequested, userDetailsLoaded, dispatch]);

   useEffect((): void => {
      if (userId && adminUser && userDetailsRequested && !userDetailsLoaded) {
         setUserEditModel({ ...adminUser, userId: userId });
         setUserDetailsLoaded(true);
      }
   }, [userDetailsLoaded, userDetailsRequested, adminUser, userId]);

   return (
      <Loading
         isLoading={isLoading || !userDetailsRequested || !userDetailsLoaded}
         isError={false}
         tryReload={FN_EMPTY_VOID}
         overlayOnChildren
      >
         <AddUser
            editMode={true}
            userEditModel={userEditModel}
            onCancel={onCancel}
            onSave={onSave}
         />
      </Loading>
   );
};

export default EditUser;
