import moment from 'moment';

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const SHORTYEAR_DATE_FORMAT = 'DD/MM/YY';
export const DATEPICKER_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_TIME_FORMAT_12 = 'DD/MM/YYYY  hh:mm A';
export const DISPLAY_DATE_TIME_FORMAT = 'DD/MM/YYYY  hh:mm ';
export const PARSE_DATE_FORMATS = [
   DISPLAY_DATE_FORMAT,
   SHORTYEAR_DATE_FORMAT,
   moment.ISO_8601,
   moment.RFC_2822,
];
export const NAMED_DATE_FORMAT = 'D MMM YYYY';
export const YEAR_FORMAT = 'YYYY';
export const NOT_APPLICABLE = 'N/A';
export const DEFAULT_LOADING_TIMEOUT = 3000;
export const NZ_TIMEZONE = 'Pacific/Auckland';
export const AMOUNT_FORMAT = '$';
export const PERCENTAGE_FORMAT = '%';
export const CARD_PAYMENT_FEE = 'CCFP';
