import React, { useState, useEffect } from 'react';
import { ExpansionPanel } from 'react-md/lib/ExpansionPanels';
import { ServerSideTable } from 'Components/Common/ServerSideTable/ServerSideTable';
import { Column } from 'react-table';
import { PagedQuery } from 'Models/Other/PagedQuery';
import ResultSearchResultReadOnly from 'Models/Examination/Data/ResultSearchResult';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import Moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import SelectField from 'react-md/lib/SelectFields';
import {
   useResultStatusList,
   useLicenceYearListWithNoFuture,
} from 'Util/Helpers/Metadata';
import ResultSearchResultDtoReadOnly from 'Models/Examination/Dto/ResultSearchResultDto';
import { ResultActions, ResultTypes } from 'State/Redux/ResultRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import { selectIsSuccess, selectIsError } from 'State/Redux/AsyncRedux';
import { toast } from 'react-toastify';

interface ResultsPanelProps {
   searchResults: ResultSearchResultReadOnly | null;
   paging: PagedQuery;
   onPagingChanged: (paging: PagedQuery) => void;
   isLoading: boolean;
}

const ResultsPanel = ({
   searchResults,
   isLoading,
   paging,
   onPagingChanged,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const resultStatuses = useResultStatusList();
   const licenceYears = useLicenceYearListWithNoFuture();
   const isSuccess = useSelector(
      selectIsSuccess([ResultTypes.UPDATE_RESULT_BY_SEARCH_RESULT_REQUEST])
   );
   const isError = useSelector(
      selectIsError([ResultTypes.UPDATE_RESULT_BY_SEARCH_RESULT_REQUEST])
   );

   const dispatch = useDispatch();

   const totalCount =
      searchResults && searchResults.totalCount ? searchResults.totalCount : 0;
   const results =
      searchResults && searchResults.results ? searchResults.results : [];
   // Use -1 to set blank edit target
   const [editTarget, setEditTarget] = useState<number>(-1);
   const [shouldSave, setShouldSave] = useState(false);
   const [pendingResponse, setPendingResponse] = useState(false);
   const [editPendingStatus, setEditPendingStatus] = useState('');
   const [editPendingLicenceYear, setEditPendingLicenceYear] = useState('');

   useEffect((): void => {
      if (pendingResponse && isSuccess) {
         toast.success(`Result successfully updated`);
         setPendingResponse(false);
      } else if (pendingResponse && isError) {
         toast.error(`Result update failed`);
         setPendingResponse(false);
      }
   }, [pendingResponse, isError, isSuccess]);

   const panelTitle = searchResults ? `Results (${totalCount})` : 'Results';

   const COLUMN_HEADERS: Column<ResultSearchResultDtoReadOnly>[] = [
      {
         Header: 'Date',
         accessor: 'date',
         Cell: (cellInfo): string => {
            return Moment(cellInfo.value).format(NAMED_DATE_FORMAT);
         },
      },
      {
         Header: 'Licence Number',
         accessor: 'licenceNumber',
      },
      {
         Header: 'Result',
         accessor: 'passFailCode',
      },
      {
         Header: 'Code',
         accessor: 'courseCode',
      },
      {
         Header: 'Title',
         accessor: 'title',
      },
      {
         Header: 'Type',
         accessor: 'courseType',
      },
      {
         Header: 'Provider',
         accessor: 'courseProvider',
      },
      {
         Header: 'Results%',
         accessor: 'result',
      },
      {
         Header: 'Status',
         accessor: 'resultStatusCode',
         className: 'overflow-visible',
         width: 220,
         Cell: (cellInfo): JSX.Element => {
            return cellInfo.original.resultId === editTarget ? (
               <SelectField
                  floating
                  id={cellInfo.original.resultId}
                  lineDirection="center"
                  className="md-cell md-cell--12"
                  menuItems={resultStatuses}
                  value={editPendingStatus}
                  position={SelectField.Positions.BELOW}
                  onChange={(val): void => {
                     setShouldSave(true);
                     setEditPendingStatus(val.toString());
                  }}
               />
            ) : (
               cellInfo.original.resultStatusCode
            );
         },
      },
      {
         Header: 'Licence year',
         accessor: (result: ResultSearchResultDtoReadOnly): string => {
            return result.licenceYear.toString();
         },
         id: 'licenceYear',
         className: 'overflow-visible',
         width: 160,
         Cell: (cellInfo): JSX.Element => {
            return cellInfo.original.resultId === editTarget ? (
               <SelectField
                  floating
                  id={cellInfo.original.resultId}
                  lineDirection="center"
                  className="md-cell md-cell--12"
                  menuItems={licenceYears}
                  value={editPendingLicenceYear}
                  position={SelectField.Positions.BELOW}
                  onChange={(val): void => {
                     setShouldSave(true);
                     setEditPendingLicenceYear(val.toString());
                  }}
               />
            ) : (
               cellInfo.original.licenceYearDescription
            );
         },
      },
      {
         Header: (): JSX.Element => {
            return <FaIcon icon="ellipsis-h" />;
         },
         id: 'edit',
         className: 'center-icon',
         width: EDIT_COLUMN_WIDTH,
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return cellInfo.original.resultId === editTarget ? (
               <FaIcon
                  onClick={(): void => {
                     if (shouldSave) {
                        dispatch(
                           ResultActions.updateResultBySearchResultRequest({
                              ...cellInfo.original,
                              resultStatusCode: editPendingStatus,
                              licenceYear: parseInt(editPendingLicenceYear, 10),
                           })
                        );
                     }
                     setShouldSave(false);
                     setEditTarget(-1);
                     setPendingResponse(true);
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     if (shouldSave) {
                        dispatch(
                           ResultActions.updateResultBySearchResultRequest({
                              ...cellInfo.original,
                              resultStatusCode: editPendingStatus,
                              licenceYear: parseInt(editPendingLicenceYear, 10),
                           })
                        );
                     }
                     setShouldSave(false);
                     setEditTarget(-1);
                     setPendingResponse(true);
                  }}
                  className="small-icon md-pointer--hover"
                  icon={shouldSave ? 'save' : 'times'}
               />
            ) : (
               <FaIcon
                  onClick={(): void => {
                     setShouldSave(false);
                     setEditTarget(cellInfo.original.resultId);
                     setEditPendingStatus(cellInfo.original.resultStatusCode);
                     setEditPendingLicenceYear(
                        cellInfo.original.licenceYear.toString()
                     );
                  }}
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     setShouldSave(false);
                     setEditTarget(cellInfo.original.resultId);
                     setEditPendingStatus(cellInfo.original.resultStatusCode);
                     setEditPendingLicenceYear(
                        cellInfo.original.licenceYear.toString()
                     );
                  }}
                  className="small-icon md-pointer--hover"
                  icon="edit"
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <ExpansionPanel
         label={panelTitle}
         footer={null}
         expanded
         expanderIcon={<></>}
         onExpandToggle={FN_EMPTY_VOID}
         className="search-result"
      >
         <ServerSideTable
            headers={COLUMN_HEADERS}
            data={results}
            totalResults={totalCount}
            paging={paging}
            onPagingChanged={onPagingChanged}
            isLoading={isLoading}
         />
      </ExpansionPanel>
   );
};

export default ResultsPanel;
