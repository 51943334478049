import './NotesPanel.scss';
import { selectIsLoading, selectIsSuccess } from 'State/Redux/AsyncRedux';
import { useSelector, useDispatch } from 'react-redux';
import {
   selectContactNotes,
   selectContact,
   ContactActions,
   ContactTypes,
} from 'State/Redux/ContactRedux';
import ContactNote from 'Models/Contact/Data/ContactNote';
import { ContactNoteInputModelReadonly } from 'Models/Contact/Data/ContactNoteInputModel';
import FileUploadInput from 'Components/Common/FileUploadInput';
import { isEmpty } from 'lodash-es';
import NoteItem from './NoteItem';
import React, { useEffect, useState } from 'react';
import {
   CONTACT_NOTE_MAX_NOTE_LENGTH,
   CONTACT_NOTE_MIN_NOTE_LENGTH,
   CONTACT_NOTE_ROW_COUNT,
} from 'Util/Constants/Contact';

const NotesPanel = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const notes = useSelector(selectContactNotes);
   const isLoading = useSelector(
      selectIsLoading([ContactTypes.SAVE_CONTACT_NOTE_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([ContactTypes.SAVE_CONTACT_NOTE_REQUEST])
   );
   const dispatch = useDispatch();

   const [isInputLengthValid, setIsInputLengthValid] = useState<boolean>(false);
   const [isSaveTriggered, setIsSaveTriggered] = useState<boolean>(false);
   const [note, setNote] = useState<string>('');
   const [attachment, setAttachment] = useState<string | null>(null);
   const [attachmentFileName, setAttachmentFileName] = useState<string>('');
   const [attachmentFileType, setAttachmentFileType] = useState<string>('');

   const fileReader: FileReader = new FileReader();

   const handleFileRead = (): void => {
      let content: string | null = fileReader.result as string;

      if (content) {
         // The FileReader API's readAsDataURL function returns a string in the following
         // format `data:image/png;base64,{base64 string}`. The following lines strips the
         // data type and sets the actual base64 encoded value.
         content = content.substr(content.indexOf(',') + 1);
         setAttachment(content);
      }
   };

   fileReader.onloadend = handleFileRead;

   const addAttachment = (file: File | File[] | null): void => {
      if (file && file instanceof File) {
         fileReader.readAsDataURL(file);
         setAttachmentFileName(file.name);
         setAttachmentFileType(file.type);
      }
   };

   const validateInputLength = (value?: string): void => {
      if (value) setNote(value);

      setIsInputLengthValid(
         value !== null &&
            value !== undefined &&
            value.length > CONTACT_NOTE_MIN_NOTE_LENGTH &&
            value.length <= CONTACT_NOTE_MAX_NOTE_LENGTH
      );
   };

   const saveNote = (): void => {
      const newNote: ContactNoteInputModelReadonly = {
         contactNoteId: 0,
         contactId: contact.contactId,
         note: note,
         attachment: attachment,
         attachmentFileName: attachmentFileName,
         attachmentFileType: attachmentFileType,
         applicationId: null,
      };

      dispatch(ContactActions.saveContactNoteRequest(newNote));
      setIsSaveTriggered(true);
   };

   const hasSaved = isSaveTriggered && !isLoading && isSuccess;
   useEffect((): void => {
      validateInputLength();
      if (hasSaved) {
         setAttachment(null);
         setAttachmentFileName('');
         setAttachmentFileType('');
      }
   }, [hasSaved]);

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--12">
            <FileUploadInput
               acceptedTypes=""
               buttonText={`Save Note`}
               fileInputIcon={`attach_file`}
               fileInputId={`note-file-upload`}
               fileInputLabel={
                  attachmentFileName ? attachmentFileName : `Add Attachment`
               }
               fileInputOnChangeAction={addAttachment}
               isButtonDisabled={!isInputLengthValid}
               maxInputLength={CONTACT_NOTE_MAX_NOTE_LENGTH}
               onSubmission={saveNote}
               placeholderText={`Enter a note`}
               textFieldOnChangeAction={validateInputLength}
               textFieldRowCount={CONTACT_NOTE_ROW_COUNT}
            />
         </div>
         <div className="md-cell md-cell--12">
            <div className="notes-container">
               {!isEmpty(notes) &&
                  notes.map(
                     (note: ContactNote, index: number): JSX.Element => (
                        <div
                           className={`note-item-container ${
                              !index ? `first-item` : ``
                           }`}
                           key={`note-item-container-${note.contactNoteId}`}
                        >
                           <div className="note-item-content">
                              <NoteItem
                                 key={`note-item-${note.contactNoteId}`}
                                 noteItem={note}
                              />
                           </div>
                        </div>
                     )
                  )}
            </div>
         </div>
      </div>
   );
};

export default NotesPanel;
