import React, { useEffect, useState } from 'react';
import ReviewCard from 'Components/Common/ReviewCard/ReviewCard';
import './ReviewPhotos.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
   ContactPhotoActions,
   selectContactPhotosForReview,
   ContactPhotoTypes,
} from 'State/Redux/ContactPhotoRedux';
import { useSelector, useDispatch } from 'react-redux';
import Loading from 'Components/Common/Loading';
import { selectIsLoading, selectIsError } from 'State/Redux/AsyncRedux';
import ContactPhotoReview from 'Models/Contact/Data/ContactPhotoReview';
import Button from 'react-md/lib/Buttons';
import { isEnterKeyPress } from 'Util/Helpers/Input';

const ReviewPhotos = (): JSX.Element => {
   const contactPhotoReviews = useSelector(selectContactPhotosForReview);
   const isLoading = useSelector(
      selectIsLoading([
         ContactPhotoTypes.GET_CONTACT_PHOTO_REVIEW_REQUEST,
         ContactPhotoTypes.SAVE_CONTACT_PHOTO_REVIEW_REQUEST,
      ])
   );
   const isError = useSelector(
      selectIsError([ContactPhotoTypes.GET_CONTACT_PHOTO_REVIEW_REQUEST])
   );
   const dispatch = useDispatch();

   const [contactPhotoSearch, setContactPhotoSearch] = useState(true);

   const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

   const reviewPhoto = (review: ContactPhotoReview): void => {
      dispatch(ContactPhotoActions.saveContactPhotoReviewRequest(review));
      setContactPhotoSearch(true);
   };

   useEffect((): void => {
      if (contactPhotoSearch && !isLoading) {
         dispatch(ContactPhotoActions.getContactPhotoReviewRequest());
         setContactPhotoSearch(false);
      }
   }, [isLoading, contactPhotoSearch, dispatch]);

   return (
      <Loading
         isLoading={isLoading}
         isError={isError}
         tryReload={(): void => {
            dispatch(ContactPhotoActions.getContactPhotoReviewRequest());
         }}
      >
         <>
            <div className="review md-cell md-cell--10 md-cell--1-offset">
               <Slider {...settings}>
                  {contactPhotoReviews.map(contactPhoto => {
                     return (
                        <div key={contactPhoto.contactPhotoId}>
                           <ReviewCard
                              contactPhoto={contactPhoto}
                              reviewPhoto={reviewPhoto}
                           />
                        </div>
                     );
                  })}
               </Slider>
            </div>
            {contactPhotoReviews.length < 1 && (
               <div className="loading-wrapper-main">
                  <div className="loading-wrapper-content">
                     <div className="">No photos for review.</div>
                     <br />
                     <Button
                        flat
                        primary
                        swapTheming
                        onClick={(): void => setContactPhotoSearch(true)}
                        onKeyUp={(keyPress): void => {
                           if (isEnterKeyPress(keyPress))
                              setContactPhotoSearch(true);
                        }}
                     >
                        Reload
                     </Button>
                  </div>
               </div>
            )}
         </>
      </Loading>
   );
};

export default ReviewPhotos;
