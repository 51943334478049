import { createReducer } from 'reduxsauce';
import ApiError from 'Models/Other/ApiError';
import { AnyAction } from 'redux';
import StateReadonly from './StateModel';
import { createScopedActions } from '.';
import DropdownDto from 'Models/Enum/Dto/DropdownDto';
import PostCodeCityDto from 'Models/Enum/Dto/PostCodeCityDto';

interface EnumState {
   cities: readonly DropdownDto[];
   postCodeCities: readonly PostCodeCityDto[];
}

export type EnumStateReadOnly = Readonly<EnumState>;

interface TypeNames {
   GET_CITIES_REQUEST: string;
   GET_CITIES_SUCCESS: string;
   GET_CITIES_FAILURE: string;
   GET_CITIES_RESET: string;

   GET_POST_CODE_CITIES_REQUEST: string;
   GET_POST_CODE_CITIES_SUCCESS: string;
   GET_POST_CODE_CITIES_FAILURE: string;
   GET_POST_CODE_CITIES_RESET: string;
}

type EnumDtoTypeNames = Readonly<TypeNames>;

export interface EnumCreators {
   getCitiesRequest: () => AnyAction;
   getCitiesFailure: (error: ApiError) => AnyAction;
   getCitiesSuccess: (data: readonly DropdownDto[] | undefined) => AnyAction;
   getCitiesReset: () => AnyAction;

   getPostCodeCitiesRequest: () => AnyAction;
   getPostCodeCitiesFailure: (error: ApiError) => AnyAction;
   getPostCodeCitiesSuccess: (
      data: readonly PostCodeCityDto[] | undefined
   ) => AnyAction;
   getPostCodeCitiesReset: () => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: EnumStateReadOnly = {
   cities: [] as DropdownDto[],
   postCodeCities: [] as PostCodeCityDto[],
};

/* ------------- Reducers ------------- */
const getCitiesSuccess = (
   state: EnumStateReadOnly,
   action: AnyAction
): EnumStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         cities: action.data,
      };
   }
   return state;
};

const getCitiesReset = (
   state: EnumStateReadOnly,
   action: AnyAction
): EnumStateReadOnly => {
   return { ...state, cities: [] };
};

const getPostCodeCitiesReset = (
   state: EnumStateReadOnly,
   action: AnyAction
): EnumStateReadOnly => {
   return { ...state, postCodeCities: [] };
};

const getPostCodeCitiesSuccess = (
   state: EnumStateReadOnly,
   action: AnyAction
): EnumStateReadOnly => {
   if (action && action.data) {
      return {
         ...state,
         postCodeCities: action.data,
      };
   }
   return state;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<EnumDtoTypeNames, EnumCreators>(
   'enum',
   {
      getCitiesRequest: [],
      getCitiesSuccess: ['data'],
      getCitiesFailure: ['error'],
      getCitiesReset: [],

      getPostCodeCitiesRequest: [],
      getPostCodeCitiesSuccess: ['data'],
      getPostCodeCitiesFailure: ['error'],
      getPostCodeCitiesReset: [],
   }
);

export const EnumDtoTypes = Types;
export const EnumDtoActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_CITIES_SUCCESS]: getCitiesSuccess,
   [Types.GET_CITIES_RESET]: getCitiesReset,

   [Types.GET_POST_CODE_CITIES_SUCCESS]: getPostCodeCitiesSuccess,
   [Types.GET_POST_CODE_CITIES_RESET]: getPostCodeCitiesReset,
});

/* ------------- Selectors ------------- */

export const selectCities = (state: StateReadonly): Readonly<DropdownDto[]> =>
   state.enum.cities;

export const selectPostCodeCities = (
   state: StateReadonly
): Readonly<PostCodeCityDto[]> => state.enum.postCodeCities;
