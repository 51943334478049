import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import Licence from 'Models/Licence/Data/LicenceGroup';
import LicenceDto from 'Models/Licence/Dto/LicenceDto';
import CreateLicenceDto from 'Models/Licence/Dto/CreateLicenceDto';
import UpdateLicenceDto from 'Models/Licence/Dto/UpdateLicenceDto';
import StatusDto from 'Models/Licence/Dto/StatusDto';
import { withAccessToken, createApi } from '.';
import DownloadFileItem from 'Models/BackOffice/DownloadFileItem';
import { LicenceSearchQuery } from 'Models/Licence/Dto/LicenceSearchQuery';

export interface LicenceServiceType {
   getLicencesById(id: string): Promise<ApiResponse<Licence>>;
   getLicencesHistoryById(id: string): Promise<ApiResponse<LicenceDto>>;
   createLicence(data: CreateLicenceDto): Promise<ApiResponse<StatusDto>>;
   updateLicence(data: UpdateLicenceDto): Promise<ApiResponse<StatusDto>>;
   exportLicenceCards(data: string): Promise<ApiResponse<DownloadFileItem>>;
   annualRenewal(): Promise<ApiResponse<string>>;
   searchLicences(data: LicenceSearchQuery): Promise<ApiResponse<LicenceDto[]>>;
}

const create = (baseURL: string | undefined): LicenceServiceType => {
   const api = createApi({ baseURL });

   const getLicencesById = (id: string): Promise<ApiResponse<Licence>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Licences/${id}`);
   };

   const getLicencesHistoryById = (
      id: string
   ): Promise<ApiResponse<LicenceDto>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Licences/History/${id}`);
   };

   const createLicence = (
      data: CreateLicenceDto
   ): Promise<ApiResponse<StatusDto>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Licences`, data);
   };

   const updateLicence = (
      data: UpdateLicenceDto
   ): Promise<ApiResponse<StatusDto>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Licences/Update`, data);
   };

   const exportLicenceCards = (
      exportType?: string
   ): Promise<ApiResponse<DownloadFileItem>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      let typeFileExport = '';
      if (exportType) {
         typeFileExport = '?typeFileExport=' + exportType;
      }
      return authApi.get(`/Licences/ExtractLicenceCards${typeFileExport}`);
   };

   const annualRenewal = (): Promise<ApiResponse<string>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Licences/AnnualRenewal`);
   };

   const searchLicences = (
      data: LicenceSearchQuery
   ): Promise<ApiResponse<LicenceDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Licences/Search`, data);
   };

   return {
      getLicencesById,
      getLicencesHistoryById,
      createLicence,
      updateLicence,
      exportLicenceCards,
      annualRenewal,
      searchLicences,
   };
};

export default {
   create,
};
