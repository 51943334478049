import './FileUploadInput.scss';
import React, { FormEvent } from 'react';

import { Button } from 'react-md/lib/Buttons';
import { FileInput } from 'react-md/lib/FileInputs';
import { FontIcon } from 'react-md/lib/FontIcons';
import { TextField } from 'react-md/lib/TextFields';

interface FileUploadInputProps {
   acceptedTypes: AcceptedFileType;
   buttonText: string;
   fileInputIcon?: string | null;
   fileInputId: string;
   fileInputLabel: string;
   fileInputOnChangeAction?: Function | null;
   isButtonDisabled: boolean;
   maxInputLength: number;
   onSubmission: Function;
   placeholderText: string;
   textFieldOnChangeAction?: Function | null;
   textFieldRowCount: number;
}

// File input accepted file types
export type AcceptedFileType = '' | 'audio/*' | 'image/*' | 'video/*';

const FileUploadInput = ({
   acceptedTypes,
   buttonText,
   fileInputIcon,
   fileInputId,
   fileInputLabel,
   fileInputOnChangeAction,
   isButtonDisabled,
   maxInputLength,
   onSubmission,
   placeholderText,
   textFieldOnChangeAction,
   textFieldRowCount,
}: Readonly<FileUploadInputProps>): JSX.Element => {
   const fileUploadFormRef = React.createRef<HTMLFormElement>();

   const onTextFieldChangeAction = (value: string | number): void => {
      if (textFieldOnChangeAction) {
         textFieldOnChangeAction(value);
      }
   };

   const onFileInputChangeAction = (
      files: File | readonly File[] | null
   ): void => {
      if (fileInputOnChangeAction) {
         fileInputOnChangeAction(files);
      }
   };

   const getFileIcon = (): JSX.Element => {
      let icon = 'file_upload';
      if (fileInputIcon) {
         icon = fileInputIcon;
      }
      return <FontIcon>{icon}</FontIcon>;
   };

   const handleSubmission = (event: FormEvent): void => {
      event.preventDefault();
      onSubmission();

      if (fileUploadFormRef && fileUploadFormRef.current) {
         fileUploadFormRef.current.reset();
      }
   };

   return (
      <form
         className="md-grid text-fields__application file-upload-input-container"
         onSubmit={handleSubmission}
         ref={fileUploadFormRef}
      >
         <TextField
            className="md-cell md-cell--bottom md-cell--12 text-field"
            id="placeholder-only-multiline"
            maxLength={maxInputLength}
            onChange={(value: string | number): void => {
               onTextFieldChangeAction(value);
            }}
            placeholder={placeholderText}
            name="text-field"
            rows={textFieldRowCount}
         />
         <div className="action-buttons-container">
            <FileInput
               accept={acceptedTypes}
               className="file-upload-input"
               flat
               iconBefore
               icon={getFileIcon()}
               id={fileInputId}
               label={fileInputLabel}
               onChange={(file: Readonly<File | File[] | null>): void =>
                  onFileInputChangeAction(file)
               }
               multiple={false}
               name="file-input"
            />
            <Button
               className="submit-button"
               disabled={isButtonDisabled}
               flat
               primary
               swapTheming
               type="submit"
            >
               {buttonText}
            </Button>
         </div>
      </form>
   );
};

export default FileUploadInput;
