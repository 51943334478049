import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import ApiError from 'Models/Other/ApiError';
import StateReadonly from './StateModel';
import { ReadonlyGlobalNotification } from 'Models/Notifications/GlobalNotification';
import { createScopedActions } from '.';

/* ------------- Interfaces for ReduxSauce ------------- */
interface GlobalNotificationState {
   globalNotification: ReadonlyGlobalNotification;
}

export type GlobalNotificationStateReadonly = Readonly<GlobalNotificationState>;

interface TypeNames {
   GET_GLOBAL_NOTIFICATION_REQUEST: string;
   GET_GLOBAL_NOTIFICATION_SUCCESS: string;
   GET_GLOBAL_NOTIFICATION_FAILURE: string;

   UPDATE_GLOBAL_NOTIFICATION_REQUEST: string;
   UPDATE_GLOBAL_NOTIFICATION_SUCCESS: string;
   UPDATE_GLOBAL_NOTIFICATION_FAILURE: string;
}

type GlobalNotificationTypeNames = Readonly<TypeNames>;

export interface GlobalNotificationCreators {
   getGlobalNotificationRequest: () => AnyAction;
   getGlobalNotificationSuccess: (
      data: ReadonlyGlobalNotification | undefined
   ) => AnyAction;
   getGlobalNotificationFailure: (error: ApiError) => AnyAction;

   updateGlobalNotificationRequest: (
      notification: ReadonlyGlobalNotification
   ) => AnyAction;
   updateGlobalNotificationSuccess: (
      data: ReadonlyGlobalNotification
   ) => AnyAction;
   updateGlobalNotificationFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: GlobalNotificationStateReadonly = {
   globalNotification: ({} as unknown) as ReadonlyGlobalNotification,
};

/* ------------- Reducers ------------- */
const getGlobalNotificationSuccess = (
   state: GlobalNotificationState,
   action: AnyAction
): GlobalNotificationStateReadonly => {
   return { ...state, globalNotification: action.data };
};

const updateGlobalNotificationSuccess = (
   state: GlobalNotificationState,
   action: AnyAction
): GlobalNotificationStateReadonly => {
   return { ...state, globalNotification: action.data };
};

const updateGlobalNotificationFailure = (
   state: GlobalNotificationStateReadonly,
   action: AnyAction
): GlobalNotificationStateReadonly => {
   return {
      ...state,
   };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   GlobalNotificationTypeNames,
   GlobalNotificationCreators
>('globalNotification', {
   getGlobalNotificationRequest: [],
   getGlobalNotificationSuccess: ['data'],
   getGlobalNotificationFailure: ['error'],

   updateGlobalNotificationRequest: ['notification'],
   updateGlobalNotificationSuccess: ['data'],
   updateGlobalNotificationFailure: ['error'],
});

export const GlobalNotificationTypes = Types;
export const GlobalNotificationActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_GLOBAL_NOTIFICATION_SUCCESS]: getGlobalNotificationSuccess,
   [Types.UPDATE_GLOBAL_NOTIFICATION_SUCCESS]: updateGlobalNotificationSuccess,
   [Types.UPDATE_GLOBAL_NOTIFICATION_FAILURE]: updateGlobalNotificationFailure,
});

/* ------------- Selectors ------------- */
export const selectGlobalNotification = (
   state: StateReadonly
): ReadonlyGlobalNotification => state.globalNotification.globalNotification;
