import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from 'Components/Common/Loading';
import {
   selectProcessingStatus,
   PaymentActions,
   PaymentTypes,
   selectPaymentResponse,
} from 'State/Redux/PaymentRedux';
import {
   selectIsLoading,
   selectIsError,
   selectIsSuccess,
} from 'State/Redux/AsyncRedux';
import { useHistory, useParams } from 'react-router';
import queryString from 'query-string';
import { isNullOrWhiteSpace } from 'Util/Helpers/Validation';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

const PaymentSuccess = (): JSX.Element => {
   const [initiated, setInitiated] = useState<boolean>(false);
   const processingStatus = useSelector(selectProcessingStatus);

   const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
   const paymentResponse = useSelector(selectPaymentResponse);
   const isPaymentResponseLoading = useSelector(
      selectIsLoading([PaymentTypes.GET_PAYMENT_RESPONSE_REQUEST])
   );

   const isLoading = useSelector(
      selectIsLoading([PaymentTypes.GET_PAYMENT_PROCESSING_STATUS_REQUEST])
   );
   const isError = useSelector(
      selectIsError([PaymentTypes.GET_PAYMENT_PROCESSING_STATUS_REQUEST])
   );
   const isSuccess = useSelector(
      selectIsSuccess([PaymentTypes.GET_PAYMENT_PROCESSING_STATUS_REQUEST])
   );

   const dispatch = useDispatch();
   const history = useHistory();
   const { returnUrl } = useParams();

   const queryStringValues = queryString.parse(history.location.search);
   const result =
      queryStringValues &&
      queryStringValues.result &&
      !Array.isArray(queryStringValues.result) &&
      !isNullOrWhiteSpace(queryStringValues.result)
         ? queryStringValues.result
         : '';

   const request = useCallback(() => {
      if (!isNullOrWhiteSpace(result)) {
         dispatch(PaymentActions.getPaymentProcessingStatusRequest(result));
      }
   }, [result, dispatch]);

   useEffect(() => {
      if (
         requestSubmitted &&
         paymentResponse &&
         paymentResponse.paymentId &&
         !isPaymentResponseLoading
      ) {
         history.push(
            `/payment?paymentId=${paymentResponse.paymentId}${
               returnUrl ? `&returnUrl=${decodeURIComponent(returnUrl)}` : ''
            }`
         );
      }
   }, [
      requestSubmitted,
      paymentResponse,
      isPaymentResponseLoading,
      history,
      returnUrl,
   ]);

   useEffect(() => {
      if (!initiated && !isLoading) {
         request();
         setInitiated(true);
      }
   }, [initiated, isLoading, request]);

   useEffect(() => {
      if (processingStatus && !requestSubmitted) {
         setRequestSubmitted(true);
         dispatch(PaymentActions.getPaymentResponseRequest(result));
      }

      if (
         initiated &&
         (processingStatus === null || !processingStatus) &&
         !isLoading &&
         isSuccess &&
         !isError
      ) {
         setTimeout(() => {
            request();
         }, 3000);
      }
   }, [
      processingStatus,
      isSuccess,
      isError,
      isLoading,
      initiated,
      request,
      requestSubmitted,
      dispatch,
      result,
   ]);

   return (
      <Loading tryReload={FN_EMPTY_VOID} isLoading={true} isError={false}>
         <></>
      </Loading>
   );
};
export default PaymentSuccess;
