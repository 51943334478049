import { put, call } from 'redux-saga/effects';
import { CourseActions } from 'State/Redux/CourseRedux';
import { CourseServiceType } from 'State/Services/CourseService';
import { ApiResponse } from 'apisauce';
import CourseCriteriaDto from 'Models/Examination/Dto/CourseCriteriaDto';
import CourseDtoReadonly from 'Models/Examination/Dto/CourseDto';
import CourseCreateErrorDtoReadonly from 'Models/Examination/Dto/CourseCreateErrorDto';
import CourseViewDtoReadonly from 'Models/Examination/Dto/CourseViewDto';

interface CourseParams {
   type: string;
   token: string;
   contactId: string;
   courseCriteria: CourseCriteriaDto[];
   courseNumber: string;
   course: CourseDtoReadonly;
}

export function* getAllCourses(
   courseService: Readonly<CourseServiceType>
): Generator {
   const courseResponse = yield call(courseService.getAllCourses);
   const response = courseResponse as ApiResponse<CourseViewDtoReadonly[]>;

   if (response.status && response.ok) {
      yield put(CourseActions.getAllCoursesSuccess(response.data));
   } else {
      yield put(
         CourseActions.getAllCoursesFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getCourseCriteria(
   courseService: Readonly<CourseServiceType>
): Generator {
   const courseResponse = yield call(courseService.getCourseCriteria);
   const response = courseResponse as ApiResponse<CourseCriteriaDto[]>;

   if (response.status && response.ok) {
      yield put(CourseActions.getCourseCriteriaSuccess(response.data));
   } else {
      yield put(
         CourseActions.getCourseCriteriaFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getCourseByCourseNumber(
   courseService: Readonly<CourseServiceType>,
   action: Readonly<CourseParams>
): Generator {
   const { courseNumber } = action;
   const courseResponse = yield call(
      courseService.getCourseByCourseNumber,
      courseNumber
   );
   const response = courseResponse as ApiResponse<CourseDtoReadonly>;

   if (response.status && response.ok) {
      yield put(CourseActions.getCourseByCourseNumberSuccess(response.data));
   } else {
      yield put(
         CourseActions.getCourseByCourseNumberFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* getCourseNumbers(
   courseService: Readonly<CourseServiceType>
): Generator {
   const courseResponse = yield call(courseService.getCourseNumbers);
   const response = courseResponse as ApiResponse<string[]>;

   if (response.status && response.ok) {
      yield put(CourseActions.getCourseNumbersSuccess(response.data));
   } else {
      yield put(
         CourseActions.getCourseNumbersFailure({
            code: 'Error',
            status: response.status,
         })
      );
   }
}

export function* saveCourse(
   courseService: Readonly<CourseServiceType>,
   action: Readonly<CourseParams>
): Generator {
   const { course } = action;
   const courseResponse = yield call(courseService.saveCourse, course);
   const response = courseResponse as ApiResponse<
      CourseDtoReadonly,
      CourseCreateErrorDtoReadonly
   >;

   if (response.status && response.ok) {
      yield put(CourseActions.saveCourseSuccess(response.data));
   } else {
      yield put(
         CourseActions.saveCourseFailure(
            response.data as CourseCreateErrorDtoReadonly
         )
      );
   }
}

export function* editCourse(
   courseService: Readonly<CourseServiceType>,
   action: Readonly<CourseParams>
): Generator {
   const { course } = action;
   const courseResponse = yield call(courseService.editCourse, course);
   const response = courseResponse as ApiResponse<
      CourseDtoReadonly,
      CourseCreateErrorDtoReadonly
   >;

   if (response.status && response.ok) {
      yield put(CourseActions.editCourseSuccess(response.data));
   } else {
      yield put(
         CourseActions.editCourseFailure(
            response.data as CourseCreateErrorDtoReadonly
         )
      );
   }
}
