import React from 'react';
import { Slider } from 'react-md/lib/Sliders';

const Reports = (): JSX.Element => {
   return (
      <div className="md-grid">
         <h2 className="md-cell md-cell--12 md-text-container">
            Reports placeholder
         </h2>
         <Slider id="page-3-slider" className="md-cell md-cell--12" />
      </div>
   );
};
export default Reports;
