import React from 'react';
import { Column, SortingRule, CellInfo } from 'react-table';
import Moment from 'moment-timezone';
import { NAMED_DATE_FORMAT } from 'Util/Constants/Common';
import { useSelector } from 'react-redux';
import { ClientSideTable } from 'Components/Common/ClientSideTable/ClientSideTable';
import LicenceDtoReadonly from 'Models/Licence/Dto/LicenceDto';
import {
   isSupervisionDateCurrentActive,
   isSupervisionDateFutureActive,
} from 'Util/Helpers/Supervision';
import { ActiveLicenceStatuses } from 'Util/Constants/LicenceStatus';
import { LicenceType } from 'Models/Metadata/LicenceType';
import StateReadonly from 'State/Redux/StateModel';
import { EDIT_COLUMN_WIDTH } from 'Util/Constants/Tables';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import FaIcon from 'Components/Common/FaIcon/FaIcon';
import { isEnterKeyPress } from 'Util/Helpers/Input';

interface ResultsPanelProps {
   licences: readonly LicenceDtoReadonly[];
   isLoading: boolean;
   editLicence: (licenceId: number) => void;
}

interface LicenceSearchDisplayModel {
   licenceId: number;
   licenceLevel: string;
   status: string;
   supervisor: string;
   licenceYear: string;
   startDate: string;
   expiryDate: string;
   onEdit: (licenceId: void) => void;
}

const generateLicenceSearchResults = (
   licences: readonly LicenceDtoReadonly[],
   licenceTypes: readonly LicenceType[],
   editLicence: (licenceId: number) => void
): LicenceSearchDisplayModel[] => {
   return licences.map(
      (licence): LicenceSearchDisplayModel => {
         const licenceType = licenceTypes.find(
            (lt): boolean => lt.licenceTypeCode === licence.licenceTypeCode
         );
         const licenceTypeDescription = licenceType
            ? licenceType.description.trim()
            : '';

         // If licence is currently active, then show the
         const supervisorDisplay =
            licence.supervision === null ||
            (!isSupervisionDateCurrentActive(licence.supervision[0]) &&
               !isSupervisionDateFutureActive(licence.supervision[0]) &&
               ActiveLicenceStatuses.some(
                  ls => ls === licence.licenceStatusCode
               ))
               ? '-'
               : licence.supervision[0].supervisor.registrationNumber +
                 ' - ' +
                 licence.supervision[0].supervisor.fullName;

         return {
            licenceId: licence.licenceId,
            licenceLevel: licenceTypeDescription,
            status: licence.licenceStatusCodeNavigation.description,
            supervisor: supervisorDisplay,
            licenceYear: licence.licenceYearNumberNavigation.description,
            startDate: licence.startDate
               ? Moment(licence.startDate).format()
               : '',
            expiryDate: licence.expiryDate
               ? Moment(licence.expiryDate).format()
               : '',

            onEdit: (): void => {
               editLicence(licence.licenceId);
            },
         };
      }
   );
};

const DEFAULT_SORTING: SortingRule[] = [{ id: 'licenceYear', desc: true }];

const ResultsPanel = ({
   licences,
   isLoading,
   editLicence,
}: Readonly<ResultsPanelProps>): JSX.Element => {
   const licenceTypes = useSelector(
      (state: StateReadonly): readonly LicenceType[] =>
         state.metadata.licenceTypes
   );

   const DateRenderer = (cell: CellInfo): JSX.Element => {
      return (
         <div>
            {cell.value ? Moment(cell.value).format(NAMED_DATE_FORMAT) : 'N/A'}
         </div>
      );
   };

   const COLUMN_HEADERS: Column<LicenceSearchDisplayModel>[] = [
      {
         Header: 'Licence Type',
         accessor: 'licenceLevel',
      },
      {
         Header: 'Licence Status',
         accessor: 'status',
      },
      {
         Header: 'Supervisor',
         accessor: 'supervisor',
      },
      {
         Header: 'Licensing Year',
         accessor: 'licenceYear',
      },
      {
         Header: 'Start Date',
         accessor: 'startDate',
         Cell: DateRenderer,
      },
      {
         Header: 'Expiry Date',
         accessor: 'expiryDate',
         Cell: DateRenderer,
      },
      {
         Header: <FaIcon icon="ellipsis-h" />,
         id: 'edit',
         width: EDIT_COLUMN_WIDTH,
         className: 'center-icon',
         accessor: FN_EMPTY_VOID,
         Cell: (cellInfo): JSX.Element => {
            return (
               <FaIcon
                  className="small-icon table-row-clickable"
                  icon="edit"
                  onClick={(): void => {
                     cellInfo.original.onEdit();
                  }}
                  onKeyUp={(keyPress): void => {
                     if (isEnterKeyPress(keyPress)) cellInfo.original.onEdit();
                  }}
               />
            );
         },
         sortable: false,
      },
   ];

   return (
      <ClientSideTable
         data={generateLicenceSearchResults(
            licences,
            licenceTypes,
            editLicence
         )}
         defaultPageSize={10}
         columns={COLUMN_HEADERS}
         defaultSorted={DEFAULT_SORTING}
         loading={isLoading}
      />
   );
};

export default ResultsPanel;
