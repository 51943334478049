import React, { useState, useEffect } from 'react';
import LoadingButton from '../LoadingButton';
import FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import {
   ExaminationActions,
   selectUserExaminationEligibilityReport,
} from 'State/Redux/ExaminationRedux';
import { selectContact } from 'State/Redux/ContactRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';

const DownloadReportButton = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const examinationEligibilityReport = useSelector(
      selectUserExaminationEligibilityReport
   );
   const dispatch = useDispatch();

   const [
      loadingButtonDownloadReport,
      setLoadingButtonDownloadReport,
   ] = useState<boolean>(false);

   useEffect((): void => {
      if (examinationEligibilityReport && loadingButtonDownloadReport) {
         const blob = new Blob([examinationEligibilityReport], {
            type: 'application/pdf',
         });
         FileSaver.saveAs(
            blob,
            `EligibilityReport-${contact.registrationNumber}.pdf`
         );
         setLoadingButtonDownloadReport(false);
         dispatch(ExaminationActions.resetExaminationEligibilityReportState());
      }
   }, [
      examinationEligibilityReport,
      loadingButtonDownloadReport,
      dispatch,
      contact.registrationNumber,
   ]);

   return (
      <LoadingButton
         flat
         primary
         onClick={(): void => {
            setLoadingButtonDownloadReport(true);
            dispatch(
               ExaminationActions.getUserExaminationEligibilityReportByContactIdRequest(
                  contact.contactId.toString()
               )
            );
         }}
         onKeyUp={(keyPress): void => {
            if (!isEnterKeyPress(keyPress)) return;
            setLoadingButtonDownloadReport(true);
            dispatch(
               ExaminationActions.getUserExaminationEligibilityReportByContactIdRequest(
                  contact.contactId.toString()
               )
            );
         }}
         isLoading={loadingButtonDownloadReport}
      >
         Download Eligibility Report
      </LoadingButton>
   );
};

export default DownloadReportButton;
