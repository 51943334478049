import React, { useState } from 'react';
import { Button } from 'react-md/lib/Buttons';

import { isEnterKeyPress } from 'Util/Helpers/Input';
import DateInput from 'Components/Common/DateInput/DateInput';
import LoadingButton from 'Components/Common/LoadingButton';
import { Checkbox } from 'react-md/lib/SelectionControls';
import {
   FeeMaintenanceSearchQuery,
   DefaultFeeMaintenanceSearchQuery,
   validateSearch,
   isValidQuery,
   filterToValidQuery,
} from 'Models/FeeMaintenance/Dto/FeeMaintenanceSearchQuery';
import SelectField from 'react-md/lib/SelectFields';
import { useApplicationTypeList, useFeeTypeList } from 'Util/Helpers/Metadata';

interface SearchPanelProps {
   searchBoardMeetingDates: (query: FeeMaintenanceSearchQuery) => void;
   isSearching: boolean;
   resetSearch: () => void;
}

const SearchPanel = ({
   searchBoardMeetingDates,
   isSearching,
   resetSearch,
}: Readonly<SearchPanelProps>): JSX.Element => {
   const [searchQuery, setSearchQuery] = useState(
      DefaultFeeMaintenanceSearchQuery
   );

   const validation = validateSearch(searchQuery);
   const filteredQuery = filterToValidQuery(searchQuery);
   const isValid = isValidQuery(filteredQuery);

   const searchOnKeyPress = (
      keyPress: React.KeyboardEvent<HTMLElement>
   ): void => {
      if (isEnterKeyPress(keyPress)) searchBoardMeetingDates(searchQuery);
   };

   const applicationTypes = useApplicationTypeList('Select Application Type');
   const feeTypes = useFeeTypeList('Select Fee Type');

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--6">
            <SelectField
               floating
               id="application"
               placeholder="Select Application Type"
               className="md-cell md-cell--12"
               position={SelectField.Positions.BELOW}
               value={searchQuery.application}
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     application: value.toString(),
                  });
               }}
               errorText="Select Application"
               menuItems={applicationTypes}
            />
         </div>
         <div className="md-cell md-cell--6">
            <SelectField
               floating
               id="fee-type"
               placeholder="Select Fee Type"
               className="md-cell md-cell--12"
               position={SelectField.Positions.BELOW}
               value={searchQuery.feeType}
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     feeType: value.toString(),
                  });
               }}
               errorText="Select Fee Type"
               menuItems={feeTypes}
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="payment-start-date"
               label="Start Date"
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     startDate: value.toString(),
                  });
               }}
               errorText="Please enter a valid start date"
               error={!validation.startDate}
               value={searchQuery.startDate ? searchQuery.startDate : undefined}
               className="md-cell md-cell--12"
               strict
            />
         </div>
         <div className="md-cell md-cell--6">
            <DateInput
               id="payment-start-date"
               label="End Date"
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     endDate: value.toString(),
                  });
               }}
               errorText="Please enter a valid end date"
               error={!validation.endDate}
               value={searchQuery.endDate ? searchQuery.endDate : undefined}
               className="md-cell md-cell--12"
               strict
            />
         </div>
         <div className="md-cell md-cell--6">
            <Checkbox
               id="past-fees"
               name="past-fees"
               label="Show Past Fees"
               checked={searchQuery.pastFees}
               onChange={(value): void => {
                  setSearchQuery({
                     ...searchQuery,
                     pastFees: value,
                  });
               }}
            />
         </div>
         <div className="md-cell md-cell--12 search-buttons">
            <LoadingButton
               isLoading={isSearching}
               flat
               primary
               swapTheming
               onClick={(): void => searchBoardMeetingDates(searchQuery)}
               onKeyUp={searchOnKeyPress}
               className="search"
               disabled={!isValid}
            >
               Search
            </LoadingButton>
            <Button
               flat
               secondary
               className="red-btn reset"
               swapTheming
               onClick={(): void => {
                  setSearchQuery(DefaultFeeMaintenanceSearchQuery);
                  resetSearch();
               }}
               onKeyUp={(keyPress): void => {
                  if (isEnterKeyPress(keyPress)) {
                     setSearchQuery(DefaultFeeMaintenanceSearchQuery);
                     resetSearch();
                  }
               }}
            >
               Reset
            </Button>
         </div>
      </div>
   );
};

export default SearchPanel;
