import React, { useState, useEffect } from 'react';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { AddressInput, PhoneInput } from 'Components/Common';
import { TextField } from 'react-md/lib/TextFields';
import {
   ContactEditModel,
   getMailingAddress,
   getPhysicalAddress,
} from 'Models/Contact/Data/ContactEditModel';
import { AddressModel } from 'Components/Common/AddressInput/AddressModel';
import { isEqual } from 'lodash-es';
import { ValidationResult } from 'Components/Common/ModelValidator/ValidationModel';
import { ContactValidationFields } from '../CreateContact';
import DateInput from 'Components/Common/DateInput/DateInput';
import { getInputValidationClassName } from 'Util/Helpers/Validation';
import { SelectField } from 'react-md';
import { useEthnicityTypeList, useGenderTypeList } from 'Util/Helpers/Metadata';

interface PersonalSectionProps {
   model: ContactEditModel;
   updateModel: (model: ContactEditModel) => void;
   validationResult?: ValidationResult<ContactValidationFields>;
   companyProfile?: boolean;
   hideCheckbok?: boolean;
   disabled?: boolean;
}

const PersonalSection = ({
   model,
   updateModel,
   validationResult,
   hideCheckbok,
   disabled,
}: Readonly<PersonalSectionProps>): JSX.Element => {
   const [mailingAddress, setMailingAddress] = useState(
      getMailingAddress(model)
   );
   const [physicalAddress, setPhysicalAddress] = useState(
      getPhysicalAddress(model)
   );
   if (!isEqual(getMailingAddress(model), mailingAddress))
      updateModel({ ...model, ...mailingAddress });
   if (!isEqual(getPhysicalAddress(model), physicalAddress))
      updateModel({ ...model, ...physicalAddress });

   const [isPreferredMobile, setIsPreferredMobile] = useState(false);
   const [isPreferredPhone, setIsPreferredPhone] = useState(false);

   useEffect(() => {
      if (
         model.preferredContactInformation &&
         model.preferredContactInformation === model.mobileNumber
      ) {
         setIsPreferredMobile(true);
      } else if (
         model.preferredContactInformation &&
         model.preferredContactInformation === model.phoneNumber
      ) {
         setIsPreferredPhone(true);
      }
   }, [model]);

   const handlePreferredMobile = (val: boolean): void => {
      if (!val) {
         setIsPreferredMobile(val);
         updateModel({
            ...model,
            preferredContactInformation: '',
         });
         return;
      }
      updateModel({
         ...model,
         preferredContactInformation: model.mobileNumber,
      });
      setIsPreferredMobile(val);
      setIsPreferredPhone(!val);
   };

   const handlePreferredPhone = (val: boolean): void => {
      if (!val) {
         setIsPreferredPhone(val);
         updateModel({
            ...model,
            preferredContactInformation: '',
         });
         return;
      }
      updateModel({
         ...model,
         preferredContactInformation: model.phoneNumber,
      });
      setIsPreferredPhone(val);
      setIsPreferredMobile(!val);
   };

   const genderTypes = useGenderTypeList();
   const enthnicityTypes = useEthnicityTypeList();
   return (
      <>
         <div className="md-grid md-cell--12">
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="first-name"
                  label="First Name(s)"
                  lineDirection="center"
                  placeholder="First Name(s)"
                  className="md-cell md-cell--bottom md-cell--12"
                  inputClassName={getInputValidationClassName(
                     validationResult && validationResult.fields.firstName.error
                  )}
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        firstName: val.toString(),
                     });
                  }}
                  value={model.firstName}
                  required
                  errorText="Please enter the First Name(s)"
                  error={
                     validationResult && validationResult.fields.firstName.error
                  }
                  maxLength={100}
                  disabled={disabled}
               />
               <DateInput
                  floating
                  id="date-of-birth"
                  label="Date of Birth *"
                  lineDirection="center"
                  placeholder="Date of Birth"
                  className="md-cell md-cell--12"
                  inputClassName={getInputValidationClassName(
                     validationResult &&
                        validationResult.fields.dateOfBirth.error
                  )}
                  onChange={(date): void => {
                     updateModel({
                        ...model,
                        dateOfBirth: date.toString(),
                     });
                  }}
                  value={model.dateOfBirth}
                  required
                  errorText="Please enter the Date of Birth"
                  error={
                     validationResult &&
                     validationResult.fields.dateOfBirth.error
                  }
                  maxDate={new Date()}
                  disabled={disabled}
               />
               <div className="md-cell md-cell--12">
                  <AddressInput
                     floating
                     id="contact_mailing_address"
                     label="Mailing Address"
                     placeholder="Mailing Address"
                     key={
                        model.mailingAddressFull
                           ? 'mailingNotRendered'
                           : 'mailingRendered'
                     }
                     defaultValue={model.mailingAddressFull}
                     onSubmit={(val: AddressModel): void => {
                        setMailingAddress({
                           mailingAddressLine1: val.addressLine1,
                           mailingAddressLine2: val.addressLine2,
                           mailingCity: val.city,
                           mailingSuburbTown: val.suburb,
                           mailingPostalCode: val.postcode,
                           mailingCountryCode: val.countryCode,
                        });
                     }}
                     disabled={disabled}
                     onChange={(val): void => {
                        if (val) return;

                        setMailingAddress({
                           mailingAddressLine1: '',
                           mailingAddressLine2: '',
                           mailingCity: '',
                           mailingSuburbTown: '',
                           mailingPostalCode: '',
                           mailingCountryCode: '',
                        });
                     }}
                     maxLength={500}
                     required
                  />
               </div>
               {!hideCheckbok && (
                  <Checkbox
                     id="checkbox-not-current"
                     name="checkbox-mail-address-no-longer-current"
                     label="No Longer Current"
                     value="material-design"
                     onChange={(val): void => {
                        updateModel({
                           ...model,
                           mailingAddressIsValid: val,
                        });
                     }}
                     defaultChecked={model.mailingAddressIsValid}
                  />
               )}
               <div className="md-cell md-cell--12">
                  <AddressInput
                     floating
                     id="contact_physical_address"
                     label="Physical Address"
                     placeholder="Physical Address"
                     disabled={disabled}
                     key={
                        model.mailingAddressFull
                           ? 'physicalNotRendered'
                           : 'physicalRendered'
                     }
                     defaultValue={model.physicalAddressFull}
                     onSubmit={(val: AddressModel): void => {
                        setPhysicalAddress({
                           physicalAddressLine1: val.addressLine1,
                           physicalAddressLine2: val.addressLine2,
                           physicalCity: val.city,
                           physicalSuburbTown: val.suburb,
                           physicalPostalCode: val.postcode,
                           physicalCountryCode: val.countryCode,
                        });
                     }}
                     onChange={(val): void => {
                        if (val) return;

                        setPhysicalAddress({
                           physicalAddressLine1: '',
                           physicalAddressLine2: '',
                           physicalCity: '',
                           physicalSuburbTown: '',
                           physicalPostalCode: '',
                           physicalCountryCode: '',
                        });
                     }}
                     maxLength={500}
                     errorText={'Please enter valid physical address'}
                  />
               </div>
            </div>
            <div className="md-cell md-cell--6">
               <TextField
                  floating
                  id="floating-center-title"
                  label="Last Name(s)"
                  lineDirection="center"
                  placeholder="Last Name(s)"
                  className="md-cell md-cell--bottom md-cell--12"
                  inputClassName={getInputValidationClassName(
                     validationResult && validationResult.fields.lastName.error
                  )}
                  disabled={disabled}
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        lastName: val.toString(),
                     });
                  }}
                  value={model.lastName}
                  required
                  errorText="Please enter the Last Name(s)"
                  error={
                     validationResult && validationResult.fields.lastName.error
                  }
                  maxLength={100}
               />
               <TextField
                  floating
                  id="floating-center-title"
                  label="Email"
                  lineDirection="center"
                  placeholder="Email"
                  className="md-cell md-cell--bottom md-cell--12"
                  inputClassName={getInputValidationClassName(
                     validationResult &&
                        validationResult.fields.emailAddress.error
                  )}
                  disabled={disabled}
                  type="email"
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        emailAddress: val.toString(),
                     });
                  }}
                  required
                  value={model.emailAddress}
                  errorText="Please enter the valid email"
                  error={
                     validationResult &&
                     validationResult.fields.emailAddress.error
                  }
                  maxLength={100}
               />
               <PhoneInput
                  floating
                  id="floating-center-title"
                  label="Contact Mobile Number"
                  placeholder="Contact Mobile Number"
                  className="md-cell md-cell--bottom md-cell--12"
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        mobileNumber: val.toString(),
                     });
                  }}
                  required
                  disabled={disabled}
                  value={model.mobileNumber}
                  maxLength={100}
                  errorText="Please enter valid mobile number"
               />
               {!hideCheckbok && (
                  <Checkbox
                     id="checkbox-prf-num-1"
                     name="simple-checkboxes[]"
                     label="Preferred Number"
                     value="material-design"
                     onChange={(val): void => {
                        handlePreferredMobile(val);
                     }}
                     checked={isPreferredMobile}
                     disabled={disabled}
                  />
               )}
               <PhoneInput
                  floating
                  id="floating-center-title"
                  label="Contact Phone Number"
                  placeholder="Contact Phone Number"
                  className="md-cell md-cell--bottom md-cell--12"
                  onChange={(val): void => {
                     updateModel({
                        ...model,
                        phoneNumber: val.toString(),
                     });
                  }}
                  value={model.phoneNumber}
                  maxLength={100}
                  errorText="Please enter valid phone number"
                  disabled={disabled}
               />
               {!hideCheckbok && (
                  <Checkbox
                     id="checkbox-prf-num-2"
                     name="simple-checkboxes[]"
                     label="Preferred Number"
                     value="material-design"
                     onChange={(val): void => {
                        handlePreferredPhone(val);
                     }}
                     checked={isPreferredPhone}
                     disabled={disabled}
                  />
               )}
            </div>

            <div className="md-cell md-cell--12">
               <SelectField
                  id="select-field-gender"
                  label="Gender"
                  placeholder="Select Gender"
                  className="md-cell md-cell--bottom md-cell--6"
                  menuItems={genderTypes}
                  position={SelectField.Positions.BELOW}
                  onChange={(value): void => {
                     updateModel({
                        ...model,
                        genderId: value ? Number(value) : null,
                     });
                  }}
                  required
                  value={model.genderId ? model.genderId : model.gender?.id}
                  errorText="Invalid gender type"
                  disabled={disabled}
                  key={
                     model.genderId ? 'genderIdNotRendered' : 'genderIdRendered'
                  }
               />
               <SelectField
                  id="select-field-ethnicity"
                  label="Ethnicity"
                  placeholder="Select Ethnicity"
                  className="md-cell md-cell--bottom md-cell--6"
                  menuItems={enthnicityTypes}
                  position={SelectField.Positions.BELOW}
                  onChange={(value): void => {
                     updateModel({
                        ...model,
                        ethnicityId: value ? Number(value) : null,
                     });
                  }}
                  required
                  value={
                     model.ethnicityId ? model.ethnicityId : model.ethnicity?.id
                  }
                  errorText="Invalid ethnicity type"
                  disabled={disabled}
                  key={
                     model.ethnicityId
                        ? 'ethnicityIdNotRendered'
                        : 'ethnicityIdRendered'
                  }
               />
            </div>
         </div>
      </>
   );
};

export default PersonalSection;
