import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FileSaver from 'file-saver';
import './LetterSelection.scss';
import {
   selectLetters,
   LetterActions,
   LetterTypes,
   selectContactLetter,
} from 'State/Redux/LetterRedux';
import LetterReadonly from 'Models/Letters/Data/Letter';
import { Column, SortingRule, CellInfo } from 'react-table';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import { selectContact } from 'State/Redux/ContactRedux';
import Button from 'react-md/lib/Buttons';
import { ConvertByteArrayStringToBlob } from 'Util/Helpers/File';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import { ClientSideTable } from '../ClientSideTable/ClientSideTable';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface LetterDisplayModel {
   letterName: string;
   letterFile: string;
   letterId: string;
   fileLocation: string;
}

const DEFAULT_SORTING: SortingRule[] = [{ id: 'letterName', desc: false }];

const generateLetterModels = (
   letters: LetterReadonly[]
): LetterDisplayModel[] => {
   return letters.map(
      (letter): LetterDisplayModel => {
         return {
            letterName: letter.letterName,
            letterFile: letter.letterFile,
            letterId: letter.letterId.toString(),
            fileLocation: letter.fileLocation,
         };
      }
   );
};

const LetterSelection = (): JSX.Element => {
   const contact = useSelector(selectContact);
   const contactLetter = useSelector(selectContactLetter);
   const letters = useSelector(selectLetters);
   const isLoading = useSelector(
      selectIsLoading([LetterTypes.GET_LETTERS_REQUEST])
   );
   const dispatch = useDispatch();

   useEffect((): void => {
      dispatch(LetterActions.getLettersRequest());
   }, [contact.contactId, dispatch]);

   useEffect((): void => {
      if (contactLetter && contactLetter.file) {
         FileSaver.saveAs(
            ConvertByteArrayStringToBlob(
               contactLetter.file,
               contactLetter.fileContentType
            ),
            contactLetter.fileName + contactLetter.fileExtension
         );
         dispatch(LetterActions.resetContactLetterState());
      }
   }, [contactLetter, dispatch]);

   if (!letters) {
      return <></>;
   }

   const selectButton = (cell: CellInfo): JSX.Element => {
      const letter = cell.original as LetterReadonly;
      if (!letter.fileLocation) return <>-</>;
      return (
         <Button
            flat
            primary
            swapTheming
            className="letter-selection"
            onClick={(e: React.MouseEvent): void => {
               const letter = cell.original as LetterReadonly;
               dispatch(
                  LetterActions.getContactLetterRequest(
                     contact.contactId.toString(),
                     letter.letterId.toString()
                  )
               );
               e.stopPropagation();
            }}
            onKeyDown={(keyPress): void => {
               if (!isEnterKeyPress(keyPress)) return;
               const letter = cell.original as LetterReadonly;
               dispatch(
                  LetterActions.getContactLetterRequest(
                     contact.contactId.toString(),
                     letter.letterId.toString()
                  )
               );
            }}
         >
            Select
         </Button>
      );
   };

   const COLUMN_HEADERS: Column<LetterDisplayModel>[] = [
      {
         Header: 'Name',
         accessor: 'letterName',
         width: 550,
      },
      {
         Header: 'Download',
         id: 'download',
         accessor: FN_EMPTY_VOID,
         Cell: selectButton,
         sortable: false,
         width: 150,
         style: { textAlign: 'center' },
      },
   ];

   return (
      <ClientSideTable
         data={generateLetterModels(letters)}
         defaultPageSize={10}
         columns={COLUMN_HEADERS}
         defaultSorted={DEFAULT_SORTING}
         loading={isLoading}
      />
   );
};

export default React.memo(LetterSelection);
