import { createStore, applyMiddleware, AnyAction, Store } from 'redux';
import createSagaMiddleware, { Task, SagaMiddleware } from 'redux-saga';
import { StateReducer } from '.';
import { composeWithDevTools } from 'redux-devtools-extension';
import StateReadonly from './StateModel';

interface StoreConfiguration {
   store: Store<StateReadonly, AnyAction>;
   sagasManager: Task;
   sagaMiddleware: SagaMiddleware<object>;
}

// creates the store
export default (
   rootReducer: StateReducer,
   rootSaga: () => Generator
): StoreConfiguration => {
   /* ------------- Redux Configuration ------------- */

   const middleware = [];
   const enhancers = [];

   /* ------------- Saga Middleware ------------- */
   const sagaMiddleware = createSagaMiddleware();
   middleware.push(sagaMiddleware);

   /* ------------- Assemble Middleware ------------- */
   enhancers.push(applyMiddleware(...middleware));
   const store = createStore(rootReducer, composeWithDevTools(...enhancers));

   // kick off root saga
   const sagasManager = sagaMiddleware.run(rootSaga);

   return {
      store,
      sagasManager,
      sagaMiddleware,
   };
};
