import { FILE_EXTENSION } from '../Constants/Photo';

export const loadImage = (src: string): Promise<HTMLImageElement> => {
   return new Promise((resolve, reject): void => {
      const img = new Image();
      img.onload = (): void => resolve(img);
      img.onerror = reject;
      img.src = src;
   });
};

export const getSupportedFileExtension = (): string => {
   const ext = FILE_EXTENSION.map((e): string => `.${e}`);
   return `${ext.slice(0, -1).join(', ')} or ${ext.slice(-1)}`;
};
