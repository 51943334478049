import React, { useCallback, useState, useEffect } from 'react';
import LoadingButton from 'Components/Common/LoadingButton';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import ConfirmationDialog from 'Components/Common/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
   SystemParametersActions,
   selectSystemParameters,
} from 'State/Redux/SystemParametersRedux';
import { LicenceActions } from 'State/Redux/LicenceRedux';
import { toast } from 'react-toastify';

const AnnualRenewalJob = (): JSX.Element => {
   const [addClicked, setAddClicked] = useState(false);
   const [showConfirmationCustom, setShowConfirmationCustom] = useState<
      boolean
   >(false);

   const dispatch = useDispatch();
   const [hasSearched, setHasSearched] = useState(false);

   useEffect((): void => {
      dispatch(SystemParametersActions.getSystemParametersRequest());
      setHasSearched(true);
   }, [dispatch]);

   const searchResults = useSelector(selectSystemParameters);

   const onAdd = useCallback((): void => {
      setAddClicked(true);
      setShowConfirmationCustom(true);
   }, []);

   const disableBtn = searchResults.find(
      (msg): boolean => msg.parameterCode === 'ANRJ'
   );

   return (
      <div className="md-grid md-cell--12">
         <div className="md-cell md-cell--12">
            {hasSearched && disableBtn?.value && (
               <LoadingButton
                  flat
                  primary
                  disabled={disableBtn?.value === 'true'}
                  swapTheming
                  isLoading={addClicked}
                  className="md-cell md-cell--3 md-cell--4-offset red-btn"
                  onKeyUp={(keyPress): void => {
                     if (!isEnterKeyPress(keyPress)) return;
                     onAdd();
                  }}
                  onClick={(): void => {
                     onAdd();
                  }}
               >
                  Clean-up and Relicensing Launch
               </LoadingButton>
            )}
            <ConfirmationDialog
               id="are-you-sure"
               confirmLabel="Yeah, we’re ready to rock and roll"
               cancelLabel="Nah"
               onConfirm={(): void => {
                  setShowConfirmationCustom(false);
                  toast.success(`Started!`);
                  dispatch(LicenceActions.annualRenewalRequest());
               }}
               onCancel={(): void => {
                  setShowConfirmationCustom(false);
                  setAddClicked(false);
               }}
               visible={showConfirmationCustom}
               title="Are you sure you want to do this..."
            >
               <>
                  This process will launch relicensing, it takes some time, and
                  it may slow down performance for users. Do you want to
                  proceed?
               </>
            </ConfirmationDialog>
         </div>
      </div>
   );
};

export default AnnualRenewalJob;
