import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
   ComplaintActions,
   ComplaintTypes,
   selectComplaint,
} from 'State/Redux/ComplaintRedux';
import { selectIsLoading } from 'State/Redux/AsyncRedux';
import EditPanel from './EditPanel';
import ViewPanel from './ViewPanel';
import Loading from 'Components/Common/Loading';
import { ContactTypes } from 'State/Redux/ContactRedux';
import {
   buildDocumentTitle,
   useDocumentTitle,
   combinePageTitles,
} from 'Util/Navigation/useDocumentTitle';
import { useParams } from 'react-router';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';

interface MatchComplaint {
   complaintId?: string;
}

interface ViewEditPanelProps {
   isEditing: boolean;
   setIsEditing: (isEditing: boolean) => void;
}

const ViewEditPanel = ({
   isEditing,
   setIsEditing,
}: Readonly<ViewEditPanelProps>): JSX.Element => {
   const complaint = useSelector(selectComplaint);
   const isLoading = useSelector(
      selectIsLoading([
         ComplaintTypes.GET_COMPLAINT_BY_ID_REQUEST,
         ContactTypes.GET_CONTACT_BY_ID_REQUEST,
      ])
   );
   const dispatch = useDispatch();

   const params = useParams<MatchComplaint>();
   const complaintId = params.complaintId;
   const stateComplaintId = complaint.complaintId || 0;
   const requiresLoading = complaintId !== stateComplaintId.toString();

   useEffect((): void => {
      if (requiresLoading && complaintId)
         dispatch(ComplaintActions.getComplaintByIdRequest(complaintId));
   }, [requiresLoading, complaintId, dispatch]);

   const complaintLoaded =
      !complaint || Object.keys(complaint || {}).length !== 0;

   let pageTitle: readonly string[] = ['Disciplinary Matters'];
   if (complaintLoaded) {
      pageTitle = combinePageTitles(`#${complaint.complaintId}`, pageTitle);
   }
   useDocumentTitle(buildDocumentTitle(pageTitle));

   return (
      <Loading
         isLoading={isLoading || !complaintLoaded}
         isError={false}
         tryReload={FN_EMPTY_VOID}
      >
         {isEditing ? (
            <EditPanel setIsEditing={setIsEditing} complaint={complaint} />
         ) : complaintLoaded && complaint ? (
            <ViewPanel complaint={complaint} />
         ) : (
            <></>
         )}
      </Loading>
   );
};

export default ViewEditPanel;
